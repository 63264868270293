
import { computed, defineComponent, ref } from 'vue';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';

let isLoading = ref(false);
export const withLoading =
  (apiFunc: () => void, close = true): (() => void) =>
  async () => {
    isLoading.value = true;
    try {
      await apiFunc();
    } catch (e) {
      if (close) isLoading.value = false;
      throw e;
    }
    if (close) isLoading.value = false;
  };

export default defineComponent({
  name: 'Loading',
  components: {
    LottieAnimation,
  },
  setup() {
    const baseUrl = process.env.BASE_URL?.substring(1) || '';

    const lottieName = location.href.includes('/mobile/') ? 'mobile_loading' : 'loading';

    return {
      isLoading: computed(() => isLoading),
      path: `${baseUrl}resources/lottie/${lottieName}.json`,
    };
  },
});
