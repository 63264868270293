import { AxiosInstance } from 'axios';
import { Service } from '../Service';
import { CommonResponseWrapper } from 'hems';
import { apiWrapper } from '../../util/helper';
import { ErrorCodeInfo, ErrorHistoryInfo, ErrorHistoryParams } from 'hems/event';
import { LanguageValue } from '@hems/util/src/constant';

export default class ErrorCodeService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getAllErrorCodes(language: LanguageValue): Promise<ErrorCodeInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<ErrorCodeInfo[]>>(apiWrapper.alarmApi('/error-codes'), {
      language,
    });
    return data;
  }

  public async getErrorCodes(language: LanguageValue, errorCode: string): Promise<ErrorCodeInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<ErrorCodeInfo[]>>(
      apiWrapper.alarmApi(`/error-codes/${errorCode}`),
      { language }
    );
    return data;
  }

  public async getErrorHistory(params: ErrorHistoryParams): Promise<{ data: ErrorHistoryInfo[]; totalCount: number }> {
    const { data, totalCount = 0 } = await this.get<CommonResponseWrapper<ErrorHistoryInfo[]>>(
      apiWrapper.alarmApi('/error-history'),
      params
    );
    return { data, totalCount };
  }
}

export { ErrorCodeService };
