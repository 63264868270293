
import '@hems/component/resources/scss/main.scss';
import { defineComponent, ref, computed, watch } from 'vue';

import Image from '@hems/component/src/image/Image2.vue';
import { MAPPER_POPUP_ICON_SIZE } from '@hems/util/src/constant/mapper';

type PopupType = 'save' | 'confirm' | 'alert' | 'warn' | 'error';
type Title = string;
type Message = string;
type btnName = string;
type MobilePopupInstance = {
  on: boolean;
  type: PopupType;
  imageName: string;
  imageExtension: string;
  imageSize: number;
  title: Title;
  message: Message;
  okName: btnName;
  cancelName: btnName;
  resolve?: (value: unknown) => void;
  reject?: (reason?: any) => void;
};

// FIXME: Class 제거 및 컴포지블화 작업 필요
class MobilePopup {
  // FIXME: 변수명 '_' 제거 필요
  private _type: PopupType = 'alert';
  private _imageName = 'img_check_72';
  private _imageExtension = 'svg';
  private _imageSize: number = MAPPER_POPUP_ICON_SIZE.CHECK;
  private _title: Title = '';
  private _message: Message = '';
  private _okName: btnName = 'Ok';
  private _cancelName: btnName = 'Cancel';

  constructor() {
    this.init();
  }

  private init(type: PopupType = 'alert') {
    this._type = type;
    this._imageName = this.setPopupImageName(type);
    this._imageExtension = 'svg';
    this._imageSize = this.setPopupImageSize(type);
    this._title = 'Ok';
    this._message = 'Cancel';

    return this;
  }

  private setPopupImageName(type: PopupType) {
    return ImageNameByPopupType[type] ?? '';
  }

  // FIXME: 화살표 함수로 변경 필요
  private setPopupImageSize(type: PopupType) {
    return ImageSizeByPopupType[type] ?? MAPPER_POPUP_ICON_SIZE.CHECK;
  }

  private getInstance(resolve: (value: unknown) => void, reject: (reason?: any) => void): MobilePopupInstance {
    return {
      on: true,
      type: this._type,
      imageName: this._imageName,
      imageExtension: this._imageExtension,
      imageSize: this._imageSize,
      title: this._title,
      message: this._message,
      okName: this._okName,
      cancelName: this._cancelName,
      resolve,
      reject,
    };
  }

  /**
   * 팝업 설정
   * @param type
   * @param title
   * @param message
   * @param okName
   * @param cancelName
   */
  setPopup(type: PopupType, title: Title, message: Message, okName = 'OK', cancelName = 'Cancel') {
    this.init(type);
    this._title = title;
    this._message = message;
    this._okName = okName;
    this._cancelName = cancelName;

    return this.chainSetMessage();
  }

  private chainSetMessage() {
    const self = this;

    return {
      open() {
        return new Promise((resolve, reject) => {
          _mobilePopup.value.on = false;
          setTimeout(() => {
            _mobilePopup.value = self.getInstance(resolve, reject);
          }, 250);
        });
      },
    };
  }
}

const ImageNameByPopupType: Partial<Record<PopupType, string>> = {
  alert: 'img_check_72',
  warn: 'img_error',
};
const ImageSizeByPopupType: Partial<Record<PopupType, number>> = {
  alert: MAPPER_POPUP_ICON_SIZE.CHECK,
  warn: MAPPER_POPUP_ICON_SIZE.ERROR,
};
const _mobilePopup = ref({} as MobilePopupInstance);
const btnCancelEl = ref(null as null | HTMLButtonElement);
const close = () => {
  _mobilePopup.value.on = false;
};

export const useMobilePopup = (): MobilePopup => new MobilePopup();

export default defineComponent({
  name: 'MobileMessagePopup',
  components: {
    Image,
  },
  setup() {
    const mobilePopup = computed(() => _mobilePopup.value);

    const onOk = () => {
      const { resolve } = mobilePopup.value;
      if (resolve) resolve(true);
      close();
    };
    const onCancel = () => {
      const { resolve } = mobilePopup.value;
      if (resolve) resolve(false);
      close();
    };
    watch(
      () => _mobilePopup.value.on,
      (on) => {
        if (on) {
          setTimeout(() => {
            btnCancelEl.value?.focus();
          }, 250);
          if (_mobilePopup.value.type === 'alert') {
            setTimeout(() => {
              close();
            }, 1000);
          }
        }
      }
    );

    return {
      mobilePopup,
      onOk,
      onCancel,
      btnCancelEl,
    };
  },
});
