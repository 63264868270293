import type { SettingGroup } from 'hems/device/settings';
import type { SelectorOption } from 'hems';

type SelectorOptions = {
  pcsConnectionType?: SelectorOption[];
  modbusConnectionType?: SelectorOption[];
  modbusRtuDev?: SelectorOption[];
  mlpeRtuDev?: SelectorOption[];
  modbusRtuBaudrate?: SelectorOption[];
  modbusRtuParity?: SelectorOption[];
  modeFlag?: SelectorOption[];
  extEmsModel?: SelectorOption[];
  debugInfo?: SelectorOption[];
  meterInfo?: SelectorOption[];
  gridCodeInfo?: SelectorOption[];
  extCtInstalledPointInfo?: SelectorOption[];
};

export function getEngineerSettingPvModuleGen3SmartModuleGen2Config(
  t: (key: string | number) => string,
  options: SelectorOptions
): SettingGroup[] {
  return [
    {
      groupTitle: 'EOS Mode',
      type: 'table',
      children: [
        {
          title: 'EOS Mode',
          code: 'eos_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
      ],
    },
    {
      groupTitle: 'EOS Info',
      type: 'table',
      children: [
        {
          title: 'Power Sampling Interval',
          code: 'eos_power_sampling_interval',
          type: 'number',
        },
        {
          title: 'Default Sampling Interval',
          code: 'eos_default_sampling_interval',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: 'FCAS',
      children: [
        {
          title: 'FCAS Flag',
          code: 'fcas_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Response Capacity limit',
          code: 'fcas_response_capacity_limit',
          type: 'number',
        },
        {
          title: 'Droop Rate Raise',
          code: 'fcas_droop_rate_raise',
          type: 'number',
        },
        {
          title: 'Droop Rate Lower',
          code: 'fcas_droop_rate_lower',
          type: 'number',
        },
        {
          title: 'Oftb Max',
          code: 'fcas_oftb_max',
          type: 'number',
        },
        {
          title: 'Oftb Min',
          code: 'fcas_oftb_min',
          type: 'number',
        },
        {
          title: 'Fr Max',
          code: 'fcas_fr_max',
          type: 'number',
        },
        {
          title: 'Fr Min',
          code: 'fcas_fr_min',
          type: 'number',
        },
        {
          title: 'Nofb Max',
          code: 'fcas_nofb_max',
          type: 'number',
        },
        {
          title: 'Nofb Min',
          code: 'fcas_nofb_min',
          type: 'number',
        },
        {
          title: 'Test Mode',
          code: 'fcas_test_mode',
          type: 'toggle',
          options: [
            { text: t('common.on'), value: 1 },
            { text: t('common.off'), value: 0 },
          ],
        },
      ],
    },
    {
      groupTitle: 'SG Ready',
      type: 'table',
      children: [
        {
          title: 'Enable Flag',
          code: 'sg_ready_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Heatpump Consumption Power',
          code: 'sg_ready_heat_pump_consumption_power',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: 'Inverter',
      type: 'table',
      children: [
        {
          title: 'Inverter limit',
          code: 'inverter_max_output_power',
          type: 'number',
        },
        {
          title: 'Grid target frequency',
          code: 'inverter_grid_target_frequency',
          type: 'number',
        },
        {
          title: 'External generator mode',
          code: 'inverter_external_generator_mode',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Permit service mode select flag',
          code: 'inverter_permit_service_mode_select',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Enter service randomized delay flag',
          code: 'inverter_enter_service_randomized_delay',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Import Power Limit',
          code: 'inverter_import_power_limit',
          type: 'number',
        },
        {
          title: 'Export Power Limit',
          code: 'inverter_export_power_limit',
          type: 'number',
        },
        {
          title: 'Battery Charge Limit',
          code: 'battery_charge_limit',
          type: 'number',
        },
        {
          title: 'Battery Discharge Limit',
          code: 'battery_discharge_limit',
          type: 'number',
        },
        {
          title: 'Logic Interface',
          code: 'inverter_logic_interface',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
      ],
    },
    {
      groupTitle: 'DC Optimizer',
      type: 'table',
      children: [
        {
          title: 'User RSD',
          code: 'dc_optimizer_user_rsd',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'MST TX Gain',
          code: 'dc_optimizer_mst_tx_gain',
          type: 'number',
        },
        {
          title: 'SLV TX Gain',
          code: 'dc_optimizer_slv_tx_gain',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: 'UL1741',
      type: 'table',
      children: [
        {
          title: 'UL1741SB Flag',
          code: 'ul1741_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
      ],
    },
    {
      groupTitle: 'AFCI',
      type: 'table',
      children: [
        {
          title: 'ARC Energy Region',
          code: 'afci_arc_energy_region',
          type: 'selector',
          options: [
            { text: 'A', value: 0 },
            { text: 'B', value: 1 },
          ],
        },
        {
          title: 'CH1 AFCI Mode',
          code: 'afci_ch1_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Time Area Threshold Gain CH1',
          code: 'afci_ch1_time_area_threshold_gain',
          type: 'number',
        },
        {
          title: 'Frequency Area Threshold Gain CH1',
          code: 'afci_ch1_frequency_area_threshold_gain',
          type: 'number',
        },
        {
          title: 'Frequency Area Threshold Percent CH1',
          code: 'afci_ch1_frequency_area_threshold_percent',
          type: 'number',
        },
        {
          title: 'CH2 AFCI Mode',
          code: 'afci_ch2_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Time Area Threshold Gain CH2',
          code: 'afci_ch2_time_area_threshold_gain',
          type: 'number',
        },
        {
          title: 'Frequency Area Threshold Gain CH2',
          code: 'afci_ch2_frequency_area_threshold_gain',
          type: 'number',
        },
        {
          title: 'Frequency Area Threshold Percent CH2',
          code: 'afci_ch2_frequency_area_threshold_percent',
          type: 'number',
        },
        {
          title: 'CH3 AFCI Mode',
          code: 'afci_ch3_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Time Area Threshold Gain CH3',
          code: 'afci_ch3_time_area_threshold_gain',
          type: 'number',
        },
        {
          title: 'Frequency Area Threshold Gain CH3',
          code: 'afci_ch3_frequency_area_threshold_gain',
          type: 'number',
        },
        {
          title: 'Frequency Area Threshold Percent CH3',
          code: 'afci_ch3_frequency_area_threshold_percent',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: 'Secondary EMS',
      type: 'table',
      children: [
        {
          title: t('common.ip_address'),
          code: 'secondary_ems_modbus_tcp_ip',
          type: 'text',
        },
        {
          title: t('common.port'),
          code: 'secondary_ems_modbus_tcp_port',
          type: 'number',
        },
        {
          title: 'Server Id',
          code: 'secondary_ems_server_id',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: t('device.pcs_external_ems'),
      type: 'table',
      children: [
        {
          title: 'PCS Connection Mode',
          code: 'pcs_connection_mode',
          type: 'toggle',
          options: [
            { text: 'External', value: 1 },
            { text: 'internal', value: 0 },
          ],
        },
        {
          title: t('device.pcs_debug_mode'),
          code: 'pcs_debug_mode',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: t('device.external_ems_model'),
          code: 'external_ems_model',
          type: 'selector',
          options: options.extEmsModel,
        },
        {
          title: t('device.pcs_connection'),
          code: 'external_ems_connection',
          type: 'selector',
          options: options.pcsConnectionType,
        },

        {
          title: t('common.ip_address'),
          code: 'external_ems_modbus_tcp_ip',
          type: 'text',
        },
        {
          title: t('common.port'),
          code: 'external_ems_modbus_tcp_port',
          type: 'number',
        },
        {
          title: t('control.rtu_dev'),
          code: 'external_ems_modbus_rtu_dev',
          type: 'selector',
          options: options.modbusRtuDev,
        },
        {
          title: t('control.rtu_baud_rate'),
          code: 'external_ems_modbus_rtu_baudrate',
          type: 'selector',
          options: options.modbusRtuBaudrate,
        },
        {
          title: t('control.rtu_parity'),
          code: 'external_ems_modbus_rtu_parity',
          type: 'selector',
          options: options.modbusRtuParity,
        },
        {
          title: t('control.rtu_data_bits'),
          code: 'external_ems_modbus_rtu_data',
          type: 'number',
        },
        {
          title: t('control.rtu_stop_bits'),
          code: 'external_ems_modbus_rtu_stop',
          type: 'number',
        },
        {
          title: 'Server Id',
          code: 'external_ems_server_id',
          type: 'number',
        },
        {
          title: 'Grid Code',
          code: 'external_ems_grid_code',
          type: 'selector',
          options: options.gridCodeInfo,
        },
        {
          title: 'External Energy Controller',
          code: 'external_ems_meter2',
          type: 'selector',
          options: options.meterInfo,
        },
      ],
    },
    {
      groupTitle: t('common.hysteresis'),
      type: 'table',
      children: [
        {
          title: t('common.low'),
          code: 'hysteresis_low',
          type: 'selector',
          options: [
            { text: '10%', value: 10 },
            { text: '15%', value: 15 },
            { text: '20%', value: 20 },
            { text: '25%', value: 25 },
          ],
        },
        {
          title: t('common.high'),
          code: 'battery_hysteresis_high',
          type: 'selector',
          options: [
            { text: '90%', value: 90 },
            { text: '85%', value: 85 },
            { text: '80%', value: 80 },
          ],
        },
      ],
    },
    {
      groupTitle: t('device.battery_user_soc'),
      type: 'table',
      children: [
        {
          title: t('common.min'),
          code: 'battery_user_soc_min',
          type: 'number',
        },
        {
          title: t('common.max'),
          code: 'battery_user_soc_max',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: 'HUB',
      type: 'table',
      children: [
        {
          title: 'HUB Installed',
          code: 'hub_installed',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Inverter Count',
          code: 'hub_inverter_count',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: 'Micro Inverter Control',
      type: 'table',
      children: [
        {
          title: 'AC Combiner',
          code: 'hub_micro_inverter_ac_combiner_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Total Max Output Power',
          code: 'hub_micro_inverter_total_max_output_power',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: 'HUB External Device Control',
      type: 'table',
      children: [
        {
          title: 'External Inverter',
          code: 'hub_external_inverter_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'External Inverter Nameplate Power',
          code: 'hub_external_inverter_nameplate_power',
          type: 'number',
        },
        {
          title: 'Generator Installed',
          code: 'hub_generator_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Generator Remote On',
          code: 'hub_generator_remote_on',
          type: 'radio',
          options: [
            { text: 'Impossible', value: 0 },
            { text: 'Possible', value: 1 },
          ],
        },
        {
          title: 'Generator Nameplate Power',
          code: 'hub_generator_nameplate_power',
          type: 'number',
        },
        {
          title: 'MSP Current Limit',
          code: 'hub_msp_current_limit',
          type: 'number',
        },
        {
          title: 'External CT Installed Point',
          code: 'hub_external_ct_installed_point',
          type: 'selector',
          options: options.extCtInstalledPointInfo,
        },
      ],
    },
    {
      groupTitle: t('device.debug_info'),
      type: 'table',
      children: [
        {
          title: t('control.process_mgr'),
          code: 'log_level_process_mgr',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: t('control.system_log'),
          code: 'log_level_system_log',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: t('control.fota'),
          code: 'log_level_fota',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: t('control.pw_control'),
          code: 'log_level_power_control',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: t('control.algo_mgr'),
          code: 'log_level_algorithm_mgr',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: t('control.ess_mgr'),
          code: 'log_level_ess_mgr',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: t('control.dc_src_mgr'),
          code: 'log_level_dc_source_mgr',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: t('control.cloud_mgr'),
          code: 'log_level_cloud_mgr',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: t('control.meter_mgr'),
          code: 'log_level_meter_mgr',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: t('control.gw_mgr'),
          code: 'log_level_gateway_mgr',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: t('control.data_mgr'),
          code: 'log_level_data_accumulator',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: t('control.db_mgr'),
          code: 'log_level_db_mgr',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: t('control.web_engine'),
          code: 'log_level_web_engine',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: 'GQA',
          code: 'log_level_gqa',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: 'MLPE Mgr',
          code: 'log_level_mlpe_mgr',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: 'Sys Mgr',
          code: 'log_level_sys_mgr',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: 'Energy Link',
          code: 'log_level_energy_link',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: 'Diag Monitor',
          code: 'log_level_diag_monitor',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: 'Csip Mgr',
          code: 'log_level_csip_mgr',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: 'Energy Optimizer',
          code: 'log_level_energy_optimizer',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: 'MQTT',
          code: 'debug_mode_mqtt',
          type: 'selector',
          options: options.debugInfo,
        },
        {
          title: 'Modbus',
          code: 'debug_mode_modbus',
          type: 'toggle',
          options: [
            { text: t('common.on'), value: 1 },
            { text: t('common.off'), value: 0 },
          ],
        },
      ],
    },
    {
      groupTitle: t('control.update_setting'),
      type: 'table',
      children: [
        {
          title: t('control.auto_firm_update'),
          code: 'auto_fw_update_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
      ],
    },
  ];
}
