const codeStorage = window.sessionStorage;
const codeKey = 'qommand-code-list';

export function clearCode() {
  if (!codeStorage) return;
  codeStorage.removeItem(codeKey);
}
export function isExistCode(hashcode: number): boolean {
  if (!codeStorage) return false;
  return !!JSON.parse(codeStorage.getItem(codeKey) ?? '{}')[hashcode];
}
export function getCode(hashcode: number): { code: string; name: string }[] {
  if (!codeStorage) return [];
  return JSON.parse(codeStorage.getItem(codeKey) ?? '{}')[hashcode];
}
export function setCode(hashcode: number, data: { code: string; name: string }[]) {
  if (!codeStorage) return;
  const codeData = JSON.parse(codeStorage.getItem(codeKey) ?? '{}');
  codeData[hashcode] = data;
  codeStorage.setItem(codeKey, JSON.stringify(codeData));
}

class SessionStorageService {
  private readonly storagePrefix = 'scratchy__';

  get<T>(key: string): T | null {
    const item = window.sessionStorage.getItem(this.storagePrefix + key);

    if (!item || item === 'null') {
      return null;
    }

    try {
      return JSON.parse(item);
    } catch (e) {
      console.log(e);
    }

    return null;
  }

  set(key: string, value: any): boolean {
    if (value === undefined) {
      value = null;
    } else {
      value = JSON.stringify(value);
    }

    try {
      window.sessionStorage.setItem(this.storagePrefix + key, value);
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  remove(key: string) {
    window.sessionStorage.removeItem(this.storagePrefix + key);
  }
}

export default new SessionStorageService();
