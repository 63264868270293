import { AxiosInstance } from 'axios';
import { Service } from '../Service';
import { CommonResponseWrapper } from 'hems';
import { apiWrapper } from '../../util/helper';
import { CustomerAgreementParams } from 'hems/install';
import { TermsInfo, EffectiveTermsInfo } from 'hems/terms';
import { TermsCategoryValue } from '@hems/util/src/constant';

export default class TermsService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }
  public async getTerms(termsType: string): Promise<EffectiveTermsInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<EffectiveTermsInfo[]>>(
      apiWrapper.managerApi(`/managements/mobiles/pro-apps/terms`),
      { termsType }
    );
    return data;
  }

  public async registerDeviceTerms(params: Partial<CustomerAgreementParams>): Promise<any> {
    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/mobiles/pro-apps/site-terms`),
      params
    );
    return data;
  }

  public async getTermsList(category: TermsCategoryValue): Promise<TermsInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<TermsInfo[]>>(
      apiWrapper.managerApi(`/managements/terms/list`),
      {
        categoryNm: category,
      }
    );
    return data;
  }

  public async getTermsDetail(termsId: number): Promise<TermsInfo> {
    const { data } = await this.get<CommonResponseWrapper<TermsInfo>>(apiWrapper.managerApi(`/managements/terms`), {
      termsId,
    });
    return data;
  }

  public async registerTerms(termsInfo: Partial<TermsInfo>) {
    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/terms`),
      termsInfo
    );
    return data;
  }

  public async updateTerms(termsId: number, termsInfo: Partial<TermsInfo>) {
    const { data } = await this.post<CommonResponseWrapper<any>>(apiWrapper.managerApi(`/managements/terms/update`), {
      termsId,
      ...termsInfo,
    });
    return data;
  }

  public async deleteTerms(termsId: number) {
    const { data } = await this.delete<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/terms/delete`),
      undefined,
      {
        data: {
          termsId,
        },
      }
    );
    return data;
  }
}
