import { ValueType } from 'hems/common/utils';

export const BUTTON_SIZE = {
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
  FIT_TO_PARENT: 'fit-to-parent',
} as const;

export type ButtonSizeValue = ValueType<typeof BUTTON_SIZE>;

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  WHITE: 'white',
} as const;

export type ButtonTypeValue = ValueType<typeof BUTTON_TYPE>;

export const BUTTON_CLASS = {
  BUTTON: 'btn_t',
  PRIMARY: 'btn_c_3',
  SECONDARY: 'btn_c_2',
  TERTIARY: 'btn_c',
  WHITE: 'btn_c_4',
} as const;

export type ButtonClassValue = ValueType<typeof BUTTON_CLASS>;
