/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited. 
*/
import { Helper, AuthHelper } from '@hems/util';

export const useAccessToken = () => {
  const accessToken = Helper.getAppEnv() === 'local' ? process.env.VUE_APP_ACCESS_TOKEN : AuthHelper.getAccessToken();

  return { accessToken };
};
