import type { ACDeviceTypeValue } from '@hems/util/src/constant';
import { AC_DEVICE_TYPE } from '@hems/util/src/constant';
import { AC_SYSTEM_FIELD_TYPE_CODE } from '@hems/util/src/constant/constant';

import type { SelectorOption } from 'hems';

import type { ValueType } from 'hems/common/utils';
import type { DeviceProfileACSystem } from 'hems/device/dashboard/smartmodule/acsys';

export const TIME_BASED_MODE_ACTION = {
  NOP: 'control.nop',
  AUTO: 'control.auto',
  MANUAL: 'control.manual',
  STOP_CHARGING: 'control.stop_charging',
  STOP_DISCHARGING: 'control.stop_discharging',
  // AC SYSTEM 용
  CHARGE_FROM_SOLAR: 'Charge from Solar',
  CHARGE_FROM_SOLAR_GRID: 'Charge from Solar and Grid',
  MAXIMIZE_EXPORT: 'Maximize Export',
  MAXIMIZE_SELF_CONSUMPTION: 'Maximize Self-Consumption',
  LIMIT_EXPORT: 'Limit Export',
} as const;

export type TimeBasedModeActionType = ValueType<typeof TIME_BASED_MODE_ACTION>;

export const mowTimeBasedActions: SelectorOption[] = [
  {
    text: TIME_BASED_MODE_ACTION.NOP,
    value: '0',
  },
  {
    text: TIME_BASED_MODE_ACTION.AUTO,
    value: '1',
  },
  {
    text: TIME_BASED_MODE_ACTION.MANUAL,
    value: '2',
  },
  {
    text: TIME_BASED_MODE_ACTION.STOP_CHARGING,
    value: '3',
  },
  {
    text: TIME_BASED_MODE_ACTION.STOP_DISCHARGING,
    value: '4',
  },
];

export const acSystemTimeBasedActions: SelectorOption[] = [
  {
    text: TIME_BASED_MODE_ACTION.CHARGE_FROM_SOLAR,
    value: '5',
  },
  {
    text: TIME_BASED_MODE_ACTION.CHARGE_FROM_SOLAR_GRID,
    value: '6',
  },
  {
    text: TIME_BASED_MODE_ACTION.MAXIMIZE_EXPORT,
    value: '7',
  },
  {
    text: TIME_BASED_MODE_ACTION.MAXIMIZE_SELF_CONSUMPTION,
    value: '8',
  },
  {
    text: TIME_BASED_MODE_ACTION.LIMIT_EXPORT,
    value: '9',
  },
];

export const SETTING_TYPE = {
  BASIC: 'BS',
  ADVANCED: 'AS',
  TOU: 'TOU',
  BASIC_ADVANCED: 'BS_AS',
  ADVANCED_TOU: 'AS_TOU',
  BASIC_TOU: 'BS_TOU',
  BASIC_ADVANCED_TOU: 'BS_AS_TOU',
  ENGINEER: 'ES',
} as const;

export type SettingTypeValue = ValueType<typeof SETTING_TYPE>;

export const SETTING_TARGET = {
  SITE: 'site',
  AC_COMBINER: 'accb',
  AC_ESS: 'aces',
  SINGLE: 'single', // single로 내리는 경우 target을 보내지 않고 기존의 deviceId 기준 setting을 내림
  ADVANCED: 'advanced', // HEM-13219 AC System advanced setting 을 내릴때만 해당 target을 보냄
} as const;

export type SettingTargetValue = ValueType<typeof SETTING_TARGET>;

const AC_DEVICE = {
  AC_COMBINER: 'AC Combiner',
  AC_COUPLED_ESS_HUB: 'AC Coupled ESS + HUB',
} as const;

export const SETTING_TAB_CODE = {
  SITE: 'site',
  DEVICE: 'device',
} as const;

export type SettingTabValue = ValueType<typeof SETTING_TAB_CODE>;

export const multiSettingTabName: ReadonlyArray<SelectorOption<SettingTabValue>> = [
  { text: 'SITE', value: SETTING_TAB_CODE.SITE },
  { text: 'DEVICE', value: SETTING_TAB_CODE.DEVICE },
] as const;

interface DeviceTabList {
  basicDeviceOption: SelectorOption[];
  engineeringDeviceOption: SelectorOption[];
  controlDeviceOption: SelectorOption[];
}

export function getDeviceTabListForAC(
  acDeviceType: ACDeviceTypeValue,
  deviceList: DeviceProfileACSystem[]
): DeviceTabList {
  const basicDeviceOption: SelectorOption[] = [];
  const engineeringDeviceOption: SelectorOption[] = [];
  const controlDeviceOption: SelectorOption[] = [];

  const combinerOption = { text: AC_DEVICE.AC_COMBINER, value: AC_SYSTEM_FIELD_TYPE_CODE.ACCB };
  const ESSHubOption = { text: AC_DEVICE.AC_COUPLED_ESS_HUB, value: AC_SYSTEM_FIELD_TYPE_CODE.ACES };

  if (acDeviceType === AC_DEVICE_TYPE.AC_COMBINER) {
    basicDeviceOption.push(combinerOption);
    controlDeviceOption.push(combinerOption);
  }

  if (acDeviceType === AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS_HUB) {
    controlDeviceOption.push(combinerOption);
    basicDeviceOption.push(combinerOption);

    basicDeviceOption.push(ESSHubOption);
    engineeringDeviceOption.push(ESSHubOption);

    /** 개별 세팅 옵션 */
    deviceList.forEach((device) => {
      if (device.product_model_nm.startsWith('AACES')) {
        const individualOption = {
          text: `AC Coupled ESS (serial #${device.device_id})`,
          value: `aces_individual_${device.device_id}`,
        };

        basicDeviceOption.push(individualOption);
        engineeringDeviceOption.push(individualOption);
        controlDeviceOption.push(individualOption);
      }
    });
  }

  return { basicDeviceOption, engineeringDeviceOption, controlDeviceOption };
}

export const PCS_CONNECTION_MODE = {
  INTERNAL: 0,
  EXTERNAL: 1,
  NONE: 2,
} as const;

export const EXTERNAL_EMS_CONNECTION = {
  DISABLE: 0,
  CAN: 1,
  MODBUS_TCP: 2,
  MODBUS_RTU: 3,
} as const;

export const EXTERNAL_EMS_MODEL = {
  NONE: 0,
  FOXESS_H1: 1,
  FOXESS_H3: 2,
  FOXESS_H3_PRO: 3,
  FOXESS_H3_G2: 4,
  CUSTOM: 99,
} as const;
