
import { defineComponent, reactive } from 'vue';
import Image from './Image.vue';
export default defineComponent({
  name: 'ToggleImage',
  components: {
    Image,
  },
  props: {
    onUrl: {
      type: String,
      required: true,
    },
    offUrl: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
      default: true,
    },
    text: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    textStyle: [String, Object],
  },
  setup(props, { emit }) {
    const state = reactive({
      value: props.modelValue,
    });
    const onClick = () => {
      if (!props.disabled) state.value = !state.value;
      emit('update:modelValue', state.value);
    };
    return {
      state,
      onClick,
    };
  },
});
