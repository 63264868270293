
import type { PropType } from 'vue';
import { defineComponent, reactive, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { SmartModuleWebACMIChartContainer } from '@hems/container/src/highcharts/combination';
import { Helper, DateHelper } from '@hems/util';
import {
  COLON_HOUR_MIN_FORMAT,
  ONE_DAY_VALUE_IN_MINUTES,
  ONE_MINUTE_VALUE_IN_MILLISECONDS,
} from '@hems/util/src/constant';

import type { LangCd } from 'hems';

import type { SmartModule15MinPwData } from 'hems/device/smartmodule';

import type { SmartModuleAcmiKey, SmartModuleAcsysSeries } from './chartOptions';
import { smartmoduleAcmiConfig, smartModuleAcsysKey, getSmartModuleACSystemChartYAxis } from './chartOptions';

export default defineComponent({
  name: 'SmartModuleWebACSystemChartContainer',
  components: {
    SmartModuleWebACMIChartContainer,
  },
  props: {
    data: {
      type: Array as PropType<SmartModule15MinPwData[]>,
      default: () => [],
    },
    langCd: {
      type: String as PropType<LangCd>,
      default: 'en',
    },
    fromDate: {
      type: Date,
      default: DateHelper.today(),
    },
    timezoneId: {
      type: String,
      default: 'Asia/Seoul',
    },
  },
  setup(props) {
    const { t } = useI18n();
    const state = reactive<{
      smartModuleAcmiInfo: SmartModuleAcsysSeries[];
      xAxisCategories: string[];
    }>({
      smartModuleAcmiInfo: [],
      xAxisCategories: [],
    });

    const yAxis = computed(() => getSmartModuleACSystemChartYAxis(t));

    watch(
      () => props.data,
      () => {
        if (props.data === null) return;
        setAcmiGraphChart();
      }
    );

    function setAcmiGraphChart() {
      state.xAxisCategories = setXAxisCategories();
      state.smartModuleAcmiInfo = setSmartModuleAcmiInfo();
    }

    function setXAxisCategories() {
      const xAxisCategories: string[] = [];

      for (let i = 0; i < ONE_DAY_VALUE_IN_MINUTES; i++) {
        const c = props.fromDate.getTime() + i * ONE_MINUTE_VALUE_IN_MILLISECONDS;
        const xAxisDate = new Date(c);
        const xAxisDateStr = DateHelper.formatDate(xAxisDate, COLON_HOUR_MIN_FORMAT);

        xAxisCategories.push(xAxisDateStr);
      }

      return xAxisCategories;
    }

    function setSmartModuleAcmiInfo() {
      const smartModuleAcmiInfo = smartmoduleAcmiConfig.filterList.map((item) => ({
        id: item.code,
        type: item.type,
        name: t(item.name, { defaultValue: item.altName }),
        yAxis: item.yAxis,
        data: [],
      })) as SmartModuleAcsysSeries[];

      let dataIdx = 0;
      for (let idx = 0; idx < state.xAxisCategories.length; idx++) {
        if (props.data[dataIdx] === undefined) break;
        let dataColecTime = DateHelper.getTimestampToTimeZoneDateFormat(
          props.data[dataIdx].colec_dt,
          props.timezoneId,
          COLON_HOUR_MIN_FORMAT
        );

        if (state.xAxisCategories[idx] === dataColecTime) {
          for (const k of smartModuleAcsysKey) {
            const key: SmartModuleAcmiKey = k;
            smartModuleAcmiInfo
              ?.find((item) => item.id === k)
              ?.data.push(Helper.nullTo(props.data[dataIdx][key], null));
          }
          dataIdx += 1;
        } else {
          for (const k of smartModuleAcsysKey) {
            smartModuleAcmiInfo?.find((item) => item.id === k)?.data.push(null);
          }
        }
      }

      return smartModuleAcmiInfo;
    }

    return {
      state,
      yAxis,
    };
  },
});
