import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "agree_wrap" }
const _hoisted_2 = { class: "cont_box ty_agree" }
const _hoisted_3 = {
  key: 0,
  class: "tit_ty_4"
}
const _hoisted_4 = { class: "agree_check" }
const _hoisted_5 = { class: "fm_chk" }
const _hoisted_6 = { class: "in" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isTermsTitle)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.$t(`${_ctx.termsTitleKey}`, { defaultValue: _ctx.termsTitleKey })), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isAgreement) = $event)),
            type: "checkbox",
            name: "termsOfService",
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChangeAgreement && _ctx.onChangeAgreement(...args)))
          }, null, 544), [
            [_vModelCheckbox, _ctx.isAgreement]
          ]),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t(`${_ctx.agreementTitleKey}`, { defaultValue: _ctx.agreementTitleKey })), 1)
        ])
      ]),
      _createElementVNode("div", {
        class: "agree_text",
        innerHTML: _ctx.termsContents
      }, null, 8, _hoisted_7)
    ])
  ]))
}