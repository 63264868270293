import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3be1f836"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "image-container",
    style: _normalizeStyle(`width: ${_ctx.imageSize.width}px; height: ${_ctx.imageSize.height}px; cursor: pointer`),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onShowInfo && _ctx.onShowInfo(...args)))
  }, [
    _createElementVNode("img", {
      src: require(`@hems/component/resources/images/${_ctx.src}.${_ctx.extension}`),
      width: _ctx.imageSize.width,
      height: _ctx.imageSize.height
    }, null, 8, _hoisted_1)
  ], 4))
}