
import { defineComponent, ref, PropType, watch, reactive } from 'vue';
import PopupWrapper from './PopupWrapper.vue';
import LoadingDots from '../process/LoadingDots.vue';

export default defineComponent({
  name: 'LoadingPopup',
  components: {
    PopupWrapper,
    LoadingDots,
  },
  props: {
    message: {
      type: Object as PropType<string | string[]>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    subMessage: {
      type: String,
      default: '',
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['retry', 'close'],
  setup(props, { emit }) {
    const state = reactive({
      isLoading: props.isLoading,
      isSuccess: props.isSuccess,
    });
    const defaultText = joinMessage(props.message);
    const text = ref(defaultText);

    function retry(message: string) {
      text.value = message;
      state.isLoading = true;
      emit('retry');
    }

    function onClose() {
      emit('close');
    }

    function joinMessage(message: string[] | string): string {
      if (Array.isArray(message)) {
        return `<p>${message.join('<br/>')}</p>`;
      }
      return message;
    }

    watch(
      () => props.message,
      (message) => {
        text.value = joinMessage(message);
      }
    );

    watch(
      () => props.isLoading,
      () => {
        state.isLoading = props.isLoading;
      }
    );

    watch(
      () => props.isSuccess,
      () => {
        if (props.isSuccess) {
          state.isSuccess = true;
        }
      }
    );

    return {
      state,
      text,
      retry,
      joinedMessage: joinMessage(props.message),
      onClose,
    };
  },
});
