/**  Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type * as Highcharts from 'highcharts';

import { getFormattedNumberValue } from '@hems/container/src/energyflow/value/EnergyValueFunctions';
import { GRAPH_SERIES_TYPE, HOME_2_COLOR } from '@hems/util/src/constant';

import type { IntlFormatLocale } from 'hems';

import type { TodayEnergyChartData } from 'hems/report';

const xLabels = ['6AM', '9AM', '12PM', '3PM', '6PM', '9PM'];

const DEFAULT_CHART_HEIGHT = 130;

interface TodayEnergyChartArguments {
  locale: IntlFormatLocale;
  data: TodayEnergyChartData[];
  name?: string;
  height?: number;
}

export const getTodayProductionChartOptions = ({
  locale,
  data,
  name = '',
  height = DEFAULT_CHART_HEIGHT,
}: TodayEnergyChartArguments): Highcharts.Options => {
  return {
    chart: {
      type: GRAPH_SERIES_TYPE.LINE,
      height: height,
      zoomType: 'x',
    },
    rangeSelector: {
      enabled: false,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      visible: false,
    },
    xAxis: {
      categories: xLabels,
    },
    tooltip: {
      formatter: function () {
        return getTooltipFormatter(this, locale);
      },
    },
    series: [
      {
        type: GRAPH_SERIES_TYPE.LINE,
        name: name,
        color: HOME_2_COLOR.PRIMARY_MINT,
        marker: {
          radius: 2,
        },
        data: data.map((item) => item.y),
        lineWidth: 1,
      },
    ],
  };
};

export const getTodayConsumptionChartOptions = ({
  locale,
  data,
  name = '',
  height = DEFAULT_CHART_HEIGHT,
}: TodayEnergyChartArguments): Highcharts.Options => {
  return {
    chart: {
      type: GRAPH_SERIES_TYPE.LINE,
      height: height,
      zoomType: 'x',
    },
    rangeSelector: {
      enabled: false,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      visible: false,
    },
    xAxis: {
      categories: xLabels,
    },
    tooltip: {
      formatter: function () {
        return getTooltipFormatter(this, locale);
      },
    },
    series: [
      {
        type: GRAPH_SERIES_TYPE.LINE,
        name: name,
        color: HOME_2_COLOR.PRIMARY_MINT,
        marker: {
          radius: 2,
        },
        data: data.map((item) => item.y),
        lineWidth: 1,
      },
    ],
  };
};

export const checkDateRange = (date: string) => {
  const time = date.substring(8, 10);
  const regexp3hours = /(06|09|12|15|18|21)$/g;

  return regexp3hours.test(time);
};

const getTooltipFormatter = (tooltipObject: Highcharts.TooltipFormatterContextObject, locale: IntlFormatLocale) => {
  return `${tooltipObject.x}</br><span style="color:${
    tooltipObject.point.color
  }>\u25CF</span> ${getFormattedNumberValue(tooltipObject.y, locale, 2)} kWh`;
};
