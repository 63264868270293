import type { AxiosInstance } from 'axios';

import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';

import type { CommonResponseWrapper } from 'hems';

import type { NoticeSearchOptions, NoticeListItem } from 'hems/notice';

export default class NoticeService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getNoticeInfo(noticeId: number): Promise<NoticeListItem> {
    const { data } = await this.get<CommonResponseWrapper<NoticeListItem>>(
      apiWrapper.managerApi('/managements/notice'),
      { noticeId }
    );

    return data;
  }

  public async getNoticeList(params: NoticeSearchOptions): Promise<NoticeListItem[]> {
    const { data } = await this.get<CommonResponseWrapper<NoticeListItem[]>>(
      apiWrapper.managerApi('/managements/notice/list'),
      params
    );

    return data;
  }

  public async createNotice(noticeInfo: Omit<NoticeListItem, 'noticeId'>) {
    const { data } = await this.post<CommonResponseWrapper<NoticeListItem>>(
      apiWrapper.managerApi('/managements/notice'),
      noticeInfo
    );

    return data;
  }

  public async deleteNotice(noticeId: number): Promise<{ noticeId: number }> {
    const { data } = await this.delete<CommonResponseWrapper<{ noticeId: number }>>(
      apiWrapper.managerApi('/managements/notice/delete'),
      undefined,
      { data: { noticeId } }
    );

    return data;
  }

  public async updateNotice(noticeInfo: NoticeListItem): Promise<{ noticeId: number }> {
    const { data } = await this.put<CommonResponseWrapper<{ noticeId: number }>>(
      apiWrapper.managerApi('/managements/notice'),
      noticeInfo
    );

    return data;
  }
}
