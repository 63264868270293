import { AppToWebCall, WebToAppCallParams } from 'hems';
import { checkUserAgent } from './smartmoduleHelper';

export function webToApp(params: WebToAppCallParams) {
  if (checkUserAgent() === 'ios') {
    // to Native IOS
    if (window.webToAppCall) window.webToAppCall.postMessage(JSON.stringify(params)); // home ios
    if (window.webkit?.messageHandlers?.webViewMessageHandler) {
      window.webkit.messageHandlers.webViewMessageHandler.postMessage(JSON.stringify(params)); // pro ios
    } else {
      if (window.webkit) {
        console.log('[WEB] webkit : ' + window.webkit);
        console.log('[WEB] messageHandlers : ' + window.webkit.messageHandlers);
      }
    }
  } else {
    // to Android
    if (window.webToAppCall) window.webToAppCall.postMessage(JSON.stringify(params)); // home android
    if (window.webToProAppCall) window.webToProAppCall.postMessage(JSON.stringify(params)); // pro android
  }
}

export function addEventListener<T extends AppToWebCall, K extends keyof AppToWebCall>(name: K, fn: T[K]) {
  if (!window.appToWebCall) {
    window.appToWebCall = {};
  }

  if (!window.appToWebCall[name]) {
    window.appToWebCall[name] = fn;
  }
}

export function removeEventListener(name: keyof AppToWebCall) {
  if (!window.appToWebCall) return;
  if (!window.appToWebCall[name]) return;

  delete window.appToWebCall[name];
}
