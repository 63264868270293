import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, vModelDynamic as _vModelDynamic, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c67b6432"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fm_wrap" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["type", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_ErrorMessageLabel = _resolveComponent("ErrorMessageLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["label", { required: _ctx.required }])
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.tooltipText)
            ? (_openBlock(), _createBlock(_component_Tooltip, {
                key: 0,
                "tooltip-position": ['top'],
                "tooltip-text": _ctx.tooltipText,
                "image-base-url": "svg",
                "image-name": "warning_circle",
                "image-extension": "svg"
              }, null, 8, ["tooltip-text"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["selecter_2", { [_ctx.className]: true, error: _ctx.validClass === 'ok' ? false : _ctx.errorMessage ? true : false }])
    }, [
      _createElementVNode("div", {
        ref: "selector",
        class: _normalizeClass(["selecter", { focus: _ctx.state.focus, open: _ctx.state.open, closed: !_ctx.state.open, disabled: _ctx.disabled }]),
        tabindex: "0"
      }, [
        _createElementVNode("span", {
          class: "selecter-selected",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickSelect && _ctx.onClickSelect(...args))),
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onFocus(true))),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFocus(false)))
        }, _toDisplayString(_ctx.selectedText), 33),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.isDescriptionProvided()),
          style: _normalizeStyle({ display: _ctx.state.open ? 'block' : 'none' })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.options, (option, optionIndex) => {
            return (_openBlock(), _createElementBlock("span", {
              key: optionIndex,
              class: _normalizeClass(["selecter-item", {
              selected: _ctx.isDisplaySelectedInList ? _ctx.inputValue === option.value : false,
              disabled: option.disabled,
            }]),
              onClick: ($event: any) => (_ctx.onClickOption(option))
            }, [
              _createTextVNode(_toDisplayString(option.text) + " ", 1),
              _withDirectives((_openBlock(), _createElementBlock("span", {
                key: optionIndex,
                class: "selecter-item description"
              }, _toDisplayString(option.description), 513)), [
                [_vShow, option.description]
              ])
            ], 10, _hoisted_2))
          }), 128))
        ], 6)
      ], 2)
    ], 2),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inputValue) = $event)),
      type: _ctx.valueType,
      name: _ctx.name,
      style: {"display":"none"}
    }, null, 8, _hoisted_3), [
      [_vModelDynamic, _ctx.inputValue]
    ]),
    _createVNode(_component_ErrorMessageLabel, {
      "error-message": _ctx.validClass === 'ok' ? '' : _ctx.errorMessage
    }, null, 8, ["error-message"])
  ]))
}