import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb260d52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "value_container top" }
const _hoisted_2 = { class: "value_container bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnergyFlowHomeLayer = _resolveComponent("EnergyFlowHomeLayer")!
  const _component_EnergyFlowPipeLayer = _resolveComponent("EnergyFlowPipeLayer")!
  const _component_EnergyFlow = _resolveComponent("EnergyFlow")!
  const _component_EnergyFlowProductLayer = _resolveComponent("EnergyFlowProductLayer")!
  const _component_NetworkStatus = _resolveComponent("NetworkStatus")!
  const _component_ProductionInfo = _resolveComponent("ProductionInfo")!
  const _component_OperationModeInfo = _resolveComponent("OperationModeInfo")!
  const _component_GridInfo = _resolveComponent("GridInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_EnergyFlowHomeLayer),
    _createVNode(_component_EnergyFlowPipeLayer, { "is-on-grid": _ctx.isOnGrid }, null, 8, ["is-on-grid"]),
    _createVNode(_component_EnergyFlow, { "energy-flow": _ctx.energyFlow }, null, 8, ["energy-flow"]),
    _createVNode(_component_EnergyFlowProductLayer),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NetworkStatus, {
        "network-status": _ctx.realtimeData.network_type,
        disconnect: _ctx.disconnect
      }, null, 8, ["network-status", "disconnect"]),
      _createVNode(_component_ProductionInfo, {
        "formatted-data": _ctx.formattedData,
        "is-connect-third-party": _ctx.isConnectThirdParty
      }, null, 8, ["formatted-data", "is-connect-third-party"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.genType !== _ctx.GEN_TYPE.GEN2)
        ? (_openBlock(), _createBlock(_component_OperationModeInfo, {
            key: 0,
            "ems-operation-mode": _ctx.realtimeData?.ems_opmode,
            "has-ess": false,
            onOnClickOperationMode: _ctx.popup?.onHomeInfo
          }, null, 8, ["ems-operation-mode", "onOnClickOperationMode"]))
        : _createCommentVNode("", true),
      _createVNode(_component_GridInfo, { "formatted-data": _ctx.formattedData }, null, 8, ["formatted-data"])
    ])
  ], 64))
}