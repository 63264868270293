
import { computed, defineComponent, onBeforeUnmount, ref, watch } from 'vue';
import { useStore } from 'vuex';

import gsap from 'gsap';

import { NUMBER_UNIT } from '@hems/util/src/constant';

import { RootState } from '@/store';

import { Timer } from 'hems';

export default defineComponent({
  name: 'BackgroundImage',
  props: {
    isPause: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore<RootState>();
    const bgArea = ref<null | HTMLDivElement>(null);
    const bgImgNum = computed(() => store.state.appCtx.bgImageNum);
    const isPause = computed(() => props.isPause);

    // TODO: 타입 지정 제거 검토
    const animate = () => {
      gsap
        .to([bgArea.value] as gsap.TweenTarget, {
          scale: 1,
          duration: 0.15,
          filter: 'brightness(130%)',
        })
        .then(() => {
          gsap.to([bgArea.value] as gsap.TweenTarget, {
            scale: 1,
            duration: 3,
            filter: 'brightness(100%)',
          });
        });
    };

    let interval: Timer;
    const resetInterval = () => {
      if (interval) clearInterval(interval);
      interval = setInterval(() => {
        if (!isPause.value) store.dispatch('appCtx/setBgImageNum', (bgImgNum.value % 3) + 1);
      }, NUMBER_UNIT.TEN_THOUSAND);
    };

    onBeforeUnmount(() => {
      clearInterval(interval);
    });

    resetInterval();

    watch(
      () => bgImgNum.value,
      () => {
        animate();
      }
    );

    return {
      bgArea,
      bgImgNum,
    };
  },
});
