
import { defineComponent, onBeforeUnmount, onMounted, reactive } from 'vue';

export default defineComponent({
  name: 'ScrollTop',
  setup() {
    const state = reactive({
      visible: false,
    });

    function onClick() {
      window.scrollTo({ top: 0 });
    }

    function handlerScroll() {
      if (window.pageYOffset > window.innerHeight / 2) {
        state.visible = true;
      } else {
        state.visible = false;
      }
    }

    onMounted(() => {
      window.addEventListener('scroll', handlerScroll);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handlerScroll);
    });
    return {
      state,
      onClick,
    };
  },
});
