export function getWeatherIcon(): Record<number, string> {
  return {
    0: 'ic_tornado_weather', // 'Tornado'
    1: 'ic_storm_weather', // 'Tropical Storm'
    2: 'ic_storm_weather', // 'Hurricane',
    3: 'ic_thunderstorms_weather', // 'Strong Storms',
    4: 'ic_thunderstorms_weather', // 'Thunderstorms',
    5: 'ic_rain_snow_weather', // 'Rain / Snow',
    6: 'ic_sleet_weather', // 'Rain / Sleet',
    7: 'ic_rain_snow_weather', // 'Wintry Mix',
    8: 'ic_sleet_weather', // 'Freezing Drizzle',
    9: 'ic_rain_light_weather', // 'Drizzle',
    10: 'ic_sleet_weather', // 'Freezing Rain',
    11: 'ic_rain_weather', // 'Showers',
    12: 'ic_rain_weather', // 'Rain',
    13: 'ic_snow_weather', // 'Flurries',
    14: 'ic_snow_weather', // 'Snow Showers',
    15: 'ic_snow_wind_weather', // 'Blowing / Drifting Snow',
    16: 'ic_snow_weather', // 'Snow',
    17: 'ic_hail_weather', // 'Hail',
    18: 'ic_sleet_weather', // 'Sleet',
    19: 'ic_sandstorm_weather', // 'Blowing Dust / Sandstorm',
    20: 'ic_fog_weather', // 'Foggy',
    21: 'ic_fog_weather', // 'Haze',
    22: 'ic_fog_weather', // 'Smoke',
    23: 'ic_wind_weather', // 'Breezy',
    24: 'ic_wind_weather', // 'Windy',
    25: 'ic_snow_wind_weather', // 'Frigid / Ice Crystals',
    26: 'ic_cloudy_weather', // 'Cloudy',
    27: 'ic_mostly_cloudy_weather', // 'Mostly Cloudy', // Night
    28: 'ic_cloudy_day_weather', // 'Mostly Cloudy', // Day
    29: 'ic_cloudy_night_weather', // 'Partly Cloudy', // Night
    30: 'ic_cloudy_day_weather', // 'Partly Cloudy', // Day
    31: 'ic_night_weather', // 'Clear',
    32: 'ic_day_weather', // 'Sunny',
    33: 'ic_night_weather', // 'Fair / Mostly Clear',
    34: 'ic_day_weather', // 'Fair / Mostly Sunny',
    35: 'ic_rain_hail_weather', // 'Mixed Rain and Hail',
    36: 'ic_day_weather', // 'Hot',
    37: 'ic_isolated_thunderstorms_weather', // 'Isolated Thunderstorms',
    38: 'ic_isolated_thunderstorms_weather', // 'Scattered Thunderstorms',
    39: 'ic_rain_light_weather', // 'Scattered Showers',
    40: 'ic_rain_heavy_weather', // 'Heavy Rain',
    41: 'ic_snow_weather', // 'Scattered Snow Showers',
    42: 'ic_snow_heavy_weather', // 'Heavy Snow',
    43: 'ic_snow_heavy_weather', // 'Blizzard',
    44: 'ic_error_weather', // 'Not Available (N/A)',
    45: 'ic_rain_light_weather', // 'Scattered Showers',
    46: 'ic_snow_heavy_weather', // 'Scattered Snow Showers',
    47: 'ic_thunderstorms_weather', // 'Scattered Thunderstorms',
  };
}
