
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import { Tooltip } from '@hems/component';

import type { StableDeviceGroupInfo } from 'hems';

import type { GroupMode } from 'hems/group';
export default defineComponent({
  name: 'StableInfoItem',
  components: { Tooltip },
  props: {
    title: {
      type: String,
      required: true,
    },
    caption: {
      type: String,
      default: '',
    },
    stableData: {
      type: Object as PropType<StableDeviceGroupInfo>,
      default: null,
    },
    mode: {
      type: String as PropType<GroupMode>,
      required: true,
    },
    titleWidth: {
      type: String,
      default: '100%',
    },
  },
});
