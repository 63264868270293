import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-813a9392"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "module-grid-containers" }
const _hoisted_2 = ["column"]
const _hoisted_3 = { class: "module-device-id" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (column, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        key: idx,
        class: "module-col"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column, (item, idx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: idx,
            class: "module-item",
            column: item
          }, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(item.device_id), 1)
          ], 8, _hoisted_2))
        }), 128))
      ]))
    }), 128))
  ]))
}