import Cookies from 'js-cookie';

import type { Role } from 'hems';

const RoleMap: Readonly<Record<string, Role>> = Object.freeze({
  '10': 'user',
  '20': 'installer',
  '30': 'operator',
  '40': 'utility',
  '50': 'cocompany',
  '60': 'dev',
  '70': 'service',
  '90': 'admin',
  '80': 'retailer',
});

export function getRoleNm(roleCd: string) {
  return RoleMap[roleCd];
}

export function parseJwt(jwt?: string): Record<string, string> | undefined {
  if (!jwt) return;
  try {
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    return;
  }
}

const TOKEN_KEY = 'access_token';

export const saveAccessToken = (token: string): void => {
  Cookies.set(TOKEN_KEY, encodeURIComponent(token), {
    path: '/',
    sameSite: 'strict',
  });
};

export const getAccessToken = (): string | undefined => Cookies.get(TOKEN_KEY);

export const removeAccessToken = (): void => {
  Cookies.remove(TOKEN_KEY, {
    path: '/',
  });
};

export function isExpired(jwtObject: Record<string, string>) {
  const expiredDate = new Date(Number(jwtObject.exp) * 1000);
  const now = new Date();

  return now > expiredDate;
}

export function getRoleCdByRoleName(roleNm: string) {
  return Object.keys(RoleMap).find((roleCd: string) => RoleMap[roleCd] === roleNm);
}
