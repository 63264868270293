import type Highcharts from 'highcharts';

import type { i18nTranslation } from 'hems';

export type SmartModuleAcmiKey = 'ac_voltage' | 'dc_voltage' | 'dc_current' | 'watt' | 'energy_accum';
export const smartModuleAcsysKey: SmartModuleAcmiKey[] = [
  'ac_voltage',
  'dc_voltage',
  'dc_current',
  'watt',
  'energy_accum',
];

export interface SmartModuleAcsysSeries {
  id: string;
  name: string;
  data: (number | null)[];
  type?: string;
  yAxis: 'ac_voltage' | 'dc_voltage' | 'dc_current' | 'watt' | 'energy_accum';
}

export const smartmoduleAcmiConfig = {
  filterList: [
    {
      code: 'ac_voltage',
      name: 'device.ac_voltage',
      altName: 'AC Voltage',
      unit: 'V',
      type: 'spline',
      yAxis: 'ac_voltage',
    },
    {
      code: 'dc_voltage',
      name: 'device.dc_voltage',
      altName: 'DC Voltage',
      unit: 'V',
      type: 'spline',
      yAxis: 'dc_voltage',
    },
    {
      code: 'dc_current',
      name: 'device.dc_current',
      altName: 'DC Current',
      unit: 'A',
      type: 'spline',
      yAxis: 'dc_current',
    },
    {
      code: 'watt',
      name: 'device.ac_power_produced',
      altName: 'AC Power Produced',
      unit: 'W',
      type: 'spline',
      yAxis: 'watt',
    },
    {
      code: 'energy_accum',
      name: 'device.energy_produced',
      altName: 'Energy Produced',
      unit: 'Wh',
      type: 'column',
      yAxis: 'energy_accum',
    },
  ],
};

export function getSmartModuleACSystemChartYAxis(t: i18nTranslation): Highcharts.YAxisOptions[] {
  return [
    {
      id: 'ac_voltage',
      title: {
        text: `${t('device.ac_voltage')} [V]`,
      },
      showEmpty: false,
    },
    {
      id: 'dc_voltage',
      title: {
        text: `${t('device.dc_voltage')} [V]`,
      },
      showEmpty: false,
    },
    {
      id: 'dc_current',
      title: {
        text: `${t('device.dc_current')} [A]`,
      },
      opposite: true,
      showEmpty: false,
    },
    {
      id: 'watt',
      title: {
        text: `${t('device.ac_power_produced')} [W]`,
      },
      opposite: true,
      showEmpty: false,
    },
    {
      id: 'energy_accum',
      title: {
        text: `${t('device.energy_produced')} [Wh]`,
      },
      showEmpty: false,
    },
  ];
}
