
import { useModelWrapper } from '@hems/util';
import { defineComponent, PropType, reactive } from 'vue';

export default defineComponent({
  name: 'GuideTabList',
  props: {
    modelValue: {
      type: String as PropType<string>,
    },
    showList: {
      type: Array as PropType<string[]>,
      required: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      tabname: useModelWrapper(props, emit),
    });

    function switchTab(tabname: string) {
      state.tabname = tabname;
    }

    return { state, switchTab };
  },
});
