<template>
  <div v-if="isSupported">
    <button class="btn_t btn_c_2" @click="copy(state.copyData)">
      <span>{{ copied ? 'Copied!' : 'Copy' }}</span>
    </button>
  </div>
</template>

<script>
import { useClipboard } from '@vueuse/core';
import { defineComponent, reactive, computed } from 'vue';
export default defineComponent({
  name: 'ClipboardButton',
  props: {
    copyData: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      copyData: computed(() => props.copyData),
    });
    const copiedDuring = 5000; // 5초 동안 Copied! 버튼 유지
    const { copy, copied, isSupported } = useClipboard({ copiedDuring });

    return {
      copy,
      copied,
      isSupported,
      state,
    };
  },
});
</script>

<style scoped></style>
