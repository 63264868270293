/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited. 
*/
import type { ValueType } from 'hems/common/utils';

/** 기본 컬러 */
export const DEFAULT_COLOR = {
  PRIMARY_BLUE: '#148cd7',
  PRIMARY_BLUE_LIGHT: '#00adef',
  BLUE_LIGHTER: '#a9cee6',
  BLUE_DARK: '#142845',

  SECONDARY_MINT: '#15dbb7',

  GRAY: '#9c9c9c',
  GRAY_LIGHT: '#e5e5e5',
  GRAY_LIGHTER: '#f5f5f5',
  GRAY_DARK: '#6d6d6d',
  GRAY_DARKER: '#4b4b4b',

  BLACK: '#292929',

  SUCCESS: '#00adef',
  WARNING: '#ffde68',
  ERROR: '#f06280',
  OFFLINE: '8b8b8b',
  INCOMPLETE: '#ff8c38',
};

/** Q.HOME 2.0 컬러 */
export const HOME_2_COLOR = {
  PRIMARY_MINT: '#00c6c1',
  PRIMARY_BLUE: '#0095d6',
  ERROR: '#fd2f40',

  MINT: '#00c6c1',
  SKY_BLUE: '#0095d6',
  GREEN: '#60d483',
  LIGHT_GREEN: '#cae223',
  ORANGE: '#f16b54',
  YELLOW: '#ffda00',
  BLUE: '#5d60ff',
  PURPLE: '#9c73ff',
  TANGERINE: '#ff981d',
  MAGENTA: '#d14ed8',
  GRAY: '#8b8b8b',
  LIGHT_GRAY: '#d8d8d8',

  BLACK: '#0a0a0a',
  BLACK_BACKGROUND: '#191919',
  WHITE: '#ffffff',
  WHITE_BACKGROUND: '#fafafa',
};

/** Q.PRO 2.0 컬러 */
export const PRO_2_COLOR = {
  PRIMARY_MINT: '#00c6c1',
  PRIMARY_BLUE: '#0095d6',

  MINT: '#00c6c1',
  SKY_BLUE: '#0095d6',
  TANGERINE: '#fd881c',
};

/** Highchart Series 컬러 */
export const HIGHCHART_SERIES_COLOR = {
  SKY_BLUE: '#7cb5ec',
  DARK_GRAY: '#434348',
  LIGHT_GREEN: '#90ed7d',
  ORANGE: '#f7a35c',
  PURPLE: '#8085e9',
  MAGENTA: '#f15c80',
  YELLOW: '#e4d354',
  GREEN: '#2b908f',
  RED: '#f45b5b',
  MINT: '#91e8e1',
  BEIGE: '#ddd3af',
} as const;

export type HighchartSeriesColorValue = ValueType<typeof HIGHCHART_SERIES_COLOR>;

export const HIGHCHART_DIVIDING_LINE_COLOR = '#8c8c8c';

/** Mapper 컬러 */
export const AC_COMBINER_BG_COLOR = {
  IMAGE_BG_COLOR: '#8b8b8b',
  IMAGE_LIGHT_BG_COLOR: '#d9d9d9',
  POWER_BG_COLOR: '#505050',
  POWER_LIGHT_BG_COLOR: '#c7c7c7',
  SEQ_BG_COLOR: 'black',
  SEQ_LIGHT_BG_COLOR: '#bbbbbb',
  COUNT_BOX_BG_COLOR: '#f0f0f0',
  COUNT_BOX_LIGHT_BG_COLOR: '#f7f7f7',
} as const;

export const ARRAY_EDIT_MODULE_COLOR = {
  SHOW_BORDER: '#00c6c1',
  SHOW_FILL: '#505050',
  HIDE_BORDER: '#f0f0f0',
  HIDE_FILL: '#f6f6f6',
} as const;

export const LIST_MATCH_MODULE_COLOR = {
  SELECT_TEXT: '#00c6c1',
  SELECT_BORDER: '#00c6c1',
  SELECT_FILL: '#eef8f7',
} as const;

export const COMMON_MODULE_COLOR = {
  TEXT_WHITE: '#fafafa',
  DEFAULT_BORDER: '#fafafa',
  DEFAULT_FILL: '#505050',
  SELECT_BORDER: '#00c6c1',
  SELECT_FILL: '#8edfdc',
  RECT_BORDER: '#f0f0f0',
} as const;

export const TEXTBOX_COLOR = {
  SELECT_CURSOR: '#00c6c1',
  TEXT_GRAY: '#8b8b8b',
  TEXT_BLACK: '#0a0a0a',
} as const;
