
import type { PropType } from 'vue';
import { defineComponent, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import _ from 'lodash';
import { Form } from 'vee-validate';
import * as yup from 'yup';

import {
  Accordion,
  BasicInput,
  ToggleInput,
  Selector,
  SettingTable,
  SettingTableRow,
  RadioGroup,
  useMessageBox,
  ChangedMark,
} from '@hems/component';
import {
  getEditableSettingConfig,
  getSchema,
  installSettingGen3Config,
  getIncludedSettingConfig,
  getSettingValue,
  getRangeDescription,
} from '@hems/container/src/forms/device/settings/_shared/advanced/advancedSettingsUtils';
import { getGen3Config } from '@hems/container/src/forms/device/settings/_shared/advanced/configFunctionsGen3';
import SettingConfirmPopup from '@hems/container/src/forms/device/settings/_shared/SettingConfirmPopup.vue';
import { DeviceServiceInstaller, CommonService } from '@hems/service';
import { Helper, SettingHelper } from '@hems/util';
import { GEN_TYPE } from '@hems/util/src/constant';
import type { AxiosErrorException } from '@hems/util/src/exception/exception';
import { getBasicSettingsColumns, getEngineerSettingsColumns } from '@hems/util/src/helper/settingHelper';

import type { Role, SelectorOption, SelectorValue } from 'hems';

import type { SettingConfigGroup } from 'hems/common/settingConfig';
import type { GridCodeInfoResponse, GridCodeInfo } from 'hems/device';
import type { SettingGroup } from 'hems/device/settings';
import type { Gen3 } from 'hems/device/settings/pvmodule';
import type { AdvancedSettingsData, AdvancedSettingsKey } from 'hems/device/settings/pvmodule/gen3';

type AdvancedSettings = Gen3.AdvancedSettings;
type BasicSettings = Gen3.BasicSettings;
type GridCode = Gen3.GridCode;

export default defineComponent({
  name: 'AdvancedSettingsGEN3',
  components: {
    Selector,
    Accordion,
    BasicInput,
    ToggleInput,
    Form,
    SettingTable,
    SettingTableRow,
    RadioGroup,
    ChangedMark,
    SettingConfirmPopup,
  },
  props: {
    productModelNm: {
      type: String,
      required: true,
    },
    roleNm: {
      type: String as PropType<Role>,
      required: true,
    },
    cntryCd: {
      type: String,
      required: true,
    },
    config: {
      type: Array as PropType<SettingConfigGroup<AdvancedSettingsKey>[]>,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    showEditableColumnsOnly: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object as PropType<Partial<AdvancedSettings>>,
      required: true,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    isConnection: Boolean,
    changedValueSet: {
      type: Object as PropType<Set<string>>,
      default: () => new Set<string>(),
    },
    groupBasicSettingInfo: {
      type: Object as PropType<Partial<BasicSettings>>,
      default: null,
    },
  },
  emits: ['goToList', 'save', 'cancel', 'edit'],
  async setup(props, { emit }) {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    let copyData: Partial<AdvancedSettings> = _.cloneDeep(props.data);
    let copyChangedValueSet: Set<string> = _.cloneDeep(props.changedValueSet);
    const deviceService = new DeviceServiceInstaller(window.axiosInstance.axios);
    const commonService = new CommonService(window.axiosInstance.axios);
    let gridCodeInfo: GridCodeInfoResponse;

    // 수정 가능한 컬럼 추출을 위한 Object
    const editableColumnsByGridCode = {} as Record<number, string[]>;

    const state = reactive<{
      isInit: boolean;
      expandAll: boolean;
      editable: boolean;
      advancedSettingConfig: SettingConfigGroup<AdvancedSettingsKey>[];
      gridCodeOptions: SelectorOption[];
      gridCodeInfoList: GridCode[];
      editableCodes: string[];
      excludedColumns: string[];
      schema: yup.AnySchema;
      data: Partial<AdvancedSettings>;
      countryCode: string;
      genType: string | null;
      changedValueSet: Set<string>;
      confirmPopup: { on: boolean; data: SettingGroup[] };
    }>({
      isInit: false,
      expandAll: true,
      editable: props.editable,
      advancedSettingConfig: props.config,
      gridCodeOptions: [],
      gridCodeInfoList: [],
      editableCodes: [],
      excludedColumns: [],
      schema: yup.object().shape({}),
      data: _.cloneDeep(props.data),
      countryCode: props.cntryCd,
      genType: null,
      changedValueSet: _.cloneDeep(props.changedValueSet),
      confirmPopup: { on: false, data: [] },
    });

    const selectorOptionsState = reactive<{
      energyPolicy: SelectorOption[];
      extctlGwConnection: SelectorOption[];
    }>({
      energyPolicy: [],
      extctlGwConnection: [],
    });

    watch(
      () => props.data,
      () => {
        copyData = _.cloneDeep(props.data);
        state.data = _.cloneDeep(props.data);
      }
    );

    state.genType = await commonService.getBaseGenType(props.productModelNm);

    // Grid 코드 정보
    state.gridCodeInfoList = await deviceService
      .getGridCodeGen3({
        productModelNm: props.productModelNm,
        cntryCd: props.cntryCd === undefined ? '' : props.cntryCd,
      })
      .then((response: GridCodeInfoResponse): GridCodeInfo[] => {
        gridCodeInfo = response;

        return response.default_values;
      })
      .catch((e: AxiosErrorException) => {
        console.error(e);

        return [];
      });

    state.gridCodeInfoList.forEach((item) => {
      editableColumnsByGridCode[item.grid_code] = item.column_names
        ?.split(',')
        .map((item) => item.trim().toLowerCase());

      if (
        !item.grid_code.toString().includes('826') &&
        (state.genType === 'GEN3' || state.genType === 'FOX_ESS' || state.genType === 'FOX_ESS_H3')
      ) {
        state.excludedColumns = ['cls_export_limit', 'cls_import_limit'];
      }

      if (state.genType === 'GEN3' || state.genType === 'FOX_ESS' || state.genType === 'FOX_ESS_H3') {
        editableColumnsByGridCode[item.grid_code] = [
          ...(editableColumnsByGridCode[item.grid_code] ?? []),
          ...SettingHelper.getInstallSettingColumns(),
        ];
      }
    });

    // Selector Option 변환
    state.gridCodeOptions = state.gridCodeInfoList.map((item) => ({
      text: item.profile_name,
      value: item.grid_code,
    }));

    state.gridCodeOptions = Helper.addSelectorOptionAtFirst(state.gridCodeOptions, {
      text: t('common.select'),
      value: null,
    });

    function init() {
      if (props.editable) {
        onEdit();
      } else {
        refreshForm();
      }
      state.isInit = true;
    }

    // eslint-disable-next-line complexity
    function refreshForm(data?: Partial<AdvancedSettings>) {
      if (data) state.data = data;
      const editableColumnNames = state.data.grid_code ? editableColumnsByGridCode[state.data.grid_code] : [];

      if (props.showEditableColumnsOnly && editableColumnNames === undefined) {
        state.advancedSettingConfig = [];
        state.schema = yup.object().shape({});

        return;
      }

      // Grid Code 별 Config 가져오기
      let tempConfig = props.config;

      if (
        state.genType === GEN_TYPE.GEN3 ||
        state.genType === GEN_TYPE.FOX_ESS ||
        state.genType === GEN_TYPE.FOX_ESS_H3
      ) {
        tempConfig = getGen3Config(props.config, gridCodeInfo.column_range, state.data?.grid_code);
      }

      const config =
        ['dev'].includes(props.roleNm ?? '') ||
        (['installer', 'admin', 'service'].includes(props.roleNm ?? '') && !state.editable)
          ? getIncludedSettingConfig(tempConfig, state.excludedColumns)
          : ['installer', 'admin', 'service'].includes(props.roleNm ?? '') && state.editable
          ? getEditableSettingConfig(tempConfig, editableColumnNames)
          : [];

      const schema = getSchema(config);

      state.advancedSettingConfig = config;
      state.schema = schema;
    }

    function setDefaultByGridCode(gridCode: number) {
      const gridCodeInfo = state.gridCodeInfoList?.find((item) => item.grid_code === gridCode);
      if (!gridCodeInfo) {
        return;
      }
      const defaultValue = { ...state.data, ...getDefaultValueByGridCodeInfo(gridCodeInfo) };
      refreshForm(defaultValue);
    }

    function getDefaultValueByGridCodeInfo(gridCodeInfo: GridCode): AdvancedSettingsData {
      return _.omit(gridCodeInfo, ['profile_name', 'grid_code', 'column_names']);
    }

    // event
    function goToList() {
      emit('goToList');
    }

    function onChangeGridCode(value: SelectorValue) {
      setDefaultByGridCode(Number(value));
    }

    function onEdit() {
      if (!props.isConnection) {
        messageBox.alert(t('message.modify_when_disconnected')).open();

        return;
      }
      copyData = _.cloneDeep(state.data);
      copyChangedValueSet = _.cloneDeep(state.changedValueSet);
      state.editable = true;
      state.expandAll = true;
      refreshForm();
      emit('edit', copyData);
    }

    // eslint-disable-next-line complexity
    function onSave() {
      if (!state.data.grid_code) {
        messageBox.alert('Grid Code is required').open();

        return;
      }

      const editableColumnNames = state.data.grid_code ? editableColumnsByGridCode[state.data.grid_code] : [];

      const combinedData = { ...state.data, ...props.groupBasicSettingInfo };

      const filteredData = Object.fromEntries(
        Object.entries(combinedData).filter(
          ([key, value]) => !Helper.isNull(value) && editableColumnNames?.includes(key)
        )
      );

      const confirmItems = Object.fromEntries(
        Object.entries(filteredData).filter(([key]) => !state.excludedColumns.includes(key))
      );

      let params = [
        ...props.config,
        ...((state.genType === 'GEN3' || state.genType === 'FOX_ESS' || state.genType === 'FOX_ESS_H3') &&
        copyData.grid_code === state.data.grid_code
          ? installSettingGen3Config(t, selectorOptionsState, ['Basic Setting', 'Engineer Setting'])
          : (state.genType === 'GEN3' || state.genType === 'FOX_ESS' || state.genType === 'FOX_ESS_H3') &&
            copyData.grid_code !== state.data.grid_code
          ? installSettingGen3Config(t, selectorOptionsState)
          : []),
      ]
        .map((config) => {
          if (config.role && !_.isEmpty(config.role)) {
            let copyConfig = config;
            if (!copyConfig.role?.includes(props.roleNm)) {
              copyConfig.hide = true;
            }
            config = { ...copyConfig };
          }

          return {
            ...config,
            children: config.children
              .map((item) => {
                return {
                  ...item,
                  value: getSettingValue<AdvancedSettingsKey>(item, confirmItems),
                };
              })
              .filter((item) => !Helper.isNull(item.value)),
          };
        })
        .filter((config) => config.children.length > 0);

      state.confirmPopup = { on: true, data: params };
    }

    function onConfirm() {
      state.confirmPopup.on = false;
      if (copyData.grid_code === state.data.grid_code) {
        state.excludedColumns = [
          ...state.excludedColumns,
          ...getBasicSettingsColumns(),
          ...getEngineerSettingsColumns(),
        ];
      }
      emit(
        'save',
        _.omit({ ...state.data, ...props.groupBasicSettingInfo }, state.excludedColumns),
        state.changedValueSet,
        function (isOk: boolean) {
          if (isOk) state.editable = false;
        }
      );
    }

    function onCancel() {
      state.editable = false;
      refreshForm(copyData);
      emit('cancel');
      state.changedValueSet = copyChangedValueSet;
    }

    function onChange(valueKey: string, targetValue?: string | number | boolean) {
      if (state.editable) {
        if (!Helper.isNull(targetValue)) {
          // 변경 항목 key set에 저장
          state.changedValueSet.add(valueKey);
        } else {
          // 변경 항목 key set에서 제거
          state.changedValueSet.delete(valueKey);
        }
      }
    }

    async function loadSelectorOptions() {
      const { ENERGY_POLICY_GEN3_CD, EXTCTL_GW_CONNECTION_GEN3_CD } = await commonService.getCodesByGroupCode([
        { grpCd: 'ENERGY_POLICY_GEN3_CD' },
        { grpCd: 'EXTCTL_GW_CONNECTION_GEN3_CD' },
      ]);
      selectorOptionsState.energyPolicy = Helper.codeNamesToSelectorOptions(ENERGY_POLICY_GEN3_CD, t, {
        text: t('common.select'),
        value: null,
      });

      selectorOptionsState.extctlGwConnection = Helper.codeNamesToSelectorOptions(EXTCTL_GW_CONNECTION_GEN3_CD, t, {
        text: t('common.select'),
        value: null,
      });
    }

    init();
    loadSelectorOptions();

    return {
      state,
      onChangeGridCode,
      goToList,
      onEdit,
      onSave,
      onCancel,
      onChange,
      onConfirm,
      getRangeDescription,
    };
  },
});
