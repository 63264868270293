// Copyright © 2024 Qcells. All rights reserved.
// This software is proprietary and confidential. Unauthorized use,
// duplication, or distribution of software is strictly prohibited.
import { useStore } from 'vuex';

import type { RootStoreAdminInstaller } from 'hems/common/store';

export const useSiteId = () => {
  const store = useStore<RootStoreAdminInstaller>();
  const siteId = store.state.siteNew.siteId;

  return { siteId };
};
