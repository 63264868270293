import { useMessageBox as createMessageBox } from '@hems/component';
import { Messages } from 'hems';
import { i18n } from '..';

export const useMessageBox = () => {
  const messageBox = createMessageBox();

  const { t } = i18n.global;

  const alertMessageBox = (message: Messages) => {
    messageBox.alert(message).open();
  }

  const saveFailMessageBox = () => {
    alertMessageBox(t('message.save_fail'));
  }

  const saveSuccessMessageBox = () => {
    alertMessageBox(t('message.save_success'));
  }

  const confirmMessageBox = async (message: Messages) => {
    const isOK = await messageBox.confirm(message).open();

    return isOK;
  }

  return {
    alertMessageBox,
    saveFailMessageBox,
    saveSuccessMessageBox,
    confirmMessageBox,
  }
}