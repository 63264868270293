import { AxiosInstance } from 'axios';
import {
  SiteId,
  ApplianceItemList,
  ApplianceCommandsParams,
  ApplianceChartParams,
  ApplianceChartResponse,
  ApplianceRefreshTokenResponse,
} from 'hems/device';
import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';
import { CommonResponseWrapper } from 'hems';
export default class HomeApplianceService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getApplianceList(siteId: SiteId): Promise<ApplianceItemList> {
    const { data } = await this.get<CommonResponseWrapper<ApplianceItemList>>(
      apiWrapper.managerApi(`/smart-things/${siteId}`)
    );
    return data;
  }

  public async getApplianceStatus(deviceId: string): Promise<any> {
    return await this.get<any>(`/devices/${deviceId}/status`);
  }

  public async getApplianceInfo(deviceId: string): Promise<any> {
    return await this.get<any>(`/devices/${deviceId}`);
  }

  public async getApplianceHealth(deviceId: string): Promise<any> {
    return await this.get<any>(`/devices/${deviceId}/health`);
  }

  public async getApplianceLocations(): Promise<any> {
    return await this.get<any>(`/locations`);
  }

  public async getApplianceLocation(locationId: string): Promise<any> {
    return await this.get<any>(`/locations/${locationId}`);
  }

  public async getApplianceRooms(locationId: string): Promise<any> {
    return await this.get<any>(`/locations/${locationId}/rooms`);
  }

  public async getApplianceRoom(locationId: string, roomId: string): Promise<any> {
    return await this.get<any>(`/locations/${locationId}/rooms/${roomId}`);
  }

  public async controlAppliance(deviceId: string, params: ApplianceCommandsParams): Promise<any> {
    return await this.post<any>(`/devices/${deviceId}/commands`, params);
  }

  public async refreshSmartThingsToken(token: string): Promise<ApplianceRefreshTokenResponse> {
    const { data } = await this.post<CommonResponseWrapper<ApplianceRefreshTokenResponse>>(
      apiWrapper.managerApi(`/smart-things/tokens/refresh`),
      { token }
    );
    return data;
  }

  public async getApplianceChart(siteId: SiteId, params: ApplianceChartParams): Promise<ApplianceChartResponse> {
    const { data } = await this.get<CommonResponseWrapper<ApplianceChartResponse>>(
      apiWrapper.managerApi(`/smart-things/charts/${siteId}`),
      params
    );
    return data;
  }

  public async getChartByAppliance(
    applianceDeivceId: string,
    params: ApplianceChartParams
  ): Promise<ApplianceChartResponse> {
    const { data } = await this.get<CommonResponseWrapper<ApplianceChartResponse>>(
      apiWrapper.managerApi(`/smart-things/charts/${applianceDeivceId}/powers`),
      params
    );
    return data;
  }
}
