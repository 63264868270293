
import { UserInfoState } from '@/store/modules/user';
import { computed, defineComponent, reactive } from 'vue';
import { useStore } from 'vuex';
import GeneralAccountInfoPopup from './general/GeneralAccountInfoPopup.vue';
import InstallerAccountInfo from './installer/InstallerAccountInfo.vue';
import PrivacyPopup from '@/components/popup/PrivacyPopup.vue';
export default defineComponent({
  name: 'AccountInfoPopup',
  components: {
    GeneralAccountInfoPopup,
    InstallerAccountInfo,
    PrivacyPopup,
  },
  emits: ['close', 'withdrawal'],
  setup() {
    const store = useStore();
    const userInfo = store.state.user as UserInfoState;
    const state = reactive({
      isUser: computed(() => {
        return userInfo.roleNm === 'user' ? true : false;
      }),
      roleNm: computed(() => userInfo.roleNm),
      privacyPopup: false,
    });

    function openPrivacyPopup() {
      state.privacyPopup = true;
    }
    function closePrivacyPopup() {
      state.privacyPopup = false;
    }

    return {
      state,
      openPrivacyPopup,
      closePrivacyPopup,
    };
  },
});
