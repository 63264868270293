
import type { PropType } from 'vue';
import { computed, defineComponent, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import { Calendar, Selector, useMessageBox, LabelInput, Searchbox, HiddenSearchbox } from '@hems/component';
const { VCalendarRange } = Calendar;
import { BasicCheckbox } from '@hems/component';
import { Image } from '@hems/component';
import { CommonService } from '@hems/service';
import { useLocale } from '@hems/util';
import { DATE_UNIT, DAY_RANGE_31 } from '@hems/util/src/constant';
import { DEFAULT_PERIOD } from '@hems/util/src/constant/eventHistory';
import { UnknownErrorException } from '@hems/util/src/exception/exception';
import { getLocalDateFormat, now, getDateDifference } from '@hems/util/src/helper/dateHelper';
import { isNull, codeNamesToSelectorOptions } from '@hems/util/src/helper/helper';

import type { CommonCode, Period } from 'hems';

import type { MakeFieldNonNullable } from 'hems/common/utils';
import type { ErrorHistorySearchBoxOptions } from 'hems/event';

export default defineComponent({
  name: 'EventSearchBox',
  components: {
    Selector,
    VCalendarRange,
    LabelInput,
    HiddenSearchbox,
    Searchbox,
    BasicCheckbox,
    Image,
  },
  props: {
    condition: {
      type: Object as PropType<ErrorHistorySearchBoxOptions>,
      default: (): ErrorHistorySearchBoxOptions => ({
        date: DEFAULT_PERIOD,
        errorLevel: null,
        search: null,
        excludeErrorCodes: null,
      }),
    },
  },
  emits: ['search'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    const store = useStore();
    const { locale, isUS } = useLocale();
    const maxDate = now();
    const errorLevelLabel = computed(() => (isUS ? 'Error Level' : t('common.event_type')));

    /** 전체 에러 코드 리스트 */
    const errorCodeList = computed<string[]>(() => store.state.event.codeList);

    const searchCondition = ref<MakeFieldNonNullable<ErrorHistorySearchBoxOptions, 'date' | 'search'>>({
      ...props.condition,
      date: props.condition.date ?? DEFAULT_PERIOD,
      search: props.condition.search ?? '',
    });
    const filteredErrorCodeList = ref<string[]>([]);
    const searchedErrorCodeList = ref<string[]>([]);
    const searchCode = ref<string>('');

    const commService = new CommonService(window.axiosInstance.axios);

    const errorLevelCode = ref<CommonCode.CodeMap[]>([]);

    const errorLevelOptions = computed(() => [
      { text: t('common.all'), value: '' },
      ...codeNamesToSelectorOptions(errorLevelCode.value, t),
    ]);

    onMounted(() => {
      getCodeGroups();
      getEventAlarmCodes();
    });

    const getCodeGroups = async () => {
      const { ALARM_TYPE_CD } = await commService.getCodesByGroupCode([{ grpCd: 'ALARM_TYPE_CD' }]);
      errorLevelCode.value = ALARM_TYPE_CD;
    };

    const getEventAlarmCodes = async () => {
      // Filtered Alarm 리스트 불러오기 -> wait 따로
      try {
        if (errorCodeList.value.length === 0) {
          await store.dispatch('event/setEventAlarmCodes');
        }
        filteredErrorCodeList.value = errorCodeList.value;
        searchedErrorCodeList.value = errorCodeList.value;
      } catch (e) {
        throw new UnknownErrorException();
      }
    };

    function filterValue() {
      //체크안된값과 전체리스트 비교 후 체크 안된값 필터링 => checkList와 codeList비교
      const excludedErrorCodes = errorCodeList.value.filter(
        (errorCode) => !filteredErrorCodeList.value.includes(errorCode)
      );
      searchCondition.value.excludeErrorCodes = excludedErrorCodes.join(',');
    }

    const onSearchFilter = () => {
      // Filterd Alarm 검색 로직
      // 프론트에서 검색
      searchedErrorCodeList.value = errorCodeList.value;
      searchCondition.value.search = searchCondition.value.search?.trim();

      if (!isNull(searchCode.value)) {
        //listData filter 후, 검색한 데이터(결과값)를 listData에 할당
        searchedErrorCodeList.value = errorCodeList.value.filter((errorCode) => {
          return errorCode.toLowerCase().includes(searchCode.value?.toLowerCase());
        });
      }
    };

    const validateRangeDate = (value: Period) => {
      const dateDifference = getDateDifference(value, DATE_UNIT.DAYS);

      if (dateDifference > DAY_RANGE_31) {
        messageBox.alert(t('message.period_not_exceed')).open();

        return false;
      }

      return true;
    };

    const onSearch = () => {
      searchCondition.value.search = searchCondition.value.search?.trim();
      emit('search', { ...searchCondition.value });
    };

    const placeholder = computed(() => {
      if (isUS) {
        return `${t('message.enter_search_word')} (${t('device.serial_no')}, ${t('device.err_code')}, ${t(
          'common.site_id'
        )})`;
      }

      return `${t('message.enter_search_word')} (${t('device.serial_no')}, ${t('common.event_cd')}, ${t(
        'common.site_id'
      )})`;
    });

    const onChangeEventTypeCode = () => {
      onSearch();
    };

    return {
      locale,
      maxDate,
      errorLevelOptions,
      searchCode,
      searchCondition,
      filteredErrorCodeList,
      searchedErrorCodeList,
      placeholder,
      isUS,
      errorLevelLabel,
      validateRangeDate,
      onSearch,
      onSearchFilter,
      filterValue,
      getEventAlarmCodes,
      getLocalDateFormat,
      onChangeEventTypeCode,
    };
  },
});
