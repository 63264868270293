
import { defineComponent, reactive, watch, onMounted } from 'vue';
import { CONNECTION_STATUS, CONNECTION_STATUS_CODE, DEVICE_STATUS, DEVICE_STATUS_CODE } from '@hems/util/src/constant';

export default defineComponent({
  name: 'MonitoringStatusFilter',
  props: {
    connectionStatus: {
      type: String,
      required: true,
    },
    operateStatusCd: {
      type: String,
      required: true,
    },
  },
  emits: ['updateMonitoringStatusFilters'],
  setup(props, { emit }) {
    const state = reactive({
      connectionFilters: checkInitConnectionStatus(props.connectionStatus, props.operateStatusCd),
      deviceStatusFilters: checkInitDeviceStatus(props.operateStatusCd),
      showDeviceStatusFilters: true,
    });

    const connectionFilterConfig = [
      {
        filterCode: CONNECTION_STATUS.online,
        title: 'device.online',
        code: CONNECTION_STATUS_CODE.online,
      },
      {
        filterCode: CONNECTION_STATUS['online-disconnected'],
        title: 'device.online_disconnected',
        code: CONNECTION_STATUS_CODE.offline,
      },
      {
        filterCode: CONNECTION_STATUS.offline,
        title: 'device.offline',
        code: CONNECTION_STATUS_CODE.offline,
      },
      {
        filterCode: CONNECTION_STATUS.incomplete,
        title: 'common.incomplete',
        code: CONNECTION_STATUS_CODE.incomplete,
      },
    ];

    const deviceStatusFilterConfig = [
      {
        filterCode: DEVICE_STATUS.run,
        title: 'device.run',
        class: { state_prod: 'ty_1' },
        code: DEVICE_STATUS_CODE.run,
      },
      {
        filterCode: DEVICE_STATUS.warning,
        title: 'device.warning',
        class: { state_prod: 'ty_2' },
        code: DEVICE_STATUS_CODE.warning,
      },
      {
        filterCode: DEVICE_STATUS.error,
        title: 'common.error',
        class: { state_prod: 'ty_3' },
        code: DEVICE_STATUS_CODE.error,
      },
      {
        filterCode: DEVICE_STATUS.offline,
        title: 'device.offline',
        class: { state_prod: 'ty_6' },
        code: DEVICE_STATUS_CODE.offline,
      },
      {
        filterCode: DEVICE_STATUS.incomplete,
        title: 'common.incomplete',
        class: { state_prod: 'ty_4' },
        code: DEVICE_STATUS_CODE.incomplete,
      },
    ];

    function checkInitConnectionStatus(connection: string, device: string) {
      const connectionFilters = new Set<keyof typeof CONNECTION_STATUS>([]);
      const connectionStatus = connection.split(',');
      const deviceStatus = device.split(',');
      if (connectionStatus.includes(CONNECTION_STATUS_CODE.offline)) {
        if (
          deviceStatus.includes(DEVICE_STATUS_CODE.run) ||
          deviceStatus.includes(DEVICE_STATUS_CODE.warning) ||
          deviceStatus.includes(DEVICE_STATUS_CODE.error)
        ) {
          connectionFilters.add(CONNECTION_STATUS['online-disconnected']);
        }
        if (connectionStatus.includes(DEVICE_STATUS_CODE.offline)) {
          connectionFilters.add(CONNECTION_STATUS.offline);
        }
        if (deviceStatus.includes(DEVICE_STATUS_CODE.incomplete)) {
          connectionFilters.add(CONNECTION_STATUS.incomplete);
        }
      }
      if (connectionStatus.includes(CONNECTION_STATUS_CODE.online)) {
        connectionFilters.add(CONNECTION_STATUS.online);
      }

      return connectionFilters;
    }

    function checkInitDeviceStatus(device: string) {
      const deviceStatusFilters = new Set<keyof typeof DEVICE_STATUS>([]);
      const deviceStatus = device.split(',');
      deviceStatus.forEach((status) => {
        switch (status) {
          case DEVICE_STATUS_CODE.run:
            deviceStatusFilters.add(DEVICE_STATUS.run);
            break;
          case DEVICE_STATUS_CODE.warning:
            deviceStatusFilters.add(DEVICE_STATUS.warning);
            break;
          case DEVICE_STATUS_CODE.error:
            deviceStatusFilters.add(DEVICE_STATUS.error);
            break;
          case DEVICE_STATUS_CODE.incomplete:
            deviceStatusFilters.add(DEVICE_STATUS.incomplete);
            break;
          case DEVICE_STATUS_CODE.offline:
            deviceStatusFilters.add(DEVICE_STATUS.offline);
        }
      });
      return deviceStatusFilters;
    }

    function onClickConnectionStatus(connectionStatus: CONNECTION_STATUS) {
      if (state.connectionFilters.has(connectionStatus)) {
        state.connectionFilters.delete(connectionStatus);
      } else {
        state.connectionFilters.add(connectionStatus);
      }
    }

    function onClickDeviceStatus(deviceStatus: DEVICE_STATUS) {
      if (state.deviceStatusFilters.has(deviceStatus)) {
        state.deviceStatusFilters.delete(deviceStatus);
      } else {
        state.deviceStatusFilters.add(deviceStatus);
      }
    }

    function getDeviceStatusByOnlineOrOnlineDisconnected(deviceStatus: Set<keyof typeof DEVICE_STATUS>) {
      return new Set(
        [...deviceStatus]
          .filter((status) => status !== 'offline' && status !== 'incomplete')
          .map((status) => deviceStatusFilterConfig.find((item) => item.filterCode === status)?.code)
      );
    }

    function getDeviceStatusByOfflineOrIncomplete(connection: Set<keyof typeof CONNECTION_STATUS>) {
      let deviceStatusSet = new Set<DEVICE_STATUS_CODE>();
      if (connection.has('offline')) {
        deviceStatusSet.add(DEVICE_STATUS_CODE.offline);
      }
      if (connection.has('incomplete')) {
        deviceStatusSet.add(DEVICE_STATUS_CODE.incomplete);
      }
      return deviceStatusSet;
    }

    watch(
      [() => state.connectionFilters, () => state.deviceStatusFilters],
      ([connection, device]) => {
        let filteredConnectionStatus = new Set<CONNECTION_STATUS_CODE>();
        let filteredDeviceStatus = new Set<DEVICE_STATUS_CODE | undefined>();

        if (connection.has('online') || connection.has('online-disconnected')) {
          if (connection.has('online')) {
            filteredConnectionStatus.add(CONNECTION_STATUS_CODE.online);
          }
          if (connection.has('online-disconnected')) {
            filteredConnectionStatus.add(CONNECTION_STATUS_CODE['online-disconnected']);
          }
          filteredDeviceStatus = new Set([
            ...filteredDeviceStatus,
            ...getDeviceStatusByOnlineOrOnlineDisconnected(device),
          ]);
        }

        if (connection.has('offline') || connection.has('incomplete')) {
          if (connection.has('online-disconnected')) {
            filteredConnectionStatus.add(CONNECTION_STATUS_CODE.offline);
          }
          filteredDeviceStatus = new Set([
            ...filteredDeviceStatus,
            ...getDeviceStatusByOfflineOrIncomplete(connection),
          ]);
        }

        if (!state.connectionFilters.has('online') && !state.connectionFilters.has('online-disconnected')) {
          state.showDeviceStatusFilters = false;
        } else {
          state.showDeviceStatusFilters = true;
        }
        emit(
          'updateMonitoringStatusFilters',
          [...filteredConnectionStatus].join(','),
          [...filteredDeviceStatus].join(',')
        );
      },
      { deep: true }
    );

    onMounted(() => {
      if (
        !state.connectionFilters.has(CONNECTION_STATUS.online) &&
        !state.connectionFilters.has(CONNECTION_STATUS['online-disconnected'])
      ) {
        state.showDeviceStatusFilters = false;
      }
    });

    return {
      state,
      connectionFilterConfig,
      deviceStatusFilterConfig: deviceStatusFilterConfig.filter((item) =>
        ['run', 'warning', 'error'].includes(item.filterCode)
      ),
      onClickConnectionStatus,
      onClickDeviceStatus,
    };
  },
});
