
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Calendar, Selector, useMessageBox, Button } from '@hems/component';
const { VCalendarRange } = Calendar;
import { ErrorHistoryParams, ErrorHistorySearchBoxOptions } from 'hems/event';
import { useLocale, useRole } from '@hems/util';
import { CommonService } from '@hems/service';
import { getLocalDateFormat, now, getDateDifference } from '@hems/util/src/helper/dateHelper';
import { codeNamesToSelectorOptions } from '@hems/util/src/helper/helper';
import { CommonCode, Period } from 'hems';
import { MakeFieldNonNullable } from 'hems/common/utils';
import { DEFAULT_PERIOD, ERROR_LEVEL_SELECTOR_OPTIONS } from '@hems/util/src/constant/eventHistory';
import { DATE_UNIT, DAY_RANGE_31 } from '@hems/util/src/constant';

export default defineComponent({
  name: 'EventBySiteSearchBox',
  components: {
    Selector,
    VCalendarRange,
    Button,
  },
  props: {
    condition: {
      type: Object as PropType<ErrorHistorySearchBoxOptions>,
      default: (): ErrorHistorySearchBoxOptions => ({
        date: DEFAULT_PERIOD,
        errorLevel: null,
      }),
    },
  },
  emits: ['search'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    const searchCondition = ref<MakeFieldNonNullable<Pick<ErrorHistoryParams, 'date' | 'errorLevel'>, 'date'>>({
      ...props.condition,
      date: props.condition.date ?? DEFAULT_PERIOD,
    });
    const { locale, isUS } = useLocale();
    const { isAdminGroup } = useRole();
    const maxDate = now();
    const errorLevelLabel = computed(() => (isUS ? 'Error Level' : t('common.event_type')));

    const commService = new CommonService(window.axiosInstance.axios);

    const errorLevelCode = ref<CommonCode.CodeMap[]>([]);

    const errorLevelOptions = computed(() => {
      const firstItem = { text: t('common.all'), value: '' };
      if (isUS) {
        return [firstItem, ...codeNamesToSelectorOptions(ERROR_LEVEL_SELECTOR_OPTIONS, t)];
      }
      return [firstItem, ...codeNamesToSelectorOptions(errorLevelCode.value, t)];
    });

    onMounted(() => {
      getCodeGroups();
    });

    const getCodeGroups = async () => {
      const { ALARM_TYPE_CD } = await commService.getCodesByGroupCode([{ grpCd: 'ALARM_TYPE_CD' }]);
      errorLevelCode.value = ALARM_TYPE_CD;
    };

    const validateRangeDate = (value: Period) => {
      const dateDifference = getDateDifference(value, DATE_UNIT.DAYS);

      if (dateDifference > DAY_RANGE_31) {
        messageBox.alert(t('message.period_not_exceed')).open();
        return false;
      }

      return true;
    };

    const onClickSearch = () => {
      emit('search', { ...searchCondition.value });
    };

    return {
      searchCondition,
      errorLevelOptions,
      locale,
      maxDate,
      isUS,
      isAdminGroup,
      errorLevelLabel,
      validateRangeDate,
      onClickSearch,
      getLocalDateFormat,
    };
  },
});
