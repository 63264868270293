import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53428450"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "btn_wrap_m"
}
const _hoisted_2 = { class: "input-button-container" }
const _hoisted_3 = { class: "input" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "input-button-container" }
const _hoisted_6 = { class: "input" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "is_default" }
const _hoisted_9 = { class: "is_edit" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  type: "submit",
  class: "btn_m btn_c_3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSettingTab = _resolveComponent("MultiSettingTab")!
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_SettingTableRow = _resolveComponent("SettingTableRow")!
  const _component_SettingTable = _resolveComponent("SettingTable")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_ToggleInput = _resolveComponent("ToggleInput")!
  const _component_ChangedMark = _resolveComponent("ChangedMark")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_Selector = _resolveComponent("Selector")!
  const _component_BaseSlider = _resolveComponent("BaseSlider")!
  const _component_Form = _resolveComponent("Form")!
  const _component_SettingConfirmPopup = _resolveComponent("SettingConfirmPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MultiSettingTab, {
      settingTargetType: _ctx.settingTargetType,
      "onUpdate:settingTargetType": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settingTargetType) = $event)),
      deviceTarget: _ctx.deviceTarget,
      "onUpdate:deviceTarget": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deviceTarget) = $event)),
      "selector-options": _ctx.basicDeviceOption
    }, null, 8, ["settingTargetType", "deviceTarget", "selector-options"]),
    (_ctx.isInit)
      ? (_openBlock(), _createBlock(_component_Form, {
          key: 0,
          "validation-schema": _ctx.schema,
          autocomplete: "off",
          onSubmit: _ctx.onSave
        }, {
          default: _withCtx(({ handleReset }) => [
            _createVNode(_component_Accordion, {
              title: _ctx.$t('control.installer_temp_password'),
              active: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTable, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('control.last_generation_time'),
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.installer_page_temp_password_setting_time,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.data.installer_page_temp_password_setting_time) = $event)),
                          name: "installer_page_temp_password_setting_time",
                          readonly: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  _: 1
                }),
                (!_ctx.state.editable)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn_m btn_c_3",
                        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onGenerate && _ctx.onGenerate(...args)))
                      }, _toDisplayString(_ctx.$t('common.generate')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title"]),
            (['admin', 'dev'].includes(_ctx.roleName ?? ''))
              ? (_openBlock(), _createBlock(_component_Accordion, {
                  key: 0,
                  title: _ctx.$t('common.web_hmi_password'),
                  active: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SettingTable, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_SettingTableRow, {
                          title: _ctx.$t('common.engineer_password'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.web_page_password_engineer,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.data.web_page_password_engineer) = $event)),
                                  name: "web_page_password_engineer",
                                  readonly: _ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              (!_ctx.state.editable)
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    type: "button",
                                    class: "btn_t btn_c_3",
                                    disabled: !_ctx.state.validClass.web_page_password_engineer || _ctx.isNull(_ctx.state.data.web_page_password_engineer),
                                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onChangeWebHMIPassword('Engineer Page')))
                                  }, _toDisplayString(_ctx.$t('common.change')), 9, _hoisted_4))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 1
                        }, 8, ["title"]),
                        _createVNode(_component_SettingTableRow, {
                          title: _ctx.$t('common.service_password'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, [
                              _createElementVNode("div", _hoisted_6, [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.web_page_password_service,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.data.web_page_password_service) = $event)),
                                  name: "web_page_password_service",
                                  readonly: _ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              (!_ctx.state.editable)
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    type: "button",
                                    class: "btn_t btn_c_3",
                                    disabled: !_ctx.state.validClass.web_page_password_service || _ctx.isNull(_ctx.state.data.web_page_password_service),
                                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onChangeWebHMIPassword('Service Page')))
                                  }, _toDisplayString(_ctx.$t('common.change')), 9, _hoisted_7))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 1
                        }, 8, ["title"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Accordion, {
              title: _ctx.$t('device.setting_info'),
              active: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTable, {
                  class: "color-gray basic-tb",
                  "table-title": _ctx.$t('device.grid'),
                  "title-width": _ctx.titleWidth
                }, {
                  default: _withCtx(() => [
                    (_ctx.isBasicSettingField('pv_feed_in_limit_flag') && _ctx.state.enableEditFeedInLimit)
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 0,
                          title: "Feed In Limit",
                          description: `0 ~ 65535 W, 0W : ${_ctx.$t('device.no_feedin')}`,
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "pv_feed_in_limit_flag",
                              "target-value": _ctx.state.data.pv_feed_in_limit_flag,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.pv_feed_in_limit_flag,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.data.pv_feed_in_limit_flag) = $event)),
                                  name: "pv_feed_in_limit_flag",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["description"]))
                      : _createCommentVNode("", true),
                    (
            _ctx.isBasicSettingField('pv_feed_in_limit_w') && _ctx.state.data.pv_feed_in_limit_flag && _ctx.state.enableEditFeedInLimit
          )
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 1,
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "pv_feed_in_limit_w",
                              "target-value": _ctx.state.data.pv_feed_in_limit_w,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.pv_feed_in_limit_w,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.data.pv_feed_in_limit_w) = $event)),
                                  name: "pv_feed_in_limit_w",
                                  type: "number",
                                  min: "0",
                                  max: "65535",
                                  readonly: !_ctx.state.editable || !_ctx.state.enableEditFeedInLimit
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isBasicSettingField('accb_backfeed_limit_flag'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 2,
                          title: "Backfeed Limit",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "accb_backfeed_limit_flag",
                              "target-value": _ctx.state.data.accb_backfeed_limit_flag,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.accb_backfeed_limit_flag,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.data.accb_backfeed_limit_flag) = $event)),
                                  name: "accb_backfeed_limit_flag",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isBasicSettingField('accb_backfeed_limit_option') && _ctx.state.data.accb_backfeed_limit_flag)
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 3,
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "accb_backfeed_limit_option",
                              "target-value": _ctx.state.data.accb_backfeed_limit_option,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_RadioGroup, {
                                  modelValue: _ctx.state.data.accb_backfeed_limit_option,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.state.data.accb_backfeed_limit_option) = $event)),
                                  name: "accb_backfeed_limit_option",
                                  options: [
                { value: 0, text: 'Entered Directly' },
                { value: 1, text: 'With NEC 120% Rule' },
              ],
                                  readonly: !_ctx.state.editable,
                                  "value-type": "number"
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (
            _ctx.isBasicSettingField('accb_backfeed_limit') &&
            _ctx.state.data.accb_backfeed_limit_flag &&
            !_ctx.state.data.accb_backfeed_limit_option
          )
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 4,
                          title: "Backfeed Limit Directly",
                          description: "1 ~ 210 (A)",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "accb_backfeed_limit",
                              "target-value": _ctx.state.data.accb_backfeed_limit,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.accb_backfeed_limit,
                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.state.data.accb_backfeed_limit) = $event)),
                                  name: "accb_backfeed_limit",
                                  type: "number",
                                  min: "1",
                                  max: "210",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (
            _ctx.isBasicSettingField('msp_busbar_rating') &&
            _ctx.state.data.accb_backfeed_limit_flag &&
            _ctx.state.data.accb_backfeed_limit_option
          )
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 5,
                          title: "Main service panel Busbar rating (A)",
                          description: "2 ~ 1000 (A)",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "msp_busbar_rating",
                              "target-value": _ctx.state.data.msp_busbar_rating,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.msp_busbar_rating,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.state.data.msp_busbar_rating) = $event)),
                                  name: "msp_busbar_rating",
                                  type: "number",
                                  min: "2",
                                  max: "1000",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (
            _ctx.isBasicSettingField('msp_breaker_rating') &&
            _ctx.state.data.accb_backfeed_limit_flag &&
            _ctx.state.data.accb_backfeed_limit_option
          )
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 6,
                          title: "Main service Breaker rating (A)",
                          description: "1 ~ 1000 (A)",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "msp_breaker_rating",
                              "target-value": _ctx.state.data.msp_breaker_rating,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.msp_breaker_rating,
                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.state.data.msp_breaker_rating) = $event)),
                                  name: "msp_breaker_rating",
                                  type: "number",
                                  min: "1",
                                  max: "1000",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (
            _ctx.isBasicSettingField('backfeed_limit_nec') &&
            _ctx.state.data.accb_backfeed_limit_flag &&
            _ctx.state.data.accb_backfeed_limit_option
          )
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 7,
                          title: "Backfeed Limit (A)",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.backfeedLimitByNEC,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.backfeedLimitByNEC) = $event)),
                              name: "backfeed_limit_nec",
                              type: "number",
                              readonly: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isBasicSettingField('inverter_multiple_earthed_neutral_system'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 8,
                          title: "Multiple Earthed Neutral(MEN) system",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "inverter_multiple_earthed_neutral_system",
                              "target-value": _ctx.state.data.inverter_multiple_earthed_neutral_system,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.inverter_multiple_earthed_neutral_system,
                                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.state.data.inverter_multiple_earthed_neutral_system) = $event)),
                                  name: "inverter_multiple_earthed_neutral_system",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["table-title", "title-width"]),
                _createVNode(_component_SettingTable, {
                  class: "basic-tb",
                  "table-title": _ctx.$t('control.energy_policy'),
                  "title-width": _ctx.titleWidth
                }, {
                  default: _withCtx(() => [
                    (_ctx.isBasicSettingField('energy_policy'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 0,
                          title: _ctx.$t('control.energy_policy'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "energy_policy",
                              "target-value": _ctx.state.data.energy_policy,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.energy_policy,
                                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.state.data.energy_policy) = $event)),
                                  name: "energy_policy",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  "before-change": _ctx.checkEnergyPolicy,
                                  options: _ctx.selectorOptionsState.energyPolicy
                                }, null, 8, ["modelValue", "disabled", "before-change", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["table-title", "title-width"]),
                _createVNode(_component_SettingTable, {
                  "table-title": _ctx.$t('common.solar'),
                  "title-width": _ctx.titleWidth,
                  class: "color-gray basic-tb",
                  "table-sub-title": _ctx.$t('message.record_installed_pv')
                }, {
                  default: _withCtx(() => [
                    (_ctx.isBasicSettingField('pv_type'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 0,
                          title: _ctx.$t('device.pv_type'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "pv_type",
                              "target-value": _ctx.state.data.pv_type,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.pv_type,
                                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.state.data.pv_type) = $event)),
                                  name: "pv_type",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: [
                { text: _ctx.$t('common.select'), value: null },
                { text: _ctx.$t('device.string_inverter'), value: 0 },
                { text: _ctx.$t('device.micro_inverter'), value: 1 },
                { text: _ctx.$t('device.power_optimizers'), value: 2 },
              ]
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isBasicSettingField('pv_capacity_calc_option'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 1,
                          title: `${_ctx.$t('device.string_level')} / ${_ctx.$t('device.module_level')}`,
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "pv_capacity_calc_option",
                              "target-value": _ctx.state.data.pv_capacity_calc_option,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_RadioGroup, {
                                  modelValue: _ctx.state.data.pv_capacity_calc_option,
                                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.state.data.pv_capacity_calc_option) = $event)),
                                  name: "pv_capacity_calc_option",
                                  options: [
                { value: 0, text: _ctx.$t('device.string_level') },
                { value: 1, text: _ctx.$t('device.module_level') },
              ],
                                  readonly: !_ctx.state.editable,
                                  "value-type": "number"
                                }, null, 8, ["modelValue", "options", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isBasicSettingField('pv_string_count'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 2,
                          title: _ctx.$t('device.pv_string_quantity'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "pv_string_count",
                              "target-value": _ctx.state.data.pv_string_count,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.pv_string_count,
                                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.state.data.pv_string_count) = $event)),
                                  name: "pv_string_count",
                                  "value-type": "number",
                                  "after-change": 
                (stringCount) => {
                  _ctx.afterChange(stringCount);
                }
              ,
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.pvStringCount
                                }, null, 8, ["modelValue", "after-change", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.data.pv_capacity_calc_option === 0 ? _ctx.state.data.pv_string_count : 0, (value, idx) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
                        (_ctx.isBasicSettingField(`pv_string_power${value}`))
                          ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                              key: 0,
                              title: `PV-${value} ${_ctx.$t('device.power')}`,
                              description: `${_ctx.acSystemComputedRange.valueRange.pv_per.min} ~ ${_ctx.acSystemComputedRange.valueRange.pv_per.max} W`,
                              "is-indent": ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ChangedMark, {
                                  "show-mark": _ctx.state.editable,
                                  "value-key": `pv_string_power${value}`,
                                  "target-value": _ctx.getPvStringPowerValue(value),
                                  "changed-value-set": _ctx.state.changedValueSet,
                                  onChange: _ctx.onChange
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_BasicInput, {
                                      modelValue: _ctx.state.data[_ctx.getPvStringPowerKey(value)],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.state.data[_ctx.getPvStringPowerKey(value)]) = $event),
                                      name: `pv_string_power${value}`,
                                      type: "number",
                                      readonly: !_ctx.state.editable
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "readonly"])
                                  ]),
                                  _: 2
                                }, 1032, ["show-mark", "value-key", "target-value", "changed-value-set", "onChange"])
                              ]),
                              _: 2
                            }, 1032, ["title", "description"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128)),
                    (_ctx.isBasicSettingField('pv_module_count') && _ctx.state.data.pv_capacity_calc_option === 1)
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 3,
                          title: _ctx.$t('device.module_count'),
                          description: `${_ctx.acSystemComputedRange.valueRange.pv_module_count.min} ~ ${_ctx.acSystemComputedRange.valueRange.pv_module_count.max} EA`,
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "pv_module_count",
                              "target-value": _ctx.state.data.pv_module_count,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.pv_module_count,
                                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.state.data.pv_module_count) = $event)),
                                  name: "pv_module_count",
                                  type: "number",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title", "description"]))
                      : _createCommentVNode("", true),
                    (_ctx.isBasicSettingField('pv_module_power') && _ctx.state.data.pv_capacity_calc_option === 1)
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 4,
                          title: _ctx.$t('device.module_power_class'),
                          description: `${_ctx.acSystemComputedRange.valueRange.pv_module_power.min} ~ ${_ctx.acSystemComputedRange.valueRange.pv_module_power.max} W`,
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "pv_module_power",
                              "target-value": _ctx.state.data.pv_module_power,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.pv_module_power,
                                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.state.data.pv_module_power) = $event)),
                                  name: "pv_module_power",
                                  type: "number",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title", "description"]))
                      : _createCommentVNode("", true),
                    (_ctx.isBasicSettingField('totalModulePower') && _ctx.state.data.pv_capacity_calc_option === 1)
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 5,
                          title: _ctx.$t('device.total_module_power'),
                          description: "W",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.totalModulePower,
                              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.totalModulePower) = $event)),
                              type: "number",
                              readonly: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["table-title", "title-width", "table-sub-title"]),
                _createVNode(_component_SettingTable, {
                  class: "basic-tb",
                  "table-title": _ctx.$t('device.battery'),
                  "title-width": _ctx.titleWidth
                }, {
                  default: _withCtx(() => [
                    (_ctx.isBasicSettingField('battery_installed_rack_count'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 0,
                          title: "Number of Battery Rack",
                          description: `${_ctx.acSystemComputedRange.valueRange.battery_installed_rack_count.min} ~ ${_ctx.acSystemComputedRange.valueRange.battery_installed_rack_count.max}`,
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "battery_installed_rack_count",
                              "target-value": _ctx.state.data.battery_installed_rack_count,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.battery_installed_rack_count,
                                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.state.data.battery_installed_rack_count) = $event)),
                                  name: "battery_installed_rack_count",
                                  type: "number",
                                  min: "0",
                                  max: "3",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["description"]))
                      : _createCommentVNode("", true),
                    (_ctx.isBasicSettingField('battery_backup_soc'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 1,
                          title: _ctx.$t('control.energy_backup'),
                          description: 
            `${_ctx.acSystemComputedRange.valueRange.battery_backup_soc.min} ~ ${_ctx.acSystemComputedRange.valueRange.battery_backup_soc.max} %, ` +
            _ctx.$t('message.5_per_incre')
          ,
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "battery_backup_soc",
                              "target-value": _ctx.state.data.battery_backup_soc,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BaseSlider, {
                                  modelValue: _ctx.state.data.battery_backup_soc,
                                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.state.data.battery_backup_soc) = $event)),
                                  min: 0,
                                  max: 100,
                                  step: 5,
                                  format: { suffix: '%' },
                                  disabled: !_ctx.state.editable
                                }, null, 8, ["modelValue", "disabled"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title", "description"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["table-title", "title-width"]),
                _createVNode(_component_SettingTable, {
                  class: "basic-tb",
                  "table-title": _ctx.$t('device.measurement'),
                  "title-width": _ctx.titleWidth
                }, {
                  default: _withCtx(() => [
                    (_ctx.isBasicSettingField('third_party_inverter_exist'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 0,
                          title: _ctx.$t('device.measurement'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "third_party_inverter_exist",
                              "target-value": _ctx.state.data.third_party_inverter_exist,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.third_party_inverter_exist,
                                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.state.data.third_party_inverter_exist) = $event)),
                                  name: "third_party_inverter_exist",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isBasicSettingField('accb_consumption_ct_installed_point'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 1,
                          title: _ctx.$t('device.consumption_cts'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "accb_consumption_ct_installed_point",
                              "target-value": _ctx.state.data.accb_consumption_ct_installed_point,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.accb_consumption_ct_installed_point,
                                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.state.data.accb_consumption_ct_installed_point) = $event)),
                                  name: "accb_consumption_ct_installed_point",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: [
                {
                  text: _ctx.$t('common.not_installed'),
                  value: _ctx.CONSUMPTION_CT_TYPE.NOT_INSTALLED,
                },
                { text: _ctx.$t('common.grid_side'), value: _ctx.CONSUMPTION_CT_TYPE.GRID_SIDE },
                { text: _ctx.$t('common.load_side'), value: _ctx.CONSUMPTION_CT_TYPE.LOAD_SIDE },
              ]
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isBasicSettingField('pv_connection_type'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 2,
                          title: _ctx.$t('device.external_production_ct'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "pv_connection_type",
                              "target-value": _ctx.state.data.pv_connection_type,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.pv_connection_type,
                                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.state.data.pv_connection_type) = $event)),
                                  name: "pv_connection_type",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: [
                { value: 2, text: _ctx.$t('common.not_installed') },
                { value: 0, text: _ctx.$t('common.installed') },
              ]
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["table-title", "title-width"]),
                _createVNode(_component_SettingTable, {
                  class: "basic-tb",
                  "table-title": _ctx.$t('device.convert_polarity'),
                  "title-width": _ctx.titleWidth
                }, {
                  default: _withCtx(() => [
                    (_ctx.isBasicSettingField('accb_polarity_reverse_production_ct'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 0,
                          title: _ctx.$t('device.production_meter'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "accb_polarity_reverse_production_ct",
                              "target-value": _ctx.state.data.accb_polarity_reverse_production_ct,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.accb_polarity_reverse_production_ct,
                                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.state.data.accb_polarity_reverse_production_ct) = $event)),
                                  name: "accb_polarity_reverse_production_ct",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isBasicSettingField('accb_polarity_reverse_consumption_l1_ct'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 1,
                          title: _ctx.$t('device.external_production_meter'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "accb_polarity_reverse_consumption_l1_ct",
                              "target-value": _ctx.state.data.accb_polarity_reverse_consumption_l1_ct,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.accb_polarity_reverse_consumption_l1_ct,
                                  "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.state.data.accb_polarity_reverse_consumption_l1_ct) = $event)),
                                  name: "accb_polarity_reverse_consumption_l1_ct",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isBasicSettingField('accb_polarity_reverse_consumption_l2_ct'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 2,
                          title: _ctx.$t('device.consumption_meter_l1'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "accb_polarity_reverse_consumption_l2_ct",
                              "target-value": _ctx.state.data.accb_polarity_reverse_consumption_l2_ct,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.accb_polarity_reverse_consumption_l2_ct,
                                  "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.state.data.accb_polarity_reverse_consumption_l2_ct) = $event)),
                                  name: "accb_polarity_reverse_consumption_l2_ct",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isBasicSettingField('accb_polarity_reverse_ext_production_ct'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 3,
                          title: _ctx.$t('device.consumption_meter_l2'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "accb_polarity_reverse_ext_production_ct",
                              "target-value": _ctx.state.data.accb_polarity_reverse_ext_production_ct,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.accb_polarity_reverse_ext_production_ct,
                                  "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.state.data.accb_polarity_reverse_ext_production_ct) = $event)),
                                  name: "accb_polarity_reverse_ext_production_ct",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["table-title", "title-width"])
              ]),
              _: 1
            }, 8, ["title"]),
            _createElementVNode("div", {
              class: _normalizeClass(["btn_wrap_m", { editorble: _ctx.state.editable }])
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn_m btn_c_2",
                  onClick: _cache[33] || (_cache[33] = 
//@ts-ignore
(...args) => (_ctx.goToList && _ctx.goToList(...args)))
                }, _toDisplayString(_ctx.$t('message.go_to_list')), 1),
                (!_ctx.hideEdit)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn_m btn_c_3",
                      onClick: _cache[34] || (_cache[34] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
                    }, _toDisplayString(_ctx.$t('common.edit')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("button", {
                  type: "reset",
                  class: "btn_m btn_c_2",
                  onClick: ($event: any) => (_ctx.onCancel(handleReset))
                }, _toDisplayString(_ctx.$t('common.cancel')), 9, _hoisted_10),
                _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.$t('common.apply')), 1)
              ])
            ], 2)
          ]),
          _: 1
        }, 8, ["validation-schema", "onSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.state.confirmPopup.on)
      ? (_openBlock(), _createBlock(_component_SettingConfirmPopup, {
          key: 1,
          title: _ctx.$t('control.basic_sett'),
          data: _ctx.state.confirmPopup.data,
          onConfirm: _ctx.onConfirm,
          onCancel: _cache[35] || (_cache[35] = () => (_ctx.state.confirmPopup.on = false))
        }, null, 8, ["title", "data", "onConfirm"]))
      : _createCommentVNode("", true)
  ], 64))
}