/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import type { AxiosInstance } from 'axios';
import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
import type { SiteId } from 'hems/device';
import type { SmartModuleACInfo, SmartModuleDayPwData, SmartModule15MinPwData } from 'hems/device/smartmodule';
import type { CommonResponseWrapper } from 'hems';
import type { Interval } from 'hems/report';
import type { CanvasJsonData } from 'hems/mapper';

export interface SmartModulePVInfoParams {
  siteId: SiteId;
  moduleId?: string;
  filter: {
    uid?: number;
    prnUid?: number;
    from: string;
    to: string;
    timezoneId?: string;
  };
}

export interface SmartModuleEnergyData {
  type: Interval;
  condition: SmartModulePVEnergyParams;
}

export interface SmartModulePVEnergyParams {
  from?: string;
  to?: string;
  timezoneId?: string;
}

export interface SmartModuleObjectSaveParams {
  /** 저장 파일 경로 */
  drawingPath: string;
  /** 저장 파일 용량 */
  fileSize: number;
  /** 저장 파일 명 */
  newFileName: string;
  /** 원본 파일 명 */
  orgFileName: string;
}

export default class SmartModuleService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }
  // 도면 파일 가져오기
  public async getDrawingFileBySiteId(siteId: SiteId): Promise<CanvasJsonData> {
    return await this.get<CanvasJsonData>(apiWrapper.managerApi(`/devices/profiles/ac/drawing/${siteId}/sites`));
  }
  // 도면 파일 추가 및 업데이트
  public async saveDrawingFileBySiteId(siteId: SiteId, data: FormData): Promise<SmartModuleObjectSaveParams> {
    const response = await this.post<CommonResponseWrapper<SmartModuleObjectSaveParams>>(
      apiWrapper.managerApi(`/devices/profiles/ac/drawing/${siteId}/sites`),
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }

  public async getModulesInfoBySiteId(siteId: SiteId): Promise<SmartModuleACInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<{ result: SmartModuleACInfo[] }>>(
      apiWrapper.managerApi(`/devices/telemetries/ac/${siteId}/mi-profiles`)
    );

    return data.result;
  }

  /** FIXME: interval, params 하나로 합치기 SmartModuleEnergyData */
  public async getEnergyMonitoringInfoBySiteId(
    siteId: SiteId,
    interval: string,
    params: SmartModulePVEnergyParams
  ): Promise<SmartModuleDayPwData[]> {
    const { data } = await this.get<CommonResponseWrapper<{ result: SmartModuleDayPwData[] }>>(
      apiWrapper.managerApi(`/devices/telemetries/ac/${siteId}/energies/${interval}`),
      params
    );

    return data.result;
  }

  public async get15MinPvPowerInfoACBySiteId(
    siteId: SiteId,
    params: SmartModulePVEnergyParams
  ): Promise<SmartModule15MinPwData[]> {
    const { data } = await this.get<CommonResponseWrapper<{ result: SmartModule15MinPwData[] }>>(
      apiWrapper.managerApi(`/devices/telemetries/ac/${siteId}/powers`),
      params
    );

    return data.result;
  }
}

export { SmartModuleService };
