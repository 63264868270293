import type { AxiosInstance } from 'axios';

import { Helper, pvmoduleSettingsHelper, smartmoduleSettingsHelper, SettingHelper } from '@hems/util';
import type { SettingTargetValue } from '@hems/util/src/constant';
import { SETTING_TARGET } from '@hems/util/src/constant';
import { getSettingSaveParams } from '@hems/util/src/helper/settingHelper';

import type { CommonResponseWrapper, Device } from 'hems';

import type {
  DeviceId,
  ProductModelName,
  SiteId,
  NMIDeviceId,
  PrnDeviceId,
  NMIValidationResponse,
  DeviceConnectionStatus,
  InstallationDeviceInfo,
  MonitoringListSearchOptions,
} from 'hems/device';
import type { ErrorModuleInfo, SAPNRegistrationInfo } from 'hems/device';
import type { SubBatteryPack } from 'hems/device/dashboard/common/batteryPack';
import type { BaseSearchOption, BatteryHistoryInfo } from 'hems/device/dashboard/common/installHistory';
import type { SettingsResponse } from 'hems/device/deviceinfo';
import type { ConfigurationsResponse } from 'hems/device/settings';
import type { Installation, ReplaceDeviceOptions } from 'hems/device/settings/installation';
import type {
  SettingsPvModuleGen2SaveParam,
  SettingsPvModuleGen3SaveParam,
  SettingsPvModuleFoxSaveParam,
} from 'hems/device/settings/pvmodule';
import type {
  SettingsSmartModuleACSystemSaveParam,
  SettingsSmartModuleGen2SaveParam,
} from 'hems/device/settings/smartmodule';
import type { Gen1 as SettingsSmartModuleGen1 } from 'hems/device/settings/smartmodule';
import type { SiteInfo, SiteNetworkType } from 'hems/device/siteinfo';
import type { DeviceInstallation, SiteDetail, SubDeviceInfo } from 'hems/install';
import type { SiteListInfo, SiteListSearchOptions } from 'hems/site';

// TODO: packages 내의 파일에서 절대경로, 상대경로 사용에 대한 논의 결과에 따라 path 수정
import { apiWrapper, getApiVersionHeader } from '../../util/helper';
import { Service } from '../Service';

interface ResultOfCheckedDeviceId {
  result: boolean;
  fail_type: string;
  product_model_nm: ProductModelName;
}

interface GridCodeParam {
  productModelNm?: string;
  cntryCd: string;
  grpCd?: string;
}

interface LocationParam {
  instlAddr: string;
  cntryCd: string;
  latitude: number;
  longitude: number;
  timezoneId: string;
  pinCode: string;
  instlState: string;
  instlPostalCode: string;
  forceInstlAddr: string | null;
}

interface HomeownerParam {
  homeownerNm: string;
  homeownerPn: string;
  homeownerEmail: string;
}

export default class DeviceService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getImageBySiteId(siteId: SiteId): Promise<Blob> {
    return await this.get<any>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/images`),
      {},
      {
        responseType: 'blob',
      }
    );
  }

  public async saveImageBySiteId(siteId: SiteId, params: any): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/images`),
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return data;
  }

  public async checkDeviceId(deviceId: Device.DeviceId): Promise<ResultOfCheckedDeviceId> {
    const { data } = await this.get<CommonResponseWrapper<ResultOfCheckedDeviceId>>(
      apiWrapper.managerApi(`/devices/profiles/validations/${deviceId}/ems-device-id`)
    );

    return data;
  }

  // 모니터링 리스트 조회
  public async getMonitoringList(params: Partial<MonitoringListSearchOptions>): Promise<Device.DeviceList> {
    const { data } = await this.get<CommonResponseWrapper<Device.DeviceList>>(
      apiWrapper.stateApi('/devices/telemetries/renewal'),
      params
    );

    return data;
  }

  // AC System - 모니터링 Site 목록 조회
  public async getMonitoringSiteList(params: Partial<MonitoringListSearchOptions>): Promise<Device.DeviceList> {
    const { data } = await this.get<CommonResponseWrapper<Device.DeviceList>>(
      apiWrapper.stateApi('/devices/telemetries/multi-inverter/sites'),
      params
    );

    return data;
  }

  // AC System - 모니터링 EMS 목록 조회
  public async getMonitoringEMSList(params: Partial<MonitoringListSearchOptions>): Promise<Device.EMSList> {
    const { data } = await this.get<CommonResponseWrapper<Device.EMSList>>(
      apiWrapper.stateApi('/devices/telemetries/multi-inverter/devices'),
      params
    );

    return data;
  }

  // 모니터링 상세 - Dashboard - 프로파일, Inventory
  public async getDeviceProfile(siteId: Device.SiteId): Promise<SiteInfo> {
    const { data } = await this.get<CommonResponseWrapper<SiteInfo>>(
      apiWrapper.managerApi(`/devices/telemetries/${siteId}`)
    );

    return data;
  }

  // 모니터링 상세 - Setting - Inatallation
  public async getInstallationInfo(siteId: Device.SiteId): Promise<Installation> {
    const { data } = await this.get<CommonResponseWrapper<Installation>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/installations`)
    );

    return data;
  }

  // 모니터링 상세 - Battery History
  async getBatteryHistoryInfo(deviceId: Device.DeviceId, param: BaseSearchOption): Promise<BatteryHistoryInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<BatteryHistoryInfo[]>>(
      apiWrapper.managerApi(`/devices/profiles/${deviceId}/battery`),
      param
    );

    return data;
  }

  // 모니터링 상세 - Battery History Excel download
  async batteryHistoryExcelDownloads(
    deviceId: Device.DeviceId,
    param: BaseSearchOption
  ): Promise<BatteryHistoryInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<BatteryHistoryInfo[]>>(
      apiWrapper.managerApi(`/devices/profiles/${deviceId}/battery/excel`),
      param
    );

    return data;
  }

  // 모니터링 상세 - Setting - Basic, Advanced, TOU
  public async getSettingInfo(siteId: Device.SiteId): Promise<SettingsResponse> {
    const { data } = await this.get<CommonResponseWrapper<SettingsResponse>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/configurations`),
      undefined,
      {
        headers: getApiVersionHeader(),
      }
    );

    return data;
  }

  // 에러 모듈 목록
  public async getErrorDeviceList(siteId: Device.SiteId): Promise<ErrorModuleInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<ErrorModuleInfo[]>>(
      apiWrapper.managerApi(`/devices/alarms/${siteId}/modules`)
    );

    return data;
  }

  // 장비 목록
  public async getDeviceList(): Promise<Device.DeviceListDownlLoadItem[]> {
    const { data } = await this.get<CommonResponseWrapper<Device.DeviceListDownlLoadItem[]>>(
      apiWrapper.managerApi('/devices/profiles/excel-files')
    );

    return data;
  }

  // 그리드 코드 목록
  public async getGridCodeGen2(params: GridCodeParam): Promise<Device.GridCodeInfoResponseGen2> {
    const { data } = await this.get<CommonResponseWrapper<Device.GridCodeInfoResponseGen2>>(
      apiWrapper.managerApi('/devices/profiles/grid-codes'),
      params
    );

    return data;
  }
  public async getGridCodeGen3(params: GridCodeParam): Promise<Device.GridCodeInfoResponse> {
    const { data } = await this.get<CommonResponseWrapper<Device.GridCodeInfoResponse>>(
      apiWrapper.managerApi('/devices/profiles/grid-codes'),
      params
    );

    return data;
  }
  public async getGridCodeACSystem(params: GridCodeParam): Promise<Device.GridCodeInfoResponseACSys> {
    const { data } = await this.get<CommonResponseWrapper<Device.GridCodeInfoResponseACSys>>(
      apiWrapper.managerApi('/devices/profiles/grid-codes'),
      params
    );

    return data;
  }

  public async saveLocationInfo(siteId: SiteId, params: LocationParam): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/locations`),
      params
    );

    return data;
  }

  //
  public async controlGen2Setting(
    deviceId: DeviceId,
    param: SettingsPvModuleGen2SaveParam
  ): Promise<ConfigurationsResponse> {
    const saveParam = {
      msgType: param.msg_type,
    };
    if (param.msg_type.indexOf('BS') !== -1)
      Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('GEN2', 'BS', param)));
    if (param.msg_type.indexOf('AS') !== -1)
      Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('GEN2', 'AS', param)));

    const { data } = await this.post<CommonResponseWrapper<ConfigurationsResponse>>(
      apiWrapper.controlApi(`/devices/${deviceId}/configurations/sync`),
      saveParam
    );

    return data;
  }
  public async controlGen3Setting(
    deviceId: DeviceId,
    param: SettingsPvModuleGen3SaveParam
  ): Promise<ConfigurationsResponse> {
    const saveParam = {
      msgType: param.msg_type,
    };
    if (param.msg_type.indexOf('BS') !== -1)
      Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('GEN3', 'BS', param)));
    if (param.msg_type.indexOf('AS') !== -1) {
      const tempParam = Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('GEN3', 'AS', param)) as {
        gridCode?: number;
      };
      const installSettingParam = Helper.convertSettingParam(SettingHelper.getFilterInstallSettingValues(param));
      const gridCode = '' + tempParam.gridCode;
      delete tempParam.gridCode;
      Object.assign(saveParam, { gridCode }, { gen3AdvancedSettings: tempParam }, installSettingParam);
    }
    if (param.msg_type.indexOf('TOU') !== -1)
      Object.assign(
        saveParam,
        Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('GEN3', 'TOU', param))
      );
    if (param.msg_type.indexOf('ES') !== -1) {
      Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('GEN3', 'ES', param)));
    }

    const { data } = await this.post<CommonResponseWrapper<ConfigurationsResponse>>(
      apiWrapper.controlApi(`/devices/${deviceId}/configurations/sync`),
      saveParam
    );

    return data;
  }
  public async controlFoxSetting(
    deviceId: DeviceId,
    param: SettingsPvModuleFoxSaveParam
  ): Promise<ConfigurationsResponse> {
    const saveParam = {
      msgType: param.msg_type,
    };
    if (param.msg_type.indexOf('BS') !== -1)
      Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('FOX', 'BS', param)));
    if (param.msg_type.indexOf('ES') !== -1)
      Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('FOX', 'ES', param)));
    if (param.msg_type.indexOf('TOU') !== -1)
      Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('FOX', 'TOU', param)));

    const { data } = await this.post<CommonResponseWrapper<ConfigurationsResponse>>(
      apiWrapper.controlApi(`/devices/${deviceId}/configurations/sync`),
      saveParam
    );

    return data;
  }
  public async controlACSystemSetting(
    deviceId: DeviceId,
    param: SettingsSmartModuleACSystemSaveParam
  ): Promise<ConfigurationsResponse> {
    const saveParam = {
      msgType: param.msg_type,
    };
    if (param.msg_type.indexOf('BS') !== -1)
      Object.assign(
        saveParam,
        Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('AC_SYS', 'BS', param))
      );
    if (param.msg_type.indexOf('AS') !== -1) {
      const tempParam = Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('AC_SYS', 'AS', param)) as {
        gridCode?: number;
      };
      const installSettingParam = Helper.convertSettingParam(SettingHelper.getFilterInstallSettingValues(param));
      const gridCode = '' + tempParam.gridCode;
      delete tempParam.gridCode;
      Object.assign(saveParam, { gridCode }, tempParam, installSettingParam);
    }
    if (param.msg_type.indexOf('TOU') !== -1)
      Object.assign(
        saveParam,
        Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('AC_SYS', 'TOU', param))
      );
    if (param.msg_type.indexOf('ES') !== -1) {
      Object.assign(
        saveParam,
        Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('AC_SYS', 'ES', param))
      );
    }

    const { data } = await this.post<CommonResponseWrapper<ConfigurationsResponse>>(
      apiWrapper.controlApi(`/devices/${deviceId}/configurations/sync`),
      saveParam
    );

    return data;
  }
  public async controlSmartModuleGen1Setting(
    deviceId: DeviceId,
    param: SettingsSmartModuleGen1.SettingsSmartModuleGen1SaveParam
  ) {
    let _param;
    if (param.msg_type === 'BS') {
      const { basic_config_info, msg_type, string_config_info } = param;
      _param = {
        msgType: msg_type,
        basicConfigInfo: JSON.stringify(basic_config_info),
        stringConfigInfo: JSON.stringify(string_config_info),
      };
    } else if (param.msg_type === 'AS') {
      const { msg_type, advanced_config_info } = param;
      _param = {
        msgType: msg_type,
        advancedConfigInfo: JSON.stringify(advanced_config_info),
      };
    } else if (param.msg_type === 'BS_AS') {
      const { basic_config_info, msg_type, string_config_info, advanced_config_info } = param;
      _param = {
        msgType: msg_type,
        basicConfigInfo: JSON.stringify(basic_config_info),
        stringConfigInfo: JSON.stringify(string_config_info),
        advancedConfigInfo: JSON.stringify(advanced_config_info),
      };
    }
    if (!_param) throw `Unsupported Messge Type ${param.msg_type}`;

    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.controlApi(`/devices/${deviceId}/configurations/sync`),
      _param
    );

    return data;
  }
  public async controlSmartModuleGen2Setting(
    deviceId: DeviceId,
    param: SettingsSmartModuleGen2SaveParam
  ): Promise<ConfigurationsResponse> {
    const saveParam = {
      msgType: param.msg_type,
    };
    if (param.msg_type.indexOf('BS') !== -1)
      Object.assign(
        saveParam,
        Helper.convertSettingParam(smartmoduleSettingsHelper.getFilterValues('DC_OPTIMIZER_GEN2', 'BS', param))
      );
    if (param.msg_type.indexOf('AS') !== -1) {
      const tempParam = Helper.convertSettingParam(
        smartmoduleSettingsHelper.getFilterValues('DC_OPTIMIZER_GEN2', 'AS', param)
      ) as {
        gridCode?: number;
      };
      const installSettingParam = Helper.convertSettingParam(SettingHelper.getFilterInstallSettingValues(param));
      const gridCode = '' + tempParam.gridCode;
      delete tempParam.gridCode;
      Object.assign(saveParam, { gridCode }, { gen3AdvancedSettings: tempParam }, installSettingParam);
    }
    if (param.msg_type.indexOf('TOU') !== -1)
      Object.assign(
        saveParam,
        Helper.convertSettingParam(smartmoduleSettingsHelper.getFilterValues('DC_OPTIMIZER_GEN2', 'TOU', param))
      );
    if (param.msg_type.indexOf('ES') !== -1) {
      Object.assign(
        saveParam,
        Helper.convertSettingParam(smartmoduleSettingsHelper.getFilterValues('DC_OPTIMIZER_GEN2', 'ES', param))
      );
    }

    const { data } = await this.post<CommonResponseWrapper<ConfigurationsResponse>>(
      apiWrapper.controlApi(`/devices/${deviceId}/configurations/sync`),
      saveParam
    );

    return data;
  }

  /** AC System Configuration with SiteId */
  public async controlACSystemSettingWithSiteId(
    siteId: SiteId,
    param: SettingsSmartModuleACSystemSaveParam,
    target: SettingTargetValue = SETTING_TARGET.SITE
  ): Promise<ConfigurationsResponse> {
    const saveParam = getSettingSaveParams(param, target);

    const { data } = await this.post<CommonResponseWrapper<ConfigurationsResponse>>(
      apiWrapper.controlApi(`/devices/site/${siteId}/configurations/sync`),
      saveParam
    );

    return data;
  }

  public async checkDeviceConnection(deviceId: Device.DeviceId): Promise<DeviceConnectionStatus> {
    const { data } = await this.get<CommonResponseWrapper<DeviceConnectionStatus>>(
      apiWrapper.managerApi(`/devices/connections/${deviceId}/status-devices`),
      undefined,
      {
        headers: getApiVersionHeader(),
      }
    );

    return data;
  }

  public async checkDeviceConnectionBySite(siteId: SiteId): Promise<DeviceConnectionStatus[]> {
    const { data } = await this.get<CommonResponseWrapper<DeviceConnectionStatus[]>>(
      apiWrapper.managerApi(`/devices/connections/${siteId}/status`)
    );

    return data;
  }

  // deletion/rollback 구별
  public async checkDeleteStatus(siteId: SiteId, params: { deviceId: Device.DeviceId }): Promise<any> {
    const { data } = await this.get<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/installations/admin/delete/status/${siteId}`),
      params
    );

    return data;
  }

  public async replaceDevice(siteId: SiteId, params: ReplaceDeviceOptions): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/installations/ems/${siteId}`),
      params
    );

    return data;
  }

  public async removeDevice(siteId: SiteId, params: { deviceId: DeviceId }): Promise<any> {
    const { data } = await this.delete<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/installations/ems/${siteId}`),
      undefined,
      { data: params }
    );

    return data;
  }

  public async deleteDevice(deviceId: DeviceId): Promise<any> {
    const { data } = await this.delete<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/installations/admin/delete/${deviceId}`)
    );

    return data;
  }

  public async rollbackDevice(deviceId: DeviceId): Promise<any> {
    const { data } = await this.delete<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/installations/admin/rollback/${deviceId}`)
    );

    return data;
  }

  // 장비 추가/교체/삭제 관련
  public async getBatteryInfo(siteId: SiteId): Promise<SubBatteryPack> {
    const { data } = await this.get<CommonResponseWrapper<SubBatteryPack>>(
      apiWrapper.managerApi(`/devices/installations/new-batteries/${siteId}`)
    );

    return data;
  }

  public async getAssignedBattery(siteId: SiteId, batteryType: string): Promise<Device.AssignedDeviceInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<Device.AssignedDeviceInfo[]>>(
      apiWrapper.managerApi(`/devices/installations/new-batteries/change-list/${siteId}`),
      { batteryListType: batteryType }
    );

    return data;
  }

  public async getAssignedDevice(siteId: SiteId, prnDeviceId?: PrnDeviceId): Promise<Device.AssignedDeviceInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<Device.AssignedDeviceInfo[]>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/assigned-devices`),
      { prnDeviceId }
    );

    return data;
  }

  public async createSubDevice(siteId: SiteId, params: SubDeviceInfo): Promise<any> {
    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/installations/power-optimizer/primiary-board/${siteId}`),
      params
    );

    return data;
  }

  public async createSubBattery(siteId: SiteId, params: SubDeviceInfo): Promise<any> {
    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/installations/new-batteries/${siteId}`),
      params
    );

    return data;
  }

  public async replaceSubDevice(siteId: SiteId, params: SubDeviceInfo): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/installations/power-optimizer/primiary-board/${siteId}`),
      params
    );

    return data;
  }

  public async replaceSubBattery(siteId: SiteId, params: SubDeviceInfo): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/installations/new-batteries/${siteId}`),
      params
    );

    return data;
  }

  public async deleteSubDevice(siteId: SiteId, params: SubDeviceInfo): Promise<any> {
    const { deviceTypeCd, deviceId, prnDeviceId } = params;
    const { data } = await this.delete<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/installations/power-optimizer/primiary-board/${siteId}`),
      undefined,
      {
        data: { deviceTypeCd, deviceId, prnDeviceId },
      }
    );

    return data;
  }

  public async deleteSubBattery(siteId: SiteId, params: SubDeviceInfo): Promise<any> {
    const { batteryDeviceId } = params;
    const { data } = await this.delete<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/installations/new-batteries/${siteId}`),
      undefined,
      {
        data: { batteryDeviceId },
      }
    );

    return data;
  }

  public async getPvModuleInfo(siteId: SiteId): Promise<any> {
    const { data } = await this.get<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/modules`)
    );

    return data;
  }

  public async replaceNMI(
    siteId: SiteId,
    params: { nmiDeviceId: NMIDeviceId; newNmiDeviceId: NMIDeviceId; deviceId: string }
  ): Promise<{ siteId: SiteId }> {
    const { data } = await this.put<CommonResponseWrapper<{ siteId: SiteId }>>(
      apiWrapper.managerApi(`/devices/installations/nmi/${siteId}`),
      params
    );

    return data;
  }

  public async removeNMI(siteId: SiteId, nmiDeviceId: NMIDeviceId, deviceId: string): Promise<{ siteId: SiteId }> {
    const { data } = await this.delete<CommonResponseWrapper<{ siteId: SiteId }>>(
      apiWrapper.managerApi(`/devices/installations/nmi/${siteId}`),
      undefined,
      { data: { nmiDeviceId, deviceId } }
    );

    return data;
  }

  public async checkNMIDeviceId(nmi_device_id: NMIDeviceId): Promise<NMIValidationResponse> {
    const { data } = await this.get<CommonResponseWrapper<NMIValidationResponse>>(
      apiWrapper.managerApi(`/devices/profiles/validations/${nmi_device_id}/nmi-id`)
    );

    return data;
  }

  public async saveEnabledNotiAlarms(siteId: Device.SiteId, params: { enabled_noti_alarms: string }): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/alarm-flags`),
      Helper.keyToCamelCase(params)
    );

    return data;
  }

  public async saveHomeownerInfo(siteId: Device.SiteId, params: HomeownerParam): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/homeowners`),
      params
    );

    return data;
  }

  public async updateRetailer(siteId: SiteId, params: { retailerCd: string | null }): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/retailers`),
      params
    );

    return data;
  }

  public async updateOffGrid(siteId: Device.SiteId, params: { off_grid: 'Y' | 'N' }): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/off-grids`),
      Helper.keyToCamelCase(params)
    );

    return data;
  }

  public async updateTestSite(siteId: Device.SiteId, params: { is_test: 'Y' | 'N' }): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/test-sites`),
      Helper.keyToCamelCase(params)
    );

    return data;
  }

  public async checkDuplicatedLocation(params: { latitude: number; longitude: number }): Promise<boolean> {
    const { data } = await this.get<CommonResponseWrapper<boolean>>(
      apiWrapper.managerApi('/devices/profiles/locations/validations'),
      params
    );

    return data;
  }

  public async updateComments(siteId: Device.SiteId, params: { memo: string | null }): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/comments`),
      params
    );

    return data;
  }

  public async updateDynamicExport(
    siteId: Device.SiteId,
    params: { dynamic_export: 'Y' | 'N' | null; dynamic_export_owner: 'Y' | 'N' | null }
  ): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/dynamic-exports`),
      Helper.keyToCamelCase(params)
    );

    return data;
  }

  public async registerSapn(params: { siteId: SiteId; lfdiFlag: 0 | 1 }): Promise<SAPNRegistrationInfo> {
    const { data } = await this.post<CommonResponseWrapper<SAPNRegistrationInfo>>(
      apiWrapper.controlApi('/devices/lfdi'),
      params
    );

    return data;
  }

  public async getInstallationSiteList(params: SiteListSearchOptions): Promise<SiteListInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<SiteListInfo[]>>(
      apiWrapper.managerApi('/managements/mobiles/pro-apps/sites'),
      params
    );

    return data;
  }

  public async getInstallationSite(siteId: SiteId): Promise<SiteDetail> {
    const { data } = await this.get<CommonResponseWrapper<SiteDetail>>(
      apiWrapper.managerApi(`/managements/mobiles/pro-apps/${siteId}/sites`)
    );

    return data;
  }

  public async getInstallationSteps(siteId: SiteId): Promise<{ siteId: string; stepData: DeviceInstallation }> {
    return await this.get<CommonResponseWrapper<{ siteId: string; stepData: DeviceInstallation }>>(
      apiWrapper.managerApi(`/managements/mobiles/pro-apps/${siteId}/steps`)
    ).then((res) => {
      const data = res.data as unknown as { siteId: string; stepData: string };

      return {
        ...data,
        stepData: data?.stepData ? (Helper.isJSON(data.stepData) ? JSON.parse(data.stepData) : null) : null,
      };
    });
  }

  public async getInstallationDeviceInfo(siteId: SiteId): Promise<InstallationDeviceInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<InstallationDeviceInfo[]>>(
      apiWrapper.managerApi(`/managements/mobiles/pro-apps/register/${siteId}`)
    );

    return data;
  }

  public getSiteNetworkType = async (siteId: number): Promise<SiteNetworkType> => {
    const { data } = await this.get<CommonResponseWrapper<SiteNetworkType>>(
      apiWrapper.stateApi(`/devices/telemetries/network-type/${siteId}`)
    );

    return data;
  };
}

export { DeviceService };
