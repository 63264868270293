import { CellRendererProps } from 'tui-grid/types/renderer';

class ImageRenderer {
  private el: HTMLImageElement;

  constructor(props: CellRendererProps) {
    const el = document.createElement('img');
    this.el = el;
    this.render(props);
  }

  getElement(): HTMLImageElement {
    return this.el;
  }

  async render(props: CellRendererProps): Promise<void> {
    const options = props.columnInfo.renderer.options;
    this.el.src = options
      ? (
          await import(
            `@hems/component/resources/vendor/image/${options.baseUrl || ''}/${options.prefix || ''}${props.value}${
              options.postfix || ''
            }.${options.extension || 'png'}`
          )
        ).default
      : '';
  }
}

export default ImageRenderer;
