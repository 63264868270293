import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-435b0c50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-root" }
const _hoisted_2 = { class: "nav-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavMainItem = _resolveComponent("NavMainItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItems, (navItem) => {
        return (_openBlock(), _createBlock(_component_NavMainItem, {
          key: navItem.code,
          "nav-item": navItem,
          onClick: _withModifiers(_ctx.onClick, ["stop","prevent"])
        }, null, 8, ["nav-item", "onClick"]))
      }), 128))
    ])
  ]))
}