
import { defineComponent } from 'vue';

import { PopupWrapper } from '@hems/component';
export default defineComponent({
  name: 'ReAgreeTermsPopup',
  components: {
    PopupWrapper,
  },
  emits: ['close', 'openPrivacyPopup', 'agree'],
});
