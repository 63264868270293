import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LottieAnimation = _resolveComponent("LottieAnimation")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: _normalizeClass(["loading", { active: _ctx.isLoading.value }])
    }, [
      _createVNode(_component_LottieAnimation, {
        path: _ctx.path,
        class: "lottie"
      }, null, 8, ["path"])
    ], 2)
  ]))
}