
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import { Form } from 'vee-validate';
import type * as Yup from 'yup';

import { Panel } from '@hems/component';
import { FORM_TYPE } from '@hems/util/src/constant';

import type { FormMode } from 'hems';

export default defineComponent({
  name: 'GroupConfigContainer',
  components: {
    Panel,
    Form,
  },
  props: {
    mode: {
      type: String as PropType<FormMode>,
      default: FORM_TYPE.READ,
    },
    title: {
      type: String,
      default: 'Setting Modification',
    },
    schema: {
      type: Object as PropType<Yup.AnySchema>,
      default: null,
    },
    isGroupControl: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['next', 'goToList'],
  setup() {
    return {
      FORM_TYPE,
    };
  },
});
