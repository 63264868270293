
import { BUTTON_SIZE, BUTTON_TYPE, ButtonSizeValue, ButtonTypeValue } from '@hems/util/src/constant';
import { computed, defineComponent, PropType, reactive } from 'vue';
const buttonSize = {
  [BUTTON_SIZE.SMALL]: 'btn_s',
  [BUTTON_SIZE.MEDIUM]: 'btn_m',
  [BUTTON_SIZE.LARGE]: 'btn_l',
  [BUTTON_SIZE.FIT_TO_PARENT]: '',
};
const buttonType = {
  primary: 'btn_c_3',
  secondary: 'btn_c_2',
  tertiary: 'btn_c',
  white: 'btn_c_4',
};

export default defineComponent({
  name: 'Button',
  props: {
    size: {
      type: String as PropType<ButtonSizeValue>,
      default: BUTTON_SIZE.MEDIUM,
    },
    type: {
      type: String as PropType<ButtonTypeValue>,
      default: BUTTON_TYPE.SECONDARY,
    },
    additionalClass: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const state = reactive({
      size: computed(() => buttonSize[props.size]),
      type: computed(() => buttonType[props.type]),
    });

    return {
      state,
    };
  },
});
