import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-037df5d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fm_wrap" }
const _hoisted_2 = {
  key: 0,
  class: "label"
}
const _hoisted_3 = ["name", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessageLabel = _resolveComponent("ErrorMessageLabel")!

  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      ref: "mapInput",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      name: _ctx.name,
      type: _ctx.state.isReadonly ? 'hidden' : _ctx.type || 'text',
      class: _normalizeClass(["fm_ipt", { [_ctx.errorMessage ? 'error' : _ctx.state.status]: true }]),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
    }, null, 42, _hoisted_3), [
      [_vShow, !_ctx.state.isReadonly],
      [_vModelDynamic, _ctx.inputValue]
    ]),
    _withDirectives(_createElementVNode("span", { class: "fm_ipt display-value" }, _toDisplayString(_ctx.inputValue), 513), [
      [_vShow, _ctx.state.isReadonly]
    ]),
    _createVNode(_component_ErrorMessageLabel, {
      "error-message": _ctx.errorMessage || _ctx.state.errMsg
    }, null, 8, ["error-message"])
  ]))
}