/* eslint-disable */
import { computed } from 'vue';

export function useModelWrapper<T>(
  props: Record<string, unknown>,
  emit: (event: any, ...args: any[]) => void,
  name = 'modelValue',
  validate: (value: T, prevValue: T) => boolean = () => true
) {
  return computed({
    get: () => props[name] as T,
    set: (value: T) => {
      if (validate(value, props[name] as T)) emit(`update:${name}`, value);
    },
  });
}
