import { AxiosInstance } from 'axios';
import { Service } from '../Service';
import { CommonCode, LangCd, CommonResponseWrapper } from 'hems';
import { apiWrapper, getApiVersionHeader } from '../../util/helper';
import { DeviceConnectionStatus, DeviceId, GenType, ProductModelName, SiteId } from 'hems/device';
import { StorageHelper } from '@hems/util';
import { NoticeInfo } from 'hems/notice';
import { SendEmailOptions } from 'hems/device/settings/installation';
import { FcasSettings } from 'hems/device/settings';

export default class CommonService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  private getStorageKey(code: { grpCd: CommonCode.GroupCode; exclude?: string[] }): number {
    return `${code.grpCd}${code.exclude?.join('_')}`.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  // 코드 목록 가져오기
  public async getCodesByGroupCode<T extends CommonCode.GroupCode>(
    codes: {
      grpCd: T;
      exclude?: string[];
    }[]
  ): Promise<{ [K in T]: CommonCode.CodeMap[] }> {
    const notExistedCodes = [] as { grpCd: T; exclude?: string[] }[];
    const result = {} as { [K in T]: CommonCode.CodeMap[] };

    // Session Storage에 있으면 result에 추가, 없으면 notExistedCodes에 파라미터 추가
    codes.forEach((item) => {
      const hashCode = this.getStorageKey(item);
      if (StorageHelper.isExistCode(hashCode)) {
        result[item.grpCd] = StorageHelper.getCode(hashCode);
      } else {
        notExistedCodes.push(item);
      }
    });

    let resResult;
    if (notExistedCodes.length > 0) {
      // notExistedCodes에 포함된 것만 API 호출

      const { data } = await this.post<CommonResponseWrapper<{ [K in T]: CommonCode.CodeMap[] }>>(
        apiWrapper.managerApi('/managements/commons/codes'),
        { codes: notExistedCodes }
      );
      resResult = data;
      for (const obj of notExistedCodes) {
        const hashCode = this.getStorageKey(obj);

        // notExistedCodes의 결과를 Session Storage에 저장
        if (resResult[obj.grpCd]) StorageHelper.setCode(hashCode, resResult[obj.grpCd]);
      }
    }

    return { ...result, ...resResult };
  }
  public async getGenType(siteId: SiteId): Promise<GenType> {
    const { data } = await this.get<CommonResponseWrapper<GenType>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/types`)
    );
    return data;
  }

  public async isConnection(deviceId: DeviceId): Promise<boolean> {
    const { data } = await this.get<CommonResponseWrapper<DeviceConnectionStatus>>(
      apiWrapper.managerApi(`/devices/connections/${deviceId}/status-devices`),
      undefined,
      {
        headers: getApiVersionHeader(),
      }
    );
    if (data.responseCd === '0') return true;
    return false;
  }

  public async healthCheck(): Promise<boolean> {
    try {
      const { data } = await this.get<CommonResponseWrapper<boolean>>('/health-check');
      return data;
    } catch (error) {
      return false;
    }
  }

  public async getGoogleAPIKey(): Promise<string> {
    const { data } = await this.get<CommonResponseWrapper<string>>(
      apiWrapper.managerApi('/managements/commons/google/api-keys')
    );
    return data;
  }

  public async getNotice(langCd: LangCd = 'en'): Promise<NoticeInfo> {
    const { data } = await this.get<CommonResponseWrapper<NoticeInfo>>(
      apiWrapper.managerApi('/managements/commons/notices'),
      { lang: langCd }
    );
    return data;
  }

  public async getEventAlarmCodes(): Promise<string[]> {
    const { data } = await this.get<CommonResponseWrapper<string[]>>(apiWrapper.managerApi('/devices/alarms/codes'));
    return data;
  }

  public async getGuideCategory(): Promise<string[]> {
    const { data } = await this.get<CommonResponseWrapper<string[]>>(
      apiWrapper.managerApi('/managements/mobiles/pro-apps/supports/categories')
    );
    return data;
  }

  public async sendOwnerEmail(siteId: SiteId, sendEmailOptions: SendEmailOptions): Promise<any> {
    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/mails/${siteId}`),
      sendEmailOptions
    );
    return data;
  }

  // TODO: 사용하지 않음 -> 삭제
  public async getProductModelNm(siteId: number): Promise<string> {
    return await this.get(apiWrapper.deviceAPI(`/product-model-name/${siteId}`));
  }

  public async getFcasSettingDefault(siteId: SiteId): Promise<FcasSettings> {
    const { data } = await this.get<CommonResponseWrapper<FcasSettings>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/fcas-values`)
    );
    return data;
  }

  public async getBaseGenType(productModelNm: ProductModelName) {
    const { data } = await this.get<CommonResponseWrapper<GenType>>(
      apiWrapper.managerApi('/devices/profiles/gen-types'),
      { productModelNm }
    );
    return data;
  }

  public async getPgpKeyFile() {
    return await this.get<any>(
      apiWrapper.managerApi('/managements/commons/s3/cyber-security/files'),
      {},
      {
        headers: {
          Accept: '*/*',
        },
        responseType: 'blob',
      }
    );
  }
}

export { CommonService };
