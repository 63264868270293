import { CellRendererProps } from 'tui-grid/types/renderer';
import { useMessageBox } from '@hems/component';
import { isNull } from '@hems/util/src/helper/helper';

class MoreDetailRenderer {
  private el: HTMLElement;
  private desc: JQuery<HTMLElement>;
  private button: JQuery<HTMLElement>;

  constructor(props: CellRendererProps) {
    const { $ } = window;
    const el = $('<div class="moredtail_con" style="display : inline-flex"></div>');

    this.desc = $(
      '<div style="width:200px; padding:0 5px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; align-self:center; "></div>'
    );
    this.button = $(
      '<div class="btn_i btn_c_3" style = "display : inline-block; float : right;"><span class="ir ico_arrow">상세보기</span></div>'
    );

    el.append(this.desc).append(this.button);

    this.el = el.get(0);
    this.render(props);
  }

  getElement(): HTMLElement {
    return this.el;
  }

  async render(props: CellRendererProps): Promise<void> {
    const messageBox = useMessageBox();
    const text = props.value as string;
    this.desc.text(text);
    if (isNull(text)) {
      this.button.hide();
      return;
    }
    this.button.on('click', () => messageBox.alert(props.value as string).open());
  }
}

export default MoreDetailRenderer;
