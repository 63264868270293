
import type { PropType } from 'vue';
import { defineComponent, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { Form } from 'vee-validate';
import * as yup from 'yup';

import { useMessageBox } from '@hems/component';
import { JoinService } from '@hems/service';
import { Helper } from '@hems/util';

import type { LangCd } from 'hems';

import PopupWrapper from './PopupWrapper.vue';
import LabelInput from '../inputs/LabelInput.vue';

const WITHDRAWAL_TEXT = 'DELETE';
export default defineComponent({
  name: 'WithdrawalPopup',
  components: { Form, PopupWrapper, LabelInput },
  props: {
    langCd: {
      type: String as PropType<LangCd>,
      default: 'en',
    },
  },
  emits: ['logout', 'close'],
  setup(props, { emit }) {
    const state = reactive({
      confirmWithdrawl: '',
      langCd: computed(() => props.langCd),
    });
    const joinService = new JoinService(window.axiosInstance.axios);
    const messageBox = useMessageBox();
    const { t } = useI18n();

    const schema = yup.object().shape({
      confirmWithdrawl: yup.string().test(
        'confirmWithdrawl',
        {
          key: 'message.invalid',
        },
        function (confirmWithdrawl?: string) {
          const { createError } = this;
          if (Helper.isNull(confirmWithdrawl)) {
            return createError({
              message: { key: 'message.field_required' },
            });
          }
          const regex = new RegExp(`^${WITHDRAWAL_TEXT}$`);
          if (!regex.test(confirmWithdrawl)) {
            return false;
          }

          return true;
        }
      ),
    });

    async function onWithdrawal() {
      try {
        const isOk = await messageBox
          .confirm(['Are you sure?', 'Once you confirm, all of your account data will be permanently deleted.'])
          .title('Confirmation Required')
          .alignTitle('center')
          .open();
        if (isOk) {
          const lang = state.langCd === 'ko' ? 'en' : state.langCd;
          const { status } = await joinService.withdrawal({ lang });

          if (status === 'error') {
            messageBox.alert(t('message.unknown_error')).open();

            return;
          }
          messageBox.alert(t('message.complete_terminate')).open();

          emit('logout');
          emit('close');
        }
      } catch (error) {
        messageBox.alert(t('message.unknown_error')).open();
      }
    }

    return {
      state,
      schema,
      onWithdrawal,
      WITHDRAWAL_TEXT,
    };
  },
});
