import { WEB_MAPPER_HEIGHT, WEB_MAPPER_WIDTH } from '@hems/util/src/constant/mapper';

import type { ModuleObject, ModuleChild } from 'hems/mapper';

export function checkUserAgent(): string {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.search('android') > -1) {
    return 'aos';
  } else if (userAgent.search('iphone') > -1 || userAgent.search('ipad') > -1) {
    return 'ios';
  } else {
    return 'pc';
  }
}

export function isModuleType(object: ModuleObject): boolean {
  return object.qtype === 'module';
}

export function isArrayType(object: any): boolean {
  return Array.isArray(object._objects) && object._objects.length > 0 && object.qtype === 'array';
}

export function isTextType(object: any): boolean {
  return object.qtype === 'i-text';
}

export function isStringTextType(object: ModuleObject): boolean {
  return object.qtype === 'string-text';
}

export function isRectType(object: ModuleChild): boolean {
  return object.type === 'rect';
}

export function isArraySelectionType(object: ModuleObject): boolean {
  return object.qtype === 'array-selection';
}

export function isInverterType(object: any): boolean {
  return object.qtype === 'inverter';
}

export function isPannelType(object: any): boolean {
  return object.qtype === 'pannel';
}
export function isLogicalTextType(object: ModuleChild): boolean {
  return object.qtype === 'logical_text';
}
export function isTextBoxType(object: any): boolean {
  return object.type === 'textbox';
}

export function isSelectObject(opt: any): boolean {
  if (
    opt &&
    opt.target &&
    opt.target.type &&
    (opt.target.qtype == 'module' ||
      opt.target.qtype == 'array' ||
      opt.target.type == 'i-text' ||
      opt.target.type == 'textbox' ||
      opt.target.qtype == 'inverter')
  ) {
    return true;
  } else {
    return false;
  }
}

export function getSerialNumber(object: any): string {
  if (object) {
    return object.get('qserial');
  }

  return '';
}
export function getInverterNumber(object: any): string {
  if (object) {
    return object.get('inverterSerial');
  }

  return '';
}

// mobile
export function getFingerDistance(e: any): number {
  const diffX = e.touches[0].clientX - e.touches[1].clientX;
  const diffY = e.touches[0].clientY - e.touches[1].clientY;

  return Math.sqrt(diffX * diffX + diffY * diffY); // Pythagorean theorem
}

export function getCanvasSize() {
  const canvasWidth = checkUserAgent() === 'pc' ? WEB_MAPPER_WIDTH : window.innerWidth;
  const canvasHeight = checkUserAgent() === 'pc' ? WEB_MAPPER_HEIGHT : window.innerHeight;

  return [canvasWidth, canvasHeight];
}
