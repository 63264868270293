
import { computed, defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'DotStep',
  props: {
    title: {
      type: String,
      required: true,
    },
    currStep: {
      type: Number,
      required: true,
    },
    stepSize: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      currStep: computed(() => props.currStep),
    });

    return {
      state,
    };
  },
});
