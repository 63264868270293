
import { defineComponent, onBeforeUnmount, onMounted, PropType, reactive, ref, watch } from 'vue';
import * as Highcharts from 'highcharts';

export default defineComponent({
  name: 'Graph',
  components: {},
  props: {
    options: {
      type: Object as PropType<Highcharts.Options>,
      required: true,
    },
  },
  emits: ['instance'],
  setup(props, { emit }) {
    const chart = ref<HTMLDivElement | null>(null);
    const state = reactive<{ instance: Highcharts.Chart | null }>({
      instance: null,
    });

    function drawData(options: Highcharts.Options) {
      if (!options.chart || !chart.value) return;
      options.chart.renderTo = chart.value;
      state.instance = Highcharts.chart(options);
      emit('instance', state.instance);
    }

    watch(
      () => props.options,
      (options: Highcharts.Options) => {
        drawData(options);
      }
    );

    onMounted(() => {
      drawData(props.options);
    });

    onBeforeUnmount(() => {
      state.instance?.destroy();
    });

    return { chart };
  },
});
