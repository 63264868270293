
import { defineComponent, PropType, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Form } from 'vee-validate';
import * as yup from 'yup';

import { DotStep, SettingTable, SettingTableRow, BasicInput, Tooltip, LabelInput, Selector } from '@hems/component';
import Image2 from '@hems/component/src/image/Image2.vue';
import { JoinService } from '@hems/service';
import { Helper, Constant } from '@hems/util';

import { Join } from 'hems/auth';
import { SocialAccountInfo } from 'hems/auth/account';
import { SiteId } from 'hems/device/types';

export default defineComponent({
  name: 'Step2RequiredData',
  components: {
    DotStep,
    SettingTable,
    SettingTableRow,
    BasicInput,
    Form,
    Tooltip,
    LabelInput,
    Selector,
    Image2,
  },
  props: {
    data: {
      type: Object as PropType<Join.GeneralEssentialJoinInfo>,
      required: true,
    },
    social: Object as PropType<SocialAccountInfo>,
    maxStep: {
      type: Number,
      required: false,
      default: 3,
    },
  },
  emits: ['next', 'back'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const joinService = new JoinService(window.axiosInstance.axios);
    const state = reactive<Join.GeneralEssentialJoinInfo>({
      site_id: props.data.site_id,
      user_id: props.social?.email ?? props.data.user_id,
      user_nm: props.social?.name ?? props.data.user_nm,
      password: props.data.password,
      mpn_no: props.data.mpn_no,
      email: props.social?.email ?? props.data.email,
      pin_code: props.data.pin_code,
      local_mpn_no: props.data.local_mpn_no,
      auth_type_cd: props.data.auth_type_cd,
    });
    const stateValidationCheck = reactive<Join.PasswordValidationCheck>({
      checkValidation1: false,
      checkValidation2: false,
      checkValidation3: false,
    });

    const prevSiteId = {
      value: undefined as undefined | SiteId,
      status: false,
    };
    const prevUserId = {
      value: undefined as undefined | string,
      status: false,
    };
    const prevEmail = {
      value: undefined as undefined | string,
      status: false,
    };

    const schema = yup.object().shape({
      site_id: yup
        .number()
        .nullable()
        .transform((v, o) => (o === '' ? null : v))
        .isValidSiteId(prevSiteId),
      user_id: yup.string().nullable().isValidUserId(prevUserId),
      user_nm: yup.string().test('user_nm', { key: 'message.invalid' }, function (user_nm?: string) {
        const { createError } = this;
        if (Helper.isNull(user_nm)) {
          return createError({
            message: { key: 'message.field_required' },
          });
        }
        if (user_nm.length < 2) {
          return createError({
            message: { key: 'message.too_short' },
          });
        }

        return true;
      }),
      password: yup.string().when({
        is: () => !props.social,
        then: yup
          .string()
          .test('password', { key: '' }, function (password?: string) {
            const { createError } = this;
            stateValidationCheck.checkValidation1 = false;
            stateValidationCheck.checkValidation2 = false;
            stateValidationCheck.checkValidation3 = false;
            if (Helper.isNull(password)) {
              return createError({
                message: { key: 'message.field_required' },
              });
            }
            const regexp1 = /^.{8,20}$/;
            const regexp2 = /^(?=.*[a-zA-Z])(?=.*\d).+$/;
            const regexp3 = /.*[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*/;
            if (regexp1.test(password)) {
              stateValidationCheck.checkValidation1 = true;
            } else {
              stateValidationCheck.checkValidation1 = false;
            }
            if (regexp2.test(password)) {
              stateValidationCheck.checkValidation2 = true;
            } else {
              stateValidationCheck.checkValidation2 = false;
            }
            if (regexp3.test(password)) {
              stateValidationCheck.checkValidation3 = true;
            } else {
              stateValidationCheck.checkValidation3 = false;
            }
            if (
              stateValidationCheck.checkValidation1 === true &&
              stateValidationCheck.checkValidation2 === true &&
              stateValidationCheck.checkValidation3 === true
            ) {
              return true;
            } else {
              return false;
            }
          })
          .nullable()
          .required(),
      }),
      password_confirm: yup
        .string()
        .nullable()
        .test('password_confirm', { key: 'message.invalid' }, function (password_confirm?: string | null) {
          if (props.social) return true;
          const { createError } = this;
          const password = this.parent['password'];
          if (Helper.isNull(password_confirm)) {
            return createError({
              message: { key: 'message.field_required' },
            });
          } else {
            if (password !== password_confirm) {
              return createError({
                message: { key: 'message.password_not_match' },
              });
            } else {
              return true;
            }
          }
        }),
      local_mpn_no: yup.string().nullable().required(),
      mpn_no: yup
        .string()
        .test('mpn_no', { key: 'message.invalid_phone_number' }, function (mpn_no?: string) {
          const { createError } = this;
          if (Helper.isNull(mpn_no)) {
            return createError({
              message: { key: 'message.field_required' },
            });
          }
          const regexp = /^[0-9+\-()]{8,20}$/g;
          if (!regexp.test(mpn_no)) {
            return false;
          }

          return true;
        })
        .nullable()
        .required(),
      email: yup.string().nullable().isValidEmail(prevEmail),
      pin_code: yup
        .string()
        .nullable()
        .isValidByFn(
          'join_pin_code',
          async (value) => {
            const siteId = state.site_id;
            if (Helper.isNull(value)) {
              return {
                isValid: false,
                errMsg: t('message.field_required'),
              };
            }
            if (!/^(?:\d{4}|\d{6})$/.test(value)) {
              return { isValid: false, errMsg: t('message.used_customer_registers') };
            }
            if (Helper.isNull(siteId)) {
              return { isValid: false, errMsg: t('message.check_site_id') };
            }

            try {
              const { result } = await joinService.checkPinCode(siteId, value);

              return { isValid: result.is_valid };
            } catch (e) {
              return { isValid: false };
            }
          },
          () => prevSiteId.value !== state.site_id
        ),
    });

    const passwordConfirm = ref(null as null | string);

    function onNext() {
      emit('next', 'essentialData', { ...state });
    }

    const dialSelectOptions = Helper.addSelectorOptionAtFirst(
      Helper.getDialCodeByCountry().map((item) => ({
        text: `${item.name} ${item.dial_code}`,
        value: item.dial_code,
      })),
      { text: t('common.select'), value: null }
    );

    return {
      state,
      passwordConfirm,
      schema,
      onNext,
      dialSelectOptions,
      stateValidationCheck,
    };
  },
});
