
import { computed, defineComponent, Prop, PropType, reactive } from 'vue';
import * as moment from 'moment';
import VCalendarWrapper from '@hems/component/src/calendars/VCalendarWrapper.vue';
import { useModelWrapper } from '@hems/util';
import { CalendarModeType } from '.';

export type CalendarRangeValue = { start: Date; end: Date };
export default defineComponent({
  name: 'VCalendarRange',
  components: {
    VCalendarWrapper,
  },
  props: {
    modelValue: {
      type: Object as PropType<CalendarRangeValue>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: String,
    format: String,
    mode: {
      type: String as PropType<CalendarModeType>,
    },
    minDate: Date as Prop<Date>,
    maxDate: Date as Prop<Date>,
    validate: Function as Prop<(date: CalendarRangeValue, beforeDate: CalendarRangeValue) => boolean>,
  },
  setup(props, { emit }) {
    const state = reactive({
      open: false,
      disabled: computed(() => props.disabled),
      value: useModelWrapper<CalendarRangeValue>(props, emit),
      displayStartValue: computed(() => {
        if (!props.modelValue.start) return null;
        return props.format
          ? moment.default(props.modelValue.start).format(props.format)
          : moment.default(props.modelValue.start).locale(navigator.language).format('LLL');
      }),
      displayEndValue: computed(() => {
        if (!props.modelValue.end) return null;
        return props.format
          ? moment.default(props.modelValue.end).format(props.format)
          : moment.default(props.modelValue.end).locale(navigator.language).format('LLL');
      }),
    });

    const dateMode = computed(() => (props.mode ? props.mode : 'datetime'));

    return {
      state,
      dateMode,
      onOpen: () => (state.open = true),
      onClose: () => (state.open = false),
    };
  },
});
