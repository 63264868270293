
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import gsap, { Power1 } from 'gsap';
export default defineComponent({
  name: 'VisualText',
  props: {
    imageNum: Number,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      activeNum: computed(() => store.state.appCtx.bgImageNum),
    });
    const text1 = ref(null as null | HTMLDivElement);
    const text2 = ref(null as null | HTMLDivElement);
    const text3 = ref(null as null | HTMLDivElement);
    function onClickPagerLink(e: MouseEvent, num: number) {
      e.preventDefault();
      store.dispatch('appCtx/setBgImageNum', num);
    }
    watch(
      () => state.activeNum,
      () => {
        animateText();
      }
    );
    function animateText() {
      const duration = 1;
      const delay = 0.3;
      gsap
        .fromTo(
          [text1.value, text2.value, text3.value],
          {
            visibility: 'hidden',
            opacity: 0,
            transform: 'translateX(30%)',
            letterSpacing: '1rem',
          },
          { visibility: 'visible' }
        )
        .then(() => {
          gsap.to([text1.value] as gsap.TweenTarget, {
            opacity: 1,
            transform: 'translateX(0%)',
            letterSpacing: '0px',
            ease: Power1.easeInOut,
            duration,
            delay: delay * 0,
          });
          gsap.to([text2.value] as gsap.TweenTarget, {
            opacity: 1,
            transform: 'translateX(0%)',
            letterSpacing: '0px',
            ease: Power1.easeInOut,
            duration,
            delay: delay * 1,
          });
          gsap.to([text3.value] as gsap.TweenTarget, {
            opacity: 1,
            transform: 'translateX(0%)',
            letterSpacing: '0px',
            ease: Power1.easeInOut,
            duration,
            delay: delay * 2,
          });
        });
    }

    onMounted(() => {
      animateText();
    });

    return {
      state,
      text1,
      text2,
      text3,
      onClickPagerLink,
    };
  },
});
