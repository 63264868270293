import { AxiosInstance } from 'axios';
import { CommonResponseWrapper, Device } from 'hems';
import { apiWrapper } from '../../util/helper';
import { DeviceService } from './DeviceService';
import { SiteId } from 'hems/device';

interface changeInstallerParams {
  siteId: number;
  installerId: string;
  deviceId: string;
}
export default class DeviceServiceAdmin extends DeviceService {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  // TODO: 사용하지 않음 -> 삭제
  public async getRecentInstalledDeviceList(pageNum: number): Promise<Device.RecentInstalledDevice[]> {
    return await this.get<Device.RecentInstalledDevice[]>(apiWrapper.adminAPI('/main/prod/list'), { pageNum });
  }

  // TODO: 사용하지 않음 -> 삭제
  public async getDeviceSimpleInfo(deviceId: Device.DeviceId): Promise<Device.DeviceSimpleInfo> {
    return await this.get<Device.DeviceSimpleInfo>(apiWrapper.adminAPI(`/mon/type/${deviceId}`));
  }

  public async changeInstaller(params: changeInstallerParams): Promise<Response> {
    const { data } = await this.put<CommonResponseWrapper<Response>>(
      apiWrapper.managerApi(`/devices/profiles/${params.siteId}/installers`),
      {},
      { params }
    );
    return data;
  }

  /** @TODO AC System TEST를 위한 임시 API로 추후 삭제 요망 */
  public deleteDeviceForACSystem(siteId: SiteId): Promise<CommonResponseWrapper<{siteId: SiteId}>> {
    return this.delete<CommonResponseWrapper<{siteId: SiteId}>>(apiWrapper.managerApi(`/devices/installations/sqa/delete/${siteId}`));
  }
}