
import { defineComponent } from 'vue';

import Image from '@/components/image/Image.vue';

export default defineComponent({
  name: 'MobileAppLink',
  components: {
    Image,
  },
  props: {
    imgUri: {
      type: String,
      required: true,
    },
    imgQrUri: {
      type: String,
      required: true,
    },
    mobileAppName: {
      type: String,
      required: true,
    },
  },
});
