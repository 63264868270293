import { AxiosInstance } from 'axios';
import { Join } from 'hems/auth';
import { SocialAccountInfo } from 'hems/auth/account';
import { SiteId } from 'hems/device';
import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';
import { CommonResponseWrapper, LangCd } from 'hems';
import { Helper } from '@hems/util';

export default class JoinService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async checkSiteId(siteId: SiteId): Promise<Join.ValidJoinResponse> {
    const { data } = await this.get<CommonResponseWrapper<Join.ValidJoinResponse>>(
      apiWrapper.managerApi(`/devices/profiles/validations/${siteId}/site-id`)
    );
    return data;
  }
  // TODO: 사용 안함 -> 추후 삭제
  public async checkUserId(userId: string): Promise<Join.ValidJoinResponse> {
    return await this.get(apiWrapper.userAPI(`/valid/id/${userId}`));
  }
  // TODO: 사용 안함 -> 추후 삭제
  public async checkEmail(email: string): Promise<Join.ValidJoinResponse> {
    return await this.get(apiWrapper.userAPI(`/valid/email/${email}`));
  }
  public async checkUserIdEmail(verify: string, userId: string | null = null): Promise<Join.ValidJoinResponse> {
    if (userId !== null) {
      const { data } = await this.get<CommonResponseWrapper<Join.ValidJoinResponse>>(
        apiWrapper.managerApi(`/managements/users/validations/email-user-id`),
        { emailUserId: verify, userId }
      );
      return data;
    }
    const { data } = await this.get<CommonResponseWrapper<Join.ValidJoinResponse>>(
      apiWrapper.managerApi(`/managements/users/validations/email-user-id`),
      { emailUserId: verify }
    );
    return data;
  }
  public async checkPinCode(siteId: SiteId, pinCode: string): Promise<Join.ValidJoinResponse> {
    const { data } = await this.get<CommonResponseWrapper<Join.ValidJoinResponse>>(
      apiWrapper.managerApi(`/devices/profiles/validations/pin-codes`),
      { siteId, pinCode }
    );
    return data;
  }

  public async joinGeneralMember(params: Join.GeneralJoinParam, social?: SocialAccountInfo): Promise<any> {
    // TODO: 일반 회원 가입에서 user_name, tel_num, local_mpn_no 은 필수 값으로 변경 되었지만 API는 추후 변경 될 예정이라 일단 optional 필드로 전송함. 따라서 추후 변경될 수 있음.
    const { issuer, issuerToken } = social ?? {};
    const _params: any = { ...params, essential_info: { ...params.essential_info } };
    const user_nm = params.essential_info.user_nm;
    delete _params.essential_info.user_nm;
    _params.user_nm = user_nm;
    const mpn_no = params.essential_info.mpn_no;
    delete _params.essential_info.mpn_no;
    _params.mpn_no = mpn_no;
    const local_mpn_no = params.essential_info.local_mpn_no;
    delete _params.essential_info.local_mpn_no;
    _params.local_mpn_no = local_mpn_no;
    _params.essential_info = Helper.keyToCamelCase(_params.essential_info);
    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/users/join`),
      Helper.keyToCamelCase(_params),
      {
        headers: { issuer, issuer_token: issuerToken },
      }
    );
    return data;
  }
  public async joinInstaller(params: Join.InstallerJoinParam, social?: SocialAccountInfo): Promise<any> {
    const { issuer, issuerToken } = social ?? {};
    const essential_info: any = {
      user_id: params.user_id,
      password: params.password,
      email: params.email,
      auth_type_cd: params.auth_type_cd,
    };
    const _params: any = { ...params, essential_info: Helper.keyToCamelCase(essential_info) };
    delete _params.user_id;
    delete _params.password;
    delete _params.email;
    delete _params.auth_type_cd;

    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/users/join`),
      Helper.keyToCamelCase(_params),
      {
        headers: { issuer, issuer_token: issuerToken },
      }
    );
    return data;
  }

  public async getInstallerGroups(): Promise<Join.InstallerGroupResponse> {
    const { data } = await this.get<CommonResponseWrapper<Join.InstallerGroupResponse>>(
      apiWrapper.managerApi(`/managements/users/installer-groups`)
    );
    return data;
  }

  public async withdrawal(params: { lang: LangCd }): Promise<Join.WithdrawalResponse> {
    const { data } = await this.put<CommonResponseWrapper<Join.WithdrawalResponse>>(
      apiWrapper.managerApi(`/managements/users/social-login-users?lang=${params.lang}`)
    );
    return data;
  }
}
