/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import _ from 'lodash';

import { NUMBER_UNIT } from '@hems/util/src/constant';
import { attachUnit } from '@hems/util/src/helper/helper';
import { floorTwoDecimal } from '@hems/util/src/helper/numberformatHelper';

import type { DeviceProfileAllGenType } from 'hems/device/siteinfo';

export const getMultipleDashboardData = (
  label: string[],
  data: (number | string | null)[],
  unit?: string
): string[] => {
  return data.map((value, index) => {
    const valueWithUnit = unit ? attachUnit(value, unit) : value;

    return `${label[index]}: ${valueWithUnit ?? ''}`;
  });
};

export const convertDeviceProfileData = (deviceInfo: DeviceProfileAllGenType): DeviceProfileAllGenType => {
  const convertedData = { ...deviceInfo };
  for (const key in deviceInfo) {
    if (typeof convertedData[key] === 'number') {
      convertedData[key] = _.floor(Number(convertedData[key]), 2);
    }
  }

  return convertedData;
};

/** PCS, Battery 버전 변환 함수 (00.00) */
export const formatToTwoDecimalVersion = (value: string): string => (Number(value) / NUMBER_UNIT.HUNDRED).toFixed(2);

/** BMS 버전 변환 함수 (00.000) */
export const formatToThreeDecimalVersion = (value: string): string => (Number(value) / NUMBER_UNIT.THOUSAND).toFixed(3);

export const calculatePowerFactor = (activePower: number, reactivePower: number): number => {
  const apparentPower = Math.sqrt(Math.pow(activePower, 2) + Math.pow(reactivePower, 2));

  return floorTwoDecimal(activePower / apparentPower);
};
