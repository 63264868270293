
import type { PropType } from 'vue';
import { defineComponent, reactive } from 'vue';

import { useModelWrapper } from '@hems/util';

import type { GroupDetailTab } from 'hems/group';

export default defineComponent({
  name: 'GroupDetailsTabContainer',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array as PropType<GroupDetailTab[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      tabname: useModelWrapper(props, emit),
    });

    const switchTab = (tabname: string) => {
      state.tabname = tabname;
    };

    return {
      state,
      switchTab,
    };
  },
});
