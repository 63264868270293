import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Suspense as _Suspense } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_MainTemplate = _resolveComponent("MainTemplate")!
  const _component_HealthCheckContainer = _resolveComponent("HealthCheckContainer")!

  return (_openBlock(), _createBlock(_Suspense, null, {
    default: _withCtx(() => [
      (!_ctx.isMobile)
        ? (_openBlock(), _createBlock(_component_HealthCheckContainer, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_MainTemplate, {
                class: _normalizeClass({ [`lang-${_ctx.languageCode}`]: true })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_RouterView)
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_RouterView)
          ]))
    ]),
    _: 1
  }))
}