import { AxiosInstance } from 'axios';
import { Service } from '../Service';
import {
  NotificationItemInfo,
  GetNotificationListParams,
  UnRecognitionNotificationInfo,
  PostNotificationRecognitionParams,
  NotificationModalInfo,
} from 'hems/notification';
import { apiWrapper } from '../../util/helper';
import { CommonResponseWrapper } from 'hems';

export default class NotificationService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getUnRecognitionNotification(): Promise<UnRecognitionNotificationInfo> {
    const { data } = await this.get<CommonResponseWrapper<UnRecognitionNotificationInfo>>(
      apiWrapper.notificationApi('/history/un-recognition')
    );
    return data;
  }

  public async getNotificationList(params: GetNotificationListParams): Promise<NotificationItemInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<NotificationItemInfo[]>>(
      apiWrapper.notificationApi('/history'),
      params
    );
    return data;
  }

  public async updateNotificationRead(notificationId: string): Promise<any> {
    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.notificationApi(`/history/read/${notificationId}`)
    );
    return data;
  }

  public async updateNotificationRecognition(params: PostNotificationRecognitionParams): Promise<any> {
    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.notificationApi('/history/recognition'),
      params
    );
    return data;
  }

  public async getNotificationModals(): Promise<NotificationModalInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<NotificationModalInfo[]>>(
      apiWrapper.notificationApi('/modals')
    );
    return data;
  }
}

export { NotificationService };
