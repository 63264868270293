import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19957c58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fm_wrap" }
const _hoisted_2 = {
  key: 0,
  class: "label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_Multiselect, {
      modelValue: _ctx.state.inputValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.inputValue) = $event)),
      mode: "tags",
      placeholder: _ctx.state.placeholder,
      "close-on-select": false,
      options: _ctx.selOptions,
      searchable: true
    }, null, 8, ["modelValue", "placeholder", "options"])
  ]))
}