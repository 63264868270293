
import { defineComponent, reactive, computed, PropType, watch } from 'vue';
import { NotificationItemInfo, NotificationCardInfo } from 'hems/notification';
import Image2 from '../../image/Image2.vue';
import NotificationItem from './NotificationItem.vue';
import _ from 'lodash';
import { LangCd } from 'hems';
import { DateHelper, Helper } from '@hems/util';
import { OnClickOutside } from '@vueuse/components';

export default defineComponent({
  name: 'NotificationListDropdown',
  components: {
    Image2,
    NotificationItem,
    OnClickOutside,
  },
  props: {
    isExistUnrecognitionNotification: {
      type: Boolean,
      default: false,
    },
    tabList: {
      type: Array as PropType<{ text: string; value: string }[]>,
      default: () => ['Notice'],
    },
    notificationList: {
      type: Array as PropType<NotificationItemInfo[]>,
      default: () => [],
    },
    langCd: {
      type: String as PropType<LangCd>,
      default: 'en',
    },
  },
  emits: ['open', 'updateNotificationRead'],
  setup(props, { emit }) {
    const state = reactive({
      isExistUnrecognitionNotification: computed(() => props.isExistUnrecognitionNotification),
      showNotificationList: false,
      currentTab: props.tabList[0].value,
      notificationList: getFormattedNotificationList(props.notificationList ?? [], props.tabList[0].value),
    });

    function getFormattedDateString(date: string, langCd: LangCd = 'en'): string {
      return DateHelper.getDateFormatter(langCd, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
        .format(new Date(date))
        .toString();
    }

    function getFormattedNotificationList(data: NotificationItemInfo[], category: string): NotificationCardInfo[] {
      if (_.isEmpty(data)) return [];
      const notificationListForCurrentTab = data
        .filter((item) => item.category === category)
        .map((item) => {
          if (item.category === 'MKT') {
            return {
              notiId: item.notiId,
              category: item.category,
              iconInfo: {
                isIcon: true,
                iconPath: 'arcstream/ic_re_symbol_arc',
                extension: 'svg',
              },
              notificationInfo: {
                read: item.isRead === 'Y' ? true : false,
                title: item.title,
                contents: item.message,
                date: getFormattedDateString(item.createDt, props.langCd),
              },
            };
          } else {
            return {
              notiId: item.notiId,
              category: item.category,
              notificationInfo: {
                read: item.isRead === 'Y' ? true : false,
                title: item.title,
                contents: item.message,
                date: getFormattedDateString(item.createDt, props.langCd),
              },
            };
          }
        });
      return notificationListForCurrentTab;
    }

    const onClickNotificationIcon = () => {
      state.showNotificationList = !state.showNotificationList;
      if (state.showNotificationList) {
        emit('open');
      }
    };

    const onMoveTab = (tabValue: string) => {
      state.currentTab = tabValue;
      state.notificationList = getFormattedNotificationList(props.notificationList ?? [], tabValue);
    };

    const onClickOutside = () => {
      state.showNotificationList = false;
    };

    watch([() => props.notificationList, () => props.langCd], () => {
      state.notificationList = getFormattedNotificationList(props.notificationList ?? [], state.currentTab);
    });

    return {
      state,
      onClickNotificationIcon,
      onMoveTab,
      _,
      onClickOutside,
    };
  },
});
