
import { defineComponent, reactive, PropType } from 'vue';
import NavMainItem from '@hems/component/src/navigation/NavMainItem.vue';
import { NavItem } from 'hems';

export default defineComponent({
  name: 'Navigation',
  components: {
    NavMainItem,
  },
  props: {
    navItems: {
      type: Array as PropType<NavItem[]>,
      default: () => [],
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const state = reactive({});

    const onClick = (e: MouseEvent, path: string) => {
      emit('click', e, path);
    };

    return {
      state,
      onClick,
    };
  },
});
