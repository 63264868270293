
import { defineComponent, nextTick, onMounted, reactive, ref, Ref } from 'vue';
import { useField } from 'vee-validate';
export type SignPadPoint = { lx: number; ly: number; mx: number; my: number };
import { ErrorMessageLabel } from '@hems/component';

export default defineComponent({
  name: 'SignPadEditor',
  components: {
    ErrorMessageLabel,
  },
  props: {
    name: String,
    height: Number,
    width: Number,
  },
  emits: ['updateAgreeSignBinary'],
  setup(props, { emit }) {
    const state = reactive({
      height: props.height || 200,
      width: props.width || 650,
      sigImgUrl: null as string | null,
      isError: false,
    });
    const sigPad: Ref<HTMLElement | null> = ref(null);
    const sigPadOutput: Ref<HTMLInputElement | null> = ref(null);

    const { value: inputValue, errorMessage } = useField<string>(props.name || 'output', undefined);

    onMounted(() => {
      if (!sigPad.value) return;

      $(sigPad.value).signaturePad({
        drawOnly: true,
        lineMargin: state.width * (19 / 20),
        lineTop: state.height * (8 / 10),
        validateFields: false,
        onDrawEnd: function () {
          nextTick(() => {
            if (!sigPadOutput.value) {
              setValue('');
              return;
            }
            setValue(sigPadOutput.value.value);
          });
        },
      });
    });

    function toImage(agreeSgin?: string): string | undefined {
      const data: SignPadPoint[] = JSON.parse(agreeSgin || '[]');
      if (!sigPad.value) return;

      $(sigPad.value).signaturePad({ displayOnly: true }).regenerate(data);
      return $(sigPad.value).signaturePad().getSignatureImage();
    }

    function setValue(value: string) {
      inputValue.value = value;
      if (value === '') {
        emit('updateAgreeSignBinary', null);
        return;
      }
      emit('updateAgreeSignBinary', toImage(value)?.replace('data:image/png;base64,', ''));
    }

    function onClear() {
      if (!sigPad.value) return;
      setValue('');
    }

    return {
      state,
      sigPad,
      sigPadOutput,
      errorMessage,
      onClear,
    };
  },
});
