import { AxiosInstance } from 'axios';
import { Service } from '../Service';
import { CurrentWeatherInfo } from 'hems/weather';
import { apiWrapper } from '../../util/helper';

export default class WeatherService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  // Retrieve Current Weather data
  public async getCurrentWeather(siteId: string): Promise<CurrentWeatherInfo> {
    return await this.get<CurrentWeatherInfo>(apiWrapper.weatherAPI(`/qcells-${siteId}/currentweatherdata`));
  }
}
