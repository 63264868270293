import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e258820"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-size-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Selector = _resolveComponent("Selector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Selector, {
      modelValue: _ctx.state.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.value) = $event)),
      "value-type": "number",
      style: {"height":"1rem"},
      options: _ctx.state.size,
      "after-change": _ctx.onChange
    }, null, 8, ["modelValue", "options", "after-change"])
  ]))
}