import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "agree_ps" }
const _hoisted_2 = { class: "btn_wrap_b mt40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DotStep = _resolveComponent("DotStep")!
  const _component_TermsGroup = _resolveComponent("TermsGroup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DotStep, {
      title: _ctx.$t('common.terms_and_policies'),
      "curr-step": 1,
      "step-size": 2
    }, null, 8, ["title"]),
    _createVNode(_component_TermsGroup, {
      modelValue: _ctx.state.termAndUse,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.termAndUse) = $event)),
      "terms-title-key": _ctx.getTermsTitleKey(_ctx.REQUIRED_TERMS_CATEGORY.TERMS_OF_SERVICE, _ctx.state.locale),
      "agreement-title-key": _ctx.getAgreementTitleKey(_ctx.REQUIRED_TERMS_CATEGORY.TERMS_OF_SERVICE, _ctx.state.locale),
      "terms-contents": _ctx.state.termsData[_ctx.REQUIRED_TERMS_CATEGORY.TERMS_OF_SERVICE].terms_contents
    }, null, 8, ["modelValue", "terms-title-key", "agreement-title-key", "terms-contents"]),
    (_ctx.state.locale !== 'US')
      ? (_openBlock(), _createBlock(_component_TermsGroup, {
          key: 0,
          modelValue: _ctx.state.crossBorders,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.crossBorders) = $event)),
          "terms-title-key": _ctx.getTermsTitleKey(_ctx.REQUIRED_TERMS_CATEGORY.TERMS_OF_REGION, _ctx.state.locale),
          "agreement-title-key": _ctx.getAgreementTitleKey(_ctx.REQUIRED_TERMS_CATEGORY.TERMS_OF_REGION, _ctx.state.locale),
          "terms-contents": _ctx.state.termsData[_ctx.REQUIRED_TERMS_CATEGORY.TERMS_OF_REGION].terms_contents
        }, null, 8, ["modelValue", "terms-title-key", "agreement-title-key", "terms-contents"]))
      : _createCommentVNode("", true),
    _createVNode(_component_TermsGroup, {
      modelValue: _ctx.state.privacyPolicy,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.privacyPolicy) = $event)),
      "terms-title-key": _ctx.getTermsTitleKey(_ctx.REQUIRED_TERMS_CATEGORY.TERMS_OF_PRIVACY, _ctx.state.locale),
      "agreement-title-key": _ctx.getAgreementTitleKey(_ctx.REQUIRED_TERMS_CATEGORY.TERMS_OF_PRIVACY, _ctx.state.locale),
      "terms-contents": _ctx.state.termsData[_ctx.REQUIRED_TERMS_CATEGORY.TERMS_OF_PRIVACY].terms_contents
    }, null, 8, ["modelValue", "terms-title-key", "agreement-title-key", "terms-contents"]),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('message.agree_req_terms')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        href: "#",
        class: "btn_l btn_c_3 width100",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onNext && _ctx.onNext(...args)))
      }, _toDisplayString(_ctx.$t('common.next')), 1)
    ])
  ], 64))
}