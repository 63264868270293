import _ from 'lodash';
import * as yup from 'yup';

import type { SelectorOption } from 'hems';

import type { SettingKeysType } from 'hems/common/settingConfig';
import type { SettingConfigChild, SettingConfigHeader, SettingConfigGroup } from 'hems/common/settingConfig';
import type { AdvancedSettingColumnRange } from 'hems/device/settings';
import type { AdvancedSettingsKey as AdvancedSettingsKeyGEN2 } from 'hems/device/settings/pvmodule/gen2';
import type { AdvancedSettingsKey as AdvancedSettingsKeyGEN3 } from 'hems/device/settings/pvmodule/gen3';
import type { AdvancedSettingsKey as AdvancedSettingsKeyACSys } from 'hems/device/settings/smartmodule/acsys';

type AdvancedSettingKeys = AdvancedSettingsKeyGEN2 | AdvancedSettingsKeyGEN3 | AdvancedSettingsKeyACSys;

import type { AdvancedSettings as ACSystemAdvancedSettings } from 'hems/device/settings/smartmodule/acsys';

function isHeader<T extends SettingKeysType>(item: SettingConfigChild<T>): item is SettingConfigHeader<T> {
  return item.type === 'header';
}

export function getEditableSettingConfig<T extends SettingKeysType>(
  settingConfig: SettingConfigGroup<T>[],
  editableColumns: string[] = [],
  excludedColumns: string[] = []
): SettingConfigGroup<T>[] {
  return settingConfig.reduce((acc, curr) => {
    const children = curr.children.filter((item) =>
      isHeader<T>(item)
        ? false
        : editableColumns.includes(String(item.code)) && !excludedColumns.includes(String(item.code))
    );
    if (children.length > 0) {
      acc.push({ ...curr, ...{ canEdit: true, children } });
    }

    return acc;
  }, [] as SettingConfigGroup<T>[]);
}

export function getSchema(advancedSettingConfig: SettingConfigGroup<AdvancedSettingKeys>[]): yup.AnySchema {
  const shape = advancedSettingConfig
    .map((group) =>
      group.children
        .map((item) =>
          (item.type === 'number' || item.type === 'selector' || item.type === 'toggle') && item.schema
            ? { [item.code]: item.schema() }
            : {}
        )
        .flat()
    )
    .flat()
    .reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

  return yup.object().shape(shape);
}

export function getACSystemSchema(
  advancedSettingConfig: SettingConfigGroup<AdvancedSettingsKeyACSys>[]
): yup.AnySchema {
  const shape = advancedSettingConfig
    .map((group) =>
      group.children
        .map((item) =>
          (item.type === 'number' || item.type === 'selector' || item.type === 'toggle') && item.schema && item.name
            ? { [item.name]: item.schema() }
            : {}
        )
        .flat()
    )
    .flat()
    .reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

  return yup.object().shape(shape);
}

type SelectorOptions = {
  energyPolicy: SelectorOption[];
  extctlGwConnection: SelectorOption[];
};

export function installSettingGen3Config(
  t: (key: string | number) => string,
  options: SelectorOptions = { energyPolicy: [], extctlGwConnection: [] },
  excludedGroupTitle: string[] = []
): SettingConfigGroup<AdvancedSettingsKeyGEN3>[] {
  return [
    {
      groupTitle: 'FCAS',
      role: ['admin', 'service', 'dev'],
      children: [
        {
          title: 'FCAS Flag',
          code: 'fcas_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Response Capacity limit',
          code: 'fcas_response_capacity_limit',
          type: 'number',
        },
        {
          title: 'Droop Rate Raise',
          code: 'fcas_droop_rate_raise',
          type: 'number',
        },
        {
          title: 'Droop Rate Lower',
          code: 'fcas_droop_rate_lower',
          type: 'number',
        },
        {
          title: 'Oftb Max',
          code: 'fcas_oftb_max',
          type: 'number',
        },
        {
          title: 'Oftb Min',
          code: 'fcas_oftb_min',
          type: 'number',
        },
        {
          title: 'Fr Max',
          code: 'fcas_fr_max',
          type: 'number',
        },
        {
          title: 'Fr Min',
          code: 'fcas_fr_min',
          type: 'number',
        },
        {
          title: 'Nofb Max',
          code: 'fcas_nofb_max',
          type: 'number',
        },
        {
          title: 'Nofb Min',
          code: 'fcas_nofb_min',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: 'Basic Setting',
      role: ['admin', 'installer', 'service', 'dev'],
      children: [
        {
          title: t('control.external_control'),
          code: 'external_control_connection',
          type: 'selector',
          selector: options.extctlGwConnection,
        },
        {
          title: t('control.energy_policy'),
          code: 'energy_policy',
          type: 'selector',
          selector: options.energyPolicy,
        },
        {
          title: 'Multiple Earthed Neutral(MEN) system',
          code: 'inverter_multiple_earthed_neutral_system',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: `${t('device.string_level')} / ${t('device.module_level')}`,
          code: 'pv_capacity_calc_option',
          type: 'radio',
          options: [
            { text: t('device.string_level'), value: 0 },
            { text: t('device.module_level'), value: 1 },
          ],
        },
        {
          title: t('device.pv_string_quantity'),
          code: 'pv_string_count',
          type: 'selector',
          options: [
            { text: t('common.select'), value: null },
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
          ],
        },
        {
          title: `PV-1 ${t('device.power')}`,
          code: 'pv_string_power1',
          type: 'number',
        },
        {
          title: `PV-2 ${t('device.power')}`,
          code: 'pv_string_power2',
          type: 'number',
        },
        {
          title: `PV-3 ${t('device.power')}`,
          code: 'pv_string_power3',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: 'Engineer Setting',
      role: ['admin', 'service', 'dev'],
      children: [
        {
          title: 'Inverter limit',
          code: 'inverter_max_output_power',
          type: 'number',
        },
        {
          title: 'Grid target frequency',
          code: 'inverter_grid_target_frequency',
          type: 'number',
        },
        {
          title: 'External generator mode',
          code: 'inverter_external_generator_mode',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
      ],
    },
  ].filter((item) => !excludedGroupTitle.includes(item.groupTitle)) as SettingConfigGroup<AdvancedSettingsKeyGEN3>[];
}
export function installSettingACMIConfig(
  t: (key: string | number) => string,
  options: SelectorOptions = { energyPolicy: [], extctlGwConnection: [] },
  excludedGroupTitle: string[] = []
): SettingConfigGroup<AdvancedSettingsKeyACSys>[] {
  return [
    {
      groupTitle: 'Basic Setting',
      role: ['admin', 'installer', 'service', 'dev'],
      children: [
        {
          title: `${t('control.feedin')} (W)`,
          code: 'pv_feed_in_limit_w',
          type: 'number',
        },
        {
          title: t('control.energy_policy'),
          code: 'energy_policy',
          type: 'selector',
          selector: options.energyPolicy,
        },
      ],
    },
    {
      groupTitle: 'Engineer Setting',
      role: ['admin', 'service', 'dev'],
      children: [
        {
          title: 'Grid target frequency',
          code: 'inverter_grid_target_frequency',
          type: 'number',
        },
        {
          title: 'Permit service mode select flag',
          code: 'inverter_permit_service_mode_select',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Enter service randomized delay flag',
          code: 'inverter_enter_service_randomized_delay',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
      ],
    },
  ].filter((item) => !excludedGroupTitle.includes(item.groupTitle)) as SettingConfigGroup<AdvancedSettingsKeyACSys>[];
}
export function installSettingACESConfig(
  t: (key: string | number) => string,
  options: SelectorOptions = { energyPolicy: [], extctlGwConnection: [] },
  excludedGroupTitle: string[] = []
): SettingConfigGroup<AdvancedSettingsKeyACSys>[] {
  return [
    {
      groupTitle: 'Basic Setting',
      role: ['admin', 'installer', 'service', 'dev'],
      children: [
        {
          title: `${t('control.feedin')} (W)`,
          code: 'pv_feed_in_limit_w',
          type: 'number',
        },
        {
          title: t('control.energy_policy'),
          code: 'energy_policy',
          type: 'selector',
          selector: options.energyPolicy,
        },
        {
          title: t('device.pv_string_quantity'),
          code: 'pv_string_count',
          type: 'selector',
          options: [
            { text: t('common.select'), value: null },
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
          ],
        },
        {
          title: `PV-1 ${t('device.power')}`,
          code: 'pv_string_power1',
          type: 'number',
        },
        {
          title: `PV-2 ${t('device.power')}`,
          code: 'pv_string_power2',
          type: 'number',
        },
        {
          title: `PV-3 ${t('device.power')}`,
          code: 'pv_string_power3',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: 'Engineer Setting',
      role: ['admin', 'service', 'dev'],
      children: [
        {
          title: 'Inverter limit',
          code: 'inverter_max_output_power',
          type: 'number',
        },
        {
          title: 'Grid target frequency',
          code: 'inverter_grid_target_frequency',
          type: 'number',
        },
        {
          title: 'Permit service mode select flag',
          code: 'inverter_permit_service_mode_select',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Enter service randomized delay flag',
          code: 'inverter_enter_service_randomized_delay',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
      ],
    },
  ].filter((item) => !excludedGroupTitle.includes(item.groupTitle)) as SettingConfigGroup<AdvancedSettingsKeyACSys>[];
}

export function getIncludedSettingConfig<T extends SettingKeysType>(
  advancedSettingConfig: SettingConfigGroup<T>[],
  excludedColumns: string[] = []
): SettingConfigGroup<T>[] {
  return advancedSettingConfig.reduce((acc, curr) => {
    const children = curr.children.filter((item) =>
      isHeader(item) ? false : !excludedColumns.includes(String(item.code))
    );
    if (children.length > 0) {
      acc.push(Object.assign({}, curr, { canEdit: true, children }));
    }

    return acc;
  }, [] as SettingConfigGroup<T>[]);
}

// 추후에 사용할 수도 있기 때문에 일단 주석처리
// const minMaxUnitGen2: MinMaxUnitData = {
//   gc_reactive_power_q_p_power_mode: {
//     0: {
//       gc_reactive_power_q_p_y1gen: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y2gen: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y3gen: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y1load: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y2load: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y3load: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     2: {
//       gc_reactive_power_q_p_y1gen: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y2gen: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y3gen: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y1load: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y2load: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y3load: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     3: {
//       gc_reactive_power_q_p_y1gen: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y2gen: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y3gen: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y1load: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y2load: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y3load: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     4: {
//       gc_reactive_power_q_p_y1gen: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y2gen: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y3gen: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y1load: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y2load: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y3load: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     1: {
//       gc_reactive_power_q_p_y1gen: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y2gen: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y3gen: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y1load: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y2load: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_p_y3load: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//     },
//   },
//   reactive_power_q_set_point_reactive_power_mode: {
//     0: {
//       gc_reactive_power_q_setpoint_value: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     2: {
//       gc_reactive_power_q_setpoint_value: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     3: {
//       gc_reactive_power_q_setpoint_value: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     4: {
//       gc_reactive_power_q_setpoint_value: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     1: {
//       gc_reactive_power_q_setpoint_value: {
//         min: '0.00',
//         max: '100.00',
//         unit: '%',
//       },
//     },
//   },
//   gc_reactive_power_q_u_power_mode: {
//     0: {
//       gc_reactive_power_q_u_y1: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y4: {
//         min: '-60.00',
//         max: '0.00',
//         unit: '%',
//       },
//     },
//     2: {
//       gc_reactive_power_q_u_y1: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y4: {
//         min: '-60.00',
//         max: '0.00',
//         unit: '%',
//       },
//     },
//     3: {
//       gc_reactive_power_q_u_y1: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y4: {
//         min: '-60.00',
//         max: '0.00',
//         unit: '%',
//       },
//     },
//     4: {
//       gc_reactive_power_q_u_y1: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y4: {
//         min: '-60.00',
//         max: '0.00',
//         unit: '%',
//       },
//     },
//     1: {
//       gc_reactive_power_q_u_y1: {
//         min: '0.00',
//         max: '100.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y2: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y3: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       gc_reactive_power_q_u_y4: {
//         min: '-100.00',
//         max: '0.00',
//         unit: '%',
//       },
//     },
//   },
// };
// const minMaxUnitGen3: MinMaxUnitData = {
//   reactive_power_q_p_reactive_power_mode: {
//     0: {
//       reactive_power_q_p_gen_y1: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_gen_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_gen_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y1: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     2: {
//       reactive_power_q_p_gen_y1: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_gen_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_gen_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y1: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     3: {
//       reactive_power_q_p_gen_y1: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_gen_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_gen_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y1: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     4: {
//       reactive_power_q_p_gen_y1: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_gen_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_gen_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y1: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     1: {
//       reactive_power_q_p_gen_y1: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       reactive_power_q_p_gen_y2: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       reactive_power_q_p_gen_y3: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y1: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y2: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       reactive_power_q_p_load_y3: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//     },
//   },
//   reactive_power_q_set_point_reactive_power_mode: {
//     0: {
//       reactive_power_q_set_point_value: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     2: {
//       reactive_power_q_set_point_value: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     3: {
//       reactive_power_q_set_point_value: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     4: {
//       reactive_power_q_set_point_value: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//     },
//     1: {
//       reactive_power_q_set_point_value: {
//         min: '0.00',
//         max: '100.00',
//         unit: '%',
//       },
//     },
//   },
//   reactive_power_q_u_reactive_power_mode: {
//     0: {
//       reactive_power_q_u_y1: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y4: {
//         min: '-60.00',
//         max: '0.00',
//         unit: '%',
//       },
//     },
//     2: {
//       reactive_power_q_u_y1: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y4: {
//         min: '-60.00',
//         max: '0.00',
//         unit: '%',
//       },
//     },
//     3: {
//       reactive_power_q_u_y1: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y4: {
//         min: '-60.00',
//         max: '0.00',
//         unit: '%',
//       },
//     },
//     4: {
//       reactive_power_q_u_y1: {
//         min: '0.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y2: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y3: {
//         min: '-60.00',
//         max: '60.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y4: {
//         min: '-60.00',
//         max: '0.00',
//         unit: '%',
//       },
//     },
//     1: {
//       reactive_power_q_u_y1: {
//         min: '0.00',
//         max: '100.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y2: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y3: {
//         min: '-100.00',
//         max: '100.00',
//         unit: '%',
//       },
//       reactive_power_q_u_y4: {
//         min: '-100.00',
//         max: '0.00',
//         unit: '%',
//       },
//     },
//   },
// };

export function getRangeDescription(item: SettingConfigChild<AdvancedSettingKeys>) {
  let description = '';

  if (item.type === 'number') {
    if (item.description) {
      return item.description;
    } else {
      description = `${item.min} ~ ${item.max} ${item.unit ? item.unit : ''}`;
    }
  }

  return description;
}

export function getFormattedData(data: ACSystemAdvancedSettings): ACSystemAdvancedSettings {
  const result: Record<string, any> = {};
  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'number') {
      result[key] = Number(value.toFixed(3));
    } else {
      result[key] = value;
    }
  });

  return result as ACSystemAdvancedSettings;
}

export function getSettingValue<T extends SettingKeysType>(
  item: SettingConfigChild<T>,
  confirmItems: { [k: string]: string | number | null }
) {
  const valueType = item.type;
  switch (valueType) {
    case 'number':
    case 'text':
      return confirmItems[String(item.code)] || null;
    case 'toggle':
      return item.options?.filter((option) => option.value == confirmItems[String(item.code)])[0]?.text || null;
    case 'radio':
      return item.options?.filter((option) => option.value == confirmItems[String(item.code)])[0]?.text || null;
    case 'selector':
      return item.options?.filter((option) => option.value == confirmItems[String(item.code)])[0]?.text || null;
    default:
      return null;
  }
}

export function getValidator(min: string, max: string) {
  const numberValidator = () =>
    yup
      .number()
      .transform((v, o) => (o === '' ? null : v))
      .nullable();
  const exponent = Math.max(min.split('.')[1]?.length ?? 0, max.split('.')[1]?.length ?? 0);
  const unitNumber = 1 / Math.pow(10, exponent);

  return numberValidator().min(Number(min)).max(Number(max)).unitNumber(unitNumber);
}

/**
 *
 * @param gridCode
 * @param defaultConfig [advancedSettingACSystemConfig] grid_code 별 설정된 기본 항목을 보여주는 항목
 * @param rangeUnitInfo [column_range] grid_code 별 설정하는 항목에 대한 정보 (col_nm, min, max, unit)
 * @returns
 */
export function getUpdateConfigByServer(
  gridCode: number,
  defaultConfig: SettingConfigGroup<AdvancedSettingsKeyACSys>[],
  rangeUnitInfo: AdvancedSettingColumnRange[]
) {
  const rangeUnitInfoByGrid = rangeUnitInfo.filter((config) => config.grid_code === gridCode);
  //grid_code : 0 -> EDM 기준의 range
  const rangeUnitInfoByDefault = rangeUnitInfo.filter((config) => config.grid_code === 0);

  // defaultConfig(advancedSettingACESConfig, advancedSettingACCombinerConfig) 의 각 group에 대해 반복
  const updatedConfig = defaultConfig.map((group) => {
    // group의 children을 반복
    const children = group.children.map((child) => {
      // children 하나의 min, max range를 가져옴
      const range =
        rangeUnitInfoByGrid.find((config: AdvancedSettingColumnRange) => config.col_nm === child.code) ||
        rangeUnitInfoByDefault.find((config: AdvancedSettingColumnRange) => config.col_nm === child.code);
      // 장비 최초 설치하여 grid code 별로 설정하지 않았을 경우에만 default config의 range를 사용
      // 항상 서버에서 주는 grid code 별 range 를 사용
      if (!_.isEmpty(range)) {
        const target = range!;

        if (child.type === 'number') {
          return {
            ...child,
            min: target.range_min,
            max: target.range_max,
            unit: target.range_unit && target.range_unit !== '-' ? target.range_unit : child.unit,
            schema: () => getValidator(target.range_min, target.range_max),
          };
        }
      }

      return child;
    });

    return {
      ...group,
      children: children,
    };
  });

  return updatedConfig;
}
