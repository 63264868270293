/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */

export const PROVIDE_INJECT_KEYS = {
  GEN_TYPE: 'genType',
  GO_TO_LIST: 'goToList',
  NEXT: 'next',
  EXIST_GROUP_INFO: 'existGroupInfo',
  GO_BACK: 'goBack',
  INV_PRODUCT_MODEL_NM: 'invProductModelNm',
  REFRESH: 'refresh',
  CONFIG: 'config',
  ADVANCED_SETTINGS_STATE: 'advancedSettingsState',
  DESCRIPTION: 'description',
  FORM_STATE: 'formState',
  BASIC_CONFIG: 'basicConfig',
  BASIC_DESCRIPTION: 'basicDescription',
  APPLE_OAUTH: 'appleOAuth',
  FACEBOOK_OAUTH: 'facebookOAuth',
  GOOGLE_OAUTH: 'googleOAuth',
  MAX_INVERTER_REF: 'maxInverterRef',
  CONTROL_POPUP: 'controlPopup',
} as const;
