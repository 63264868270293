
import type { PropType } from 'vue';
import { defineComponent, reactive, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import _ from 'lodash';
import moment from 'moment-timezone';
import { Form } from 'vee-validate';
import * as yup from 'yup';

import {
  Accordion,
  BasicInput,
  Selector,
  SettingTable,
  SettingTableRow,
  useMessageBox,
  BaseSlider,
  ChangedMark,
} from '@hems/component';
import { getBasicSettingPvModuleFoxConfig } from '@hems/container/src/forms/device/settings/_shared/basic/config';
import SettingConfirmPopup from '@hems/container/src/forms/device/settings/_shared/SettingConfirmPopup.vue';
import { CommonService } from '@hems/service';
import { DateHelper, useLanguage } from '@hems/util';
import { useRole } from '@hems/util';
import { ENERGY_POLICY } from '@hems/util/src/constant';
import { ROLE_TYPE } from '@hems/util/src/constant/passwordManagement';
import {
  isNull,
  checkValidEmsVersion,
  codeNamesToSelectorOptions,
  getTemporaryPassword,
} from '@hems/util/src/helper/helper';

import type { SelectorOption, SelectorValue } from 'hems';

import type { SettingGroup } from 'hems/device/settings';
import type { BasicSettings as BasicSettingsPvModuleFox } from 'hems/device/settings/pvmodule/fox';
import type { WebHmiPasswordType } from 'hems/webHmiManagement';

const initValue: Partial<BasicSettingsPvModuleFox> = {};

export default defineComponent({
  name: 'BasicSettingsPvModuleFoxSharedContainer',
  components: {
    Accordion,
    BasicInput,
    Form,
    Selector,
    SettingTable,
    SettingTableRow,
    BaseSlider,
    ChangedMark,
    SettingConfirmPopup,
  },
  props: {
    data: {
      type: Object as PropType<Partial<BasicSettingsPvModuleFox>>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    isConnection: {
      type: Boolean,
      required: true,
    },
    isHcore: {
      type: Boolean,
      default: false,
    },
    isAcCoupled: {
      type: Boolean,
      default: false,
    },
    isHybrid: {
      type: Boolean,
      default: false,
    },
    isGrp: {
      type: Boolean,
      default: false,
    },
    timeZone: {
      type: String,
      default: '',
    },
    emsVersion: {
      type: String,
      default: '',
    },
    genType: {
      type: String as PropType<'FOX_ESS' | 'FOX_ESS_H3'>,
      default: 'FOX_ESS',
    },
    changedValueSet: {
      type: Object as PropType<Set<string>>,
      default: () => new Set<string>(),
    },
  },
  emits: ['goToList', 'save', 'generate', 'cancel', 'edit', 'changeWebHMIPassword'],
  async setup(props, { emit }) {
    const { roleName } = useRole();
    const messageBox = useMessageBox();
    const { t } = useI18n();
    const commonService = new CommonService(window.axiosInstance.axios);
    let copyData: Partial<BasicSettingsPvModuleFox> = _.cloneDeep(props.data);
    let copyChangedValueSet: Set<string> = _.cloneDeep(props.changedValueSet);
    const { languageCode } = useLanguage();
    const state = reactive<{
      editable: boolean;
      data: Partial<BasicSettingsPvModuleFox>;
      enableEditFeedInLimit: boolean;
      prevFeedInLimit?: number;
      prevFeedInLimitWatt?: number;
      isAcCoupled: boolean;
      isHybrid: boolean;
      isGrp: boolean;
      isShowFeedInWatt: boolean;
      isEmsVer20OrHigher: boolean;
      isEmsVer31OrHigher: boolean;
      changedValueSet: Set<string>;
      confirmPopup: { on: boolean; data: SettingGroup[] };
      validClass: { web_page_password_engineer: boolean; web_page_password_service: boolean };
    }>({
      editable: props.editable,
      data: { ...getInitialValue(), ...props.data },
      enableEditFeedInLimit: props.data.energy_policy !== 2,
      prevFeedInLimit: props.data.pv_feed_in_limit,
      prevFeedInLimitWatt: props.data.pv_feed_in_limit_w,
      isAcCoupled: props.isAcCoupled,
      isHybrid: props.isHybrid,
      isGrp: props.isGrp,
      isShowFeedInWatt: checkValidEmsVersion(props.genType, props.emsVersion, 20) === true,
      isEmsVer20OrHigher: checkValidEmsVersion(props.genType, props.emsVersion, 20) === true,
      isEmsVer31OrHigher: checkValidEmsVersion(props.genType, props.emsVersion, 31) === true,
      changedValueSet: _.cloneDeep(props.changedValueSet),
      confirmPopup: { on: false, data: [] },
      validClass: {
        web_page_password_engineer: false,
        web_page_password_service: false,
      },
    });
    let selectorOptionsState = reactive<{ energyPolicy: SelectorOption[] }>({
      energyPolicy: [],
    });

    function getInitialValue() {
      if (!props.isGrp) {
        return {
          ...initValue,
        };
      }
    }

    const computedVal = {
      valueRange: {
        pv_feed_in_limit: { min: 0, max: 100 },
        pv_max_pwr2: { min: 0, max: 3300 },
        feed_in_limit: { min: 0, max: 100 },
        battery_backup_soc: { min: 0, max: 100 },
        pv_per: { min: 0, max: 65535 },
      },
    };
    const defaultValidate = yup
      .number()
      .transform((v) => (isNull(v) || isNaN(v) ? null : v))
      .nullable();

    const schema = yup.object().shape({
      pv_feed_in_limit:
        !state.isAcCoupled && state.enableEditFeedInLimit && (state.isGrp || !state.isShowFeedInWatt)
          ? defaultValidate.integer().min(0).max(100)
          : yup.number().nullable().notRequired(),
      pv_feed_in_limit_w: defaultValidate.integer().min(0).max(65000),
      energy_policy: defaultValidate,
      web_page_password_engineer: yup
        .string()
        .nullable()
        .isValidPassword(
          { min: 16, max: 16 },
          function (isValid: boolean) {
            state.validClass.web_page_password_engineer = isValid;
          },
          t
        ),
      web_page_password_service: yup
        .string()
        .nullable()
        .isValidPassword(
          { min: 16, max: 16 },
          function (isValid: boolean) {
            state.validClass.web_page_password_service = isValid;
          },
          t
        ),
    });

    function getFormatDate(dt?: string | number | Date | undefined): string {
      let date = '-';
      if (dt === undefined || dt === 0 || dt === '0') return date;

      if (typeof dt === 'number') {
        if (dt.toString().length === 10) dt *= 1000;
        if (!isExpireDate(dt))
          date = DateHelper.getTimezoneDate(new Date(dt), { isTime: true, timezone: props.timeZone });
      } else if (typeof dt === 'string') {
        let timestamp = Number(dt);
        if (dt.length === 10) timestamp *= 1000;
        if (!isExpireDate(timestamp)) {
          const tmpDate = new Date(timestamp);
          const utcDate = moment.utc(tmpDate, 'YYYYMMDDHHmmss');
          const tzDate = utcDate.clone().tz(props.timeZone);
          date = tzDate.format(DateHelper.getGridDateFormat('TD', languageCode.value));
        }
      }

      return date;
    }

    function isExpireDate(time?: string | number | undefined) {
      let isExpire = false;
      if (time === undefined || time === 0 || time === '0') return isExpire;
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (Number(time) < yesterday.getTime()) {
        isExpire = true;
      }

      return isExpire;
    }

    async function loadSelectorOptions() {
      const { ENERGY_POLICY_GEN3_CD } = await commonService.getCodesByGroupCode([
        { grpCd: 'ENERGY_POLICY_GEN3_CD', exclude: ['4'] },
      ]);

      selectorOptionsState.energyPolicy = codeNamesToSelectorOptions(ENERGY_POLICY_GEN3_CD, t, {
        text: t('common.select'),
        value: null,
      });
    }

    function getParam(): Partial<BasicSettingsPvModuleFox> {
      const { pv_feed_in_limit, pv_feed_in_limit_w, energy_policy, battery_backup_soc } = state.data;

      const param: Partial<BasicSettingsPvModuleFox> = {
        pv_feed_in_limit:
          !state.isAcCoupled && state.enableEditFeedInLimit && (state.isGrp || !state.isShowFeedInWatt)
            ? energy_policy !== 2
              ? pv_feed_in_limit
              : undefined
            : undefined,
        pv_feed_in_limit_w:
          !state.isAcCoupled && state.enableEditFeedInLimit && (state.isGrp || state.isShowFeedInWatt)
            ? energy_policy !== 2
              ? pv_feed_in_limit_w
              : undefined
            : undefined,
        energy_policy,
        battery_backup_soc,
      };
      const cleanedParam = _.pickBy(param, (v) => v !== undefined && v !== null);

      return cleanedParam;
    }

    function getTempPasswordParam(): Partial<BasicSettingsPvModuleFox> {
      const nowUTCDate = moment.utc().format();
      const nowUTCTimestamp = new Date(nowUTCDate).getTime() / 1000;
      const param: Partial<BasicSettingsPvModuleFox> = {
        installer_page_temp_password_password: getTemporaryPassword(),
        installer_page_temp_password_setting_time: nowUTCTimestamp,
      };

      const cleanedParam = _.pickBy(param, (v) => v !== undefined && v !== null);

      return cleanedParam;
    }
    function onSave() {
      let confirmItems = getParam();
      let params = getBasicSettingPvModuleFoxConfig(t, selectorOptionsState)
        .map((config) => {
          return {
            ...config,
            children: config.children
              .map((item) => {
                const itemValue = confirmItems[item.code as keyof BasicSettingsPvModuleFox];
                const value =
                  item.type === 'number' || item.type === 'text'
                    ? itemValue
                    : !isNull(itemValue)
                    ? item.options?.filter((option) => option.value == itemValue)[0]?.text
                    : undefined;

                return {
                  ...item,
                  value: value,
                };
              })
              .filter((item) => !isNull(item.value)),
          };
        })
        .filter((config) => config.children.length > 0);

      state.confirmPopup = { on: true, data: params };
    }

    function onConfirm() {
      state.confirmPopup.on = false;
      emit('save', getParam(), state.changedValueSet, function (isOk: boolean) {
        if (isOk) {
          state.editable = false;
          if (state.data.energy_policy !== 2) {
            state.prevFeedInLimit = state.data.pv_feed_in_limit;
            state.prevFeedInLimitWatt = state.data.pv_feed_in_limit_w;
          }
        }
      });
    }

    function onGenerate() {
      if (state.data.install_done != 1) {
        // 장비 초기 설정 x
        messageBox.alert(t('message.device_init_set_not_complete')).open();

        return;
      }
      if (!props.isConnection) {
        // 장비 미연결 상태일 경우
        let messageCode = 'code.cmdgen3configreqstatuscd.230';
        messageBox.alert(t(messageCode)).open();

        return;
      }
      const isValidVersion = checkValidEmsVersion(props.genType, props.emsVersion, 18);
      if (isValidVersion === null) {
        // 버전 정보가 없거나 다른 형식일 경우
        messageBox.alert([t('message.error_data_info'), '[' + t('device.ems_ver') + ']']).open();

        return;
      } else if (!isValidVersion) {
        // 버전이 낮을 경우
        messageBox.alert([t('message.create_fail'), t('message.retry_or_update_ems_ver')]).open();

        return;
      }

      emit('generate', getTempPasswordParam());
    }

    function onEdit() {
      if (!props.isConnection) {
        messageBox.alert(t('message.modify_when_disconnected')).open();

        return;
      }

      copyData = _.cloneDeep(state.data);
      state.editable = true;
      emit('edit', copyData);
    }

    function onCancel(handleReset?: () => void) {
      state.data = copyData;
      state.editable = false;
      if (handleReset) handleReset();
      emit('cancel');
    }
    function goToList() {
      emit('goToList');
    }

    function onChange(valueKey: string, targetValue?: string | number | boolean) {
      if (state.editable) {
        if (!isNull(targetValue)) {
          // 변경 항목 key set에 저장
          state.changedValueSet.add(valueKey);
        } else {
          // 변경 항목 key set에서 제거
          state.changedValueSet.delete(valueKey);
        }
      } else {
        // cancel 버튼 클릭 시
        state.changedValueSet = copyChangedValueSet;
      }
    }

    function checkEnergyPolicy(value: SelectorValue, beforeValue: SelectorValue): boolean {
      if (value === Number(ENERGY_POLICY.ZERO_EXPORT)) {
        state.prevFeedInLimit = state.data.pv_feed_in_limit;
        state.enableEditFeedInLimit = false;
        state.data.pv_feed_in_limit = 0;
        state.data.pv_feed_in_limit_w = 0;
      } else {
        state.enableEditFeedInLimit = true;
        if (beforeValue === Number(ENERGY_POLICY.ZERO_EXPORT)) {
          state.data.pv_feed_in_limit = state.prevFeedInLimit;
        } else if (state.prevFeedInLimit && beforeValue === Number(ENERGY_POLICY.ZERO_EXPORT)) {
          state.data.pv_feed_in_limit = state.prevFeedInLimit;
        }
        if (beforeValue === Number(ENERGY_POLICY.ZERO_EXPORT)) {
          state.data.pv_feed_in_limit_w = state.prevFeedInLimitWatt;
        } else if (state.prevFeedInLimitWatt && beforeValue === Number(ENERGY_POLICY.ZERO_EXPORT)) {
          state.data.pv_feed_in_limit_w = state.prevFeedInLimitWatt;
        }
      }
      if (value === Number(ENERGY_POLICY.TIME_BASED)) {
        messageBox.alert(t('message.pricingsetting_when_timebasemode')).open();
      }

      return true;
    }

    function getWebHMIPasswordParams(type: WebHmiPasswordType): Partial<BasicSettingsPvModuleFox> {
      if (type === ROLE_TYPE.ENGINEER) {
        const params = {
          web_page_password_engineer: state.data.web_page_password_engineer,
        };

        return params;
      } else {
        const params = {
          web_page_password_service: state.data.web_page_password_service,
        };

        return params;
      }
    }

    function onChangeWebHMIPassword(type: WebHmiPasswordType) {
      emit('changeWebHMIPassword', getWebHMIPasswordParams(type));
    }

    onMounted(() => {
      if (state.data.energy_policy === 2) {
        state.enableEditFeedInLimit = false;
        state.data.pv_feed_in_limit = 0;
        state.data.pv_feed_in_limit_w = 0;
      }
      if (props.isAcCoupled) {
        state.enableEditFeedInLimit = false;
        state.data.pv_feed_in_limit = 100;
      }
      if (props.data.installer_page_temp_password_setting_time != undefined) {
        state.data.installer_page_temp_password_setting_time = getFormatDate(
          props.data.installer_page_temp_password_setting_time
        );
      }
    });

    watch(
      () => props.data,
      () => {
        state.data.installer_page_temp_password_setting_time = getFormatDate(
          props.data.installer_page_temp_password_setting_time
        );
      }
    );

    watch(
      () => languageCode.value,
      () => {
        state.data.installer_page_temp_password_setting_time = getFormatDate(
          props.data.installer_page_temp_password_setting_time
        );
      }
    );

    await loadSelectorOptions();

    return {
      state,
      schema,
      computedVal,
      selectorOptionsState,
      onSave,
      onGenerate,
      onEdit,
      onCancel,
      goToList,
      checkEnergyPolicy,
      onChange,
      onConfirm,
      titleWidth: '40%',
      onChangeWebHMIPassword,
      isNull,
      roleName,
    };
  },
});
