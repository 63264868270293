
import { defineComponent, onErrorCaptured, PropType, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Form } from 'vee-validate';
import * as yup from 'yup';

import { DotStep, SettingTable, SettingTableRow, BasicInput, Selector, useMessageBox } from '@hems/component';
import Image2 from '@hems/component/src/image/Image2.vue';
import { JoinService } from '@hems/service';
import { Helper, Constant } from '@hems/util';

import { SelectorOption } from 'hems';

import { Join } from 'hems/auth';
import { SocialAccountInfo } from 'hems/auth/account';

export default defineComponent({
  name: 'Step2RequiredData',
  components: {
    DotStep,
    SettingTable,
    SettingTableRow,
    BasicInput,
    Form,
    //Selector,
    Image2,
  },
  props: {
    data: {
      type: Object as PropType<Join.InstallerEssentialJoinInfo>,
      required: true,
    },
    social: Object as PropType<SocialAccountInfo>,
  },
  emits: ['next', 'back'],
  setup(props, { emit }) {
    const messageBox = useMessageBox();
    const { t } = useI18n();
    const joinService = new JoinService(window.axiosInstance.axios);
    const state = reactive<Omit<Join.InstallerEssentialJoinInfo, 'terms_ver'>>({
      user_id: props.social?.email ?? props.data.user_id,
      password: props.data.password,
      user_nm: props.social?.name ?? props.data.user_nm,
      mpn_no: props.data.mpn_no,
      email: props.social?.email ?? props.data.email,
      comn_nm: props.data.comn_nm,
      special_num: props.data.special_num,
      //installer_group_id: props.data.installer_group_id,
      company_regi_num: props.data.company_regi_num,
      auth_type_cd: props.data.auth_type_cd,
    });

    const stateValidationCheck = reactive<Join.PasswordValidationCheck>({
      checkValidation1: false,
      checkValidation2: false,
      checkValidation3: false,
    });

    const installerGroupOptions = ref<SelectorOption[]>([]);

    joinService.getInstallerGroups().then(({ result }) => {
      installerGroupOptions.value = Helper.addSelectorOptionAtFirst(
        result.map((item) => ({ text: item, value: item })),
        { text: '', value: null }
      );
    });

    let prevUserId = {
      value: undefined as undefined | string,
      status: false,
    };
    let prevEmail = {
      value: undefined as undefined | string,
      status: false,
    };

    const schema = yup.object().shape({
      user_id: yup.string().nullable().isValidUserId(prevUserId),
      password: yup.string().when({
        is: () => !props.social,
        then: yup
          .string()
          .test('password', { key: '' }, function (password?: string) {
            const { createError } = this;
            stateValidationCheck.checkValidation1 = false;
            stateValidationCheck.checkValidation2 = false;
            stateValidationCheck.checkValidation3 = false;
            if (Helper.isNull(password)) {
              return createError({
                message: { key: 'message.field_required' },
              });
            }
            const regexp1 = /^.{8,20}$/;
            const regexp2 = /^(?=.*[a-zA-Z])(?=.*\d).+$/;
            const regexp3 = /.*[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*/;
            if (regexp1.test(password)) {
              stateValidationCheck.checkValidation1 = true;
            } else {
              stateValidationCheck.checkValidation1 = false;
            }
            if (regexp2.test(password)) {
              stateValidationCheck.checkValidation2 = true;
            } else {
              stateValidationCheck.checkValidation2 = false;
            }
            if (regexp3.test(password)) {
              stateValidationCheck.checkValidation3 = true;
            } else {
              stateValidationCheck.checkValidation3 = false;
            }
            if (
              stateValidationCheck.checkValidation1 === true &&
              stateValidationCheck.checkValidation2 === true &&
              stateValidationCheck.checkValidation3 === true
            ) {
              return true;
            } else {
              return false;
            }
          })
          .nullable()
          .required(),
      }),
      password_confirm: yup
        .string()
        .nullable()
        .test('password_confirm', { key: 'message.invalid' }, function (password_confirm?: string | null) {
          if (props.social) return true;
          const { createError } = this;
          const password = this.parent['password'];
          if (Helper.isNull(password_confirm)) {
            return createError({
              message: { key: 'message.field_required' },
            });
          } else {
            if (password !== password_confirm) {
              return createError({
                message: { key: 'message.password_not_match' },
              });
            } else {
              return true;
            }
          }
        }),
      comn_nm: yup.string().min(1).max(30).nullable().required(),
      mpn_no: yup
        .string()
        .test('mpn_no', { key: 'message.invalid_phone_number' }, function (mpn_no?: string) {
          const { createError } = this;
          if (Helper.isNull(mpn_no)) {
            return createError({
              message: { key: 'message.field_required' },
            });
          }
          const regexp = /^[0-9+\-()]{8,20}$/g;
          if (!regexp.test(mpn_no)) {
            return false;
          }

          return true;
        })
        .nullable()
        .required(),
      email: yup.string().nullable().isValidEmail(prevEmail),
      special_num: yup.string().nullable().required(t('message.num_req_installer')),
      user_nm: yup.string().test('user_nm', { key: 'message.invalid' }, function (user_nm?: string) {
        const { createError } = this;
        if (Helper.isNull(user_nm)) {
          return createError({
            message: { key: 'message.field_required' },
          });
        }
        if (user_nm.length < 2) {
          return createError({
            message: { key: 'message.too_short' },
          });
        }

        return true;
      }),
    });

    const passwordConfirm = ref(null as null | string);

    function onNext() {
      emit('next', 'essentialData', { ...state });
    }

    onErrorCaptured((error, instance) => {
      console.error(error, instance);
      messageBox.alert(t('message.unknown_error')).open();

      return true;
    });

    return {
      state,
      passwordConfirm,
      schema,
      installerGroupOptions,
      onNext,
      stateValidationCheck,
    };
  },
});
