import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "txt" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "txt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "language-selector",
    ref: "languageEl",
    class: _normalizeClass(["len-drop", { on: _ctx.state.dropbox.onLenDrop }])
  }, [
    _createElementVNode("button", {
      class: "btn-len btnLen",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleLocaleDropbox()))
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.state.selected?.text), 1)
    ]),
    _createElementVNode("div", {
      ref: "lenLayer",
      class: _normalizeClass(["len-layer", { on: _ctx.state.dropbox.onLenLayer }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.options, (language) => {
        return (_openBlock(), _createElementBlock("button", {
          key: language.value,
          class: "btn-len",
          onClick: ($event: any) => (_ctx.onSelectLanguage(language))
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(language.text), 1)
        ], 8, _hoisted_2))
      }), 128))
    ], 2)
  ], 2))
}