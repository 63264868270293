import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4d4a864"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box-rt-wrap" }
const _hoisted_2 = { class: "box-rt" }
const _hoisted_3 = {
  key: 0,
  class: "disconnect-area"
}
const _hoisted_4 = { class: "content-area" }
const _hoisted_5 = { class: "txt" }
const _hoisted_6 = { class: "pictogram-area innerCont" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!
  const _component_EnergyFlowACContainer = _resolveComponent("EnergyFlowACContainer")!
  const _component_EnergyFlowContainer = _resolveComponent("EnergyFlowContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.disconnect)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Image2, { src: "energyflow2/ic_re_disconnected" }),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('common.disconnect')), 1),
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.retryConnection && _ctx.retryConnection(...args)))
              }, "Retry")
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.isACSystem)
          ? (_openBlock(), _createBlock(_component_EnergyFlowACContainer, {
              key: 0,
              "realtime-data": _ctx.realtimeData,
              "is-realtime-connected": _ctx.isRealtimeConnected,
              disconnect: _ctx.disconnect,
              "site-configuration-info": _ctx.siteConfigurationInfo,
              "is-consumption-ct": _ctx.isConsumptionCt
            }, null, 8, ["realtime-data", "is-realtime-connected", "disconnect", "site-configuration-info", "is-consumption-ct"]))
          : (_openBlock(), _createBlock(_component_EnergyFlowContainer, {
              key: 1,
              "realtime-data": _ctx.realtimeData,
              "is-realtime-connected": _ctx.isRealtimeConnected,
              disconnect: _ctx.disconnect,
              "is-connect-third-party": _ctx.isConnectThirdParty
            }, null, 8, ["realtime-data", "is-realtime-connected", "disconnect", "is-connect-third-party"]))
      ])
    ])
  ]))
}