import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45582bbf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "txt" }
const _hoisted_2 = {
  key: 1,
  class: "title"
}
const _hoisted_3 = { class: "txt" }
const _hoisted_4 = {
  ref: "content",
  class: "acc_cont"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("dl", {
    class: _normalizeClass(["accordion", [{ acc_status: _ctx.showStatus }, _ctx.accordionClassName]])
  }, [
    _createElementVNode("dt", {
      class: _normalizeClass(["acc_subj", _ctx.stateClassName])
    }, [
      (!_ctx.isFixedOpen)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.subTitle), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.subTitle), 1)
          ]))
    ], 2),
    _createElementVNode("dd", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 512)
  ], 2))
}