import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-064e40c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = {
  id: "gnb",
  class: "header_con"
}
const _hoisted_3 = { class: "navi_icon bor_none fl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, [
          _createElementVNode("a", {
            href: "javascript:void(0)",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goHome && _ctx.goHome(...args)), ["stop","prevent"]))
          }, [
            _createVNode(_component_Image, { src: "new2021/new_btn_home" })
          ])
        ])
      ]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}