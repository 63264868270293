
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import { Flow } from '@hems/component';
import { defaultEnergyFlow } from '@hems/container/src/energyflow/flow/EnergyFlowFunctions';
import { FLOW_ANIMATION } from '@hems/util/src/constant';

import type { EnergyFlowCase } from 'hems/energyFlow';

export default defineComponent({
  name: 'EnergyFlowAC',
  components: {
    Flow,
  },
  props: {
    energyFlow: {
      type: Object as PropType<EnergyFlowCase>,
      default: () => defaultEnergyFlow,
    },
  },
  setup() {
    return {
      FLOW_ANIMATION,
    };
  },
});
