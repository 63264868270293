
import { computed, defineComponent, PropType } from 'vue';
import { PricingItem } from 'hems/device/settings/pvmodule/gen3';
import { useI18n } from 'vue-i18n';
import { acSystemTimeBasedActions, mowTimeBasedActions } from '@hems/util/src/constant';
import { SelectorOption } from 'hems';
import { useGenType } from '@hems/util';

export default defineComponent({
  name: 'PricingTableRow',
  props: {
    data: {
      type: Object as PropType<PricingItem>,
      required: true,
    },
    removeRow: {
      type: Function as PropType<(payload: MouseEvent) => void>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { isACSystem } = useGenType();
    const actionOptions: SelectorOption[] = isACSystem ? acSystemTimeBasedActions : mowTimeBasedActions;

    const actionText = computed(() => {
      // TODO: 타입 관련 임시 조치로 === -> == 로 변경함, 추후 tou 타입 개선 작업 시 재변경 예정
      const text = actionOptions.find((item) => item.value == props.data?.action)?.text ?? '';

      return t(text, { defaultValue: text });
    });

    return { actionText };
  },
});
