
import { defineComponent, reactive } from 'vue';
import PopupWrapper from './PopupWrapper.vue';
export default defineComponent({
  name: 'EmsReleaseNotePopup',
  components: {
    PopupWrapper,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const state = reactive({
      header: ['Version', 'Date', 'Feature Update', 'Bug Fix', 'Security Update'],
      data: [
        {
          version: '31.00.02',
          date: '2024.02.29',
          featureUpdate: ['Removed Web HMI User home page', 'Applied Grid code auto change features'],
          bugFix: ['Fixed Advanced setting issue(UK)'],
          securityUpdate: [],
        },
        {
          version: '29.01.58',
          date: '2024.01.08',
          featureUpdate: ['Applied the new Advanced setting v2.45', 'Applied the new Installation v2.41 ~ v2.44'],
          bugFix: ['Fixed EEPROM date write Issue'],
          securityUpdate: [
            'Applied Unique password of WebHMI per a product',
            'Enhanced WebHMI Login function against Brutal Force Attack',
            'Added Error Code for Boot Integrity Error',
            'Applied Enhanced IPTable Rules against DDos Attack',
          ],
        },
        {
          version: '28.00.03',
          date: '2023.12.21',
          featureUpdate: ['E01M error level lowered', 'Added error code for battery target power issue (D72W)'],
          bugFix: [
            'Fixed load value error problem (Issue of not properly recognizing the Main Load value)',
            'Fixed null issue for MQTT Connector',
          ],
          securityUpdate: ['Applied Secure Storage Feature', 'Applied checking invalid input on WebHMI'],
        },
        {
          version: '27.00.09',
          date: '2023.11.22',
          featureUpdate: [
            'Applied Portugal grid code and Portuguese language',
            'Applied Battery throughput feature',
            'Applied FoxESS MQTT protocol',
            'Applied FoxESS Pro2.0 integration feature',
          ],
          bugFix: [
            'Fixed the issue of not being able to connect when the space bar is in the Wifi SSID',
            'Fixed the issue that exapi url value is empty',
            'Fixed SAPN DER control issue',
          ],
          securityUpdate: [
            'Applied Secure Boot Feature',
            'Enhanced WiFi AP Password',
            'Applied the updated G100 certification',
          ],
        },
        {
          version: '25.00.04',
          date: '2023.10.17',
          featureUpdate: [
            'Applied Time-based mode enhancements',
            'Added DTSU666-Direct feature',
            'Multi-tech LTE dongle driver porting',
          ],
          bugFix: ['Applied hotfix for the issue of not being able to access SSIDs containing the space bar'],
          securityUpdate: [],
        },
      ],
    });

    const close = () => {
      emit('close');
    };

    return {
      state,
      close,
    };
  },
});
