import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5406565e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnergyValueBox = _resolveComponent("EnergyValueBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.formattedEnergyData.battery)
      ? (_openBlock(), _createBlock(_component_EnergyValueBox, {
          key: 0,
          "image-path": _ctx.ENERGY_VALUE_IMAGE.BATTERY_IMAGE_PATH,
          title: _ctx.$t('common.energy_storage'),
          "value-type": "battery",
          data: _ctx.formattedEnergyData.battery,
          disconnect: _ctx.disconnect
        }, null, 8, ["image-path", "title", "data", "disconnect"]))
      : _createCommentVNode("", true),
    (_ctx.formattedEnergyData.pv)
      ? (_openBlock(), _createBlock(_component_EnergyValueBox, {
          key: 1,
          "image-path": _ctx.ENERGY_VALUE_IMAGE.PV_MODULE_IMAGE_PATH,
          title: _ctx.$t('common.pv_module'),
          "value-type": "pv",
          data: _ctx.formattedEnergyData.pv,
          disconnect: _ctx.disconnect,
          "detail-message-info": {
        isInfo: _ctx.isConnectThirdParty,
        message: _ctx.$t('message.sum_qhomecore_thirdparty'),
      }
        }, null, 8, ["image-path", "title", "data", "disconnect", "detail-message-info"]))
      : _createCommentVNode("", true),
    (_ctx.formattedEnergyData.load)
      ? (_openBlock(), _createBlock(_component_EnergyValueBox, {
          key: 2,
          "image-path": _ctx.ENERGY_VALUE_IMAGE.LOAD_IMAGE_PATH,
          title: _ctx.$t('common.my_home_2'),
          "value-type": "load",
          data: _ctx.formattedEnergyData.load,
          disconnect: _ctx.disconnect
        }, null, 8, ["image-path", "title", "data", "disconnect"]))
      : _createCommentVNode("", true),
    (_ctx.formattedEnergyData.homeEnergy.status.isHomeAppliance)
      ? (_openBlock(), _createBlock(_component_EnergyValueBox, {
          key: 3,
          "image-path": _ctx.ENERGY_VALUE_IMAGE.HOME_APPLIANCE_IMAGE_PATH,
          title: _ctx.$t('common.home_appliance'),
          "value-type": "homeEnergy",
          data: _ctx.formattedEnergyData.homeEnergy,
          disconnect: _ctx.disconnect,
          onReloadHomeEnergyData: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reloadHomeEnergyData')))
        }, null, 8, ["image-path", "title", "data", "disconnect"]))
      : _createCommentVNode("", true)
  ]))
}