
import { defineComponent } from 'vue';

import { Image2 } from '@hems/component';
import { ENERGY_FLOW_IMAGE } from '@hems/util/src/constant';

export default defineComponent({
  name: 'EnergyFlowACProductLayer',
  components: {
    Image2,
  },
  props: {
    isConsumptionCt: {
      type: Boolean,
      default: true,
    },
    hasEss: {
      type: Boolean,
      default: true,
    },
    hasPv: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      ENERGY_FLOW_IMAGE,
    };
  },
});
