import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dba1ffc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "today-production-value-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "today-chart" }
const _hoisted_4 = { class: "number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GraphChart = _resolveComponent("GraphChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.today_production')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.showChart)
        ? (_openBlock(), _createBlock(_component_GraphChart, {
            key: 0,
            options: _ctx.options,
            type: "TodayProduction"
          }, null, 8, ["options"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", null, _toDisplayString(`${_ctx.todayProduction.num} kWh`), 1)
    ])
  ]))
}