import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ea4c368"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "value-box" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = { class: "e-value" }
const _hoisted_4 = { class: "tit" }
const _hoisted_5 = {
  key: 0,
  class: "info-image"
}
const _hoisted_6 = {
  key: 0,
  class: "number"
}
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!
  const _component_InformationImage = _resolveComponent("InformationImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Image2, {
        src: _ctx.imagePath,
        extension: "svg"
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["detail", { highlight: _ctx.data.detail.highlight }])
      }, [
        _createTextVNode(_toDisplayString(_ctx.data.detail.text) + " ", 1),
        (_ctx.detailMessageInfo.isInfo)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_InformationImage, {
                src: "svg/ic_re_info_default",
                extension: "svg",
                title: _ctx.data.detail.text,
                message: _ctx.detailMessageInfo.message,
                "image-size": { width: '10', height: '10' }
              }, null, 8, ["title", "message"])
            ]))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.data.num)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_ctx.valueType !== 'homeEnergy' || _ctx.isHomeAppliance)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.numberText), 1))
              : _createCommentVNode("", true),
            (_ctx.isHomeAppliance)
              ? (_openBlock(), _createBlock(_component_Image2, {
                  key: 1,
                  src: "energyflow2/ic_re_refresh_default",
                  extension: "svg",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reloadHomeEnergyData')))
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.data.status.text)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["status", { highlight: _ctx.data.status.highlight }])
          }, [
            (_ctx.data.status.icon !== '')
              ? (_openBlock(), _createBlock(_component_Image2, {
                  key: 0,
                  src: _ctx.data.status.icon ?? '',
                  extension: "svg"
                }, null, 8, ["src"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, _toDisplayString(_ctx.data.status.text), 1)
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}