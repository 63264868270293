
import type { PropType } from 'vue';
import { defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import _ from 'lodash';

import { Image2 } from '@hems/component';
import {
  convertWattToKilowatt,
  getFormattedPVValue,
  getStatus,
} from '@hems/container/src/energyflow/value/EnergyValueFunctions';
import { useLocale } from '@hems/util';
import { ENERGY_PRODUCT_TYPE, ENERGY_VALUE_IMAGE } from '@hems/util/src/constant';

import type { RealtimeMonitoringInfo } from 'hems/energyFlow';

export default defineComponent({
  name: 'EnergyValueACNoConsumptionCT',
  components: {
    Image2,
  },
  props: {
    realtimeData: {
      type: Object as PropType<RealtimeMonitoringInfo>,
      required: true,
    },
    disconnect: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { intlFormatLocale } = useLocale();

    const getFormattedData = (data: RealtimeMonitoringInfo) => {
      const status = getStatus(ENERGY_PRODUCT_TYPE.PV, data, props.disconnect, t);
      if (_.isEmpty(data)) {
        return { ...convertWattToKilowatt('W', 0.0, intlFormatLocale.value), status };
      }

      return { ...getFormattedPVValue(data.pv_pw), status };
    };

    const formattedPVData = ref(getFormattedData(props.realtimeData));

    watch(
      () => [intlFormatLocale.value, props.realtimeData, props.disconnect],
      () => {
        formattedPVData.value = getFormattedData(props.realtimeData);
      }
    );

    return {
      formattedPVData,
      ENERGY_VALUE_IMAGE,
    };
  },
});
