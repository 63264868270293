
import type { PropType } from 'vue';
import { defineComponent, computed, onMounted, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import _ from 'lodash';

import { EnergyValueBox } from '@hems/component';
import {
  convertWattToKilowatt,
  getDetail,
  getHomeEnergyDetail,
  getHomeEnergyStatus,
  getStatus,
} from '@hems/container/src/energyflow/value/EnergyValueFunctions';
import { useLocale } from '@hems/util';
import type { EnergyProductValueType } from '@hems/util/src/constant/energyFlow';
import { ENERGY_PRODUCT_TYPE, ENERGY_VALUE_IMAGE } from '@hems/util/src/constant/energyFlow';

import type { EnergyProductCardValue, HomeEnergyData } from 'hems/energyFlow';
import type { RealtimeMonitoringInfo } from 'hems/energyFlow';

export default defineComponent({
  name: 'EnergyValueContainer',
  components: {
    EnergyValueBox,
  },
  props: {
    realtimeData: {
      type: Object as PropType<RealtimeMonitoringInfo>,
      required: true,
    },
    disconnect: {
      type: Boolean,
      default: false,
    },
    homeEnergyData: {
      type: Object as PropType<HomeEnergyData>,
      default: () => ({ isHomeAppliance: false, value: 0.0 }),
    },
    isConnectThirdParty: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['reloadHomeEnergyData'],
  setup(props) {
    const { t } = useI18n();

    const { intlFormatLocale } = useLocale();
    const realtimeMonitoringData = computed(() => props.realtimeData);
    const homeEnergyData = computed(() => props.homeEnergyData);

    const getRealtimeFormattedData = (
      data: RealtimeMonitoringInfo,
      valueType: EnergyProductValueType | null = null
    ): EnergyProductCardValue | null => {
      const detail = getDetail(intlFormatLocale.value, valueType, data, props.disconnect, t);
      const status = getStatus(valueType, data, props.disconnect, t);
      if (_.isEmpty(data)) {
        return { ...convertWattToKilowatt('W', 0.0, intlFormatLocale.value), detail, status };
      }
      if (valueType === ENERGY_PRODUCT_TYPE.BATTERY) {
        return { ...convertWattToKilowatt('W', data.bt_pw, intlFormatLocale.value), detail, status };
      } else if (valueType === ENERGY_PRODUCT_TYPE.PV) {
        return { ...convertWattToKilowatt('W', data.pv_pw, intlFormatLocale.value), detail, status };
      } else if (valueType === ENERGY_PRODUCT_TYPE.LOAD) {
        return { ...convertWattToKilowatt('W', data.cons_pw, intlFormatLocale.value), detail, status };
      }

      return null;
    };

    const getHomeEnergyFormattedData = (data: HomeEnergyData): EnergyProductCardValue => {
      const detail = getHomeEnergyDetail();
      const status = getHomeEnergyStatus(t, data);
      if (data.isHomeAppliance) {
        return { ...convertWattToKilowatt('W', data.value, intlFormatLocale.value), detail, status };
      } else {
        return {
          ...{ ori: 0.0, num: '0.0', unit: 'W' },
          detail: getHomeEnergyDetail(),
          status: getHomeEnergyStatus(t),
        };
      }
    };

    const getAllEnergyFormattedData = () => {
      return {
        [ENERGY_PRODUCT_TYPE.BATTERY]: getRealtimeFormattedData(
          realtimeMonitoringData.value,
          ENERGY_PRODUCT_TYPE.BATTERY
        ),
        [ENERGY_PRODUCT_TYPE.PV]: getRealtimeFormattedData(realtimeMonitoringData.value, ENERGY_PRODUCT_TYPE.PV),
        [ENERGY_PRODUCT_TYPE.LOAD]: getRealtimeFormattedData(realtimeMonitoringData.value, ENERGY_PRODUCT_TYPE.LOAD),
        [ENERGY_PRODUCT_TYPE.HOME_ENERGY]: getHomeEnergyFormattedData(homeEnergyData.value),
      };
    };

    const formattedEnergyData = ref(getAllEnergyFormattedData());

    watch([() => intlFormatLocale.value, () => realtimeMonitoringData.value, () => homeEnergyData.value], () => {
      formattedEnergyData.value = getAllEnergyFormattedData();
    });

    watch(
      () => props.disconnect,
      (disconnect) => {
        if (disconnect) {
          formattedEnergyData.value = getAllEnergyFormattedData();
        }
      }
    );

    onMounted(() => {
      formattedEnergyData.value = getAllEnergyFormattedData();
    });

    return {
      formattedEnergyData,
      ENERGY_VALUE_IMAGE,
    };
  },
});
