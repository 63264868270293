import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48542d6b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "btn_wrap_m"
}
const _hoisted_2 = { class: "input-button-container" }
const _hoisted_3 = { class: "input" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "input-button-container" }
const _hoisted_6 = { class: "input" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "is_default" }
const _hoisted_9 = { class: "is_edit" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  type: "submit",
  class: "btn_m btn_c_3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_SettingTableRow = _resolveComponent("SettingTableRow")!
  const _component_SettingTable = _resolveComponent("SettingTable")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_ChangedMark = _resolveComponent("ChangedMark")!
  const _component_ToggleInput = _resolveComponent("ToggleInput")!
  const _component_Selector = _resolveComponent("Selector")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_BaseSlider = _resolveComponent("BaseSlider")!
  const _component_Form = _resolveComponent("Form")!
  const _component_SettingConfirmPopup = _resolveComponent("SettingConfirmPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Form, {
      "validation-schema": _ctx.schema,
      autocomplete: "off",
      onSubmit: _ctx.onSave,
      onKeydown: _cache[49] || (_cache[49] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
    }, {
      default: _withCtx(({ handleReset }) => [
        (!_ctx.state.isGrp)
          ? (_openBlock(), _createBlock(_component_Accordion, {
              key: 0,
              title: _ctx.$t('control.installer_temp_password'),
              active: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTable, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('control.last_generation_time'),
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.installer_page_temp_password_setting_time,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.data.installer_page_temp_password_setting_time) = $event)),
                          name: "installer_page_temp_password_setting_time",
                          readonly: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  _: 1
                }),
                (!_ctx.state.editable)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn_m btn_c_3",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onGenerate && _ctx.onGenerate(...args)))
                      }, _toDisplayString(_ctx.$t('common.generate')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        (
        !_ctx.state.isGrp &&
        _ctx.state.isEmsVer31OrHigher &&
        ['admin', 'dev'].includes(_ctx.roleName ?? '') &&
        ['GEN3', 'FOX_ESS', 'FOX_ESS_H3'].includes(_ctx.genType)
      )
          ? (_openBlock(), _createBlock(_component_Accordion, {
              key: 1,
              title: _ctx.$t('common.web_hmi_password'),
              active: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTable, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('common.engineer_password'),
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.web_page_password_engineer,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.data.web_page_password_engineer) = $event)),
                              name: "web_page_password_engineer",
                              readonly: _ctx.state.editable
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          (!_ctx.state.editable)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                type: "button",
                                class: "btn_t btn_c_3",
                                disabled: !_ctx.state.validClass.web_page_password_engineer || _ctx.isNull(_ctx.state.data.web_page_password_engineer),
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChangeWebHMIPassword('Engineer Page')))
                              }, _toDisplayString(_ctx.$t('common.change')), 9, _hoisted_4))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('common.service_password'),
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.web_page_password_service,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.data.web_page_password_service) = $event)),
                              name: "web_page_password_service",
                              readonly: _ctx.state.editable
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          (!_ctx.state.editable)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                type: "button",
                                class: "btn_t btn_c_3",
                                disabled: !_ctx.state.validClass.web_page_password_service || _ctx.isNull(_ctx.state.data.web_page_password_service),
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onChangeWebHMIPassword('Service Page')))
                              }, _toDisplayString(_ctx.$t('common.change')), 9, _hoisted_7))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Accordion, {
          title: _ctx.$t('device.setting_info'),
          active: true
        }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_SettingTable, {
              "table-title": _ctx.$t('device.grid'),
              "title-width": _ctx.titleWidth,
              class: "color-gray",
              "table-sub-title": _ctx.state.isGrp ? _ctx.$t('message.feed_in_description') : ''
            }, {
              default: _withCtx(() => [
                (_ctx.state.isGrp || !_ctx.state.isShowFeedInWatt)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 0,
                      title: `${_ctx.$t('control.feedin')} (%)`,
                      description: `0 ~ 100 %, 0% : ${_ctx.$t('device.no_feedin')}`,
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.state.editable,
                          "value-key": "pv_feed_in_limit",
                          "target-value": _ctx.state.data.pv_feed_in_limit,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.pv_feed_in_limit,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.data.pv_feed_in_limit) = $event)),
                              name: "pv_feed_in_limit",
                              type: "number",
                              min: "0",
                              max: "100",
                              readonly: !_ctx.state.editable || !_ctx.state.enableEditFeedInLimit
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title", "description"]))
                  : _createCommentVNode("", true),
                (_ctx.state.isGrp || _ctx.state.isShowFeedInWatt)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 1,
                      title: `${_ctx.$t('control.feedin')} (W)`,
                      description: `0 ~ 38400 W, 0W : ${_ctx.$t('device.no_feedin')}`,
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.state.editable,
                          "value-key": "pv_feed_in_limit_w",
                          "target-value": _ctx.state.data.pv_feed_in_limit_w,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.pv_feed_in_limit_w,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.data.pv_feed_in_limit_w) = $event)),
                              name: "pv_feed_in_limit_w",
                              type: "number",
                              min: "0",
                              max: "38400",
                              readonly: !_ctx.state.editable || !_ctx.state.enableEditFeedInLimit
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title", "description"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_SettingTableRow, {
                  title: "Multiple Earthed Neutral(MEN) system",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "inverter_multiple_earthed_neutral_system",
                      "target-value": _ctx.state.data.inverter_multiple_earthed_neutral_system,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ToggleInput, {
                          modelValue: _ctx.state.data.inverter_multiple_earthed_neutral_system,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.data.inverter_multiple_earthed_neutral_system) = $event)),
                          name: "inverter_multiple_earthed_neutral_system",
                          toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                          readonly: !_ctx.state.editable
                        }, null, 8, ["modelValue", "toggle", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["table-title", "title-width", "table-sub-title"]), [
              [_vShow, !_ctx.isAcCoupled && _ctx.state.data.energy_policy !== 2]
            ]),
            _createVNode(_component_SettingTable, {
              "table-title": _ctx.$t('device.external_device'),
              "title-width": _ctx.titleWidth
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.external_control'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "external_control_connection",
                      "target-value": _ctx.state.data.external_control_connection,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.external_control_connection,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.data.external_control_connection) = $event)),
                          name: "external_control_connection",
                          "value-type": "number",
                          disabled: !_ctx.state.editable,
                          options: _ctx.selectorOptionsState.extctlGwConnection
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('device.meter_connection'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "external_control_meter_load",
                      "target-value": _ctx.state.data.external_control_meter_load,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RadioGroup, {
                          modelValue: _ctx.state.data.external_control_meter_load,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.data.external_control_meter_load) = $event)),
                          name: "external_control_meter_load",
                          options: [
                { value: 0, text: _ctx.$t('device.inverter') },
                { value: 1, text: _ctx.$t('control.gateway') },
              ],
                          readonly: !_ctx.state.editable,
                          "value-type": "number"
                        }, null, 8, ["modelValue", "options", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, 
            _ctx.state.data.external_control_connection === 1 ||
            _ctx.state.data.external_control_connection === 2 ||
            _ctx.state.data.external_control_connection === 3
          ]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('device.pv_meter_connection'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "external_control_pv_meter_load",
                      "target-value": _ctx.state.data.external_control_pv_meter_load,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RadioGroup, {
                          modelValue: _ctx.state.data.external_control_pv_meter_load,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.state.data.external_control_pv_meter_load) = $event)),
                          name: "external_control_pv_meter_load",
                          options: [
                { value: 0, text: _ctx.$t('device.inverter') },
                { value: 1, text: _ctx.$t('control.gateway') },
              ],
                          readonly: !_ctx.state.editable,
                          "value-type": "number"
                        }, null, 8, ["modelValue", "options", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, 
            _ctx.state.data.external_control_connection === 1 ||
            _ctx.state.data.external_control_connection === 2 ||
            _ctx.state.data.external_control_connection === 3
          ]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.tcp_port'),
                  description: "0 ~ 65535",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "external_control_modbus_tcp_port",
                      "target-value": _ctx.state.data.external_control_modbus_tcp_port,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.external_control_modbus_tcp_port,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.state.data.external_control_modbus_tcp_port) = $event)),
                          name: "external_control_modbus_tcp_port",
                          min: "0",
                          max: "65535",
                          type: "number",
                          readonly: !_ctx.state.editable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.external_control_connection === 1]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.rtu_dev'),
                  description: `${_ctx.$t('common.default')} : Serial 1(/dev/ttymxc3)`,
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "external_control_modbus_rtu_dev",
                      "target-value": _ctx.state.data.external_control_modbus_rtu_dev,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.external_control_modbus_rtu_dev,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.state.data.external_control_modbus_rtu_dev) = $event)),
                          name: "external_control_modbus_rtu_dev",
                          disabled: !_ctx.state.editable,
                          options: _ctx.selectorOptionsState.modbusRtuDev
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title", "description"]), [
                  [_vShow, _ctx.state.data.external_control_connection === 2]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.rtu_baud_rate'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "external_control_modbus_rtu_baudrate",
                      "target-value": _ctx.state.data.external_control_modbus_rtu_baudrate,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.external_control_modbus_rtu_baudrate,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.state.data.external_control_modbus_rtu_baudrate) = $event)),
                          name: "external_control_modbus_rtu_baudrate",
                          "value-type": "number",
                          disabled: !_ctx.state.editable,
                          options: _ctx.selectorOptionsState.modbusRtuBaudrate
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.external_control_connection === 2]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.rtu_parity'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "external_control_modbus_rtu_parity",
                      "target-value": _ctx.state.data.external_control_modbus_rtu_parity,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.external_control_modbus_rtu_parity,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.state.data.external_control_modbus_rtu_parity) = $event)),
                          name: "external_control_modbus_rtu_parity",
                          disabled: !_ctx.state.editable,
                          options: _ctx.selectorOptionsState.modbusRtuParity
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.external_control_connection === 2]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.rtu_data_bits'),
                  description: "5 ~ 8",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "external_control_modbus_rtu_data",
                      "target-value": _ctx.state.data.external_control_modbus_rtu_data,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.external_control_modbus_rtu_data,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.state.data.external_control_modbus_rtu_data) = $event)),
                          name: "external_control_modbus_rtu_data",
                          min: "5",
                          max: "8",
                          type: "number",
                          readonly: !_ctx.state.editable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.external_control_connection === 2]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.rtu_stop_bits'),
                  description: "1 ~ 2",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "external_control_modbus_rtu_stop",
                      "target-value": _ctx.state.data.external_control_modbus_rtu_stop,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.external_control_modbus_rtu_stop,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.state.data.external_control_modbus_rtu_stop) = $event)),
                          name: "external_control_modbus_rtu_stop",
                          min: "1",
                          max: "2",
                          type: "number",
                          readonly: !_ctx.state.editable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.external_control_connection === 2]
                ])
              ]),
              _: 1
            }, 8, ["table-title", "title-width"]),
            _withDirectives(_createVNode(_component_SettingTable, {
              "table-title": _ctx.$t('control.energy_policy'),
              "title-width": _ctx.titleWidth
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.energy_policy'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "energy_policy",
                      "target-value": _ctx.state.data.energy_policy,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.energy_policy,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.state.data.energy_policy) = $event)),
                          name: "energy_policy",
                          "value-type": "number",
                          disabled: !_ctx.state.editable,
                          "before-change": _ctx.checkEnergyPolicy,
                          options: _ctx.selectorOptionsState.energyPolicy
                        }, null, 8, ["modelValue", "disabled", "before-change", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"])
              ]),
              _: 1
            }, 8, ["table-title", "title-width"]), [
              [_vShow, _ctx.state.data.external_control_connection == 0]
            ]),
            _withDirectives(_createVNode(_component_SettingTable, {
              "table-title": _ctx.$t('control.pv_setting'),
              "title-width": _ctx.titleWidth,
              class: "color-gray",
              "table-sub-title": _ctx.$t('message.record_installed_pv')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('device.pv_type'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "pv_type",
                      "target-value": _ctx.state.data.pv_type,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.pv_type,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.state.data.pv_type) = $event)),
                          name: "pv_type",
                          "value-type": "number",
                          disabled: !_ctx.state.editable,
                          options: [
                { text: _ctx.$t('common.select'), value: null },
                { text: _ctx.$t('device.string_inverter'), value: 0 },
                { text: _ctx.$t('device.micro_inverter'), value: 1 },
                { text: _ctx.$t('device.power_optimizers'), value: 2 },
              ]
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: `${_ctx.$t('device.string_level')} / ${_ctx.$t('device.module_level')}`,
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "pv_capacity_calc_option",
                      "target-value": _ctx.state.data.pv_capacity_calc_option,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RadioGroup, {
                          modelValue: _ctx.state.data.pv_capacity_calc_option,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.state.data.pv_capacity_calc_option) = $event)),
                          name: "pv_capacity_calc_option",
                          options: [
                { value: 0, text: _ctx.$t('device.string_level') },
                { value: 1, text: _ctx.$t('device.module_level') },
              ],
                          readonly: !_ctx.state.editable,
                          "value-type": "number"
                        }, null, 8, ["modelValue", "options", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('device.pv_string_quantity'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "pv_string_count",
                      "target-value": _ctx.state.data.pv_string_count,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.pv_string_count,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.state.data.pv_string_count) = $event)),
                          name: "pv_string_count",
                          "value-type": "number",
                          "after-change": 
                (stringCount) => {
                  _ctx.afterChange(stringCount);
                }
              ,
                          disabled: !_ctx.state.editable,
                          options: [
                { text: _ctx.$t('common.select'), value: null },
                { text: '1', value: 1 },
                { text: '2', value: 2 },
                { text: '3', value: 3 },
              ]
                        }, null, 8, ["modelValue", "after-change", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.data.pv_capacity_calc_option === 0 ? _ctx.state.data.pv_string_count : 0, (value, idx) => {
                  return (_openBlock(), _createBlock(_component_SettingTableRow, {
                    key: idx,
                    title: `PV-${value} ${_ctx.$t('device.power')}`,
                    description: `${_ctx.computedVal.valueRange.pv_per.min} ~ ${_ctx.computedVal.valueRange.pv_per.max} W`,
                    "is-indent": true
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ChangedMark, {
                        "show-mark": _ctx.state.editable,
                        "value-key": `pv_string_power${value}`,
                        "target-value": _ctx.getPvStringPowerValue(value),
                        "changed-value-set": _ctx.state.changedValueSet,
                        onChange: _ctx.onChange
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BasicInput, {
                            modelValue: _ctx.state.data[_ctx.getPvStringPowerKey(value)],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.state.data[_ctx.getPvStringPowerKey(value)]) = $event),
                            name: `pv_string_power${value}`,
                            type: "number",
                            readonly: !_ctx.state.editable
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "readonly"])
                        ]),
                        _: 2
                      }, 1032, ["show-mark", "value-key", "target-value", "changed-value-set", "onChange"])
                    ]),
                    _: 2
                  }, 1032, ["title", "description"]))
                }), 128)),
                (_ctx.state.data.pv_capacity_calc_option === 1)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 0,
                      title: _ctx.$t('device.module_count'),
                      description: `${_ctx.computedVal.valueRange.pv_module_count.min} ~ ${_ctx.computedVal.valueRange.pv_module_count.max} EA`,
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.state.editable,
                          "value-key": "pv_module_count",
                          "target-value": _ctx.state.data.pv_module_count,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.pv_module_count,
                              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.state.data.pv_module_count) = $event)),
                              name: "pv_module_count",
                              type: "number",
                              readonly: !_ctx.state.editable
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title", "description"]))
                  : _createCommentVNode("", true),
                (_ctx.state.data.pv_capacity_calc_option === 1)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 1,
                      title: _ctx.$t('device.module_power_class'),
                      description: `${_ctx.computedVal.valueRange.pv_module_power.min} ~ ${_ctx.computedVal.valueRange.pv_module_power.max} W`,
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.state.editable,
                          "value-key": "pv_module_power",
                          "target-value": _ctx.state.data.pv_module_power,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.pv_module_power,
                              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.state.data.pv_module_power) = $event)),
                              name: "pv_module_power",
                              type: "number",
                              readonly: !_ctx.state.editable
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title", "description"]))
                  : _createCommentVNode("", true),
                (_ctx.state.data.pv_capacity_calc_option === 1)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 2,
                      title: _ctx.$t('device.total_module_power'),
                      description: "W",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.totalModulePower,
                          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.totalModulePower) = $event)),
                          type: "number",
                          readonly: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["table-title", "title-width", "table-sub-title"]), [
              [_vShow, !_ctx.isAcCoupled]
            ]),
            _createVNode(_component_SettingTable, {
              "table-title": _ctx.$t('device.battery'),
              "title-width": _ctx.titleWidth
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTableRow, {
                  title: "Number of Battery Rack",
                  description: `${_ctx.computedVal.valueRange.battery_installed_rack_count.min} ~ ${_ctx.computedVal.valueRange.battery_installed_rack_count.max}`,
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "battery_installed_rack_count",
                      "target-value": _ctx.state.data.battery_installed_rack_count,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.battery_installed_rack_count,
                          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.state.data.battery_installed_rack_count) = $event)),
                          name: "battery_installed_rack_count",
                          type: "number",
                          min: "0",
                          max: "3",
                          readonly: !_ctx.state.editable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["description"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.energy_backup'),
                  description: 
            `${_ctx.computedVal.valueRange.battery_backup_soc.min} ~ ${_ctx.computedVal.valueRange.battery_backup_soc.max} %, ` +
            _ctx.$t('message.5_per_incre')
          ,
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "battery_backup_soc",
                      "target-value": _ctx.state.data.battery_backup_soc,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BaseSlider, {
                          modelValue: _ctx.state.data.battery_backup_soc,
                          "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.state.data.battery_backup_soc) = $event)),
                          min: 0,
                          max: 100,
                          step: 5,
                          format: { suffix: '%' },
                          disabled: !_ctx.state.editable
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title", "description"])
              ]),
              _: 1
            }, 8, ["table-title", "title-width"]),
            _withDirectives(_createVNode(_component_SettingTable, {
              "table-title": _ctx.$t('device.grid_meter'),
              "title-width": _ctx.titleWidth
            }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('device.meter_model'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "meter_model",
                      "target-value": _ctx.state.data.meter_model,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.meter_model,
                          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.state.data.meter_model) = $event)),
                          name: "meter_model",
                          "value-type": "number",
                          disabled: !_ctx.state.editable,
                          options: _ctx.selectorOptionsState.smtrModlCd,
                          "after-change": _ctx.onChangeMeterModel
                        }, null, 8, ["modelValue", "disabled", "options", "after-change"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.external_control_connection === 0 || _ctx.state.data.external_control_meter_load === 0]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: "Connection Type",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "meter_connection",
                      "target-value": _ctx.state.data.meter_connection,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.meter_connection,
                          "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.state.data.meter_connection) = $event)),
                          name: "meter_connection",
                          "value-type": "number",
                          disabled: !_ctx.state.editable || _ctx.state.data.meter_model === null || _ctx.state.data.meter_model === 0,
                          options: _ctx.selectorOptionsState.modbusConnectionType
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.state.data.external_control_connection === 0 || _ctx.state.data.external_control_meter_load === 0]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.tcp_ip_address'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "meter_modbus_tcp_ip",
                      "target-value": _ctx.state.data.meter_modbus_tcp_ip,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.meter_modbus_tcp_ip,
                          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.state.data.meter_modbus_tcp_ip) = $event)),
                          name: "meter_modbus_tcp_ip",
                          readonly: !_ctx.state.editable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, 
            (_ctx.state.data.external_control_connection === 0 || _ctx.state.data.external_control_meter_load === 0) &&
            _ctx.state.data.meter_connection === 1
          ]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.tcp_port'),
                  description: "0 ~ 65535",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "meter_modbus_tcp_port",
                      "target-value": _ctx.state.data.meter_modbus_tcp_port,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.meter_modbus_tcp_port,
                          "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.state.data.meter_modbus_tcp_port) = $event)),
                          name: "meter_modbus_tcp_port",
                          min: "0",
                          max: "65535",
                          type: "number",
                          readonly: !_ctx.state.editable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, 
            (_ctx.state.data.external_control_connection === 0 || _ctx.state.data.external_control_meter_load === 0) &&
            _ctx.state.data.meter_connection === 1
          ]
                ]),
                (
            (_ctx.state.data.external_control_connection === 0 || _ctx.state.data.external_control_meter_load === 0) &&
            _ctx.state.data.meter_connection === 2
          )
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 0,
                      title: _ctx.$t('control.rtu_dev'),
                      description: `${_ctx.$t('common.default')} : Serial 1(/dev/ttymxc3)`,
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.state.editable,
                          "value-key": "meter_modbus_rtu_dev",
                          "target-value": _ctx.state.data.meter_modbus_rtu_dev,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Selector, {
                              modelValue: _ctx.state.data.meter_modbus_rtu_dev,
                              "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.state.data.meter_modbus_rtu_dev) = $event)),
                              name: "meter_modbus_rtu_dev",
                              disabled: !_ctx.state.editable,
                              options: _ctx.selectorOptionsState.modbusRtuDev
                            }, null, 8, ["modelValue", "disabled", "options"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title", "description"]))
                  : _createCommentVNode("", true),
                (
            (_ctx.state.data.external_control_connection === 0 || _ctx.state.data.external_control_meter_load === 0) &&
            _ctx.state.data.meter_connection === 2
          )
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 1,
                      title: _ctx.$t('control.rtu_baud_rate'),
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.state.editable,
                          "value-key": "meter_modbus_rtu_baudrate",
                          "target-value": _ctx.state.data.meter_modbus_rtu_baudrate,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Selector, {
                              modelValue: _ctx.state.data.meter_modbus_rtu_baudrate,
                              "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.state.data.meter_modbus_rtu_baudrate) = $event)),
                              name: "meter_modbus_rtu_baudrate",
                              "value-type": "number",
                              disabled: !_ctx.state.editable,
                              options: _ctx.selectorOptionsState.modbusRtuBaudrate
                            }, null, 8, ["modelValue", "disabled", "options"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                (
            (_ctx.state.data.external_control_connection === 0 || _ctx.state.data.external_control_meter_load === 0) &&
            _ctx.state.data.meter_connection === 2
          )
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 2,
                      title: _ctx.$t('control.rtu_parity'),
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.state.editable,
                          "value-key": "meter_modbus_rtu_parity",
                          "target-value": _ctx.state.data.meter_modbus_rtu_parity,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Selector, {
                              modelValue: _ctx.state.data.meter_modbus_rtu_parity,
                              "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.state.data.meter_modbus_rtu_parity) = $event)),
                              name: "meter_modbus_rtu_parity",
                              disabled: !_ctx.state.editable,
                              options: _ctx.selectorOptionsState.modbusRtuParity
                            }, null, 8, ["modelValue", "disabled", "options"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                (
            (_ctx.state.data.external_control_connection === 0 || _ctx.state.data.external_control_meter_load === 0) &&
            _ctx.state.data.meter_connection === 2
          )
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 3,
                      title: _ctx.$t('control.rtu_data_bits'),
                      description: "5 ~ 8",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.state.editable,
                          "value-key": "meter_modbus_rtu_data",
                          "target-value": _ctx.state.data.meter_modbus_rtu_data,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.meter_modbus_rtu_data,
                              "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.state.data.meter_modbus_rtu_data) = $event)),
                              name: "meter_modbus_rtu_data",
                              type: "number",
                              min: "5",
                              max: "8",
                              readonly: !_ctx.state.editable
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                (
            (_ctx.state.data.external_control_connection === 0 || _ctx.state.data.external_control_meter_load === 0) &&
            _ctx.state.data.meter_connection === 2
          )
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 4,
                      title: _ctx.$t('control.rtu_stop_bits'),
                      description: "1 ~ 2",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.state.editable,
                          "value-key": "meter_modbus_rtu_stop",
                          "target-value": _ctx.state.data.meter_modbus_rtu_stop,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.meter_modbus_rtu_stop,
                              "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.state.data.meter_modbus_rtu_stop) = $event)),
                              name: "meter_modbus_rtu_stop",
                              type: "number",
                              min: "1",
                              max: "2",
                              readonly: !_ctx.state.editable
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["table-title", "title-width"]), [
              [_vShow, _ctx.state.data.external_control_connection === 0 || _ctx.state.data.external_control_meter_load === 0]
            ]),
            _withDirectives(_createVNode(_component_SettingTable, {
              "table-title": "Third Party Inverter",
              "title-width": _ctx.titleWidth
            }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: "Third Party Inverter",
                  "is-indent": "",
                  description: _ctx.state.isGrp ? 'Older version of FW does not apply this value.' : ''
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "third_party_inverter_exist",
                      "target-value": _ctx.state.data.third_party_inverter_exist,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ToggleInput, {
                          modelValue: _ctx.state.data.third_party_inverter_exist,
                          "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.state.data.third_party_inverter_exist) = $event)),
                          name: "third_party_inverter_exist",
                          toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                          readonly: !_ctx.state.editable
                        }, null, 8, ["modelValue", "toggle", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["description"]), [
                  [_vShow, _ctx.state.isGrp || _ctx.state.isEmsVer20OrHigher]
                ]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('device.measurement_type'),
                  "is-indent": "",
                  description: 
            (() => {
              if (_ctx.state.isGrp && _ctx.isHybrid) {
                return 'Older version of FW does not apply this value.';
              } else if (_ctx.state.isGrp && _ctx.isAcCoupled) {
                return `Older version of FW does not apply 'None'.`;
              }
              return '';
            })()
          
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "pv_connection_type",
                      "target-value": _ctx.state.data.pv_connection_type,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RadioGroup, {
                          modelValue: _ctx.state.data.pv_connection_type,
                          "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.state.data.pv_connection_type) = $event)),
                          name: "pv_connection_type",
                          options: 
                _ctx.state.isGrp || _ctx.state.isEmsVer20OrHigher
                  ? [
                      { value: 0, text: _ctx.$t('device.external_ct') },
                      { value: 1, text: _ctx.$t('device.pv_meter') },
                      { value: 2, text: _ctx.$t('common.none') },
                    ]
                  : [
                      { value: 0, text: _ctx.$t('device.external_ct') },
                      { value: 1, text: _ctx.$t('device.pv_meter') },
                    ]
              ,
                          readonly: !_ctx.state.editable,
                          "value-type": "number"
                        }, null, 8, ["modelValue", "options", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title", "description"]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('device.meter_model'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "pv_meter_model",
                      "target-value": _ctx.state.data.pv_meter_model,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.pv_meter_model,
                          "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.state.data.pv_meter_model) = $event)),
                          name: "pv_meter_model",
                          "value-type": "number",
                          disabled: !_ctx.state.editable,
                          options: _ctx.selectorOptionsState.smtrModlPvCd,
                          "after-change": _ctx.onChangePvMeterModel
                        }, null, 8, ["modelValue", "disabled", "options", "after-change"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.pv_connection_type === 1]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: "Connection Type",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "pv_meter_connection",
                      "target-value": _ctx.state.data.pv_meter_connection,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.pv_meter_connection,
                          "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.state.data.pv_meter_connection) = $event)),
                          name: "pv_meter_connection",
                          "value-type": "number",
                          disabled: !_ctx.state.editable || _ctx.state.data.pv_meter_model === null || _ctx.state.data.pv_meter_model === 0,
                          options: _ctx.selectorOptionsState.modbusConnectionType
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.state.data.pv_connection_type === 1]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.tcp_ip_address'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "pv_meter_modbus_tcp_ip",
                      "target-value": _ctx.state.data.pv_meter_modbus_tcp_ip,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.pv_meter_modbus_tcp_ip,
                          "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.state.data.pv_meter_modbus_tcp_ip) = $event)),
                          name: "pv_meter_modbus_tcp_ip",
                          readonly: !_ctx.state.editable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.pv_connection_type === 1 && _ctx.state.data.pv_meter_connection === 1]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.tcp_port'),
                  description: "0 ~ 65535",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "pv_meter_modbus_tcp_port",
                      "target-value": _ctx.state.data.pv_meter_modbus_tcp_port,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.pv_meter_modbus_tcp_port,
                          "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.state.data.pv_meter_modbus_tcp_port) = $event)),
                          name: "pv_meter_modbus_tcp_port",
                          min: "0",
                          max: "65535",
                          type: "number",
                          readonly: !_ctx.state.editable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.pv_connection_type === 1 && _ctx.state.data.pv_meter_connection === 1]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.rtu_dev'),
                  description: `${_ctx.$t('common.default')} : Serial 2(/dev/ttymxc0)`,
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "pv_meter_modbus_rtu_dev",
                      "target-value": _ctx.state.data.pv_meter_modbus_rtu_dev,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.pv_meter_modbus_rtu_dev,
                          "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.state.data.pv_meter_modbus_rtu_dev) = $event)),
                          name: "pv_meter_modbus_rtu_dev",
                          disabled: !_ctx.state.editable,
                          options: _ctx.selectorOptionsState.modbusRtuDev
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title", "description"]), [
                  [_vShow, _ctx.state.data.pv_connection_type === 1 && _ctx.state.data.pv_meter_connection === 2]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.rtu_baud_rate'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "pv_meter_modbus_rtu_baudrate",
                      "target-value": _ctx.state.data.pv_meter_modbus_rtu_baudrate,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.pv_meter_modbus_rtu_baudrate,
                          "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.state.data.pv_meter_modbus_rtu_baudrate) = $event)),
                          name: "pv_meter_modbus_rtu_baudrate",
                          "value-type": "number",
                          disabled: !_ctx.state.editable,
                          options: _ctx.selectorOptionsState.modbusRtuBaudrate
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.pv_connection_type === 1 && _ctx.state.data.pv_meter_connection === 2]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.rtu_parity'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "pv_meter_modbus_rtu_parity",
                      "target-value": _ctx.state.data.pv_meter_modbus_rtu_parity,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.pv_meter_modbus_rtu_parity,
                          "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.state.data.pv_meter_modbus_rtu_parity) = $event)),
                          name: "pv_meter_modbus_rtu_parity",
                          disabled: !_ctx.state.editable,
                          options: _ctx.selectorOptionsState.modbusRtuParity
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.pv_connection_type === 1 && _ctx.state.data.pv_meter_connection === 2]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.rtu_data_bits'),
                  description: "5 ~ 8",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "pv_meter_modbus_rtu_data",
                      "target-value": _ctx.state.data.pv_meter_modbus_rtu_data,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.pv_meter_modbus_rtu_data,
                          "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.state.data.pv_meter_modbus_rtu_data) = $event)),
                          name: "pv_meter_modbus_rtu_data",
                          type: "number",
                          min: "5",
                          max: "8",
                          readonly: !_ctx.state.editable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.pv_connection_type === 1 && _ctx.state.data.pv_meter_connection === 2]
                ]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.rtu_stop_bits'),
                  description: "1 ~ 2",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "pv_meter_modbus_rtu_stop",
                      "target-value": _ctx.state.data.pv_meter_modbus_rtu_stop,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.pv_meter_modbus_rtu_stop,
                          "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.state.data.pv_meter_modbus_rtu_stop) = $event)),
                          name: "pv_meter_modbus_rtu_stop",
                          type: "number",
                          min: "1",
                          max: "2",
                          readonly: !_ctx.state.editable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.pv_connection_type === 1 && _ctx.state.data.pv_meter_connection === 2]
                ])
              ]),
              _: 1
            }, 8, ["title-width"]), [
              [_vShow, _ctx.state.isGrp || _ctx.state.isEmsVer20OrHigher || _ctx.state.isAcCoupled]
            ])
          ]),
          _: 1
        }, 8, ["title"]),
        _createElementVNode("div", {
          class: _normalizeClass(["btn_wrap_m", { editorble: _ctx.state.editable }])
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              type: "button",
              class: "btn_m btn_c_2",
              onClick: _cache[47] || (_cache[47] = 
//@ts-ignore
(...args) => (_ctx.goToList && _ctx.goToList(...args)))
            }, _toDisplayString(_ctx.$t('message.go_to_list')), 1),
            (!_ctx.hideEdit)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn_m btn_c_3",
                  onClick: _cache[48] || (_cache[48] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
                }, _toDisplayString(_ctx.$t('common.edit')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", {
              type: "reset",
              class: "btn_m btn_c_2",
              onClick: ($event: any) => (_ctx.onCancel(handleReset))
            }, _toDisplayString(_ctx.$t('common.cancel')), 9, _hoisted_10),
            _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.$t('common.apply')), 1)
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["validation-schema", "onSubmit"]),
    (_ctx.state.confirmPopup.on)
      ? (_openBlock(), _createBlock(_component_SettingConfirmPopup, {
          key: 0,
          title: _ctx.$t('control.basic_sett'),
          data: _ctx.state.confirmPopup.data,
          onConfirm: _ctx.onConfirm,
          onCancel: _cache[50] || (_cache[50] = () => (_ctx.state.confirmPopup.on = false))
        }, null, 8, ["title", "data", "onConfirm"]))
      : _createCommentVNode("", true)
  ], 64))
}