
import type { PropType } from 'vue';
import { defineComponent, computed, onBeforeUnmount, ref } from 'vue';

import { useIntervalFn } from '@vueuse/core';
import axios from 'axios';
import EventSource from 'eventsource';
import moment from 'moment';

import { withLoading } from '@hems/component';
import { DEFAULT_REALTIME_MONITORING_DATA } from '@hems/container/src/energyflow/value/EnergyValueFunctions';
import {
  CommonService,
  UserReportService,
  NotificationService,
  AuthService,
  StatisticsServiceAdmin,
} from '@hems/service';
import DeviceService from '@hems/service/src/api/device/DeviceService';
import { AuthHelper, Helper, DateHelper, useGenType } from '@hems/util';
import {
  FIFTY_SECONDS_VALUE_IN_MILLISECONDS,
  FIVE_MINUTES_VALUE_IN_MILLISECONDS,
  GEN_TYPE,
  ONE_MINUTE_VALUE_IN_MILLISECONDS,
  TEN_SECONDS_VALUE_IN_MILLISECONDS,
} from '@hems/util/src/constant';
import { NETWORK_TYPE_CODE } from '@hems/util/src/constant/network';
import { today, getYesterday } from '@hems/util/src/helper/dateHelper';

import type { BaseDeviceInfo } from 'hems/device';
import type { RealtimeMonitoringInfo } from 'hems/energyFlow';
import type { SiteConfiguration } from 'hems/install';
import type { ReportIntervalResult } from 'hems/report';

// FIXME: 절대 경로로 변경 시 node_modules 내의 경로로 찾는 문제
import EnergyFlowTimeoutPopup from './EnergyFlowTimeoutPopup.vue';
import DashboardEnergyFlowContainer from './flow/DashboardEnergyFlowContainer.vue';
import DashboardEnergyValueContainer from './value/DashboardEnergyValueContainer.vue';

export default defineComponent({
  name: 'DashboardEnergyValueFlowContainer',
  components: {
    DashboardEnergyValueContainer,
    DashboardEnergyFlowContainer,
    EnergyFlowTimeoutPopup,
  },
  props: {
    baseDeviceInfo: {
      type: Object as PropType<BaseDeviceInfo | null>,
      required: true,
      default: null,
    },
    siteConfigurationInfo: {
      type: Object as PropType<SiteConfiguration | null>,
      required: false,
      default: null,
    },
    isConsumptionCt: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['dataLoaded'],
  async setup(props) {
    const daqAuthApiAxiosInstance = axios.create({
      baseURL: process.env.VUE_APP_DAQ_API_URL,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        username: props.baseDeviceInfo?.site_id,
        password: process.env.VUE_APP_DAQ_API_AUTH_PASSWORD,
      },
    });
    const commonService = new CommonService(window.axiosInstance.axios);
    const statisticsService = new StatisticsServiceAdmin(window.axiosInstance.axios);
    const userReportService = new UserReportService(window.axiosInstance.axios);
    const notificationService = new NotificationService(window.axiosInstance.axios);
    const deviceService = new DeviceService(window.axiosInstance.axios);
    const authService = new AuthService(daqAuthApiAxiosInstance);
    const { genType, isFoxESSType } = useGenType();
    const disconnect = ref<boolean>(false);
    const serverSentEventSource = ref<EventSource | null>(null);
    const isRealtimeConnected = ref<boolean>(false);
    const realtimeData = ref<RealtimeMonitoringInfo>({ ...DEFAULT_REALTIME_MONITORING_DATA });
    const baseDevice = computed<BaseDeviceInfo | null>(() => props.baseDeviceInfo);
    const hourDate = ref<{ today: Date; yesterday: Date }>({
      today: today(),
      yesterday: getYesterday(),
    });
    const reportData = ref<{ today: ReportIntervalResult | null; yesterday: ReportIntervalResult | null }>({
      today: null,
      yesterday: null,
    });
    const isConnectThirdParty = ref<boolean>(false);
    const realtimeApiAuthToken = ref<string>('');
    const isEnergyFlowTimeoutPopup = ref(false);
    const isCellular = ref(false);

    const realtime_url = process.env.VUE_APP_REALTIME_API_URL;

    const getRealtimeMonitoringData = () => {
      if (serverSentEventSource.value) {
        serverSentEventSource.value.onmessage = function (evt: MessageEvent) {
          const data = evt.data;
          if (Helper.isJSON(data)) {
            const monitoringData = JSON.parse(data);
            realtimeData.value = transformRealtimeMonitoringData(monitoringData.monitoring_data);
            // TODO: network type을 가져오는 신규 API의 응답 값과 실시간 모니터링의 network type 응답 값이 상이하여 추가한 로직으로 테스트 후 통합 예정
            if (realtimeData.value.network_type === NETWORK_TYPE_CODE.LTE) {
              isCellular.value = true;
            }
          }
        };
        serverSentEventSource.value.onerror = function (e: MessageEvent) {
          console.error(e);
          serverSentEventSource.value?.close();
          isRealtimeConnected.value = false;
        };
      }
    };

    const getFoxESSEnergyFlowData = () => {
      if (!isFoxESSType) return;

      checkConnection().then(async (status) => {
        disconnect.value = !status;
        if (status) {
          const monitoringData = await statisticsService.getFoxESSMonitoringData(baseDevice.value?.device_id ?? '');
          realtimeData.value = transformRealtimeMonitoringData(monitoringData.monitoring_data);
          // TODO: network type을 가져오는 신규 API의 응답 값과 실시간 모니터링의 network type 응답 값이 상이하여 추가한 로직으로 테스트 후 통합 예정
          if (realtimeData.value.network_type === NETWORK_TYPE_CODE.LTE) {
            isCellular.value = true;
          }
        }
      });
    };

    const transformRealtimeMonitoringData = (data: RealtimeMonitoringInfo) => {
      const { grid_status = true, cons_pw = 0, load_main_pw = 0, load_sub_pw = 0 } = data;

      return {
        ...data,
        grid_status,
        cons_pw: genType.value === GEN_TYPE.GEN2 ? cons_pw : load_main_pw + load_sub_pw,
      };
    };

    const checkConnection = async () => {
      return await commonService.isConnection(baseDevice.value?.device_id ?? '');
    };

    const retryConnection = () => {
      withLoading(async () => {
        await checkConnection().then((status) => {
          if (status) {
            disconnect.value = status;
          }
        });
      })();
    };

    const getRealtimeApiAuthToken = async (): Promise<string> => {
      const authToken = realtimeApiAuthToken.value;
      if (authToken) {
        const jwtObject = AuthHelper.parseJwt(authToken);
        if (jwtObject && !AuthHelper.isExpired(jwtObject)) {
          return authToken;
        }
      }

      try {
        const res = await authService.getRealtimeApiAuthToken();
        realtimeApiAuthToken.value = res.data;

        return res.data;
      } catch (e) {
        console.error(e);
        realtimeApiAuthToken.value = '';

        return '';
      }
    };

    const getEnergyFlowData = () => {
      if (isFoxESSType) return;

      checkConnection().then(async (status) => {
        disconnect.value = !status;
        if (status) {
          const authToken = await getRealtimeApiAuthToken();
          if (Helper.isNull(authToken)) return;
          serverSentEventSource.value = new EventSource(`${realtime_url}/${baseDevice.value?.site_id}`, {
            withCredentials: true,
            headers: {
              'X-AUTH-TOKEN': authToken,
            },
          });
          serverSentEventSource.value.onopen = function () {
            isRealtimeConnected.value = true;
            getRealtimeMonitoringData();
          };
        }
      });
    };

    const getReportDataParams = (dayType: 'today' | 'yesterday' = 'today') => {
      const date = dayType === 'today' ? hourDate.value.today : hourDate.value.yesterday;
      const start = DateHelper.formatDateByInterval(date, 'hourly');
      const end = DateHelper.formatDateByInterval(DateHelper.getHourlyEndValue(date), 'hourly');

      return {
        from: start,
        to: end,
        type: 'gen,con,demand',
        device_id: baseDevice.value?.device_id ?? '',
        site_id: Number(baseDevice.value?.site_id),
        product_model_nm: baseDevice.value?.product_model_nm ?? '',
        timezone_id: baseDevice.value?.timezone_id ?? '',
      };
    };

    const loadReportData = async () => {
      try {
        await userReportService
          .getServerTime(baseDevice.value?.timezone_id ?? '')
          .then((res: string) => {
            hourDate.value.today = moment(DateHelper.getTime(res, 'hourly')).startOf('day').toDate();
            hourDate.value.yesterday = moment(DateHelper.getTime(res, 'hourly'))
              .subtract(1, 'days')
              .startOf('day')
              .toDate();
          })
          .then(async () => {
            const [todayReportData, yesterdayReportData] = await Promise.all([
              userReportService.getReportsByInterval('hourly', getReportDataParams('today')),
              userReportService.getReportsByInterval('hourly', getReportDataParams('yesterday')),
            ]);
            if (todayReportData.status === 'ok') {
              reportData.value.today = todayReportData.result;
              isConnectThirdParty.value = todayReportData.result.third_party === 'Y';
            }
            if (yesterdayReportData.status === 'ok') {
              reportData.value.yesterday = yesterdayReportData.result;
            }
          });
      } catch (e) {
        console.error(e);
      }
    };

    const loadSiteNetworkType = async () => {
      try {
        if (baseDevice.value?.site_id) {
          const { networkType } = await deviceService.getSiteNetworkType(baseDevice.value?.site_id);
          isCellular.value = networkType === NETWORK_TYPE_CODE.LTE;
        }
      } catch (e) {
        console.error(e);
      }
    };

    const startRealtimeMonitoring = () => {
      if (!isFoxESSType && !isEnergyFlowTimeoutPopup.value) {
        serverSentEventSource.value?.close();
        getEnergyFlowData();
      }
    };

    const { pause: pauseRealtimeMonitoringInterval, resume: resumeRealtimeMonitoringInterval } = useIntervalFn(
      startRealtimeMonitoring,
      FIFTY_SECONDS_VALUE_IN_MILLISECONDS
    );

    const FoxESSInterval = setInterval(() => {
      if (isFoxESSType && !isEnergyFlowTimeoutPopup.value) {
        getFoxESSEnergyFlowData();
      }
    }, ONE_MINUTE_VALUE_IN_MILLISECONDS);

    const reportInterval = setInterval(() => {
      loadReportData();
    }, ONE_MINUTE_VALUE_IN_MILLISECONDS);

    const { pause: pauseEnergyFlowTimeout, resume: resumeEnergyFlowTimeout } = useIntervalFn(() => {
      if (!disconnect.value && isCellular.value) {
        isEnergyFlowTimeoutPopup.value = true;
        serverSentEventSource.value?.close();
        isRealtimeConnected.value = false;
        pauseRealtimeMonitoringInterval();
        pauseEnergyFlowTimeout();
      }
    }, FIVE_MINUTES_VALUE_IN_MILLISECONDS + TEN_SECONDS_VALUE_IN_MILLISECONDS);

    const resumeEnergyFlow = () => {
      isEnergyFlowTimeoutPopup.value = false;
      startRealtimeMonitoring();
      resumeRealtimeMonitoringInterval();
      resumeEnergyFlowTimeout();
    };

    // @TODO notification 기능 추가 시 호출
    const modals = async () => {
      return await notificationService.getNotificationModals();
    };

    // @TODO notification 기능 추가 시 호출
    async function updateNotificationRecongnition(notificationId: number) {
      try {
        await notificationService.updateNotificationRecognition({ notificationIds: notificationId.toString() });
      } catch (e) {
        console.error(e);
      }
    }

    onBeforeUnmount(() => {
      serverSentEventSource.value?.close();
      clearInterval(FoxESSInterval);
      clearInterval(reportInterval);
    });

    await Promise.all([getFoxESSEnergyFlowData(), getEnergyFlowData(), loadReportData(), loadSiteNetworkType()]);

    return {
      genType,
      disconnect,
      isRealtimeConnected,
      realtimeData,
      baseDevice,
      hourDate,
      reportData,
      isConnectThirdParty,
      isEnergyFlowTimeoutPopup,
      retryConnection,
      resumeEnergyFlow,
    };
  },
});
