/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { GEN_TYPE } from '@hems/util/src/constant';
import { useStore } from 'vuex';
import type { RootStoreAdminInstaller } from 'hems/common/store';

export const useSiteDeviceGenType = () => {
  const store = useStore<RootStoreAdminInstaller>();

  const siteDeviceGenType = store.state.siteNew.genType;

  const isAC = siteDeviceGenType === GEN_TYPE.AC_SYS;

  const isGEN2 = siteDeviceGenType === GEN_TYPE.GEN2;

  const isGEN3 = siteDeviceGenType === GEN_TYPE.GEN3;

  const isFOX = siteDeviceGenType === GEN_TYPE.FOX_ESS || siteDeviceGenType === GEN_TYPE.FOX_ESS_H3;

  /** AC 제외 장비 타입 */
  const isMOW = isGEN2 || isGEN3 || isFOX;

  return {
    isGEN2,
    isGEN3,
    isFOX,
    isAC,
    isMOW,
  };
};
