
import { Helper } from '@hems/util';
import { computed, defineComponent, onMounted, PropType, reactive, watch } from 'vue';

export default defineComponent({
  name: 'IncreasingNumberLabel',
  props: {
    targetNumber: {
      type: Number as PropType<number>,
      default: 0,
    },
    seconds: {
      type: Number as PropType<number>,
      default: 2,
    },
    decimal: {
      type: Number as PropType<number>,
      default: 0,
    },
    locale: {
      type: String as PropType<string>,
      default: 'en',
    },
  },
  setup(props) {
    const state = reactive({
      number: 0,
      formatNumber: computed(() =>
        Helper.getNumberFormatter(props.locale, {
          minimumFractionDigits: props.decimal,
          maximumFractionDigits: props.decimal,
        }).format(state.number)
      ),
    }) as {
      number: number;
      formatNumber: string;
    };

    const loop = (repeat: number, delay: number, func: () => void) => {
      if (repeat <= 0) return;
      setTimeout(() => {
        func();
        loop(repeat - 1, delay, func);
      }, delay);
    };

    const run = () => {
      const isNegative = props.targetNumber < 0;
      const absNum = Math.abs(props.targetNumber);
      const _increaseUnit = Math.ceil(absNum / 100);
      const increaseUnit =
        _increaseUnit > 10000
          ? Math.ceil(_increaseUnit / 1000) * 1000
          : _increaseUnit > 1000
          ? Math.ceil(_increaseUnit / 100) * 100
          : _increaseUnit > 100
          ? Math.ceil(_increaseUnit / 10) * 10
          : _increaseUnit;
      const repeatNum = Math.ceil(absNum / increaseUnit);
      const delay = (props.seconds * 1000) / repeatNum;

      loop(repeatNum, delay, () => {
        if (Math.abs(state.number) + increaseUnit > absNum) {
          state.number = props.targetNumber;
          return;
        }
        if (isNegative) {
          state.number -= increaseUnit;
          return;
        }
        state.number += increaseUnit;
      });
    };

    watch(() => props.targetNumber, run);
    onMounted(() => {
      run();
    });
    return {
      state,
    };
  },
});
