import type { ACDeviceTypeValue } from '@hems/util/src/constant';
import { AC_DEVICE_TYPE, GEN_TYPE } from '@hems/util/src/constant';
import type { RoleNameValue } from '@hems/util/src/constant/role';
import { ROLE_NAME } from '@hems/util/src/constant/role';

import type { GenType } from 'hems/device';
import type {
  ACCombinerKey as ACCombinerKeyType,
  BatteryKey,
  EnergyHourKey,
  EnergyMinuteKey,
  InverterKey,
  MeterKey,
  PowerKey,
  PvKey,
} from 'hems/device/statistics/common';
import type { GraphKeysParameters } from 'hems/statistics';

type ACDeviceTypeWithRoleName = `${ACDeviceTypeValue}_${RoleNameValue}`;

export const powerKey: { [K in GenType | ACDeviceTypeValue]?: PowerKey[] } = {
  GEN2: ['inverter_pw', 'pv_pw', 'bt_pw', 'load_pw', 'meter_active_pw'],
  GEN3: ['inverter_pw', 'pv_pw', 'bt_pw', 'load_pw', 'grid_pw', 'total_pv_pw'],
  FOX_ESS: ['inverter_pw', 'pv_pw', 'bt_pw', 'load_pw', 'grid_pw', 'total_pv_pw'],
  FOX_ESS_H3: [
    'inverter_pw',
    'inverter_pw_r',
    'inverter_pw_s',
    'inverter_pw_t',
    'pv_pw',
    'bt_pw',
    'load_pw',
    'load_pw_r',
    'load_pw_s',
    'load_pw_t',
    'grid_pw',
    'total_pv_pw',
  ],
  AC_SYS: ['inverter_pw', 'accb_power_total', 'bt_pw', 'load_pw', 'grid_pw', 'total_pv_pw'],
  AC_COMB: ['accb_power_total', 'load_pw', 'grid_pw'],
  AC_ESS: ['inverter_pw', 'bt_pw', 'load_pw', 'grid_pw', 'total_pv_pw'],
  AC_MULTI_ESS: ['inverter_pw', 'bt_pw', 'load_pw', 'grid_pw', 'total_pv_pw'],
};

const commonEnergyMinuteKey: EnergyMinuteKey[] = [
  'grid_fdin_pwh_day',
  'grid_pur_pwh_day',
  'load_pwh_day',
  'pcs_fd_pwh_day',
  'pcs_pch_pwh_day',
  'bt_chrg_pwh_day',
  'bt_dchrg_pwh_day',
];
export const energyMinuteKey: {
  [K in GenType | ACDeviceTypeValue | ACDeviceTypeWithRoleName]?: EnergyMinuteKey[];
} = {
  GEN2: [...commonEnergyMinuteKey, 'pv_pwh_day'],
  GEN3: [...commonEnergyMinuteKey, 'pv_pwh_day', 'total_pv_pwh_day'],
  FOX_ESS: [...commonEnergyMinuteKey, 'pv_pwh_day', 'total_pv_pwh_day'],
  FOX_ESS_H3: [...commonEnergyMinuteKey, 'pv_pwh_day', 'total_pv_pwh_day'],
  AC_SYS: [...commonEnergyMinuteKey, 'accb_power_accum', 'total_pv_pwh_day'],
  AC_COMB: ['grid_fdin_pwh_day', 'grid_pur_pwh_day', 'load_pwh_day', 'accb_power_accum'],
  AC_COMB_installer: ['load_pwh_day', 'accb_power_accum'],
  AC_ESS: [...commonEnergyMinuteKey, 'total_pv_pwh_day'],
  AC_MULTI_ESS: [...commonEnergyMinuteKey, 'total_pv_pwh_day'],
};

const commonEnergyHourKey: EnergyHourKey[] = [
  'grid_ob_wh',
  'pcs_tr_wh',
  'pcs_cons_wh',
  'bt_dchrg_wh',
  'load_wh',
  'grid_tr_wh',
  'aggr_dt',
  'bt_chrg_wh',
];
export const energyHourKey: { [K in GenType | ACDeviceTypeValue | ACDeviceTypeWithRoleName]?: EnergyHourKey[] } = {
  GEN2: [...commonEnergyHourKey, 'pv_wh'],
  GEN3: [...commonEnergyHourKey, 'pv_wh', 'total_pv_wh'],
  FOX_ESS: [...commonEnergyHourKey, 'pv_wh', 'total_pv_wh'],
  FOX_ESS_H3: [...commonEnergyHourKey, 'pv_wh', 'total_pv_wh'],
  AC_SYS: [...commonEnergyHourKey, 'accb_power_wh', 'total_pv_wh'],
  AC_COMB: ['grid_ob_wh', 'load_wh', 'accb_power_wh', 'grid_tr_wh', 'aggr_dt'],
  AC_COMB_installer: ['load_wh', 'accb_power_wh'],
  AC_ESS: [...commonEnergyHourKey, 'total_pv_wh'],
  AC_MULTI_ESS: [...commonEnergyHourKey, 'total_pv_wh'],
};
export const ACCombinerKey: ACCombinerKeyType[] = [
  'accb_voltage_single',
  'accb_current_single',
  'accb_frequency_single',
];

export const inverterKey: { [K in GenType]?: InverterKey[] } = {
  GEN2: ['inverter_v', 'inverter_i', 'inverter_freq', 'dc_link_v', 'bt_v'],
  GEN3: ['inverter_v', 'inverter_i', 'inverter_freq', 'dc_link_v', 'bt_v'],
  FOX_ESS: ['inverter_v', 'inverter_i', 'inverter_freq', 'dc_link_v', 'bat_voltage_total'],
  FOX_ESS_H3: [
    'inverter_v_r',
    'inverter_v_s',
    'inverter_v_t',
    'inverter_i_r',
    'inverter_i_s',
    'inverter_i_t',
    'inverter_freq_r',
    'inverter_freq_s',
    'inverter_freq_t',
    'dc_link_v',
    'bat_voltage_total',
  ],
  AC_SYS: ['inverter_v', 'inverter_i', 'inverter_freq'],
};

const commonPvKey: PvKey[] = ['pv_v1', 'pv_v2', 'pv_i1', 'pv_i2'];
export const pvKey: { [K in GenType]?: PvKey[] } = {
  GEN2: commonPvKey,
  GEN3: commonPvKey,
  FOX_ESS: [...commonPvKey, 'pv_v3', 'pv_i3', 'pv_pw1', 'pv_pw2', 'pv_pw3'],
  FOX_ESS_H3: [...commonPvKey, 'pv_v3', 'pv_i3', 'pv_pw1', 'pv_pw2', 'pv_pw3'],
};

const commonMeterKey: MeterKey[] = [
  'meter_v',
  'meter_i',
  'meter_freq',
  'meter_pwfactor',
  'meter_active_pw',
  'meter_reactive_pw',
];
export const meterKey: { [K in GenType | ACDeviceTypeValue | ACDeviceTypeWithRoleName]?: MeterKey[] } = {
  GEN2: commonMeterKey,
  GEN3: commonMeterKey,
  FOX_ESS: commonMeterKey,
  FOX_ESS_H3: [
    'meter_v_r',
    'meter_v_s',
    'meter_v_t',
    'meter_i_r',
    'meter_i_s',
    'meter_i_t',
    'meter_freq_r',
    'meter_freq_s',
    'meter_freq_t',
    'meter_pwfactor',
    'meter_pwfactor_r',
    'meter_pwfactor_s',
    'meter_pwfactor_t',
    'meter_active_pw',
    'meter_active_pw_r',
    'meter_active_pw_s',
    'meter_active_pw_t',
    'meter_reactive_pw',
    'meter_reactive_pw_r',
    'meter_reactive_pw_s',
    'meter_reactive_pw_t',
  ],
  AC_SYS: commonMeterKey,
  AC_COMB_installer: ['meter_v', 'meter_i', 'meter_freq'],
};

const commonBatteryKey: BatteryKey[] = [
  'cell_avg_t1',
  'cell_avg_v1',
  'cell_min_t1',
  'cell_max_t1',
  'cell_min_v1',
  'cell_max_v1',
];
const commonBatteryKeyH3: BatteryKey[] = [
  'cell_avg_t2',
  'cell_avg_t3',
  'cell_avg_v2',
  'cell_avg_v3',
  'soc1',
  'soc2',
  'soc3',
  'soh1',
  'soh2',
  'soh3',
  'rack_v1',
  'rack_v2',
  'rack_v3',
  'rack_i1',
  'rack_i2',
  'rack_i3',
  'cell_min_t2',
  'cell_min_t3',
  'cell_max_t2',
  'cell_max_t3',
  'cell_min_v2',
  'cell_min_v3',
  'cell_max_v2',
  'cell_max_v3',
];
export const batteryKey: { [K in GenType]?: BatteryKey[] } = {
  GEN2: ['user_soc_avg', ...commonBatteryKey],
  GEN3: ['user_soc_avg', ...commonBatteryKey, ...commonBatteryKeyH3],
  FOX_ESS: ['user_soc_avg', 'soh1', 'rack_v1', 'rack_i1', 'cell_min_t1', 'cell_max_t1', 'cell_min_v1', 'cell_max_v1'],
  FOX_ESS_H3: [
    'user_soc_avg',
    'soh1',
    'rack_v1',
    'rack_i1',
    'cell_min_t1',
    'cell_max_t1',
    'cell_min_v1',
    'cell_max_v1',
  ],
  AC_SYS: [
    'user_soc_avg',
    'rack_user_soc1',
    'rack_user_soc2',
    'rack_user_soc3',
    'real_soc_avg',
    ...commonBatteryKey,
    ...commonBatteryKeyH3,
  ],
};

export const filterPowerKeysByAcCoupled = (genType: GenType, isACCoupled = false) => {
  if (genType === GEN_TYPE.GEN3 && isACCoupled) {
    return powerKey[genType]?.filter((key) => key !== 'pv_pw');
  }

  return powerKey[genType];
};

export const filterEnergyMinuteKeysByAcCoupled = (genType: GenType, isACCoupled = false) => {
  if (genType === GEN_TYPE.GEN3 && isACCoupled) {
    return energyMinuteKey[genType]?.filter((key) => key !== 'pv_pwh_day');
  }

  return energyMinuteKey[genType];
};

export const filterEnergyHourKeysByAcCoupled = (genType: GenType, isACCoupled = false) => {
  if (genType === GEN_TYPE.GEN3 && isACCoupled) {
    return energyHourKey[genType]?.filter((key) => key !== 'pv_wh');
  }

  return energyHourKey[genType];
};

const getACDeviceTypeFilterKeyWithRoleName = (
  roleName: RoleNameValue,
  ACDeviceType: ACDeviceTypeValue
): ACDeviceTypeValue | ACDeviceTypeWithRoleName => {
  if (roleName === ROLE_NAME.INSTALLER) {
    return `${ACDeviceType}_${roleName}`;
  }

  return ACDeviceType;
};

const getMeterFilterKey = (roleName: RoleNameValue, genType: GenType, ACDeviceType: ACDeviceTypeValue) => {
  return roleName === ROLE_NAME.INSTALLER && ACDeviceType === AC_DEVICE_TYPE.AC_COMBINER
    ? getACDeviceTypeFilterKeyWithRoleName(roleName, ACDeviceType)
    : genType;
};

export const getMinuteKeysByGenType = ({
  genType,
  roleName,
  ACDeviceType = AC_DEVICE_TYPE.AC_COMBINER,
  isACCoupled = false,
}: GraphKeysParameters) => {
  const validACSystemCase: ACDeviceTypeValue[] = [
    AC_DEVICE_TYPE.AC_COMBINER,
    AC_DEVICE_TYPE.AC_ESS,
    AC_DEVICE_TYPE.AC_MULTI_ESS,
  ];
  const isValidACSystemCase = GEN_TYPE.AC_SYS && validACSystemCase.includes(ACDeviceType);

  return {
    power: (isValidACSystemCase ? powerKey[ACDeviceType] : filterPowerKeysByAcCoupled(genType, isACCoupled)) ?? [],
    energy:
      (isValidACSystemCase
        ? energyMinuteKey[getACDeviceTypeFilterKeyWithRoleName(roleName, ACDeviceType)]
        : filterEnergyMinuteKeysByAcCoupled(genType, isACCoupled)) ?? [],
    ACCombiner: ACCombinerKey,
    pv: pvKey[genType] ?? [],
    inverter: inverterKey[genType] ?? [],
    meter: meterKey[getMeterFilterKey(roleName, genType, ACDeviceType)] ?? [],
    battery: batteryKey[genType] ?? [],
  };
};

export const getHourKeysByGenType = ({
  genType,
  roleName,
  ACDeviceType = AC_DEVICE_TYPE.AC_COMBINER,
  isACCoupled = false,
}: GraphKeysParameters) => {
  const validACSystemCase: ACDeviceTypeValue[] = [
    AC_DEVICE_TYPE.AC_COMBINER,
    AC_DEVICE_TYPE.AC_ESS,
    AC_DEVICE_TYPE.AC_MULTI_ESS,
  ];
  const isValidACSystemCase = validACSystemCase.includes(ACDeviceType);

  return {
    energy:
      (genType === GEN_TYPE.AC_SYS && isValidACSystemCase
        ? energyHourKey[getACDeviceTypeFilterKeyWithRoleName(roleName, ACDeviceType)]
        : filterEnergyHourKeysByAcCoupled(genType, isACCoupled)) ?? [],
  };
};

export const getDefaultMinuteKeys = () => {
  return {
    power: [],
    energy: [],
    ACCombiner: [],
    pv: [],
    inverter: [],
    meter: [],
    battery: [],
  };
};
