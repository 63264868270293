import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["slider-container", _ctx.state.tooltipEnable])
  }, [
    _createVNode(_component_Slider, _mergeProps({
      modelValue: _ctx.state.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.value) = $event))
    }, _ctx.$attrs), null, 16, ["modelValue"])
  ], 2))
}