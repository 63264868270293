
import type { PropType } from 'vue';
import { defineComponent, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { Selector, MultiSelector, LabelInput, Searchbox, HiddenSearchbox } from '@hems/component';
import { CommonService } from '@hems/service';
import { Helper, useLocale } from '@hems/util';

import type { CodeName, SelectorOption, LabelSelectorOption, Role } from 'hems';

import type { MonitoringListSearchOptions } from 'hems/device';

export default defineComponent({
  name: 'MonitoringSearchBox',
  components: {
    LabelInput,
    Selector,
    MultiSelector,
    Searchbox,
    HiddenSearchbox,
  },
  props: {
    condition: {
      type: Object as PropType<Partial<MonitoringListSearchOptions>>,
      required: true,
    },
    isFcas: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String as PropType<Role>,
      required: true,
    },
    isSapn: {
      type: Boolean,
      default: false,
    },
    monitoringListTabName: {
      type: String,
      required: true,
    },
  },
  emits: ['search'],
  async setup(props, { emit }) {
    const { t } = useI18n();
    const { isAU, isUS } = useLocale();
    const commService = new CommonService(window.axiosInstance.axios);
    let tabName = computed(() => props.monitoringListTabName);
    const isSearchBoxShow = computed(() => (isAU || isUS) && tabName.value === 'site');

    const state = reactive({
      hide: true,
      condition: { ...props.condition },
      productModelNms: props.condition.productModelNm ? props.condition.productModelNm.split(',') : [],
      placeHolder: 'If not selected, search All',
    });

    const { PRODUCT_MODEL_CD } = await commService.getCodesByGroupCode([
      {
        grpCd: 'PRODUCT_MODEL_CD',
        exclude: [
          'ELSR362-00002',
          'ELSR362-00003',
          'ELSR362-00004',
          'ELSR362-00005',
          'ELSR722-00004',
          'ELSR103-00001',
          'ELSR552-00001',
          'ELSR362-00001',
          'ELSR802-00002',
          'ELSR552-00008',
          'ELSR362-00008',
          'ELSR802-00008',
          'ELSR552-00005',
          'ELSR362-00006',
          'ELSR552-00006',
        ],
      },
    ]);

    function convertOptions(codeNames: CodeName[]): SelectorOption[] {
      const selectAll: SelectorOption = { text: t('common.all'), value: '' };

      return Helper.addSelectorOptionAtFirst(Helper.codeNamesToSelectorOptions(codeNames, t), selectAll);
    }

    function convertLabelOptions(codeNames: CodeName[]): LabelSelectorOption[] {
      return Helper.codeNamesToLabelSelectorOptions(codeNames, t);
    }

    function onSearch() {
      state.condition.searchFor = state.condition.searchFor?.trim();
      state.condition.productModelNm = state.productModelNms.join(',');
      const { searchFor, productModelNm, emsVer, pcsVer, directMarketingTerms, offGrid, autoFwUpdateFlag, sapnFlag } =
        state.condition;
      emit('search', {
        searchFor,
        productModelNm,
        emsVer,
        pcsVer,
        directMarketingTerms,
        offGrid,
        autoFwUpdateFlag,
        sapnFlag,
      });
    }

    return {
      state,
      onSearch,
      productModelList: computed(() => convertLabelOptions(PRODUCT_MODEL_CD || [])),
      directMarketingList: convertOptions([
        { code: 'Y', name: 'Y' },
        { code: 'N', name: 'N' },
      ]),
      gridStatusList: convertOptions([
        { code: 'Y', name: 'Y' },
        { code: 'N', name: 'N' },
      ]),
      autoFwUpdateList: convertOptions([
        { code: '1', name: 'Enable' },
        { code: '0', name: 'Disable' },
      ]),
      sapnFlagList: convertOptions([
        { code: 'SAPN', name: 'Success' },
        { code: 'FAIL', name: 'Fail' },
        { code: 'NO', name: 'Not Target' },
      ]),
      placeholder: computed(() =>
        !['user', 'installer'].includes(props.role)
          ? props.isFcas
            ? t('message.enter_search_word').concat(
                ` (${[
                  t('device.serial_no'),
                  t('common.site_id'),
                  t('account.owner_name'),
                  t('account.installer_id'),
                  `NMI ${t('common.number')}`,
                ].join(', ')})`
              )
            : t('message.enter_search_word').concat(
                ` (${[
                  t('device.serial_no'),
                  t('common.site_id'),
                  t('account.owner_name'),
                  t('account.installer_id'),
                ].join(', ')})`
              )
          : t('message.enter_search_word').concat(
              ` (${[t('device.serial_no'), t('common.site_id'), t('account.owner_name')].join(', ')})`
            )
      ),
      tabName,
      isSearchBoxShow,
    };
  },
});
