import { LangCd } from 'hems';

type GoogleMapApiOptions = {
  apiKey: string;
};
export type PositionInfo = {
  lat: number;
  lng: number;
  address: string;
  cntryCd: string;
  cntryNm: string;
};
export type TimezoneInfo = {
  dstOffset: number;
  rawOffset: number;
  status: string;
  timeZoneId: string;
  timeZoneName: string;
};

export type StatePostalInfo = {
  state: string;
  postalCode: string;
};
export class GoogleMapApi {
  private apiKey;
  private _lat?: number;
  private _lng?: number;
  private _address = '';
  private _countryCode = '';
  private _countryName = '';
  constructor(options: GoogleMapApiOptions) {
    const { apiKey } = options;
    this.apiKey = apiKey;
  }

  get lat(): number | undefined {
    return this._lat;
  }
  get lng(): number | undefined {
    return this._lng;
  }
  get address(): string {
    return this._address;
  }
  get countryCode(): string {
    return this._countryCode;
  }
  get countryName(): string {
    return this._countryName;
  }

  getLocatioinInfo(lat: number, lng: number, language: LangCd = 'en'): Promise<PositionInfo> {
    return new Promise<PositionInfo>((resolve, reject) => {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?language=${language}&latlng=${lat},${lng}&key=${this.apiKey}`
      )
        .then((response) => {
          response
            .json()
            .then((addressInfo) => {
              for (let i = 0; i < addressInfo.results.length; i++) {
                const address_component = addressInfo.results[i].address_components.find((item: any) =>
                  item.types.includes('country')
                );
                if (!address_component) continue;
                resolve({
                  lat,
                  lng,
                  address: addressInfo.results[i].formatted_address as string,
                  cntryCd: address_component?.short_name,
                  cntryNm: address_component?.long_name,
                });
                return;
              }
            })
            .catch((e) => {
              console.error(e);
              return reject();
            });
        })
        .catch((e) => {
          console.error(e);
          return reject();
        });
    });
  }

  getCurrentLocation(): Promise<{ lat: number; lng: number }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (e: GeolocationPositionError) => {
            reject(e);
          }
        );
      }
    });
  }

  getTimezone(lat: number, lng: number): Promise<TimezoneInfo> {
    return new Promise<TimezoneInfo>((resolve, reject) => {
      fetch(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(
          +new Date() / 1000
        )}&key=${this.apiKey}`
      )
        .then((response) => {
          response
            .json()
            .then((timezoneInfo: TimezoneInfo) => {
              resolve(timezoneInfo);
            })
            .catch(() => reject());
        })
        .catch(() => reject());
    });
  }

  getStatePostalInfo(address: string): Promise<StatePostalInfo> {
    return new Promise<StatePostalInfo>((resolve, reject) => {
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?language=en&address=${address}&key=${this.apiKey}`)
        .then((response) => {
          response
            .json()
            .then((addressInfo) => {
              const state = addressInfo.results[0].address_components.find((item: any) =>
                item.types.includes('administrative_area_level_1')
              );
              const postalCode = addressInfo.results[0].address_components.find((item: any) =>
                item.types.includes('postal_code')
              );
              resolve({
                state: state?.short_name,
                postalCode: postalCode?.short_name,
              });
            })
            .catch((e) => {
              console.error(e);
              return reject(e);
            });
        })
        .catch((e) => {
          console.error(e);
          return reject(e);
        });
    });
  }
}
