import type { LocationQueryValue } from 'vue-router';

import type { RowKey } from 'tui-grid';

import { SAPN_TYPE, FORM_TYPE, GROUP_MODE } from '@hems/util/src/constant/constant';
import type { ACDeviceTypeValue } from '@hems/util/src/constant/device';
import { AC_DEVICE_TYPE, GEN_TYPE } from '@hems/util/src/constant/device';
import type { GraphGroupCodeValue } from '@hems/util/src/constant/graph';
import { GRAPH_GROUP_CODE } from '@hems/util/src/constant/graph';
import { isEmptyString } from '@hems/util/src/helper/helper';

import type { FormMode, Period, YesOrNo } from 'hems';

import type { GenType, SAPNFlagType, YesOrNoType } from 'hems/device';
import type { DeviceProfileACSystem } from 'hems/device/dashboard/smartmodule/acsys';
import type { PvStringPowerIndexType } from 'hems/device/settings';
import type { Device, DeviceAC, DeviceProfileAllGenType } from 'hems/device/siteinfo';
import type { GroupMode } from 'hems/group/types';

type RouteValueType = LocationQueryValue | LocationQueryValue[] | string | string[] | number;

export function isStringType(value: RouteValueType): value is string {
  return typeof value === 'string';
}

export function isSAPNType(value: RouteValueType): value is SAPNFlagType {
  return value === SAPN_TYPE.SAPN || value === SAPN_TYPE.FAIL || value === SAPN_TYPE.NO;
}

export function isYesOrNoType(value: RouteValueType): value is YesOrNoType {
  return value === 'Y' || value === 'N';
}

export function isFormModeType(value: RouteValueType): value is FormMode {
  return value === FORM_TYPE.READ || value === FORM_TYPE.EDIT || value === FORM_TYPE.NEW;
}

export function isGroupModeType(value: RouteValueType): value is GroupMode {
  return value === GROUP_MODE.READ || value === GROUP_MODE.NEW_COPY || value === GROUP_MODE.NEW;
}
export function isGenType(value: RouteValueType): value is GenType {
  return (
    value === GEN_TYPE.GEN2 ||
    value === GEN_TYPE.GEN3 ||
    value === GEN_TYPE.FOX_ESS ||
    value === GEN_TYPE.FOX_ESS_H3 ||
    value === GEN_TYPE.AC_SYS
  );
}

export const getRouteInfo = <T extends RouteValueType>(
  value: RouteValueType,
  isTypeGuardValue: (value: RouteValueType) => value is T
): T | undefined => {
  if (isTypeGuardValue(value)) return value;

  return;
};

export const isPeriod = (date: Date | Period): date is Period => {
  return 'start' in date && 'end' in date;
};

export const isNumberType = (value: RouteValueType): value is number => {
  return typeof value === 'number';
};

export const getStringValue = (value?: string | null) => {
  if (value === undefined || value === null || isEmptyString(value.trim())) {
    return null;
  }

  return value;
};

export const getYesOrNoValue = (value: string): YesOrNo => {
  return isYesOrNoType(value) ? value : 'Y';
};

export const isDeviceACType = (device: Device): device is DeviceAC => {
  return device.genType === GEN_TYPE.AC_SYS;
};
export const isACSystemType = (deviceInfo: DeviceProfileAllGenType): deviceInfo is DeviceProfileACSystem => {
  return deviceInfo.gen_type === GEN_TYPE.AC_SYS;
};

export const getNumberValue = (value?: number | null) => {
  if (value === undefined || value === null) {
    return null;
  }

  return value;
};

export const isACDeviceType = (value: string): value is ACDeviceTypeValue => {
  const ACDeviceTypeValueList: string[] = Object.values(AC_DEVICE_TYPE);

  return ACDeviceTypeValueList.includes(value);
};

export const getACDeviceTypeValue = (value?: string | null): ACDeviceTypeValue | null => {
  if (value === undefined || value === null) {
    return null;
  }

  if (isACDeviceType(value)) {
    return value;
  }

  return null;
};

export const isGraphGroupCodeType = (value: string): value is GraphGroupCodeValue => {
  const graphGroupCodeList: string[] = Object.values(GRAPH_GROUP_CODE);

  return graphGroupCodeList.includes(value);
};

export const getGraphGroupCodeTypeValue = (value: string): GraphGroupCodeValue | null => {
  if (isGraphGroupCodeType(value)) {
    return value;
  }

  return null;
};

export const isGraphGroupCodeListType = (value: string[]): value is GraphGroupCodeValue[] => {
  const graphGroupCodeList: string[] = Object.values(GRAPH_GROUP_CODE);

  return value.every((item) => graphGroupCodeList.includes(item));
};

export const getGraphGroupCodeListTypeValue = (value: string[]): GraphGroupCodeValue[] | null => {
  if (isGraphGroupCodeListType(value)) {
    return value;
  }

  return null;
};

export const isPvStringPowerValue = (value: number): value is PvStringPowerIndexType => value >= 1 && value <= 5;

export const isRowKeyType = (rowKey: unknown): rowKey is RowKey =>
  typeof rowKey === 'number' || typeof rowKey === 'string';
