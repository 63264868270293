import { CellRendererProps } from 'tui-grid/types/renderer';
import { DateHelper } from '@hems/util';
import { UTC_TIME_ZONE } from '@hems/util/src/constant';
import { isNull } from '@hems/util/src/helper/helper';

class UTCTooltipRenderer {
  private el: HTMLElement;
  private text: JQuery<HTMLElement>;
  private tooltipText: JQuery<HTMLElement>;

  constructor(props: CellRendererProps) {
    const el = $('<div></div>');
    const tooltipContainer = $('<div class="utc_tooltip"></div>');
    this.text = $('<div></div>');
    this.tooltipText = $('<span class="tooltip-text"></span>');

    tooltipContainer.append(this.text).append(this.tooltipText);

    el.append(tooltipContainer);

    this.el = el.get(0);
    this.render(props);
  }

  getElement(): HTMLElement {
    return this.el;
  }

  async render(props: CellRendererProps): Promise<void> {
    if (isNull(props.value)) return;
    const options = props.columnInfo.renderer.options;
    const tooltipColumnName = options ? options.tooltipColumnName : '';
    const position = options ? options.position : [];
    const isSecond = options?.isSecond ?? true;
    const tooltip_text = props.grid.getValue(props.rowKey, tooltipColumnName);

    this.text.text(`${props.value}`);
    position.forEach((pos: string) => {
      this.tooltipText.addClass(pos);
    });
    this.tooltipText.text(
      DateHelper.getTimezoneDate(tooltip_text as string, { isTime: true, isSecond, timezone: UTC_TIME_ZONE })
    );
  }
}

export default UTCTooltipRenderer;
