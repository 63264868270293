
import type { PropType } from 'vue';
import { defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

import _ from 'lodash';
import { Form } from 'vee-validate';
import * as yup from 'yup';

import {
  Accordion,
  BasicInput,
  ToggleInput,
  RadioGroup,
  SettingTable,
  SettingTableRow,
  BaseSlider,
  ChangedMark,
} from '@hems/component';
import { useMessageBox } from '@hems/component';
import { getSettingValue } from '@hems/container/src/forms/device/settings/_shared/advanced/advancedSettingsUtils';
import SettingConfirmPopup from '@hems/container/src/forms/device/settings/_shared/SettingConfirmPopup.vue';
import { getBasicSettingGen2Config } from '@hems/container/src/forms/device/settings/pvmodule/gen2/basic/config';
import { Helper } from '@hems/util';

import type { SelectorOption } from 'hems';

import type { SettingGroup } from 'hems/device/settings';
import type { BasicSettingsKey, BasicSettings } from 'hems/device/settings/pvmodule/gen2';

const initValue: Partial<BasicSettings> = {
  pem_mode: 0,
  basicmode_cd: '1',
};

export default defineComponent({
  name: 'BasicSettingsPvModuleGen2Container',
  components: {
    Accordion,
    BasicInput,
    ToggleInput,
    RadioGroup,
    Form,
    SettingTable,
    SettingTableRow,
    BaseSlider,
    ChangedMark,
    SettingConfirmPopup,
  },
  props: {
    data: {
      type: Object as PropType<Partial<BasicSettings>>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    isConnection: Boolean,
    countryCd: {
      type: String,
      required: true,
    },
    isGrp: {
      type: Boolean,
      default: false,
    },
    changedValueSet: {
      type: Object as PropType<Set<string>>,
      default: () => new Set<string>(),
    },
  },
  emits: ['goToList', 'save', 'cancel', 'edit'],
  setup(props, { emit }) {
    const messageBox = useMessageBox();
    const { t } = useI18n();
    let copyData: Partial<BasicSettings> = _.cloneDeep(props.data);
    let copyChangedValueSet: Set<string> = _.cloneDeep(props.changedValueSet);

    const state = reactive<{
      editable: boolean;
      data: Partial<BasicSettings>;
      changedValueSet: Set<string>;
      confirmPopup: { on: boolean; data: SettingGroup[] };
    }>({
      editable: props.editable,
      data: !props.isGrp ? { ...initValue, ...props.data } : { ...props.data },
      changedValueSet: _.cloneDeep(props.changedValueSet),
      confirmPopup: { on: false, data: [] },
    });

    const valueRange = {
      pv_max_pwr1: { min: 1000, max: 3300 },
      pv_max_pwr2: { min: 1000, max: 3300 },
      feed_in_limit: { min: 0, max: 100 },
      backup_soc: { min: 0, max: 100 },
      max_inverter_pw_cd: { min: 3600, max: ['AU', 'NZ'].includes(props.countryCd) ? 5000 : 4600 },
    };

    const defaultValidate = yup
      .number()
      .transform((v, o) => (o === '' ? null : v))
      .nullable();

    const schema = yup.object().shape({
      pv_max_pwr1: defaultValidate.test('pv_max_pwr1', { key: 'message.invalid' }, function (value?: number | null) {
        try {
          const { createError } = this;
          if (Helper.isNull(value) || value === 0) {
            return true;
          }
          if (value < valueRange.pv_max_pwr1.min) {
            return createError({
              message: { key: 'message.greater_min', values: { min: valueRange.pv_max_pwr1.min } },
            });
          }
          if (value > valueRange.pv_max_pwr1.max) {
            return createError({
              message: { key: 'message.greater_max', values: { max: valueRange.pv_max_pwr1.max } },
            });
          }

          return true;
        } catch (error) {
          return false;
        }
      }),
      pv_max_pwr2: defaultValidate.test('pv_max_pwr2', { key: 'message.invalid' }, function (value?: number | null) {
        try {
          const { createError } = this;
          if (Helper.isNull(value) || value === 0) {
            return true;
          }
          if (value < valueRange.pv_max_pwr2.min) {
            return createError({
              message: { key: 'message.greater_min', values: { min: valueRange.pv_max_pwr2.min } },
            });
          }
          if (value > valueRange.pv_max_pwr2.max) {
            return createError({
              message: { key: 'message.greater_max', values: { max: valueRange.pv_max_pwr2.max } },
            });
          }

          return true;
        } catch (error) {
          return false;
        }
      }),
      feed_in_limit: defaultValidate.integer().min(valueRange.feed_in_limit.min).max(valueRange.feed_in_limit.max),
      pem_mode: defaultValidate,
      max_inverter_pw_cd: defaultValidate.min(valueRange.max_inverter_pw_cd.min).max(valueRange.max_inverter_pw_cd.max),
      basicmode_cd: yup.string().nullable(),
      smeterd0id: defaultValidate,
    });

    function getParam(): Partial<BasicSettings> {
      const {
        pv_max_pwr1,
        pv_max_pwr2,
        feed_in_limit,
        pem_mode,
        max_inverter_pw_cd,
        smeterd0id,
        basicmode_cd,
        backup_soc,
      } = state.data;
      const param: Partial<BasicSettings> = {
        pv_max_pwr1,
        pv_max_pwr2,
        feed_in_limit,
        pem_mode,
        max_inverter_pw_cd,
        smeterd0id,
        basicmode_cd,
        backup_soc,
      };

      const cleanedParam = _.pickBy(param, (v) => v !== undefined);

      return cleanedParam;
    }

    function onSave() {
      let confirmItems = getParam();
      let params = getBasicSettingGen2Config(t)
        .map((config) => {
          return {
            ...config,
            children: config.children
              .map((item) => {
                let value: string | number | null = null;
                value = getSettingValue<BasicSettingsKey>(item, confirmItems);

                return {
                  ...item,
                  value: value,
                };
              })
              .filter((item) => !Helper.isNull(item.value)),
          };
        })
        .filter((config) => config.children.length > 0);

      state.confirmPopup = { on: true, data: params };
    }

    function onConfirm() {
      state.confirmPopup.on = false;
      if (props.isGrp) {
        emit('save', getParam(), state.changedValueSet, function (isOk: boolean) {
          if (isOk) state.editable = false;
        });
      } else {
        emit('save', getParam(), function (isOk: boolean) {
          if (isOk) state.editable = false;
        });
      }
    }

    function onEdit() {
      if (!props.isConnection) {
        messageBox.alert(t('message.modify_when_disconnected')).open();

        return;
      }
      copyData = _.cloneDeep(state.data);
      state.editable = true;
      emit('edit', copyData);
    }
    function onCancel(handleReset?: () => void) {
      state.data = copyData;
      state.editable = false;
      if (handleReset) handleReset();
      emit('cancel');
    }
    function goToList() {
      emit('goToList');
    }

    function onChange(valueKey: string, targetValue?: string | number | boolean) {
      if (state.editable) {
        if (!Helper.isNull(targetValue)) {
          // 변경 항목 key set에 저장
          state.changedValueSet.add(valueKey);
        } else {
          // 변경 항목 key set에서 제거
          state.changedValueSet.delete(valueKey);
        }
      } else {
        // cancel 버튼 클릭 시
        state.changedValueSet = copyChangedValueSet;
      }
    }

    return {
      state,
      schema,
      valueRange,
      onSave,
      onEdit,
      onCancel,
      goToList,
      onChange,
      onConfirm,
      titleWidth: '40%',
    };
  },
});
