/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited. 
*/
import { twoDaysAgo, getDateWithAdjustedTime, now } from '@hems/util/src/helper/dateHelper';

import type { CommonCode } from 'hems';

import type { ValueType } from 'hems/common/utils';

export const defaultStartDateTimeOptions = { hour: 0, minute: 0, second: 0, millisecond: 0 };
export const defaultEndDateTimeOptions = { hour: 23, minute: 59, second: 59, millisecond: 999 };

export const DEFAULT_PERIOD = {
  start: getDateWithAdjustedTime(twoDaysAgo(), defaultStartDateTimeOptions),
  end: getDateWithAdjustedTime(now(), defaultEndDateTimeOptions),
};

export const ERROR_HISTORY_GRID_TYPE = {
  ALL: 'all',
  SITE: 'site',
} as const;

export type ErrorHistoryGridTypeValue = ValueType<typeof ERROR_HISTORY_GRID_TYPE>;

export const ERROR_LEVEL = {
  FAULT: 'F',
  WARNING: 'W',
  ALARM: 'A',
} as const;

export type ErrorLevelValue = ValueType<typeof ERROR_LEVEL>;

export const ERROR_LEVEL_DISPLAY_TEXT = {
  FAULT: 'Fault',
  WARNING: 'Warning',
  ALARM: 'Alarm',
} as const;

export type ErrorLevelDisplayTextValue = ValueType<typeof ERROR_LEVEL_DISPLAY_TEXT>;

export const ERROR_LEVEL_SELECTOR_OPTIONS: CommonCode.CodeMap[] = [
  { code: ERROR_LEVEL_DISPLAY_TEXT.FAULT.toLocaleLowerCase(), name: ERROR_LEVEL_DISPLAY_TEXT.FAULT },
  { code: ERROR_LEVEL_DISPLAY_TEXT.WARNING.toLocaleLowerCase(), name: ERROR_LEVEL_DISPLAY_TEXT.WARNING },
  { code: ERROR_LEVEL_DISPLAY_TEXT.ALARM.toLocaleLowerCase(), name: ERROR_LEVEL_DISPLAY_TEXT.ALARM },
];

export const ERROR_PREFIX = {
  GRID: 'G',
  SOLAR: 'S',
  MICRO_INVERTER: 'M',
  AC_COMBINER: 'A',
  DC_OPTIMIZER: 'O',
  /** ESS - Inverter */
  INVERTER: 'P',
  /** ESS - BDC */
  BDC: 'D',
  /** ESS - EMS 또는 G4 HUB - EMS */
  EMS: 'E',
  /** ESS - BMS */
  BMS: 'B',
  HUB: 'H',
  CLOUD: 'C',
} as const;

export type ErrorPrefixValue = ValueType<typeof ERROR_PREFIX>;

export const ERROR_STATUS_TYPE = {
  ERROR: 'E',
  WARNING: 'W',
} as const;

export type ErrorStatusTypeValue = ValueType<typeof ERROR_STATUS_TYPE>;
