import { AxiosInstance } from 'axios';
import { Auth, Issuer, CommonResponseWrapper } from 'hems';
import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';
import { Helper } from '@hems/util';

export default class AuthService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getAccessToken(params: Auth.LoginParam): Promise<Auth.TokenInfoResponse> {
    const { data } = await this.post<CommonResponseWrapper<Auth.TokenInfoResponse>>(
      apiWrapper.authenticationApi(`/login`),
      params
    );
    return data;
  }

  public async getAccessTokenBySocialToken(
    issuer: Issuer,
    issuer_token: string,
    username: string
  ): Promise<Auth.TokenInfoResponse> {
    const { data } = await this.post<CommonResponseWrapper<Auth.TokenInfoResponse>>(
      apiWrapper.authenticationApi(`/login/social`),
      { username },
      { headers: { issuer, issuer_token } }
    );
    return data;
  }

  public async findId<T>(email: T): Promise<Auth.FindIDResonse<T>> {
    const { data } = await this.get<CommonResponseWrapper<Auth.FindIDResonse<T>>>(
      apiWrapper.managerApi(`/managements/users/id`),
      { email }
    );
    return data;
  }

  public async resetPassword(params: Auth.ResetPasswordParam): Promise<Auth.ResetPasswordResponse> {
    const { email, user_id, langCd } = params;
    const { data } = await this.post<CommonResponseWrapper<Auth.ResetPasswordResponse>>(
      apiWrapper.managerApi(`/managements/users/reset/passwords?lang=${langCd}`),
      Helper.keyToCamelCase({ user_id, email })
    );
    return data;
  }

  public async checkTermsVersion(
    params: Auth.TermsVersionParam,
    accessToken: string
  ): Promise<Auth.CheckTermsVersionResponse> {
    const { data } = await this.get<CommonResponseWrapper<Auth.CheckTermsVersionResponse>>(
      apiWrapper.managerApi(`/managements/users/validations/terms`),
      params,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    return data;
  }

  public async updateTermsVersion(params: Auth.TermsVersionParam, accessToken: string): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/users/terms`),
      undefined,
      {
        params,
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    return data;
  }

  public async addRetryLoginLog(logMessage: Record<string, any>): Promise<any> {
    const { data } = await this.post<CommonResponseWrapper<any>>(`/tracking/login`, logMessage);
    return data;
  }

  public async getRealtimeApiAuthToken(): Promise<{ data: string; status: string | number }> {
    return await this.get(apiWrapper.daqApi('/v1/auth'));
  }
}
