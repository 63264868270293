
import { defineComponent, reactive, PropType } from 'vue';
import Image2 from '../../image/Image2.vue';
import { NotificationCardIconInfo, NotificationCardNotiInfo } from 'hems/notification';
export default defineComponent({
  name: 'NotificationItem',
  components: {
    Image2,
  },
  props: {
    iconInfo: {
      type: Object as PropType<NotificationCardIconInfo>,
      default: () => ({ isIcon: false, iconPath: '', extension: '' }),
    },
    notificationInfo: {
      type: Object as PropType<NotificationCardNotiInfo>,
      default: () => ({ read: true, title: '', contents: '', date: '' }),
    },
  },
});
