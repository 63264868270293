import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44a29cb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "messagebox-title messagebox-container" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "messagebox-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: _normalizeClass(["messagebox-dim", { on: _ctx.messageBox.on }])
    }, [
      _createElementVNode("div", {
        class: "messagebox-box",
        style: _normalizeStyle(_ctx.messageBox.boxStyle)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", {
            class: "title",
            style: _normalizeStyle(_ctx.messageBox.titleStyle)
          }, _toDisplayString(_ctx.messageBox.title), 5),
          _createElementVNode("a", {
            href: "javascript:void(0)",
            class: "close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
          })
        ]),
        _createElementVNode("div", {
          class: "messagebox-message messagebox-container",
          style: _normalizeStyle(_ctx.messageBox.messageStyle),
          innerHTML: _ctx.messages
        }, null, 12, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            ref: "btnCancelEl",
            type: "button",
            class: "btn_xs btn_c_2",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
          }, _toDisplayString(_ctx.messageBox.type === 'alert' ? 'Close' : 'Cancel'), 513),
          (_ctx.messageBox.type === 'confirm')
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn_xs btn_c_3",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onOk && _ctx.onOk(...args)))
              }, _toDisplayString(_ctx.$t(`common.ok`)), 1))
            : _createCommentVNode("", true)
        ])
      ], 4)
    ], 2)
  ]))
}