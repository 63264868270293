import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fdb4e478"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-item-name" }
const _hoisted_2 = {
  ref: "subMenuBg",
  class: "depth-2-bg"
}
const _hoisted_3 = {
  ref: "subMenuContainer",
  class: "nav-container depth-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavSubItem = _resolveComponent("NavSubItem")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["nav-item", { on: _ctx.state.show }]),
    onMouseover: _cache[0] || (_cache[0] = () => (_ctx.state.show = true)),
    onMouseleave: _cache[1] || (_cache[1] = () => (_ctx.state.show = false)),
    onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["stop","prevent"]))
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t(`${_ctx.navItem.name}`, { defaultValue: _ctx.navItem.defaultName })), 1),
    _createElementVNode("div", _hoisted_2, null, 512),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItem.children, (navSubItem) => {
        return (_openBlock(), _createBlock(_component_NavSubItem, {
          key: navSubItem.code,
          "nav-item": navSubItem,
          onClick: _ctx.onSubClick
        }, null, 8, ["nav-item", "onClick"]))
      }), 128))
    ], 512)
  ], 34))
}