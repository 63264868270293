
import { computed, defineComponent, reactive } from 'vue';
export default defineComponent({
  name: 'GroupInfoItem',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      title: computed(() => props.title),
    });

    return {
      state,
    };
  },
});
