
import { defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { gsap } from 'gsap';

export default defineComponent({
  name: 'HiddenSearchbox',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const div = ref(null as HTMLDivElement | null);

    const state = reactive({
      show: props.show,
    });
    function toggle() {
      state.show = !state.show;
    }

    watch(
      () => state.show,
      (show) => {
        if (show) {
          if (!div.value) return;
          div.value.style.padding = '10px';
          gsap
            .to([div.value] as gsap.TweenTarget, {
              height: 'auto',
              duration: 0.15,
            })
            .then(() => {
              if (!div.value) return;
              div.value.style.overflow = 'visible';
            });
        } else {
          if (!div.value) return;
          div.value.style.overflow = 'hidden';
          gsap
            .to([div.value] as gsap.TweenTarget, {
              height: '0',
              duration: 0.15,
            })
            .then(() => {
              if (!div.value) return;
              div.value.style.padding = '0';
            });
        }
      }
    );

    onMounted(() => {
      if (!div.value) return;
      div.value.style.height = state.show ? 'auto' : '0';
      div.value.style.padding = state.show ? '10px' : '0';
    });

    return {
      state,
      div,
      toggle,
    };
  },
});
