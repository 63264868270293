/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited. 
*/
import type { GenTypeValue } from '@hems/util/src/constant';
import { GEN_TYPE } from '@hems/util/src/constant';
import type { RootStoreAdminInstaller } from 'hems/common/store';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRole } from '@hems/util';

export function useGenType() {
  const adminInstallerStore = useStore<RootStoreAdminInstaller>();
  const userStore = useStore();
  const { isUser } = useRole();
  const genType = computed<GenTypeValue | null>(() =>
    isUser ? userStore.state.device.gen_type : adminInstallerStore.state.siteNew.genType
  );
  const isACSystem = genType.value === GEN_TYPE.AC_SYS;
  const isFoxESSType = genType.value === GEN_TYPE.FOX_ESS || genType.value === GEN_TYPE.FOX_ESS_H3;

  return {
    genType,
    isACSystem,
    isFoxESSType,
  };
}
