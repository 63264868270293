import { CellRenderer, CellRendererProps } from 'tui-grid/types/renderer';
import { Helper } from '@hems/util';
interface TooltipRendererOptions {
  tooltipColumnName: string;
  position?: ('bottom' | 'left' | 'top' | 'right')[];
  format?: (tooltipText: string) => string;
}

class TooltipRenderer implements CellRenderer {
  private el: HTMLElement;
  private tooltipContainer: JQuery<HTMLElement>;
  private text: JQuery<HTMLElement>;
  private tooltipText: JQuery<HTMLElement>;

  constructor(props: CellRendererProps) {
    const el = $('<div class="tooltip-grid"></div>');
    this.tooltipContainer = $('<div class="tui-grid-tooltip"></div>');
    this.text = $('<span class="tui-grid-value"></span>');
    this.tooltipText = $('<span class="tui-grid-tooltip-text"></span>');
    this.tooltipContainer.append(this.tooltipText);
    el.append(this.text).append(this.tooltipContainer);

    this.el = el.get(0);
    this.render(props);
  }

  getElement(): HTMLElement {
    return this.el;
  }

  render(props: CellRendererProps): void {
    const value = Helper.nullTo(props.value, '') as string;
    const options = props.columnInfo.renderer.options as TooltipRendererOptions | undefined | null;

    if (!options) {
      this.setTooltip(value, value);
      return;
    }

    const tooltipColumnName = Helper.nullTo(options.tooltipColumnName, '');
    let tooltip_text = Helper.nullTo(props.grid.getValue(props.rowKey, tooltipColumnName), value) as string;
    tooltip_text = tooltip_text ? options.format?.(tooltip_text) || tooltip_text : '';
    this.setTooltip(value, tooltip_text, options.position);
  }

  mounted(parent: HTMLElement): void {
    $(parent).closest('.tui-grid-cell').css({ overflow: 'visible' });
  }

  setTooltip(value: string, tooltipText: string, pos: ('bottom' | 'left' | 'top' | 'right')[] = ['bottom']): void {
    this.text.text(value);
    if (tooltipText) {
      this.tooltipText.text(tooltipText);
      this.tooltipContainer.addClass(pos);
    } else this.tooltipContainer.remove();
  }
}

export default TooltipRenderer;
