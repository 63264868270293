
import { defineComponent } from 'vue';

import { PopupWrapper } from '@hems/component';

export default defineComponent({
  name: 'EnergyFlowTimeoutPopup',
  components: {
    PopupWrapper,
  },
  emits: ['resume'],
});
