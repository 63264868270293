
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import AdvancedSettingsGEN3 from '@hems/container/src/forms/device/settings/_shared/advanced/AdvancedSettingsGEN3.vue';
import { advancedSettingGen3Config as configMOW } from '@hems/container/src/forms/device/settings/pvmodule/gen3/advanced/configMOW';

import type { Role } from 'hems';

import type { AdvancedSettings, AdvancedSettingsKey, BasicSettings } from 'hems/device/settings/pvmodule/gen3';

const initValue: Partial<AdvancedSettings> = {
  cls_export_limit: 5000,
  cls_import_limit: 18400,
};

export default defineComponent({
  name: 'AdvancedSettingsPvModuleGen3Container',
  components: {
    AdvancedSettingsGEN3,
  },
  props: {
    data: {
      type: Object as PropType<Partial<AdvancedSettings>>,
      required: true,
    },
    productModelNm: {
      type: String,
      required: true,
    },
    cntryCd: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    showEditableColumnsOnly: {
      type: Boolean,
      default: true,
    },
    isConnection: Boolean,
    roleNm: {
      type: String as PropType<Role>,
      required: true,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    isGrp: {
      type: Boolean,
      default: false,
    },
    changedValueSet: {
      type: Object as PropType<Set<string>>,
      default: () => new Set<string>(),
    },
    groupBasicSettingInfo: {
      type: Object as PropType<Partial<BasicSettings>>,
      default: null,
    },
  },
  emits: ['goToList', 'save', 'cancel', 'edit'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const configData = { ...initValue, ...getFormattedData(props.data) };
    const config = configMOW(t);

    function getFormattedData(data: Partial<AdvancedSettings>): Partial<AdvancedSettings> {
      const result: Record<AdvancedSettingsKey | string, number | null> = {};
      Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'number') {
          result[key] = Number(value.toFixed(3));
        } else {
          result[key] = value;
        }
      });

      return result;
    }

    function goToList() {
      emit('goToList');
    }

    function onSave(data: AdvancedSettings, changedValueSet: Set<string>, callback?: (isOk: boolean) => void) {
      if (props.isGrp) {
        emit('save', data, changedValueSet);
      } else {
        emit('save', data, callback);
      }
    }
    function onCancel() {
      emit('cancel');
    }
    function onEdit() {
      emit('edit');
    }

    return {
      config,
      configData,
      goToList,
      onSave,
      onCancel,
      onEdit,
    };
  },
});
