import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36e50b01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup_message_box" }
const _hoisted_2 = {
  key: 0,
  class: "popup_image"
}
const _hoisted_3 = { class: "popup_title" }
const _hoisted_4 = {
  key: 1,
  class: "popup_message"
}
const _hoisted_5 = {
  key: 2,
  class: "popup_button_container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: _normalizeClass(["popup_container", { on: _ctx.mobilePopup.on }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.mobilePopup.type === 'alert' || _ctx.mobilePopup.type === 'warn')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.mobilePopup.imageName)
                ? (_openBlock(), _createBlock(_component_Image, {
                    key: 0,
                    src: `smartmodule/pro/${_ctx.mobilePopup.imageName}`,
                    extension: _ctx.mobilePopup.imageExtension,
                    style: _normalizeStyle(`width: ${_ctx.mobilePopup.imageSize}px; height: ${_ctx.mobilePopup.imageSize}px;`)
                  }, null, 8, ["src", "extension", "style"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.mobilePopup.title), 1),
        (_ctx.mobilePopup.type !== 'alert')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.mobilePopup.message), 1))
          : _createCommentVNode("", true),
        (_ctx.mobilePopup.type !== 'alert' && _ctx.mobilePopup.type !== 'error')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("button", {
                ref: "btnCancelEl",
                type: "button",
                class: "popup_button cancel",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
              }, _toDisplayString(_ctx.mobilePopup.cancelName), 513),
              _createElementVNode("button", {
                type: "button",
                class: "popup_button ok",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onOk && _ctx.onOk(...args)))
              }, _toDisplayString(_ctx.mobilePopup.okName), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}