import { CellRendererProps } from 'tui-grid/types/renderer';

class BatteryRenderer {
  private el: HTMLElement;
  private batteryContainer: JQuery<HTMLElement>;
  private guage: JQuery<HTMLElement>;
  private value: JQuery<HTMLElement>;

  constructor(props: CellRendererProps) {
    const el = $('<div class="fm_fcc"></div>');
    this.batteryContainer = $('<span class="state_battery"></span>');
    this.guage = $('<span class="in"></span>');
    this.value = $('<span class="state_val"></span>');

    el.append(this.batteryContainer);
    el.append(this.value);
    this.batteryContainer.append(this.guage);

    this.el = el.get(0);
    this.render(props);
  }

  getElement(): HTMLElement {
    return this.el;
  }

  async render(props: CellRendererProps): Promise<void> {
    // const options = props.columnInfo.renderer.options;
    const bt_stus_cd = props.grid.getValue(props.rowKey, 'bt_stus_cd');
    const soc = props.value as number;
    switch (bt_stus_cd) {
      case '0':
        this.batteryContainer.addClass('ty_1');
        break;
      case '1':
        this.batteryContainer.addClass('ty_2');
        break;
      case '2':
        this.batteryContainer.addClass('ty_3');
        break;
    }
    this.guage.css({ width: `${soc || 0}%` });
    this.value.text(`${Math.round((soc || 0) * 100) / 100}%`);
  }
}

export default BatteryRenderer;
