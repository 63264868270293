
import { InstallationProcessInfo } from 'hems/install';
import { defineComponent, PropType, computed } from 'vue';
import BaseCard from '../BaseCard.vue';
import Image2 from '../../image/Image2.vue';
import { INSTALLATION_STATUS } from '@hems/util/src/constant';

export default defineComponent({
  name: 'ProcessCard',
  components: {
    BaseCard,
    Image2,
  },
  props: {
    currentMainStep: {
      type: Number,
      default: 1,
    },
    stepNumber: {
      type: Number,
      default: 1,
    },
    mainStep: {
      type: Object as PropType<InstallationProcessInfo>,
      default: null,
    },
    subSteps: {
      type: Array as PropType<InstallationProcessInfo[]>,
      default: () => [],
    },
    maxMainStepNumber: {
      type: Number,
      default: 3,
    },
    replacedStep: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const isComplete = computed(() => props.mainStep?.status === INSTALLATION_STATUS.COMPLETE);
    const isInProgress = computed(() => props.stepNumber === props.currentMainStep);

    return {
      isComplete,
      isInProgress,
    };
  },
});
