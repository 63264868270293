
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { AdvancedSettings, BasicSettings } from 'hems/device/settings/smartmodule/acsys';
import AdvancedSettingsACSystem from '../../../_shared/advanced/AdvancedSettingsACSystem.vue';
import { useRole } from '@hems/util';

export default defineComponent({
  name: 'AdvancedSettingsSmartModuleACSystemContainer',
  components: {
    AdvancedSettingsACSystem,
  },
  props: {
    data: {
      type: Array as PropType<AdvancedSettings[]>,
      required: true,
    },
    cntryCd: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    showEditableColumnsOnly: {
      type: Boolean,
      default: true,
    },
    isConnection: Boolean,
    hideEdit: {
      type: Boolean,
      default: false,
    },
    isGrp: {
      type: Boolean,
      default: false,
    },
    basicSettingInfo: {
      type: Object as PropType<Partial<BasicSettings>>,
    },
  },
  emits: ['goToList', 'save', 'cancel', 'edit'],
  setup(props, { emit }) {
    const { roleName } = useRole();

    function goToList() {
      emit('goToList');
    }
    function onSave(data: AdvancedSettings, callback?: (isOk: boolean) => void) {
      emit('save', data, callback);
    }
    function onCancel() {
      emit('cancel');
    }
    function onEdit() {
      emit('edit');
    }

    return {
      goToList,
      onSave,
      onCancel,
      onEdit,
      roleName,
    };
  },
});
