import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-401d8042"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["checked"]
const _hoisted_2 = { class: "in_off" }
const _hoisted_3 = { class: "in_on" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessageLabel = _resolveComponent("ErrorMessageLabel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", {
      class: _normalizeClass(["fm_chk_2", { readonly: _ctx.readonly }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, [
      _createElementVNode("input", {
        type: "checkbox",
        hidden: "",
        checked: _ctx.isChecked
      }, null, 8, _hoisted_1),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.toggle[1][1]), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.toggle[0][1]), 1)
    ], 2),
    _createVNode(_component_ErrorMessageLabel, { "error-message": _ctx.errorMessage }, null, 8, ["error-message"])
  ], 64))
}