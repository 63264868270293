
import type { PropType } from 'vue';
import { computed, defineComponent, nextTick, reactive, onMounted, watch, ref, onBeforeUpdate } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import _ from 'lodash';
import moment from 'moment-timezone';
import { Form } from 'vee-validate';
import * as yup from 'yup';

import {
  Accordion,
  BasicInput,
  ToggleInput,
  Selector,
  SettingTable,
  SettingTableRow,
  useMessageBox,
  BaseSlider,
  RadioGroup,
  ChangedMark,
  MultiSettingTab,
} from '@hems/component';
import {
  isSettingField,
  settingTableRerender,
  getBasicSettingACSystemConfig,
  getTargetList,
  acSystemComputedRange,
} from '@hems/container/src/forms/device/settings/_shared/ACSystemConfig';
import SettingConfirmPopup from '@hems/container/src/forms/device/settings/_shared/SettingConfirmPopup.vue';
import { CommonService } from '@hems/service';
import { DateHelper, useLanguage } from '@hems/util';
import { useRole } from '@hems/util';
import { useACDeviceType } from '@hems/util';
import type { SettingTabValue } from '@hems/util/src/constant';
import { getDeviceTabListForAC, ENERGY_POLICY, SETTING_TAB_CODE } from '@hems/util/src/constant';
import { NUMBER_UNIT, CONSUMPTION_CT_TYPE } from '@hems/util/src/constant';
import { ROLE_TYPE } from '@hems/util/src/constant/passwordManagement';
import {
  isNull,
  codeNamesToSelectorOptions,
  getTemporaryPassword,
  addSelectorOptionAtFirst,
} from '@hems/util/src/helper/helper';
import { isPvStringPowerValue } from '@hems/util/src/helper/tsguardHelper';

import type { CommonCode, SelectorOption, SelectorValue } from 'hems';

import type { acSystemSettingGroup } from 'hems/device/settings';
import type { SettingGroup } from 'hems/device/settings';
import type { PvStringPowerType } from 'hems/device/settings';
import type { BasicSettingsSmartModuleACSystem } from 'hems/device/settings/smartmodule';
import type { BasicSettings } from 'hems/device/settings/smartmodule/acsys';
import type { WebHmiPasswordType } from 'hems/webHmiManagement';

export default defineComponent({
  name: 'BasicSettingsACSystem',
  components: {
    Accordion,
    BasicInput,
    ToggleInput,
    Form,
    Selector,
    SettingTable,
    SettingTableRow,
    BaseSlider,
    RadioGroup,
    ChangedMark,
    SettingConfirmPopup,
    MultiSettingTab,
  },
  props: {
    data: {
      type: Object as PropType<BasicSettings>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    isConnection: {
      type: Boolean,
      required: true,
    },
    timeZone: {
      type: String,
      required: true,
    },
    changedValueSet: {
      type: Object as PropType<Set<string>>,
      default: () => new Set<string>(),
    },
  },
  emits: ['goToList', 'save', 'generate', 'cancel', 'edit', 'changeWebHMIPassword'],
  async setup(props, { emit }) {
    const messageBox = useMessageBox();
    const { t } = useI18n();
    const commonService = new CommonService(window.axiosInstance.axios);
    let copyData: BasicSettings = _.cloneDeep(props.data);
    let copyChangedValueSet: Set<string> = _.cloneDeep(props.changedValueSet);
    const { roleName } = useRole();
    const DEFAULT_RTU_BAUDRATE = 9600;
    const DEFAULT_RTU_BAUDRATE_EM530OR540 = 115200;

    const { isCase1 } = useACDeviceType();

    const schema = ref<yup.AnySchema>(yup.object().shape({}));

    const store = useStore();
    const { languageCode } = useLanguage();

    const isInit = ref(true);

    const state = reactive<{
      editable: boolean;
      data: BasicSettings;
      enableEditFeedInLimit: boolean;
      prevFeedInLimitWatt: number;
      changedValueSet: Set<string>;
      confirmPopup: { on: boolean; data: SettingGroup[] };
      validClass: { web_page_password_engineer: boolean; web_page_password_service: boolean };
    }>({
      editable: props.editable,
      data: { ...props.data },
      enableEditFeedInLimit: props.data.energy_policy !== Number(ENERGY_POLICY.ZERO_EXPORT),
      prevFeedInLimitWatt: props.data.pv_feed_in_limit_w,
      changedValueSet: _.cloneDeep(props.changedValueSet),
      confirmPopup: { on: false, data: [] },
      validClass: {
        web_page_password_engineer: false,
        web_page_password_service: false,
      },
    });

    const settingTargetType = ref<SettingTabValue>(SETTING_TAB_CODE.SITE);

    let selectorOptionsState = reactive<{
      energyPolicy: SelectorOption[];
      pvStringCount: SelectorOption[];
    }>({
      energyPolicy: [],
      pvStringCount: [],
    });

    const fieldList = getTargetList(getBasicSettingACSystemConfig(t, selectorOptionsState, state.data));

    const { basicDeviceOption } = getDeviceTabListForAC(store.state.device.acDeviceType, store.state.site.device_info);

    const deviceTarget = ref(basicDeviceOption[0]?.value ?? '');

    const passwordSchema = yup.object().shape({
      web_page_password_engineer: yup
        .string()
        .nullable()
        .isValidPassword(
          { min: 16, max: 16 },
          function (isValid: boolean) {
            state.validClass.web_page_password_engineer = isValid;
          },
          t
        ),
      web_page_password_service: yup
        .string()
        .nullable()
        .isValidPassword(
          { min: 16, max: 16 },
          function (isValid: boolean) {
            state.validClass.web_page_password_service = isValid;
          },
          t
        ),
    });

    const totalModulePower = computed(() => {
      const { pv_module_count, pv_module_power } = state.data;

      if (pv_module_count === 0 || pv_module_power === 0) {
        return '';
      }

      const totalPower = pv_module_count * pv_module_power;

      if (isNaN(totalPower)) {
        return '';
      }

      return totalPower;
    });

    function getFormatDate(dt?: string | number | Date | undefined): string {
      let date = '-';
      if (dt === undefined || dt === 0 || dt === '0') return date;

      if (typeof dt === 'number') {
        if (String(dt).length === 10) dt *= NUMBER_UNIT.THOUSAND;
        if (!isExpireDate(dt))
          date = moment(new Date(dt)).tz(props.timeZone).format(DateHelper.getGridDateFormat('TD', languageCode.value));
      } else if (typeof dt === 'string') {
        let timestamp = Number(dt);
        if (dt.length === 10) timestamp *= NUMBER_UNIT.THOUSAND;
        if (!isExpireDate(timestamp)) {
          const tmpDate = new Date(timestamp);
          const utcDate = moment.utc(tmpDate, 'YYYYMMDDHHmmss');
          const tzDate = utcDate.clone().tz(props.timeZone);
          date = tzDate.format(DateHelper.getGridDateFormat('TD', languageCode.value));
        }
      }

      return date;
    }

    function isExpireDate(time?: string | number) {
      if (!time) return false;

      const timestamp = typeof time === 'string' ? Number(time) : time;

      return timestamp < DateHelper.getYesterday().getTime();
    }

    const getEnergyPolicySelectorValues = (energyPolicyGen3Code: CommonCode.CodeMap[]) => {
      return [
        { text: t('common.select'), value: null },
        ...codeNamesToSelectorOptions(energyPolicyGen3Code, t).reduce((acc, item) => {
          const value = Number(item.value);

          const autoValueCondition = isCase1 && value === Number(ENERGY_POLICY.SELF_CONSUMPTION);

          const zeroExportValueCondition =
            value === Number(ENERGY_POLICY.ZERO_EXPORT) &&
            state.data.accb_consumption_ct_installed_point !== CONSUMPTION_CT_TYPE.NOT_INSTALLED;

          if (autoValueCondition) {
            item.text = t('device.auto');
            acc.push(item);
          } else if (zeroExportValueCondition) {
            item.text = t('device.zero_export');
            acc.push(item);
          } else {
            acc.push(item);
          }

          return acc;
        }, [] as { text: string; value: SelectorValue }[]),
      ];
    };

    const fetchEnergyPolicyCodes = (excludeConditions: string[]) =>
      commonService.getCodesByGroupCode([{ grpCd: 'ENERGY_POLICY_GEN3_CD', exclude: excludeConditions }]);

    const getEnergyPolicySelectorOptions = async () => {
      const excludeConditions = isCase1
        ? [ENERGY_POLICY.TIME_BASED, ENERGY_POLICY.EXTERNAL_GENERATION]
        : [ENERGY_POLICY.EXTERNAL_GENERATION];

      try {
        const response = await fetchEnergyPolicyCodes(excludeConditions);

        return getEnergyPolicySelectorValues(response.ENERGY_POLICY_GEN3_CD);
      } catch (e) {
        console.log(e);

        return [];
      }
    };

    const loadSelectorOptions = async () => {
      selectorOptionsState.energyPolicy = await getEnergyPolicySelectorOptions();

      selectorOptionsState.pvStringCount = addSelectorOptionAtFirst(
        [
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
          { text: '5', value: 5 },
        ],
        { text: t('common.select'), value: null }
      );
    };

    // eslint-disable-next-line complexity
    function getParam(): Partial<BasicSettings> {
      let param: Partial<BasicSettings> = {
        energy_policy: state.data.energy_policy,
        pv_connection_type: state.data.pv_connection_type,
        third_party_inverter_exist: state.data.third_party_inverter_exist,
        battery_backup_soc: state.data.battery_backup_soc,
        battery_installed_rack_count: state.data.battery_installed_rack_count,
        inverter_multiple_earthed_neutral_system: state.data.inverter_multiple_earthed_neutral_system,
        pv_feed_in_limit_flag: state.data.pv_feed_in_limit_flag,
        pv_feed_in_limit_w: state.enableEditFeedInLimit
          ? state.data.energy_policy !== Number(ENERGY_POLICY.ZERO_EXPORT)
            ? state.data.pv_feed_in_limit_w
            : undefined
          : undefined,
        pv_type: state.data.pv_type,
        pv_capacity_calc_option: state.data.pv_capacity_calc_option,
        pv_string_count: state.data.pv_string_count,
        pv_string_power1:
          state.data.pv_capacity_calc_option === 0 && state.data.pv_string_count === 1
            ? state.data.pv_string_power1
            : undefined,
        pv_string_power2:
          state.data.pv_capacity_calc_option === 0 && state.data.pv_string_count === 2
            ? state.data.pv_string_power2
            : undefined,
        pv_string_power3:
          state.data.pv_capacity_calc_option === 0 && state.data.pv_string_count === 3
            ? state.data.pv_string_power3
            : undefined,
        pv_string_power4:
          state.data.pv_capacity_calc_option === 0 && state.data.pv_string_count === 4
            ? state.data.pv_string_power4
            : undefined,
        pv_string_power5:
          state.data.pv_capacity_calc_option === 0 && state.data.pv_string_count === 5
            ? state.data.pv_string_power5
            : undefined,
        pv_module_count: state.data.pv_capacity_calc_option === 1 ? state.data.pv_module_count : undefined,
        pv_module_power: state.data.pv_capacity_calc_option === 1 ? state.data.pv_module_power : undefined,
        pv_meter_model: state.data.pv_connection_type === 1 ? state.data.pv_meter_model : undefined,
        pv_meter_connection: state.data.pv_connection_type === 1 ? state.data.pv_meter_connection : undefined,
        pv_meter_modbus_tcp_ip:
          state.data.pv_connection_type === 1 && state.data.pv_meter_connection === 1
            ? state.data.pv_meter_modbus_tcp_ip
            : undefined,
        pv_meter_modbus_tcp_port:
          state.data.pv_connection_type === 1 && state.data.pv_meter_connection === 1
            ? state.data.pv_meter_modbus_tcp_port
            : undefined,
        pv_meter_modbus_rtu_dev:
          state.data.pv_connection_type === 1 && state.data.pv_meter_connection === 2
            ? state.data.pv_meter_modbus_rtu_dev || '/dev/ttymxc0'
            : undefined,
        pv_meter_modbus_rtu_baudrate:
          state.data.pv_connection_type === 1 && state.data.pv_meter_connection === 2
            ? state.data.pv_meter_modbus_rtu_baudrate
            : undefined,
        pv_meter_modbus_rtu_parity:
          state.data.pv_connection_type === 1 && state.data.pv_meter_connection === 2
            ? state.data.pv_meter_modbus_rtu_parity
            : undefined,
        pv_meter_modbus_rtu_data:
          state.data.pv_connection_type === 1 && state.data.pv_meter_connection === 2
            ? state.data.pv_meter_modbus_rtu_data
            : undefined,
        pv_meter_modbus_rtu_stop:
          state.data.pv_connection_type === 1 && state.data.pv_meter_connection === 2
            ? state.data.pv_meter_modbus_rtu_stop
            : undefined,
        accb_backfeed_limit_flag: state.data.accb_backfeed_limit_flag,
        accb_backfeed_limit: state.data.accb_backfeed_limit_option
          ? backfeedLimitByNEC.value
          : state.data.accb_backfeed_limit,
        accb_consumption_ct_installed_point: state.data.accb_consumption_ct_installed_point,
        accb_polarity_reverse_production_ct: state.data.accb_polarity_reverse_production_ct,
        accb_polarity_reverse_consumption_l1_ct: state.data.accb_polarity_reverse_consumption_l1_ct,
        accb_polarity_reverse_consumption_l2_ct: state.data.accb_polarity_reverse_consumption_l2_ct,
        accb_polarity_reverse_ext_production_ct: state.data.accb_polarity_reverse_ext_production_ct,
        msp_busbar_rating: state.data.msp_busbar_rating,
        msp_breaker_rating: state.data.msp_breaker_rating,
      };

      if (!state.data.accb_backfeed_limit_flag) {
        param = _.omit(param, ['accb_backfeed_limit', 'msp_busbar_rating', 'msp_breaker_rating']);
      } else {
        if (!state.data.accb_backfeed_limit_option) {
          param = _.omit(param, ['msp_busbar_rating', 'msp_breaker_rating']);
        }
      }

      const cleanedParam = _.pickBy(param, (v) => v !== undefined && v !== null);

      return cleanedParam;
    }

    function getTempPasswordParam(): Partial<BasicSettings> {
      const nowUTCDate = moment.utc().format();
      const nowUTCTimestamp = new Date(nowUTCDate).getTime() / NUMBER_UNIT.THOUSAND;
      const param: Partial<BasicSettings> = {
        installer_page_temp_password_password: getTemporaryPassword(),
        installer_page_temp_password_setting_time: nowUTCTimestamp,
      };

      const cleanedParam = _.pickBy(param, (v) => v !== undefined && v !== null);

      return cleanedParam;
    }

    function onSave() {
      let confirmItems = getParam();
      const filteredItems = getFilteredParams(confirmItems);

      let params = getBasicSettingACSystemConfig(t, selectorOptionsState, state.data)
        .map((config) => {
          return {
            ...config,
            children: config.children
              .map((item) => {
                const itemValue = filteredItems[item.code as keyof BasicSettings];
                const value =
                  item.type === 'number' || item.type === 'text'
                    ? itemValue
                    : !isNull(itemValue)
                    ? item.options?.filter((option) => option.value == itemValue)[0]?.text
                    : undefined;

                return {
                  ...item,
                  value: value,
                };
              })
              .filter((item) => !isNull(item.value)),
          };
        })
        .filter((config) => config.children.length > 0);

      state.confirmPopup = { on: true, data: params };
    }

    function onConfirm() {
      state.confirmPopup.on = false;
      emit(
        'save',
        getFilteredParams(getParam()),
        settingTargetType.value,
        deviceTarget.value,
        function (isOk: boolean) {
          if (isOk) {
            state.editable = false;
            if (state.data.energy_policy !== Number(ENERGY_POLICY.ZERO_EXPORT)) {
              state.prevFeedInLimitWatt = state.data.pv_feed_in_limit_w;
            }
          }
        }
      );
    }

    function onGenerate() {
      if (state.data.install_done != 1) {
        // 장비 초기 설정 x
        messageBox.alert(t('message.device_init_set_not_complete')).open();

        return;
      }
      if (!props.isConnection) {
        // 장비 미연결 상태일 경우
        const messageCode = 'code.cmdgen3configreqstatuscd.230';
        messageBox.alert(t(messageCode)).open();

        return;
      }
      emit('generate', getTempPasswordParam());
    }

    function onEdit() {
      if (!props.isConnection) {
        messageBox.alert(t('message.modify_when_disconnected')).open();

        return;
      }

      copyData = _.cloneDeep(state.data);
      state.editable = true;
      emit('edit', copyData);

      schema.value = getSchema(getBasicSettingACSystemConfig(t, selectorOptionsState, state.data))
        .required()
        .concat(passwordSchema);
      rerender();
    }

    function onCancel(handleReset?: () => void) {
      state.data = copyData;
      state.editable = false;
      if (handleReset) handleReset();
      emit('cancel');
    }
    function goToList() {
      emit('goToList');
    }

    function onChange(valueKey: string, targetValue?: string | number | boolean) {
      if (state.editable) {
        if (!isNull(targetValue)) {
          // 변경 항목 key set에 저장
          state.changedValueSet.add(valueKey);
        } else {
          // 변경 항목 key set에서 제거
          state.changedValueSet.delete(valueKey);
        }
      } else {
        // cancel 버튼 클릭 시
        state.changedValueSet = copyChangedValueSet;
      }
    }

    function checkEnergyPolicy(value: SelectorValue, beforeValue: SelectorValue): boolean {
      if (value === Number(ENERGY_POLICY.ZERO_EXPORT)) {
        //feedInLimit은 비활성화, backFeedLimit은 활성화
        state.enableEditFeedInLimit = false;
        state.data.pv_feed_in_limit_w = 0;
      } else {
        state.enableEditFeedInLimit = true;
        if (beforeValue === Number(ENERGY_POLICY.ZERO_EXPORT)) {
          state.data.pv_feed_in_limit_w = state.prevFeedInLimitWatt;
        }
      }
      if (value === Number(ENERGY_POLICY.TIME_BASED)) {
        messageBox.alert(t('message.pricingsetting_when_timebasemode')).open();
      }

      return true;
    }

    function onChangeMeterModel(value: SelectorValue) {
      if (isNull(value) || value == 0) {
        state.data.meter_connection = 0;

        return;
      }
      if (!value) return;

      state.data.meter_modbus_rtu_baudrate = DEFAULT_RTU_BAUDRATE;
      if (value == 12 || value == 13) {
        state.data.meter_modbus_rtu_baudrate = DEFAULT_RTU_BAUDRATE_EM530OR540;
      }
    }

    function onChangePvMeterModel(value: SelectorValue) {
      if (isNull(value) || value == 0) {
        state.data.pv_meter_connection = 0;

        return;
      }
      if (!value) return;

      state.data.pv_meter_modbus_rtu_baudrate = DEFAULT_RTU_BAUDRATE;
      if (value == 12 || value == 13) {
        state.data.pv_meter_modbus_rtu_baudrate = DEFAULT_RTU_BAUDRATE_EM530OR540;
      }
    }

    function getWebHMIPasswordParams(type: WebHmiPasswordType): Partial<BasicSettingsSmartModuleACSystem> {
      if (type === ROLE_TYPE.ENGINEER) {
        const params = {
          web_page_password_engineer: state.data.web_page_password_engineer,
        };

        return params;
      } else {
        const params = {
          web_page_password_service: state.data.web_page_password_service,
        };

        return params;
      }
    }

    function onChangeWebHMIPassword(type: WebHmiPasswordType) {
      emit('changeWebHMIPassword', getWebHMIPasswordParams(type));
    }

    const backfeedLimitByNEC = ref<number | undefined>(undefined);

    const calcComputedBackfeedLimit = () => {
      const { msp_busbar_rating, msp_breaker_rating } = state.data;

      if (msp_busbar_rating && msp_breaker_rating) {
        backfeedLimitByNEC.value = Math.floor((msp_busbar_rating * 1.2 - msp_breaker_rating) * 0.8);
      } else {
        backfeedLimitByNEC.value = undefined;
      }
    };

    function isBasicSettingField(fieldName: string) {
      return isSettingField(fieldName, fieldList, settingTargetType.value, deviceTarget.value);
    }

    //@TODO advancedSettingsUtil 에도 동일한 function이 있는데 type이 달라서 추후에 통합할 예정
    function getSchema(settingConfig: acSystemSettingGroup[]) {
      const shape = settingConfig.reduce((acc, group) => {
        const groupShape = group.children.reduce((groupAcc, item) => {
          if (['text', 'number', 'selector', 'toggle'].includes(item.type) && item.schema) {
            if (isBasicSettingField(item.code)) {
              return { ...groupAcc, [item.code]: item.schema() };
            }
          }

          return groupAcc;
        }, {});

        return { ...acc, ...groupShape };
      }, {});

      return yup.object().shape(shape);
    }

    function getFilteredParams(confirmedItems: Partial<BasicSettings>) {
      const filteredItems: Record<keyof BasicSettings | string, string | number | null | undefined> = {};

      Object.keys(confirmedItems).forEach((code) => {
        if (isBasicSettingField(code)) {
          filteredItems[code] = confirmedItems[code as keyof BasicSettings];
        }
      });

      return filteredItems;
    }

    const rerender = () => {
      isInit.value = false;
      nextTick(() => {
        isInit.value = true;
      });
    };

    onBeforeUpdate(() => {
      nextTick(() => {
        settingTableRerender('basic-tb');
      });
    });

    const getPvStringPowerValue = (value: number) => state.data[getPvStringPowerKey(value)];

    const getPvStringPowerKey = (value: number): PvStringPowerType => {
      if (!isPvStringPowerValue(value)) return 'pv_string_power1';

      return `pv_string_power${value}`;
    };

    const afterChange = (stringCount: SelectorValue) => {
      for (let i = Number(stringCount) + 1; i <= 3; i++) {
        state.data[getPvStringPowerKey(i - 1)] = 0;
      }
    };

    onMounted(() => {
      settingTableRerender('basic-tb');
      if (state.data.energy_policy === Number(ENERGY_POLICY.ZERO_EXPORT)) {
        state.enableEditFeedInLimit = false;
        state.data.pv_feed_in_limit_w = 0;
      }
      if (props.data.installer_page_temp_password_setting_time != undefined) {
        state.data.installer_page_temp_password_setting_time = getFormatDate(
          props.data.installer_page_temp_password_setting_time
        );
      }
      if (props.data.accb_backfeed_limit_option === undefined) {
        state.data.accb_backfeed_limit_option = 0;
      }
      if (props.data.accb_consumption_ct_installed_point === undefined) {
        state.data.accb_consumption_ct_installed_point = 0;
      }
      if (props.data.accb_polarity_reverse_consumption_l1_ct === undefined) {
        state.data.accb_polarity_reverse_consumption_l1_ct = 0;
      }
      if (props.data.accb_polarity_reverse_consumption_l2_ct === undefined) {
        state.data.accb_polarity_reverse_consumption_l2_ct = 0;
      }
      if (props.data.accb_polarity_reverse_ext_production_ct === undefined) {
        state.data.accb_polarity_reverse_ext_production_ct = 0;
      }
      if (props.data.accb_polarity_reverse_production_ct === undefined) {
        state.data.accb_polarity_reverse_production_ct = 0;
      }
      calcComputedBackfeedLimit();
    });

    watch(
      () => props.data,
      () => {
        state.data.installer_page_temp_password_setting_time = getFormatDate(
          props.data.installer_page_temp_password_setting_time
        );
      }
    );

    watch(
      () => languageCode.value,
      () => {
        state.data.installer_page_temp_password_setting_time = getFormatDate(
          props.data.installer_page_temp_password_setting_time
        );
      }
    );

    watch(
      () => [settingTargetType.value, deviceTarget.value],
      () => {
        onCancel();
      }
    );

    watch(
      () => state.data.third_party_inverter_exist,
      (third_party_inverter_exist) => {
        if (third_party_inverter_exist === 0) {
          state.data.pv_connection_type = 2;
        }
      }
    );

    watch(
      () => [state.data.msp_breaker_rating, state.data.msp_busbar_rating],
      () => {
        calcComputedBackfeedLimit();
      }
    );

    watch(
      () => [settingTargetType.value, deviceTarget.value],
      () => {
        onCancel();

        if (!state.data.accb_backfeed_limit_option) {
          state.data.accb_backfeed_limit_option = 0;
        }
      }
    );

    await loadSelectorOptions();

    return {
      state,
      acSystemComputedRange,
      schema,
      selectorOptionsState,
      onSave,
      onGenerate,
      onEdit,
      onCancel,
      onChangeMeterModel,
      onChangePvMeterModel,
      goToList,
      checkEnergyPolicy,
      onChange,
      onConfirm,
      titleWidth: '40%',
      totalModulePower,
      onChangeWebHMIPassword,
      isNull,
      backfeedLimitByNEC,
      isBasicSettingField,
      settingTargetType,
      deviceTarget,
      basicDeviceOption,
      roleName,
      CONSUMPTION_CT_TYPE,
      isInit,
      getPvStringPowerValue,
      getPvStringPowerKey,
      afterChange,
    };
  },
});
