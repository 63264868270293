import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Flow = _resolveComponent("Flow")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: _ctx.FLOW_ANIMATION.AC_PV_TO_BATTERY
    }, null, 8, ["name"]), [
      [_vShow, _ctx.energyFlow.isPvToBattery]
    ]),
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: _ctx.FLOW_ANIMATION.AC_PV_TO_GRID
    }, null, 8, ["name"]), [
      [_vShow, _ctx.energyFlow.isPvToGrid]
    ]),
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: _ctx.FLOW_ANIMATION.AC_PV_TO_LOAD
    }, null, 8, ["name"]), [
      [_vShow, _ctx.energyFlow.isPvToLoad]
    ]),
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: _ctx.FLOW_ANIMATION.AC_BATTERY_TO_GRID
    }, null, 8, ["name"]), [
      [_vShow, _ctx.energyFlow.isBatteryToGrid]
    ]),
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: _ctx.FLOW_ANIMATION.AC_BATTERY_TO_LOAD
    }, null, 8, ["name"]), [
      [_vShow, _ctx.energyFlow.isBatteryToLoad]
    ]),
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: _ctx.FLOW_ANIMATION.AC_GRID_TO_BATTERY
    }, null, 8, ["name"]), [
      [_vShow, _ctx.energyFlow.isGridToBattery]
    ]),
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: _ctx.FLOW_ANIMATION.AC_GRID_TO_LOAD
    }, null, 8, ["name"]), [
      [_vShow, _ctx.energyFlow.isGridToLoad]
    ]),
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: _ctx.FLOW_ANIMATION.AC_GENERATOR_TO_LOAD
    }, null, 8, ["name"]), [
      [_vShow, _ctx.energyFlow.isGeneratorToLoad]
    ])
  ], 64))
}