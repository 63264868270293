
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { EnergyIndependenceInfo } from 'hems/dashboard';
export default defineComponent({
  name: 'EnergyIndependenceBar',
  props: {
    energyIndependenceData: {
      type: Object as PropType<EnergyIndependenceInfo>,
      required: true,
    },
  },
});
