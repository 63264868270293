
import type { PropType } from 'vue';
import { computed, defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

import { Form } from 'vee-validate';
import type * as Yup from 'yup';

import type { FormMode } from 'hems';

export default defineComponent({
  name: 'NoticeFormContainer',
  components: {
    Form,
  },
  props: {
    mode: {
      type: String as PropType<FormMode>,
      default: 'read',
    },
    editable: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
    },
    schema: {
      type: Object as PropType<Yup.AnySchema>,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['save', 'edit', 'goToList', 'cancel'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const state = reactive({
      mode: computed(() => props.mode),
      editable: computed(() => props.editable),
    });

    const cancelOrGoToListBtnText = computed(() => (state.editable ? t('common.cancel') : t('message.go_to_list')));
    const publishOrEditBtnText = computed(() => (state.editable ? t('common.publish') : t('common.edit')));

    const cancelOrGoToListEvent = () => {
      state.editable ? emit('cancel') : emit('goToList');
    };
    const saveOrEditEvent = () => {
      state.editable ? emit('save') : emit('edit');
    };

    return {
      state,
      cancelOrGoToListBtnText,
      publishOrEditBtnText,
      cancelOrGoToListEvent,
      saveOrEditEvent,
    };
  },
});
