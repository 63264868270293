
import type { PropType } from 'vue';
import { defineComponent, reactive, ref, watch } from 'vue';

import { gsap } from 'gsap';

import NavSubItem from '@hems/component/src/navigation/NavSubItem.vue';

import type { NavItem } from 'hems';

export default defineComponent({
  name: 'NavMainItem',
  components: {
    NavSubItem,
  },
  props: {
    navItem: {
      type: Object as PropType<NavItem>,
      required: true,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const subMenuBg = ref();
    const subMenuContainer = ref();

    const state = reactive({
      show: false,
    });

    watch(
      () => state.show,
      (show) => {
        if (show) {
          gsap.to([subMenuBg.value, subMenuContainer.value] as gsap.TweenTarget, {
            height: '4.375rem',
            duration: 0.4,
          });

          return;
        }
        gsap.to([subMenuBg.value, subMenuContainer.value] as gsap.TweenTarget, {
          height: '0',
          duration: 0.4,
        });
      }
    );
    const onClick = (e: MouseEvent) => {
      emit('click', e, props.navItem.path);
    };
    const onSubClick = (e: MouseEvent, path: string) => {
      emit('click', e, path);
    };

    return {
      state,
      subMenuBg,
      subMenuContainer,
      onClick,
      onSubClick,
    };
  },
});
