
import { computed, defineComponent, PropType, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { Helper, useModelWrapper } from '@hems/util';
import { LabelInput, Selector } from '@hems/component';
import { CodeName, SelectorOption } from 'hems';
import { CommonService } from '@hems/service';
import { TargetEssSearchCondition } from '@hems/service/src/api/groupSetting/GroupSettingServiceInstaller';

export default defineComponent({
  name: 'TargetEssSearchBox',
  components: {
    LabelInput,
    Selector,
  },
  props: {
    modelValue: {
      type: Object as PropType<TargetEssSearchCondition>,
      required: true,
    },
  },
  emits: ['search'],

  async setup(props, { emit }) {
    const { t } = useI18n();
    const commService = new CommonService(window.axiosInstance.axios);
    const state = reactive({
      searchCondition: useModelWrapper<TargetEssSearchCondition>(props, emit),
      emsModeList: [] as CodeName[],
    });

    const onSearch = async () => {
      emit('search');
    };

    function convertOptions(codeNames: CodeName[]): SelectorOption[] {
      const selectAll: SelectorOption = { text: 'All', value: '' };
      const result = codeNames.map<SelectorOption>((item) => ({
        value: item.code,
        text: t(`${item.name}`, { defaultValue: item.name }),
      }));
      return [selectAll].concat(result);
    }

    const { EMS_RUN_MODE_CD } = await commService.getCodesByGroupCode([{ grpCd: 'EMS_RUN_MODE_CD' }]);

    return {
      state,
      Helper,
      onSearch,
      options: computed(() => convertOptions(EMS_RUN_MODE_CD || [])),
    };
  },
});
