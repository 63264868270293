
import type { PropType } from 'vue';
import { computed, defineComponent, reactive, watch } from 'vue';

import { useField } from 'vee-validate';

import Image2 from '@hems/component/src/image/Image2.vue';
import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';
import { Helper } from '@hems/util';

export default defineComponent({
  name: 'BasicInput',
  components: {
    ErrorMessageLabel,
    Image2,
  },
  inheritAttrs: false,
  props: {
    modelValue: [String, Number],
    validateMode: {
      type: String as PropType<'input' | 'blur'>,
      default: 'input',
    },
    className: {
      type: String as PropType<'fm_ipt' | 'fm_ipt_2' | 'fm_ipt_4'>,
      default: 'fm_ipt',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    name: String,
    as: Function,
    unit: String,
    type: String,
    validClass: {
      type: String as PropType<'ok' | 'error'>,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const inputName = props.name ?? Helper.getUUID();
    const { value: inputValue, errorMessage } = useField<string | number | null>(inputName, undefined, {
      initialValue: props.modelValue,
      type: props.type,
    });

    const state = reactive({
      isReadonly: computed(() =>
        props.readonly === undefined ? false : ['', true, 'true'].includes(props.readonly) ? true : false
      ),
      displayValue: computed(() => {
        const value = props.as ? props.as() : Helper.nullTo(props.modelValue, '');

        return `${value} ${!Helper.isNull(value) ? props.unit || '' : ''}`;
      }),
      showPassword: false,
    });

    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue !== inputValue.value) inputValue.value = props.modelValue ?? null;
      }
    );

    function setValue(value: number | string) {
      if (props.type === 'number') {
        if (Helper.isNull(value)) {
          emit('update:modelValue', null);

          return;
        }
        emit('update:modelValue', Number(value));

        return;
      }
      emit('update:modelValue', value);
    }

    function onBlur(e: Event) {
      if (props.validateMode === 'blur') {
        setValue((e as any).target.value);
      }
    }

    function onChange(e: Event) {
      if (props.validateMode === 'input') {
        setValue((e as any).target.value);
      }
    }

    function displayPassword(value: string) {
      if (value === null) return value;

      return Array.from(Array(value.length), (_, index) => (index > 2 ? '*' : value.substring(index, 1))).join('');
    }

    return {
      state,
      inputValue,
      inputName,
      errorMessage,
      displayPassword,
      onBlur,
      onChange,
      Helper,
    };
  },
});
