import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ display: 'relative', height: _ctx.height || '100%', width: _ctx.width || '100%' })
  }, [
    _createElementVNode("div", { id: _ctx.chartId }, null, 8, _hoisted_1)
  ], 4))
}