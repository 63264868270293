export const enum CONNECTION_STATUS {
  online = 'online',
  'online-disconnected' = 'online-disconnected',
  offline = 'offline',
  incomplete = 'incomplete',
}

export const enum CONNECTION_STATUS_CODE {
  online = '1',
  'online-disconnected' = '0',
  offline = '0',
  incomplete = '0',
}

export const enum DEVICE_STATUS {
  run = 'run',
  warning = 'warning',
  error = 'error',
  incomplete = 'incomplete',
  offline = 'offline',
}

export const enum DEVICE_STATUS_CODE {
  run = '0',
  warning = '1',
  error = '2',
  incomplete = '4',
  offline = '6',
}
