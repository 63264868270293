import * as yup from 'yup';

import { Helper } from '@hems/util';

import type { i18nTranslation } from 'hems';

import type { SettingConfigGroup } from 'hems/common/settingConfig';
import type { AdvancedSettingsKey } from 'hems/device/settings/pvmodule/gen2';
const numberValidator = () =>
  yup
    .number()
    .transform((v, o) => (o === '' ? null : v))
    .nullable();

export const getAdvancedSettingGen2Config = (t: i18nTranslation): SettingConfigGroup<AdvancedSettingsKey>[] => {
  return [
    {
      groupTitle: 'Over Voltage',
      children: [
        {
          title: 'Level1',
          code: 'gc_over_voltage_level1',
          type: 'number',
          min: '1.00',
          max: '1.35',
          unit: 'Un',
          schema: () => numberValidator().min(1).max(1.35).unitNumber(0.01, 1),
        },
        {
          title: 'Time1',
          code: 'gc_over_voltage_time1',
          type: 'number',
          min: '0',
          max: '65535',
          unit: 'mesc',
          schema: () => numberValidator().integer().min(0).max(65535),
        },
        {
          title: 'Level2',
          code: 'gc_over_voltage_level2',
          type: 'number',
          min: '1.00',
          max: '1.35',
          unit: 'Un',
          schema: () => numberValidator().min(1).max(1.35).unitNumber(0.01, 1),
        },
        {
          title: 'Time2',
          code: 'gc_over_voltage_time2',
          type: 'number',
          min: '0',
          max: '65535',
          unit: 'mesc',
          schema: () => numberValidator().integer().min(0).max(65535),
        },
        {
          title: 'Level3',
          code: 'gc_over_voltage_level3',
          type: 'number',
          min: '1.00',
          max: '1.35',
          unit: 'Un',
          schema: () => numberValidator().min(1).max(1.35).unitNumber(0.01, 1),
        },
        {
          title: 'Time3',
          code: 'gc_over_voltage_time3',
          type: 'number',
          min: '0',
          max: '65535',
          unit: 'mesc',
          schema: () => numberValidator().integer().min(0).max(65535),
        },
        {
          title: 'Level4',
          code: 'gc_over_voltage_level4',
          type: 'number',
          min: '1.00',
          max: '1.35',
          unit: 'Un',
          schema: () => numberValidator().min(1).max(1.35).unitNumber(0.01, 1),
        },
        {
          title: 'Time4',
          code: 'gc_over_voltage_time4',
          type: 'number',
          min: '0',
          max: '65535',
          unit: 'mesc',
          schema: () => numberValidator().integer().min(0).max(65535),
        },
      ],
    },
    {
      groupTitle: 'Under Voltage',
      children: [
        {
          title: 'Level1',
          code: 'gc_under_voltage_level1',
          type: 'number',
          min: '0.01',
          max: '1.00',
          unit: 'Un',
          schema: () => numberValidator().min(0.01).max(1).unitNumber(0.01, 0.01),
        },
        {
          title: 'Time1',
          code: 'gc_under_voltage_time1',
          type: 'number',
          min: '0',
          max: '65535',
          unit: 'mesc',
          schema: () => numberValidator().integer().min(0).max(65535),
        },
        {
          title: 'Level2',
          code: 'gc_under_voltage_level2',
          type: 'number',
          min: '0.01',
          max: '1.00',
          unit: 'Un',
          schema: () => numberValidator().min(0.01).max(1).unitNumber(0.01, 0.01),
        },
        {
          title: 'Time2',
          code: 'gc_under_voltage_time2',
          type: 'number',
          min: '0',
          max: '65535',
          unit: 'mesc',
          schema: () => numberValidator().integer().min(0).max(65535),
        },
      ],
    },
    {
      groupTitle: '10Min Voltage',
      children: [
        {
          title: 'In',
          code: 'gc_10min_voltage_in',
          type: 'number',
          min: '1.00',
          max: '1.35',
          unit: 'Un',
          schema: () => numberValidator().min(1).max(1.35).unitNumber(0.01, 1),
        },
      ],
    },
    {
      groupTitle: 'Over Frequency',
      children: [
        {
          title: 'In',
          code: 'gc_over_frequency_in',
          type: 'number',
          min: '50.00',
          max: '70.00',
          unit: 'Hz',
          schema: () => numberValidator().min(50).max(70).unitNumber(0.01, 50),
        },
        {
          title: 'Out',
          code: 'gc_over_frequency_out',
          type: 'number',
          min: '50.00',
          max: '70.00',
          unit: 'Hz',
          schema: () => numberValidator().min(50).max(70).unitNumber(0.01, 50),
        },
      ],
    },
    {
      groupTitle: 'Under Frequency',
      children: [
        {
          title: 'In',
          code: 'gc_under_frequency_in',
          type: 'number',
          min: '40.00',
          max: '60.00',
          unit: 'Hz',
          schema: () => numberValidator().min(40).max(60).unitNumber(0.01, 40),
        },
        {
          title: 'Out',
          code: 'gc_under_frequency_out',
          type: 'number',
          min: '40.00',
          max: '60.00',
          unit: 'Hz',
          schema: () => numberValidator().min(40).max(60).unitNumber(0.01, 40),
        },
      ],
    },
    {
      groupTitle: 'Active Power SetPoint',
      children: [
        {
          title: 'SetPoint',
          code: 'gc_active_power_setpoint_enable',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'SetPoint Value',
          code: 'gc_active_power_setpoint_value',
          type: 'number',
          min: '0',
          max: '100',
          schema: () => numberValidator().integer().min(0).max(100),
        },
      ],
    },
    {
      groupTitle: 'Active Power Frequency',
      children: [
        {
          title: 'Frequency',
          code: 'gc_active_power_frequency_enable',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'SL: Delta P',
          code: 'gc_active_power_frequency_sl',
          type: 'number',
          min: '2',
          max: '15',
          description: '0, 2 ~ 15',
          schema: () =>
            numberValidator().test(
              'gc_active_power_frequency_sl',
              { key: 'message.invalid' },
              function (value?: number | null) {
                try {
                  const { createError } = this;
                  if (Helper.isNull(value) || value === 0) {
                    return true;
                  }
                  if (value < 2) {
                    return createError({
                      message: { key: 'message.greater_min', values: { min: 2 } },
                    });
                  }
                  if (value > 15) {
                    return createError({
                      message: { key: 'message.greater_max', values: { max: 15 } },
                    });
                  }

                  return true;
                } catch (error) {
                  return false;
                }
              }
            ),
        },
        {
          title: 'X1: Frequency',
          code: 'gc_active_power_frequency_x1',
          type: 'number',
          min: '40.00',
          max: '60.00',
          schema: () => numberValidator().min(40).max(60).unitNumber(0.01, 40),
        },
        {
          title: 'X2: Frequency',
          code: 'gc_active_power_frequency_x2',
          type: 'number',
          min: '40.00',
          max: '60.00',
          schema: () => numberValidator().min(40).max(60).unitNumber(0.01, 40),
        },
        {
          title: 'SH: Delta P',
          code: 'gc_active_power_frequency_sh',
          type: 'number',
          min: '2',
          max: '15',
          description: '0, 2 ~ 15',
          schema: () =>
            numberValidator().test(
              'gc_active_power_frequency_sh',
              { key: 'message.invalid' },
              function (value?: number | null) {
                try {
                  const { createError } = this;
                  if (Helper.isNull(value) || value === 0) {
                    return true;
                  }
                  if (value < 2) {
                    return createError({
                      message: { key: 'message.greater_min', values: { min: 2 } },
                    });
                  }
                  if (value > 15) {
                    return createError({
                      message: { key: 'message.greater_max', values: { max: 15 } },
                    });
                  }

                  return true;
                } catch (error) {
                  return false;
                }
              }
            ),
        },
        {
          title: 'X3: Frequency',
          code: 'gc_active_power_frequency_x3',
          type: 'number',
          min: '50.00',
          max: '70.00',
          schema: () => numberValidator().min(50).max(70).unitNumber(0.01, 50),
        },
        {
          title: 'X4: Frequency',
          code: 'gc_active_power_frequency_x4',
          type: 'number',
          min: '50.00',
          max: '70.00',
          schema: () => numberValidator().min(50).max(70).unitNumber(0.01, 50),
        },
        {
          title: 'fStop_CH : Frequency',
          code: 'gc_active_power_frequency_fstop_ch',
          type: 'number',
          min: '45.000',
          max: '60.000',
          schema: () => numberValidator().min(45).max(60).unitNumber(0.001, 45),
        },
        {
          title: 'fTransition : Frequency',
          code: 'gc_active_power_frequency_ftransition',
          type: 'number',
          min: '45.000',
          max: '60.000',
          schema: () => numberValidator().min(45).max(60).unitNumber(0.001, 45),
        },
      ],
    },
    {
      groupTitle: 'Active Power Voltage',
      children: [
        {
          title: 'Voltage',
          code: 'gc_active_power_voltage_enable',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'X1: Voltage',
          code: 'gc_active_power_voltage_x1',
          type: 'number',
          min: '0.80',
          max: '1.25',
          schema: () => numberValidator().min(0.8).max(1.25).unitNumber(0.01, 0.8),
        },
        {
          title: 'Y1: Power',
          code: 'gc_active_power_voltage_y1',
          type: 'number',
          min: '0.00',
          max: '1.00',
          schema: () => numberValidator().min(0).max(1).unitNumber(0.01, 0),
        },
        {
          title: 'X2: Voltage',
          code: 'gc_active_power_voltage_x2',
          type: 'number',
          min: '0.80',
          max: '1.25',
          schema: () => numberValidator().min(0.8).max(1.25).unitNumber(0.01, 0.8),
        },
        {
          title: 'Y2: Power',
          code: 'gc_active_power_voltage_y2',
          type: 'number',
          min: '0.00',
          max: '1.00',
          schema: () => numberValidator().min(0).max(1).unitNumber(0.01, 0),
        },
        {
          title: 'X3: Voltage',
          code: 'gc_active_power_voltage_x3',
          type: 'number',
          min: '1.00',
          max: '1.25',
          schema: () => numberValidator().min(0.8).max(1.25).unitNumber(0.01, 0.8),
        },
        {
          title: 'Y3: Power',
          code: 'gc_active_power_voltage_y3',
          type: 'number',
          min: '0.00',
          max: '1.00',
          schema: () => numberValidator().min(0).max(1).unitNumber(0.01, 0),
        },
        {
          title: 'X4: Voltage',
          code: 'gc_active_power_voltage_x4',
          type: 'number',
          min: '1.00',
          max: '1.25',
          schema: () => numberValidator().min(0.8).max(1.25).unitNumber(0.01, 0.8),
        },
        {
          title: 'Y4: Power',
          code: 'gc_active_power_voltage_y4',
          type: 'number',
          min: '0.00',
          max: '1.00',
          schema: () => numberValidator().min(0).max(1).unitNumber(0.01, 0),
        },
      ],
    },
    {
      groupTitle: 'PT1 Filter behavior : Tau',
      children: [
        {
          title: 'Tau Value',
          code: 'gc_reactive_pt1_filter_tau',
          type: 'number',
          min: '3',
          max: '60',
          unit: 'msec',
          schema: () => numberValidator().integer().min(3).max(60),
        },
      ],
    },
    {
      groupTitle: 'Power Factor SetPoint',
      children: [
        {
          title: 'SetPoint',
          code: 'gc_reactive_power_setpoint_enable',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'SetPoint Excited',
          code: 'gc_reactive_power_setpoint_excited',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'SetPoint Value',
          code: 'gc_reactive_power_setpoint_value',
          type: 'number',
          min: '0.80',
          max: '1.00',
          schema: () => numberValidator().min(0.8).max(1).unitNumber(0.01, 0.8),
        },
      ],
    },
    {
      groupTitle: 'Reactive Power Cospi(P)',
      children: [
        {
          title: 'Cospi(P)',
          code: 'gc_reactive_power_cospi_enable',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'Cospi(P) Excited',
          code: 'gc_reactive_power_cospi_excited',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'X1: Power',
          code: 'gc_reactive_power_cospi_x1',
          type: 'number',
          min: '0.00',
          max: '1.00',
          schema: () => numberValidator().min(0).max(1).unitNumber(0.01, 0),
        },
        {
          title: 'Y1: Power Factor',
          code: 'gc_reactive_power_cospi_y1',
          type: 'number',
          min: '0.80',
          max: '1.00',
          schema: () => numberValidator().min(0.8).max(1).unitNumber(0.01, 0.8),
        },
        {
          title: 'X2: Power',
          code: 'gc_reactive_power_cospi_x2',
          type: 'number',
          min: '0.00',
          max: '1.00',
          schema: () => numberValidator().min(0).max(1).unitNumber(0.01, 0),
        },
        {
          title: 'Y2: Power Factor',
          code: 'gc_reactive_power_cospi_y2',
          type: 'number',
          min: '0.80',
          max: '1.00',
          schema: () => numberValidator().min(0.8).max(1).unitNumber(0.01, 0.8),
        },
        {
          title: 'X3: Power',
          code: 'gc_reactive_power_cospi_x3',
          type: 'number',
          min: '0.00',
          max: '1.00',
          schema: () => numberValidator().min(0).max(1).unitNumber(0.01, 0),
        },
        {
          title: 'Y3: Power Factor',
          code: 'gc_reactive_power_cospi_y3',
          type: 'number',
          min: '0.80',
          max: '1.00',
          schema: () => numberValidator().min(0.8).max(1).unitNumber(0.01, 0.8),
        },
      ],
    },
    {
      groupTitle: 'Reactive Power Q(SetPoint)',
      children: [
        {
          title: 'Q(SetPoint)',
          code: 'gc_reactive_power_q_setpoint_enable',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'Q(SetPoint) Excited',
          code: 'gc_reactive_power_q_setpoint_excited',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'SetPoint Value',
          code: 'gc_reactive_power_q_setpoint_value',
          type: 'number',
          min: '0.000',
          max: '0.600',
          schema: () => numberValidator().min(0).max(0.6).unitNumber(0.001, 0),
        },
      ],
    },
    {
      groupTitle: 'Reactive Power Q(U)',
      children: [
        {
          title: 'Q(U)',
          code: 'gc_reactive_power_q_u_enable',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: 'X1: Voltage',
          code: 'gc_reactive_power_q_u_x1',
          type: 'number',
          min: '0',
          max: '1.9999',
          schema: () => numberValidator().min(0).max(1.9999).unitNumber(0.0001, 0),
        },
        {
          title: 'Y1: Reactive',
          code: 'gc_reactive_power_q_u_y1',
          type: 'number',
          min: '-60.00',
          max: '60.00',
          schema: () => numberValidator().min(-60).max(60).unitNumber(0.01, -60),
        },
        {
          title: 'X2: Voltage',
          code: 'gc_reactive_power_q_u_x2',
          type: 'number',
          min: '0',
          max: '1.9999',
          schema: () => numberValidator().min(0).max(1.9999).unitNumber(0.0001, 0),
        },
        {
          title: 'Y2: Reactive',
          code: 'gc_reactive_power_q_u_y2',
          type: 'number',
          min: '-60.00',
          max: '60.00',
          schema: () => numberValidator().min(-60).max(60).unitNumber(0.01, 0),
        },
        {
          title: 'X3: Voltage',
          code: 'gc_reactive_power_q_u_x3',
          type: 'number',
          min: '0',
          max: '1.9999',
          schema: () => numberValidator().min(0).max(1.9999).unitNumber(0.0001, 0),
        },
        {
          title: 'Y3: Reactive',
          code: 'gc_reactive_power_q_u_y3',
          type: 'number',
          min: '-60.00',
          max: '60.00',
          schema: () => numberValidator().min(-60).max(60).unitNumber(0.01, 0),
        },
        {
          title: 'X4: Voltage',
          code: 'gc_reactive_power_q_u_x4',
          type: 'number',
          min: '0',
          max: '1.9999',
          schema: () => numberValidator().min(0).max(1.9999).unitNumber(0.0001, 0),
        },
        {
          title: 'Y4: Reactive',
          code: 'gc_reactive_power_q_u_y4',
          type: 'number',
          min: '-60.00',
          max: '60.00',
          schema: () => numberValidator().min(-60).max(60).unitNumber(0.01, 0),
        },
      ],
    },
    {
      groupTitle: 'Pv_Insulation Control',
      children: [
        {
          title: 'Enable',
          code: 'gc_pv_insulation_control_enable',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'Fault_Level',
          code: 'gc_pv_insulation_control_faultlevel',
          type: 'number',
          min: '0',
          max: '50000',
          schema: () => numberValidator().integer().min(0).max(50000),
          unit: 'R',
        },
        {
          title: 'Check_Cnt',
          code: 'gc_pv_insulation_control_checkcnt',
          type: 'number',
          min: '1',
          max: '100',
          unit: 'Cnt',
          schema: () => numberValidator().integer().min(1).max(100),
        },
      ],
    },
    {
      groupTitle: 'Gradient Control',
      children: [
        {
          title: 'Enable',
          code: 'gc_gradient_control_enable',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'Energy Source Change Enable',
          code: 'gc_gradient_control_energy_source_change_enable',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'Time_Level',
          code: 'gc_gradient_control_timelevel',
          type: 'number',
          min: '60',
          max: '1200',
          unit: 'sec',
          schema: () => numberValidator().integer().min(60).max(1200),
        },
      ],
    },
    {
      groupTitle: 'Limit Control',
      children: [
        {
          title: 'Export Hard Limit Flag',
          code: 'limitctrl_exporthard_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'Export Hard Limit Level',
          code: 'limitctrl_exporthard_level',
          type: 'number',
          min: '0',
          max: '100',
          unit: '%',
          schema: () => numberValidator().integer().min(0).max(100),
        },
        {
          title: 'Export Hard Limit Time',
          code: 'limitctrl_exporthard_time',
          type: 'number',
          min: '0',
          max: '100',
          unit: 'Sec',
          schema: () => numberValidator().integer().min(0).max(100),
        },
        {
          title: 'Export Soft Limit Flag',
          code: 'limitctrl_exportsoft_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'Export Soft Limit Level',
          code: 'limitctrl_exportsoft_level',
          type: 'number',
          min: '0',
          max: '100',
          unit: '%',
          schema: () => numberValidator().integer().min(0).max(100),
        },
        {
          title: 'Export Soft Limit Time',
          code: 'limitctrl_exportsoft_time',
          type: 'number',
          min: '0',
          max: '100',
          unit: 'Sec',
          schema: () => numberValidator().integer().min(0).max(100),
        },
        {
          title: 'Generation Hard Limit Flag',
          code: 'limitctrl_generationhard_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'Generation Hard Limit Level',
          code: 'limitctrl_generationhard_level',
          type: 'number',
          min: '0',
          max: '100',
          unit: '%',
          schema: () => numberValidator().integer().min(0).max(100),
        },
        {
          title: 'Generation Hard Limit Time',
          code: 'limitctrl_generationhard_time',
          type: 'number',
          min: '0',
          max: '100',
          unit: 'Sec',
          schema: () => numberValidator().integer().min(0).max(100),
        },
        {
          title: 'Generation Soft Limit Flag',
          code: 'limitctrl_generationsoft_flag',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
          schema: () => numberValidator(),
        },
        {
          title: 'Generation Soft Limit Level',
          code: 'limitctrl_generationsoft_level',
          type: 'number',
          min: '0',
          max: '100',
          unit: '%',
          schema: () => numberValidator().integer().min(0).max(100),
        },
        {
          title: 'Generation Soft Limit Time',
          code: 'limitctrl_generationsoft_time',
          type: 'number',
          min: '0',
          max: '100',
          unit: 'Sec',
          schema: () => numberValidator().integer().min(0).max(100),
        },
      ],
    },
  ];
};
