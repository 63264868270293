
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import type { NavItem } from 'hems';
export default defineComponent({
  name: 'NavSubItem',
  props: {
    navItem: {
      type: Object as PropType<NavItem>,
      required: true,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const onClick = (e: MouseEvent) => {
      emit('click', e, props.navItem.path);
    };

    return {
      onClick,
    };
  },
});
