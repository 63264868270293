
import { useModelWrapper } from '@hems/util';
import { defineComponent, PropType, reactive } from 'vue';
import { batteryType } from '@hems/component/src/battery/batteryConfig';

export default defineComponent({
  name: 'Battery',
  inheritAttrs: false,
  props: {
    modelValue: [Array, Boolean],
    name: String,
    type: {
      type: String as PropType<'discharge' | 'charge' | 'wait'>,
      required: true,
      default: 'wait',
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      value: useModelWrapper(props, emit),
    });
    return {
      state,
      batteryType,
    };
  },
});
