import type { SettingConfigGroup } from 'hems/common/settingConfig';
import type { SelectorOption } from 'hems';
import type { SettingGroup } from 'hems/device/settings';

type SelectorOptions = {
  energyPolicy: SelectorOption[];
  smtrModlCd?: SelectorOption[];
  smtrModlPvCd?: SelectorOption[];
  modbusConnectionType?: SelectorOption[];
  modbusRtuDev?: SelectorOption[];
  modbusRtuBaudrate?: SelectorOption[];
  modbusRtuParity?: SelectorOption[];
  extctlGwConnection?: SelectorOption[];
};

export function getBasicSettingPvModuleGen3SmartModuleGen2Config(
  t: (key: string | number) => string,
  options: SelectorOptions
): SettingGroup[] {
  return [
    {
      groupTitle: t('device.grid'),
      type: 'table',
      children: [
        {
          title: `${t('control.feedin')} (%)`,
          code: 'pv_feed_in_limit',
          type: 'number',
        },
        {
          title: `${t('control.feedin')} (W)`,
          code: 'pv_feed_in_limit_w',
          type: 'number',
        },
        {
          title: 'Multiple Earthed Neutral(MEN) system',
          code: 'inverter_multiple_earthed_neutral_system',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
      ],
    },
    {
      groupTitle: t('device.external_device'),
      type: 'table',
      children: [
        {
          title: t('control.external_control'),
          code: 'external_control_connection',
          type: 'selector',
          options: options.extctlGwConnection,
        },
        {
          title: t('device.meter_connection'),
          code: 'external_control_meter_load',
          type: 'radio',
          options: [
            { text: t('device.inverter'), value: 0 },
            { text: t('control.gateway'), value: 1 },
          ],
        },
        {
          title: t('device.pv_meter_connection'),
          code: 'external_control_pv_meter_load',
          type: 'radio',
          options: [
            { text: t('device.inverter'), value: 0 },
            { text: t('control.gateway'), value: 1 },
          ],
        },
        {
          title: t('control.tcp_port'),
          code: 'external_control_modbus_tcp_port',
          type: 'number',
        },
        {
          title: t('control.rtu_dev'),
          code: 'external_control_modbus_rtu_dev',
          type: 'selector',
          options: options.modbusRtuDev,
        },
        {
          title: t('control.rtu_baud_rate'),
          code: 'external_control_modbus_rtu_baudrate',
          type: 'selector',
          options: options.modbusRtuBaudrate,
        },
        {
          title: t('control.rtu_parity'),
          code: 'external_control_modbus_rtu_parity',
          type: 'selector',
          options: options.modbusRtuParity,
        },
        {
          title: t('control.rtu_data_bits'),
          code: 'external_control_modbus_rtu_data',
          type: 'number',
        },
        {
          title: t('control.rtu_stop_bits'),
          code: 'external_control_modbus_rtu_stop',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: t('control.energy_policy'),
      type: 'table',
      children: [
        {
          title: t('control.energy_policy'),
          code: 'energy_policy',
          type: 'selector',
          options: options.energyPolicy,
        },
      ],
    },
    {
      groupTitle: t('control.pv_setting'),
      type: 'table',
      children: [
        {
          title: t('device.pv_type'),
          code: 'pv_type',
          type: 'selector',
          options: [
            { text: t('common.select'), value: null },
            { text: t('device.string_inverter'), value: 0 },
            { text: t('device.micro_inverter'), value: 1 },
            { text: t('device.power_optimizers'), value: 2 },
          ],
        },
        {
          title: `${t('device.string_level')} / ${t('device.module_level')}`,
          code: 'pv_capacity_calc_option',
          type: 'radio',
          options: [
            { text: t('device.string_level'), value: 0 },
            { text: t('device.module_level'), value: 1 },
          ],
        },
        {
          title: t('device.pv_string_quantity'),
          code: 'pv_string_count',
          type: 'selector',
          options: [
            { text: t('common.select'), value: null },
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
          ],
        },
        {
          title: `PV-1 ${t('device.power')}`,
          code: 'pv_string_power1',
          type: 'number',
        },
        {
          title: `PV-2 ${t('device.power')}`,
          code: 'pv_string_power2',
          type: 'number',
        },
        {
          title: `PV-3 ${t('device.power')}`,
          code: 'pv_string_power3',
          type: 'number',
        },
        {
          title: `PV-4 ${t('device.power')}`,
          code: 'pv_string_power4',
          type: 'number',
        },
        {
          title: `PV-5 ${t('device.power')}`,
          code: 'pv_string_power5',
          type: 'number',
        },
        {
          title: t('device.module_count'),
          code: 'pv_module_count',
          type: 'number',
        },
        {
          title: t('device.module_power_class'),
          code: 'pv_module_power',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: t('device.battery'),
      type: 'table',
      children: [
        {
          title: t('control.energy_backup'),
          code: 'battery_backup_soc',
          type: 'number',
        },
        {
          title: 'Number of Battery Rack',
          code: 'battery_installed_rack_count',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: t('device.grid_meter'),
      type: 'table',
      children: [
        {
          title: t('device.meter_model'),
          code: 'meter_model',
          type: 'selector',
          options: options.smtrModlCd,
        },
        {
          title: t('device.connection_type'),
          code: 'meter_connection',
          type: 'selector',
          options: options.modbusConnectionType,
        },
        {
          title: t('control.tcp_ip_address'),
          code: 'meter_modbus_tcp_ip',
          type: 'text',
        },
        {
          title: t('control.tcp_port'),
          code: 'meter_modbus_tcp_port',
          type: 'number',
        },
        {
          title: t('control.rtu_dev'),
          code: 'meter_modbus_rtu_dev',
          type: 'selector',
          options: options.modbusRtuDev,
        },
        {
          title: t('control.rtu_baud_rate'),
          code: 'meter_modbus_rtu_baudrate',
          type: 'selector',
          options: options.modbusRtuBaudrate,
        },
        {
          title: t('control.rtu_parity'),
          code: 'meter_modbus_rtu_parity',
          type: 'selector',
          options: options.modbusRtuParity,
        },
        {
          title: t('control.rtu_data_bits'),
          code: 'meter_modbus_rtu_data',
          type: 'number',
        },
        {
          title: t('control.rtu_stop_bits'),
          code: 'meter_modbus_rtu_stop',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: 'Third Party Inverter',
      type: 'table',
      children: [
        {
          title: 'Third Party Inverter',
          code: 'third_party_inverter_exist',
          type: 'toggle',
          options: [
            { text: t('common.enable'), value: 1 },
            { text: t('common.disable'), value: 0 },
          ],
        },
        {
          title: t('device.measurement_type'),
          code: 'pv_connection_type',
          type: 'radio',
          options: [
            { text: t('device.external_ct'), value: 0 },
            { text: t('device.pv_meter'), value: 1 },
            { text: t('common.none'), value: 2 },
          ],
        },
        {
          title: t('device.meter_model'),
          code: 'pv_meter_model',
          type: 'selector',
          options: options.smtrModlPvCd,
        },
        {
          title: t('device.connection_type'),
          code: 'pv_meter_connection',
          type: 'selector',
          options: options.modbusConnectionType,
        },
        {
          title: t('control.tcp_ip_address'),
          code: 'pv_meter_modbus_tcp_ip',
          type: 'text',
        },
        {
          title: t('control.tcp_port'),
          code: 'pv_meter_modbus_tcp_port',
          type: 'number',
        },
        {
          title: t('control.rtu_dev'),
          code: 'pv_meter_modbus_rtu_dev',
          type: 'selector',
          options: options.modbusRtuDev,
        },
        {
          title: t('control.rtu_baud_rate'),
          code: 'pv_meter_modbus_rtu_baudrate',
          type: 'selector',
          options: options.modbusRtuBaudrate,
        },
        {
          title: t('control.rtu_parity'),
          code: 'pv_meter_modbus_rtu_parity',
          type: 'selector',
          options: options.modbusRtuParity,
        },
        {
          title: t('control.rtu_data_bits'),
          code: 'pv_meter_modbus_rtu_data',
          type: 'number',
        },
        {
          title: t('control.rtu_stop_bits'),
          code: 'pv_meter_modbus_rtu_stop',
          type: 'number',
        },
      ],
    },
  ];
}

export function getBasicSettingPvModuleFoxConfig(
  t: (key: string | number) => string,
  options: SelectorOptions
): SettingGroup[] {
  return [
    {
      groupTitle: t('device.grid'),
      type: 'table',
      children: [
        {
          title: `${t('control.feedin')} (%)`,
          code: 'pv_feed_in_limit',
          type: 'number',
        },
        {
          title: `${t('control.feedin')} (W)`,
          code: 'pv_feed_in_limit_w',
          type: 'number',
        },
      ],
    },
    {
      groupTitle: t('control.energy_policy'),
      type: 'table',
      children: [
        {
          title: t('control.energy_policy'),
          code: 'energy_policy',
          type: 'selector',
          options: options.energyPolicy,
        },
      ],
    },
    {
      groupTitle: t('device.battery'),
      type: 'table',
      children: [
        {
          title: t('control.energy_backup'),
          code: 'battery_backup_soc',
          type: 'number',
        },
      ],
    },
  ];
}
