import { CellRendererProps } from 'tui-grid/types/renderer';

class ConnectionStatusRendererV2 {
  private el: HTMLElement;
  private connection: JQuery<HTMLElement>;
  private blindValue: JQuery<HTMLElement>;

  constructor(props: CellRendererProps) {
    this.connection = $('<span class="state_connect"></span>');
    this.blindValue = $('<span class="blind"></span>');
    this.connection.append(this.blindValue);

    this.el = this.connection.get(0);
    this.render(props);
  }

  getElement(): HTMLElement {
    return this.el;
  }

  async render(props: CellRendererProps): Promise<void> {
    const value = props.grid.getValue(props.rowKey, 'connect_status');
    if (value === 'online') {
      this.connection.addClass('online');
      this.blindValue.text('connect');
    } else if (value === 'online-disconnected') {
      this.connection.addClass('online-disconnected');
      this.blindValue.text('disconnect');
    } else if (value === 'offline') {
      this.connection.addClass('offline');
      this.blindValue.text('disconnect');
    }
  }
}

export default ConnectionStatusRendererV2;
