
import { computed, defineComponent, PropType, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { Helper, DateHelper, useLocale } from '@hems/util';
import { Searchbox, HiddenSearchbox, useMessageBox, Calendar, LabelInput } from '@hems/component';
import { useStore } from 'vuex';
import { GroupSettingsSearchOptions } from 'hems/group/setting';
import { MakeFieldNonNullable } from 'hems/common/utils';

export default defineComponent({
  name: 'GroupSettingSearchBox',
  components: {
    VCalendarRange: Calendar.VCalendarRange,
    LabelInput,
    HiddenSearchbox,
    Searchbox,
  },
  props: {
    condition: {
      type: Object as PropType<GroupSettingsSearchOptions>,
      default: () => ({
        date: {
          start: DateHelper.sixDaysAgo(),
          end: DateHelper.endDate(),
        },
        searchFor: null,
      }),
    },
  },
  emits: ['search'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    const store = useStore();
    const langCd = computed(() => store.state.appCtx.langCd);
    const state = reactive({
      searchCondition: getDefaultCondition(props.condition),
    });
    const { locale } = useLocale();

    function getDefaultCondition(
      condition: GroupSettingsSearchOptions
    ): MakeFieldNonNullable<GroupSettingsSearchOptions, 'searchFor' | 'deviceId'> {
      return {
        ...condition,
        searchFor: condition.searchFor ?? '',
        deviceId: condition.deviceId ?? '',
      };
    }

    const validateRangeDate = (value: Calendar.CalendarRangeValue) => {
      const dateCount =
        DateHelper.getDiffDate(DateHelper.getDateToString(value.start), DateHelper.getDateToString(value.end)) + 1;
      if (dateCount > 31) {
        messageBox.alert(t('message.period_not_exceed')).open();
        return false;
      }
      return true;
    };

    const onClickSearch = () => {
      state.searchCondition.searchFor = state.searchCondition.searchFor ? state.searchCondition.searchFor?.trim() : '';
      state.searchCondition.deviceId = state.searchCondition.deviceId ? state.searchCondition.deviceId?.trim() : '';
      emit('search', { ...state.searchCondition });
    };

    return {
      state,
      langCd,
      Helper,
      validateRangeDate,
      onClickSearch,
      locale,
      DateHelper,
    };
  },
});
