import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50cb980d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mark-wrapper" }
const _hoisted_2 = { class: "slot" }
const _hoisted_3 = { class: "mark" }
const _hoisted_4 = { class: "mark-circle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("div", _hoisted_4, null, 512), [
        [_vShow, _ctx.showMark && _ctx.state.changed]
      ])
    ])
  ]))
}