import { AxiosInstance } from 'axios';
import {
  RemoteUpdateListSearchOptions,
  RemoteUpdateListItem,
  SuperRemoteUpdateTargetEssSearchOptions,
  SuperRemoteUpdateTargetEss,
  CheckedGroupName,
  RevertStableResult,
  RemoteUpdateProcessStatus,
  SuperRemoteUpdateDetails,
} from 'hems/group/update';
import { CommonResponseWrapper, ProcessResult, StableDeviceGroupInfo } from 'hems';
import { ProductModelName } from 'hems/device';
import { apiWrapper } from '../../util/helper';
import RemoteUpdateService from './RemoteUpdateService';
import { GroupId } from 'hems/group/types';

export default class RemoteUpdateServiceAdmin extends RemoteUpdateService {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  // 그룹 리스트
  public async getRemoteUpdateGroupList(
    params: Partial<RemoteUpdateListSearchOptions>
  ): Promise<RemoteUpdateListItem[]> {
    const { data } = await this.get<CommonResponseWrapper<RemoteUpdateListItem[]>>(
      apiWrapper.controlApi('/groups/firmware-updates'),
      params
    );
    return data;
  }

  // 그룹 상세 정보
  public async getRemoteUpdateDetails(groupId: number): Promise<SuperRemoteUpdateDetails> {
    const { data } = await this.get<CommonResponseWrapper<SuperRemoteUpdateDetails>>(
      apiWrapper.controlApi(`/groups/firmware-updates/${groupId}/information`)
    );
    return data;
  }

  // 대상 ESS
  public async getTargetEssList<T extends SuperRemoteUpdateTargetEss>(
    params: Partial<SuperRemoteUpdateTargetEssSearchOptions>
  ): Promise<T[]> {
    const { data } = await this.post<CommonResponseWrapper<T[]>>(
      apiWrapper.managerApi('/groups/firmware-updates/devices'),
      {
        ...params,
        grpId: params.grpId ? '' + params.grpId : undefined,
      }
    );
    return data;
  }

  // 그룹명 중복 체크
  public async checkGroupName(groupNm: string): Promise<CheckedGroupName> {
    const { data } = await this.get<CommonResponseWrapper<CheckedGroupName>>(
      apiWrapper.controlApi(`/groups/firmware-updates/${groupNm}/duplications`)
    );
    return data;
  }

  public async getStableGroupByProductModelNm(productModelNm: ProductModelName): Promise<ProcessResult> {
    const { data } = await this.get<CommonResponseWrapper<ProcessResult>>(
      apiWrapper.controlApi('/groups/firmware-updates/stable/summaries'),
      { productModelNm }
    );
    return data;
  }

  // Stable 그룹 정보
  public async getStableGroupInfoByProductModelNm(productModelNm: ProductModelName): Promise<StableDeviceGroupInfo> {
    const { data } = await this.get<CommonResponseWrapper<StableDeviceGroupInfo>>(
      apiWrapper.controlApi('/groups/firmware-updates/stable'),
      { productModelNm }
    );
    return data;
  }

  // Stable 그룹 요약 정보
  public async getStableGroupSummaryByProductModelNm(productModelNm: ProductModelName): Promise<StableDeviceGroupInfo> {
    const { data } = await this.get<CommonResponseWrapper<StableDeviceGroupInfo>>(
      apiWrapper.controlApi('/groups/firmware-updates/stable/summaries'),
      { productModelNm }
    );
    return data;
  }

  // Statble 취소
  public async revertStable(groupId: GroupId): Promise<RevertStableResult> {
    const { data } = await this.put<CommonResponseWrapper<RevertStableResult>>(
      apiWrapper.controlApi(`/groups/firmware-updates/stable/${groupId}/revert`)
    );
    return data;
  }

  // 업데이트 진행 Status 확인 -- TODO: 사용하지 않음 -> 삭제
  public async getRemoteUpdateProcessStatus(groupId: string, ctrlId: number): Promise<RemoteUpdateProcessStatus[]> {
    return await this.get(apiWrapper.adminAPI(`/upt/grp/process/${groupId}`), { grpId: groupId, ctrlId });
  }

  // 업데이트 presigned URL 정보 가져오기
  public async getRemoteUpdatePreSignedUrl(
    productModelNm: ProductModelName,
    fileName: string | null | undefined
  ): Promise<any> {
    const { data } = await this.get<CommonResponseWrapper<any>>(
      apiWrapper.managerApi('/managements/commons/s3/pre-signed'),
      { productModelNm, fileName }
    );
    return data;
  }

  public async putRemoteUpdateFile(fileURL: string | null, file: File | null): Promise<any> {
    if (fileURL == null || file == null) return;

    return await fetch(fileURL, {
      method: 'PUT',
      body: file,
    });
  }

  public async saveRemoteUpdate(groupNm: string, params: FormData): Promise<any> {
    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.controlApi(`/groups/firmware-updates/${groupNm}`),
      params
    );
    return data;
  }

  public async getProcessResult(groupId: GroupId, ctrlId: number): Promise<ProcessResult[]> {
    const { data } = await this.get<CommonResponseWrapper<ProcessResult[]>>(
      apiWrapper.controlApi(`/groups/firmware-updates/${groupId}/progress`),
      { ctrlId }
    );
    return data;
  }
}
