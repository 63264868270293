import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "pack_col" }
const _hoisted_2 = { class: "fm_wrap_group" }
const _hoisted_3 = { class: "fm_wrap re_dep" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "label" }
const _hoisted_7 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Selector = _resolveComponent("Selector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Selector, {
      modelValue: _ctx.state.selected,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.selected) = $event)),
      label: _ctx.$t('device.batt_serial_n', { num: _ctx.num }),
      options: _ctx.options,
      "tooltip-text": _ctx.state.selected === '0' ? _ctx.$t('message.no_serial_number') : ''
    }, null, 8, ["modelValue", "label", "options", "tooltip-text"]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('device.battery_model')), 1),
        _createElementVNode("input", {
          type: "text",
          class: "fm_ipt",
          value: _ctx.data?.model_nm,
          readonly: ""
        }, null, 8, _hoisted_5)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.batModules, (item, idx) => {
        return (_openBlock(), _createElementBlock("label", {
          key: idx,
          class: "fm_wrap re_dep_2"
        }, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('device.battery_module_n', { num: idx + 1 })), 1),
          _createElementVNode("input", {
            type: "text",
            class: "fm_ipt",
            value: item.sn,
            readonly: ""
          }, null, 8, _hoisted_7)
        ]))
      }), 128))
    ], 512), [
      [_vShow, _ctx.showDetails]
    ])
  ]))
}