import { useStore } from 'vuex';
import { computed } from 'vue';
import { ROLE_NAME, RoleCodeValue, RoleNameValue } from '@hems/util/src/constant/role';

export function useRole() {
  const store = useStore();
  const roleName = computed<RoleNameValue>(() => store.state.user.roleNm);
  const roleCode = computed<RoleCodeValue>(() => store.state.user.roleCd);

  const isInstaller = roleName.value === ROLE_NAME.INSTALLER;
  const isUser = roleName.value === ROLE_NAME.USER;
  const isAdminGroup = !isInstaller && !isUser;
  const isAdmin = roleName.value === ROLE_NAME.ADMIN;
  const isDev = roleName.value === ROLE_NAME.DEV;
  const isService = roleName.value === ROLE_NAME.SERVICE;

  return {
    roleName,
    roleCode,
    isInstaller,
    isUser,
    isAdminGroup,
    isAdmin,
    isDev,
    isService,
  };
}
