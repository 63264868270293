
import type { PropType } from 'vue';
import { defineComponent, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useMessageBox, Calendar, Selector } from '@hems/component';
import type { SearchBoxGraphCondition } from '@hems/container/src/searchbox/types';
import { Helper, DateHelper, useLocale } from '@hems/util';
import { DAY_RANGE_31, GRAPH_TERM_UNIT } from '@hems/util/src/constant';

import type { TermUnit } from 'hems';

const { VCalendarRange, VCalendar } = Calendar;

export default defineComponent({
  name: 'GraphSearchBox',
  components: {
    Selector,
    VCalendarRange,
    VCalendar,
  },
  props: {
    condition: {
      type: Object as PropType<SearchBoxGraphCondition>,
      required: true,
    },
    timezone: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  emits: ['search', 'changeTermUnit'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const messageBox = useMessageBox();
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0);
    const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1);
    const searchCondition = ref<SearchBoxGraphCondition>(props.condition);
    const minDate = ref<Date>(getMinDate(props.condition.term_unit));

    const { locale } = useLocale();

    function getMinDate(termUnit: TermUnit) {
      if (termUnit === GRAPH_TERM_UNIT.MINUTE) {
        return new Date(now.getFullYear(), now.getMonth() - 3, now.getDate() + 1);
      }

      return new Date(now.getFullYear() - 1, now.getMonth(), now.getDate() + 1);
    }

    const validateRangeDate = (value: Calendar.CalendarRangeValue) => {
      const dateCount =
        DateHelper.getDiffDate(DateHelper.getDateToString(value.start), DateHelper.getDateToString(value.end)) + 1;
      if (dateCount > DAY_RANGE_31) {
        messageBox.alert(t('message.period_not_exceed')).open();

        return false;
      }

      return true;
    };

    watch(
      () => searchCondition.value.term_unit,
      (term_unit) => {
        emit('changeTermUnit', term_unit);
        minDate.value = getMinDate(term_unit);
        if (term_unit === GRAPH_TERM_UNIT.MINUTE) {
          searchCondition.value.date = today;

          return;
        }
        searchCondition.value.date = {
          start: startDate,
          end: endDate,
        };
      }
    );

    watch(
      () => searchCondition.value.date,
      () => {
        emit('search', { ...searchCondition.value });
      }
    );

    return {
      searchCondition,
      minDate,
      Helper,
      GRAPH_TERM_UNIT,
      locale,
      DateHelper,
      validateRangeDate,
    };
  },
});
