
import { computed, defineComponent, ref } from 'vue';
import Image2 from '../image/Image2.vue';

const defaultTitleAndMessage = {
  500: {
    title: 'Internal Server Error',
    message: 'Please try reloading the page. We’re working hard to fix this issue.',
  },
  501: {
    title: 'Not Implemented',
    message: 'The requested method is not implemented by the server.',
  },
  502: {
    title: 'Bad Gateway',
    message: 'The server encountered a temporary error and could not complete your request.',
  },
  503: {
    title: 'Service Unavailable',
    message: 'The service you requested is not available at this time.',
  },
  504: {
    title: 'Gateway Timeout',
    message: 'The server encountered a temporary error and could not complete your reuqest.',
  },
  400: {
    title: 'Bad Request',
    message: 'Your client has issued a malformed or illegal request.',
  },
  401: {
    title: 'Unathorized',
    message: 'Sorry, you are not authorized to access this page.',
  },
  403: {
    title: 'Forbidden',
    message: 'You don’t have perimission to access on this resource.',
  },
  404: {
    title: 'Not Found',
    message: 'Your requested page was not found on this server.',
  },
  410: {
    title: 'Gone',
    message: 'This localhost page can’t be found. It may have been moved or deleted.',
  },
};

type ErrorType = 4 | 5;
type ErrorStatus = number;
type ErrorTitle = string;
type ErrorMessage = string;
type ErrorCode = string;
type ErrorDescription = string;
type ErrorNotificationInstance = {
  on: boolean;
  type: ErrorType;
  imageName: string;
  imageExtension: string;
  status: ErrorStatus;
  title: ErrorTitle;
  message: ErrorMessage;
  code: ErrorCode;
  description: ErrorDescription;
  resolve?: (value: unknown) => void;
  reject?: (reason?: any) => void;
};
class ErrorNotification {
  private _type: ErrorType = 5;
  private _imageName = 'ic_re_500error_glass_img';
  private _imageExtension = 'svg';
  private _status: ErrorStatus = 500;
  private _title: ErrorTitle = '500 Internal Server Error';
  private _message: ErrorMessage = "Please try reloading the page. We're working hard to fix this issue.";
  private _code: ErrorCode = '';
  private _description: ErrorDescription = '';

  constructor() {
    this.init();
  }

  private init(type: ErrorType = 5) {
    this._type = type;
    this._imageName = type === 5 ? 'ic_re_500error_glass_img' : 'ic_re_400error_glass_img';
    this._imageExtension = 'svg';
    this._status = 500;
    this._title = '500 Internal Server Error';
    this._message = "Please try reloading the page. We're working hard to fix this issue.";
    this._code = '';
    this._description = '';
    return this;
  }

  private getInstance(resolve: (value: unknown) => void, reject: (reason?: any) => void): ErrorNotificationInstance {
    return {
      on: true,
      type: this._type,
      imageName: this._imageName,
      imageExtension: this._imageExtension,
      status: this._status,
      title: this._title,
      message: this._message,
      code: this._code,
      description: this._description,
      resolve,
      reject,
    };
  }

  private setErrorTitleAndMessage(status: ErrorStatus) {
    const self = this;
    switch (status) {
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
      case 400:
      case 401:
      case 403:
      case 404:
      case 410:
        self._title = `${status} ${defaultTitleAndMessage[status].title}`;
        self._message = defaultTitleAndMessage[status].message;
        break;
      default:
        self._title = `500 ${defaultTitleAndMessage[500].title}`;
        self._message = defaultTitleAndMessage[500].message;
        break;
    }
    return this;
  }

  private setDetails(status: ErrorStatus) {
    const self = this;
    self._status = status;
    self.setErrorTitleAndMessage(status);
    return this;
  }

  error5(status: ErrorStatus) {
    this.init(5);
    this.setDetails(status);
    return this.chainSetErrorNotification();
  }
  error4(status: ErrorStatus) {
    this.init(4);
    this.setDetails(status);
    return this.chainSetErrorNotification();
  }

  private chainSetErrorNotification() {
    const self = this;
    return {
      code(code: string) {
        self._code = code;
        return this;
      },
      description(description: string) {
        self._description = description;
        return this;
      },
      open() {
        return new Promise((resolve, reject) => {
          _errorNotificationBox.value.on = false;
          setTimeout(() => {
            _errorNotificationBox.value = self.getInstance(resolve, reject);
          }, 250);
        });
      },
    };
  }
}

const _errorNotificationBox = ref({} as ErrorNotificationInstance);
const btnCloseEl = ref(null as null | HTMLButtonElement);
const close = () => {
  _errorNotificationBox.value.on = false;
};

export const useErrorNotification = (): ErrorNotification => new ErrorNotification();

export default defineComponent({
  name: 'ErrorNotification',
  components: {
    Image2,
  },
  setup() {
    const errorNotificationBox = computed(() => _errorNotificationBox.value);

    const onClose = () => {
      const { resolve } = errorNotificationBox.value;
      if (resolve) resolve(true);
      close();
    };

    return {
      errorNotificationBox,
      onClose,
      btnCloseEl,
    };
  },
});
