
import { defineComponent, PropType, reactive, computed, Prop, ref, onBeforeUnmount, watch, onMounted } from 'vue';
import { useField } from 'vee-validate';
import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';
import { Helper } from '@hems/util';
import { SelectorOption, SelectorValue, TwoDepthSelectorOption } from 'hems';
import _ from 'lodash';

export default defineComponent({
  name: 'TwoDepthSelector',
  components: {
    ErrorMessageLabel,
  },
  props: {
    label: String,
    valueType: {
      type: String as PropType<'string' | 'number' | 'boolean'>,
      default: 'string',
    },
    className: {
      type: String,
      default: 'selecter_2',
    },
    options: {
      type: Array as PropType<TwoDepthSelectorOption[]>,
      required: true,
    },
    modelValue: [String, Number] as Prop<SelectorValue>,
    callback: Function as PropType<(value: string) => void>,
    name: String,
    beforeChange: Function as PropType<(value: SelectorValue, beforeValue: SelectorValue) => boolean>,
    afterChange: Function as PropType<(value: SelectorValue, text: string) => void>,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const selector = ref(null);
    const state = reactive({
      open: false,
      disabled: computed(() => props.disabled),
      focus: false,
      options: computed<TwoDepthSelectorOption[]>(() =>
        props.options.map((opt) => {
          return {
            label: opt.label,
            open: opt.open,
            options: opt.options.map((item) => {
              const value = transformValue(item.value);
              return { ...item, value };
            }),
          };
        })
      ),
    });

    const { value: inputValue, errorMessage } = useField<SelectorValue>(props.name || Helper.getUUID(), undefined, {
      initialValue: transformValue(props.modelValue),
      type: props.valueType,
    });

    function transformValue(value?: SelectorValue): SelectorValue {
      if (value === undefined || value === null) return null;

      if (props.valueType === 'boolean') return Boolean(value);
      if (props.valueType === 'number') return Number(value);

      return value;
    }

    const selected = computed(() =>
      state.options
        .map((opt) => opt.options)
        .flat()
        .find((item) => item.value === inputValue.value)
    );
    const selectedText = computed(() => (selected.value ? selected.value.text : state.options?.[0]?.options[0].text));

    function onClickSelect() {
      if (!state.disabled) state.open = !state.open;
    }

    function onClickOption(value: SelectorOption) {
      if (props.beforeChange) {
        if (props.beforeChange(value.value, inputValue.value)) {
          setValue(value);
        }
      } else {
        setValue(value);
      }
    }
    function onFocus(isFocus: boolean) {
      state.focus = isFocus;
    }

    function setValue(value: SelectorOption) {
      inputValue.value = value.value;
      emit('update:modelValue', value.value, value.text);
      if (props.afterChange) {
        props.afterChange(value.value, value.text);
      }
    }

    watch(
      () => props.modelValue,
      () => {
        if (inputValue.value !== props.modelValue) inputValue.value = props.modelValue || '';
      }
    );

    function onClickOtherLayer(e: MouseEvent) {
      if ((e.target as HTMLElement).parentElement !== selector.value) {
        state.open = false;
      }
    }

    onMounted(() => {
      document.addEventListener('click', onClickOtherLayer);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('click', onClickOtherLayer);
    });

    return {
      state,
      selected,
      selectedText,
      inputValue,
      selector,
      onClickSelect,
      onClickOption,
      onFocus,
      errorMessage,
    };
  },
});
