
import 'tui-grid/dist/tui-grid.css';
import '@vueform/slider/themes/default.css';
import '@hems/component/resources/vendor/css_new/common.css';
import '@hems/component/resources/vendor/css_new/common_style.css';
import '@hems/component/resources/vendor/css_new/main.css';
import '@hems/component/resources/vendor/css_new/contents.css';
import '@hems/component/resources/styles/customer-web-style.scss';
import '@hems/component/resources/vendor/css_new2021/common_new2021.css';
import '@hems/component/resources/vendor/script/selecter/jquery.fs.selecter.css';
import '@hems/component/resources/styles/main.scss';
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';

import type { PropType } from 'vue';
import { defineComponent, reactive, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import { useIdle } from '@vueuse/core';

import { MainTemplate, Header, Navigation, LanguageSelector, LoginUserInfo, IdleTimePopup } from '@hems/component';
import { CommonService } from '@hems/service';
import { AuthHelper, useLocale, useAccessToken } from '@hems/util';

import type { LangCd, NavItem } from 'hems';
export default defineComponent({
  name: 'MainTemplateContainer',
  components: {
    MainTemplate,
    Header,
    Navigation,
    LanguageSelector,
    LoginUserInfo,
    IdleTimePopup,
  },
  props: {
    navigationConfig: {
      type: Array as PropType<NavItem[]>,
      default: () => [],
    },
  },
  emits: ['navigation', 'home', 'afterSetRole'],
  async setup(props, { emit }) {
    const commonService = new CommonService(window.axiosInstance.axios);
    const { accessToken } = useAccessToken();
    const { locale: i18nLocale } = useI18n();
    const store = useStore();
    const { isUS } = useLocale();

    const state = reactive({
      isInit: false,
      langCd: store.state.appCtx.langCd as LangCd,
      popup: false,
    });

    watch(
      () => state.langCd,
      (langCd) => {
        i18nLocale.value = langCd;
        store.dispatch('appCtx/setLangCd', langCd);
        store.dispatch('appCtx/setLocale', langCd);
      }
    );
    const onClickNavigation = (e: MouseEvent, path: string) => {
      emit('navigation', e, path);
    };
    const goHome = () => {
      emit('home');
    };

    if (!accessToken) {
      alert('Please Log in');
      window.location.href = '/';

      return { state };
    }

    const jwtObject = AuthHelper.parseJwt(accessToken);
    if (!jwtObject) {
      alert('Invalid token');
      window.location.href = '/';

      return { state };
    }

    const { idle } = useIdle(60 * 60 * 1000); // 60 min

    watch(idle, (idleValue) => {
      if (idleValue) {
        state.popup = true;
      }
    });

    const onLogout = (e: MouseEvent) => {
      //e.preventDefault();
      state.popup = false;
      AuthHelper.removeAccessToken();
      window.axiosInstance.clearAccessToken();
      location.href = '/';
    };

    const onContinue = () => {
      state.popup = false;
    };

    store
      .dispatch('user/setRole', {
        roleCd: jwtObject.auth_type_cd,
        roleNm: AuthHelper.getRoleNm(jwtObject.auth_type_cd),
      })
      .then(async () => {
        emit('afterSetRole');
        store.dispatch('appCtx/setGoogleApiKey', await commonService.getGoogleAPIKey());
      });

    // 초기화
    window.axiosInstance.setAccessToken(accessToken);
    i18nLocale.value = state.langCd;
    state.isInit = true;

    const getUSNavigationConfig = (navigationConfig: NavItem[]): NavItem[] => {
      const remoteNavItem = navigationConfig.find((nav) => nav.code === 'remote');

      const navigationConfigExcludeRemoteNavItem = navigationConfig.filter(({ code }) => code !== 'remote');

      if (remoteNavItem) {
        remoteNavItem.children = remoteNavItem.children?.filter(
          ({ code }) => code !== 'remote-group-setting' && code !== 'remote-group-control'
        );

        return [remoteNavItem, ...navigationConfigExcludeRemoteNavItem];
      } else {
        return navigationConfigExcludeRemoteNavItem;
      }
    };

    const navigationConfigByLocale = computed<NavItem[]>(() => {
      return isUS ? getUSNavigationConfig(props.navigationConfig) : props.navigationConfig;
    });

    return {
      state,
      onClickNavigation,
      goHome,
      onLogout,
      onContinue,
      navigationConfigByLocale,
    };
  },
  errorCaptured(error, instance, info) {
    console.error('MainTemplate ErrorCaptured', error, instance, info);
    alert('An unknown error has occurred.');
  },
});
