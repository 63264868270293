/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */

// 2022-02-13일 변경. https://developers.google.com/identity/gsi/web/guides/migration?hl=ko
import type { Plugin } from 'vue';

import { PROVIDE_INJECT_KEYS } from '@hems/util/src/constant/provideInjection';

export class Vue3GoogleOAuth2 {
  private _accessToken?: string;
  private _email?: string;
  private _name?: string;

  constructor() {
    // TODO: 타입지정 개선 확인 예정
    (function (doc, tagNm, id) {
      const fjs = doc.getElementsByTagName(tagNm)[0];
      if (doc.getElementById(id)) return;
      const jsEl = doc.createElement(tagNm) as HTMLScriptElement;
      jsEl.id = id;
      jsEl.src = 'https://accounts.google.com/gsi/client';

      fjs.parentNode?.insertBefore(jsEl, fjs);
    })(document, 'script', 'google-apisdk');
  }

  public logout() {
    this._accessToken = undefined;
    this._email = undefined;
    this._name = undefined;
  }

  public getAccessToken() {
    return this._accessToken;
  }
  public getEmail() {
    return this._email;
  }

  public getName() {
    return this._name;
  }

  public isLogin() {
    return !!this._accessToken;
  }

  public setAccessToken(accessToken: string) {
    this._accessToken = accessToken;
  }
  public setEmail(email: string) {
    this._email = email;
  }
  public setName(name: string) {
    this._name = name;
  }
}

const Vue3GoogleOAuth2Plugin: Plugin = {
  install: (app, options) => {
    if (typeof options === 'object') {
      if (!options.clientId) {
        throw new Error('clientId is required');
      }
    } else {
      throw new TypeError('invalid option type. Object type accepted only');
    }

    //Install Vue plugin
    const instance = new Vue3GoogleOAuth2();

    app.config.globalProperties.$googleOAuth = instance;
    app.provide(PROVIDE_INJECT_KEYS.GOOGLE_OAUTH, instance);
  },
};

export default Vue3GoogleOAuth2Plugin;
