import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dd8b902"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "right"
}
const _hoisted_2 = { class: "number" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "info-image" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InformationImage = _resolveComponent("InformationImage")!

  return (_ctx.isConsumptionCt)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.productionValueText), 1),
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.hasPv ? _ctx.$t('common.production') : _ctx.$t('device.consumption')) + " ", 1),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.isConnectThirdParty)
              ? (_openBlock(), _createBlock(_component_InformationImage, {
                  key: 0,
                  src: _ctx.ENERGY_VALUE_IMAGE.INFO_IMAGE_PATH,
                  extension: "svg",
                  title: _ctx.$t('common.production'),
                  message: _ctx.$t('message.sum_qhomecore_thirdparty'),
                  "image-size": _ctx.infoImageSize
                }, null, 8, ["src", "title", "message", "image-size"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}