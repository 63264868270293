
import { Device } from 'hems';
import { DeviceId, SiteId } from 'hems/device';
import { defineComponent, PropType } from 'vue';
export default defineComponent({
  name: 'ModuleGrid',

  props: {
    siteId: Number as PropType<SiteId>,
    deviceId: String as PropType<DeviceId>,
    data: Array as PropType<Device.PvModuleInfo[][]>,
  },
  setup() {
    return {};
  },
});
