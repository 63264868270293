
import { defineComponent, reactive } from 'vue';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';

export default defineComponent({
  name: 'SuspendLoading',
  components: {
    LottieAnimation,
  },
  setup() {
    const baseUrl = process.env.BASE_URL?.substring(1) || '';

    return {
      path: `${baseUrl}resources/lottie/loading.json`,
    };
  },
});
