import type { ACDeviceTypeValue } from '@hems/util/src/constant';
import { ACSystemModelNamePrefixes, AC_DEVICE_TYPE, EMS_PRIORITY_ORDER_MAP } from '@hems/util/src/constant';

import type { DeviceProfileACSystem } from 'hems/device/dashboard/smartmodule/acsys';
import type {
  DeviceInfo,
  PvModuleGen2,
  PvModuleGen3,
  PvModuleFox,
  SmartModuleGen1,
  SmartModule,
  PvModule,
  SmartModuleGen2,
  SmartModuleACSystem,
} from 'hems/device/deviceinfo';
import type { Device } from 'hems/device/siteinfo';
import type { SiteConfiguration } from 'hems/install';

export function isSmartModule(data: Partial<DeviceInfo>): data is Partial<SmartModule> {
  if (isSmartModuleGen1(data) || isSmartModuleGen2(data) || isSmartModuleACSystem(data)) return true;

  return false;
}

export function isPvModule(data: Partial<DeviceInfo>): data is Partial<PvModule> {
  if (isPvModuleGen2(data) || isPvModuleGen3(data) || isPvModuleFox(data)) return true;

  return false;
}

export function isPvModuleGen2(data: Partial<DeviceInfo>): data is PvModuleGen2 {
  if (data.gen_type === 'GEN2') return true;

  return false;
}
export function isPvModuleGen3(data: Partial<DeviceInfo>): data is PvModuleGen3 {
  if (data.gen_type === 'GEN3') return true;

  return false;
}
export function isPvModuleFox(data: Partial<DeviceInfo>): data is PvModuleFox {
  if (data.gen_type === 'FOX_ESS' || data.gen_type === 'FOX_ESS_H3') return true;

  return false;
}
export function isSmartModuleGen1(data: Partial<DeviceInfo>): data is SmartModuleGen1 {
  if (data.gen_type?.startsWith('MLPE_GEN1')) return true;

  return false;
}
export function isSmartModuleGen2(data: Partial<DeviceInfo>): data is Partial<SmartModuleGen2> {
  if (data.gen_type?.startsWith('DC_OPTIMIZER_GEN2')) return true;

  return false;
}
export function isSmartModuleACSystem(data: Partial<DeviceInfo>): data is Partial<SmartModuleACSystem> {
  if (data.gen_type?.startsWith('AC_SYS')) return true;

  return false;
}

export function getACSystemType(
  deviceInfo: Partial<DeviceProfileACSystem>[],
  siteConfiguration: SiteConfiguration
): ACDeviceTypeValue {
  // Multi
  if (deviceInfo.length >= 2) {
    if (siteConfiguration) {
      const { hasPV, hasESS, hasHub, hasThirdParty } = siteConfiguration;
      if (hasThirdParty) {
        if (hasHub) {
          const essList = deviceInfo.filter((item) => item.device_type_cd === AC_DEVICE_TYPE.AC_ESS);
          if (essList.length >= 2) return AC_DEVICE_TYPE.AC_THIRD_PARTY_MULTI_ESS_HUB;
          else return AC_DEVICE_TYPE.AC_THIRD_PARTY_ESS_HUB;
        } else {
          return AC_DEVICE_TYPE.AC_THIRD_PARTY_ESS;
        }
      } else {
        if (hasPV && hasESS && hasHub) return AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS_HUB;
        if (hasPV && hasESS) return AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS;
        if (hasESS) return AC_DEVICE_TYPE.AC_MULTI_ESS;
      }
    }

    return AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS_HUB;
  }
  // Single
  else {
    // 'AC_COMB' (case 1) or 'AC_ESS' (case 8-1)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return deviceInfo[0].device_type_cd!;
  }
}

export const isACSystemModel = (productModelName: string) =>
  ACSystemModelNamePrefixes.some((modelNamePrefix) => productModelName.startsWith(modelNamePrefix));

export const deviceInfoSorter = (deviceA: Device, deviceB: Device): number => {
  if (!deviceA.deviceInfo.ems_type_cd || !deviceB.deviceInfo.ems_type_cd) return 0;

  const priorityDeviceA = EMS_PRIORITY_ORDER_MAP[deviceA.deviceInfo.ems_type_cd] ?? Number.MAX_SAFE_INTEGER;
  const priorityDeviceB = EMS_PRIORITY_ORDER_MAP[deviceB.deviceInfo.ems_type_cd] ?? Number.MAX_SAFE_INTEGER;

  return priorityDeviceA - priorityDeviceB;
};
