import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cfef351a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "table-item",
  class: "tb_fm line"
}
const _hoisted_2 = {
  key: 0,
  class: "table-title"
}
const _hoisted_3 = {
  key: 1,
  class: "table-sub-title"
}
const _hoisted_4 = { class: "tb" }
const _hoisted_5 = ["width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tableTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.tableTitle), 1))
      : _createCommentVNode("", true),
    (_ctx.tableSubTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.tableSubTitle), 1))
      : _createCommentVNode("", true),
    _createElementVNode("table", _hoisted_4, [
      _createElementVNode("caption", {
        class: _normalizeClass({ blind: !_ctx.caption })
      }, _toDisplayString(_ctx.caption), 3),
      _createElementVNode("colgroup", null, [
        _createElementVNode("col", { width: _ctx.titleWidth }, null, 8, _hoisted_5)
      ]),
      _createElementVNode("tbody", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}