import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "is_default" }
const _hoisted_2 = { class: "is_edit" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  type: "submit",
  class: "btn_m btn_c_3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSettingTab = _resolveComponent("MultiSettingTab")!
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_ChangedMark = _resolveComponent("ChangedMark")!
  const _component_SettingTableRow = _resolveComponent("SettingTableRow")!
  const _component_ToggleInput = _resolveComponent("ToggleInput")!
  const _component_SettingTable = _resolveComponent("SettingTable")!
  const _component_Selector = _resolveComponent("Selector")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Form = _resolveComponent("Form")!
  const _component_SettingConfirmPopup = _resolveComponent("SettingConfirmPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MultiSettingTab, {
      settingTargetType: _ctx.settingTargetType,
      "onUpdate:settingTargetType": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settingTargetType) = $event)),
      deviceTarget: _ctx.deviceTarget,
      "onUpdate:deviceTarget": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deviceTarget) = $event)),
      "selector-options": _ctx.engineeringDeviceOption
    }, null, 8, ["settingTargetType", "deviceTarget", "selector-options"]),
    (_ctx.isInit)
      ? (_openBlock(), _createBlock(_component_Form, {
          key: 0,
          "validation-schema": _ctx.schema,
          onSubmit: _ctx.onSave
        }, {
          default: _withCtx(({ handleReset }) => [
            _createVNode(_component_Accordion, {
              title: _ctx.$t('common.engineer_setting_info'),
              active: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTable, {
                  class: "engineer-tb",
                  "table-title": "Inverter"
                }, {
                  default: _withCtx(() => [
                    (_ctx.isEngineeringSettingField('inverter_max_output_power'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 0,
                          title: "Inverter limit",
                          description: "3600 ~ 20000",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "inverter_max_output_power",
                              "target-value": _ctx.state.data.inverter_max_output_power,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.inverter_max_output_power,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.data.inverter_max_output_power) = $event)),
                                  name: "inverter_max_output_power",
                                  type: "number",
                                  min: "3600",
                                  max: "20000",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('inverter_grid_target_frequency'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 1,
                          title: "Grid target frequency",
                          description: "50.00 ~ 60.00Hz",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "inverter_grid_target_frequency",
                              "target-value": _ctx.state.data.inverter_grid_target_frequency,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.inverter_grid_target_frequency,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.data.inverter_grid_target_frequency) = $event)),
                                  name: "inverter_grid_target_frequency",
                                  type: "number",
                                  min: "50",
                                  max: "60",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('inverter_external_generator_mode'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 2,
                          title: "External generator mode",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "inverter_external_generator_mode",
                              "target-value": _ctx.state.data.inverter_external_generator_mode,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.inverter_external_generator_mode,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.data.inverter_external_generator_mode) = $event)),
                                  name: "inverter_external_generator_mode",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('inverter_permit_service_mode_select'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 3,
                          title: "Permit service mode select flag",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "inverter_permit_service_mode_select",
                              "target-value": _ctx.state.data.inverter_permit_service_mode_select,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.inverter_permit_service_mode_select,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.data.inverter_permit_service_mode_select) = $event)),
                                  name: "inverter_permit_service_mode_select",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('inverter_enter_service_randomized_delay'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 4,
                          title: "Enter service randomized delay flag",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "inverter_enter_service_randomized_delay",
                              "target-value": _ctx.state.data.inverter_enter_service_randomized_delay,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.inverter_enter_service_randomized_delay,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.data.inverter_enter_service_randomized_delay) = $event)),
                                  name: "inverter_enter_service_randomized_delay",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('inverter_import_power_limit'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 5,
                          title: "Import Power Limit",
                          description: `0 ~ ${_ctx.state.data.inverter_max_output_power ?? 3600}W`,
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "inverter_import_power_limit",
                              "target-value": _ctx.state.data.inverter_import_power_limit,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.inverter_import_power_limit,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.data.inverter_import_power_limit) = $event)),
                                  name: "inverter_import_power_limit",
                                  type: "number",
                                  min: "0",
                                  max: _ctx.state.data.inverter_max_output_power ?? 3600,
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "max", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["description"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('inverter_export_power_limit'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 6,
                          title: "Export Power Limit",
                          description: `0 ~ ${_ctx.state.data.inverter_max_output_power ?? 3600}W`,
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "inverter_export_power_limit",
                              "target-value": _ctx.state.data.inverter_export_power_limit,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.inverter_export_power_limit,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.data.inverter_export_power_limit) = $event)),
                                  name: "inverter_export_power_limit",
                                  type: "number",
                                  min: "0",
                                  max: _ctx.state.data.inverter_max_output_power ?? 3600,
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "max", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["description"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('battery_charge_limit'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 7,
                          title: "Battery Charge Limit",
                          description: "0 ~ 65535W",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "battery_charge_limit",
                              "target-value": _ctx.state.data.battery_charge_limit,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.battery_charge_limit,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.data.battery_charge_limit) = $event)),
                                  name: "battery_charge_limit",
                                  type: "number",
                                  min: "0",
                                  max: "65535",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('battery_discharge_limit'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 8,
                          title: "Battery Discharge Limit",
                          description: "0 ~ 65535W",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "battery_discharge_limit",
                              "target-value": _ctx.state.data.battery_discharge_limit,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.battery_discharge_limit,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.data.battery_discharge_limit) = $event)),
                                  name: "battery_discharge_limit",
                                  type: "number",
                                  min: "0",
                                  max: "65535",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_SettingTable, {
                  class: "engineer-tb",
                  "table-title": _ctx.$t('device.battery_user_soc')
                }, {
                  default: _withCtx(() => [
                    (_ctx.isEngineeringSettingField('battery_user_soc_min'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 0,
                          title: _ctx.$t('common.min'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "battery_user_soc_min",
                              "target-value": _ctx.state.data.battery_user_soc_min,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.battery_user_soc_min,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.state.data.battery_user_soc_min) = $event)),
                                  name: "battery_user_soc_min",
                                  type: "number",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('battery_user_soc_max'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 1,
                          title: _ctx.$t('common.max'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "battery_user_soc_max",
                              "target-value": _ctx.state.data.battery_user_soc_max,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.battery_user_soc_max,
                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.state.data.battery_user_soc_max) = $event)),
                                  name: "battery_user_soc_max",
                                  type: "number",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["table-title"]),
                (_ctx.isEngineeringSettingField('ul1741_flag'))
                  ? (_openBlock(), _createBlock(_component_SettingTable, {
                      key: 0,
                      class: "engineer-tb",
                      "table-title": "UL1741"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isEngineeringSettingField('ul1741_flag'))
                          ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                              key: 0,
                              title: "UL1741SB Flag",
                              "is-indent": ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ChangedMark, {
                                  "show-mark": _ctx.state.editable,
                                  "value-key": "ul1741_flag",
                                  "target-value": _ctx.state.data.ul1741_flag,
                                  "changed-value-set": _ctx.state.changedValueSet,
                                  onChange: _ctx.onChange
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ToggleInput, {
                                      modelValue: _ctx.state.data.ul1741_flag,
                                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.state.data.ul1741_flag) = $event)),
                                      name: "ul1741_flag",
                                      toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                      readonly: !_ctx.state.editable
                                    }, null, 8, ["modelValue", "toggle", "readonly"])
                                  ]),
                                  _: 1
                                }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.isEngineeringSettingField('pcs_debug_mode'))
                  ? (_openBlock(), _createBlock(_component_SettingTable, {
                      key: 1,
                      class: "engineer-tb",
                      "table-title": 'PCS Debug Mode'
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isEngineeringSettingField('pcs_debug_mode'))
                          ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                              key: 0,
                              title: _ctx.$t('device.pcs_debug_mode'),
                              "is-indent": ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ChangedMark, {
                                  "show-mark": _ctx.state.editable,
                                  "value-key": "pcs_debug_mode",
                                  "target-value": _ctx.state.data.pcs_debug_mode,
                                  "changed-value-set": _ctx.state.changedValueSet,
                                  onChange: _ctx.onChange
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ToggleInput, {
                                      modelValue: _ctx.state.data.pcs_debug_mode,
                                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.state.data.pcs_debug_mode) = $event)),
                                      name: "pcs_debug_mode",
                                      toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                      readonly: !_ctx.state.editable
                                    }, null, 8, ["modelValue", "toggle", "readonly"])
                                  ]),
                                  _: 1
                                }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                              ]),
                              _: 1
                            }, 8, ["title"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_SettingTable, {
                  class: "engineer-tb",
                  "table-title": _ctx.$t('common.hysteresis')
                }, {
                  default: _withCtx(() => [
                    (_ctx.isEngineeringSettingField('battery_hysteresis_low'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 0,
                          title: _ctx.$t('common.low'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "battery_hysteresis_low",
                              "target-value": _ctx.state.data.battery_hysteresis_low,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.battery_hysteresis_low,
                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.state.data.battery_hysteresis_low) = $event)),
                                  name: "battery_hysteresis_low",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: [
                { text: '10%', value: 10 },
                { text: '15%', value: 15 },
                { text: '20%', value: 20 },
                { text: '25%', value: 25 },
              ]
                                }, null, 8, ["modelValue", "disabled"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('battery_hysteresis_high'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 1,
                          title: _ctx.$t('common.high'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "battery_hysteresis_high",
                              "target-value": _ctx.state.data.battery_hysteresis_high,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.battery_hysteresis_high,
                                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.state.data.battery_hysteresis_high) = $event)),
                                  name: "battery_hysteresis_high",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: [
                { text: '90%', value: 90 },
                { text: '85%', value: 85 },
                { text: '80%', value: 80 },
              ]
                                }, null, 8, ["modelValue", "disabled"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["table-title"]),
                _createVNode(_component_SettingTable, {
                  class: "engineer-tb",
                  "table-title": "HUB External Device Control"
                }, {
                  default: _withCtx(() => [
                    (_ctx.isEngineeringSettingField('hub_external_inverter_flag'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 0,
                          title: "External Inverter",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "hub_external_inverter_flag",
                              "target-value": _ctx.state.data.hub_external_inverter_flag,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.hub_external_inverter_flag,
                                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.state.data.hub_external_inverter_flag) = $event)),
                                  name: "hub_external_inverter_flag",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (
            _ctx.state.data.hub_external_inverter_flag && _ctx.isEngineeringSettingField('hub_external_inverter_nameplate_power')
          )
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 1,
                          title: "External Inverter Nameplate Power",
                          description: "0 ~ 19200W",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "hub_external_inverter_nameplate_power",
                              "target-value": _ctx.state.data.hub_external_inverter_nameplate_power,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.hub_external_inverter_nameplate_power,
                                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.state.data.hub_external_inverter_nameplate_power) = $event)),
                                  name: "hub_external_inverter_nameplate_power",
                                  min: "0",
                                  max: "19200",
                                  type: "number",
                                  readonly: !_ctx.state.editable || _ctx.state.data.hub_external_inverter_flag === 0
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('hub_external_inverter2_flag'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 2,
                          title: "External Inverter 2",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "hub_external_inverter2_flag",
                              "target-value": _ctx.state.data.hub_external_inverter2_flag,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.hub_external_inverter2_flag,
                                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.state.data.hub_external_inverter2_flag) = $event)),
                                  name: "hub_external_inverter2_flag",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (
            _ctx.state.data.hub_external_inverter2_flag &&
            _ctx.isEngineeringSettingField('hub_external_inverter2_nameplate_power')
          )
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 3,
                          title: "External Inverter 2 Nameplate Power",
                          description: "0 ~ 19200W",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "hub_external_inverter2_nameplate_power",
                              "target-value": _ctx.state.data.hub_external_inverter2_nameplate_power,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.hub_external_inverter2_nameplate_power,
                                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.state.data.hub_external_inverter2_nameplate_power) = $event)),
                                  name: "hub_external_inverter2_nameplate_power",
                                  min: "0",
                                  max: "19200",
                                  type: "number",
                                  readonly: !_ctx.state.editable || _ctx.state.data.hub_external_inverter_flag === 0
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('hub_generator_flag'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 4,
                          title: "Generator Installed",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "hub_generator_flag",
                              "target-value": _ctx.state.data.hub_generator_flag,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.hub_generator_flag,
                                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.state.data.hub_generator_flag) = $event)),
                                  name: "hub_generator_flag",
                                  toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('hub_generator_remote_on'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 5,
                          title: "Generator Remote On",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "hub_generator_remote_on",
                              "target-value": _ctx.state.data.hub_generator_remote_on,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_RadioGroup, {
                                  modelValue: _ctx.state.data.hub_generator_remote_on,
                                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.state.data.hub_generator_remote_on) = $event)),
                                  name: "hub_generator_remote_on",
                                  options: [
                { value: 0, text: 'Impossible' },
                { value: 1, text: 'Possible' },
              ],
                                  readonly: !_ctx.state.editable || _ctx.state.data.hub_generator_flag === 0,
                                  "value-type": "number"
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('hub_generator_nameplate_power'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 6,
                          title: "Generator Nameplate Power",
                          description: "0 ~ 15360W",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "hub_generator_nameplate_power",
                              "target-value": _ctx.state.data.hub_generator_nameplate_power,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.hub_generator_nameplate_power,
                                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.state.data.hub_generator_nameplate_power) = $event)),
                                  name: "hub_generator_nameplate_power",
                                  min: "0",
                                  max: "15360W",
                                  type: "number",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('hub_msp_current_limit'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 7,
                          title: "MSP Current Limit",
                          description: "-200 ~ 200W",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "hub_msp_current_limit",
                              "target-value": _ctx.state.data.hub_msp_current_limit,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BasicInput, {
                                  modelValue: _ctx.state.data.hub_msp_current_limit,
                                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.state.data.hub_msp_current_limit) = $event)),
                                  name: "hub_msp_current_limit",
                                  min: "-200",
                                  max: "200",
                                  type: "number",
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('hub_external_ct_installed_point'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 8,
                          title: "External CT Installed Point",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "hub_external_ct_installed_point",
                              "target-value": _ctx.state.data.hub_external_ct_installed_point,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.hub_external_ct_installed_point,
                                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.state.data.hub_external_ct_installed_point) = $event)),
                                  name: "hub_external_ct_installed_point",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.extCtInstalledPointInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (_ctx.isEngineeringSettingField('auto_fw_update_flag'))
                  ? (_openBlock(), _createBlock(_component_SettingTable, {
                      key: 2,
                      class: "engineer-tb",
                      "table-title": _ctx.$t('control.update_setting')
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isEngineeringSettingField('auto_fw_update_flag'))
                          ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                              key: 0,
                              title: _ctx.$t('control.auto_firm_update'),
                              "is-indent": ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ChangedMark, {
                                  "show-mark": _ctx.state.editable,
                                  "value-key": "auto_fw_update_flag",
                                  "target-value": _ctx.state.data.auto_fw_update_flag,
                                  "changed-value-set": _ctx.state.changedValueSet,
                                  onChange: _ctx.onChange
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ToggleInput, {
                                      modelValue: _ctx.state.data.auto_fw_update_flag,
                                      "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.state.data.auto_fw_update_flag) = $event)),
                                      name: "auto_fw_update_flag",
                                      toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                                      readonly: !_ctx.state.editable
                                    }, null, 8, ["modelValue", "toggle", "readonly"])
                                  ]),
                                  _: 1
                                }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                              ]),
                              _: 1
                            }, 8, ["title"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["table-title"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_SettingTable, {
                  class: "engineer-tb",
                  "table-title": _ctx.$t('device.debug_info')
                }, {
                  default: _withCtx(() => [
                    (_ctx.isEngineeringSettingField('log_level_process_mgr'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 0,
                          title: _ctx.$t('control.process_mgr'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_process_mgr",
                              "target-value": _ctx.state.data.log_level_process_mgr,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_process_mgr,
                                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.state.data.log_level_process_mgr) = $event)),
                                  name: "log_level_process_mgr",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_system_log'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 1,
                          title: _ctx.$t('control.system_log'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_system_log",
                              "target-value": _ctx.state.data.log_level_system_log,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_system_log,
                                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.state.data.log_level_system_log) = $event)),
                                  name: "log_level_system_log",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_fota'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 2,
                          title: _ctx.$t('control.fota'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_fota",
                              "target-value": _ctx.state.data.log_level_fota,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_fota,
                                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.state.data.log_level_fota) = $event)),
                                  name: "log_level_fota",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_power_control'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 3,
                          title: _ctx.$t('control.pw_control'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_power_control",
                              "target-value": _ctx.state.data.log_level_power_control,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_power_control,
                                  "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.state.data.log_level_power_control) = $event)),
                                  name: "log_level_power_control",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_algorithm_mgr'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 4,
                          title: _ctx.$t('control.algo_mgr'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_algorithm_mgr",
                              "target-value": _ctx.state.data.log_level_algorithm_mgr,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_algorithm_mgr,
                                  "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.state.data.log_level_algorithm_mgr) = $event)),
                                  name: "log_level_algorithm_mgr",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_ess_mgr'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 5,
                          title: _ctx.$t('control.ess_mgr'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_ess_mgr",
                              "target-value": _ctx.state.data.log_level_ess_mgr,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_ess_mgr,
                                  "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.state.data.log_level_ess_mgr) = $event)),
                                  name: "log_level_ess_mgr",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_dc_source_mgr'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 6,
                          title: _ctx.$t('control.dc_src_mgr'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_dc_source_mgr",
                              "target-value": _ctx.state.data.log_level_dc_source_mgr,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_dc_source_mgr,
                                  "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.state.data.log_level_dc_source_mgr) = $event)),
                                  name: "log_level_dc_source_mgr",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_cloud_mgr'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 7,
                          title: _ctx.$t('control.cloud_mgr'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_cloud_mgr",
                              "target-value": _ctx.state.data.log_level_cloud_mgr,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_cloud_mgr,
                                  "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.state.data.log_level_cloud_mgr) = $event)),
                                  name: "log_level_cloud_mgr",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_meter_mgr'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 8,
                          title: _ctx.$t('control.meter_mgr'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_meter_mgr",
                              "target-value": _ctx.state.data.log_level_meter_mgr,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_meter_mgr,
                                  "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.state.data.log_level_meter_mgr) = $event)),
                                  name: "log_level_meter_mgr",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_gateway_mgr'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 9,
                          title: _ctx.$t('control.gw_mgr'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_gateway_mgr",
                              "target-value": _ctx.state.data.log_level_gateway_mgr,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_gateway_mgr,
                                  "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.state.data.log_level_gateway_mgr) = $event)),
                                  name: "log_level_gateway_mgr",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_data_accumulator'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 10,
                          title: _ctx.$t('control.data_mgr'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_data_accumulator",
                              "target-value": _ctx.state.data.log_level_data_accumulator,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_data_accumulator,
                                  "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.state.data.log_level_data_accumulator) = $event)),
                                  name: "log_level_data_accumulator",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_db_mgr'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 11,
                          title: _ctx.$t('control.db_mgr'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_db_mgr",
                              "target-value": _ctx.state.data.log_level_db_mgr,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_db_mgr,
                                  "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.state.data.log_level_db_mgr) = $event)),
                                  name: "log_level_db_mgr",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_web_engine'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 12,
                          title: _ctx.$t('control.web_engine'),
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_web_engine",
                              "target-value": _ctx.state.data.log_level_web_engine,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_web_engine,
                                  "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.state.data.log_level_web_engine) = $event)),
                                  name: "log_level_web_engine",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_gqa'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 13,
                          title: "GQA",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_gqa",
                              "target-value": _ctx.state.data.log_level_gqa,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_gqa,
                                  "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.state.data.log_level_gqa) = $event)),
                                  name: "log_level_gqa",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_mlpe_mgr'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 14,
                          title: "MLPE Mgr",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_mlpe_mgr",
                              "target-value": _ctx.state.data.log_level_mlpe_mgr,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_mlpe_mgr,
                                  "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.state.data.log_level_mlpe_mgr) = $event)),
                                  name: "log_level_mlpe_mgr",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_sys_mgr'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 15,
                          title: "Sys Mgr",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_sys_mgr",
                              "target-value": _ctx.state.data.log_level_sys_mgr,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_sys_mgr,
                                  "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.state.data.log_level_sys_mgr) = $event)),
                                  name: "log_level_sys_mgr",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_power_scheduler'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 16,
                          title: "Power Scheduler",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_power_scheduler",
                              "target-value": _ctx.state.data.log_level_power_scheduler,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_power_scheduler,
                                  "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.state.data.log_level_power_scheduler) = $event)),
                                  name: "log_level_power_scheduler",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_echonet_mgr'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 17,
                          title: "Echonet Mgr",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_echonet_mgr",
                              "target-value": _ctx.state.data.log_level_power_scheduler,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_power_scheduler,
                                  "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.state.data.log_level_power_scheduler) = $event)),
                                  name: "log_level_power_scheduler",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_energy_link'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 18,
                          title: "Energy Link",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_energy_link",
                              "target-value": _ctx.state.data.log_level_energy_link,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_energy_link,
                                  "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.state.data.log_level_energy_link) = $event)),
                                  name: "log_level_energy_link",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_diag_monitor'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 19,
                          title: "Diag Monitor",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_diag_monitor",
                              "target-value": _ctx.state.data.log_level_diag_monitor,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_diag_monitor,
                                  "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.state.data.log_level_diag_monitor) = $event)),
                                  name: "log_level_diag_monitor",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_csip_mgr'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 20,
                          title: "Csip Mgr",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_csip_mgr",
                              "target-value": _ctx.state.data.log_level_csip_mgr,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_csip_mgr,
                                  "onUpdate:modelValue": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.state.data.log_level_csip_mgr) = $event)),
                                  name: "log_level_csip_mgr",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_energy_optimizer'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 21,
                          title: "Energy Optimizer",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_energy_optimizer",
                              "target-value": _ctx.state.data.log_level_energy_optimizer,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_energy_optimizer,
                                  "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.state.data.log_level_energy_optimizer) = $event)),
                                  name: "log_level_energy_optimizer",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('log_level_ipc_bridge'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 22,
                          title: "IPC Bridge",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "log_level_ipc_bridge",
                              "target-value": _ctx.state.data.log_level_ipc_bridge,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.log_level_ipc_bridge,
                                  "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.state.data.log_level_ipc_bridge) = $event)),
                                  name: "log_level_ipc_bridge",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('debug_mode_mqtt'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 23,
                          title: "MQTT",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "debug_mode_mqtt",
                              "target-value": _ctx.state.data.debug_mode_mqtt,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Selector, {
                                  modelValue: _ctx.state.data.debug_mode_mqtt,
                                  "onUpdate:modelValue": _cache[50] || (_cache[50] = ($event: any) => ((_ctx.state.data.debug_mode_mqtt) = $event)),
                                  name: "debug_mode_mqtt",
                                  "value-type": "number",
                                  disabled: !_ctx.state.editable,
                                  options: _ctx.selectorOptionsState.debugInfo
                                }, null, 8, ["modelValue", "disabled", "options"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isEngineeringSettingField('debug_mode_modbus'))
                      ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                          key: 24,
                          title: "Modbus",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "debug_mode_modbus",
                              "target-value": _ctx.state.data.debug_mode_modbus,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.debug_mode_modbus,
                                  "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.state.data.debug_mode_modbus) = $event)),
                                  name: "debug_mode_modbus",
                                  toggle: [
                [1, _ctx.$t('common.on')],
                [0, _ctx.$t('common.off')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["table-title"]),
                (_ctx.isEngineeringSettingField('site_configuration_disable_mode'))
                  ? (_openBlock(), _createBlock(_component_SettingTable, {
                      key: 3,
                      class: "engineer-tb",
                      "table-title": 'Site Configuration'
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SettingTableRow, {
                          title: "Disable Mode",
                          "is-indent": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChangedMark, {
                              "show-mark": _ctx.state.editable,
                              "value-key": "site_configuration_disable_mode",
                              "target-value": _ctx.state.data.site_configuration_disable_mode,
                              "changed-value-set": _ctx.state.changedValueSet,
                              onChange: _ctx.onChange
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ToggleInput, {
                                  modelValue: _ctx.state.data.site_configuration_disable_mode,
                                  "onUpdate:modelValue": _cache[52] || (_cache[52] = ($event: any) => ((_ctx.state.data.site_configuration_disable_mode) = $event)),
                                  name: "site_configuration_disable_mode",
                                  toggle: [
                [1, _ctx.$t('common.on')],
                [0, _ctx.$t('common.off')],
              ],
                                  readonly: !_ctx.state.editable
                                }, null, 8, ["modelValue", "toggle", "readonly"])
                              ]),
                              _: 1
                            }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title"]),
            _createElementVNode("div", {
              class: _normalizeClass(["btn_wrap_m", { editorble: _ctx.state.editable }])
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn_m btn_c_2",
                  onClick: _cache[53] || (_cache[53] = 
//@ts-ignore
(...args) => (_ctx.goToList && _ctx.goToList(...args)))
                }, _toDisplayString(_ctx.$t('message.go_to_list')), 1),
                (!_ctx.hideEdit)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn_m btn_c_3",
                      onClick: _cache[54] || (_cache[54] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
                    }, _toDisplayString(_ctx.$t('common.edit')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn_m btn_c_2",
                  onClick: ($event: any) => (_ctx.onCancel(handleReset))
                }, _toDisplayString(_ctx.$t('common.cancel')), 9, _hoisted_3),
                _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.$t('common.apply')), 1)
              ])
            ], 2)
          ]),
          _: 1
        }, 8, ["validation-schema", "onSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.state.confirmPopup.on)
      ? (_openBlock(), _createBlock(_component_SettingConfirmPopup, {
          key: 1,
          title: "Engineer Setting",
          data: _ctx.state.confirmPopup.data,
          onConfirm: _ctx.onConfirm,
          onCancel: _cache[55] || (_cache[55] = () => (_ctx.state.confirmPopup.on = false))
        }, null, 8, ["data", "onConfirm"]))
      : _createCommentVNode("", true)
  ], 64))
}