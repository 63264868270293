import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Exceptions } from '@hems/util';
const { AxiosErrorException } = Exceptions;

export class Service {
  protected axios: AxiosInstance;
  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  protected async download(
    url: string,
    params?: Record<string, any>,
    defaultFileName?: string
  ): Promise<{ data: Blob; filename: string }> {
    try {
      const { data, headers } = await this.axios.get<Blob>(url, {
        params,
        headers: {
          Accept: '*/*',
        },
        responseType: 'blob',
      });

      const items: string[] = headers['content-disposition']?.split(';');
      const filename = items
        .map<{ key: string; value: string }>((item) => {
          const temp = item.split('=');
          return { key: temp?.[0]?.trim(), value: temp?.[1]?.replace(/[" ]/gi, '') };
        })
        .find((item) => item.key === 'filename');

      return { data, filename: filename?.value || defaultFileName || 'unknown' };
    } catch (e) {
      throw new AxiosErrorException(url, params, e);
    }
  }

  protected async get<T = any>(url: string, params?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await this.axios.get<T>(url, { params, ...config });
      return data;
    } catch (e) {
      throw new AxiosErrorException(url, params, e);
    }
  }

  protected async put<T = any>(url: string, params?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await this.axios.put<T>(url, params, config);
      return data;
    } catch (e) {
      throw new AxiosErrorException(url, params, e);
    }
  }

  protected async post<T = any>(url: string, params?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await this.axios.post<T>(url, params, config);
      return data;
    } catch (e) {
      throw new AxiosErrorException(url, params, e);
    }
  }

  protected async delete<T = any>(url: string, params?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await this.axios.delete<T>(url, { params, ...config });
      return data;
    } catch (e) {
      throw new AxiosErrorException(url, params, e);
    }
  }
}
