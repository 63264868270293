
import { useStore } from 'vuex';
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useACDeviceType } from '@hems/util';
import { AC_DEVICE_TYPE } from '@hems/util/src/constant';

export default defineComponent({
  name: 'SubTab',
  props: {
    multiTabName: {
      type: String,
      default: '',
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    // const router = useRouter();
    const siteId = computed(() => route.query.siteId);
    const deviceId = computed(() => route.query.deviceId);
    const statusId = computed(() => route.query.statusId);
    const genType = computed(() => store.state.device.gen_type);
    const tabname = computed(() => route.path.replace('/remote/monitoring/details/setting/', '').split('/')[0]);
    const roleNm = computed(() => store.state.user.roleNm);
    //TODO 추후에 추가될 가능성이 있어서 주석 처리
    // let multiSettingTabName = ref(props.multiTabName);
    // watch(
    //   () => props.multiTabName,
    //   () => {
    //     multiSettingTabName.value = props.multiTabName;

    //     if (multiSettingTabName.value === 'device') {
    //       router.push({
    //         name: 'BasicSettings',
    //         query: { deviceId: deviceId.value, siteId: siteId.value },
    //       });
    //     } else if (multiSettingTabName.value === 'site') {
    //       router.push({
    //         name: 'Installation',
    //         query: { deviceId: deviceId.value, siteId: siteId.value },
    //       });
    //     }
    //   }
    // );

    const { ACDeviceType } = useACDeviceType();
    const isCase1 = ACDeviceType.value === AC_DEVICE_TYPE.AC_COMBINER;

    return {
      siteId,
      deviceId,
      statusId,
      genType,
      tabname,
      roleNm,
      // multiSettingTabName,
      isCase1,
    };
  },
});
