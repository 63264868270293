
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { EngineerSettings } from 'hems/device/settings/smartmodule/acsys';
import EngineerSettingsACSystem from '../../../_shared/engineer/EngineerSettingsACSystem.vue';
import type { SiteId } from 'hems/device';

export default defineComponent({
  name: 'EngineerSettingsSmartModuleACSystemContainer',
  components: {
    EngineerSettingsACSystem,
  },
  props: {
    data: {
      type: Object as PropType<EngineerSettings>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    isConnection: {
      type: Boolean,
      required: true,
    },
    isHcore: {
      type: Boolean,
      default: false,
    },
    isQcore: {
      type: Boolean,
      default: false,
    },
    isAcCoupled: {
      type: Boolean,
      default: false,
    },
    siteId: {
      type: Number as PropType<SiteId>,
      default: null,
    },
  },
  emits: ['goToList', 'save', 'cancel', 'edit'],
  async setup(props, { emit }) {
    function onSave(params: Partial<EngineerSettings>, callback: (isOk: boolean) => void) {
      emit('save', params, callback);
    }
    function onEdit(copyData: EngineerSettings) {
      emit('edit', copyData);
    }

    return {
      onSave,
      onEdit,
    };
  },
});
