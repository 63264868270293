
import { computed, defineComponent, PropType, reactive, ref } from 'vue';
import { useModelWrapper, Constant } from '@hems/util';
import { LangCd } from 'hems';
import { gsap } from 'gsap';
import { LANGUAGE } from '@hems/util/src/constant';

export default defineComponent({
  name: 'LanguageSelector',
  props: {
    modelValue: {
      type: String as PropType<LangCd>,
      default: LANGUAGE.EN,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const lenLayer = ref(null);
    const languageEl = ref<null | HTMLDivElement>(null);
    const { languages } = Constant;

    const state = reactive({
      language: useModelWrapper<LangCd>(props, emit),
      selected: computed(() => languages.find((item) => item.value === props.modelValue)),
      options: computed(() => languages.filter((item) => item.value !== props.modelValue)),
      dropbox: {
        onLenDrop: false,
        onLenLayer: false,
        show: false,
      },
    });

    const onSelectLanguage = (selectedLanguage: { text: string; value: LangCd }) => {
      state.language = selectedLanguage.value;
      closeDropBox();
    };

    const toggleLocaleDropbox = () => {
      if (state.dropbox.show) {
        closeDropBox();
        return;
      }
      showDropbox();
    };

    function clickOtherLayer(e: MouseEvent) {
      const target = e.target as HTMLElement;
      if (target.closest('#language-selector') !== languageEl.value) closeDropBox();
    }

    const showDropbox = () => {
      window.addEventListener('click', clickOtherLayer);
      state.dropbox.show = true;
      state.dropbox.onLenDrop = true;
      setTimeout(() => {
        state.dropbox.onLenLayer = true;
      }, 100);

      const height = 40 * (languages.length - 1) + 20;
      gsap.to([lenLayer.value] as gsap.TweenTarget, { height: `${height}px`, duration: 0.3 });
    };

    const closeDropBox = () => {
      window.removeEventListener('click', clickOtherLayer);
      state.dropbox.show = false;
      state.dropbox.onLenLayer = false;
      setTimeout(() => {
        state.dropbox.onLenDrop = false;
      }, 100);

      gsap.to([lenLayer.value] as gsap.TweenTarget, { height: '0', duration: 0.3 });
    };

    return {
      state,
      onSelectLanguage,
      toggleLocaleDropbox,
      closeDropBox,
      lenLayer,
      languageEl,
    };
  },
});
