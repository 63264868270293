
import { defineComponent, onMounted, PropType, reactive, ref, Ref, watch, computed } from 'vue';
import Grid, { GridOptions } from 'tui-grid';
import { GridEventListener, GridEventName, OptColumn, OptRow, SortStateResetOption } from 'tui-grid/types/options';
import _ from 'lodash';

// const presetTheme = ['default', 'striped', 'clean'];
// const presetLanguage = ['en', 'ko'];

export default defineComponent({
  name: 'ToastGrid',
  props: {
    data: {
      type: [Array] as PropType<OptRow[]>,
      default: () => [],
    },
    target: {
      type: String,
    },
    columns: {
      type: Array as PropType<OptColumn[]>,
      required: true,
    },
    sortState: {
      type: Object as PropType<SortStateResetOption>,
    },
    options: {
      type: Object as PropType<Partial<GridOptions>>,
    },
    columnName: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    allColumn: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    checkAll: {
      type: Boolean,
    },
  },
  emits: ['instance'],
  setup(props, { emit, attrs }) {
    let instance: Grid | null = null;
    const state = reactive({
      show: false,
      column: props.columns as PropType<OptColumn[]>,
      columnName: computed(() => props.columnName),
      target: computed(() => props.target),
      homeState: false,
      siteState: false,
      checkAll: computed(() => props.checkAll),
      allColumn: computed(() => props.allColumn),
    });
    const tuiGrid: Ref<HTMLElement | null> = ref(null);
    const listener = attrs.events as Record<GridEventName, GridEventListener>;

    function createChart() {
      if (!tuiGrid.value) return;
      Grid.applyTheme('clean');
      instance = new Grid(
        _.merge(
          {
            contextMenu: null,
            el: tuiGrid.value,
            columns: props.columns,
            data: props.data,
            rowHeight: 'auto',
            minRowHeight: 50,
            // useClientSort: false,
          },
          props.options
        )
      );
      // instance.setColumnValues()
      addEventListeners();

      emit('instance', instance);
    }

    function addEventListeners() {
      if (!listener) return;
      for (const _eventName of Object.keys(listener)) {
        const eventName = _eventName as GridEventName;
        if (typeof listener[eventName] === 'function') {
          instance?.on(eventName, listener[eventName]);
        }
      }
    }

    watch(
      () => props.data,
      () => {
        instance?.resetData(_.cloneDeep(props.data) || [], {
          sortState: props.sortState,
        });
      }
    );

    watch(
      () => [state.target, state.columnName, state.checkAll, state.allColumn],
      () => {
        if (state.target) {
          if (typeof state.target === 'string') {
            if (state.columnName?.includes(state.target)) {
              instance?.showColumn(state.target);
            } else {
              instance?.hideColumn(state.target);
            }
          }
        }
        if (state.checkAll === true) {
          state.columnName === state.allColumn;
          for (let i = 0; i < state.allColumn.length; i++) {
            instance?.showColumn(state.allColumn[i]);
          }
        } else if (state.checkAll === false) {
          state.columnName === state.allColumn;
          for (let i = 0; i < state.allColumn.length; i++) {
            instance?.hideColumn(state.allColumn[i]);
          }
        }
      }
    );

    onMounted(() => {
      createChart();
    });

    return {
      state,
      tuiGrid,
    };
  },
});
