
import { defineComponent, reactive, PropType } from 'vue';
import { SettingGroup } from 'hems/device/settings';
import { Accordion } from '@hems/component';
export default defineComponent({
  name: 'SettingConfirmPopup',
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Object as PropType<SettingGroup[]>,
      default: () => [] as SettingGroup[],
    },
  },
  components: {
    Accordion,
  },
  emits: ['cancel', 'confirm'],
  setup(props, { emit }) {
    const state = reactive({});

    function onCancel() {
      emit('cancel');
    }

    function onConfirm() {
      emit('confirm');
    }

    return {
      state,
      onCancel,
      onConfirm,
      entries: Object.entries,
    };
  },
});
