
import type { PropType } from 'vue';
import { defineComponent, ref, watch } from 'vue';

import {
  getChartTemplateAndData,
  getXAxisCategories,
  updateChartData,
} from '@hems/container/src/forms/device/statistics/_shared';
import {
  getACCombinerConfig,
  getBatteryConfig,
  getEnergyMinuteConfig,
  getInverterConfig,
  getMeterConfig,
  getPowerConfig,
  getPvConfig,
} from '@hems/container/src/forms/device/statistics/_shared/filterConfig';
import {
  getDefaultMinuteKeys,
  getMinuteKeysByGenType,
} from '@hems/container/src/forms/device/statistics/_shared/graphKeys';
import DeviceLineGraph from '@hems/container/src/highcharts/spline/DeviceLineGraph.vue';
import { useGenType, useACDeviceType, useRole } from '@hems/util';
import { AC_DEVICE_TYPE, GEN_TYPE, GRAPH_GROUP_CODE, GRAPH_Y_AXIS_UNIT } from '@hems/util/src/constant';
import { calculatePowerFactor } from '@hems/util/src/helper/dashboardHelper';

import type { FilterGroupMinute, GroupCode, SimpleSeries, StatisticsData } from 'hems/device/statistics/common';

export default defineComponent({
  name: 'StatisticsMinuteContainer',
  components: {
    DeviceLineGraph,
  },
  props: {
    data: {
      type: Array as PropType<StatisticsData[]>,
      default: () => [],
    },
    isAcCoupled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const xAxisCategories = ref<string[]>([]);
    const power = ref<SimpleSeries[]>([]);
    const energy = ref<SimpleSeries[]>([]);
    const ACCombiner = ref<SimpleSeries[]>([]);
    const pv = ref<SimpleSeries[]>([]);
    const inverter = ref<SimpleSeries[]>([]);
    const meter = ref<SimpleSeries[]>([]);
    const battery = ref<SimpleSeries[]>([]);

    const { genType, isACSystem } = useGenType();
    const { ACDeviceType, hasACCombiner, isCase1 } = useACDeviceType();
    const isShowACCombinerGraph = isACSystem && hasACCombiner;
    const isFoxH3 = genType.value === GEN_TYPE.FOX_ESS_H3;

    const { isAdminGroup, roleName } = useRole();

    const getGraphKeys = () => {
      if (genType.value) {
        return getMinuteKeysByGenType({
          genType: genType.value,
          roleName: roleName.value,
          ACDeviceType: ACDeviceType.value,
          isACCoupled: props.isAcCoupled,
        });
      }

      return getDefaultMinuteKeys();
    };

    const graphKeys = getGraphKeys();

    const getMinuteGraphConfig = () => {
      if (genType.value) {
        return [
          getPowerConfig(graphKeys.power, genType.value),
          getEnergyMinuteConfig(graphKeys.energy, genType.value),
          getACCombinerConfig(graphKeys.ACCombiner),
          getPvConfig(graphKeys.pv, genType.value),
          getInverterConfig(graphKeys.inverter, genType.value),
          getMeterConfig(graphKeys.meter, genType.value),
          getBatteryConfig(graphKeys.battery, genType.value),
        ];
      }

      return [];
    };

    const { chartDataTemplate, chartData } = getChartTemplateAndData<FilterGroupMinute>(getMinuteGraphConfig());

    const getFilterList = (filterGroup: GroupCode) => chartDataTemplate[filterGroup]?.filterList;

    const getFilterGroupName = (filterGroup: GroupCode) => chartDataTemplate[filterGroup]?.filterGroupName ?? '';

    watch(
      () => props.data,
      (data) => {
        xAxisCategories.value = getXAxisCategories(data);
        updateChartData(chartData, data, {
          power: graphKeys.power,
          energy: graphKeys.energy,
          ACCombiner: graphKeys.ACCombiner,
          pv: graphKeys.pv,
          inverter: graphKeys.inverter,
          meter: graphKeys.meter,
          battery: graphKeys.battery,
        });
        power.value = chartData[GRAPH_GROUP_CODE.POWER];
        energy.value = chartData[GRAPH_GROUP_CODE.ENERGY];
        ACCombiner.value = chartData[GRAPH_GROUP_CODE.AC_COMBINER];
        pv.value = chartData[GRAPH_GROUP_CODE.PV];
        inverter.value = chartData[GRAPH_GROUP_CODE.INVERTER];
        meter.value = isACSystem
          ? getACMeterChartData(chartData[GRAPH_GROUP_CODE.METER])
          : chartData[GRAPH_GROUP_CODE.METER];
        battery.value = chartData[GRAPH_GROUP_CODE.BATTERY];
      }
    );

    const calculateGraphPowerFactor = (
      meterActivePowerList: (number | null)[],
      meterReactivePowerList: (number | null)[]
    ) => {
      return meterActivePowerList.map((meterActivePower, index) => {
        const meterReactivePower = meterReactivePowerList[index];
        if (meterActivePower === null || meterReactivePower === null) {
          return null;
        }

        return calculatePowerFactor(meterActivePower, meterReactivePower);
      });
    };

    const getACMeterChartData = (meterDataList: SimpleSeries[]) => {
      const meterActivePowerList = meterDataList.find((meterData) => meterData.id === 'meter_active_pw')?.data ?? [];
      const meterReactivePowerList =
        meterDataList.find((meterData) => meterData.id === 'meter_reactive_pw')?.data ?? [];

      return meterDataList.map((meterData) => {
        if (meterData.id === 'meter_pwfactor') {
          return { ...meterData, data: calculateGraphPowerFactor(meterActivePowerList, meterReactivePowerList) };
        }

        return meterData;
      });
    };

    return {
      power,
      energy,
      ACCombiner,
      pv,
      inverter,
      meter,
      battery,
      xAxisCategories,
      AC_DEVICE_TYPE,
      GEN_TYPE,
      isShowACCombinerGraph,
      isFoxH3,
      ACDeviceType,
      GRAPH_GROUP_CODE,
      GRAPH_Y_AXIS_UNIT,
      getFilterList,
      getFilterGroupName,
      isCase1,
      isACSystem,
      isAdminGroup,
    };
  },
});
