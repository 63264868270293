
import { defineComponent, computed } from 'vue';

import { Image2 } from '@hems/component';
import {
  convertOperationModeToNumberString,
  getOperationModeText,
} from '@hems/container/src/energyflow/flow/EnergyFlowFunctions';
import { useGenType } from '@hems/util';
import { GEN_TYPE } from '@hems/util/src/constant';

export default defineComponent({
  name: 'OperationModeInfo',
  components: { Image2 },
  props: {
    emsOperationMode: {
      type: String,
      required: true,
    },
    hasEss: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['onClickOperationMode'],
  setup(props) {
    const { isACSystem } = useGenType();
    const operationMode = computed(() => convertOperationModeToNumberString(props.emsOperationMode) ?? null);
    const operationModeText = computed(() => getOperationModeText(props.hasEss));

    return {
      operationMode,
      operationModeText,
      isACSystem,
      GEN_TYPE,
    };
  },
});
