
import { useModelWrapper } from '@hems/util';
import { computed, defineComponent, Prop, PropType, reactive, watch } from 'vue';
import { CalendarModeType, CalendarValueType } from './';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'VCalendarWrapper',
  props: {
    open: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    modelValue: {
      type: [Date, Object] as PropType<CalendarValueType | null>,
      required: true,
    },
    isRange: Boolean as Prop<boolean>,
    timezone: String as Prop<string>,
    mode: {
      type: String as PropType<CalendarModeType>,
      default: 'date',
    },
    locale: String,
    minDate: Date as Prop<Date>,
    maxDate: Date as Prop<Date>,
    validate: Function as Prop<(date: CalendarValueType | null, beforeDate: CalendarValueType | null) => boolean>,
    applyMode: {
      type: String as PropType<'button' | 'picker'>,
      default: 'button',
    },
    isShowPermanentButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'close', 'permanent'],
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      open: useModelWrapper<boolean>(props, emit, 'open'),
      value: props.modelValue,
      locale: computed(() => store.state.appCtx.langCd || 'en'),
      attributes: [
        {
          highlight: {
            start: {
              fillMode: 'solid',
            },
            base: {
              fillMode: 'solid',
            },
            end: {
              fillMode: 'solid',
            },

            style: {
              backgroundColor: '#00adef',
            },
          },
        },
      ],
      modelConfig: {
        start: {
          timeAdjust: '00:00:00',
        },
        end: {
          timeAdjust: '23:00:00',
        },
      },
    });

    const close = () => {
      emit('close');
    };
    const apply = () => {
      emit('update:modelValue', state.value);
      close();
    };

    const ok = () => {
      if (props.validate) {
        if (props.validate(state.value, props.modelValue)) {
          apply();
        }
        return;
      }
      apply();
    };

    const applyPermanent = () => {
      state.value = null;
      apply();
      emit('permanent', true);
    };

    watch(
      () => props.open,
      () => {
        if (props.open) state.value = props.modelValue;
      }
    );

    watch(
      () => state.value,
      () => {
        if (props.applyMode === 'picker') {
          ok();
        }
      }
    );

    return {
      state,
      ok,
      close,
      applyPermanent,
    };
  },
});
