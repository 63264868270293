
import { useModelWrapper } from '@hems/util';
import {
  computed,
  ComputedRef,
  defineComponent,
  Prop,
  PropType,
  reactive,
  Ref,
  ref,
  watch,
  WritableComputedRef,
  onMounted,
} from 'vue';
import { useMessageBox } from '@hems/component';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ImageUploader',
  props: {
    name: String,
    editable: {
      type: Boolean,
      default: true,
    },
    modelValue: File as Prop<File | null>,
    accept: Array as PropType<string[]>,
    blobImg: Blob as PropType<Blob | null>,
  },
  setup(props, { emit }) {
    const messageBox = useMessageBox();
    const { t } = useI18n();
    const state = reactive<{
      file: WritableComputedRef<File | null>;
      editable: ComputedRef<boolean>;
      imageUrl: string | null;
      savedImageUrl: Blob | null;
    }>({
      file: useModelWrapper<File>(props, emit),
      editable: computed(() => props.editable),
      imageUrl: props.modelValue ? URL.createObjectURL(props.modelValue) : null,
      savedImageUrl: props.blobImg || null,
    });
    const imageInput: Ref<HTMLInputElement | null> = ref(null);

    onMounted(() => {
      createImageObjectURL(props.blobImg);
    });

    watch(
      () => props.blobImg,
      () => {
        createImageObjectURL(props.blobImg);
      }
    );

    function createImageObjectURL(image?: Blob | null) {
      if (!image) {
        state.imageUrl = null;
        return;
      }
      state.imageUrl = URL.createObjectURL(image);
    }

    function onChangeImages() {
      if (!imageInput.value) return;
      const file = imageInput.value.files?.[0];
      if (!file) return;

      if (props.accept && !props.accept.includes(file?.type)) {
        clear();
        messageBox.alert(t('message.not_allowed_file')).open();
        return;
      }
      state.file = file;
      state.imageUrl = URL.createObjectURL(file);
    }
    function onClickImageUpload() {
      imageInput.value?.click();
    }
    function clear() {
      state.file = null;
      state.imageUrl = null;
      if (!imageInput.value) return;

      imageInput.value.value = '';
      imageInput.value.files = null;
    }

    return {
      state,
      imageInput,
      onClickImageUpload,
      onChangeImages,
      clear,
    };
  },
});
