
import { defineComponent } from 'vue';
import { ScrollTop } from '@hems/component';

export default defineComponent({
  name: 'WidePageTemplate',
  components: {
    ScrollTop,
  },
});
