
import type { PropType } from 'vue';
import { computed, defineComponent, reactive, watch } from 'vue';

import { Accordion } from '@hems/component';
import { Image2 } from '@hems/component';

import type { Role } from 'hems';
export default defineComponent({
  name: 'GuideWrapper',
  components: {
    Accordion,
    Image2,
  },
  props: {
    subtitle: {
      type: String as PropType<string>,
    },
    hardwareData: {
      type: Object,
    },
    softwareData: {
      type: Object,
    },
    modelValue: {
      type: String as PropType<string>,
    },
    role: {
      type: String as PropType<Role>,
    },
  },
  emits: ['edit', 'create', 'delete'],
  async setup(props, { emit }) {
    const state = reactive({
      subtitle: props.subtitle as string | undefined,
      isAllowedEdit: ['admin', 'dev'].includes(props.role ?? ''),
    });

    watch(
      () => props.subtitle,
      (subtitle) => {
        state.subtitle = subtitle;
      }
    );
    const hardwareDataList = computed(() =>
      props?.hardwareData?.map((item: any) => ({
        ...item,
      }))
    );

    const softwareDataList = computed(() =>
      props?.softwareData?.map((item: any) => ({
        ...item,
      }))
    );

    // 카테고리 분류
    const quickGuideList = computed(() => hardwareDataList.value?.filter((v: any) => v.categoryName == 'Quick Guide'));
    const quickGuideSubArr = computed(() => quickGuideList.value?.map((v: any) => v.filterName));
    const quickGuideSubUqArr = computed(() =>
      quickGuideSubArr.value
        ?.filter((element: string, index: number) => {
          return quickGuideSubArr.value.indexOf(element) === index;
        })
        .sort()
    );

    const installationManualList = computed(() =>
      hardwareDataList.value?.filter((v: any) => v.categoryName == 'Installation Manual')
    );
    const installationManualSubArr = computed(() => installationManualList.value?.map((v: any) => v.filterName));
    const installationManualSubUqArr = computed(() =>
      installationManualSubArr.value
        ?.filter((element: string, index: number) => {
          return installationManualSubArr.value.indexOf(element) === index;
        })
        .sort()
    );

    const specSheetList = computed(() => hardwareDataList.value?.filter((v: any) => v.categoryName == 'Spec Sheet'));
    const specSheetSubArr = computed(() => specSheetList.value?.map((v: any) => v.filterName));
    const specSheetSubUqArr = computed(() =>
      specSheetSubArr.value
        ?.filter((element: string, index: number) => {
          return specSheetSubArr.value.indexOf(element) === index;
        })
        .sort()
    );

    const userManualList = computed(() => hardwareDataList.value?.filter((v: any) => v.categoryName == 'User Manual'));
    const userManualSubArr = computed(() => userManualList.value?.map((v: any) => v.filterName));
    const userManualSubUqArr = computed(() =>
      userManualSubArr.value
        ?.filter((element: string, index: number) => {
          return userManualSubArr.value.indexOf(element) === index;
        })
        .sort()
    );

    const InstallerAppGuideList = computed(() =>
      softwareDataList.value?.filter((v: any) => v.categoryName == 'Installer App Guide')
    );
    const InstallerAppGuideSubArr = computed(() => InstallerAppGuideList.value?.map((v: any) => v.filterName));
    const InstallerAppGuideSubUqArr = computed(() =>
      InstallerAppGuideSubArr.value
        ?.filter((element: string, index: number) => {
          return InstallerAppGuideSubArr.value.indexOf(element) === index;
        })
        .sort()
    );

    const InstallerWebGuideList = computed(() =>
      softwareDataList.value?.filter((v: any) => v.categoryName == 'Installer Web Guide')
    );
    const InstallerWebGuideSubArr = computed(() => InstallerWebGuideList.value?.map((v: any) => v.filterName));
    const InstallerWebGuideSubUqArr = computed(() =>
      InstallerWebGuideSubArr.value
        ?.filter((element: string, index: any) => {
          return InstallerWebGuideSubArr.value.indexOf(element) === index;
        })
        .sort()
    );

    const onCreate = () => {
      emit('create');
    };
    const onEdit = (id: number) => {
      emit('edit', id);
    };
    const onDelete = (id: number) => {
      emit('delete', id);
    };

    return {
      state,
      hardwareDataList,
      quickGuideList,
      quickGuideSubUqArr,
      installationManualList,
      installationManualSubUqArr,
      specSheetList,
      specSheetSubUqArr,
      userManualList,
      userManualSubUqArr,
      InstallerAppGuideList,
      InstallerAppGuideSubUqArr,
      InstallerWebGuideList,
      InstallerWebGuideSubUqArr,
      onCreate,
      onEdit,
      onDelete,
    };
  },
});
