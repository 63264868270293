
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SettingTableRow',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    isIndent: {
      type: Boolean,
      default: false,
    },
    indentDepth: {
      type: Number,
      default: 1,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isMultiValues: Boolean,
  },
});
