
import { defineComponent, PropType, reactive } from 'vue';
import Selector from '@hems/component/src/selectors/Selector/Selector.vue';
import { useModelWrapper } from '@hems/util';
export default defineComponent({
  name: 'ListSize',
  components: {
    Selector,
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    size: {
      type: Array as PropType<number[]>,
      default: () => [10, 20, 50],
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const state = reactive({
      value: useModelWrapper<number>(props, emit),
      size: props.size.map((item) => ({ text: '' + item, value: item })),
    });

    const onChange = () => {
      emit('change');
    };

    return {
      state,
      onChange,
    };
  },
});
