export const ROLE_TYPE = {
  ENGINEER: 'Engineer Page',
  SERVICE: 'Service Page',
} as const;

export const DEVICE_STATUS = {
  INVALID_SERIAL: '0',
  REGISTERED_SERIAL: '1',
  NOT_REGISTERED_SERIAL: '2',
} as const;

export const UNIQUE_PASSWORD_EMS_VERSION = 'R300001';
