import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pop_tit"
}
const _hoisted_2 = { class: "btn_wrap_m mt50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupWrapper = _resolveComponent("PopupWrapper")!

  return (_openBlock(), _createBlock(_component_PopupWrapper, {
    class: "ty_5",
    style: {"opacity":"1","left":"50%","margin-left":"-440px","top":"60.5px"}
  }, {
    default: _withCtx(() => [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          type: "button",
          class: "btn_m btn_c_2 btn_w_2",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, _toDisplayString(_ctx.cancelText || 'Cancel'), 1),
        _createElementVNode("button", {
          type: "button",
          class: "btn_m btn_c_3 btn_w_2",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)))
        }, _toDisplayString(_ctx.okText || 'OK'), 1)
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "close_btn_2",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, _toDisplayString(_ctx.$t('common.close')), 1)
    ]),
    _: 3
  }))
}