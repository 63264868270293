
import { defineComponent, PropType, reactive, computed } from 'vue';
import { LabelInput, Searchbox, TwoDepthSelector } from '@hems/component';
import { SupportSearchBoxOptions } from 'hems/support';
import { CommonService } from '@hems/service';
import { useI18n } from 'vue-i18n';
import { Helper } from '@hems/util';
import _ from 'lodash';
import { MakeFieldNonNullable } from 'hems/common/utils';

export default defineComponent({
  name: 'SupportSearchBox',
  components: {
    Searchbox,
    LabelInput,
    // TwoDepthSelector,
  },
  props: {
    condition: {
      type: Object as PropType<SupportSearchBoxOptions>,
      default: () => ({
        category: null,
        searchFor: null,
      }),
    },
  },
  emits: ['search'],
  async setup(props, { emit }) {
    const commonService = new CommonService(window.axiosInstance.axios);
    const { t } = useI18n();
    const state = reactive<{
      searchCondition: MakeFieldNonNullable<SupportSearchBoxOptions, 'searchFor'>;
      locationSet: Record<string, string>[];
    }>({
      searchCondition: { ...props.condition, searchFor: props.condition.searchFor ?? '' },
      locationSet: [],
    });

    const { CNTRY_CD } = await commonService.getCodesByGroupCode([
      {
        grpCd: 'CNTRY_CD',
      },
    ]);

    function onSearch() {
      if (state.searchCondition) {
        state.searchCondition.searchFor = state.searchCondition.searchFor?.trim();
      }
      emit('search', { ...state.searchCondition });
    }

    // 이중 필터링 목록으로 수정 필요
    const guideFilteringList = computed(() =>
      Helper.addSelectorOptionAtFirst(
        CNTRY_CD.map((item) => Helper.codeNameToSelectorOption(item, t)),
        { text: t('common.all'), value: '' }
      )
    );

    let selectorOptionsState = reactive<{
      locations: { label: string | null; open: boolean; options: { text: string; value: string }[] }[];
    }>({
      locations: [] as any,
    });
    // Location 선택 Option 생성
    state.locationSet.push({ location: '1', locationId: '2', rooms: '3' });
    selectorOptionsState.locations = [{ label: null, open: true, options: [{ text: 'Show All', value: '2' }] }];
    const locations = _.uniqWith(state.locationSet, _.isEqual);
    for (const location of locations) {
      const subCategoryOptions = [];
      for (const room of location.rooms) {
        subCategoryOptions.push({ text: 'battery', value: room });
      }

      selectorOptionsState.locations.push({ label: location.location, open: false, options: subCategoryOptions });
    }

    return {
      state,
      onSearch,
      guideFilteringList,
      selectorOptionsState,
    };
  },
});
