import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "code_wrap" }
const _hoisted_2 = { class: "btn_wrap_m flex" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "is_default" }
const _hoisted_6 = { class: "ir_b ico_list" }
const _hoisted_7 = { class: "ir_b ico_edit" }
const _hoisted_8 = { class: "is_edit" }
const _hoisted_9 = {
  type: "submit",
  class: "btn_i_2 ty_2 btn_c_3",
  style: {"padding-top":"9px"}
}
const _hoisted_10 = { class: "is_default" }
const _hoisted_11 = { class: "is_edit" }
const _hoisted_12 = {
  type: "submit",
  class: "btn_m btn_c_3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Selector = _resolveComponent("Selector")!
  const _component_ToggleInput = _resolveComponent("ToggleInput")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_ChangedMark = _resolveComponent("ChangedMark")!
  const _component_SettingTableRow = _resolveComponent("SettingTableRow")!
  const _component_SettingTable = _resolveComponent("SettingTable")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Form = _resolveComponent("Form")!
  const _component_SettingConfirmPopup = _resolveComponent("SettingConfirmPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.state.isInit)
      ? (_openBlock(), _createBlock(_component_Form, {
          key: 0,
          "validation-schema": _ctx.state.schema,
          onSubmit: _ctx.onSave
        }, {
          default: _withCtx(({ error }) => [
            _createTextVNode(_toDisplayString(error) + " ", 1),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_Selector, {
                modelValue: _ctx.state.data.grid_code,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.data.grid_code) = $event)),
                "class-name": "selecter_3",
                "value-type": "number",
                label: _ctx.$t('device.grid_code'),
                options: _ctx.state.gridCodeOptions,
                disabled: !_ctx.state.editable,
                "after-change": _ctx.onChangeGridCode
              }, null, 8, ["modelValue", "label", "options", "disabled", "after-change"])
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn_m btn_w btn_c_2 cap",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.expandAll = !_ctx.state.expandAll))
                }, _toDisplayString(_ctx.state.expandAll ? _ctx.$t(`common.fold_all`) : _ctx.$t(`common.expand_all`)), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass(["btn_wrap_m", { editorble: _ctx.state.editable }]),
                  style: {"margin-top":"0"}
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn_i_2 btn_c_3",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToList && _ctx.goToList(...args)))
                    }, [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('message.go_to_list')), 1)
                    ]),
                    (!_ctx.hideEdit)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          type: "button",
                          class: "btn_i_2 ty_2 btn_c_4",
                          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
                        }, [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('common.edit')), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn_i_2 btn_c_2",
                      style: {"padding-top":"9px"},
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
                    }, _toDisplayString(_ctx.$t('common.cancel')), 1),
                    _createElementVNode("button", _hoisted_9, _toDisplayString(_ctx.$t('common.apply')), 1)
                  ])
                ], 2)
              ])
            ]),
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.advancedSettingConfig, (group, idx) => {
                return (_openBlock(), _createBlock(_component_Accordion, {
                  key: idx,
                  title: group.groupTitle,
                  active: _ctx.state.expandAll
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SettingTable, { "title-width": "35%" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.children, (item) => {
                          return (_openBlock(), _createBlock(_component_SettingTableRow, {
                            key: item.code,
                            title: item.title,
                            description: _ctx.getRangeDescription(item)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ChangedMark, {
                                "show-mark": _ctx.state.editable,
                                "value-key": item.code,
                                "target-value": _ctx.state.data[item.code],
                                "changed-value-set": _ctx.state.changedValueSet,
                                onChange: _ctx.onChange
                              }, {
                                default: _withCtx(() => [
                                  (item.type === 'toggle')
                                    ? (_openBlock(), _createBlock(_component_ToggleInput, {
                                        key: 0,
                                        modelValue: _ctx.state.data[item.code],
                                        "onUpdate:modelValue": ($event: any) => ((_ctx.state.data[item.code]) = $event),
                                        name: item.code,
                                        toggle: [
                  [1, _ctx.$t(`common.enable`)],
                  [0, _ctx.$t(`common.disable`)],
                ],
                                        readonly: !_ctx.state.editable
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "toggle", "readonly"]))
                                    : (item.type === 'selector')
                                      ? (_openBlock(), _createBlock(_component_Selector, {
                                          key: 1,
                                          modelValue: _ctx.state.data[item.code],
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.state.data[item.code]) = $event),
                                          name: item.code,
                                          "value-type": 
                  typeof _ctx.state.data[item.code] === 'boolean'
                    ? 'boolean'
                    : typeof _ctx.state.data[item.code] === 'number'
                    ? 'number'
                    : 'string'
                ,
                                          options: item.options,
                                          disabled: !_ctx.state.editable
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "value-type", "options", "disabled"]))
                                      : (item.type === 'radio')
                                        ? (_openBlock(), _createBlock(_component_RadioGroup, {
                                            key: 2,
                                            modelValue: _ctx.state.data[item.code],
                                            "onUpdate:modelValue": ($event: any) => ((_ctx.state.data[item.code]) = $event),
                                            name: item.code,
                                            options: item.options,
                                            readonly: !_ctx.state.editable,
                                            "max-column-num": item.maxColumnNum ?? 6
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "options", "readonly", "max-column-num"]))
                                        : (item.type === 'text' || item.type === 'number')
                                          ? (_openBlock(), _createBlock(_component_BasicInput, {
                                              key: 3,
                                              modelValue: _ctx.state.data[item.code],
                                              "onUpdate:modelValue": ($event: any) => ((_ctx.state.data[item.code]) = $event),
                                              type: "number",
                                              name: item.code,
                                              readonly: !_ctx.state.editable
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "readonly"]))
                                          : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["show-mark", "value-key", "target-value", "changed-value-set", "onChange"])
                            ]),
                            _: 2
                          }, 1032, ["title", "description"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["title", "active"]))
              }), 128))
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["btn_wrap_m", { editorble: _ctx.state.editable }])
            }, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn_m btn_c_2",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.goToList && _ctx.goToList(...args)))
                }, _toDisplayString(_ctx.$t('message.go_to_list')), 1),
                (!_ctx.hideEdit)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn_m btn_c_3",
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
                    }, _toDisplayString(_ctx.$t('common.edit')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn_m btn_c_2",
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
                }, _toDisplayString(_ctx.$t('common.cancel')), 1),
                _createElementVNode("button", _hoisted_12, _toDisplayString(_ctx.$t('common.apply')), 1)
              ])
            ], 2)
          ]),
          _: 1
        }, 8, ["validation-schema", "onSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.state.confirmPopup.on)
      ? (_openBlock(), _createBlock(_component_SettingConfirmPopup, {
          key: 1,
          title: _ctx.$t(`control.advanced_sett`),
          data: _ctx.state.confirmPopup.data,
          onConfirm: _ctx.onConfirm,
          onCancel: _cache[8] || (_cache[8] = () => (_ctx.state.confirmPopup.on = false))
        }, null, 8, ["title", "data", "onConfirm"]))
      : _createCommentVNode("", true)
  ], 64))
}