/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited. 
*/
import type { ValueType } from 'hems/common/utils';

export const GRAPH_TYPE = {
  CATEGORY: 'CATEGORY',
  INTEGRATED: 'INTEGRATED',
} as const;

export type GraphTypeValue = ValueType<typeof GRAPH_TYPE>;

export const GRAPH_TERM_UNIT = {
  MINUTE: 'TH',
  HOUR: 'TD',
  MONTH: 'TM',
} as const;

export type GraphTermUnitValue = Omit<ValueType<typeof GRAPH_TERM_UNIT>, 'TM'>;
export type GraphTermUnitAllValue = ValueType<typeof GRAPH_TERM_UNIT>;

export const GRAPH_INTERVAL = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
} as const;

export type GraphInterval = ValueType<typeof GRAPH_INTERVAL>;

export const GRAPH_GROUP_CODE = {
  POWER: 'power',
  ENERGY: 'energy',
  INVERTER: 'inverter',
  METER: 'meter',
  GRID: 'grid',
  BATTERY: 'battery',
  PV: 'pv',
  AC_COMBINER: 'ACCombiner',
  ALL: 'all',
} as const;

export type GraphGroupCodeValue = ValueType<typeof GRAPH_GROUP_CODE>;

export const GRAPH_Y_AXIS_UNIT = {
  WATT: 'W',
  WATT_HOUR: 'Wh',
  KILOWATT_HOUR: 'kWh',
  VOLTAGE: 'V',
  AMPERE: 'A',
  HERTZ: 'Hz',
  CELSIUS: '℃',
  PERCENTAGE: '%',
  /** 유효 전력 단위 */
  VOLTAGE_AMPERE: 'VA',
  /** 무효 전력 단위 */
  VOLTAGE_AMPERE_REACTIVE: 'VAR',
  FACTOR: 'factor',
};

export type GraphYAxisUnitValue = ValueType<typeof GRAPH_Y_AXIS_UNIT>;

export const GRAPH_SERIES_TYPE = {
  SPLINE: 'spline',
  LINE: 'line',
  COLUMN: 'column',
  PIE: 'pie',
} as const;
