import { DEFAULT_LANGUAGE, LanguageValue } from '@hems/util/src/constant';
import { useStore } from 'vuex';
import { computed } from 'vue';

export function useLanguage() {
  const store = useStore();
  const languageCode = computed<LanguageValue>(() => store.state.appCtx.langCd ?? DEFAULT_LANGUAGE);

  return { languageCode };
}
