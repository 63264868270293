/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { Role } from 'hems';
import { Module } from 'vuex';
import { RootState } from '../index';

export interface UserInfoState {
  userId: string;
  userNm: string;
  homeId: string;
  ownDeviceId: string;
  roleCd: string;
  roleNm: Role | '';
}

export const initState: UserInfoState = {
  userId: '',
  userNm: '',
  homeId: '',
  ownDeviceId: '',
  roleCd: '',
  roleNm: '',
};

export default {
  namespaced: true,
  state: { ...initState },
  getters: {},
  mutations: {
    clear(state) {
      state.userId = '';
      state.userNm = '';
      state.ownDeviceId = '';
      state.roleCd = '';
      state.roleNm = '';
    },
    setRole(state, payload: { roleCd: string; roleNm: Role }) {
      state.roleCd = payload.roleCd;
      state.roleNm = payload.roleNm;
    },
    setUserId(state, payload: string) {
      state.userId = payload;
    },
  },
  actions: {
    clear({ commit }) {
      commit('clear');
    },
    setRole({ commit }, payload: { roleCd: string; roleNm: Role }) {
      commit('setRole', payload);
    },
    setUserId({ commit }, payload: string) {
      commit('setUserId', payload);
    },
  },
} as Module<UserInfoState, RootState>;
