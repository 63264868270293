
import { computed, defineComponent, nextTick, PropType, reactive, watch, onMounted } from 'vue';
import {
  Accordion,
  LabelInput,
  TruncateInput,
  BasicInput,
  ToggleInput,
  GoogleMapAutoComplete,
  PositionInfo,
  GoogleMapWrapper,
  TimezoneInfo,
  ImageUploader,
  useMessageBox,
  SettingTable,
  SettingTableRow,
  Tooltip,
  withLoading,
  StatePostalInfo,
  Selector,
  RadioGroup,
  ErrorMessageLabel,
  LoadingPopup,
} from '@hems/component';
import _ from 'lodash';
import { Helper } from '@hems/util';
import { CommonService, DeviceServiceInstaller } from '@hems/service';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { LangCd, Role, SelectorOption } from 'hems';
import { SignPadViewer } from '@hems/component';
import { AssignedDeviceInfo, DeviceId, SmartModuleInfo, NMIDeviceId, SiteId, GenType } from 'hems/device';
import { SmartModuleGen1, SmartModuleGen2 } from 'hems/device/deviceinfo';
import { ModuleInfo } from 'hems/device/dashboard/smartmodule/gen1';
import { ModuleInfo as ModuleInfoGen2 } from 'hems/device/dashboard/smartmodule/gen2';
import { Installation, ReplaceDeviceOptions } from 'hems/device/settings/installation';
import { useI18n } from 'vue-i18n';
import { DeviceType, DeviceTypeCd } from 'hems/install';
import { AxiosErrorException } from '@hems/util/src/exception/exception';
import { alarmCodeMap } from './alarmConfig';
import ReplaceDevicePopup from './ReplaceDevicePopup.vue';
import ReplaceNMIDevicePopup from './ReplaceNMIDevicePopup.vue';
import { useTimestamp } from '@vueuse/core';
import { SubBatteryPack } from 'hems/device/dashboard/common/batteryPack';

export default defineComponent({
  name: 'InstallationContainer',
  components: {
    Accordion,
    LabelInput,
    TruncateInput,
    BasicInput,
    ToggleInput,
    Form,
    GoogleMapWrapper,
    GoogleMapAutoComplete,
    ImageUploader,
    SignPadViewer,
    ReplaceDevicePopup,
    ReplaceNMIDevicePopup,
    SettingTable,
    SettingTableRow,
    Tooltip,
    Selector,
    RadioGroup,
    ErrorMessageLabel,
    LoadingPopup,
  },
  props: {
    data: {
      type: Object as PropType<Installation>,
      required: true,
    },
    deviceId: {
      type: String as PropType<DeviceId>,
      required: true,
    },
    product_model_nm: {
      type: String as PropType<string>,
      required: true,
    },
    device_type_cd: {
      type: String as PropType<DeviceType>,
      required: true,
    },
    siteId: {
      type: Number as PropType<SiteId>,
      required: true,
    },
    langCd: {
      type: String as PropType<LangCd>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    roleNm: {
      type: String as PropType<Role>,
      required: true,
    },
    apiKey: {
      type: String,
      required: true,
    },
    isConnection: Boolean,
    nmiInfo: Object as PropType<{ isNMI: boolean; nmiDeviceId?: NMIDeviceId; retailerCd?: string }>,
    genType: {
      type: String as PropType<GenType>,
      required: true,
    },
    fcasInfo: Object as PropType<{ isFCAS: boolean }>,
    pvInfo: {
      type: Array as PropType<SmartModuleGen1['pv_info'] | SmartModuleGen2['module_info']>,
      required: true,
      default: () => [],
    },
    pvInfoList: {
      type: Array as PropType<SmartModuleGen1['pv_info'] | SmartModuleGen2['module_info']>,
      required: true,
      default: () => [],
    },
    priboInfo: {
      type: Array as PropType<SmartModuleGen2['primary_board_info']>,
      required: true,
      default: () => [],
    },
    stringInfo: {
      type: Array as PropType<SmartModuleGen1['string_info']>,
      required: true,
      default: () => [],
    },
    prnUid: {
      type: Number,
      required: true,
    },
    deviceInfo: {
      type: Object as PropType<SmartModuleGen1['device_info']>,
      required: true,
    },
    sapnInfo: {
      type: Object as PropType<{ isSAPN: boolean; ems_ver: string }>,
      default: () => ({ isSAPN: false, ems_ver: '' }),
    },
  },
  emits: [
    'goToList',
    'save',
    'afterReplaceDevice',
    'afterRemoveDevice',
    'afterDeleteDevice',
    'afterRollbackDevice',
    'afterModifyNMIDevice',
    'afterUpdateSubDevice',
    'afterPrnUidChange',
  ],
  async setup(props, { emit }) {
    let copyData: Installation;
    let copyRetailer: string | null = null;
    let copyBlobImg: Blob | null | undefined = undefined;
    const deviceService = new DeviceServiceInstaller(window.axiosInstance.axios);
    const commonService = new CommonService(window.axiosInstance.axios);
    const messageBox = useMessageBox();
    const { t } = useI18n();

    const state = reactive({
      editable: props.editable,
      language: computed(() => props.langCd),
      data: { ...props.data },
      activateStatus: '' as string,
      deleteStatus: '' as string,
      activateMessage: false,
      googleMap: null as any,
      isGoogleMapLoaded: false,
      blobImg: undefined as Blob | null | undefined,
      nmiDeviceId: props.nmiInfo?.nmiDeviceId ?? null,
      rollbackDeviceId: '' as string,
      retailerCd: props.nmiInfo?.retailerCd ?? null,
      notiAlarms: alarmCodeMap.map((item) => ({
        enabled: (Helper.isNull(props.data?.enabled_noti_alarms) ? '' : props.data?.enabled_noti_alarms).includes(
          item.code
        )
          ? 1
          : 0,
        alarmCd: item.code,
        show: item.genType.includes(props.genType) ? true : false,
      })),
      popup: {
        replaceDevice: { on: false },
        replaceNMIDevice: { on: false },
        sapnLoading: {
          on: false,
          defaultMessage: 'SAPN registration is in progress. Please wait.' as string | string[],
          displayingMessage: 'SAPN registration is in progress. Please wait.' as string | string[],
          inProgress: true,
          isSuccess: false,
        },
        batteryModuleInfo: { on: false },
      },
      fcasInfo: { ...props.fcasInfo },
      modules: undefined as SmartModuleInfo[] | undefined,
      pvInfo: computed(() => props.pvInfo.slice().sort(sorter)),
      priboInfo: computed(() =>
        props.priboInfo.slice().sort((a, b) => (a.device_id > b.device_id ? 1 : a.device_id < b.device_id ? -1 : 0))
      ),
      stringInfo: computed(() =>
        props.stringInfo.slice().sort((a, b) => (a.device_id > b.device_id ? 1 : a.device_id < b.device_id ? -1 : 0))
      ),
      batteryInfo: [] as SubBatteryPack,
      batteryModuleInfo: {},
      groupedPvInfo: computed(() =>
        _.chain(props.pvInfo.slice().sort(sorter))
          .groupBy('prn_uid')
          .map((value, key) => ({ prn_uid: key, rows: value }))
          .value()
      ),
      prnUidOptions: [] as SelectorOption[],
      prn_uid: null as number | null,
      prnDeviceId: '' as string,
      pvInfoList: computed(() =>
        props.pvInfo.filter((item) => item.prn_uid === (props.prnUid === undefined ? 1 : props.prnUid)).sort(sorter)
      ),
      siteId: computed(() => props.siteId),
      deviceInfo: computed(() => props.deviceInfo),
      deviceTypeCd: '' as DeviceTypeCd,
      assignedInverter: [] as AssignedDeviceInfo[],
      assignedString: [] as AssignedDeviceInfo[],
      assignedPriBoard: [] as AssignedDeviceInfo[],
      assignedBattery: [] as AssignedDeviceInfo[],
      assignedDeviceInfo: [] as AssignedDeviceInfo[],
      prevDeviceId: '' as DeviceId,
      isMod: false as boolean,
      telNum: `${props.data?.local_mpn_no ?? ''} ${props.data?.mpn_no ?? ''}`,
      onPopup: false,
      sapnValidationInfo: {
        isValidEmsVersion: Helper.checkValidEmsVersion(props.genType, props.sapnInfo.ems_ver, 23) ?? false,
        errorMessage: getSapnErrorMessage(),
      },
      lfdi: props.data.lfdi ?? null,
      validClass: {
        retailerCd: '' as 'ok' | 'error',
      },
    });

    // Module Selector Option 변환
    state.prnUidOptions =
      props.genType === 'MLPE_GEN1'
        ? props.stringInfo.map((item) => ({
            text: item.device_id + '',
            value: item.uid + '',
          }))
        : props.priboInfo.map((item) => ({
            text: item.device_id + '',
            value: item.uid + '',
          }));

    function openPopup(popupName: keyof typeof state.popup, params?: any) {
      state.popup[popupName] = { ...state.popup[popupName], on: true, ...params } as any;
    }
    function closePopup(popupName: keyof typeof state.popup) {
      state.popup[popupName] = { ...state.popup[popupName], on: false } as any;
    }

    const onClickReplacement = async () => {
      const confirmReplacement = await messageBox
        .confirm([`${t('message.inform_qcell_manager')}`, `${t('message.limit_service')}`])
        .open();

      if (confirmReplacement) {
        openPopup('replaceDevice');
      }
    };

    const onClickEmail = async () => {
      const confirmSendEmail = await messageBox
        .confirm(t('message.resend_email', { email: state.data.homeowner_email }))
        .open();

      if (confirmSendEmail) {
        const emailData = {
          device_id: props.deviceId,
          pin_code: state.data.instl_pin_code.toString(),
          email: state.data.homeowner_email,
        };
        try {
          await commonService.sendOwnerEmail(props.siteId, emailData);
        } catch (e) {
          await messageBox.alert(t('message.email_delivery_failed')).open();
          console.error(e);
        }
      }
    };

    const computedVal = {
      cntry: computed(() =>
        !Helper.isNull(state.data.cntry_cd)
          ? !Helper.isNull(state.data.cntry_nm)
            ? state.data.cntry_nm.includes('code')
              ? `${state.data.cntry_cd} (${t(state.data.cntry_nm)})`
              : `${state.data.cntry_cd} (${state.data.cntry_nm})`
            : state.data.cntry_cd
          : ''
      ),
      isNMI: computed(() => props.nmiInfo?.isNMI ?? false),
      isFCAS: computed(() => (props.fcasInfo?.isFCAS && ['admin', 'dev'].includes(props.roleNm)) ?? false),
    };

    watch(
      () => props.data,
      () => {
        state.data = { ...props.data };
      }
    );

    watch(
      () => props.pvInfoList,
      () => {
        state.pvInfoList = { ...props.pvInfoList };
      }
    );

    watch(
      () => state.googleMap,
      (status) => {
        if (status !== null) state.isGoogleMapLoaded = true;
      }
    );

    watch(
      () => state.retailerCd,
      async () => {
        if (!Helper.isNull(state.retailerCd) && Helper.isNull(state.nmiDeviceId)) {
          const check = await messageBox.alert(t('message.cannot_set_retailer')).open();
          if (!check) {
            state.retailerCd = null;
          }
        }
      }
    );

    watch(
      () => state.prn_uid,
      () => {
        const initPrnInfo =
          props.genType === 'MLPE_GEN1'
            ? state.stringInfo.filter((item) => item.uid === state.prn_uid)
            : state.priboInfo.filter((item) => item.uid === state.prn_uid);
        for (var i in initPrnInfo) {
          state.prnDeviceId = initPrnInfo[i].device_id;
        }
        onChangePrnUid();
      }
    );

    onMounted(() => {
      const initPrnInfo =
        props.genType === 'MLPE_GEN1'
          ? props.stringInfo.filter((item, index) => index === 0)
          : props.priboInfo.filter((item, index) => index === 0);
      for (var i in initPrnInfo) {
        state.prn_uid = initPrnInfo[i].uid;
        state.prnDeviceId = initPrnInfo[i].device_id;
      }
      if (localStorage.getItem('activateStatus')) {
        const BeforeTimeStamp = Number(localStorage.getItem('activateStatus'));
        const AfterTimeStamp = useTimestamp({ offset: 0 }).value;
        if (AfterTimeStamp - BeforeTimeStamp >= 60000) {
          localStorage.removeItem('activateStatus');
          state.activateMessage = false;
        } else {
          state.activateStatus = '0';
          state.activateMessage = true;
          setTimeout(() => {
            location.reload();
          }, BeforeTimeStamp + 60000 - AfterTimeStamp);
        }
      }
    });

    const { RETAILER_NAME_CD } = await commonService.getCodesByGroupCode([{ grpCd: 'RETAILER_NAME_CD' }]);
    const retailerNameCdList = Helper.codeNamesToSelectorOptions(RETAILER_NAME_CD, t, {
      text: t('common.none'),
      value: null,
    }).map((item) => (item.value === '1' ? Object.assign(item, { disabled: true }) : item));
    const yesOrNoSelectorOptions = Helper.addSelectorOptionAtFirst(
      [
        {
          text: t('common.yes'),
          value: 'Y',
        },
        {
          text: t('common.no'),
          value: 'N',
        },
      ],
      { text: t('common.select'), value: null }
    );

    let mapLoadedCount = 0;
    let maxCount = 42; // 0.7초당 1번 - 최대 30초

    const timer = (millisec: number) => new Promise((res) => setTimeout(res, millisec));

    async function loaded() {
      while (!state.isGoogleMapLoaded) {
        await timer(700);
        if (++mapLoadedCount >= maxCount) {
          break;
        }
      }
    }

    withLoading(async () => await loaded())();

    // load image
    deviceService.getImageBySiteId(props.siteId).then((blobImg) => {
      if (blobImg.size === 0) {
        state.blobImg = null;
        return;
      }
      state.blobImg = blobImg;
      state.data.file = new File([blobImg], 'file.png', { type: 'image/png' });
    });

    function getParam() {
      // 위치정보, 이미지 정보 저장...
      let param = {} as Partial<Installation>;
      const {
        instl_addr,
        latitude,
        longitude,
        cntry_cd,
        cntry_nm,
        instl_comn_nm,
        instl_mpn_no,
        instl_email,
        instl_pin_code,
        timezone_id,
        file,
        homeowner_nm,
        homeowner_pn,
        homeowner_email,
        instl_state,
        instl_postal_code,
        off_grid,
        is_test,
        force_instl_addr,
        memo,
      } = state.data;
      param = {
        instl_addr,
        latitude,
        longitude,
        cntry_cd,
        cntry_nm,
        instl_comn_nm,
        instl_mpn_no,
        instl_email,
        instl_pin_code,
        timezone_id,
        file,
        homeowner_nm,
        homeowner_pn,
        homeowner_email,
        instl_state,
        instl_postal_code,
        off_grid,
        is_test,
        force_instl_addr,
        memo,
      };
      param.enabled_noti_alarms = getEnabledNotiAlarms();
      if (props.sapnInfo.isSAPN && state.data.is_sapn === 'Y') {
        param.dynamic_export = state.data.dynamic_export;
        param.dynamic_export_owner = state.data.dynamic_export_owner;
      }
      if (computedVal.isNMI.value && !Helper.isNull(state.nmiDeviceId)) {
        return { ...param, nmi_device_id: state.nmiDeviceId, retailer_cd: state.retailerCd };
      }
      return param;
    }

    function getEnabledNotiAlarms() {
      const enabledAlarms = state.notiAlarms.filter((item) => item.show && item.enabled).map((item) => item.alarmCd);
      return enabledAlarms.join(',');
    }

    function onSave() {
      emit('save', getParam(), function (isOk: boolean) {
        if (isOk) state.editable = false;
      });
    }

    function onEdit() {
      copyData = _.cloneDeep(state.data);
      copyRetailer = state.retailerCd;
      copyBlobImg = state.blobImg;
      state.editable = true;
    }
    function onCancel(handleReset?: () => void) {
      state.data = copyData;
      state.retailerCd = copyRetailer;
      state.editable = false;
      state.blobImg = undefined;
      emit('afterPrnUidChange', 1);
      nextTick(() => {
        state.blobImg = copyBlobImg;
      });
      if (handleReset) handleReset();
    }
    function goToList() {
      emit('goToList');
    }

    async function replaceDevice(newDeviceInfo: ReplaceDeviceOptions) {
      const params = { ...newDeviceInfo, deviceId: props.deviceId };
      try {
        await deviceService.replaceDevice(props.siteId, params);
        emit('afterReplaceDevice', props.siteId, newDeviceInfo.newDeviceId);
      } catch (e) {
        console.error(e);
        await messageBox.alert(t('message.error_replace_device')).open();
      } finally {
        closePopup('replaceDevice');
      }
    }

    async function registerNMI(newNMIDeviceId: NMIDeviceId) {
      try {
        await deviceService.registerNMI(props.siteId, { nmiDeviceId: newNMIDeviceId, deviceId: props.deviceId });
        state.nmiDeviceId = newNMIDeviceId;
        emit('afterModifyNMIDevice', { newNMIDeviceId, siteId: props.siteId, deviceId: props.deviceId });
      } catch (e) {
        console.error(e);
        if (e instanceof AxiosErrorException) {
          e.afterError({
            500: async () => {
              await messageBox.alert(t('message.error_nmi_reg')).open();
            },
          });
        }
      } finally {
        closePopup('replaceNMIDevice');
      }
    }

    async function replaceNMI(newNMIDeviceId: NMIDeviceId) {
      if (!state.nmiDeviceId) return;

      try {
        await deviceService.replaceNMI(props.siteId, {
          nmiDeviceId: state.nmiDeviceId,
          newNmiDeviceId: newNMIDeviceId,
          deviceId: props.deviceId,
        });
        state.nmiDeviceId = newNMIDeviceId;
        emit('afterModifyNMIDevice', { newNMIDeviceId, siteId: props.siteId, deviceId: props.deviceId });
      } catch (e) {
        console.error(e);
        if (e instanceof AxiosErrorException) {
          e.afterError({
            500: async () => {
              await messageBox.alert(t('message.error_nmi_replace')).open();
            },
          });
        }
      } finally {
        closePopup('replaceNMIDevice');
      }
    }

    async function setNMI(newNMIDeviceId: NMIDeviceId) {
      if (!computedVal.isNMI.value) return;
      if (state.nmiDeviceId) {
        await replaceNMI(newNMIDeviceId);
      } else {
        await registerNMI(newNMIDeviceId);
      }
    }
    async function removeNMI() {
      if (!state.nmiDeviceId || !props.siteId) return;
      if (!(await messageBox.confirm([t('message.ask_delete')]).open())) return;
      try {
        await deviceService.removeNMI(props.siteId, state.nmiDeviceId, props.deviceId);
        state.nmiDeviceId = null;
        emit('afterModifyNMIDevice', { siteId: props.siteId, deviceId: props.deviceId });
      } catch (e) {
        console.error(e);
        await messageBox.alert(t('message.unknown_error')).open();
      }
    }

    async function deleteDevice() {
      if (!(await messageBox.confirm([`${t('message.inform_deletion')}`, `${t('message.inform_proceed')}`]).open()))
        return;

      try {
        await deviceService.deleteDevice(props.deviceId);
        emit('afterDeleteDevice');
      } catch (e) {
        console.error(e);
        messageBox.alert(t('message.error_delete_device')).open();
      }
    }

    async function rollbackDevice() {
      if (!(await messageBox.confirm([`${t('message.inform_rollback')}`, `${t('message.inform_proceed')}`]).open()))
        return;

      try {
        await deviceService.rollbackDevice(props.deviceId).then((data) => {
          state.rollbackDeviceId = data.after_device_id;
        });
        if (!(await messageBox.alert([t('message.complete_rollback_device')]).open()))
          emit('afterRollbackDevice', props.siteId, state.rollbackDeviceId);
      } catch (e) {
        console.error(e);
        messageBox.alert(t('message.error_rollback_device')).open();
      }
    }

    async function removeDevice() {
      if (!(await messageBox.confirm([`${t('message.inform_qcell_manager')}`, `${t('message.limit_service')}`]).open()))
        return;

      try {
        const params = { deviceId: props.deviceId };
        await deviceService.removeDevice(props.siteId, params);
        emit('afterRemoveDevice');
      } catch (e) {
        console.error(e);
        messageBox.alert(t('message.error_remove_device')).open();
      }
    }

    function onDetail(batteryModuleInfo: any) {
      if (batteryModuleInfo === null) return;
      const moduleInfoJSON = JSON.parse(batteryModuleInfo);
      state.popup.batteryModuleInfo.on = true;
      state.batteryModuleInfo = moduleInfoJSON;
    }

    const prevLocation = {
      value: undefined as undefined | { lat: number; lon: number },
      status: false,
    };
    const currentLocation = {
      value: { lat: props.data?.latitude ?? 0, lon: props.data?.longitude ?? 0 },
      status: true,
    };

    const schema = yup.object().shape({
      cntry_cd: yup.string().required(),
      instl_addr: yup
        .string()
        .required()
        .isValidLatLon(prevLocation, { latKey: 'latitude', lonKey: 'longitude' }, currentLocation),
      latitude: numberValiate(),
      longitude: numberValiate(),
      timezone_id: yup.string().nullable().required(),
      instl_pin_code: yup
        .string()
        .matches(/^(?:\d{4}|\d{6})$/, { message: { key: 'message.invalid_format' } })
        .required(),
      owner_email: yup.string().email().nullable().required(),
      owner_name: yup.string().trim().min(1).nullable().required(),
      owner_contact: yup
        .string()
        .test('mpn_no', { key: 'message.invalid' }, function (mpn_no?: string) {
          const { createError } = this;
          if (Helper.isNull(mpn_no)) {
            return createError({
              message: { key: 'message.field_required' },
            });
          }
          const regexp = /^[0-9+\-()]+$/g;
          if (!regexp.test(mpn_no)) {
            return false;
          }
          return true;
        })
        .nullable()
        .required(),
      instl_state: yup
        .string()
        .nullable()
        .when({
          is: (v: string) => !Helper.isNull(v),
          then: yup.string().nullable().required(),
        }),
      instl_postal_code: yup
        .string()
        .nullable()
        .when({
          is: (v: string) => !Helper.isNull(v),
          then: yup.string().nullable().required(),
        }),
      retailerCd: yup.string().nullable().notRequired(),
    });

    function numberValiate() {
      return yup
        .number()
        .transform((v, o) => (o === '' ? null : v))
        .nullable()
        .required();
    }

    function onChangeMapPosition(
      position: PositionInfo,
      timezoneInfo: TimezoneInfo,
      statePostalInfo: StatePostalInfo
    ): void {
      const { lat, lng, address, cntryCd, cntryNm } = position;
      if (!state.data) return;
      state.data.latitude = lat;
      state.data.longitude = lng;
      state.data.cntry_cd = cntryCd;
      state.data.cntry_nm = cntryNm;
      if (state.editable) state.data.instl_addr = address;
      state.data.timezone_id = timezoneInfo.timeZoneId;
      state.data.instl_state = statePostalInfo.state;
      state.data.instl_postal_code = statePostalInfo.postalCode;
    }

    function onChangeAddress(
      position: PositionInfo,
      timezoneInfo: TimezoneInfo,
      statePostalInfo: StatePostalInfo,
      address: string
    ): void {
      const { lat, lng, cntryCd, cntryNm } = position;
      if (!state.data) return;
      state.data.latitude = lat;
      state.data.longitude = lng;
      state.data.cntry_cd = cntryCd;
      state.data.cntry_nm = cntryNm;
      state.data.instl_addr = address;
      state.data.timezone_id = timezoneInfo.timeZoneId;
      state.data.instl_state = statePostalInfo.state;
      state.data.instl_postal_code = statePostalInfo.postalCode;
    }

    function onChangeCustomAddress(address: string): void {
      if (!state.data) return;
      state.data.instl_addr = address;
    }

    function onChangePrnUid() {
      emit('afterPrnUidChange', state.prn_uid);
    }

    function sorter(a: ModuleInfo | ModuleInfoGen2, b: ModuleInfo | ModuleInfoGen2): number {
      return a.uid > b.uid ? 1 : a.uid < b.uid ? -1 : 0;
    }

    function setSapnDisplayingMessage(result: number, lfdi: string) {
      if (result === 0) {
        state.popup.sapnLoading.displayingMessage = [
          'SAPN server registration is complete. Please check LFDI.',
          `LFDI: ${lfdi}`,
        ];
        state.popup.sapnLoading.isSuccess = true;
      } else if (result === 1) {
        state.popup.sapnLoading.displayingMessage = 'Error occurs due to timeout.';
      } else {
        state.popup.sapnLoading.displayingMessage = 'SAPN registration has failed.';
      }
    }

    async function retrySAPNRegistration() {
      try {
        // lfdi 발급 재시도
        state.popup.sapnLoading.on = true;
        state.popup.sapnLoading.inProgress = true;
        state.popup.sapnLoading.displayingMessage = state.popup.sapnLoading.defaultMessage;
        const { result } = await deviceService.registerSapn({ siteId: props.siteId, lfdiFlag: 1 });
        if (result.responseCd === '0') {
          setSapnDisplayingMessage(0, result.lfdi);
          state.lfdi = result.lfdi;
          state.sapnValidationInfo.errorMessage = '';
        } else if (result.responseCd === '1') {
          setSapnDisplayingMessage(1, result.lfdi);
          state.sapnValidationInfo.errorMessage = 'Error occurs due to timeout.';
        } else {
          setSapnDisplayingMessage(-1, result.lfdi);
          state.sapnValidationInfo.errorMessage = 'SAPN registration has failed.';
        }
        state.popup.sapnLoading.inProgress = false;
      } catch (e) {
        console.error(e);
        state.popup.sapnLoading.inProgress = false;
        setSapnDisplayingMessage(-1, '');
        state.sapnValidationInfo.errorMessage = 'SAPN registration has failed.';
      }
    }

    function getSapnErrorMessage() {
      const isValidEmsVersion = Helper.checkValidEmsVersion(props.genType, props.sapnInfo.ems_ver, 23) ?? false;
      if (!isValidEmsVersion) {
        return 'Older version of FW does not support this feature.';
      }
      const { lfdi, lfdi_result_cd } = props.data;
      if (Helper.isNull(lfdi) && !Helper.isNull(lfdi_result_cd)) {
        if (lfdi_result_cd === '1') {
          return 'Error occurs due to timeout.';
        } else {
          return 'SAPN registration has failed.';
        }
      } else {
        return '';
      }
    }

    async function getDeleteStatus() {
      const params = { deviceId: props.deviceId };
      await deviceService.checkDeleteStatus(props.siteId, params).then((data) => {
        state.activateStatus = data.activate;
        state.deleteStatus = data.type;
      });
    }

    if (
      props.roleNm === 'admin' ||
      props.roleNm === 'dev' ||
      props.roleNm === 'installer' ||
      props.roleNm === 'service'
    ) {
      await Promise.all([getDeleteStatus()]);
    }

    // await Promise.all([onLoadAssignedDevice()]);
    return {
      state,
      schema,
      onChangeMapPosition,
      onChangeAddress,
      onChangeCustomAddress,
      onChangePrnUid,
      computedVal,
      goToList,
      onEdit,
      onCancel,
      onSave,
      openPopup,
      closePopup,
      deleteDevice,
      rollbackDevice,
      removeDevice,
      replaceDevice,
      setNMI,
      removeNMI,
      onDetail,
      instance: (instance: any) => {
        state.googleMap = instance?.map ?? null;
      },
      retailerNameCdList,
      _,
      onClickEmail,
      onClickReplacement,
      yesOrNoSelectorOptions,
      retrySAPNRegistration,
      isNull: Helper.isNull,
    };
  },
});
