
import { defineComponent, reactive, PropType } from 'vue';
import { Form } from 'vee-validate';
import * as Yup from 'yup';

export default defineComponent({
  name: 'InstallationFormContainer',
  components: {
    Form,
  },
  props: {
    schema: {
      type: Object as PropType<Yup.AnySchema>,
    },
    enableNext: {
      type: Boolean,
      default: true,
    },
    submitBtnText: {
      type: String,
      default: 'Save',
    },
  },
  setup(props, { emit }) {
    const state = reactive({});

    function onSave() {
      emit('save');
    }

    function onCancel() {
      emit('cancel');
    }

    return {
      state,
      onSave,
      onCancel,
    };
  },
});
