
import { defineComponent, reactive, watch, inject } from 'vue';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { Selector, BasicInput, PopupWrapper } from '@hems/component';
import { SelectorOption } from 'hems';
import { Helper } from '@hems/util';
import { useI18n } from 'vue-i18n';
import { PROVIDE_INJECT_KEYS, acSystemTimeBasedActions, mowTimeBasedActions } from '@hems/util/src/constant';
import { useGenType } from '@hems/util';

export default defineComponent({
  name: 'AddPricingItemPopup',
  components: {
    Selector,
    BasicInput,
    Form,
    PopupWrapper,
  },
  props: {
    minuteOptionUnit: {
      type: Number,
      default: 1,
    },
  },
  emits: ['onAdd', 'close'],
  setup(props, { emit }) {
    const INVERTER_REF_MAX_VALUE = 30000;
    const maxInverterRef = inject<number>(PROVIDE_INJECT_KEYS.MAX_INVERTER_REF) ?? INVERTER_REF_MAX_VALUE;
    const minInverterRef = -maxInverterRef;
    const { t } = useI18n();

    const state = reactive<{
      time: string | null;
      action: string | null;
      actionText: string | null;
      inverterReference: number | null;
      hours: number | null;
      minutes: number | null;
    }>({
      time: null,
      action: null,
      actionText: null,
      inverterReference: null,
      hours: null,
      minutes: null,
    });

    const { isACSystem } = useGenType();
    const actionOptions = isACSystem ? acSystemTimeBasedActions : mowTimeBasedActions;

    const options = reactive<{
      action: SelectorOption[];
      hours: SelectorOption[];
      minutes: SelectorOption[];
    }>({
      action: Helper.addSelectorOptionAtFirst(
        actionOptions.map<SelectorOption>((item) => ({
          text: t(item.text, { defaultValue: item.text }),
          value: item.value,
        })),
        { text: t('common.select'), value: null }
      ),
      hours: Helper.addSelectorOptionAtFirst(getNumberSelectorOptions(0, 23), {
        text: t('common.select'),
        value: null,
      }),
      minutes: Helper.addSelectorOptionAtFirst(getNumberSelectorOptions(0, 59, props.minuteOptionUnit), {
        text: t('common.select'),
        value: null,
      }),
    });

    const schema = yup.object().shape({
      hours: yup.string().nullable().required(),
      minutes: yup.string().nullable().required(),
      action: yup.number().nullable().required(),
      inverterReference: yup.mixed().when(['action'], {
        is: (v: string) => v == '2',
        then: yup
          .number()
          .transform((v, o) => (o === '' ? null : v))
          .nullable()
          .min(minInverterRef)
          .max(maxInverterRef)
          .required(),
      }),
    });

    watch(
      () => state.action,
      (action) => {
        state.actionText = options.action.find((item) => item.value === action)?.text || '';
        // action이 Inverter 일 경우에만
        if (state.action !== '2') {
          state.inverterReference = null;
        }
      }
    );

    function getAddedItem() {
      return {
        time: `${state.hours}:${state.minutes}`,
        action: state.action,
        actionText: state.actionText,
        inverterReference: state.inverterReference,
      };
    }

    function onClose() {
      emit('close');
    }
    function onAdd() {
      emit('onAdd', getAddedItem());
    }

    function getNumberSelectorOptions(start: number, end: number, step = 1): SelectorOption[] {
      if (start > end) throw 'Invalid Arguments';
      const result: SelectorOption[] = [];
      for (let i = start; i <= end; i += step) {
        result.push({ text: `${Helper.padLeft(i, 2)}`, value: Helper.padLeft(i, 2) });
      }
      return result;
    }

    return {
      state,
      schema,
      options,
      maxInverterRef,
      minInverterRef,
      onClose,
      onAdd,
    };
  },
});
