
import { defineComponent, PropType } from 'vue';
import { EngineerSettings } from 'hems/device/settings/pvmodule/fox';
import EngineerSettingsPvModuleFoxSharedContainer from '../../../_shared/engineer/EngineerSettingsPvModuleFoxSharedContainer.vue';
import { SiteId } from 'hems/device';

export default defineComponent({
  name: 'EngineerSettingsPvModuleFoxContainer',
  components: {
    EngineerSettingsPvModuleFoxSharedContainer,
  },
  props: {
    data: {
      type: Object as PropType<EngineerSettings>,
      required: true,
    },
    productModelNm: {
      type: String,
      required: true,
    },
    cntryCd: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    isConnection: Boolean,
    isHcore: {
      type: Boolean,
      default: false,
    },
    isQcore: {
      type: Boolean,
      default: false,
    },
    isAcCoupled: {
      type: Boolean,
      default: false,
    },
    isFcas: {
      type: Boolean,
      default: false,
    },
    siteId: {
      type: Number as PropType<SiteId>,
      default: null,
    },
  },
  emits: ['goToList', 'save', 'cancel', 'edit'],
  async setup(props, { emit }) {
    function onSave(params: Partial<EngineerSettings>, callback?: (isOk: boolean) => void) {
      emit('save', params, callback);
    }
    function onEdit(copyData: EngineerSettings) {
      emit('edit', copyData);
    }

    return {
      onSave,
      onEdit,
    };
  },
});
