import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "is_default" }
const _hoisted_2 = { class: "is_edit" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  type: "submit",
  class: "btn_m btn_c_3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleInput = _resolveComponent("ToggleInput")!
  const _component_ChangedMark = _resolveComponent("ChangedMark")!
  const _component_SettingTableRow = _resolveComponent("SettingTableRow")!
  const _component_SettingTable = _resolveComponent("SettingTable")!
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_Selector = _resolveComponent("Selector")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Form = _resolveComponent("Form")!
  const _component_SettingConfirmPopup = _resolveComponent("SettingConfirmPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Form, {
      "validation-schema": _ctx.schema,
      onSubmit: _ctx.onSave
    }, {
      default: _withCtx(({ handleReset }) => [
        _createVNode(_component_Accordion, {
          title: _ctx.$t('common.engineer_setting_info'),
          active: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SettingTable, { "table-title": "EOS Mode" }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTableRow, {
                  title: "EOS Mode",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "eos_flag",
                      "target-value": _ctx.state.data.eos_flag,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ToggleInput, {
                          modelValue: _ctx.state.data.eos_flag,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.data.eos_flag) = $event)),
                          name: "eos_flag",
                          toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "toggle", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_SettingTable, { "table-title": "EOS Info" }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTableRow, {
                  title: "Power Sampling Interval",
                  description: "milliseconds",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "eos_power_sampling_interval",
                      "target-value": _ctx.state.data.eos_power_sampling_interval,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.eos_power_sampling_interval,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.data.eos_power_sampling_interval) = $event)),
                          name: "eos_power_sampling_interval",
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SettingTableRow, {
                  title: "Default Sampling Interval",
                  description: "milliseconds",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "eos_default_sampling_interval",
                      "target-value": _ctx.state.data.eos_default_sampling_interval,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.eos_default_sampling_interval,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.data.eos_default_sampling_interval) = $event)),
                          name: "eos_default_sampling_interval",
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.isSetFCAS && _ctx.state.data.eos_flag === 1)
              ? (_openBlock(), _createBlock(_component_SettingTable, {
                  key: 0,
                  "table-title": "FCAS"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SettingTableRow, {
                      title: "FCAS Flag",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "fcas_flag",
                          "target-value": _ctx.state.data.fcas_flag,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ToggleInput, {
                              modelValue: _ctx.state.data.fcas_flag,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.data.fcas_flag) = $event)),
                              name: "fcas_flag",
                              toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                              readonly: !_ctx.isEditable
                            }, null, 8, ["modelValue", "toggle", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_SettingTableRow, {
                      title: "Response Capacity limit",
                      description: "0 ~ 20000W",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "fcas_response_capacity_limit",
                          "target-value": _ctx.state.data.fcas_response_capacity_limit,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.fcas_response_capacity_limit,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.data.fcas_response_capacity_limit) = $event)),
                              name: "fcas_response_capacity_limit",
                              min: "0",
                              max: "20000",
                              type: "number",
                              readonly: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_SettingTableRow, {
                      title: "Droop Rate Raise",
                      description: "0.00 ~ 100.00%",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "fcas_droop_rate_raise",
                          "target-value": _ctx.state.data.fcas_droop_rate_raise,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.fcas_droop_rate_raise,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.data.fcas_droop_rate_raise) = $event)),
                              name: "fcas_droop_rate_raise",
                              min: "0",
                              max: "100",
                              type: "number",
                              readonly: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_SettingTableRow, {
                      title: "Droop Rate Lower",
                      description: "0.00 ~ 100.00%",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "fcas_droop_rate_lower",
                          "target-value": _ctx.state.data.fcas_droop_rate_lower,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.fcas_droop_rate_lower,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.data.fcas_droop_rate_lower) = $event)),
                              name: "fcas_droop_rate_lower",
                              min: "0",
                              max: "100",
                              type: "number",
                              readonly: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_SettingTableRow, {
                      title: "Oftb Max",
                      description: "45.00 ~ 65.00Hz",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "fcas_oftb_max",
                          "target-value": _ctx.state.data.fcas_oftb_max,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.fcas_oftb_max,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.data.fcas_oftb_max) = $event)),
                              name: "fcas_oftb_max",
                              min: "45",
                              max: "65",
                              type: "number",
                              readonly: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_SettingTableRow, {
                      title: "Oftb Min",
                      description: "45.00 ~ 65.00Hz",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "fcas_oftb_min",
                          "target-value": _ctx.state.data.fcas_oftb_min,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.fcas_oftb_min,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.data.fcas_oftb_min) = $event)),
                              name: "fcas_oftb_min",
                              min: "45",
                              max: "65",
                              type: "number",
                              readonly: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_SettingTableRow, {
                      title: "Fr Max",
                      description: "45.00 ~ 65.00Hz",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "fcas_fr_max",
                          "target-value": _ctx.state.data.fcas_fr_max,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.fcas_fr_max,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.data.fcas_fr_max) = $event)),
                              name: "fcas_fr_max",
                              min: "45",
                              max: "65",
                              type: "number",
                              readonly: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_SettingTableRow, {
                      title: "Fr Min",
                      description: "45.00 ~ 65.00Hz",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "fcas_fr_min",
                          "target-value": _ctx.state.data.fcas_fr_min,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.fcas_fr_min,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.data.fcas_fr_min) = $event)),
                              name: "fcas_fr_min",
                              min: "45",
                              max: "65",
                              type: "number",
                              readonly: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_SettingTableRow, {
                      title: "Nofb Max",
                      description: "45.00 ~ 65.00Hz",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "fcas_nofb_max",
                          "target-value": _ctx.state.data.fcas_nofb_max,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.fcas_nofb_max,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.state.data.fcas_nofb_max) = $event)),
                              name: "fcas_nofb_max",
                              min: "45",
                              max: "65",
                              type: "number",
                              readonly: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_SettingTableRow, {
                      title: "Nofb Min",
                      description: "45.00 ~ 65.00Hz",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "fcas_nofb_min",
                          "target-value": _ctx.state.data.fcas_nofb_min,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.fcas_nofb_min,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.state.data.fcas_nofb_min) = $event)),
                              name: "fcas_nofb_min",
                              min: "45",
                              max: "65",
                              type: "number",
                              readonly: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_SettingTableRow, {
                      title: "Test Mode",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "fcas_test_mode",
                          "target-value": _ctx.state.data.fcas_test_mode,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ToggleInput, {
                              modelValue: _ctx.state.data.fcas_test_mode,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.state.data.fcas_test_mode) = $event)),
                              name: "fcas_test_mode",
                              toggle: [
                [1, _ctx.$t('common.on')],
                [0, _ctx.$t('common.off')],
              ],
                              readonly: !_ctx.isEditable
                            }, null, 8, ["modelValue", "toggle", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_SettingTable, { "table-title": "SG Ready" }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTableRow, {
                  title: "Enable Flag",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "sg_ready_flag",
                      "target-value": _ctx.state.data.sg_ready_flag,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ToggleInput, {
                          modelValue: _ctx.state.data.sg_ready_flag,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.state.data.sg_ready_flag) = $event)),
                          name: "sg_ready_flag",
                          toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "toggle", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                (_ctx.state.data.sg_ready_flag)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 0,
                      title: "Heatpump Consumption Power",
                      description: "0 ~ 65000W",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "sg_ready_heat_pump_consumption_power",
                          "target-value": _ctx.state.data.sg_ready_heat_pump_consumption_power,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.sg_ready_heat_pump_consumption_power,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.state.data.sg_ready_heat_pump_consumption_power) = $event)),
                              name: "sg_ready_heat_pump_consumption_power",
                              readonly: !_ctx.isEditable
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 512), [
              [_vShow, !_ctx.isACCoupled]
            ]),
            _createVNode(_component_SettingTable, {
              "table-title": _ctx.$t(`device.pcs_external_ems`)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTableRow, {
                  title: "PCS Connection Mode",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "pcs_connection_mode",
                      "target-value": _ctx.state.data.pcs_connection_mode,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ToggleInput, {
                          modelValue: _ctx.state.data.pcs_connection_mode,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.state.data.pcs_connection_mode) = $event)),
                          name: "pcs_connection_mode",
                          toggle: [
                [1, 'External'],
                [0, 'Internal'],
              ],
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t(`device.pcs_debug_mode`),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "pcs_debug_mode",
                      "target-value": _ctx.state.data.pcs_debug_mode,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ToggleInput, {
                          modelValue: _ctx.state.data.pcs_debug_mode,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.state.data.pcs_debug_mode) = $event)),
                          name: "pcs_debug_mode",
                          toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "toggle", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _withDirectives(_createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('device.external_ems_model'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "external_ems_model",
                      "target-value": _ctx.state.data.external_ems_model,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.external_ems_model,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.state.data.external_ems_model) = $event)),
                          name: "external_ems_model",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.extEmsModel
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]), [
                  [_vShow, _ctx.state.data.pcs_connection_mode === 1]
                ]),
                _createVNode(_component_SettingTableRow, {
                  title: "PCS Connection Type",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "external_ems_connection",
                      "target-value": _ctx.state.data.external_ems_connection,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.external_ems_connection,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.state.data.external_ems_connection) = $event)),
                          name: "external_ems_connection",
                          "value-type": "number",
                          disabled: _ctx.isDisabledEMSConnection,
                          options: 
                _ctx.state.data.pcs_connection_mode === _ctx.PCS_CONNECTION_MODE.EXTERNAL
                  ? _ctx.selectorOptionsState.pcsConnectionType
                  : _ctx.selectorOptionsState.filteredPcsConnectionType
              
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                (_ctx.state.data.external_ems_connection === _ctx.EXTERNAL_EMS_CONNECTION.MODBUS_TCP)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 0,
                      title: _ctx.$t('common.ip_address'),
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "external_ems_modbus_tcp_ip",
                          "target-value": _ctx.state.data.external_ems_modbus_tcp_ip,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.external_ems_modbus_tcp_ip,
                              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.state.data.external_ems_modbus_tcp_ip) = $event)),
                              name: "external_ems_modbus_tcp_ip",
                              readonly: _ctx.isDisabledEMSModBus
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                (_ctx.state.data.external_ems_connection === _ctx.EXTERNAL_EMS_CONNECTION.MODBUS_TCP)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 1,
                      title: _ctx.$t('common.port'),
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "external_ems_modbus_tcp_port",
                          "target-value": _ctx.state.data.external_ems_modbus_tcp_port,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.external_ems_modbus_tcp_port,
                              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.state.data.external_ems_modbus_tcp_port) = $event)),
                              type: "number",
                              name: "external_ems_modbus_tcp_port",
                              readonly: _ctx.isDisabledEMSModBus
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                (_ctx.state.data.external_ems_connection === _ctx.EXTERNAL_EMS_CONNECTION.MODBUS_RTU)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 2,
                      title: _ctx.$t('control.rtu_dev'),
                      description: _ctx.$t('common.default') + ': Serial 2(/dev/ttymxc0)',
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "external_ems_modbus_rtu_dev",
                          "target-value": _ctx.state.data.external_ems_modbus_rtu_dev,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Selector, {
                              modelValue: _ctx.state.data.external_ems_modbus_rtu_dev,
                              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.state.data.external_ems_modbus_rtu_dev) = $event)),
                              name: "external_ems_modbus_rtu_dev",
                              disabled: _ctx.isDisabledEMSModBus,
                              options: _ctx.selectorOptionsState.modbusRtuDev
                            }, null, 8, ["modelValue", "disabled", "options"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title", "description"]))
                  : _createCommentVNode("", true),
                (_ctx.state.data.external_ems_connection === _ctx.EXTERNAL_EMS_CONNECTION.MODBUS_RTU)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 3,
                      title: _ctx.$t('control.rtu_baud_rate'),
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "external_ems_modbus_rtu_baudrate",
                          "target-value": _ctx.state.data.external_ems_modbus_rtu_baudrate,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Selector, {
                              modelValue: _ctx.state.data.external_ems_modbus_rtu_baudrate,
                              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.state.data.external_ems_modbus_rtu_baudrate) = $event)),
                              name: "external_ems_modbus_rtu_baudrate",
                              "value-type": "number",
                              disabled: _ctx.isDisabledEMSModBus,
                              options: _ctx.selectorOptionsState.modbusRtuBaudrate
                            }, null, 8, ["modelValue", "disabled", "options"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                (_ctx.state.data.external_ems_connection === _ctx.EXTERNAL_EMS_CONNECTION.MODBUS_RTU)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 4,
                      title: _ctx.$t('control.rtu_parity'),
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "external_ems_modbus_rtu_parity",
                          "target-value": _ctx.state.data.external_ems_modbus_rtu_parity,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Selector, {
                              modelValue: _ctx.state.data.external_ems_modbus_rtu_parity,
                              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.state.data.external_ems_modbus_rtu_parity) = $event)),
                              name: "external_ems_modbus_rtu_parity",
                              disabled: _ctx.isDisabledEMSModBus,
                              options: _ctx.selectorOptionsState.modbusRtuParity
                            }, null, 8, ["modelValue", "disabled", "options"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                (_ctx.state.data.external_ems_connection === _ctx.EXTERNAL_EMS_CONNECTION.MODBUS_RTU)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 5,
                      title: _ctx.$t('control.rtu_data_bits'),
                      description: "5 ~ 8",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "external_ems_modbus_rtu_data",
                          "target-value": _ctx.state.data.external_ems_modbus_rtu_data,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.external_ems_modbus_rtu_data,
                              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.state.data.external_ems_modbus_rtu_data) = $event)),
                              name: "external_ems_modbus_rtu_data",
                              type: "number",
                              readonly: _ctx.isDisabledEMSModBus
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                (_ctx.state.data.external_ems_connection === _ctx.EXTERNAL_EMS_CONNECTION.MODBUS_RTU)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 6,
                      title: _ctx.$t('control.rtu_stop_bits'),
                      description: "1 ~ 2",
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.isEditable,
                          "value-key": "external_ems_modbus_rtu_stop",
                          "target-value": _ctx.state.data.external_ems_modbus_rtu_stop,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.external_ems_modbus_rtu_stop,
                              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.state.data.external_ems_modbus_rtu_stop) = $event)),
                              name: "external_ems_modbus_rtu_stop",
                              type: "number",
                              readonly: _ctx.isDisabledEMSModBus
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_SettingTableRow, {
                  title: "Server Id",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "external_ems_server_id",
                      "target-value": _ctx.state.data.external_ems_server_id,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.external_ems_server_id,
                          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.state.data.external_ems_server_id) = $event)),
                          name: "external_ems_server_id",
                          type: "number",
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SettingTableRow, {
                  title: "Grid Code",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "external_ems_grid_code",
                      "target-value": _ctx.state.data.external_ems_grid_code,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.external_ems_grid_code,
                          "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.state.data.external_ems_grid_code) = $event)),
                          name: "external_ems_grid_code",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.gridCodeInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SettingTableRow, {
                  title: "External Energy Controller",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "external_ems_meter2",
                      "target-value": _ctx.state.data.external_ems_meter2,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.external_ems_meter2,
                          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.state.data.external_ems_meter2) = $event)),
                          name: "external_ems_meter2",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.meterInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["table-title"]),
            _createVNode(_component_SettingTable, {
              "table-title": _ctx.$t('device.battery_user_soc')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('common.min'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "battery_user_soc_min",
                      "target-value": _ctx.state.data.battery_user_soc_min,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.battery_user_soc_min,
                          "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.state.data.battery_user_soc_min) = $event)),
                          name: "battery_user_soc_min",
                          type: "number",
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('common.max'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "battery_user_soc_max",
                      "target-value": _ctx.state.data.battery_user_soc_max,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.battery_user_soc_max,
                          "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.state.data.battery_user_soc_max) = $event)),
                          name: "battery_user_soc_max",
                          type: "number",
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"])
              ]),
              _: 1
            }, 8, ["table-title"]),
            _createVNode(_component_SettingTable, {
              "table-title": _ctx.$t('device.debug_info')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.process_mgr'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_process_mgr",
                      "target-value": _ctx.state.data.log_level_process_mgr,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_process_mgr,
                          "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.state.data.log_level_process_mgr) = $event)),
                          name: "log_level_process_mgr",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t(`control.system_log`),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_system_log",
                      "target-value": _ctx.state.data.log_level_system_log,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_system_log,
                          "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.state.data.log_level_system_log) = $event)),
                          name: "log_level_system_log",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.fota'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_fota",
                      "target-value": _ctx.state.data.log_level_fota,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_fota,
                          "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.state.data.log_level_fota) = $event)),
                          name: "log_level_fota",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.pw_control'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_power_control",
                      "target-value": _ctx.state.data.log_level_power_control,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_power_control,
                          "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.state.data.log_level_power_control) = $event)),
                          name: "log_level_power_control",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.algo_mgr'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_algorithm_mgr",
                      "target-value": _ctx.state.data.log_level_algorithm_mgr,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_algorithm_mgr,
                          "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.state.data.log_level_algorithm_mgr) = $event)),
                          name: "log_level_algorithm_mgr",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.ess_mgr'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_ess_mgr",
                      "target-value": _ctx.state.data.log_level_ess_mgr,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_ess_mgr,
                          "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.state.data.log_level_ess_mgr) = $event)),
                          name: "log_level_ess_mgr",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.dc_src_mgr'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_dc_source_mgr",
                      "target-value": _ctx.state.data.log_level_dc_source_mgr,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_dc_source_mgr,
                          "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.state.data.log_level_dc_source_mgr) = $event)),
                          name: "log_level_dc_source_mgr",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.cloud_mgr'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_cloud_mgr",
                      "target-value": _ctx.state.data.log_level_cloud_mgr,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_cloud_mgr,
                          "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.state.data.log_level_cloud_mgr) = $event)),
                          name: "log_level_cloud_mgr",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.meter_mgr'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_meter_mgr",
                      "target-value": _ctx.state.data.log_level_meter_mgr,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_meter_mgr,
                          "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.state.data.log_level_meter_mgr) = $event)),
                          name: "log_level_meter_mgr",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.gw_mgr'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_gateway_mgr",
                      "target-value": _ctx.state.data.log_level_gateway_mgr,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_gateway_mgr,
                          "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.state.data.log_level_gateway_mgr) = $event)),
                          name: "log_level_gateway_mgr",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.data_mgr'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_data_accumulator",
                      "target-value": _ctx.state.data.log_level_data_accumulator,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_data_accumulator,
                          "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.state.data.log_level_data_accumulator) = $event)),
                          name: "log_level_data_accumulator",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.db_mgr'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_db_mgr",
                      "target-value": _ctx.state.data.log_level_db_mgr,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_db_mgr,
                          "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.state.data.log_level_db_mgr) = $event)),
                          name: "log_level_db_mgr",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.web_engine'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_web_engine",
                      "target-value": _ctx.state.data.log_level_web_engine,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_web_engine,
                          "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.state.data.log_level_web_engine) = $event)),
                          name: "log_level_web_engine",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_SettingTableRow, {
                  title: "GQA",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_gqa",
                      "target-value": _ctx.state.data.log_level_gqa,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_gqa,
                          "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.state.data.log_level_gqa) = $event)),
                          name: "log_level_gqa",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SettingTableRow, {
                  title: "MLPE Mgr",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_mlpe_mgr",
                      "target-value": _ctx.state.data.log_level_mlpe_mgr,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_mlpe_mgr,
                          "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.state.data.log_level_mlpe_mgr) = $event)),
                          name: "log_level_mlpe_mgr",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SettingTableRow, {
                  title: "Sys Mgr",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_sys_mgr",
                      "target-value": _ctx.state.data.log_level_sys_mgr,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_sys_mgr,
                          "onUpdate:modelValue": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.state.data.log_level_sys_mgr) = $event)),
                          name: "log_level_sys_mgr",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SettingTableRow, {
                  title: "Energy Link",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_energy_link",
                      "target-value": _ctx.state.data.log_level_energy_link,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_energy_link,
                          "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.state.data.log_level_energy_link) = $event)),
                          name: "log_level_energy_link",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SettingTableRow, {
                  title: "Diag Monitor",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_diag_monitor",
                      "target-value": _ctx.state.data.log_level_diag_monitor,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_diag_monitor,
                          "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.state.data.log_level_diag_monitor) = $event)),
                          name: "log_level_diag_monitor",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SettingTableRow, {
                  title: "Csip Mgr",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_csip_mgr",
                      "target-value": _ctx.state.data.log_level_csip_mgr,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_csip_mgr,
                          "onUpdate:modelValue": _cache[50] || (_cache[50] = ($event: any) => ((_ctx.state.data.log_level_csip_mgr) = $event)),
                          name: "log_level_csip_mgr",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SettingTableRow, {
                  title: "Energy Optimizer",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "log_level_energy_optimizer",
                      "target-value": _ctx.state.data.log_level_energy_optimizer,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.log_level_energy_optimizer,
                          "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.state.data.log_level_energy_optimizer) = $event)),
                          name: "log_level_energy_optimizer",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SettingTableRow, {
                  title: "MQTT",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "debug_mode_mqtt",
                      "target-value": _ctx.state.data.debug_mode_mqtt,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.debug_mode_mqtt,
                          "onUpdate:modelValue": _cache[52] || (_cache[52] = ($event: any) => ((_ctx.state.data.debug_mode_mqtt) = $event)),
                          name: "debug_mode_mqtt",
                          "value-type": "number",
                          disabled: !_ctx.isEditable,
                          options: _ctx.selectorOptionsState.debugInfo
                        }, null, 8, ["modelValue", "disabled", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_SettingTableRow, {
                  title: "Modbus",
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "debug_mode_modbus",
                      "target-value": _ctx.state.data.debug_mode_modbus,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ToggleInput, {
                          modelValue: _ctx.state.data.debug_mode_modbus,
                          "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => ((_ctx.state.data.debug_mode_modbus) = $event)),
                          name: "debug_mode_modbus",
                          toggle: [
                [1, _ctx.$t('common.on')],
                [0, _ctx.$t('common.off')],
              ],
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "toggle", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["table-title"]),
            _createVNode(_component_SettingTable, {
              "table-title": _ctx.$t('control.update_setting')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.auto_firm_update'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.isEditable,
                      "value-key": "auto_fw_update_flag",
                      "target-value": _ctx.state.data.auto_fw_update_flag,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ToggleInput, {
                          modelValue: _ctx.state.data.auto_fw_update_flag,
                          "onUpdate:modelValue": _cache[54] || (_cache[54] = ($event: any) => ((_ctx.state.data.auto_fw_update_flag) = $event)),
                          name: "auto_fw_update_flag",
                          toggle: [
                [1, _ctx.$t('common.enable')],
                [0, _ctx.$t('common.disable')],
              ],
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "toggle", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"])
              ]),
              _: 1
            }, 8, ["table-title"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createElementVNode("div", {
          class: _normalizeClass(["btn_wrap_m", { editorble: _ctx.isEditable }])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("button", {
              type: "button",
              class: "btn_m btn_c_2",
              onClick: _cache[55] || (_cache[55] = 
//@ts-ignore
(...args) => (_ctx.goToList && _ctx.goToList(...args)))
            }, _toDisplayString(_ctx.$t('message.go_to_list')), 1),
            (!_ctx.hideEdit)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn_m btn_c_3",
                  onClick: _cache[56] || (_cache[56] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
                }, _toDisplayString(_ctx.$t('common.edit')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              type: "button",
              class: "btn_m btn_c_2",
              onClick: ($event: any) => (_ctx.onCancel(handleReset))
            }, _toDisplayString(_ctx.$t('common.cancel')), 9, _hoisted_3),
            _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.$t(`common.apply`)), 1)
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["validation-schema", "onSubmit"]),
    (_ctx.state.confirmPopup.on)
      ? (_openBlock(), _createBlock(_component_SettingConfirmPopup, {
          key: 0,
          title: "Engineer Setting",
          data: _ctx.state.confirmPopup.data,
          onConfirm: _ctx.onConfirm,
          onCancel: _cache[57] || (_cache[57] = () => (_ctx.state.confirmPopup.on = false))
        }, null, 8, ["data", "onConfirm"]))
      : _createCommentVNode("", true)
  ], 64))
}