import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Marker = _resolveComponent("Marker")!
  const _component_GoogleMap = _resolveComponent("GoogleMap")!

  return (_openBlock(), _createBlock(_component_GoogleMap, {
    ref: "googleMap",
    class: "googlemap",
    "api-key": _ctx.apiKey,
    language: _ctx.state.language,
    style: {"width":"100%","height":"100%"},
    center: { lat: _ctx.state.position.lat, lng: _ctx.state.position.lng },
    zoom: 15
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Marker, {
        options: {
        draggable: _ctx.state.editable,
        position: _ctx.state.position,
      },
        onDragend: _ctx.dragend
      }, null, 8, ["options", "onDragend"])
    ]),
    _: 1
  }, 8, ["api-key", "language", "center"]))
}