import type { ValueType } from 'hems/common/utils';

export const MAX_MAIN_STEP_NUMBER = 3;

export enum InstallationStepType {
  'SiteDetail' = 1,
  'CustomerAgreement',
  'SystemSetting',
  'ArrayMapping',
}

export enum INSTALL_DONE {
  'DONE' = 1,
  'NOT' = 0,
}

export enum OFFLINE_FLAG {
  'OFFLINE' = 1,
  'ONLINE' = 0,
}

export const INSTALLATION_STATUS = {
  /** 완료 */
  COMPLETE: 'complete',
  /** 진행중 */
  IN_PROGRESS: 'inProgress',
  /** 미완료 */
  IN_COMPLETE: 'inComplete',
} as const;

export type InstallationStatusValue = ValueType<typeof INSTALLATION_STATUS>;

export const INSTALLATION_SUB_STEP = {
  SITE_CREATION: 'siteCreation',
  EMS_FIRST_SETTING: 'emsFirstSetting',
  EMS_SECOND_SETTING: 'emsSecondSetting',
  ARRAY_MAPPING: 'arrayMapping',
} as const;

export type InstallationSubStepValue = ValueType<typeof INSTALLATION_SUB_STEP>;

export const SITE_TYPE = {
  RESIDENTIAL: '1',
  COMMERCIAL: '2',
  OTHER_PURPOSE: '3',
  TEST: '4',
} as const;

export type SiteTypeValue = ValueType<typeof SITE_TYPE>;

export const RETAILER_CODE = {
  ARCSTREAM: '1',
  SYNERGY: '2',
  SYMPHONY: '3',
  NECTR: '4',
} as const;

export type RetailerCodeValue = ValueType<typeof RETAILER_CODE>;
