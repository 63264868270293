import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c912111"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "navItemCont",
  class: "nav-item-container"
}
const _hoisted_2 = { class: "nav-item-text" }
const _hoisted_3 = { class: "nav-item-text active" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["nav-item-root", { on: _ctx.state.hover }]),
    onMouseenter: _cache[0] || (_cache[0] = () => (_ctx.state.hover = true)),
    onMouseleave: _cache[1] || (_cache[1] = () => (_ctx.state.hover = false))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_Image, {
          src: _ctx.src,
          extension: "png"
        }, null, 8, ["src"])
      ]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
        (_ctx.description)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "description",
              innerHTML: _ctx.description
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true)
      ])
    ], 512)
  ], 34))
}