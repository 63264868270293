import { CellRendererProps } from 'tui-grid/types/renderer';

class CheckBoxRenderer {
  private el: HTMLElement;
  private hiddenInput: HTMLInputElement;

  constructor(props: CellRendererProps) {
    const { grid, rowKey } = props;

    const container = document.createElement('div');
    container.className = 'tac';

    const label = document.createElement('label');
    label.className = 'fm_chk';

    this.hiddenInput = document.createElement('input');
    this.hiddenInput.className = `hiddenInput_${props.columnInfo.renderer.options?.component}`;
    this.hiddenInput.type = 'checkbox';

    const spanContainer = document.createElement('span');
    spanContainer.className = 'in in_hide';

    const span = document.createElement('span');
    span.className = 'blind';
    span.innerText = 'Check';

    this.hiddenInput.addEventListener('change', () => {
      if (this.hiddenInput.checked) {
        grid.setValue(rowKey, 'isChecked', true);
        return;
      }
      grid.setValue(rowKey, 'isChecked', false);
    });

    spanContainer.appendChild(span);
    label.appendChild(this.hiddenInput);
    label.appendChild(spanContainer);
    container.appendChild(label);
    this.el = container;
    this.render(props);
  }

  getElement(): HTMLElement {
    return this.el;
  }

  async render(props: CellRendererProps): Promise<void> {
    const { grid, rowKey } = props;

    if (grid.getValue(rowKey, 'isChecked')) {
      grid.check(rowKey);
      this.hiddenInput.checked = true;
    } else {
      grid.uncheck(rowKey);
      this.hiddenInput.checked = false;
    }
  }
}

export default CheckBoxRenderer;
