import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f440cd3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "s-logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h1", _hoisted_1, [
    _createElementVNode("div", {
      class: "ci-image",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    })
  ]))
}