import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d72c490"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "left"
}
const _hoisted_2 = { class: "img-wrap" }
const _hoisted_3 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_ctx.operationMode)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "number",
          style: {"cursor":"pointer"},
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClickOperationMode')))
        }, [
          _createTextVNode(_toDisplayString(_ctx.operationModeText[_ctx.operationMode]) + " ", 1),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Image2, {
              src: "installation/ic_arrow_right",
              extension: "svg"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.isACSystem ? 'Operation Mode' : 'Energy Policy'), 1)
      ]))
    : _createCommentVNode("", true)
}