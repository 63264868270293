
import { defineComponent, PropType, ref } from 'vue';
import { SETTING_TAB_CODE, SettingTabValue, multiSettingTabName } from '@hems/util/src/constant/settings';
import { SelectorOption, SelectorValue } from 'hems';
import Selector from '../selectors/Selector/Selector.vue';

export default defineComponent({
  name: 'MultiSettingTab',
  components: {
    Selector,
  },
  props: {
    settingTargetType: {
      type: String as PropType<SettingTabValue>,
      required: true,
    },
    deviceTarget: {
      type: String as PropType<SelectorValue>,
      required: true,
    },
    selectorOptions: {
      type: Array as PropType<SelectorOption[]>,
      required: true,
    },
  },
  emits: ['update:settingTargetType', 'update:deviceTarget'],
  setup(props, { emit }) {
    const tabName = ref(props.settingTargetType);

    const selectedDevice = ref(props.deviceTarget);

    const switchTab = (tabname: SettingTabValue) => {
      tabName.value = tabname;
      emit('update:settingTargetType', tabName.value);
    };

    const onChange = () => {
      emit('update:deviceTarget', selectedDevice.value);
    };

    return {
      tabName,
      selectedDevice,
      multiSettingTabName,
      switchTab,
      onChange,
      SETTING_TAB_CODE,
    };
  },
});
