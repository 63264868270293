
import { defineComponent, ref, watch, computed } from 'vue';
import Image2 from '../image/Image2.vue';

type Type = 'general' | 'error' | 'success' | 'confirm' | 'promotion';
type Title = string;
type Message = string;
type MessageNotificationInstance = {
  on: boolean;
  type: Type;
  imageName: string;
  imageExtension: string;
  imageSize: number;
  title: Title;
  message: Message;
  isIcon: boolean;
  isButton: boolean;
  width: number;
  height: number;
  function: () => void;
  resolve?: (value: unknown) => void;
  reject?: (reason?: any) => void;
};
class MessageNotification {
  private _type: Type = 'error';
  private _imageName = 'ic_re_error_glass_img';
  private _imageExtension = 'svg';
  private _imageSize = 52;
  private _title: Title = '';
  private _message: Message = '';
  private _isIcon = true;
  private _isButton = true;
  private _width = 400;
  private _height = 268;
  private _function = () => {
    return this;
  };

  constructor() {
    this.init();
  }

  private init(type: Type = 'error') {
    this._type = type;
    this._imageName = type === 'error' || type === 'confirm' ? 'ic_re_error_glass_img' : 'ic_re_check_glass_img';
    this._imageExtension = 'svg';
    this._imageSize = type === 'error' || type === 'confirm' ? 52 : 102;
    this._title = '';
    this._message = '';
    return this;
  }

  private getInstance(resolve: (value: unknown) => void, reject: (reason?: any) => void): MessageNotificationInstance {
    return {
      on: true,
      type: this._type,
      imageName: this._imageName,
      imageExtension: this._imageExtension,
      imageSize: this._imageSize,
      title: this._title,
      message: this._message,
      isIcon: this._isIcon,
      isButton: this._isButton,
      width: this._width,
      height: this._height,
      function: this._function,
      resolve,
      reject,
    };
  }

  setMessageNotification(type: Type, title: Title, message: Message) {
    this.init(type);
    this._title = title;
    this._message = message;
    return this.chainSetMessage();
  }

  private chainSetMessage() {
    const self = this;
    return {
      open() {
        return new Promise((resolve, reject) => {
          _messageNotification.value.on = false;
          setTimeout(() => {
            _messageNotification.value = self.getInstance(resolve, reject);
          }, 250);
        });
      },
      setIsIcon(isIcon = true) {
        self._isIcon = isIcon;
        return this;
      },
      setIcon(iconName: string, iconExt: string, iconSize: number) {
        self._imageName = iconName;
        self._imageExtension = iconExt;
        self._imageSize = iconSize;
        return this;
      },
      setIsButton(isButton = true) {
        self._isButton = isButton;
        return this;
      },
      setSize(width: number, height: number) {
        self._width = width;
        self._height = height;
        return this;
      },
      setFunction(func: any) {
        self._function = func;
        return this;
      },
    };
  }
}

const _messageNotification = ref({} as MessageNotificationInstance);
const btnCancelEl = ref(null as null | HTMLButtonElement);
const close = () => {
  _messageNotification.value.on = false;
};

export const useMessageNotification = (): MessageNotification => new MessageNotification();

export default defineComponent({
  name: 'MessageNotification',
  components: {
    Image2,
  },
  setup() {
    const messageNotification = computed(() => _messageNotification.value);

    const onOk = () => {
      const { resolve } = messageNotification.value;
      if (resolve) resolve(true);
      close();
    };
    const onCancel = () => {
      const { resolve } = messageNotification.value;
      if (resolve) resolve(false);
      close();
    };
    const onProcess = () => {
      onOk();
      messageNotification.value.function();
    };
    watch(
      () => _messageNotification.value.on,
      (on) => {
        if (on) {
          setTimeout(() => {
            btnCancelEl.value?.focus();
          }, 250);
        }
      }
    );

    return {
      messageNotification,
      onOk,
      onCancel,
      onProcess,
      btnCancelEl,
    };
  },
});
