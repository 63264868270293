import { CellRendererProps } from 'tui-grid/types/renderer';
import { useMessageBox } from '@hems/component';
import { isNull } from '@hems/util/src/helper/helper';
import { ErrorCodeDisplayDetails } from 'hems/event';
import { CellValue } from 'tui-grid';
class AlarmDetailRenderer {
  private el: HTMLElement;
  private desc: JQuery<HTMLElement>;
  private button: JQuery<HTMLElement>;

  constructor(props: CellRendererProps) {
    const { $ } = window;
    const el = $('<div class="moredtail_con" style="display : inline-flex"></div>');

    this.desc = $(
      '<div style="width:200px; padding:0 5px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; align-self:center; "></div>'
    );
    this.button = $(
      '<div class="btn_i btn_c_3" style = "display : inline-block; float : right;"><span class="ir ico_arrow">상세보기</span></div>'
    );

    el.append(this.desc).append(this.button);

    this.el = el.get(0);
    this.render(props);
  }

  getElement(): HTMLElement {
    return this.el;
  }

  async render(props: CellRendererProps): Promise<void> {
    const messageBox = useMessageBox();
    const description = getStringValue(props.value);
    const errorDetails = props.grid.getValue(props.rowKey, 'display');
    if (isNull(description)) {
      this.button.hide();
      return;
    }
    const howToFix = getHowToFix(errorDetails);
    const detailInfo = !isNull(howToFix) ? [description, howToFix] : description;
    this.desc.text(description);
    this.button.on('click', () => messageBox.alert(detailInfo).open());
  }
}

const isErrorCodeDisplayDetails = (errorDetails: CellValue): errorDetails is ErrorCodeDisplayDetails => {
  return errorDetails !== null && typeof errorDetails === 'object' && 'description' in errorDetails;
};

const getStringValue = (description: CellValue): string => {
  if (typeof description === 'string') {
    return description;
  }
  return '';
};

const getHowToFix = (errorDetails: CellValue) => {
  if (isErrorCodeDisplayDetails(errorDetails) && errorDetails.howToFix) {
    const howToFix = errorDetails.howToFix.replaceAll('"', '');
    return `<br/>How to fix<br/>${howToFix}`;
  }
  return '';
};

export default AlarmDetailRenderer;
