
import type { PropType } from 'vue';
import { defineComponent, reactive, watch, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { GuideWrapper, withLoading, useMessageBox, Pagenation, ListSize } from '@hems/component';
import { Image2 } from '@hems/component';
import EventDetailPopup from '@hems/container/src/guide/EventDetailPopup.vue';
import SupportDetailPopup from '@hems/container/src/guide/SupportDetailPopup.vue';
import { SupportService } from '@hems/service';
import { Helper } from '@hems/util';

import type { Role } from 'hems';

import type { SupportInfo, SupportSearchBoxOptions, EventcodeInfo } from 'hems/support';

export default defineComponent({
  name: 'GuideContainer',
  components: {
    GuideWrapper,
    Image2,
    SupportDetailPopup,
    EventDetailPopup,
    Pagenation,
    ListSize,
  },
  props: {
    modelValue: {
      type: String as PropType<string>,
      required: true,
    },
    searchCondition: {
      type: Object as PropType<SupportSearchBoxOptions>,
      required: true,
    },
    tabname: {
      type: String as PropType<string>,
      required: true,
      default: 'all',
    },
    role: {
      type: String as PropType<Role>,
      required: true,
    },
  },

  async setup(props, { emit }) {
    const messageBox = useMessageBox();
    const { t } = useI18n();
    const supportService = new SupportService(window.axiosInstance.axios);
    const route = useRoute();
    const state = reactive({
      tabname: props.tabname as string,
      guidePopup: false,
      eventPopup: false,
      condition: {
        ...props.searchCondition,
        supportId: '',
        pdfFileName: '',
        pageNum: route.query.pageNum ? Number(route.query.pageNum as string) : 1,
        listSize: route.query.listSize ? Number(route.query.listSize as string) : 10,
        sortColumn: 'create_dt desc',
      },
      eventcodeId: '',
      isAllowedEdit: ['admin', 'dev'].includes(props.role ?? ''),
      currCnt: 0,
    });
    const supportId = ref<number | null>(null);
    const allGuideData = ref<SupportInfo[]>([]);
    const hardwareData = ref<SupportInfo[]>([]);
    const softwareData = ref<SupportInfo[]>([]);
    const eventcodeData = ref<EventcodeInfo[]>([]);
    const searchedGuideData = ref<SupportInfo[]>([]);

    watch(
      () => props.tabname,
      (tabname) => {
        state.tabname = tabname;
        searchList();
      }
    );

    watch(
      () => props.searchCondition,
      () => {
        Object.assign(state.condition, props.searchCondition);
        searchList();
      }
    );

    onMounted(() => {
      loadData();
    });

    const gridProps = reactive({
      guide: [] as SupportInfo[],
      eventcode: [] as EventcodeInfo[],
    });

    const filterHardwareData = (data: SupportInfo[]) =>
      data.filter(
        (data) =>
          data.categoryCode === '1' ||
          data.categoryCode === '2' ||
          data.categoryCode === '3' ||
          data.categoryCode === '4'
      );

    const filterSoftwareData = (data: SupportInfo[]) =>
      data.filter((data) => data.categoryCode === '5' || data.categoryCode === '6');

    const loadData = withLoading(async () => {
      try {
        gridProps.guide = await supportService.getGuideList(state.condition);
        gridProps.eventcode = await supportService.getEventcodeList(state.condition);

        allGuideData.value = gridProps.guide;
        hardwareData.value = filterHardwareData(gridProps.guide);
        softwareData.value = filterSoftwareData(gridProps.guide);
        eventcodeData.value = gridProps.eventcode;
      } catch (e) {
        gridProps.guide = [];
      }
    });

    const searchList = () => {
      state.condition.searchFor = state.condition.searchFor?.trim();
      if (!Helper.isNull(state.condition.searchFor)) {
        if (state.tabname == 'all') {
          const searchedData = gridProps.guide.filter((data: any) => {
            return data.pdfFileName
              .substring(0, data.pdfFileName.length - 4)
              .toLowerCase()
              .includes(state.condition?.searchFor?.toLowerCase() as any);
          });
          allGuideData.value = searchedData;
          searchedGuideData.value = searchedData;
        } else if (state.tabname == 'hardware_guide') {
          const searchedData = Object.values(filterHardwareData(gridProps.guide)).filter((data: any) => {
            return data.pdfFileName
              .substring(0, data.pdfFileName.length - 4)
              .toLowerCase()
              .includes(state.condition?.searchFor?.toLowerCase() as any);
          });
          hardwareData.value = searchedData;
          searchedGuideData.value = searchedData;
        } else if (state.tabname == 'software_guide') {
          const searchedData = Object.values(filterSoftwareData(gridProps.guide)).filter((data: any) => {
            return data.pdfFileName
              .substring(0, data.pdfFileName.length - 4)
              .toLowerCase()
              .includes(state.condition?.searchFor?.toLowerCase() as any);
          });
          softwareData.value = searchedData;
          searchedGuideData.value = searchedData;
        } else if (state.tabname == 'event_guide') {
          const searchedData = gridProps.eventcode.filter((data: any) => {
            return (
              data.alarmNm.toLowerCase().includes(state.condition?.searchFor?.toLowerCase() as any) ||
              data.alarmDesc.toLowerCase().includes(state.condition?.searchFor?.toLowerCase() as any) ||
              data.alarmCd.toLowerCase().includes(state.condition?.searchFor?.toLowerCase() as any)
            );
          });
          eventcodeData.value = searchedData;
        }
      } else {
        allGuideData.value = gridProps.guide;
        hardwareData.value = filterHardwareData(gridProps.guide);
        softwareData.value = filterSoftwareData(gridProps.guide);
        eventcodeData.value = gridProps.eventcode;
      }
    };

    function onCreate(type: any) {
      if (type == 'guide') {
        state.guidePopup = true;
      } else {
        state.eventPopup = true;
      }
    }
    function onEdit(data: number) {
      supportId.value = data;
      state.guidePopup = true;
    }
    async function onDelete(data: number | string) {
      if (await messageBox.confirm(t('message.ask_delete')).open()) {
        try {
          withLoading(async () => {
            if (typeof data === 'number') {
              supportId.value = data;
              if (!supportId.value) return;
              supportService.deleteGuide(supportId.value).then(() => {
                loadData();
              });
            } else {
              state.eventcodeId = data;
              if (!state.eventcodeId) return;
              supportService.deleteEventcode(state.eventcodeId).then(() => {
                loadData();
              });
            }
          })();
        } catch (e) {
          messageBox.alert(t('message.error_apply_setting')).open();
        }
      }
    }
    function onConfirm() {
      state.guidePopup = false;
      state.eventPopup = false;
      supportId.value = null;
      state.eventcodeId = '';
    }
    function onClose() {
      state.guidePopup = false;
      state.eventPopup = false;
      supportId.value = null;
      state.eventcodeId = '';
    }

    function onDetail(data: string) {
      state.eventcodeId = data;
      state.eventPopup = true;
    }

    return {
      state,
      loadData,
      searchList,
      onCreate,
      onConfirm,
      onClose,
      onEdit,
      onDetail,
      onDelete,
      supportId,
      hardwareData,
      softwareData,
      allGuideData,
      eventcodeData,
      searchedGuideData,
      isNull: Helper.isNull,
    };
  },
});
