import _ from 'lodash';

import { getValidator } from '@hems/container/src/forms/device/settings/_shared/advanced/advancedSettingsUtils';

import type { SettingConfigGroup } from 'hems/common/settingConfig';
import type { AdvancedSettingColumnRange } from 'hems/device/settings';
import type { AdvancedSettingsKey } from 'hems/device/settings/pvmodule/gen3';

export function getGen3Config(
  defaultConfig: SettingConfigGroup<AdvancedSettingsKey>[],
  rangeUnit: AdvancedSettingColumnRange[],
  grid_code?: number
) {
  const rangeUnitByGridCode = rangeUnit.filter((range) => range.grid_code === grid_code);
  const rangeUnitDefault = rangeUnit.filter((range) => range.grid_code === 0);

  return defaultConfig.map((group) => {
    const groupChildren = group.children.map((children) => {
      const rangeArray =
        rangeUnitByGridCode.filter((range) => range.col_nm === children.code) ??
        rangeUnitDefault.filter((range) => range.col_nm === children.code);
      if (!_.isEmpty(rangeArray)) {
        const target = rangeArray[0];
        if (children.type === 'number') {
          return {
            ...children,
            min: target.range_min,
            max: target.range_max,
            unit: target.range_unit && target.range_unit !== '-' ? target.range_unit : children.unit,
            schema: () => getValidator(target.range_min, target.range_max),
          };
        }

        return children;
      }

      return children;
    });

    return {
      ...group,
      children: groupChildren,
    };
  });
}
