/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
// 날짜 관련 값 (number)

import type { ValueType } from 'hems/common/utils';

/** 1440 : 분 단위의 하루 값 */
export const ONE_DAY_VALUE_IN_MINUTES = 24 * 60;
/** 60000 : 밀리초 단위의 1분 값 */
export const ONE_MINUTE_VALUE_IN_MILLISECONDS = 60 * 1000;
/** 3600000 : 밀리초 단위의 1시간 값 */
export const ONE_HOUR_VALUE_IN_MILLISECONDS = ONE_MINUTE_VALUE_IN_MILLISECONDS * 60;
/** 밀리초 단위 하루 값 */
export const ONE_DAY_VALUE_IN_MILLISECONDS = 1000 * 3600 * 24;

// 날짜 관련 값 (string)
export const START_TIME_VALUE = '00:00:00';
export const END_TIME_VALUE = '23:59:59';

// 날짜 관련 포맷
export const DASH_DATE_FORMAT = 'YYYY-MM-DD';
export const COLON_HOUR_MIN_FORMAT = 'HH:mm';

// TIMEZONE
export const UTC_TIME_ZONE = 'GMT';

export const DAY_RANGE_31 = 31;

export const DATE_UNIT = {
  DAYS: 'days',
  MONTHS: 'months',
  YEARS: 'years',
} as const;

export type DateUnitValue = ValueType<typeof DATE_UNIT>;

/** 50000: 밀리초 단위의 50초 값 */
export const FIFTY_SECONDS_VALUE_IN_MILLISECONDS = 50 * 1000;

/** 300000: 밀리초 단위의 5분 값 */
export const FIVE_MINUTES_VALUE_IN_MILLISECONDS = 5 * ONE_MINUTE_VALUE_IN_MILLISECONDS;

/** 10000: 밀리초 단위의 10초 값 */
export const TEN_SECONDS_VALUE_IN_MILLISECONDS = 10 * 1000;
