import _ from 'lodash';
import * as yup from 'yup';

import { Helper } from '@hems/util';

import type { SettingConfigGroup } from 'hems/common/settingConfig';
import type { AdvancedSettingsKey } from 'hems/device/settings/pvmodule/gen2';

const numberValidator = () =>
  yup
    .number()
    .transform((v, o) => (o === '' ? null : v))
    .nullable();

export function getGEN2ConfigByGridCode(gridCode?: number): SettingConfigGroup<AdvancedSettingsKey>[] {
  switch (gridCode) {
    case 276:
      return advancedSettingGen2Config276;
    case 36:
      return advancedSettingGen2Config36;
    case 361:
    case 362:
    case 363:
    case 5541:
      return advancedSettingGen2Config361;
    case 861:
      return advancedSettingGen2Config826;
    default:
      return [];
  }
}

// 독일 (VDE-AR-N 4105 : 2018)
export const advancedSettingGen2Config276: SettingConfigGroup<AdvancedSettingsKey>[] = [
  {
    groupTitle: 'Active Power SetPoint',
    children: [
      {
        title: 'SetPoint',
        code: 'gc_active_power_setpoint_enable',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'SetPoint Value',
        code: 'gc_active_power_setpoint_value',
        type: 'number',
        min: '0',
        max: '100',
        schema: () => numberValidator().integer().min(0).max(100),
      },
    ],
  },
  {
    groupTitle: 'Active Power Frequency',
    children: [
      {
        title: 'SH: Delta P',
        code: 'gc_active_power_frequency_sh',
        type: 'number',
        min: '2',
        max: '15',
        description: '0, 2 ~ 15',
        schema: () =>
          numberValidator().test(
            'gc_active_power_frequency_sh',
            { key: 'message.invalid' },
            function (value?: number | null) {
              try {
                const { createError } = this;
                if (Helper.isNull(value) || value === 0) {
                  return true;
                }
                if (value < 2) {
                  return createError({
                    message: { key: 'message.greater_min', values: { min: 2 } },
                  });
                }
                if (value > 15) {
                  return createError({
                    message: { key: 'message.greater_max', values: { max: 15 } },
                  });
                }

                return true;
              } catch (error) {
                return false;
              }
            }
          ),
      },
      {
        title: 'X3: Frequency',
        code: 'gc_active_power_frequency_x3',
        type: 'number',
        min: '50.20',
        max: '50.50',
        schema: () => numberValidator().min(50.2).max(50.5).unitNumber(0.01, 50.2),
      },
    ],
  },
  {
    groupTitle: 'PT1 Filter behavior : Tau',
    children: [
      {
        title: 'Tau Value',
        code: 'gc_reactive_pt1_filter_tau',
        type: 'number',
        min: '6',
        max: '60',
        unit: 'msec',
        schema: () => numberValidator().integer().min(6).max(60),
      },
    ],
  },
  {
    groupTitle: 'Power Factor SetPoint',
    children: [
      {
        title: 'SetPoint',
        code: 'gc_reactive_power_setpoint_enable',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'SetPoint Excited',
        code: 'gc_reactive_power_setpoint_excited',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'SetPoint Value',
        code: 'gc_reactive_power_setpoint_value',
        type: 'number',
        min: '0.80',
        max: '1.00',
        schema: () => numberValidator().min(0.8).max(1).unitNumber(0.01, 0.8),
      },
    ],
  },
  {
    groupTitle: 'Reactive Power Cospi(P)',
    children: [
      {
        title: 'Cospi(P)',
        code: 'gc_reactive_power_cospi_enable',
        type: 'toggle',
      },
      {
        title: 'Cospi(P) Excited',
        code: 'gc_reactive_power_cospi_excited',
        type: 'toggle',
        schema: () => numberValidator(),
      },
    ],
  },
];

// 호주 (AS/NZS 4777.2:2015)
export const advancedSettingGen2Config36: SettingConfigGroup<AdvancedSettingsKey>[] = [
  {
    groupTitle: '10Min Voltage',
    children: [
      {
        title: 'In',
        code: 'gc_10min_voltage_in',
        type: 'number',
        min: '1.06',
        max: '1.12',
        unit: 'Un',
        schema: () => numberValidator().min(1.06).max(1.12).unitNumber(0.01, 1.06),
      },
    ],
  },
  {
    groupTitle: 'Active Power SetPoint',
    children: [
      {
        title: 'SetPoint',
        code: 'gc_active_power_setpoint_enable',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'SetPoint Value',
        code: 'gc_active_power_setpoint_value',
        type: 'number',
        min: '0',
        max: '100',
        schema: () => numberValidator().integer().min(0).max(100),
      },
    ],
  },
  {
    groupTitle: 'Active Power Frequency',
    children: [
      {
        title: 'X1: Frequency',
        code: 'gc_active_power_frequency_x1',
        type: 'number',
        min: '47.00',
        max: '49.00',
        schema: () => numberValidator().min(47).max(49).unitNumber(0.01, 47),
      },
      {
        title: 'X2: Frequency',
        code: 'gc_active_power_frequency_x2',
        type: 'number',
        description: '49.75 / Fix',
        schema: () => numberValidator().min(49.75).max(49.75),
      },
      {
        title: 'X3: Frequency',
        code: 'gc_active_power_frequency_x3',
        type: 'number',
        description: '50.25 / Fix',
        schema: () => numberValidator().min(50.25).max(50.25),
      },
      {
        title: 'X4: Frequency',
        code: 'gc_active_power_frequency_x4',
        type: 'number',
        min: '51.00',
        max: '52.00',
        schema: () => numberValidator().min(51).max(52).unitNumber(0.01, 51),
      },
    ],
  },
  {
    groupTitle: 'Active Power Voltage',
    children: [
      {
        title: 'Voltage',
        code: 'gc_active_power_voltage_enable',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'X1: Voltage',
        code: 'gc_active_power_voltage_x1',
        type: 'number',
        description: '0.9 / Fix',
        schema: () => numberValidator().min(0.9).max(0.9),
      },
      {
        title: 'X2: Voltage',
        code: 'gc_active_power_voltage_x2',
        type: 'number',
        min: '0.93',
        max: '1.00',
        schema: () => numberValidator().min(0.93).max(1.0).unitNumber(0.01, 0.93),
      },
      {
        title: 'X3: Voltage',
        code: 'gc_active_power_voltage_x3',
        type: 'number',
        min: '1.02',
        max: '1.11',
        schema: () => numberValidator().min(1.02).max(1.11).unitNumber(0.01, 1.02),
      },
      {
        title: 'X4: Voltage',
        code: 'gc_active_power_voltage_x4',
        type: 'number',
        min: '1.06',
        max: '1.15',
        schema: () => numberValidator().min(1.06).max(1.15).unitNumber(0.01, 1.06),
      },
    ],
  },
  {
    groupTitle: 'Power Factor SetPoint',
    children: [
      {
        title: 'SetPoint',
        code: 'gc_reactive_power_setpoint_enable',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'SetPoint Excited',
        code: 'gc_reactive_power_setpoint_excited',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'SetPoint Value',
        code: 'gc_reactive_power_setpoint_value',
        type: 'number',
        min: '0.80',
        max: '1.00',
        schema: () => numberValidator().min(0.8).max(1).unitNumber(0.01, 0.8),
      },
    ],
  },
  {
    groupTitle: 'Reactive Power Cospi(P)',
    children: [
      {
        title: 'Cospi(P)',
        code: 'gc_reactive_power_cospi_enable',
        type: 'toggle',
      },
      {
        title: 'Cospi(P) Excited',
        code: 'gc_reactive_power_cospi_excited',
        type: 'toggle',
        schema: () => numberValidator(),
      },
    ],
  },
  {
    groupTitle: 'Reactive Power Q(SetPoint)',
    children: [
      {
        title: 'Q(SetPoint)',
        code: 'gc_reactive_power_q_setpoint_enable',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'Q(SetPoint) Excited',
        code: 'gc_reactive_power_q_setpoint_excited',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'SetPoint Value',
        code: 'gc_reactive_power_q_setpoint_value',
        type: 'number',
        min: '0.000',
        max: '0.600',
        schema: () => numberValidator().min(0).max(0.6).unitNumber(0.001, 0),
      },
    ],
  },
  {
    groupTitle: 'Reactive Power Q(U)',
    children: [
      {
        title: 'Q(U)',
        code: 'gc_reactive_power_q_u_enable',
        type: 'toggle',
      },
      {
        title: 'X1: Voltage',
        code: 'gc_reactive_power_q_u_x1',
        type: 'number',
        description: '0.9000 / Fix',
        schema: () => numberValidator().min(0.9).max(0.9),
      },
      {
        title: 'Y1: Reactive',
        code: 'gc_reactive_power_q_u_y1',
        type: 'number',
        min: '-60.00',
        max: '0.00',
        schema: () => numberValidator().min(-60).max(0).unitNumber(0.01, 0),
      },
      {
        title: 'X2: Voltage',
        code: 'gc_reactive_power_q_u_x2',
        type: 'number',
        min: '0.9391',
        max: '1.0000',
        schema: () => numberValidator().min(0.9391).max(1.0).unitNumber(0.0001, 0.9391),
      },
      {
        title: 'Y2: Reactive',
        code: 'gc_reactive_power_q_u_y2',
        type: 'number',
        description: '0.00 / Fix',
        schema: () => numberValidator().min(0).max(0),
      },
      {
        title: 'X3: Voltage',
        code: 'gc_reactive_power_q_u_x3',
        type: 'number',
        min: '1.0217',
        max: '1.1087',
        schema: () => numberValidator().min(1.0217).max(1.1087).unitNumber(0.0001, 1.0217),
      },
      {
        title: 'Y3: Reactive',
        code: 'gc_reactive_power_q_u_y3',
        type: 'number',
        description: '0.00 / Fix',
        schema: () => numberValidator().min(0).max(0),
      },
      {
        title: 'X4: Voltage',
        code: 'gc_reactive_power_q_u_x4',
        type: 'number',
        min: '1.0609',
        max: '1.1522',
        schema: () => numberValidator().min(1.0609).max(1.1522).unitNumber(0.0001, 0.0609),
      },
      {
        title: 'Y4: Reactive',
        code: 'gc_reactive_power_q_u_y4',
        type: 'number',
        min: '0.00',
        max: '60.00',
        schema: () => numberValidator().min(0).max(60).unitNumber(0.01, 0),
      },
    ],
  },
  {
    groupTitle: 'Gradient Control',
    children: [
      {
        title: 'Energy Source Change Enable',
        code: 'gc_gradient_control_energy_source_change_enable',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'Time_Level',
        code: 'gc_gradient_control_timelevel',
        type: 'number',
        min: '60',
        max: '1200',
        unit: 'sec',
        schema: () => numberValidator().integer().min(60).max(1200),
      },
    ],
  },
];

// 호주(AS/NZS 4777.2:2020) - 호주(A), 호주(B), 호주(C)
// 뉴질랜드(AS/NZS 4777.2:2020)
export const advancedSettingGen2Config361: SettingConfigGroup<AdvancedSettingsKey>[] = [
  {
    groupTitle: '10Min Voltage',
    children: [
      {
        title: 'In',
        code: 'gc_10min_voltage_in',
        type: 'number',
        min: '1.06',
        max: '1.12',
        unit: 'Un',
        schema: () => numberValidator().min(1.06).max(1.12).unitNumber(0.01, 1.06),
      },
    ],
  },
  {
    groupTitle: 'Active Power SetPoint',
    children: [
      {
        title: 'SetPoint',
        code: 'gc_active_power_setpoint_enable',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'SetPoint Value',
        code: 'gc_active_power_setpoint_value',
        type: 'number',
        min: '0',
        max: '100',
        schema: () => numberValidator().integer().min(0).max(100),
      },
    ],
  },
  {
    groupTitle: 'Active Power Frequency',
    children: [
      {
        title: 'X1: Frequency',
        code: 'gc_active_power_frequency_x1',
        type: 'number',
        min: '47.00',
        max: '49.00',
        schema: () => numberValidator().min(47).max(49).unitNumber(0.01, 47),
      },
      {
        title: 'X2: Frequency',
        code: 'gc_active_power_frequency_x2',
        type: 'number',
        min: '49.50',
        max: '49.90',
        schema: () => numberValidator().min(49.5).max(49.9).unitNumber(0.01, 49.5),
      },
      {
        title: 'X3: Frequency',
        code: 'gc_active_power_frequency_x3',
        type: 'number',
        min: '50.10',
        max: '50.50',
        schema: () => numberValidator().min(50.1).max(50.5).unitNumber(0.01, 50.1),
      },
      {
        title: 'X4: Frequency',
        code: 'gc_active_power_frequency_x4',
        type: 'number',
        min: '51.00',
        max: '53.00',
        schema: () => numberValidator().min(51).max(53).unitNumber(0.01, 51),
      },
      {
        title: 'fStop_CH : Frequency',
        code: 'gc_active_power_frequency_fstop_ch',
        type: 'number',
        min: '48.00',
        max: '49.50',
        schema: () => numberValidator().min(48).max(49.5).unitNumber(0.01, 48),
      },
      {
        title: 'fTransition : Frequency',
        code: 'gc_active_power_frequency_ftransition',
        type: 'number',
        min: '50.50',
        max: '52.00',
        schema: () => numberValidator().min(50.5).max(52).unitNumber(0.01, 50.5),
      },
    ],
  },
  {
    groupTitle: 'Active Power Voltage',
    children: [
      {
        title: 'Voltage',
        code: 'gc_active_power_voltage_enable',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'Y1: Power',
        code: 'gc_active_power_voltage_y1',
        type: 'number',
        min: '0.00',
        max: '1.00',
        schema: () => numberValidator().min(0).max(1).unitNumber(0.01, 0),
      },
      {
        title: 'X1: Voltage',
        code: 'gc_active_power_voltage_x1',
        type: 'number',
        min: '0.80',
        max: '1.25',
        schema: () => numberValidator().min(0.8).max(1.25).unitNumber(0.01, 0.8),
      },
      {
        title: 'X2: Voltage',
        code: 'gc_active_power_voltage_x2',
        type: 'number',
        min: '0.80',
        max: '1.25',
        schema: () => numberValidator().min(0.8).max(1.25).unitNumber(0.01, 0.8),
      },
      {
        title: 'X3: Voltage',
        code: 'gc_active_power_voltage_x3',
        type: 'number',
        min: '1.00',
        max: '1.25',
        schema: () => numberValidator().min(0.8).max(1.25).unitNumber(0.01, 0.8),
      },
      {
        title: 'X4: Voltage',
        code: 'gc_active_power_voltage_x4',
        type: 'number',
        min: '1.00',
        max: '1.25',
        schema: () => numberValidator().min(0.8).max(1.25).unitNumber(0.01, 0.8),
      },
      {
        title: 'Y4: Power',
        code: 'gc_active_power_voltage_y4',
        type: 'number',
        min: '0.00',
        max: '1.00',
        schema: () => numberValidator().min(0).max(1).unitNumber(0.01, 0),
      },
    ],
  },
  {
    groupTitle: 'Power Factor SetPoint',
    children: [
      {
        title: 'SetPoint',
        code: 'gc_reactive_power_setpoint_enable',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'SetPoint Excited',
        code: 'gc_reactive_power_setpoint_excited',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'SetPoint Value',
        code: 'gc_reactive_power_setpoint_value',
        type: 'number',
        min: '0.80',
        max: '1.00',
        schema: () => numberValidator().min(0.8).max(1).unitNumber(0.01, 0.8),
      },
    ],
  },
  {
    groupTitle: 'Reactive Power Cospi(P)',
    children: [
      {
        title: 'Cospi(P)',
        code: 'gc_reactive_power_cospi_enable',
        type: 'toggle',
      },
      {
        title: 'Cospi(P) Excited',
        code: 'gc_reactive_power_cospi_excited',
        type: 'toggle',
        schema: () => numberValidator(),
      },
    ],
  },
  {
    groupTitle: 'Reactive Power Q(SetPoint)',
    children: [
      {
        title: 'Q(SetPoint)',
        code: 'gc_reactive_power_q_setpoint_enable',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'Q(SetPoint) Excited',
        code: 'gc_reactive_power_q_setpoint_excited',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'SetPoint Value',
        code: 'gc_reactive_power_q_setpoint_value',
        type: 'number',
        min: '0.000',
        max: '0.600',
        schema: () => numberValidator().min(0).max(0.6).unitNumber(0.001, 0),
      },
    ],
  },
  {
    groupTitle: 'Reactive Power Q(U)',
    children: [
      {
        title: 'Q(U)',
        code: 'gc_reactive_power_q_u_enable',
        type: 'toggle',
      },
      {
        title: 'X1: Voltage',
        code: 'gc_reactive_power_q_u_x1',
        type: 'number',
        min: '0',
        max: '1.9999',
        schema: () => numberValidator().min(0).max(1.9999).unitNumber(0.0001, 0),
      },
      {
        title: 'Y1: Reactive',
        code: 'gc_reactive_power_q_u_y1',
        type: 'number',
        min: '-60.00',
        max: '0.00',
        schema: () => numberValidator().min(-60).max(0).unitNumber(0.01, -60),
      },
      {
        title: 'X2: Voltage',
        code: 'gc_reactive_power_q_u_x2',
        type: 'number',
        min: '0',
        max: '1.9999',
        schema: () => numberValidator().min(0).max(1.9999).unitNumber(0.0001, 0),
      },
      {
        title: 'Y2: Reactive',
        code: 'gc_reactive_power_q_u_y2',
        type: 'number',
        description: '0.00 / Fix',
        schema: () => numberValidator().min(0).max(0),
      },
      {
        title: 'X3: Voltage',
        code: 'gc_reactive_power_q_u_x3',
        type: 'number',
        min: '0',
        max: '1.9999',
        schema: () => numberValidator().min(0).max(1.9999).unitNumber(0.0001, 0),
      },
      {
        title: 'Y3: Reactive',
        code: 'gc_reactive_power_q_u_y3',
        type: 'number',
        description: '0.00 / Fix',
        schema: () => numberValidator().min(0).max(0),
      },
      {
        title: 'X4: Voltage',
        code: 'gc_reactive_power_q_u_x4',
        type: 'number',
        min: '0',
        max: '1.9999',
        schema: () => numberValidator().min(0).max(1.9999).unitNumber(0.0001, 0),
      },
      {
        title: 'Y4: Reactive',
        code: 'gc_reactive_power_q_u_y4',
        type: 'number',
        min: '0.00',
        max: '60.00',
        schema: () => numberValidator().min(0).max(60).unitNumber(0.01, 0),
      },
    ],
  },
  {
    groupTitle: 'Gradient Control',
    children: [
      {
        title: 'Energy Source Change Enable',
        code: 'gc_gradient_control_energy_source_change_enable',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'Time_Level',
        code: 'gc_gradient_control_timelevel',
        type: 'number',
        min: '60',
        max: '1200',
        unit: 'sec',
        schema: () => numberValidator().integer().min(60).max(1200),
      },
    ],
  },
  {
    groupTitle: 'Limit Control',
    children: [
      {
        title: 'Export Hard Limit Flag',
        code: 'limitctrl_exporthard_flag',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'Export Hard Limit Level',
        code: 'limitctrl_exporthard_level',
        type: 'number',
        min: '0',
        max: '100',
        unit: '%',
        schema: () => numberValidator().integer().min(0).max(100),
      },
      {
        title: 'Export Hard Limit Time',
        code: 'limitctrl_exporthard_time',
        type: 'number',
        min: '0',
        max: '100',
        unit: 'Sec',
        schema: () => numberValidator().integer().min(0).max(100),
      },
      {
        title: 'Export Soft Limit Flag',
        code: 'limitctrl_exportsoft_flag',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'Export Soft Limit Level',
        code: 'limitctrl_exportsoft_level',
        type: 'number',
        min: '0',
        max: '100',
        unit: '%',
        schema: () => numberValidator().integer().min(0).max(100),
      },
      {
        title: 'Export Soft Limit Time',
        code: 'limitctrl_exportsoft_time',
        type: 'number',
        min: '0',
        max: '100',
        unit: 'Sec',
        schema: () => numberValidator().integer().min(0).max(100),
      },
      {
        title: 'Generation Hard Limit Flag',
        code: 'limitctrl_generationhard_flag',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'Generation Hard Limit Level',
        code: 'limitctrl_generationhard_level',
        type: 'number',
        min: '0',
        max: '100',
        unit: '%',
        schema: () => numberValidator().integer().min(0).max(100),
      },
      {
        title: 'Generation Hard Limit Time',
        code: 'limitctrl_generationhard_time',
        type: 'number',
        min: '0',
        max: '100',
        unit: 'Sec',
        schema: () => numberValidator().integer().min(0).max(100),
      },
      {
        title: 'Generation Soft Limit Flag',
        code: 'limitctrl_generationsoft_flag',
        type: 'toggle',
        schema: () => numberValidator(),
      },
      {
        title: 'Generation Soft Limit Level',
        code: 'limitctrl_generationsoft_level',
        type: 'number',
        min: '0',
        max: '100',
        unit: '%',
        schema: () => numberValidator().integer().min(0).max(100),
      },
      {
        title: 'Generation Soft Limit Time',
        code: 'limitctrl_generationsoft_time',
        type: 'number',
        min: '0',
        max: '100',
        unit: 'Sec',
        schema: () => numberValidator().integer().min(0).max(100),
      },
    ],
  },
];

// 영국(G99/1-5: 2019)
export const advancedSettingGen2Config826: SettingConfigGroup<AdvancedSettingsKey>[] = [
  {
    groupTitle: 'Active Power Frequency',
    children: [
      {
        title: 'SH: Delta P',
        code: 'gc_active_power_frequency_sh',
        type: 'number',
        min: '2',
        max: '15',
        description: '0, 2 ~ 12',
        schema: () =>
          numberValidator().test(
            'gc_active_power_frequency_sh',
            { key: 'message.invalid' },
            function (value?: number | null) {
              try {
                const { createError } = this;
                if (Helper.isNull(value) || value === 0) {
                  return true;
                }
                if (value < 2) {
                  return createError({
                    message: { key: 'message.greater_min', values: { min: 2 } },
                  });
                }
                if (value > 12) {
                  return createError({
                    message: { key: 'message.greater_max', values: { max: 12 } },
                  });
                }

                return true;
              } catch (error) {
                return false;
              }
            }
          ),
      },
    ],
  },
];

export function getGen2Config(defaultConfig: SettingConfigGroup<AdvancedSettingsKey>[], grid_code?: number) {
  let configByGridCode: SettingConfigGroup<AdvancedSettingsKey>[] = [];

  if (!grid_code) return defaultConfig;

  configByGridCode = getGEN2ConfigByGridCode(grid_code);

  return defaultConfig.map((group) => {
    const groupTitle = group.groupTitle;
    const targetGroup = configByGridCode.filter((group) => group.groupTitle === groupTitle);

    if (!_.isEmpty(targetGroup)) {
      const target = targetGroup[0];
      const groupChildren = group.children.map((children) => {
        const replacedChildren = target.children.filter((item) => item.code === children.code);
        if (!_.isEmpty(replacedChildren)) {
          return replacedChildren[0];
        }

        return children;
      });

      return {
        groupTitle,
        children: groupChildren,
      };
    }

    return group;
  });
}
