
import { defineComponent } from 'vue';

import PopupWrapper from './PopupWrapper.vue';

export default defineComponent({
  name: 'IdleTimePopup',
  components: {
    PopupWrapper,
  },
  emits: ['confirm', 'close'],
  setup(props, { emit }) {
    const close = () => {
      emit('close');
    };

    const confirm = () => {
      emit('confirm');
    };

    return {
      confirm,
      close,
    };
  },
});
