
import type { PropType } from 'vue';
import { defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import {
  getChartTemplateAndData,
  getGraphDataByGroupCodes,
  getXAxisCategories,
  updateChartData,
} from '@hems/container/src/forms/device/statistics/_shared';
import IntegratedDeviceLineGraph from '@hems/container/src/highcharts/spline/IntegratedDeviceLineGraph.vue';
import { useACDeviceType, useGenType, useRole } from '@hems/util';
import { AC_DEVICE_TYPE, GRAPH_GROUP_CODE, GRAPH_Y_AXIS_UNIT } from '@hems/util/src/constant';
import type { ACDeviceTypeValue } from '@hems/util/src/constant';

import type { FilterGroupMinute, GroupCode, SimpleSeries, StatisticsData } from 'hems/device/statistics/common';

import {
  getACCombinerConfig,
  getBatteryConfig,
  getEnergyMinuteConfig,
  getInverterConfig,
  getMeterConfig,
  getPowerConfig,
  getPvConfig,
} from './_shared/filterConfig';
import { getDefaultMinuteKeys, getMinuteKeysByGenType } from './_shared/graphKeys';

export default defineComponent({
  name: 'StatisticsMinuteIntegratedContainer',
  components: {
    IntegratedDeviceLineGraph,
  },
  props: {
    data: {
      type: Array as PropType<StatisticsData[]>,
      default: () => [],
    },
    acDeviceType: {
      type: String as PropType<ACDeviceTypeValue>,
      default: AC_DEVICE_TYPE.AC_COMBINER,
    },
    isAcCoupled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const xAxisCategories = ref<string[]>([]);
    const graphData = ref<SimpleSeries[]>([]);

    const { genType, isACSystem } = useGenType();
    const { ACDeviceType, hasACCombiner, isCase1 } = useACDeviceType();
    const isShowACCombinerGraph = isACSystem && hasACCombiner;
    const { isAdminGroup, roleName } = useRole();

    const getGraphKeys = () => {
      if (genType.value) {
        return getMinuteKeysByGenType({
          genType: genType.value,
          roleName: roleName.value,
          ACDeviceType: ACDeviceType.value,
          isACCoupled: props.isAcCoupled,
        });
      }

      return getDefaultMinuteKeys();
    };

    const graphKeys = getGraphKeys();

    const getMinuteGraphConfig = () => {
      if (genType.value) {
        return [
          getPowerConfig(graphKeys.power, genType.value),
          getEnergyMinuteConfig(graphKeys.energy, genType.value),
          getACCombinerConfig(graphKeys.ACCombiner),
          getPvConfig(graphKeys.pv, genType.value),
          getInverterConfig(graphKeys.inverter, genType.value),
          getMeterConfig(graphKeys.meter, genType.value),
          getBatteryConfig(graphKeys.battery, genType.value),
        ];
      }

      return [];
    };

    const { chartDataTemplate, chartData } = getChartTemplateAndData<FilterGroupMinute>(getMinuteGraphConfig());

    const getFilterList = (filterGroup: GroupCode) => chartDataTemplate[filterGroup]?.filterList ?? [];

    const getFilterGroupName = (filterGroup: GroupCode) => chartDataTemplate[filterGroup]?.filterGroupName ?? '';

    const getTitleAndFilterList = () => {
      let titleList = [`${t(getFilterGroupName(GRAPH_GROUP_CODE.POWER))} [${GRAPH_Y_AXIS_UNIT.WATT}]`];
      let filterList = [getFilterList(GRAPH_GROUP_CODE.POWER)];

      if (isShowACCombinerGraph && isAdminGroup) {
        titleList.push(
          `${t(getFilterGroupName(GRAPH_GROUP_CODE.AC_COMBINER), {
            defaultValue: getFilterGroupName(GRAPH_GROUP_CODE.AC_COMBINER),
          })}`
        );
        filterList.push(getFilterList(GRAPH_GROUP_CODE.AC_COMBINER));
      }

      if (!isACSystem || !isCase1) {
        titleList.push(`${t(getFilterGroupName(GRAPH_GROUP_CODE.INVERTER))}`);
        filterList.push(getFilterList(GRAPH_GROUP_CODE.INVERTER));
      }

      if (!isACSystem) {
        titleList.push(
          `${t(getFilterGroupName(GRAPH_GROUP_CODE.PV), {
            defaultValue: getFilterGroupName(GRAPH_GROUP_CODE.PV),
          })}`
        );
        filterList.push(getFilterList(GRAPH_GROUP_CODE.PV));
      }

      titleList.push(
        `${t(getFilterGroupName(GRAPH_GROUP_CODE.METER), {
          defaultValue: getFilterGroupName(GRAPH_GROUP_CODE.METER),
        })}`
      );
      filterList.push(getFilterList(GRAPH_GROUP_CODE.METER));

      if (!isACSystem || !isCase1) {
        titleList.push(`${t(getFilterGroupName(GRAPH_GROUP_CODE.BATTERY))}`);
        filterList.push(getFilterList(GRAPH_GROUP_CODE.BATTERY));
      }

      titleList.push(`${t(getFilterGroupName(GRAPH_GROUP_CODE.ENERGY))} [${GRAPH_Y_AXIS_UNIT.WATT_HOUR}]`);
      filterList.push(getFilterList(GRAPH_GROUP_CODE.ENERGY));

      return { titleList, filterList };
    };

    const { titleList, filterList } = getTitleAndFilterList();

    watch(
      () => props.data,
      (data) => {
        xAxisCategories.value = getXAxisCategories(data);
        updateChartData(chartData, data, {
          power: graphKeys.power,
          energy: graphKeys.energy,
          ACCombiner: graphKeys.ACCombiner,
          pv: graphKeys.pv,
          inverter: graphKeys.inverter,
          meter: graphKeys.meter,
          battery: graphKeys.battery,
        });
        const { POWER, AC_COMBINER, PV, INVERTER, METER, BATTERY, ENERGY } = GRAPH_GROUP_CODE;
        if (!isACSystem) {
          graphData.value = getGraphDataByGroupCodes([POWER, PV, INVERTER, METER, BATTERY, ENERGY], chartData);
        } else if (isCase1) {
          graphData.value = getGraphDataByGroupCodes([POWER, AC_COMBINER, METER, ENERGY], chartData);
        } else if (isShowACCombinerGraph) {
          graphData.value = getGraphDataByGroupCodes([POWER, AC_COMBINER, INVERTER, METER, BATTERY, ENERGY], chartData);
        } else {
          graphData.value = getGraphDataByGroupCodes([POWER, INVERTER, METER, BATTERY, ENERGY], chartData);
        }
      }
    );

    return {
      titleList,
      filterList,
      graphData,
      xAxisCategories,
      chartDataTemplate,
    };
  },
});
