
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { PopupWrapper } from '@hems/component';
import { useLocale, useMessageBox } from '@hems/util';

import MobileAppLink from '@/components/link/MobileAppLink.vue';
import { getMobileAppLinkConfig } from '@/config/appLinkConfig';
import { APP_STORE_TYPE, AppStoreType, MOBILE_APP_TYPE, MobileAppType } from '@/config/ConstantPortal';

const mobileAppTypeList = [MOBILE_APP_TYPE.GO, MOBILE_APP_TYPE.PRO, MOBILE_APP_TYPE.HOME];

export default defineComponent({
  name: 'MobileAppLinkPopup',
  components: {
    PopupWrapper,
    MobileAppLink,
  },
  props: {
    storeType: {
      type: String as PropType<AppStoreType>,
      default: APP_STORE_TYPE.GOOGLE,
    },
  },
  emits: ['close'],
  setup(props) {
    const { t } = useI18n();

    const { alertMessageBox } = useMessageBox();
    const { isUS } = useLocale();

    const mobileAppLinkConfig = getMobileAppLinkConfig(isUS);

    const openStoreUrl = (appType: MobileAppType) => {
      if (!props.storeType) {
        alertMessageBox(t('message.unknown_error'));

        return;
      }

      const link = mobileAppLinkConfig[appType].link[props.storeType];
      if (!link) {
        alertMessageBox(t('message.unknown_error'));

        return;
      }
      window.open(link, '_blank');
    };

    return {
      openStoreUrl,
      mobileAppTypeList,
      mobileAppLinkConfig,
    };
  },
});
