
import { defineComponent, reactive, PropType, computed } from 'vue';
import { SimpleTableColumm } from './types';

export default defineComponent({
  name: 'SimpleTable',
  props: {
    id: String,
    editable: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array as PropType<SimpleTableColumm[]>,
      required: true,
    },
    data: {
      type: Array as PropType<Record<string, any>[]>,
      default: () => [],
    },
    sort: Function,
  },
  setup(props) {
    const state = reactive({
      data: props.data,
    });
    const contentAlign = computed(() => {
      return props.columns.reduce((prev, curr) => {
        return { ...prev, [curr.id]: curr.contentAlign ?? 'center' };
      }, {});
    });
    const orderedColumnIds = computed(() => {
      return props.columns.reduce<string[]>((prev, curr) => {
        return prev.concat(curr.id);
      }, []);
    });

    return {
      state,
      orderedColumnIds,
      contentAlign,
    };
  },
});
