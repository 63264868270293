
import { defineComponent } from 'vue';
import { Loading, MessageBox, ErrorNotification, MessageNotification, MobilePopup } from '@hems/component';

export default defineComponent({
  name: 'MainTemplate',
  components: {
    Loading,
    MessageBox,
    ErrorNotification,
    MessageNotification,
    MobilePopup,
  },
});
