
import { defineComponent, computed, PropType } from 'vue';
import Image2 from './Image.vue';
export default defineComponent({
  name: 'ImageDescription',
  components: {
    Image2,
  },
  props: {
    messageType: {
      type: String as PropType<'info' | 'warn' | 'notice'>,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    fontColor: {
      type: String,
      default: '#000000',
    },
  },
  setup(props) {
    const description = computed(() => props.message);
    function getBackgroundColor(messageType: string) {
      if (messageType === 'info') {
        return '#f6f6f6';
      } else if (messageType === 'warn') {
        return '#fff4f5';
      } else if (messageType === 'notice') {
        return '#fff9f3';
      } else {
        return '';
      }
    }
    function getImageSrc(messageType: string) {
      if (messageType === 'info') {
        return 'svg/ic_re_info_default';
      } else if (messageType === 'warn') {
        return 'svg/ic_error';
      } else if (messageType === 'notice') {
        return 'svg/ic_re_info';
      } else {
        return '';
      }
    }

    return {
      description,
      getBackgroundColor,
      getImageSrc,
    };
  },
});
