import { AxiosInstance } from 'axios';
import { apiWrapper } from '../../util/helper';
import EventService from './EventService';
import { EventInfo, EventListSearchOptions } from 'hems/event';
import moment from 'moment';
import { CommonResponseWrapper } from 'hems';
export default class EventServiceInstaller extends EventService {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getEventList(params: EventListSearchOptions): Promise<EventInfo[]> {
    let _params = { ...params } as any;
    if (params.date) {
      const { start, end } = params.date;
      if (start && end) {
        _params = {
          ..._params,
          fromDate: moment(start).format('YYYYMMDD'),
          toDate: moment(end).format('YYYYMMDD'),
        };
        delete _params.date;
      }
    }
    const { data } = await this.get<CommonResponseWrapper<EventInfo[]>>(
      apiWrapper.managerApi(`/devices/alarms`),
      _params
    );
    return data;
  }
}
