
import type { PropType } from 'vue';
import { defineComponent, reactive, watch } from 'vue';
import { Helper } from '@hems/util';
export default defineComponent({
  name: 'ChangedMark',
  props: {
    showMark: {
      type: Boolean,
      default: false,
    },
    valueKey: String,
    targetValue: {
      type: [String, Number, Boolean, null] as PropType<string | number | boolean | null>,
    },
    changedValueSet: {
      type: Object as PropType<Set<string>>,
      default: () => new Set<string>(),
    },
  },
  emit: ['change'],
  setup(props, { emit }) {
    const state = reactive({
      changed: props.changedValueSet.has(props.valueKey ?? ''),
    });

    watch(
      () => props.targetValue,
      (targetValue) => {
        if (Helper.isNull(targetValue)) {
          state.changed = false;
        } else {
          state.changed = true;
        }
        emit('change', props.valueKey, targetValue);
      }
    );

    watch(
      () => props.showMark,
      (showMark) => {
        if (!showMark) {
          state.changed = false;
        }
      }
    );

    watch(
      () => props.changedValueSet,
      () => {
        state.changed = props.changedValueSet.has(props.valueKey ?? '');
      }
    );

    return {
      state,
    };
  },
});
