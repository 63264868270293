import { AxiosInstance } from 'axios';
import { Service } from '../Service';
import { SiteId } from 'hems/device';
import { apiWrapper } from '../../util/helper';
import { CommonResponseWrapper } from 'hems';

export default class StormCoverService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getStormInfo(siteId: SiteId): Promise<any> {
    const { data } = await this.get<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/users/storm-cover/alerts/${siteId}`)
    );
    return data;
  }

  public async sendSeenStormInfoFlag(siteId: SiteId): Promise<any> {
    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/users/storm-cover/alerts/${siteId}`)
    );
    return data;
  }
}
