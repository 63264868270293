import { CellRendererProps } from 'tui-grid/types/renderer';
class ClickableRenderer {
  private el: HTMLElement;

  constructor(props: CellRendererProps) {
    const { $ } = window;

    const el = $(`<a class="text">${props.value}</a>`);
    this.el = el.get(0);
  }

  getElement(): HTMLElement {
    return this.el;
  }
}

export default ClickableRenderer;
