
import type { PropType } from 'vue';
import { defineComponent, ref, watch, onMounted, onBeforeUnmount } from 'vue';

import * as Highcharts from 'highcharts';

import type { SmartModuleAcsysSeries } from '@hems/container/src/forms/device/smartmodule/chartOptions';
import { graphTooltipFormatter } from '@hems/util/src/helper/graphHelper';

export default defineComponent({
  name: 'SmartModuleWebACMIChartContainer',
  props: {
    title: {
      type: String,
      default: '',
    },
    xAxisCategories: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    yAxis: {
      type: Array as PropType<Highcharts.YAxisOptions[]>,
      default: () => [],
    },
    data: {
      type: Array as PropType<SmartModuleAcsysSeries[]>,
      default: () => [],
    },
    tickXCount: {
      type: Number,
      default: 12,
    },
  },
  setup(props) {
    const chart = ref<HTMLDivElement | null>(null);
    const instance = ref<Highcharts.StockChart | null>(null);

    onMounted(() => {
      createChart(props.xAxisCategories, props.data);
    });

    onBeforeUnmount(() => {
      destroyChart();
    });

    watch([() => props.data], () => {
      if (!instance.value) return;
      const options = getOptions(props.xAxisCategories, props.data);
      instance.value.update(options, true, true, true);
    });

    function createChart(xAxisCategories: string[], data: SmartModuleAcsysSeries[]) {
      if (!chart.value) return;

      const options = getOptions(xAxisCategories, data);
      instance.value = Highcharts.chart(
        Object.assign({
          credits: { enabled: false },
          chart: {
            type: 'spline',
            zoomType: 'x',
            renderTo: chart.value,
          },
          title: {
            text: props.title,
            align: 'left',
            style: {
              fontWeight: 'bolder',
              fontSize: '1.2em',
            },
          },
          options,
        })
      );
      instance.value.update(options, true, true, true);
    }

    function getOptions(xAxisCategories: string[], _data: SmartModuleAcsysSeries[]): Highcharts.Options {
      const data = _data.map((item) => ({
        name: item.name,
        data: item.data,
        type: item.type,
        yAxis: item.yAxis,
        connectNulls: item.type === 'spline' ? true : false,
        pointWidth: item.type === 'column' ? 5 : undefined,
      }));

      return {
        yAxis: props.yAxis,
        xAxis: {
          categories: xAxisCategories,
          tickPositioner: () => {
            const dataLength = xAxisCategories.length;
            const maxCountTickX = props.tickXCount;
            const distance = Math.round(dataLength / maxCountTickX);
            if (maxCountTickX > dataLength) return Array.from(Array(dataLength), (_, idx) => idx);

            const tickX = Array.from(Array(maxCountTickX), (_, idx) => distance * idx);
            tickX[maxCountTickX - 1] = dataLength - 1;

            return tickX;
          },
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
        },
        plotOptions: {
          series: {
            lineWidth: 1,
          },
          column: {
            pointWidth: 15,
          },
        },
        series: data as Highcharts.SeriesOptionsType[],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 1000,
                minHeight: 400,
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                },
              },
            },
          ],
        },
        tooltip: {
          shared: true,
          formatter: function () {
            return graphTooltipFormatter(this);
          },
        },
      };
    }

    function destroyChart() {
      if (instance.value) {
        instance.value.destroy();
        instance.value = null;
      }
    }

    return {
      chart,
    };
  },
});
