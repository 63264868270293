import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43a2fce6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification-item-container" }
const _hoisted_2 = { class: "notification-read" }
const _hoisted_3 = { class: "read-mark" }
const _hoisted_4 = { class: "notification-item" }
const _hoisted_5 = { class: "notification-item-details" }
const _hoisted_6 = {
  key: 0,
  class: "title"
}
const _hoisted_7 = { class: "contents" }
const _hoisted_8 = { class: "date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
        [_vShow, !_ctx.notificationInfo.read]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.iconInfo.isIcon)
        ? (_openBlock(), _createBlock(_component_Image2, {
            key: 0,
            class: "notification-item-icon",
            src: _ctx.iconInfo.iconPath,
            extension: _ctx.iconInfo.extension,
            width: "18",
            height: "18"
          }, null, 8, ["src", "extension"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.notificationInfo.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.notificationInfo.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.notificationInfo.contents), 1),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.notificationInfo.date), 1)
      ])
    ])
  ]))
}