
import { defineComponent, reactive, ref, PropType } from 'vue';
import PopupWrapper from '@hems/component/src/popup/PopupWrapper.vue';
import { Form } from 'vee-validate';
import { Role, Support } from 'hems';
import * as yup from 'yup';
import { SupportService } from '@hems/service';
import { useI18n } from 'vue-i18n';
import { isNull } from '@hems/util/src/helper/helper';
import { useMessageBox, withLoading, LabelInput, AutoResizeInput } from '@hems/component';

export default defineComponent({
  name: 'EventDetailPopup',
  components: {
    PopupWrapper,
    Form,
    LabelInput,
    AutoResizeInput,
  },
  props: {
    params: {
      type: String,
      required: true,
    },
    role: {
      type: String as PropType<Role>,
      required: true,
    },
    mode: {
      type: String as PropType<'device' | 'guide'>,
      default: 'guide',
    },
    data: {
      type: String,
      default: '',
    },
  },
  emits: ['confirm', 'close'],
  async setup(props, { emit }) {
    const { t } = useI18n();
    const supportService = new SupportService(window.axiosInstance.axios);
    const detailForm = ref(null as null | any);
    const messageBox = useMessageBox();

    let isInit = false;
    const state = reactive({
      title: props.params ? 'Info' : 'New',
      data: {
        alarmCd: '',
        alarmNm: '',
        deviceTypeCd: '',
        alarmDesc: '',
        alarmCheck: '',
        alarmDetail: '',
        alarmLevel: '',
      } as Support.EventcodeDataDetailOptions,
      editable: false,
      isAllowedEdit: ['admin', 'dev'].includes(props.role ?? ''),
      mode: props.mode,
      alarmStart: props.data,
    });

    if (state.title == 'New') {
      state.editable = true;
    }

    const defaultValidate = yup
      .string()
      .transform((v, o) => (o === 'null' || o === '' ? null : v))
      .nullable()
      .required();

    const schema = yup.object().shape({
      alarmCd: defaultValidate,
      alarmNm: defaultValidate,
      deviceTypeCd: defaultValidate,
      alarmDesc: defaultValidate,
      alarmCheck: defaultValidate,
      alarmDetail: defaultValidate,
    });

    const getParam = () => {
      const { alarmCd, alarmNm, deviceTypeCd, alarmDesc, alarmCheck, alarmDetail } = state.data;
      return {
        alarmCd,
        alarmNm,
        deviceTypeCd,
        alarmDesc,
        alarmCheck,
        alarmDetail,
      };
    };

    async function onEdit() {
      state.editable = true;
      state.title = 'Edit';
    }

    async function onSave() {
      const params = getParam();

      if (!params) {
        messageBox.alert(t('message.invalid_set_value')).open();
        return;
      } else {
        try {
          withLoading(async () => {
            if (props.params) {
              //수정할 경우
              await supportService.updateEventcode(props.params, params).then(() => emit('confirm'));
              return;
            } else {
              //새로 등록할 경우
              await supportService.addEventcode(params).then(() => emit('confirm'));
            }
          })();
        } catch (e) {
          messageBox.alert(t('message.error_apply_setting')).open();
        }
      }
    }

    function onClose() {
      state.editable = false;
      emit('close');
    }

    isInit = true;

    if (!isNull(props.params)) {
      await supportService.getEventcodeList(props.params).then((result: any) => {
        const detailData = result.find((data: any) => data.alarmCd == props.params);

        //data 비어있을 경우 어떻게 처리할 것인지 수정 필요
        state.data = {
          alarmCd: detailData?.alarmCd,
          alarmNm: detailData?.alarmNm,
          deviceTypeCd: detailData?.deviceTypeCd,
          alarmDesc: detailData?.alarmDesc,
          alarmCheck: detailData?.alarmCheck,
          alarmDetail: detailData?.alarmDetail,
        } as Support.EventcodeDataDetailOptions;
      });
    }

    return {
      isInit,
      state,
      onClose,
      onSave,
      schema,
      detailForm,
      onEdit,
    };
  },
});
