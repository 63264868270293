
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import { Image2 } from '@hems/component';
import { useGenType } from '@hems/util';

import type { RealtimeMonitoringInfo } from 'hems/energyFlow';
import type { SiteConfiguration } from 'hems/install';

import EnergyFlowACContainer from './EnergyFlowACContainer.vue';
import EnergyFlowContainer from './EnergyFlowContainer.vue';

export default defineComponent({
  name: 'DashboardEnergyFlowContainer',
  components: {
    Image2,
    EnergyFlowContainer,
    EnergyFlowACContainer,
  },
  props: {
    realtimeData: {
      type: Object as PropType<RealtimeMonitoringInfo>,
      required: true,
    },
    isRealtimeConnected: {
      type: Boolean,
      required: false,
      default: false,
    },
    disconnect: {
      type: Boolean,
      required: false,
      default: true,
    },
    isConnectThirdParty: {
      type: Boolean,
      default: false,
    },
    siteConfigurationInfo: {
      type: Object as PropType<SiteConfiguration | null>,
      default: null,
    },
    isConsumptionCt: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['retryConnection'],
  setup(_, { emit }) {
    const { isACSystem } = useGenType();

    const retryConnection = () => {
      emit('retryConnection');
    };

    return {
      retryConnection,
      isACSystem,
    };
  },
});
