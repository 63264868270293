import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock, vShow as _vShow, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22845a94"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_ErrorMessageLabel = _resolveComponent("ErrorMessageLabel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.state.editable)
      ? (_openBlock(), _createBlock(_component_BasicInput, _mergeProps({
          key: 0,
          modelValue: _ctx.state.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.value) = $event)),
          name: _ctx.name
        }, _ctx.$attrs, {
          placeholder: _ctx.placeholder,
          readonly: !_ctx.state.editable
        }), null, 16, ["modelValue", "name", "placeholder", "readonly"]))
      : _withDirectives((_openBlock(), _createElementBlock("textarea", _mergeProps({
          key: 1,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.value) = $event)),
          class: ["selecter_3 fm_ipt scroll", { [_ctx.className]: true, [`${!!_ctx.errorMessage ? 'error' : _ctx.validClass ? _ctx.validClass : ''}`]: true }],
          name: _ctx.name
        }, _ctx.$attrs, { placeholder: _ctx.placeholder }), null, 16, _hoisted_1)), [
          [_vModelText, _ctx.state.value]
        ]),
    _withDirectives(_createVNode(_component_ErrorMessageLabel, { "error-message": _ctx.errorMessage }, null, 8, ["error-message"]), [
      [_vShow, _ctx.type !== 'hidden']
    ])
  ], 64))
}