import { CellRendererProps } from 'tui-grid/types/renderer';
class RemoteImageRenderer {
  private el: HTMLElement;
  private image: JQuery<HTMLElement>;

  constructor(props: CellRendererProps) {
    const { $ } = window;
    const el = $('<label class="fm_etc"></label>');
    const input = $(
      '<input type="checkbox" style="text-align:center;" class="fm-img" name=""><span class="photo"></span>'
    );

    this.image = $('<img style="max-height:3rem;" alt=""></img>');
    input.append(this.image);
    el.append(input);
    this.el = el.get(0);
    this.render(props);
  }

  getElement(): HTMLElement {
    return this.el;
  }

  async render(props: CellRendererProps): Promise<void> {
    const url = `data:image/png;base64,${props.value}`;
    fetch(url)
      .then((res) => res.blob())
      .then((res) => {
        const blobUrl = URL.createObjectURL(res);
        this.image.attr('src', blobUrl);
      });
  }
}

export default RemoteImageRenderer;
