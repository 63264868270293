
import { defineComponent, PropType, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import { DotStep, useMessageBox, TermsGroup } from '@hems/component';
import { TermsHelper } from '@hems/util';
import { RequiredTermsCategoryValue, REQUIRED_TERMS_CATEGORY } from '@hems/util/src/constant';

import { AppCtxState } from '@/store/modules/appCtx';

import { EnvLocale } from 'hems';

import { Join } from 'hems/auth';
import { EffectiveTermsInfo } from 'hems/terms';

export default defineComponent({
  name: 'Step1TermAndCondition',
  components: {
    DotStep,
    TermsGroup,
  },
  props: {
    data: {
      type: Object as PropType<Join.TermAndCondition>,
      required: true,
    },
    locale: {
      type: String as PropType<EnvLocale>,
    },
    termsList: {
      type: Array as PropType<EffectiveTermsInfo[]>,
      required: true,
    },
  },
  emits: ['next'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    const store = useStore();
    const appCtx = store.state.appCtx as AppCtxState;
    const langCd = computed(() => appCtx.langCd);

    const state = reactive<
      Join.TermAndCondition & { termsData: Record<RequiredTermsCategoryValue, EffectiveTermsInfo>; locale: EnvLocale }
    >({
      termAndUse: props.data.termAndUse,
      crossBorders: props.data.crossBorders,
      privacyPolicy: props.data.privacyPolicy,
      termsData: {} as Record<RequiredTermsCategoryValue, EffectiveTermsInfo>,
      locale: props.locale ?? appCtx.envLocale,
    });
    const isUS = state.locale === 'US';

    async function onNext() {
      const { termAndUse, crossBorders, privacyPolicy } = state;
      if (isUS) {
        if (!termAndUse || !privacyPolicy) {
          await messageBox.alert(t('message.agree_terms')).open();

          return false;
        }
        emit('next', 'termAndCondition', { termAndUse, privacyPolicy });
      } else {
        if (!termAndUse || !crossBorders || !privacyPolicy) {
          await messageBox.alert(t('message.agree_terms')).open();

          return false;
        }
        emit('next', 'termAndCondition', { termAndUse, crossBorders, privacyPolicy });
      }
    }

    const setRequiredTerms = () => {
      state.termsData = TermsHelper.getFilteredTerms(props.termsList, state.locale, langCd.value).requiredTerms;
    };

    setRequiredTerms();

    return {
      state,
      REQUIRED_TERMS_CATEGORY,
      onNext,
      getTermsTitleKey: TermsHelper.getTermsTitleKey,
      getAgreementTitleKey: TermsHelper.getAgreementTitleKey,
    };
  },
});
