import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c79b1ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "value_container top" }
const _hoisted_2 = { class: "value_container bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnergyFlowACHomeLayer = _resolveComponent("EnergyFlowACHomeLayer")!
  const _component_EnergyFlowACPipeLayer = _resolveComponent("EnergyFlowACPipeLayer")!
  const _component_EnergyFlowAC = _resolveComponent("EnergyFlowAC")!
  const _component_EnergyFlowACProductLayer = _resolveComponent("EnergyFlowACProductLayer")!
  const _component_NetworkStatus = _resolveComponent("NetworkStatus")!
  const _component_ProductionInfo = _resolveComponent("ProductionInfo")!
  const _component_OperationModeInfo = _resolveComponent("OperationModeInfo")!
  const _component_GridInfo = _resolveComponent("GridInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_EnergyFlowACHomeLayer),
    (_ctx.isConsumptionCt)
      ? (_openBlock(), _createBlock(_component_EnergyFlowACPipeLayer, {
          key: 0,
          "is-on-grid": _ctx.realtimeMonitoringData.grid_status ?? true,
          "has-ess": _ctx.siteConfigurationInfo?.hasESS,
          "has-pv": _ctx.siteConfigurationInfo?.hasPV,
          "show-generator": _ctx.showGenerator
        }, null, 8, ["is-on-grid", "has-ess", "has-pv", "show-generator"]))
      : _createCommentVNode("", true),
    (_ctx.isConsumptionCt)
      ? (_openBlock(), _createBlock(_component_EnergyFlowAC, {
          key: 1,
          "energy-flow": _ctx.energyFlow
        }, null, 8, ["energy-flow"]))
      : _createCommentVNode("", true),
    _createVNode(_component_EnergyFlowACProductLayer, {
      "is-consumption-ct": _ctx.isConsumptionCt,
      "has-ess": _ctx.siteConfigurationInfo?.hasESS,
      "has-pv": _ctx.siteConfigurationInfo?.hasPV
    }, null, 8, ["is-consumption-ct", "has-ess", "has-pv"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NetworkStatus, {
        "network-status": _ctx.realtimeData.network_type,
        disconnect: _ctx.disconnect
      }, null, 8, ["network-status", "disconnect"]),
      _createVNode(_component_ProductionInfo, {
        "formatted-data": _ctx.formattedData,
        "has-pv": _ctx.siteConfigurationInfo?.hasPV,
        "is-connect-third-party": _ctx.isConnectThirdParty,
        "is-consumption-ct": _ctx.isConsumptionCt
      }, null, 8, ["formatted-data", "has-pv", "is-connect-third-party", "is-consumption-ct"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_OperationModeInfo, {
        "ems-operation-mode": _ctx.realtimeData?.ems_opmode,
        "has-ess": _ctx.siteConfigurationInfo?.hasESS ?? false,
        onOnClickOperationMode: _ctx.popup?.onHomeInfo
      }, null, 8, ["ems-operation-mode", "has-ess", "onOnClickOperationMode"]),
      _createVNode(_component_GridInfo, {
        "formatted-data": _ctx.formattedData,
        "is-consumption-ct": _ctx.isConsumptionCt
      }, null, 8, ["formatted-data", "is-consumption-ct"])
    ])
  ], 64))
}