
import type { PropType } from 'vue';
import { defineComponent, ref, watch } from 'vue';

import {
  getChartTemplateAndData,
  getXAxisCategories,
  updateChartData,
} from '@hems/container/src/forms/device/statistics/_shared';
import { getEnergyHourConfig } from '@hems/container/src/forms/device/statistics/_shared/filterConfig';
import { getHourKeysByGenType } from '@hems/container/src/forms/device/statistics/_shared/graphKeys';
import DeviceLineGraph from '@hems/container/src/highcharts/spline/DeviceLineGraph.vue';
import { useACDeviceType, useGenType, useRole } from '@hems/util';
import { GRAPH_GROUP_CODE, GRAPH_TERM_UNIT, GRAPH_Y_AXIS_UNIT } from '@hems/util/src/constant';

import type { FilterGroupHour, GroupCode, SimpleSeries, StatisticsData } from 'hems/device/statistics/common';

export default defineComponent({
  name: 'StatisticsHourContainer',
  components: {
    DeviceLineGraph,
  },
  props: {
    data: {
      type: Array as PropType<StatisticsData[]>,
      default: () => [],
    },
    isAcCoupled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const xAxisCategories = ref<string[]>([]);
    const energy = ref<SimpleSeries[]>([]);

    const { genType } = useGenType();
    const { ACDeviceType } = useACDeviceType();
    const { roleName } = useRole();

    const chartKeys = genType.value
      ? getHourKeysByGenType({
          genType: genType.value,
          roleName: roleName.value,
          ACDeviceType: ACDeviceType.value,
          isACCoupled: props.isAcCoupled,
        })
      : { energy: [] };
    const config: FilterGroupHour[] = genType.value ? [getEnergyHourConfig(chartKeys.energy, genType.value)] : [];

    const { chartDataTemplate, chartData } = getChartTemplateAndData<FilterGroupHour>(config);

    const getFilterList = (filterGroup: GroupCode) => chartDataTemplate[filterGroup]?.filterList;

    const getFilterGroupName = (filterGroup: GroupCode) => chartDataTemplate[filterGroup]?.filterGroupName ?? '';

    watch(
      () => props.data,
      (data) => {
        updateChartData(chartData, data, { energy: chartKeys.energy });
        xAxisCategories.value = getXAxisCategories(data, GRAPH_TERM_UNIT.HOUR);
        energy.value = chartData[GRAPH_GROUP_CODE.ENERGY];
      }
    );

    return {
      xAxisCategories,
      energy,
      GRAPH_TERM_UNIT,
      GRAPH_GROUP_CODE,
      GRAPH_Y_AXIS_UNIT,
      getFilterList,
      getFilterGroupName,
    };
  },
});
