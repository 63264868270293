import { useI18n } from 'vue-i18n';

import type { ColumnOptions } from 'tui-grid';

import { DateHelper } from '@hems/util';
import type { GraphGroupCodeValue, GraphTermUnitValue } from '@hems/util/src/constant';
import { GRAPH_TERM_UNIT } from '@hems/util/src/constant';
import { getConvertedGraphDateString } from '@hems/util/src/helper/dateHelper';
import { getGraphGroupCodeTypeValue, getGraphGroupCodeListTypeValue } from '@hems/util/src/helper/tsguardHelper';

import type { i18nTranslation } from 'hems';

import type {
  FilterGroup,
  SimpleSeries,
  SuperFilterItem,
  StatisticsFilterGroup,
  StatisticsMinuteData,
  StatisticsHourData,
  StatisticsData,
  StatisticsAllDataKey,
} from 'hems/device/statistics/common';

export type SuperStatisticsData = { std_dt: string };
export type ChartData = Record<GraphGroupCodeValue, SimpleSeries[]>;
export type SuperFilterGroup = FilterGroup & {
  filterList: SuperFilterItem[];
};

// FIXME useI18n 을 사용하고 있어, 컴포저블로 변경 필요
export function getLabelText(filter: SuperFilterItem) {
  const { t } = useI18n();

  return `${filter.prefix ?? ''}${t(filter.name, { defaultValue: filter.altName })}${filter.postfix ?? ''}${
    filter.unit ? ` [${filter.unit}]` : ''
  }`;
}

export function clearChartData(chartData: ChartData) {
  for (const key in chartData) {
    const chartDataKey: GraphGroupCodeValue | null = getGraphGroupCodeTypeValue(key);
    if (chartDataKey) {
      chartData[chartDataKey]?.forEach((item) => (item.data = []));
    }
  }
}

export function getChartTemplateAndData<T extends SuperFilterGroup>(chartConfig: T[]) {
  const chartDataTemplate: Partial<Record<GraphGroupCodeValue, T>> = {};
  const chartData = chartConfig.reduce<ChartData>((prev, curr) => {
    chartDataTemplate[curr.filterGroupCode] = curr;

    return {
      ...prev,
      [curr.filterGroupCode]: curr.filterList.map<SimpleSeries>((item) => {
        return {
          id: item.code,
          name: getLabelText(item),
          yAxis: item.yAxis,
          data: [],
        };
      }),
    };
  }, {} as ChartData);

  return {
    chartDataTemplate,
    chartData,
  };
}

export function getXAxisCategories<T extends SuperStatisticsData>(
  data: T[],
  termUnit: GraphTermUnitValue = GRAPH_TERM_UNIT.MINUTE
): string[] {
  return data.map((item) => DateHelper.getConvertedGraphDateString(item.std_dt, termUnit));
}

type GraphKeys = { [K in GraphGroupCodeValue]?: StatisticsAllDataKey[] };
type GraphData = { [K in StatisticsAllDataKey]?: number | null };

export function updateChartData(chartData: ChartData, data: StatisticsData[], graphKeys: GraphKeys) {
  clearChartData(chartData);
  const groupKeys: GraphGroupCodeValue[] | null = getGraphGroupCodeListTypeValue(Object.keys(graphKeys));

  for (let i = 0; i < data.length; i++) {
    groupKeys?.forEach((groupKey) => {
      graphKeys[groupKey]?.forEach((key) => {
        const graphData: GraphData = data[i];
        const graphDataByKey = graphData[key] ?? null;
        chartData[groupKey]?.find((item) => item.id === key)?.data.push(graphDataByKey);
      });
    });
  }
}

export const getColumns = (config: StatisticsFilterGroup[], t: i18nTranslation): ColumnOptions[] => {
  const columnArr: ColumnOptions[] = [{ header: '', name: 'std_dt' }];
  config.forEach((item) => {
    item.filterList.forEach(
      (item: SuperFilterItem & { code: keyof StatisticsMinuteData | keyof StatisticsHourData }) => {
        columnArr.push({
          header: `${t(`${item.name}`, { defaultValue: item.name })}${item.postfix ?? ''}`,
          name: item.code,
        });
      }
    );
  });

  return columnArr;
};

export const convertFormatDate = (data: StatisticsData[]): (StatisticsData & { std_dt: string })[] => {
  return data.map((item) => ({
    ...item,
    std_dt: getConvertedGraphDateString(item.std_dt, GRAPH_TERM_UNIT.HOUR),
  }));
};

export const getGraphDataByGroupCodes = (groupCodes: GraphGroupCodeValue[], chartData: ChartData) => {
  return groupCodes.reduce(
    (accumulator: SimpleSeries[], groupCode: GraphGroupCodeValue) => [...accumulator, ...chartData[groupCode]],
    []
  );
};
