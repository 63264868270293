
import type { PropType } from 'vue';
import { computed, defineComponent, nextTick, watch, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import type { ColumnOptions } from 'tui-grid';

import { useMessageBox, withLoading, Pagenation, ToastGrid, Panel, ListSize } from '@hems/component';
import { getColumns } from '@hems/container/src/dataGrid/eventDataGrid/gridConfig';
import EventDetailPopup from '@hems/container/src/guide/EventDetailPopup.vue';
import { ErrorCodeService, EventServiceInstaller } from '@hems/service';
import { useLanguage, useLocale, useRole } from '@hems/util';
import { SORT_ORDER } from '@hems/util/src/constant';
import type { ErrorHistoryGridTypeValue } from '@hems/util/src/constant/eventHistory';
import { ERROR_HISTORY_GRID_TYPE } from '@hems/util/src/constant/eventHistory';
import {
  getErrorData,
  getErrorHistoryParams,
  getEventData,
  getEventHistoryParams,
} from '@hems/util/src/helper/errorHistoryHelper';
import { isNull, formatNumber, downloadCSV, addSortOrderMark } from '@hems/util/src/helper/helper';
import { getRouteInfo, isNumberType } from '@hems/util/src/helper/tsguardHelper';

import type { GridEventType, GridEvents } from 'hems';

import type { DataGridOptions } from 'hems/common/dataGrid';
import type { MakeFieldNonNullable } from 'hems/common/utils';
import type { ErrorHistoryInfo, ErrorHistorySearchBoxOptions, ErrorHistoryParams } from 'hems/event';

type ErrorPopupInfo = {
  isOpen: boolean;
  errorCode: string;
  errorStart: string;
};

export default defineComponent({
  name: 'EventDataGrid',
  components: {
    ToastGrid,
    Pagenation,
    Panel,
    ListSize,
    EventDetailPopup,
  },
  props: {
    searchCondition: {
      type: Object as PropType<ErrorHistorySearchBoxOptions>,
      required: true,
    },
    eventGridType: {
      type: String as PropType<ErrorHistoryGridTypeValue>,
      default: ERROR_HISTORY_GRID_TYPE.ALL,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const messageBox = useMessageBox();
    const eventService = new EventServiceInstaller(window.axiosInstance.axios);
    const errorCodeService = new ErrorCodeService(window.axiosInstance.axios);
    const { languageCode } = useLanguage();
    const { roleName, isAdminGroup } = useRole();
    const showGrid = ref(false);
    const { isUS } = useLocale();

    onMounted(() => {
      loadErrorData();
    });

    const errorDetailPopupInfo = ref<ErrorPopupInfo>({
      isOpen: false,
      errorCode: '',
      errorStart: '',
    });

    const condition = ref<MakeFieldNonNullable<ErrorHistoryParams, 'pageNumber' | 'listSize'>>({
      ...props.searchCondition,
      pageNumber: getRouteInfo(route.query.pageNumber, isNumberType) ?? 1,
      listSize: getRouteInfo(route.query.listSize, isNumberType) ?? 10,
      sort: isUS ? addSortOrderMark('triggerDate', SORT_ORDER.DESCENDING) : `event_dt ${SORT_ORDER.DESCENDING}`,
      language: languageCode.value,
    });

    const toastGrid = ref<DataGridOptions<ErrorHistoryInfo>>({
      gridOptions: {
        columns: computed((): ColumnOptions[] => getColumns(t, props.eventGridType, roleName.value, isUS)),
        data: [],
        sortState: {
          columnName: 'triggerDate',
          ascending: false,
          multiple: false,
        },
        options: {
          bodyHeight: 'fitToParent',
        },
      },
    });

    const currCnt = ref<number>(0);
    const eventList = computed(() => toastGrid.value.gridOptions.data);

    const loadData = withLoading(async () => {
      try {
        const params = getEventHistoryParams(condition.value);
        const eventList = await eventService.getEventList(params);
        const { data, totalCount } = getEventData(eventList, t);
        toastGrid.value.gridOptions.data = data;
        currCnt.value = totalCount;
      } catch (error) {
        console.error(error);
        toastGrid.value.gridOptions.data = [];
        condition.value.pageNumber = 1;
      }
    });

    const loadErrorList = withLoading(async () => {
      try {
        const params = getErrorHistoryParams(condition.value, languageCode.value);
        const errorHistory = await errorCodeService.getErrorHistory(params);
        const { data, totalCount } = getErrorData(errorHistory.data, errorHistory.totalCount);
        toastGrid.value.gridOptions.data = data;
        currCnt.value = totalCount;
      } catch (error) {
        console.error(error);
        toastGrid.value.gridOptions.data = [];
        condition.value.pageNumber = 1;
      }
    });

    const loadErrorData = () => {
      if (isUS) {
        loadErrorList();
      } else {
        loadData();
      }
    };

    let allErrorData: ErrorHistoryInfo[] = [];
    const onDownload = withLoading(async () => {
      try {
        let totalCnt = currCnt.value;
        let pageNum = 1;
        const listSize = 30000;

        if (totalCnt === 0) {
          messageBox.alert(t('message.no_results')).open();

          return;
        }

        while (totalCnt > 0) {
          if (isUS) {
            const params = getErrorHistoryParams(condition.value, languageCode.value);
            const errorHistory = await errorCodeService.getErrorHistory(params);
            const { data } = getErrorData(errorHistory.data, errorHistory.totalCount);
            allErrorData = [...allErrorData, ...data];
          } else {
            const params = getEventHistoryParams(condition.value);
            const eventList = await eventService.getEventList(params);
            const { data } = getEventData(eventList, t);
            allErrorData = [...allErrorData, ...data];
          }
          pageNum = pageNum + 1;
          totalCnt = totalCnt - listSize;
        }
        downloadCSV(allErrorData, toastGrid.value.gridOptions.columns, 'EventHistory');
      } catch (e) {
        console.error(e);
        messageBox.alert(t('message.error_download')).open();
      }
    });

    const onChangeListSize = () => {
      if (condition.value.pageNumber === 1) {
        loadErrorData();

        return;
      }
      condition.value.pageNumber = 1;
    };

    const onChangePage = () => {
      loadErrorData();
    };

    watch(
      () => props.searchCondition,
      () => {
        condition.value = { ...condition.value, ...props.searchCondition };
        if (condition.value.pageNumber === 1) {
          loadErrorData();

          return;
        }
        condition.value.pageNumber = 1;
      }
    );

    watch(
      () => languageCode.value,
      () => {
        if (isUS) {
          loadErrorData();
        } else {
          rerenderDataGrid();
        }
      }
    );

    function rerenderDataGrid() {
      showGrid.value = false;
      nextTick(() => (showGrid.value = true));
    }

    function onClose() {
      errorDetailPopupInfo.value.isOpen = false;
      errorDetailPopupInfo.value.errorCode = '';
    }

    const handleBeforeSortEvent = (gridEventProps: GridEventType) => {
      gridEventProps.stop();
      const { ascending, multiple, columnName } = gridEventProps;
      toastGrid.value.gridOptions.sortState = {
        columnName,
        ascending,
        multiple,
      };
      const sortOrder = ascending ? SORT_ORDER.ASCENDING : SORT_ORDER.DESCENDING;
      if (isUS) {
        condition.value.sort = addSortOrderMark(columnName, sortOrder);
      } else {
        condition.value.sort = `${columnName} ${sortOrder}`;
      }
      loadErrorData();
    };

    // @TODO: 신규 이벤트 상세 팝업 적용 시 사용
    const handleClickEvent = (gridEventProps: GridEventType) => {
      const { rowKey, columnName, instance } = gridEventProps;
      if (isNull(rowKey)) return;
      const errorCode = String(instance.getValue(rowKey, 'errorCode'));
      errorDetailPopupInfo.value.errorStart = String(instance.getValue(rowKey, 'triggerDate'));

      if (columnName === 'description' && errorCode) {
        errorDetailPopupInfo.value.errorCode = errorCode;
        errorDetailPopupInfo.value.isOpen = true;
      }
    };

    const events: GridEvents = {
      beforeSort: handleBeforeSortEvent,
    };

    showGrid.value = true;

    return {
      showGrid,
      currCnt,
      eventList,
      errorDetailPopupInfo,
      toastGrid,
      condition,
      roleName,
      events,
      isAdminGroup,
      formatNumber,
      onClose,
      onChangeListSize,
      onDownload,
      onChangePage,
    };
  },
});
