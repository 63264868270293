
import { computed, defineComponent, Prop, PropType, reactive, watch } from 'vue';
import VCalendarWrapper from '@hems/component/src/calendars/VCalendarWrapper.vue';
import { useModelWrapper, DateHelper } from '@hems/util';
import { useI18n } from 'vue-i18n';
import { useMessageBox } from '@hems/component';
import { CalendarModeType } from '.';

type CalendarRangeValue = { start: Date; end: Date };
export default defineComponent({
  name: 'PricingCalendarRange',
  components: {
    VCalendarWrapper,
  },
  props: {
    modelValue: {
      type: Object as PropType<CalendarRangeValue>,
      required: true,
    },
    format: String,
    language: {
      type: String,
      default: 'en',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String as PropType<CalendarModeType>,
    },
    minDate: Date as Prop<Date>,
    maxDate: Date as Prop<Date>,
    validate: Function as Prop<(date: CalendarRangeValue, beforeDate: CalendarRangeValue) => boolean>,
    isPermanent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['permanent'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    const state = reactive({
      open: {
        start: false,
        end: false,
      },
      editable: computed(() => props.editable),
      value: useModelWrapper<CalendarRangeValue>(props, emit),
      displayStartValue: getDisplayValue(props.modelValue?.start, false),
      displayEndValue: getDisplayValue(props.modelValue?.end, props.isPermanent),
      isPermanent: props.isPermanent,
    });

    const dateMode = computed(() => (props.mode ? props.mode : 'datetime'));

    function getDisplayValue(value: Date | null, isPermanent: boolean) {
      if (isPermanent) {
        return t('common.permanent');
      }
      if (value) {
        return DateHelper.getLocalDate(value, { isTime: false });
      }
      return '';
    }

    watch(
      () => state.value.start,
      (value) => {
        state.displayStartValue = getDisplayValue(value, false);
        checkValidFromToDate();
      }
    );

    watch(
      () => state.value.end,
      (value) => {
        if (value !== null) {
          state.isPermanent = false;
          state.displayEndValue = getDisplayValue(value, state.isPermanent);
          emit('permanent', state.isPermanent);
        }
        checkValidFromToDate();
      }
    );

    function applyPermanent(isPermanent: boolean) {
      state.isPermanent = isPermanent;
      state.displayEndValue = getDisplayValue(null, state.isPermanent);
      emit('permanent', state.isPermanent);
    }

    function checkValidFromToDate() {
      if (!DateHelper.isValidDateRange(state.value.start, state.value.end)) {
        messageBox.alert(t('message.start_before_end')).open();
        return false;
      }
      return true;
    }

    return {
      state,
      dateMode,
      onOpenStart: () => {
        if (state.editable) state.open.start = true;
      },
      onCloseStart: () => (state.open.start = false),
      onOpenEnd: () => {
        if (state.editable) state.open.end = true;
      },
      onCloseEnd: () => (state.open.end = false),
      applyPermanent,
    };
  },
});
