
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { useACDeviceType, useSiteDeviceGenType, useSiteInfo } from '@hems/util/src/composable';
import { NUMBER_UNIT } from '@hems/util/src/constant';

export default defineComponent({
  name: 'Inventory',
  setup() {
    const { t } = useI18n();

    const { inventoryInfo } = useSiteInfo();

    const { isAC } = useSiteDeviceGenType();

    const { isSolarConfig, isESSOnlyConfig } = useACDeviceType();

    const convertPVCapacity = (PVCapacity: number) => {
      return PVCapacity >= NUMBER_UNIT.THOUSAND
        ? `${(PVCapacity / NUMBER_UNIT.THOUSAND).toFixed(1)} kWp`
        : `${PVCapacity} Wp`;
    };

    const PVCapacity = inventoryInfo ? convertPVCapacity(inventoryInfo.total_pv_cap) : null;

    const batteryCapacity = inventoryInfo ? inventoryInfo.total_bat_cap.toFixed(1) : null;

    const PVLabel = isAC ? t('device.num_of_microinverter') : t('device.n_of_modules');

    return {
      inventoryInfo,
      PVCapacity,
      batteryCapacity,
      isSolarConfig,
      isESSOnlyConfig,
      PVLabel,
    };
  },
});
