
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import PricingSettingsPvModuleGen3SmartModuleGen2 from '@hems/container/src/forms/device/settings/_shared/pricing/PricingSettingsPvModuleGen3SmartModuleGen2.vue';

import type { PricingSettings } from 'hems/device/settings/pvmodule/gen3';

export default defineComponent({
  name: 'PricingSettingsPvModuleGen3Container',
  components: {
    PricingSettingsPvModuleGen3SmartModuleGen2,
  },
  props: {
    data: {
      type: Object as PropType<Partial<PricingSettings>>,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    confirmMessage: {
      type: String,
      default: 'message.save_confirm',
    },
    isConnection: Boolean,
    productModelNm: String,
    emsVersion: {
      type: String,
      default: '',
    },
  },
  emits: ['save', 'goToList', 'cancel', 'edit'],
  setup(props, { emit }) {
    async function onSave(param: PricingSettings) {
      emit('save', param);
    }
    function onEdit(copyData: PricingSettings) {
      emit('edit', copyData);
    }

    return {
      onSave,
      onEdit,
    };
  },
});
