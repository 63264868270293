
import { defineComponent, reactive, ref, computed, PropType } from 'vue';
import PopupWrapper from '@hems/component/src/popup/PopupWrapper.vue';
import Selector from '@hems/component/src/selectors/Selector/Selector.vue';
import { Form } from 'vee-validate';
import { SelectorOption } from 'hems';
import * as yup from 'yup';
import { CommonService, SupportService } from '@hems/service';
import { useI18n } from 'vue-i18n';
import { isNull } from '@hems/util/src/helper/helper';
import { useMessageBox, withLoading, LabelInput } from '@hems/component';
import { SupportInfo } from 'hems/support';

export default defineComponent({
  name: 'SupportDetailPopup',
  components: {
    PopupWrapper,
    Selector,
    Form,
    LabelInput,
  },
  props: {
    params: {
      type: Number as PropType<number | null>,
      required: true,
    },
  },
  emits: ['confirm', 'close', 'load'],
  async setup(props, { emit }) {
    const { t } = useI18n();
    const commonService = new CommonService(window.axiosInstance.axios);
    const supportService = new SupportService(window.axiosInstance.axios);
    const detailForm = ref(null as null | any);
    const messageBox = useMessageBox();

    let isInit = false;
    const state = reactive({
      title: props.params ? 'Modify' : 'New',
      data: {} as Omit<SupportInfo, 'supportId'>,
    });
    const defaultSupportDetail = {
      categoryCode: '',
      categoryName: '',
      filterName: '',
      pdfFileName: '',
      pdfFileUrl: '',
    };
    const supportDetail = ref<Omit<SupportInfo, 'supportId'>>(defaultSupportDetail);

    const defaultValidate = yup
      .string()
      .transform((v, o) => (o === 'null' || o === '' ? null : v))
      .nullable()
      .required();

    const schema = yup.object().shape({
      category: defaultValidate,
      filterName: defaultValidate,
      pdfFileName: defaultValidate,
      pdfFileUrl: defaultValidate,
    });

    const getGuideCategoryList = await commonService.getGuideCategory();

    const selectAll: SelectorOption = { text: t('common.all'), value: '' };
    const guideCategoryList = computed(() =>
      [selectAll].concat(
        getGuideCategoryList.map((data: any) => ({
          value: data.categoryCode,
          text: t(`${data.categoryName}`, { defaultValue: data.categoryName }),
        }))
      )
    );

    const getParam = () => {
      const { categoryCode, filterName, pdfFileName, pdfFileUrl } = supportDetail.value;
      return {
        categoryCode,
        filterName,
        pdfFileName: pdfFileName + '.pdf',
        pdfFileUrl,
      };
    };

    async function onConfirm() {
      const params = getParam();

      try {
        withLoading(async () => {
          if (props.params) {
            //수정할 경우
            await supportService
              .updateGuide(props.params, params)
              .then(() => emit('confirm'))
              .then(() => emit('load'));
            return;
          } else {
            //새로 등록할 경우
            await supportService.addGuide(params).then(() => emit('confirm'));
          }
        })();
      } catch (e) {
        messageBox.alert(t('message.error_apply_setting')).open();
      }
    }

    function onClose() {
      emit('close');
    }

    isInit = true;

    if (!isNull(props.params)) {
      await supportService.getGuideList(props.params).then((result: SupportInfo[]) => {
        const detailData = result.find((data: any) => data.supportId == props.params);

        supportDetail.value = detailData ?? defaultSupportDetail;
      });
    }

    return {
      isInit,
      state,
      onClose,
      onConfirm,
      schema,
      detailForm,
      getGuideCategoryList,
      guideCategoryList,
      supportDetail,
    };
  },
});
