/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import _ from 'lodash';

import { SORT_ORDER } from '@hems/util/src/constant';
import {
  defaultStartDateTimeOptions,
  defaultEndDateTimeOptions,
  ERROR_LEVEL,
  ERROR_LEVEL_DISPLAY_TEXT,
  ERROR_PREFIX,
  ERROR_STATUS_TYPE,
  DEFAULT_PERIOD,
} from '@hems/util/src/constant/eventHistory';
import type { LanguageValue } from '@hems/util/src/constant/languages';
import { LANGUAGE } from '@hems/util/src/constant/languages';
import { getDateWithAdjustedTime, getLocalDate } from '@hems/util/src/helper/dateHelper';
import { addSortOrderMark, isNull } from '@hems/util/src/helper/helper';
import { getStringValue, getYesOrNoValue } from '@hems/util/src/helper/tsguardHelper';

import type { i18nTranslation } from 'hems';

import type {
  DashboardErrorInfo,
  ErrorCodeDisplayDetails,
  ErrorHistoryInfo,
  ErrorHistoryParams,
  EventInfo,
  EventListSearchOptions,
  RecentErrorHistoryInfo,
  RecentEvent,
  SiteErrorInfo,
} from 'hems/event';

type ErrorList = { data: ErrorHistoryInfo[]; totalCount: number };

const dateFormatConfig = { isTime: true, needUtcConvert: true };

export const getEventHistoryParams = (condition: ErrorHistoryParams): EventListSearchOptions => {
  const { pageNumber, listSize, sort, search, errorLevel, countryCode, date, excludeErrorCodes } = condition;

  return {
    listSize,
    date,
    filteredAlarmCodes: excludeErrorCodes,
    pageNum: pageNumber,
    sortColumn: sort,
    searchFor: search,
    eventTypeCd: errorLevel,
    cntryCd: countryCode,
  };
};

export const getErrorHistoryParams = (condition: ErrorHistoryParams, language: LanguageValue): ErrorHistoryParams => {
  const {
    pageNumber,
    listSize,
    sort,
    search,
    date = DEFAULT_PERIOD,
    errorLevel,
    countryCode,
    excludeErrorCodes,
  } = condition;

  return {
    pageNumber,
    listSize,
    sort: getStringValue(sort),
    search: getStringValue(search),
    language,
    triggerDateStart: getDateWithAdjustedTime(date?.start, defaultStartDateTimeOptions).toISOString(),
    triggerDateEnd: getDateWithAdjustedTime(date?.end, defaultEndDateTimeOptions).toISOString(),
    cleared: null,
    errorLevel: getStringValue(errorLevel),
    countryCode: getStringValue(countryCode),
    excludeErrorCodes: getStringValue(excludeErrorCodes),
  };
};

export const getEventData = (eventList: EventInfo[], t: i18nTranslation): ErrorList => {
  const data = eventList.map((object) => ({
    countryCode: object.cntry_cd,
    subDeviceId: '',
    triggerDate: object.event_dt ? getLocalDate(object.event_dt, dateFormatConfig) : '',
    clearDate: object.clear_dt ? getLocalDate(object.clear_dt, dateFormatConfig) : '',
    triggerDateUtc: object.event_dt_utc ?? null,
    clearDateUtc: object.clear_dt_utc ?? null,
    siteId: String(object.site_id),
    deviceId: object.product_model_nm.indexOf('FOX') > -1 ? object.fox_box_id : object.device_id.replace(' ', '<br />'),
    errorCode: object.alarm_cd,
    errorLevel: t(`${object.alarm_type_str}`),
    display: {
      description: t(`${object.alarm_detail}`),
      howToFix: '',
    },
    description: t(`${object.alarm_detail}`),
  }));
  const totalCount = eventList[0]?.total_records;

  return { data, totalCount };
};

export const getErrorData = (errorList: ErrorHistoryInfo[], totalCount: number): ErrorList => {
  return {
    data: errorList.map((errorInfo) => ({
      ...errorInfo,
      deviceId: getDeviceId(errorInfo.deviceId, errorInfo.subDeviceId),
      triggerDate: errorInfo.triggerDate ? getLocalDate(errorInfo.triggerDate, dateFormatConfig) : '',
      clearDate: errorInfo.clearDate ? getLocalDate(errorInfo.clearDate, dateFormatConfig) : '',
      triggerDateUtc: errorInfo.triggerDate ?? null,
      clearDateUtc: errorInfo.clearDate ?? null,
      errorLevel: getErrorLevel(errorInfo.errorLevel),
      description: getDescription(errorInfo.display),
    })),
    totalCount,
  };
};

export const getRecentEventData = (eventList: RecentEvent[], t: i18nTranslation): RecentErrorHistoryInfo[] => {
  return eventList.map((event) => ({
    siteId: String(event.site_id),
    deviceId: event.fox_box_id ? event.fox_box_id : event.device_id,
    errorLevel: t(event.alarm_cd_nm),
    triggerDate: getLocalDate(event.create_dt, dateFormatConfig),
    errorCode: event.alarm_cd,
    newFlag: getYesOrNoValue(event.new_flag),
  }));
};

export const getRecentErrorData = (errorList: ErrorHistoryInfo[]): RecentErrorHistoryInfo[] => {
  return errorList.map((error) => ({
    siteId: error.siteId,
    deviceId: getDeviceId(error.deviceId, error.subDeviceId),
    errorLevel: getErrorLevel(error.errorLevel),
    triggerDate: getLocalDate(error.triggerDate, dateFormatConfig),
    errorCode: error.errorCode,
    // TODO: 기존의 recent event history에서 서버에서 주는 값이 항상 'Y'인지 확인 필요
    newFlag: 'Y',
  }));
};

export const getSiteErrorParams = (siteId: string, language: LanguageValue = LANGUAGE.EN) => {
  return {
    sort: addSortOrderMark('triggerDate', SORT_ORDER.DESCENDING),
    language,
    cleared: false,
    search: siteId,
  };
};

export const getSiteErrorData = (errorList: ErrorHistoryInfo[]): SiteErrorInfo => {
  const errorCodeAndDate = errorList.map((error) => ({
    ...error,
    triggerDate: getLocalDate(error.triggerDate, dateFormatConfig),
  }));
  const siteErrors: SiteErrorInfo = {};
  Object.values(ERROR_PREFIX).forEach((prefix) => {
    const errorsByPrefix = getErrorInfoByPrefix(errorCodeAndDate, prefix);
    siteErrors[prefix] = { errorStatusType: getSiteErrorStatusType(errorsByPrefix), errorInfo: errorsByPrefix };
  });

  return siteErrors;
};

const getDescription = (display: ErrorCodeDisplayDetails): string => {
  return getStringValue(display.description) ?? '';
};

const getErrorLevel = (errorLevel: string | null): string | null => {
  switch (errorLevel) {
    case ERROR_LEVEL.FAULT:
      return ERROR_LEVEL_DISPLAY_TEXT.FAULT;
    case ERROR_LEVEL.WARNING:
      return ERROR_LEVEL_DISPLAY_TEXT.WARNING;
    case ERROR_LEVEL.ALARM:
      return ERROR_LEVEL_DISPLAY_TEXT.ALARM;
    default:
      return null;
  }
};

const getDeviceId = (deviceId: string, subDeviceId?: string | null) => {
  if (isNull(subDeviceId)) {
    return deviceId;
  }

  return `${deviceId}(${subDeviceId})`;
};

const getErrorInfoByPrefix = (errors: ErrorHistoryInfo[], prefix: string): ErrorHistoryInfo[] => {
  return errors.filter((error) => error.errorCode.startsWith(prefix));
};

export const getSiteErrorStatusType = (errors: ErrorHistoryInfo[]) => {
  const faults = errors.some((error) => error.errorLevel === ERROR_LEVEL.FAULT);
  const warnings = errors.some((error) => error.errorLevel === ERROR_LEVEL.WARNING);
  if (faults) {
    return ERROR_STATUS_TYPE.ERROR;
  }
  if (warnings) {
    return ERROR_STATUS_TYPE.WARNING;
  }

  return null;
};

const getDeviceIdFilter = (errors: DashboardErrorInfo | null, deviceId?: string) => {
  let errorInfos = errors?.errorInfo;

  if (deviceId) {
    errorInfos = errors?.errorInfo?.filter((error) => error.deviceId === deviceId);
  }

  return {
    errorStatusType: getSiteErrorStatusType(errorInfos ?? []),
    errorInfo: errorInfos ?? [],
  };
};

export const getErrorCodeAndDateForAC = (errors?: DashboardErrorInfo | null, deviceId?: string): string => {
  if (!errors || _.isEmpty(errors.errorInfo)) {
    return '';
  }

  const errorCodeAndDate = _.uniq(
    getDeviceIdFilter(errors, deviceId).errorInfo?.map((error) => `${error.errorCode}(${error.triggerDate})`)
  );

  return errorCodeAndDate?.join(', ');
};

export const getErrorCodeAndDateForACFromArray = (
  errorArray: (DashboardErrorInfo | null)[],
  device_id?: string
): string => {
  const isErrorCodesEmpty = errorArray.every((item) => item?.errorInfo?.length === 0);
  if (isErrorCodesEmpty) {
    return '';
  }

  const errors = errorArray.map((item) => getErrorCodeAndDateForAC(item, device_id));

  return errors.join(', ');
};

export const getErrorStatusTypeFromArray = (errorArray: (DashboardErrorInfo | null)[], deviceId?: string) => {
  const isErrorCodesEmpty = errorArray.every((item) => item?.errorInfo?.length === 0);
  if (isErrorCodesEmpty) {
    return;
  }

  const errorStatusTypeByArray = errorArray.map((item) => getDeviceIdFilter(item, deviceId).errorStatusType);

  if (errorStatusTypeByArray.includes(ERROR_STATUS_TYPE.ERROR)) {
    return ERROR_STATUS_TYPE.ERROR;
  } else if (errorStatusTypeByArray.includes(ERROR_STATUS_TYPE.WARNING)) {
    return ERROR_STATUS_TYPE.WARNING;
  }

  return;
};

/** GEN2, GEN3, FOX 장비의 에러 코드 이름과 에러 발생 날짜를 String으로 변환 */
export const getErrorCodeAndDate = (codes: string, errorMap?: { [key: string]: string }) => {
  if (!errorMap) {
    return '';
  }

  const splitCodes = codes?.split(',');
  const codesAndDates = _.pick(errorMap, splitCodes);

  const mappedErrorList = Object.entries(codesAndDates).map(([code, value]) => {
    const errorDate = `${getLocalDate(value, { isTime: true, needUtcConvert: true })}`;

    return `${code}(${errorDate})`;
  });

  return mappedErrorList.join(', ');
};
