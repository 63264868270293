
import type { PropType } from 'vue';
import { defineComponent, ref, watch } from 'vue';
import type * as Highcharts from 'highcharts';
import { HighchartsWrapper } from '@hems/component';
import type { Device } from 'hems';
import { useI18n } from 'vue-i18n';
import type { HighchartsWrapperTypes } from '@hems/component';
import { CONNECTION_STATUS_CODE, DEVICE_STATUS_CODE } from '@hems/util/src/constant';
import { useLanguage } from '@hems/util';

export type ChartData = Device.DeviceCountByStatus;

// 차트 기본 옵션
const defaultChartOption: Partial<Highcharts.Options> = Object.freeze({
  chart: {
    type: 'pie',
    height: '300px',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    borderColor: '#8ae',
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      size: '100%',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b> : {point.y}',
      },
      borderWidth: 1,
      cursor: 'pointer',
      fillColor: '#E0F2F7',
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                format: '<b>{point.name}</b>',
              },
            },
          },
        },
      },
    ],
  },
  colors: ['#00adef', '#ffde68', '#f06280', '#8b8b8b', '#ff8c38'],
  series: [
    {
      type: 'pie',
      dataLabels: {
        style: {
          fontSize: '0.875rem',
        },
      },
      data: [],
    },
  ],
});

export default defineComponent({
  name: 'DevicePieChartContainer',
  components: {
    HighchartsWrapper,
  },
  props: {
    isOnline: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object as PropType<Partial<ChartData> | null>,
      required: true,
    },
    checkComplete: {
      type: Boolean,
      default: true,
    },
    checkInComplete: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { languageCode } = useLanguage();
    const instance = ref<HighchartsWrapperTypes.ChartInstance | null>(null);

    watch([() => props.data, () => languageCode.value, () => props.checkComplete, () => props.checkInComplete], () => {
      setChartData(props.data);
    });

    // Series 데이터 설정
    const setChartData = (data: Partial<ChartData> | null) => {
      if (!instance.value || !data) return;
      instance.value?.instance.series[0].setData(props.isOnline ? getOnlineSeries(data) : getOfflineSeries(data));
    };

    const goMonitoringList = (event: Highcharts.PointClickEventObject, opName: string) => {
      const status = {
        connectionStatus: '',
        operStusCd: '',
      };

      switch (opName) {
        case 'onRun':
          status.connectionStatus = CONNECTION_STATUS_CODE.online;
          status.operStusCd = DEVICE_STATUS_CODE.run;
          break;
        case 'onWarn':
          status.connectionStatus = CONNECTION_STATUS_CODE.online;
          status.operStusCd = DEVICE_STATUS_CODE.warning;
          break;
        case 'onErr':
          status.connectionStatus = CONNECTION_STATUS_CODE.online;
          status.operStusCd = DEVICE_STATUS_CODE.error;
          break;
        case 'disconRun':
          status.connectionStatus = CONNECTION_STATUS_CODE['online-disconnected'];
          status.operStusCd = DEVICE_STATUS_CODE.run;
          break;
        case 'disconWarn':
          status.connectionStatus = CONNECTION_STATUS_CODE['online-disconnected'];
          status.operStusCd = DEVICE_STATUS_CODE.warning;
          break;
        case 'disconErr':
          status.connectionStatus = CONNECTION_STATUS_CODE['online-disconnected'];
          status.operStusCd = DEVICE_STATUS_CODE.error;
          break;
        case 'std':
          status.connectionStatus = CONNECTION_STATUS_CODE.incomplete;
          status.operStusCd = DEVICE_STATUS_CODE.incomplete;
          break;
        case 'offline':
          status.connectionStatus = CONNECTION_STATUS_CODE.offline;
          status.operStusCd = DEVICE_STATUS_CODE.offline;
          break;
        default:
          status.connectionStatus = CONNECTION_STATUS_CODE.online;
          status.operStusCd = DEVICE_STATUS_CODE.run;
          break;
      }

      emit('click', status);
    };

    // chartData에서 연결 장치 정보를 Series 데이터로 가져오기
    const getOnlineSeries = (chartData?: Partial<ChartData>): Array<Highcharts.PointOptionsType> => {
      if (!chartData || !props.checkComplete) return [];
      const { onRunCnt, onWarnCnt, onErrCnt } = chartData;

      return [
        {
          name: t('device.normal'),
          y: onRunCnt,
          events: { click: (event) => goMonitoringList(event, 'onRun') },
          color: '#00adef',
        },
        {
          name: t('device.warning'),
          y: onWarnCnt,
          events: { click: (event) => goMonitoringList(event, 'onWarn') },
          color: '#ffde68',
        },
        {
          name: t('common.error'),
          y: onErrCnt,
          events: { click: (event) => goMonitoringList(event, 'onErr') },
          color: '#f06280',
        },
      ];
    };

    // chartData에서 미연결 장치 정보를 Series 데이터로 가져오기
    const getOfflineSeries = (chartData?: Partial<ChartData>): Array<Highcharts.PointOptionsType> => {
      if (!chartData) return [];
      const { disconRunCnt, disconWarnCnt, disconErrCnt, offlineCnt, stdCnt } = chartData;

      const completeSeries: Array<Highcharts.PointOptionsType> = [
        {
          name: t('device.normal'),
          y: disconRunCnt,
          events: { click: (event) => goMonitoringList(event, 'disconRun') },
          color: '#00adef',
        },
        {
          name: t('device.warning'),
          y: disconWarnCnt,
          events: { click: (event) => goMonitoringList(event, 'disconWarn') },
          color: '#ffde68',
        },
        {
          name: t('common.error'),
          y: disconErrCnt,
          events: { click: (event) => goMonitoringList(event, 'disconErr') },
          color: '#f06280',
        },
        {
          name: t('device.offline'),
          y: offlineCnt,
          events: { click: (event) => goMonitoringList(event, 'offline') },
          color: '#8b8b8b',
        },
      ];
      const inCompleteSeries: Array<Highcharts.PointOptionsType> = [
        {
          name: t('common.incomplete'),
          y: stdCnt,
          events: { click: (event) => goMonitoringList(event, 'std') },
          color: '#ff8c38',
        },
      ];

      return props.checkComplete
        ? props.checkInComplete
          ? [...completeSeries, ...inCompleteSeries]
          : completeSeries
        : props.checkInComplete
        ? inCompleteSeries
        : [];
    };

    return {
      defaultChartOption,
      loadInstance: (ins: HighchartsWrapperTypes.ChartInstance) => {
        instance.value = ins;
      },
    };
  },
});
