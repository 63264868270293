
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import type { StatusTemplateType } from 'hems/device/dashboard';

export default defineComponent({
  name: 'DashboardTable',
  props: {
    tableDataList: {
      type: Array as PropType<StatusTemplateType[]>,
      required: true,
    },
    caption: {
      type: String,
      required: true,
    },
    showCaption: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const getIndentClassName = ({ isIndent, indentDepth = 1 }: StatusTemplateType) => {
      if (isIndent) {
        return `indent${indentDepth}`;
      }
    };

    return { getIndentClassName };
  },
});
