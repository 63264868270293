
import { defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

import { PopupWrapper, useMessageBox, AccountInfo } from '@hems/component';
import { UserInfoService } from '@hems/service';

import { GeneralAccountInfo } from 'hems/auth/account';

export default defineComponent({
  name: 'GeneralAccountInfoPopup',
  components: {
    PopupWrapper,
    AccountInfo,
  },
  emits: ['close', 'openPrivacyPopup', 'withdrawal'],
  async setup() {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    const userInfoService = new UserInfoService(window.axiosInstance.axios);
    const accountInfo = await getAccountInfo();

    const state = reactive<{
      accountInfo: GeneralAccountInfo;
    }>({
      accountInfo,
    });

    async function saveAccountInfo(params: GeneralAccountInfo) {
      const {
        email,
        password,
        mpn_no,
        user_nm,
        bld_area_val,
        direct_marketing,
        usage_report,
        local_mpn_no,
        alarm_mail_recv_flag,
      } = params;

      await userInfoService.updateGeneralAccountInfo({
        email,
        password,
        mpn_no,
        user_nm,
        bld_area_val,
        direct_marketing,
        usage_report,
        local_mpn_no,
      });

      await userInfoService.updateGeneralAccountAlarmFlag(alarm_mail_recv_flag);

      state.accountInfo = await getAccountInfo();
      messageBox.alert(t('message.save_success')).open();
    }

    async function getAccountInfo() {
      return await userInfoService.getGeneralAccountInfo();
    }

    return {
      state,
      saveAccountInfo,
    };
  },
});
