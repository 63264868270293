import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb94e3a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-noti-box" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "message" }
const _hoisted_5 = {
  key: 0,
  class: "code"
}
const _hoisted_6 = {
  key: 1,
  class: "description"
}
const _hoisted_7 = { class: "btn-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: _normalizeClass(["error-noti-dim", { on: _ctx.errorNotificationBox.on }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.errorNotificationBox.imageName)
            ? (_openBlock(), _createBlock(_component_Image2, {
                key: 0,
                src: `notification/${_ctx.errorNotificationBox.imageName}`,
                extension: _ctx.errorNotificationBox.imageExtension,
                width: "52",
                height: "52"
              }, null, 8, ["src", "extension"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.errorNotificationBox.title), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.errorNotificationBox.message), 1),
        (_ctx.errorNotificationBox.code !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Error code: " + _toDisplayString(_ctx.errorNotificationBox.code), 1))
          : _createCommentVNode("", true),
        (_ctx.errorNotificationBox.description !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.errorNotificationBox.description), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            ref: "btnCloseEl",
            type: "button",
            class: "btn-close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
          }, "Close", 512)
        ])
      ])
    ], 2)
  ]))
}