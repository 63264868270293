
import { computed, defineComponent, PropType, reactive } from 'vue';
import { batteryType } from '@hems/component/src/battery/batteryConfig';
import { Helper } from '@hems/util';

export default defineComponent({
  name: 'BatterySOC',
  props: {
    type: {
      type: String as PropType<'discharge' | 'charge' | 'wait'>,
      default: 'wait',
    },
    name: String,
    soc: Number,
  },
  setup(props) {
    const state = reactive({
      soc: computed(() => Helper.formatNumber(props.soc || 0, 1)),
    });

    return {
      state,
      batteryType,
    };
  },
});
