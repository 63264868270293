
import { useModelWrapper } from '@hems/util';
import type { SelectorValue } from 'hems';
import type { PropType } from 'vue';
import { computed, defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'BasicCheckbox',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: [String, Number, Boolean, null] as PropType<SelectorValue>,
      required: false,
      default: null,
    },
    modelValue: {
      type: [Array, Boolean] as PropType<(string | number)[] | boolean>,
      required: false,
      default: false,
    },
    readonly: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      isReadonly: computed(() => (props.readonly === '' || props.readonly ? true : false)),
      value: useModelWrapper(props, emit),
    });
    const onClick = (e: MouseEvent) => {
      if (state.isReadonly) {
        e.preventDefault();
      }
    };

    return {
      state,
      onClick,
    };
  },
});
