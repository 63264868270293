
import { Helper } from '@hems/util';
import type { PropType } from 'vue';
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import _ from 'lodash';
import * as Highcharts from 'highcharts';

export default defineComponent({
  name: 'HighchartsWrapper',
  props: {
    height: {
      type: String,
      required: false,
      default: '100%',
    },
    width: {
      type: String,
      required: false,
      default: '100%',
    },
    chartOptions: {
      type: Object as PropType<Highcharts.Options>,
      required: true,
    },
  },
  emits: ['instance'],
  setup(props, { emit }) {
    const instance = ref<Highcharts.Chart | null>(null);
    const uuid = Helper.getUUID();
    const requiredOption = {
      credits: {
        enabled: false,
      },
      chart: {
        renderTo: uuid,
      },
    };

    watch(
      () => props.chartOptions,
      () => {
        if (!props.chartOptions) return;
        if (!instance.value) {
          createChart();

          return;
        }
        instance.value.update(props.chartOptions, true, true, true);
      }
    );
    onMounted(() => {
      createChart();
    });

    function createChart() {
      instance.value = Highcharts.chart(_.merge(props.chartOptions, requiredOption));
      emit('instance', { instance: instance.value, destroyChart });
    }

    function destroyChart() {
      if (instance.value) {
        instance.value.destroy();
        instance.value = null;
      }
    }

    onBeforeUnmount(destroyChart);

    return {
      chartId: computed(() => uuid),
    };
  },
});
