
import { defineComponent, reactive, computed, PropType } from 'vue';

import { useMessageBox } from '@hems/component';
import { isNull } from '@hems/util/src/helper/helper';

import { isAU } from '@/config/ConstantPortal';

export default defineComponent({
  name: 'GeneralJoinType',
  props: {
    selectedLocale: String as PropType<string | null>,
  },
  emits: ['click'],
  setup(props, { emit }) {
    const state = reactive({
      selectedLocale: computed(() => props.selectedLocale),
    });
    const messageBox = useMessageBox();

    const onClickJoin = () => {
      if (!isAU || !isNull(state.selectedLocale)) {
        emit('click');
      } else {
        messageBox.alert('Please select the region.').open();
      }
    };

    return {
      state,
      isAU,
      isNull,
      onClickJoin,
    };
  },
});
