
import { defineComponent, reactive, Ref, ref } from 'vue';
import Image from '@hems/component/src/image/Image.vue';
import { AuthHelper } from '@hems/util';

export default defineComponent({
  name: 'LoginUserInfo',
  components: {
    Image,
  },
  setup() {
    const state = reactive({
      on: false,
    });
    const loginContainerEl: Ref<HTMLElement | null> = ref(null);

    function onToggle(e: MouseEvent) {
      e.preventDefault();

      if (state.on) closeLogoutLayer();
      else openLogoutLayer();
    }
    function openLogoutLayer() {
      window.addEventListener('click', clickOtherLayer);
      state.on = true;
    }
    function closeLogoutLayer() {
      window.removeEventListener('click', clickOtherLayer);
      state.on = false;
    }
    function clickOtherLayer(e: MouseEvent) {
      const target = e.target as HTMLElement;
      if (target.parentElement !== loginContainerEl.value) closeLogoutLayer();
    }
    function onLogout(e: MouseEvent) {
      e.preventDefault();
      AuthHelper.removeAccessToken();
      location.href = '/';
    }
    return {
      state,
      loginContainerEl,
      onToggle,
      onLogout,
    };
  },
});
