import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42ac561f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chart-area" }
const _hoisted_2 = { ref: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({ graph: _ctx.type == 'Overview' })
    }, [
      _createElementVNode("div", _hoisted_2, null, 512)
    ], 2)
  ]))
}