
import type { PropType } from 'vue';
import { defineComponent, computed, watch, ref } from 'vue';

import { GraphChart } from '@hems/component';
import { getTodayProductionChartOptions } from '@hems/container/src/energyflow/value/energyIndendenceChartOptions';
import { createChartData, getFormattedNumberValue } from '@hems/container/src/energyflow/value/EnergyValueFunctions';
import { useLocale } from '@hems/util';
import { getBrowserTimezone } from '@hems/util/src/helper/dateHelper';

import type { ReportIntervalResult, TodayEnergyChartData } from 'hems/report';

const CHART_HEIGHT = 240;

export default defineComponent({
  name: 'TodayProductionACNoConsumptionCT',
  components: {
    GraphChart,
  },
  props: {
    reportData: {
      type: Object as PropType<ReportIntervalResult | null>,
      required: true,
    },
    showChart: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      default: getBrowserTimezone(),
    },
  },
  setup(props) {
    const { intlFormatLocale } = useLocale();
    const chartData = ref<TodayEnergyChartData[]>([]);
    const todayProduction = computed(() =>
      getFormattedNumberValue(props.reportData?.gen_kwh.origin ?? 0, intlFormatLocale.value)
    );

    const options = computed(() =>
      getTodayProductionChartOptions({ locale: intlFormatLocale.value, data: chartData.value, height: CHART_HEIGHT })
    );

    watch([() => props.showChart, () => props.reportData], () => {
      if (props.reportData) {
        chartData.value = createChartData(
          todayProduction.value.ori,
          props.reportData.gen_data,
          props.reportData.colec_time,
          props.timezone
        );
      }
    });

    return {
      todayProduction,
      options,
    };
  },
});
