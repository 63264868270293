
import { useModelWrapper } from '@hems/util';
import { defineComponent, reactive, PropType } from 'vue';
export default defineComponent({
  name: 'Tab',
  props: {
    modelValue: {
      type: String as PropType<string>,
    },
    tabList: {
      type: Array as PropType<{ text: string; value: string }[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      tabname: useModelWrapper(props, emit),
    });

    function switchTab(tabname: string) {
      state.tabname = tabname;
    }

    return {
      state,
      switchTab,
    };
  },
});
