import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68701db0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "image"
}
const _hoisted_2 = {
  key: 2,
  class: "btn-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: _normalizeClass(["message-noti-dim", { on: _ctx.messageNotification.on }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["message-noti-box", _ctx.messageNotification.type]),
        style: _normalizeStyle({ 'min-width': _ctx.messageNotification.width + `px`, 'min-height': _ctx.messageNotification.height + `px` })
      }, [
        (_ctx.messageNotification.isIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_ctx.messageNotification.imageName)
                ? (_openBlock(), _createBlock(_component_Image2, {
                    key: 0,
                    src: `notification/${_ctx.messageNotification.imageName}`,
                    extension: _ctx.messageNotification.imageExtension,
                    width: _ctx.messageNotification.imageSize,
                    height: _ctx.messageNotification.imageSize
                  }, null, 8, ["src", "extension", "width", "height"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.messageNotification.type === 'general' || 'promotion')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "cancel-icon",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
            }, [
              _createVNode(_component_Image2, {
                src: `notification/ic_re_cancel_default`,
                extension: "svg",
                width: "16",
                height: "16"
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["title", _ctx.messageNotification.type])
        }, _toDisplayString(_ctx.messageNotification.title), 3),
        _createElementVNode("div", {
          class: _normalizeClass(["message", _ctx.messageNotification.type])
        }, _toDisplayString(_ctx.messageNotification.message), 3),
        (_ctx.messageNotification.isButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.messageNotification.type === 'confirm')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "message-btn btn-ok",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onOk && _ctx.onOk(...args)))
                  }, " Yes "))
                : _createCommentVNode("", true),
              (_ctx.messageNotification.type !== 'promotion')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    ref: "btnCancelEl",
                    type: "button",
                    class: "message-btn btn-cancel",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
                  }, _toDisplayString(_ctx.messageNotification.type === 'error' || _ctx.messageNotification.type === 'success' ? 'Close' : 'Cancel'), 513))
                : (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    type: "button",
                    class: "message-btn btn-promotion",
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onProcess && _ctx.onProcess(...args)))
                  }, _toDisplayString(_ctx.$t('message.arcstream_promotion_more')), 1))
            ]))
          : _createCommentVNode("", true)
      ], 6)
    ], 2)
  ]))
}