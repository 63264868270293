
import { computed, defineComponent, onMounted, PropType, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { PopupWrapper, useMessageBox } from '@hems/component';
import { Helper } from '@hems/util';

export default defineComponent({
  name: 'FindIdPwPopup',
  components: {
    PopupWrapper,
  },
  props: {
    tabName: {
      type: String as PropType<'id' | 'pw'>,
      default: 'id',
    },
    result: { type: String as PropType<string | null>, default: null },
  },
  emits: ['close', 'clear', 'findId', 'resetPw'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    const idEl = ref(null as null | HTMLInputElement);
    const emailEl = ref(null as null | HTMLInputElement);
    const state = reactive({
      tabName: props.tabName,
      id: null as null | string,
      email: null as null | string,
      result: computed(() => props.result),
    });
    const closePopup = (e: Event) => {
      e.preventDefault();
      emit('close');
    };

    function onOk(e: Event) {
      e.preventDefault();
      if (state.tabName === 'id') {
        if (Helper.isNull(state.email)) {
          messageBox.alert(t('message.enter_email')).open();

          return;
        }
        emit('findId', state.email);
        clear();

        return;
      }

      if (state.tabName === 'pw') {
        if (Helper.isNull(state.email)) {
          messageBox.alert(t('message.enter_email')).open();

          return;
        }
        if (Helper.isNull(state.id)) {
          messageBox.alert(t('message.enter_id')).open();

          return;
        }
        emit('resetPw', state.id, state.email);
        clear();
      }
    }

    function onClickTab(tabName: 'id' | 'pw') {
      clear();
      state.tabName = tabName;
      if (tabName === 'id') emailEl.value?.focus();
      else if (tabName === 'pw') idEl.value?.focus();
    }

    function clear() {
      state.id = null;
      state.email = null;
      emit('clear');
    }

    onMounted(() => {
      onClickTab(props.tabName);
    });

    return {
      state,
      idEl,
      emailEl,
      closePopup,
      onOk,
      onClickTab,
    };
  },
});
