
import type { PropType } from 'vue';
import { computed, defineComponent, reactive } from 'vue';

import { Button } from '@hems/component';
import { Helper } from '@hems/util';

import type { FormMode } from 'hems';
export default defineComponent({
  name: 'GroupTargetEssContainer',
  components: {
    Button,
  },
  props: {
    mode: {
      type: String as PropType<FormMode>,
      default: 'read',
    },
    title: {
      type: String,
      default: 'Target ESS',
    },
    total: {
      type: Number,
      default: 0,
    },
    stable: {
      type: String,
      default: 'N',
    },
  },
  emits: ['goToList', 'delete', 'add', 'next'],
  setup(props, { emit }) {
    const state = reactive({
      mode: computed(() => props.mode),
      total: computed(() => Helper.formatNumber(props.total)),
      stable: computed(() => props.stable),
    });

    function goToList() {
      emit('goToList');
    }
    function onDelete() {
      emit('delete');
    }
    function onAdd() {
      emit('add');
    }
    function onNext() {
      emit('next');
    }

    return {
      state,
      goToList,
      onDelete,
      onAdd,
      onNext,
    };
  },
});
