
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CIImage',
  setup() {
    return {
      onClick() {
        window.location.href = '/';
      },
    };
  },
});
