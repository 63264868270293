
import { defineComponent, PropType, reactive, computed } from 'vue';
import { LabelInput, Searchbox } from '@hems/component';
import { useI18n } from 'vue-i18n';
import { CustomerSearchBoxOptions } from 'hems/user';
import _ from 'lodash';
import { Helper } from '@hems/util';
import { MakeFieldNonNullable } from 'hems/common/utils';

export default defineComponent({
  name: 'CustomerSearchBox',
  components: {
    Searchbox,
    LabelInput,
  },
  props: {
    condition: {
      type: Object as PropType<CustomerSearchBoxOptions>,
      default: () => ({
        searchFor: '',
      }),
    },
  },
  emits: ['search'],
  async setup(props, { emit }) {
    const { t } = useI18n();
    const state = reactive<{ searchCondition: MakeFieldNonNullable<CustomerSearchBoxOptions, 'searchFor'> }>({
      searchCondition: { ...props.condition, searchFor: props.condition.searchFor ?? '' },
    });

    function onSearch() {
      state.searchCondition.searchFor = state.searchCondition.searchFor?.trim() ?? '';
      emit(
        'search',
        _.pickBy(state.searchCondition, (v) => !Helper.isNull(v))
      );
    }

    return {
      state,
      onSearch,
      placeholder: computed(() =>
        t('message.enter_search_word').concat(
          ` (${[
            t('account.user_name'),
            t('account.id'),
            t('common.site_id'),
            t('device.serial_no'),
            t('common.phone_number'),
            t('account.email'),
          ].join(', ')})`
        )
      ),
    };
  },
});
