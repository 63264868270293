
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { EngineerSettings } from 'hems/device/settings/pvmodule/gen3';
import EngineerSettingsPvModuleGen3SmartModuleGen2 from '@hems/container/src/forms/device/settings/_shared/engineer/EngineerSettingsPvModuleGen3SmartModuleGen2.vue';
import type { SiteId } from 'hems/device';
// TODO smartmodule 타입 사용하지 않고 제거하기
import type { EngineerSettingsSmartModuleGen2 } from 'hems/device/settings/smartmodule';
import type { EngineerSettingsPvModuleGen3 } from 'hems/device/settings/pvmodule';

export default defineComponent({
  name: 'EngineerSettingsPvModuleGen3Container',
  components: {
    EngineerSettingsPvModuleGen3SmartModuleGen2,
  },
  props: {
    data: {
      type: Object as PropType<EngineerSettingsPvModuleGen3 & EngineerSettingsSmartModuleGen2>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    isConnection: {
      type: Boolean,
      required: true,
    },
    isHcore: {
      type: Boolean,
      default: false,
    },
    isQcore: {
      type: Boolean,
      default: false,
    },
    isAcCoupled: {
      type: Boolean,
      default: false,
    },
    isFcas: {
      type: Boolean,
      default: false,
    },
    siteId: {
      type: Number as PropType<SiteId>,
      default: null,
    },
  },
  emits: ['goToList', 'save', 'cancel', 'edit'],
  async setup(props, { emit }) {
    function onSave(params: Partial<EngineerSettings>, callback?: (isOk: boolean) => void) {
      emit('save', params, callback);
    }
    function onEdit(copyData: EngineerSettings) {
      emit('edit', copyData);
    }

    return {
      onSave,
      onEdit,
    };
  },
});
