
import { CommonService } from '@hems/service';
import { defineComponent } from 'vue';
import MaintenancePage from '@hems/component/src/redirectPages/MaintenancePage.vue';

export default defineComponent({
  name: 'HealthCheckContainer',
  components: { MaintenancePage },
  async setup() {
    const commonService = new CommonService(window.axiosInstance.axios);

    return {
      isGood: await commonService.healthCheck(),
    };
  },
});
