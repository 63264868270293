import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5782089f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flow-img"
}
const _hoisted_2 = {
  key: 1,
  class: "flow-img"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_ctx.isOnGrid)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Image2, {
          src: _ctx.ENERGY_FLOW_IMAGE.PIPE_IMAGE_PATH,
          extension: "svg"
        }, null, 8, ["src"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_Image2, {
          src: _ctx.ENERGY_FLOW_IMAGE.OFF_GRID_PIPE_IMAGE_PATH,
          extension: "svg"
        }, null, 8, ["src"])
      ]))
}