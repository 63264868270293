
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SettingTable',
  props: {
    tableTitle: String,
    tableSubTitle: String,
    titleWidth: {
      type: String,
      default: '50%',
    },
    caption: String,
  },
});
