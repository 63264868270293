
import type { Ref } from 'vue';
import { defineComponent, onMounted, ref } from 'vue';
export type SignPadPoint = { lx: number; ly: number; mx: number; my: number };

export default defineComponent({
  name: 'SignPadViewer',
  props: {
    agreeSign: {
      type: String,
      default: null,
    },
    agreeSignBinary: {
      type: String,
      default: null,
    },
    minHeight: {
      type: Number,
      default: 200,
    },
    width: {
      type: Number,
      default: 650,
    },
    hideDownload: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const signPad: Ref<HTMLElement | null> = ref(null);
    const signImageUrl = ref<string | null>(null);

    const toImage = (agreeSign?: string) => {
      const data: SignPadPoint[] = JSON.parse(agreeSign || '[]');
      if (!signPad.value) return;

      $(signPad.value).signaturePad({ displayOnly: true }).regenerate(data);

      return $(signPad.value).signaturePad().getSignatureImage();
    };

    onMounted(() => {
      if (props.agreeSignBinary) {
        signImageUrl.value = `data:image/png;base64, ${props.agreeSignBinary}`;
      } else {
        signImageUrl.value = toImage(props.agreeSign);
      }
    });

    const download = () => {
      let link = document.createElement('a');
      link.download = 'sign.png';
      link.href = signImageUrl.value || '#';
      link.click();
    };

    return {
      signImageUrl,
      download,
    };
  },
});
