import { CellRendererProps } from 'tui-grid/types/renderer';
import { isNull } from '@hems/util/src/helper/helper';

class CommentsRenderer {
  private el: HTMLElement;
  private tooltipContainer: JQuery<HTMLElement>;
  private img: JQuery<HTMLElement>;
  private text: JQuery<HTMLElement>;
  private tooltipbox: JQuery<HTMLElement>;

  constructor(props: CellRendererProps) {
    const el = $('<div class="comments-container"></div>');
    this.tooltipContainer = $('<div class="utc_tooltip"></div>');
    this.img = $('<span class="state_comments"></span>');
    this.text = $('<div class="state_text"></div>');
    this.tooltipbox = $('<span class="tooltip-comments"></span>');

    el.append(this.text);
    el.append(this.tooltipContainer);

    this.el = el.get(0);
    this.render(props);
  }

  getElement(): HTMLElement {
    return this.el;
  }

  async render(props: CellRendererProps): Promise<void> {
    const options = props.columnInfo.renderer.options;
    const position = options ? options.position : [];
    const tooltip_text = props.grid.getValue(props.rowKey, 'memo');

    //tooltip
    if (!isNull(tooltip_text)) {
      this.tooltipContainer.append(this.img).append(this.tooltipbox);
      position.forEach((pos: string) => {
        this.tooltipbox.addClass(pos);
      });
      this.tooltipbox.text(`${tooltip_text}`);
    }
  }
}

export default CommentsRenderer;
