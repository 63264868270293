import { AxiosInstance } from 'axios';
import { CommonResponseWrapper, Device } from 'hems';
import { DeviceId, SiteId } from 'hems/device/types';
import { apiWrapper } from '../../util/helper';
import { DeviceService } from './DeviceService';
import { NMIRegisterParams, SAPNLfdiInfo } from 'hems/device';
import { CustomerAgreement, SiteDetail, SiteDemoDetail, SerialValid } from 'hems/install';

export default class DeviceServiceInstaller extends DeviceService {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getRecentInstalledDeviceList(pageNum: number): Promise<Device.RecentInstalledDevice[]> {
    const { data } = await this.get<CommonResponseWrapper<Device.RecentInstalledDevice[]>>(
      apiWrapper.managerApi(`/devices/profiles/dashboards`),
      { pageNum }
    );
    return data;
  }

  // TODO: 사용하지 않음 -> 삭제
  public async getDeviceSimpleInfo(deviceId: Device.DeviceId): Promise<Device.DeviceSimpleInfo> {
    return await this.get<Device.DeviceSimpleInfo>(apiWrapper.installerAPI(`/mon/type/${deviceId}`));
  }

  public async installDevice(params: FormData): Promise<{ siteId: string }> {
    const { data } = await this.post<CommonResponseWrapper<{ siteId: string }>>(
      apiWrapper.managerApi(`/devices/installations/ems`),
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return data;
  }

  // 장비 목록
  public async getDeviceList(): Promise<Device.DeviceListDownlLoadItem[]> {
    const { data } = await this.get<CommonResponseWrapper<Device.DeviceListDownlLoadItem[]>>(
      apiWrapper.managerApi(`/devices/profiles/excel-files`)
    );
    return data;
  }

  public async registerNMI(siteId: SiteId, params: Partial<NMIRegisterParams>): Promise<{ siteId: SiteId }> {
    const { data } = await this.post<CommonResponseWrapper<{ siteId: SiteId }>>(
      apiWrapper.managerApi(`/devices/installations/nmi/${siteId}`),
      params
    );
    return data;
  }

  public async getLfdi(siteId: SiteId): Promise<SAPNLfdiInfo | null> {
    const { data } = await this.get<CommonResponseWrapper<SAPNLfdiInfo | null>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/lfdi`)
    );
    return data;
  }

  public async registerSite(params: Partial<SiteDetail>): Promise<{ siteId: SiteId }> {
    const { data } = await this.post<CommonResponseWrapper<{ siteId: SiteId }>>(
      apiWrapper.managerApi(`/managements/mobiles/pro-apps/sites`),
      params
    );
    return data;
  }

  public async registerDemoSite(params: Partial<SiteDemoDetail>): Promise<{ siteId: SiteId }> {
    const { data } = await this.post<CommonResponseWrapper<{ siteId: SiteId }>>(
      apiWrapper.managerApi(`/managements/mobiles/pro-apps/sites`),
      params
    );
    return data;
  }

  public async updateStepData(siteId: SiteId, params: string): Promise<any> {
    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/mobiles/pro-apps/steps`),
      {
        siteId: siteId,
        stepData: params,
      }
    );
    return data;
  }

  public async checkValidAcDevice(deviceId: Device.DeviceId): Promise<SerialValid> {
    const { data } = await this.get<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/devices/profiles/validations/${deviceId}/ac-device-id`)
    );
    return data;
  }
}
