
import { defineComponent, defineAsyncComponent } from 'vue';
import { PopupWrapper } from '@hems/component';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'NoticePopup',
  components: {
    PopupWrapper,
  },
  props: {
    popupName: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  setup(props) {
    const store = useStore();
    const { envLocale, langCd } = store.state.appCtx;

    const NoticePopupCompoment = defineAsyncComponent(async () => {
      try {
        return await import(`./${envLocale}/${langCd}/${props.popupName}.vue`);
      } catch (error) {
        return await import(`./${envLocale}/en/${props.popupName}.vue`);
      }
    });

    return {
      NoticePopupCompoment,
    };
  },
});
