import { CellRendererProps } from 'tui-grid/types/renderer';

class DeleteImageRenderer {
  private el: HTMLImageElement;

  constructor(props: CellRendererProps) {
    const el = document.createElement('img');
    this.el = el;
    this.render(props);
  }

  getElement(): HTMLImageElement {
    return this.el;
  }

  async render(props: CellRendererProps): Promise<void> {
    const editable = props.grid.getValue(props.rowKey, 'editable');
    const options = props.columnInfo.renderer.options;
    if (editable) {
      this.el.src = options
        ? (
            await import(
              `@hems/component/resources/images/${options.baseUrl || ''}/${options.imageName || ''}.${
                options.extension || 'png'
              }`
            )
          ).default
        : '';

      this.el.height = options?.height ?? 20;
      this.el.width = options?.width ?? 20;
    }
  }
}

export default DeleteImageRenderer;
