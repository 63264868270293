
import type { PropType } from 'vue';
import { defineComponent, onBeforeUnmount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Options, Chart, SVGElement, TooltipPositionerPointObject, SeriesSplineOptions } from 'highcharts';

import { Panel, BasicCheckbox } from '@hems/component';
import { getLabelText } from '@hems/container/src/forms/device/statistics/_shared';
import BaseGraph from '@hems/container/src/highcharts/spline/BaseGraph.vue';
import {
  GRAPH_Y_AXIS_UNIT,
  HIGHCHART_SERIES_COLOR,
  HIGHCHART_DIVIDING_LINE_COLOR,
  GRAPH_SERIES_TYPE,
} from '@hems/util/src/constant';
import { integratedGraphTooltipFormatter } from '@hems/util/src/helper/graphHelper';
import { capitalize } from '@hems/util/src/helper/helper';
import { getStringValue } from '@hems/util/src/helper/tsguardHelper';

import type { StatTypes } from 'hems/device/statistics';
import type { SuperFilterItem } from 'hems/device/statistics/common';
import type { GraphCheckboxFilter, IntegratedGraphCheckboxFilter } from 'hems/statistics';

const GRAPH_SECTION = {
  TOP: {
    HEIGHT: '48%',
  },
  MIDDLE: {
    POSITION: '50%',
    HEIGHT: '23%',
  },
  BOTTOM: {
    POSITION: '75%',
    HEIGHT: '23%',
  },
};

const GRAPH_SIZE = {
  WIDTH: 1160,
  HEIGHT: 1100,
};

export default defineComponent({
  name: 'IntegratedDeviceLineGraph',
  components: {
    Panel,
    BaseGraph,
    BasicCheckbox,
  },
  props: {
    checkAll: {
      type: Boolean,
      default: () => true,
    },
    titleList: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    filterList: {
      type: Array as PropType<Array<SuperFilterItem[]>>,
      default: () => [],
    },
    xAxisCategories: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    data: {
      type: Array as PropType<StatTypes.SimpleSeries[]>,
      default: () => [],
    },
  },

  setup(props) {
    const { t } = useI18n();
    // FIXME: 기본 값을 넣어주면 setup 전체에 타입 오류가 발생 -> 코드 리팩토링이 필요할 것으로 생각되어 추후 수정 필요
    const options = ref<Options>();

    const filters: IntegratedGraphCheckboxFilter[] = props.filterList.map(
      (filterItems: SuperFilterItem[], idx: number) => {
        return {
          title: props.titleList[idx],
          items: filterItems.map((filterItem: SuperFilterItem) => ({
            text: getLabelText(filterItem),
            value: filterItem.code,
            isChecked: filterItem.defaultChecked ?? false,
          })),
        };
      }
    );

    const isAllChecked = ref(props.checkAll);
    const isCategoryAllChecked = ref(filters.map((item) => item.items.every((checkboxItem) => checkboxItem.isChecked)));
    const chartInstance = ref<Chart | null>(null);
    const checkedFilters = ref<GraphCheckboxFilter[][]>(
      filters.map((filter) => {
        return filter.items.map((item) => {
          return { name: item.value, isChecked: props.checkAll ? true : item.isChecked };
        });
      })
    );

    const seriesRedrawState: Record<number, boolean> = {};
    let crosshair: SVGElement | null = null;

    onBeforeUnmount(() => {
      chartInstance.value = null;
    });

    const createChart = (instance: Chart) => {
      chartInstance.value = instance;
    };

    watch([() => props.xAxisCategories, () => props.data], () => {
      options.value = { ...getOptions(), ...getOptionForUpdate(props.xAxisCategories, props.data) };
    });

    function onCheckAll() {
      isAllChecked.value = !isAllChecked.value;
      if (isAllChecked.value) {
        isCategoryAllChecked.value = isCategoryAllChecked.value.map(() => true);
        checkedFilters.value = checkedFilters.value.map((items) => {
          return items.map((item) => {
            return { name: item.name, isChecked: true };
          });
        });
        if (!chartInstance.value) return;
        options.value = { ...getOptions(), ...getOptionForUpdate(props.xAxisCategories, props.data) };
      } else {
        isCategoryAllChecked.value = isCategoryAllChecked.value.map(() => false);
        checkedFilters.value = checkedFilters.value.map((items) => {
          return items.map((item) => {
            return { name: item.name, isChecked: false };
          });
        });
        if (!chartInstance.value) return;
        chartInstance.value.series?.forEach((series) => series.setVisible(false, false));
        chartInstance.value.redraw();
      }
    }

    function onCheckCategoryAll(idx: number) {
      isCategoryAllChecked.value[idx] = !isCategoryAllChecked.value[idx];
      if (isCategoryAllChecked.value[idx]) {
        checkedFilters.value[idx] = checkedFilters.value[idx].map((item) => {
          return { name: item.name, isChecked: true };
        });
      } else {
        checkedFilters.value[idx] = checkedFilters.value[idx].map((item) => {
          return { name: item.name, isChecked: false };
        });
      }

      isAllChecked.value = isCategoryAllChecked.value.every((isChecked) => isChecked);

      if (!chartInstance.value) return;
      let startIdx = 0;
      let endIdx = 0;
      for (let i = 0; i <= idx; i++) {
        if (i >= 0 && i <= idx - 1) {
          startIdx += filters[i].items.length;
        }
        endIdx += filters[i].items.length;
      }
      if (isCategoryAllChecked.value[idx]) {
        options.value = { ...getOptions(), ...getOptionForUpdate(props.xAxisCategories, props.data) };
      } else {
        for (let i = startIdx; i < endIdx; i++) {
          chartInstance.value.series?.[i]?.setVisible(false, false);
        }
      }
      chartInstance.value.redraw();
    }

    function onClickCheckbox(idx: number, idx2: number) {
      let index = 0;
      for (let i = 0; i < idx; i++) {
        index += filters[i].items.length;
      }
      checkedFilters.value[idx][idx2].isChecked = !checkedFilters.value[idx][idx2].isChecked;
      isCategoryAllChecked.value[idx] = checkedFilters.value[idx].map((item) => item.isChecked).includes(false)
        ? false
        : true;
      isAllChecked.value = isCategoryAllChecked.value.includes(false) ? false : true;

      if (!chartInstance.value) return;
      if (!checkedFilters.value[idx][idx2].isChecked) {
        chartInstance.value.series?.[index + idx2].setVisible(false, false);
      } else {
        chartInstance.value.series?.[index + idx2].setVisible(true, seriesRedrawState[index + idx2] ?? true);
        seriesRedrawState[index + idx2] = false;
      }
      chartInstance.value.redraw();
    }

    const highchartSeriesColors: string[] = Object.values(HIGHCHART_SERIES_COLOR);

    function getOptions(): Options {
      return {
        credits: { enabled: false },
        title: { text: '' },
        colors: highchartSeriesColors,
        chart: {
          type: GRAPH_SERIES_TYPE.SPLINE,
          zoomType: 'x',
          width: GRAPH_SIZE.WIDTH,
          height: GRAPH_SIZE.HEIGHT,
        },
        boost: {
          useGPUTranslations: true,
        },
        yAxis: [
          {
            id: GRAPH_Y_AXIS_UNIT.WATT,
            title: {
              text: `${t('device.power')} (${GRAPH_Y_AXIS_UNIT.WATT})`,
            },
            showEmpty: false,
            lineWidth: 3,
            lineColor: HIGHCHART_DIVIDING_LINE_COLOR,
            height: GRAPH_SECTION.TOP.HEIGHT,
          },
          {
            id: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            title: {
              text: `${t('common.energy')} (${GRAPH_Y_AXIS_UNIT.WATT_HOUR})`,
            },
            min: 0,
            showEmpty: false,
            lineWidth: 3,
            lineColor: HIGHCHART_DIVIDING_LINE_COLOR,
            offset: 0,
            top: GRAPH_SECTION.BOTTOM.POSITION,
            height: GRAPH_SECTION.BOTTOM.HEIGHT,
          },
          {
            id: GRAPH_Y_AXIS_UNIT.KILOWATT_HOUR,
            title: {
              text: `${t('common.energy')} (${GRAPH_Y_AXIS_UNIT.KILOWATT_HOUR})`,
            },
            min: 0,
            showEmpty: false,
            lineWidth: 3,
            lineColor: HIGHCHART_DIVIDING_LINE_COLOR,
            offset: 0,
            top: GRAPH_SECTION.BOTTOM.POSITION,
            height: GRAPH_SECTION.BOTTOM.HEIGHT,
          },
          {
            id: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            title: {
              text: `${t('device.voltage')} (${GRAPH_Y_AXIS_UNIT.VOLTAGE})`,
            },
            showEmpty: false,
            height: GRAPH_SECTION.TOP.HEIGHT,
          },
          {
            id: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE,
            title: {
              text: `Apparent Power (${GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE})`,
            },
            showEmpty: false,
            height: GRAPH_SECTION.TOP.HEIGHT,
          },
          {
            id: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE,
            title: {
              text: `${t('device.voltage')} (${GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE})`,
            },
            showEmpty: false,
            height: GRAPH_SECTION.TOP.HEIGHT,
          },
          {
            id: GRAPH_Y_AXIS_UNIT.AMPERE,
            title: {
              text: `${t('device.current')} (${GRAPH_Y_AXIS_UNIT.AMPERE})`,
            },
            showEmpty: false,
            height: GRAPH_SECTION.TOP.HEIGHT,
          },
          {
            id: GRAPH_Y_AXIS_UNIT.HERTZ,
            title: {
              text: `${t('device.frequency')} (${GRAPH_Y_AXIS_UNIT.HERTZ})`,
            },
            opposite: true,
            showEmpty: false,
            height: GRAPH_SECTION.TOP.HEIGHT,
          },
          {
            id: GRAPH_Y_AXIS_UNIT.CELSIUS,
            title: {
              text: `${t('device.temp')} (${GRAPH_Y_AXIS_UNIT.CELSIUS})`,
            },
            opposite: true,
            showEmpty: false,
            offset: 0,
            top: GRAPH_SECTION.MIDDLE.POSITION,
            height: GRAPH_SECTION.MIDDLE.HEIGHT,
          },
          {
            id: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            title: {
              text: `${t('common.percentage')} (${GRAPH_Y_AXIS_UNIT.PERCENTAGE})`,
            },
            showEmpty: false,
            lineWidth: 3,
            lineColor: HIGHCHART_DIVIDING_LINE_COLOR,
            offset: 0,
            top: GRAPH_SECTION.MIDDLE.POSITION,
            height: GRAPH_SECTION.MIDDLE.HEIGHT,
          },
          {
            id: GRAPH_Y_AXIS_UNIT.FACTOR,
            title: {
              text: capitalize(GRAPH_Y_AXIS_UNIT.FACTOR),
            },
            opposite: true,
            showEmpty: false,
            height: GRAPH_SECTION.TOP.HEIGHT,
          },
        ],
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
        },
        plotOptions: {
          series: {
            lineWidth: 1,
            turboThreshold: 2000,
            events: {
              legendItemClick(evt) {
                const id: string | null = getStringValue(evt.target.userOptions.id);
                const visible = evt.target.visible;
                checkedFilters.value.forEach((items, i) => {
                  items.forEach((item, j) => {
                    if (id && item.name === id) {
                      checkedFilters.value[i][j].isChecked = visible ? false : true;
                    }
                  });
                });

                return true;
              },
            },
          },
        },
        series: [],
        tooltip: {
          useHTML: true,
          formatter: function () {
            return integratedGraphTooltipFormatter(this);
          },
          positioner: function (labelWidth: number, labelHeight: number, point: TooltipPositionerPointObject) {
            crosshair?.destroy();
            const chart = this.chart,
              container = chart.container,
              r = chart.renderer,
              top = chart.plotTop,
              left = chart.plotLeft,
              height = chart.plotHeight,
              x = point.plotX,
              y = point.plotY,
              chartWidth = chart.chartWidth;

            crosshair = r
              .path([
                ['M', left + x, top],
                ['L', left + x, top + height],
              ])
              .attr({
                'stroke-width': 1,
                stroke: HIGHCHART_DIVIDING_LINE_COLOR,
              })
              .add();

            const maxY = container.offsetTop + container.offsetHeight / 2,
              plotY = container.offsetTop + y;

            return {
              x: chartWidth + container.offsetLeft,
              y: plotY > maxY ? maxY : plotY,
            };
          },
          outside: true,
        },
      };
    }

    function getOptionForUpdate(xAxisCategories: string[], data: StatTypes.SimpleSeries[]): Options {
      const series: SeriesSplineOptions[] = data.map((item, idx) => {
        seriesRedrawState[idx] = checkedFilters.value
          .map((items) => items.map((item) => !item.isChecked).flat())
          .flat()[idx];

        return {
          ...item,
          boostThreshold: 1,
          turboThreshold: 1,
          visible: checkedFilters.value.map((items) => items.map((item) => item.isChecked).flat()).flat()[idx],
          type: GRAPH_SERIES_TYPE.SPLINE,
        };
      });

      return {
        xAxis: {
          categories: xAxisCategories,
          tickPositioner: () => {
            const tickX = new Set([0]);
            const regexp2hours = /(00|02|04|06|08|10|12|14|16|18|20|22|24):00$/g;
            xAxisCategories.forEach((item, index) => {
              if (regexp2hours.test(item)) tickX.add(index);
            });
            tickX.add(xAxisCategories.length - 1);

            return Array.from(tickX);
          },
        },
        series,
      };
    }

    return {
      options,
      filters,
      isAllChecked,
      isCategoryAllChecked,
      checkedFilters,
      createChart,
      onCheckAll,
      onCheckCategoryAll,
      onClickCheckbox,
    };
  },
});
