
import { defineComponent, reactive, ref, PropType } from 'vue';
import CommonPopup from '@hems/component/src/popup/CommonPopup.vue';
import BasicInput from '@hems/component/src/inputs/BasicInput.vue';
import { Form } from 'vee-validate';
import { Installation } from 'hems';
import * as yup from 'yup';
import { DeviceType } from 'hems/install';

export default defineComponent({
  name: 'ReplaceDevicePopup',
  components: {
    CommonPopup,
    Form,
    BasicInput,
  },
  emits: ['checkdeviceId', 'confirm'],
  props: {
    deviceId: {
      type: String as PropType<string>,
      required: true,
      defaultValue: '',
    },
    product_model_nm: {
      type: String as PropType<string>,
      required: true,
    },
    device_type_cd: {
      type: String as PropType<DeviceType>,
      required: true,
    },
  },
  async setup(props, { emit }) {
    const replaceDeviceForm = ref(null as null | any);

    let isInit = false;
    const state = reactive({
      data: {
        deviceId: props.deviceId,
        productModelNm: props.product_model_nm,
      } as Installation.DeviceInfo,
      validClass: '',
    });

    const prevValues = {
      value: {
        deviceId: props.deviceId,
        productModelNm: props.product_model_nm,
      },
      status: false,
    };

    const schema = yup.object().shape({
      deviceId: yup
        .string()
        .deviceId(prevValues, (isValid, result) => {
          if (isValid) {
            state.data.productModelNm = result.product_model_nm;
            state.validClass = 'ok';
          } else {
            state.data.productModelNm = '';
            state.validClass = 'error';
          }
        })
        .nullable()
        .required(),
    });

    async function onConfirm() {
      const { valid } = await replaceDeviceForm.value.validate();
      if (valid) {
        const { deviceId, productModelNm } = state.data;
        emit('confirm', {
          newDeviceId: deviceId,
          productModelNm,
        });
      }
    }

    isInit = true;

    return {
      isInit,
      state,
      onConfirm,
      schema,
      replaceDeviceForm,
    };
  },
});
