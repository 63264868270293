
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';

import { DateHelper, useSiteDeviceGenType, useSiteId, useSiteInfo } from '@hems/util';
import { NUMBER_UNIT } from '@hems/util/src/constant';

import type { Timer } from 'hems';

export default defineComponent({
  name: 'MonitoringDetailsHeader',
  setup() {
    const { isAC } = useSiteDeviceGenType();
    const { siteId } = useSiteId();
    const { masterDeviceProfile } = useSiteInfo();

    const deviceId = masterDeviceProfile?.device_id;

    const timezoneId = masterDeviceProfile?.timezone_id;

    const getLocalTime = () =>
      DateHelper.getLocalDate(DateHelper.now(), {
        isTime: true,
        needTimezoneConvert: true,
        timezone: timezoneId,
      });

    const localTime = ref(getLocalTime());

    const updateTime = () => {
      localTime.value = getLocalTime();
    };

    let intervalId: Timer;

    onMounted(() => {
      intervalId = setInterval(updateTime, NUMBER_UNIT.THOUSAND);
    });

    onBeforeUnmount(() => clearInterval(intervalId));

    return {
      siteId,
      deviceId,
      isAC,
      timezoneId,
      localTime,
    };
  },
});
