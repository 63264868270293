import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tal" }
const _hoisted_2 = { class: "ir ico_del" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, _toDisplayString(_ctx.data.time), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.actionText), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.data.inverterReference), 1),
    _createElementVNode("td", _hoisted_1, [
      (_ctx.editable)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn_etc",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeRow && _ctx.removeRow(...args)))
          }, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('common.delete')), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}