import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-921d0c9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "prompt-contents" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "button-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: _normalizeClass(["prompt-dim", { on: _ctx.state.promptPopup.on }])
    }, [
      _createElementVNode("div", _mergeProps({ class: "prompt-box" }, _ctx.$attrs), [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.showClose)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                ref: "btnCloseEl",
                type: "button",
                class: "btn_xs btn_c_2",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
              }, _toDisplayString(_ctx.closeButtonText), 513))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            type: "button",
            class: "btn_xs btn_c_3",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onConfirm && _ctx.onConfirm(...args)))
          }, _toDisplayString(_ctx.confirmButtonText), 1)
        ])
      ], 16)
    ], 2)
  ]))
}