
import type { PropType } from 'vue';
import { computed, defineComponent, reactive, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useMessageBox } from '@hems/component';
import AddPricingItemPopup from '@hems/container/src/forms/device/settings/_shared/pricing/component/AddPricingItemPopup.vue';
import PricingTableRow from '@hems/container/src/forms/device/settings/_shared/pricing/component/PricingTableRow.vue';

import type { PricingItem } from 'hems/device/settings/pvmodule/gen3';

export default defineComponent({
  name: 'PricingTable',
  components: {
    AddPricingItemPopup,
    PricingTableRow,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Object as PropType<PricingItem[]>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    maxCount: {
      type: Number,
      default: 0,
    },
    minuteOptionUnit: {
      type: Number,
      default: 1,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const state = reactive<{ data: PricingItem[] }>({
      data: props.modelValue ?? [],
    });

    const openPopup = ref(false);

    const allowed = computed(() => (props.modelValue?.length ?? 0) < props.maxCount);

    watch(
      () => props.modelValue,
      () => {
        state.data = props.modelValue;
      }
    );

    const messageBox = useMessageBox();

    function togglePopup() {
      if (!allowed.value) {
        messageBox.alert(t('message.up_to_max', { max_count: props.maxCount })).open();

        return;
      }
      openPopup.value = !openPopup.value;
    }

    function closePopup() {
      openPopup.value = false;
    }

    function concatPricingItem(data: PricingItem) {
      const idx = state.data.findIndex((item) => item.time === data.time);
      let pricingItemList: PricingItem[];
      if (idx >= 0) {
        pricingItemList = [...state.data];
        pricingItemList[idx] = data;
      } else {
        pricingItemList = state.data.concat(data).sort((a, b) => (a.time > b.time ? 1 : -1));
      }

      return pricingItemList;
    }

    function removePricingItemByIndex(index: number) {
      return state.data.filter((_, idx) => idx !== index);
    }

    function onAdd(data: PricingItem) {
      emit('update:modelValue', concatPricingItem(data));
      closePopup();
    }

    function onRemove(idx: number) {
      emit('update:modelValue', removePricingItemByIndex(idx));
    }

    return {
      state,
      openPopup,
      togglePopup,
      closePopup,
      onAdd,
      onRemove,
    };
  },
});
