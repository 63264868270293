import { AxiosInstance } from 'axios';
import { CommonResponseWrapper, Device } from 'hems';
import { apiWrapper } from '../../util/helper';
import { StatisticsService } from './StatisticsService';

export interface DeviceChartParams {
  cntryCd: string | null;
  allCntryCd: string | null;
}

export default class StatisticsServiceAdmin extends StatisticsService {
  constructor(axios: AxiosInstance) {
    super(axios);
  }
  // @TODO: isMulti 사용 여부 확인 후 삭제
  public async getDeviceCount(
    params: DeviceChartParams | Omit<DeviceChartParams, 'allCntryCd'>,
    isMulti?: boolean
  ): Promise<Device.DeviceCountByStatus> {
    const { data } = await this.get<CommonResponseWrapper<Device.DeviceCountByStatus>>(
      // isMulti
      //   ? apiWrapper.stateApi(`/devices/charts/data/multi-inverter`)
      //   : apiWrapper.stateApi(`/devices/charts/data`),
      apiWrapper.stateApi('/devices/charts/data/renewal'),
      params
    );
    return data;
  }
  public async getLastWeekInstalledDeviceCount(
    params: DeviceChartParams | Omit<DeviceChartParams, 'allCntryCd'>
  ): Promise<Device.LastWeekInstalledDeviceCountByStatus> {
    const { data } = await this.get<CommonResponseWrapper<Device.LastWeekInstalledDeviceCountByStatus>>(
      apiWrapper.managerApi(`/managements/activities/instl-last-week`),
      params
    );
    return data;
  }
}

export { StatisticsServiceAdmin };
