import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-217ea9fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pop_tit" }
const _hoisted_2 = {
  key: 0,
  class: "region_selector_wrap"
}
const _hoisted_3 = { style: {"text-align":"left","margin-top":"2rem"} }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "btn_wrap_m mt50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Selector = _resolveComponent("Selector")!
  const _component_PopupWrapper = _resolveComponent("PopupWrapper")!

  return (_openBlock(), _createBlock(_component_PopupWrapper, {
    class: "join_wrap",
    style: {"width":"1024px","opacity":"1","left":"50%","margin-left":"-640px","top":"190px","margin-bottom":"50px"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('message.terms_personal_privacy')), 1),
      (_ctx.isAU)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Selector, {
              modelValue: _ctx.selectedLocale,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLocale) = $event)),
              options: _ctx.auCountryOptions
            }, null, 8, ["modelValue", "options"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          innerHTML: _ctx.privacyPolicyTerms?.terms_contents
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          type: "button",
          class: "btn_m btn_c btn_w_2",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
        }, _toDisplayString(_ctx.$t('common.close')), 1)
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "close_btn_2",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
      }, _toDisplayString(_ctx.$t('common.close')), 1)
    ]),
    _: 1
  }))
}