import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pop_tit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Step1TermAndCondition = _resolveComponent("Step1TermAndCondition")!
  const _component_Step2RequiredData = _resolveComponent("Step2RequiredData")!
  const _component_Step3OptionalData = _resolveComponent("Step3OptionalData")!
  const _component_PopupWrapper = _resolveComponent("PopupWrapper")!

  return (_openBlock(), _createBlock(_component_PopupWrapper, {
    class: "join_wrap",
    style: {"opacity":"1","left":"50%","margin-left":"-640px","top":"190px","margin-bottom":"50px"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('account.join_member')), 1),
      (_ctx.state.step === 1)
        ? (_openBlock(), _createBlock(_component_Step1TermAndCondition, {
            key: 0,
            data: _ctx.state.termAndCondition,
            locale: _ctx.locale,
            "max-step": _ctx.maxStep,
            "terms-list": _ctx.termsList,
            onNext: _ctx.onNext
          }, null, 8, ["data", "locale", "max-step", "terms-list", "onNext"]))
        : _createCommentVNode("", true),
      (_ctx.state.step === 2)
        ? (_openBlock(), _createBlock(_component_Step2RequiredData, {
            key: 1,
            social: _ctx.social,
            data: _ctx.state.essentialData,
            "max-step": _ctx.maxStep,
            onNext: _ctx.onNext,
            onBack: _ctx.onBack
          }, null, 8, ["social", "data", "max-step", "onNext", "onBack"]))
        : _createCommentVNode("", true),
      (_ctx.state.step === 3)
        ? (_openBlock(), _createBlock(_component_Step3OptionalData, {
            key: 2,
            data: _ctx.state.optionalData,
            locale: _ctx.locale,
            "terms-list": _ctx.termsList,
            onNext: _ctx.onNext,
            onBack: _ctx.onBack
          }, null, 8, ["data", "locale", "terms-list", "onNext", "onBack"]))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        type: "button",
        class: "close_btn_2",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
      }, _toDisplayString(_ctx.$t('common.close')), 1)
    ]),
    _: 1
  }))
}