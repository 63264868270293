import type { ColumnOptions } from 'tui-grid';

import { CustomRenderer } from '@hems/component';
import type { ErrorHistoryGridTypeValue } from '@hems/util/src/constant/eventHistory';
import { ERROR_HISTORY_GRID_TYPE } from '@hems/util/src/constant/eventHistory';
import type { RoleNameValue } from '@hems/util/src/constant/role';
import { ROLE_NAME } from '@hems/util/src/constant/role';

import type { i18nTranslation } from 'hems';

export function getColumns(
  t: i18nTranslation,
  gridType: ErrorHistoryGridTypeValue = ERROR_HISTORY_GRID_TYPE.ALL,
  roleName: RoleNameValue = ROLE_NAME.INSTALLER,
  isUS = false
): ColumnOptions[] {
  if (gridType === ERROR_HISTORY_GRID_TYPE.ALL) {
    if (roleName === ROLE_NAME.INSTALLER) {
      return getColumnsForInstallerErrorHistory(t, isUS);
    }

    return getColumnsForAdminErrorHistory(t, isUS);
  }
  if (roleName === ROLE_NAME.INSTALLER) {
    return getColumnsForInstallerSiteErrorHistory(t, isUS);
  }

  return getColumnsForAdminSiteErrorHistory(t, isUS);
}

const getColumnsForAdminErrorHistory = (t: i18nTranslation, isUS: boolean): ColumnOptions[] => {
  return [
    {
      header: t('common.country'),
      name: 'countryCode',
      align: 'center',
      sortable: true,
      width: 130,
    },
    ...getCommonErrorHistoryColumns(t, isUS),
    getErrorLevelColumn(t, isUS),
    getDescriptionColumn(t),
  ];
};

const getColumnsForInstallerErrorHistory = (t: i18nTranslation, isUS: boolean): ColumnOptions[] => {
  const commonErrorHistoryColumns = getCommonErrorHistoryColumns(t, isUS);
  const descriptionColumn = getDescriptionColumn(t);

  if (isUS) {
    return [...commonErrorHistoryColumns, descriptionColumn];
  }

  return [...commonErrorHistoryColumns, getErrorLevelColumn(t, isUS), descriptionColumn];
};

const getColumnsForAdminSiteErrorHistory = (t: i18nTranslation, isUS = false): ColumnOptions[] => {
  return [...getCommonSiteErrorHistoryColumns(t, isUS), getErrorLevelColumn(t, isUS), getDescriptionColumn(t)];
};

const getColumnsForInstallerSiteErrorHistory = (t: i18nTranslation, isUS = false): ColumnOptions[] => {
  const commonSiteErrorHistoryColumns = getCommonSiteErrorHistoryColumns(t, isUS);
  const descriptionColumn = getDescriptionColumn(t);

  if (isUS) {
    return [...commonSiteErrorHistoryColumns, descriptionColumn];
  }

  return [...commonSiteErrorHistoryColumns, getErrorLevelColumn(t, isUS), descriptionColumn];
};

const getCommonErrorHistoryColumns = (t: i18nTranslation, isUS = false): ColumnOptions[] => {
  return [
    {
      header: t('common.start_dt'),
      name: 'triggerDate',
      align: 'center',
      width: 150,
      sortable: true,
      className: 'overflow-visible',
      renderer: {
        type: CustomRenderer.UTCTooltipRenderer,
        options: {
          tooltipColumnName: 'triggerDateUtc',
          position: ['bottom', 'right'],
        },
      },
    },
    {
      header: t('common.end_dt'),
      name: 'clearDate',
      align: 'center',
      width: 150,
      sortable: true,
      className: 'overflow-visible',
      renderer: {
        type: CustomRenderer.UTCTooltipRenderer,
        options: {
          tooltipColumnName: 'clearDateUtc',
          position: ['bottom', 'left'],
        },
      },
    },
    {
      header: t('common.site_id'),
      name: 'siteId',
      align: 'center',
      sortable: true,
      width: 130,
    },
    {
      header: t('device.serial_no'),
      name: 'deviceId',
      align: 'center',
      sortable: true,
      width: isUS ? 170 : 155,
      resizable: true,
    },
    {
      header: isUS ? t('device.err_code') : t('device.event_cd'),
      name: 'errorCode',
      align: 'center',
      sortable: true,
      width: 110,
    },
  ];
};

const getCommonSiteErrorHistoryColumns = (t: i18nTranslation, isUS = false): ColumnOptions[] => {
  return [
    {
      header: t('common.start_dt'),
      name: 'triggerDate',
      align: 'center',
      width: 180,
      sortable: true,
      className: 'overflow-visible',
      renderer: {
        type: CustomRenderer.UTCTooltipRenderer,
        options: {
          tooltipColumnName: 'triggerDateUtc',
          position: ['bottom', 'right'],
        },
      },
    },
    {
      header: t('common.end_dt'),
      name: 'clearDate',
      align: 'center',
      width: 180,
      sortable: true,
      className: 'overflow-visible',
      renderer: {
        type: CustomRenderer.UTCTooltipRenderer,
        options: {
          tooltipColumnName: 'clearDateUtc',
          position: ['bottom', 'left'],
        },
      },
    },
    {
      header: t('device.serial_no'),
      name: 'deviceId',
      align: 'center',
      sortable: true,
      width: isUS ? 160 : 155,
      resizable: true,
    },
    {
      header: isUS ? t('device.err_code') : t('common.event_cd'),
      name: 'errorCode',
      align: 'center',
      sortable: true,
      width: 120,
    },
  ];
};

const getErrorLevelColumn = (t: i18nTranslation, isUS: boolean): ColumnOptions => {
  return {
    header: isUS ? 'Error Level' : t('common.event_type'),
    name: 'errorLevel',
    align: 'center',
    width: 120,
    resizable: true,
  };
};

const getDescriptionColumn = (t: i18nTranslation): ColumnOptions => {
  return {
    header: t('common.description'),
    name: 'description',
    renderer: {
      type: CustomRenderer.AlarmDetailRenderer,
    },
    align: 'center',
    minWidth: 300,
    resizable: true,
  };
};
