
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import BasicSettingsPvModuleGen3SmartModuleGen2 from '@hems/container/src/forms/device/settings/_shared/basic/BasicSettingsPvModuleGen3SmartModuleGen2.vue';

import type { BasicSettings } from 'hems/device/settings/pvmodule/gen3';

export default defineComponent({
  name: 'BasicSettingGen3Container',
  components: {
    BasicSettingsPvModuleGen3SmartModuleGen2,
  },
  props: {
    data: {
      type: Object as PropType<Partial<BasicSettings>>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    isConnection: {
      type: Boolean,
      required: true,
    },
    isHcore: {
      type: Boolean,
      default: false,
    },
    isAcCoupled: {
      type: Boolean,
      default: false,
    },
    isHybrid: {
      type: Boolean,
      default: false,
    },
    isGrp: {
      type: Boolean,
      default: false,
    },
    timeZone: {
      type: String,
      default: '',
    },
    emsVersion: {
      type: String,
      default: '',
    },
    changedValueSet: {
      type: Object as PropType<Set<string>>,
      default: () => new Set<string>(),
    },
  },
  emits: ['goToList', 'save', 'generate', 'cancel', 'edit', 'changeWebHMIPassword'],
  async setup(props, { emit }) {
    function onSave(params: Partial<BasicSettings>, changedValueSet: Set<string>, callback?: (isOk: boolean) => void) {
      if (props.isGrp) {
        emit('save', params, changedValueSet);
      } else {
        emit('save', params, callback);
      }
    }

    function onGenerate(params: BasicSettings) {
      emit('generate', params);
    }

    function onEdit(copyData: BasicSettings) {
      emit('edit', copyData);
    }

    function onChangeWebHMIPassword(params: BasicSettings) {
      emit('changeWebHMIPassword', params);
    }

    return {
      onSave,
      onGenerate,
      onEdit,
      onChangeWebHMIPassword,
    };
  },
});
