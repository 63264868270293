
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import BasicSettingsACSystem from '@hems/container/src/forms/device/settings/_shared/basic/BasicSettingsACSystem.vue';

import type { BasicSettings } from 'hems/device/settings/smartmodule/acsys';

export default defineComponent({
  name: 'BasicSettingsSmartModuleACSystemContainer',
  components: {
    BasicSettingsACSystem,
  },
  props: {
    data: {
      type: Object as PropType<BasicSettings>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    isConnection: {
      type: Boolean,
      required: true,
    },
    productModelType: {
      type: String,
      required: true,
    },
    timeZone: {
      type: String,
      required: true,
    },
    changedValueSet: {
      type: Object as PropType<Set<string>>,
      default: () => new Set<string>(),
    },
  },
  emits: ['goToList', 'save', 'generate', 'cancel', 'edit', 'changeWebHMIPassword'],
  setup(props, { emit }) {
    function onSave(
      params: Partial<BasicSettings>,
      multiTabName: string,
      settingTarget: string,
      callback?: (isOk: boolean) => void
    ) {
      emit('save', params, multiTabName, settingTarget, callback);
    }

    function onGenerate(params: BasicSettings) {
      emit('generate', params);
    }

    function onEdit(copyData: BasicSettings) {
      emit('edit', copyData);
    }

    function onChangeWebHMIPassword(params: BasicSettings) {
      emit('changeWebHMIPassword', params);
    }

    return {
      onSave,
      onGenerate,
      onEdit,
      onChangeWebHMIPassword,
    };
  },
});
