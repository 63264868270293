import { AxiosInstance } from 'axios';
import { Service } from '../Service';
import { CommonResponseWrapper } from 'hems';
import { apiWrapper, getApiVersionHeader } from '../../util/helper';
import { DeviceId, SiteId } from 'hems/device';
import { StatisticsDataWithThirdParty } from 'hems/device/statistics/common';
import { StatisticsParams } from 'hems/statistics';

export default class StatisticsService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getDeviceStatistics(
    deviceId: DeviceId,
    params: StatisticsParams
  ): Promise<StatisticsDataWithThirdParty> {
    const { data } = await this.get<CommonResponseWrapper<StatisticsDataWithThirdParty>>(
      apiWrapper.managerApi(`/histories/graphs/${deviceId}`),
      params,
      {
        headers: getApiVersionHeader(),
      }
    );
    return data;
  }

  public async getSiteStatistics(siteId: SiteId, params: StatisticsParams): Promise<StatisticsDataWithThirdParty> {
    const { data } = await this.get<CommonResponseWrapper<StatisticsDataWithThirdParty>>(
      apiWrapper.managerApi(`/histories/graphs/${siteId}/ac-system`),
      params,
      {
        headers: getApiVersionHeader(),
      }
    );
    return data;
  }

  public async getFoxESSMonitoringData(deviceId: DeviceId): Promise<any> {
    const { data } = await this.get<CommonResponseWrapper<any>>(
      apiWrapper.stateApi(`/devices/telemetries/realtime/${deviceId}`)
    );
    return data;
  }
}

export { StatisticsService };
