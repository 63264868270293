import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withKeys as _withKeys, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a67ffea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "btn_wrap_m"
}
const _hoisted_2 = { class: "input-button-container" }
const _hoisted_3 = { class: "input" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "input-button-container" }
const _hoisted_6 = { class: "input" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "is_default" }
const _hoisted_9 = { class: "is_edit" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  type: "submit",
  class: "btn_m btn_c_3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_SettingTableRow = _resolveComponent("SettingTableRow")!
  const _component_SettingTable = _resolveComponent("SettingTable")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_ChangedMark = _resolveComponent("ChangedMark")!
  const _component_Selector = _resolveComponent("Selector")!
  const _component_BaseSlider = _resolveComponent("BaseSlider")!
  const _component_Form = _resolveComponent("Form")!
  const _component_SettingConfirmPopup = _resolveComponent("SettingConfirmPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Form, {
      "validation-schema": _ctx.schema,
      autocomplete: "off",
      onSubmit: _ctx.onSave,
      onKeydown: _cache[12] || (_cache[12] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
    }, {
      default: _withCtx(({ handleReset }) => [
        (!_ctx.state.isGrp)
          ? (_openBlock(), _createBlock(_component_Accordion, {
              key: 0,
              title: _ctx.$t('control.installer_temp_password'),
              active: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTable, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('control.last_generation_time'),
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.state.data.installer_page_temp_password_setting_time,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.data.installer_page_temp_password_setting_time) = $event)),
                          name: "installer_page_temp_password_setting_time",
                          readonly: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  _: 1
                }),
                (!_ctx.state.editable)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn_m btn_c_3",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onGenerate && _ctx.onGenerate(...args)))
                      }, _toDisplayString(_ctx.$t('common.generate')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        (
        !_ctx.state.isGrp &&
        _ctx.state.isEmsVer31OrHigher &&
        ['admin', 'dev'].includes(_ctx.roleName ?? '') &&
        ['GEN3', 'FOX_ESS', 'FOX_ESS_H3'].includes(_ctx.genType)
      )
          ? (_openBlock(), _createBlock(_component_Accordion, {
              key: 1,
              title: _ctx.$t('common.web_hmi_password'),
              active: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTable, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('common.engineer_password'),
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.web_page_password_engineer,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.data.web_page_password_engineer) = $event)),
                              name: "web_page_password_engineer",
                              readonly: _ctx.state.editable
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          (!_ctx.state.editable)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                type: "button",
                                class: "btn_t btn_c_3",
                                disabled: !_ctx.state.validClass.web_page_password_engineer || _ctx.isNull(_ctx.state.data.web_page_password_engineer),
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChangeWebHMIPassword('Engineer Page')))
                              }, _toDisplayString(_ctx.$t('common.change')), 9, _hoisted_4))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('common.service_password'),
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.web_page_password_service,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.data.web_page_password_service) = $event)),
                              name: "web_page_password_service",
                              readonly: _ctx.state.editable
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          (!_ctx.state.editable)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                type: "button",
                                class: "btn_t btn_c_3",
                                disabled: !_ctx.state.validClass.web_page_password_service || _ctx.isNull(_ctx.state.data.web_page_password_service),
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onChangeWebHMIPassword('Service Page')))
                              }, _toDisplayString(_ctx.$t('common.change')), 9, _hoisted_7))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Accordion, {
          title: _ctx.$t('device.setting_info'),
          active: true
        }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_SettingTable, {
              "table-title": _ctx.$t('device.grid'),
              "title-width": _ctx.titleWidth,
              class: "color-gray",
              "table-sub-title": _ctx.state.isGrp ? _ctx.$t('message.feed_in_description') : ''
            }, {
              default: _withCtx(() => [
                (_ctx.state.isGrp || !_ctx.state.isShowFeedInWatt)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 0,
                      title: `${_ctx.$t('control.feedin')} (%)`,
                      description: `0 ~ 100 %, 0% : ${_ctx.$t('device.no_feedin')}`,
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.state.editable,
                          "value-key": "pv_feed_in_limit",
                          "target-value": _ctx.state.data.pv_feed_in_limit,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.pv_feed_in_limit,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.data.pv_feed_in_limit) = $event)),
                              name: "pv_feed_in_limit",
                              type: "number",
                              min: "0",
                              max: "100",
                              readonly: !_ctx.state.editable || !_ctx.state.enableEditFeedInLimit
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title", "description"]))
                  : _createCommentVNode("", true),
                (_ctx.state.isGrp || _ctx.state.isShowFeedInWatt)
                  ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                      key: 1,
                      title: `${_ctx.$t('control.feedin')} (W)`,
                      description: `0 ~ 65000 W, 0W : ${_ctx.$t('device.no_feedin')}`,
                      "is-indent": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChangedMark, {
                          "show-mark": _ctx.state.editable,
                          "value-key": "pv_feed_in_limit_w",
                          "target-value": _ctx.state.data.pv_feed_in_limit_w,
                          "changed-value-set": _ctx.state.changedValueSet,
                          onChange: _ctx.onChange
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BasicInput, {
                              modelValue: _ctx.state.data.pv_feed_in_limit_w,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.data.pv_feed_in_limit_w) = $event)),
                              name: "pv_feed_in_limit_w",
                              type: "number",
                              min: "0",
                              max: "65000",
                              readonly: !_ctx.state.editable || !_ctx.state.enableEditFeedInLimit
                            }, null, 8, ["modelValue", "readonly"])
                          ]),
                          _: 1
                        }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["title", "description"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["table-title", "title-width", "table-sub-title"]), [
              [_vShow, !_ctx.isAcCoupled && _ctx.state.data.energy_policy !== 2]
            ]),
            _createVNode(_component_SettingTable, {
              "table-title": _ctx.$t('control.energy_policy'),
              "title-width": _ctx.titleWidth
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.energy_policy'),
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "energy_policy",
                      "target-value": _ctx.state.data.energy_policy,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Selector, {
                          modelValue: _ctx.state.data.energy_policy,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.data.energy_policy) = $event)),
                          name: "energy_policy",
                          "value-type": "number",
                          disabled: !_ctx.state.editable,
                          "before-change": _ctx.checkEnergyPolicy,
                          options: _ctx.selectorOptionsState.energyPolicy
                        }, null, 8, ["modelValue", "disabled", "before-change", "options"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title"])
              ]),
              _: 1
            }, 8, ["table-title", "title-width"]),
            _createVNode(_component_SettingTable, {
              "table-title": _ctx.$t('device.battery'),
              "title-width": _ctx.titleWidth
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTableRow, {
                  title: _ctx.$t('control.energy_backup'),
                  description: 
            `${_ctx.computedVal.valueRange.battery_backup_soc.min} ~ ${_ctx.computedVal.valueRange.battery_backup_soc.max} %, ` +
            _ctx.$t('message.5_per_incre')
          ,
                  "is-indent": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChangedMark, {
                      "show-mark": _ctx.state.editable,
                      "value-key": "battery_backup_soc",
                      "target-value": _ctx.state.data.battery_backup_soc,
                      "changed-value-set": _ctx.state.changedValueSet,
                      onChange: _ctx.onChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BaseSlider, {
                          modelValue: _ctx.state.data.battery_backup_soc,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.data.battery_backup_soc) = $event)),
                          min: 0,
                          max: 100,
                          step: 5,
                          format: { suffix: '%' },
                          disabled: !_ctx.state.editable
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["show-mark", "target-value", "changed-value-set", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title", "description"])
              ]),
              _: 1
            }, 8, ["table-title", "title-width"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createElementVNode("div", {
          class: _normalizeClass(["btn_wrap_m", { editorble: _ctx.state.editable }])
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              type: "button",
              class: "btn_m btn_c_2",
              onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.goToList && _ctx.goToList(...args)))
            }, _toDisplayString(_ctx.$t('message.go_to_list')), 1),
            (!_ctx.hideEdit)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn_m btn_c_3",
                  onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
                }, _toDisplayString(_ctx.$t('common.edit')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", {
              type: "reset",
              class: "btn_m btn_c_2",
              onClick: ($event: any) => (_ctx.onCancel(handleReset))
            }, _toDisplayString(_ctx.$t('common.cancel')), 9, _hoisted_10),
            _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.$t('common.apply')), 1)
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["validation-schema", "onSubmit"]),
    (_ctx.state.confirmPopup.on)
      ? (_openBlock(), _createBlock(_component_SettingConfirmPopup, {
          key: 0,
          title: _ctx.$t('control.basic_sett'),
          data: _ctx.state.confirmPopup.data,
          onConfirm: _ctx.onConfirm,
          onCancel: _cache[13] || (_cache[13] = () => (_ctx.state.confirmPopup.on = false))
        }, null, 8, ["title", "data", "onConfirm"]))
      : _createCommentVNode("", true)
  ], 64))
}