/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited. 
*/
import type { AxiosInstance } from 'axios';
import { apiWrapper, getApiVersionHeader } from '../../util/helper';
import { DeviceService } from './DeviceService';
import type { UserInstallationInfoResponse } from '@hems/user/src/types/device';
import type { CommonResponseWrapper } from 'hems';
import type { SiteId } from 'hems/device';
import { getEnvLocale } from '@hems/util/src/helper/helper';
import type { SettingsResponse } from 'hems/device/deviceinfo';
import { Helper } from '@hems/util';

export default class DeviceServiceUser extends DeviceService {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getUserInstallationInfo(): Promise<UserInstallationInfoResponse> {
    const isUS = getEnvLocale() === 'US';
    const { data } = await this.get<CommonResponseWrapper<UserInstallationInfoResponse>>(
      apiWrapper.managerApi('/devices/profiles/information'),
      undefined,
      {
        headers: getApiVersionHeader(isUS),
      }
    );

    if (Array.isArray(data.result)) {
      if (data.result.length >= 2) {
        return data;
      }

      return {
        ...data,
        result: Helper.isEmptyArray(data.result) ? null : data.result[0],
      };
    }

    return data;
  }

  public async getSiteId(): Promise<SiteId> {
    const { data } = await this.get<CommonResponseWrapper<SiteId>>(apiWrapper.managerApi('/devices/profiles/site-id'));

    return data;
  }

  /** AC System Configuration Monitoring */
  public async getUserACSystemSettingInfo(siteId: SiteId): Promise<SettingsResponse> {
    const { data } = await this.get<CommonResponseWrapper<SettingsResponse>>(
      apiWrapper.managerApi(`/devices/profiles/ac/user/${siteId}/configurations`)
    );

    return data;
  }
}
