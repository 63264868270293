
import { computed, defineComponent, PropType, reactive } from 'vue';
import { Form } from 'vee-validate';
import * as Yup from 'yup';
import { FormMode } from 'hems';

export default defineComponent({
  name: 'GroupInfoForm',
  components: {
    Form,
  },
  props: {
    mode: {
      type: String as PropType<FormMode>,
      default: 'read',
    },
    title: {
      type: String,
      default: 'Group Information',
    },
    schema: {
      type: Object as PropType<Yup.AnySchema>,
    },
  },
  emits: ['next', 'goToList'],
  setup(props, { emit }) {
    const state = reactive({
      mode: computed(() => props.mode),
    });

    function onSave() {
      emit('next');
    }

    function goToList() {
      emit('goToList');
    }

    return {
      state,
      onSave,
      goToList,
    };
  },
});
