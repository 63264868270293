import { AxiosInstance } from 'axios';
import { Service } from '../Service';

export default class MaintenanceService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }
}

export { MaintenanceService };
