import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_SettingTableRow = _resolveComponent("SettingTableRow")!
  const _component_SettingTable = _resolveComponent("SettingTable")!
  const _component_Form = _resolveComponent("Form")!
  const _component_CommonPopup = _resolveComponent("CommonPopup")!

  return (_openBlock(), _createBlock(_component_CommonPopup, {
    title: _ctx.title,
    onConfirm: _ctx.onConfirm
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        ref: "form",
        "validation-schema": _ctx.schema,
        onSubmit: _cache[2] || (_cache[2] = 
        () => {
          return false;
        }
      )
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SettingTable, null, {
            default: _withCtx(() => [
              (_ctx.state.nmiDeviceId)
                ? (_openBlock(), _createBlock(_component_SettingTableRow, {
                    key: 0,
                    title: "NMI"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BasicInput, {
                        modelValue: _ctx.state.nmiDeviceId,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.nmiDeviceId) = $event)),
                        name: "nmiDeviceId",
                        readonly: ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_SettingTableRow, { title: "new NMI" }, {
                default: _withCtx(() => [
                  _createVNode(_component_BasicInput, {
                    modelValue: _ctx.state.newNmiDeviceId,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.newNmiDeviceId) = $event)),
                    name: "newNmiDeviceId"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["validation-schema"])
    ]),
    _: 1
  }, 8, ["title", "onConfirm"]))
}