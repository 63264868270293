
import { defineComponent } from 'vue';
import { Loading, MobilePopup } from '@hems/component';

export default defineComponent({
  name: 'MobileTemplate',
  components: {
    Loading,
    MobilePopup,
  },
});
