import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a87a02a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-download-container" }
const _hoisted_2 = { class: "txt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Image, {
      src: _ctx.imgUri,
      class: "app-icon",
      extension: "png"
    }, null, 8, ["src"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.mobileAppName), 1),
    _createVNode(_component_Image, {
      src: _ctx.imgQrUri,
      extension: "png",
      class: "img-qr"
    }, null, 8, ["src"])
  ]))
}