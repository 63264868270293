import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-348a43d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pop_tit" }
const _hoisted_2 = { class: "search_opts" }
const _hoisted_3 = { class: "opt w100" }
const _hoisted_4 = { class: "join-type-selection" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Selector = _resolveComponent("Selector")!
  const _component_GeneralJoinType = _resolveComponent("GeneralJoinType")!
  const _component_InstallerJoinType = _resolveComponent("InstallerJoinType")!
  const _component_PopupWrapper = _resolveComponent("PopupWrapper")!

  return (_openBlock(), _createBlock(_component_PopupWrapper, {
    class: "join_wrap",
    style: {"opacity":"1","left":"50%","margin-left":"-640px","top":"190px","margin-bottom":"50px","width":"1024px"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('account.join')), 1),
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          (_ctx.isAU)
            ? (_openBlock(), _createBlock(_component_Selector, {
                key: 0,
                modelValue: _ctx.state.selectedLocale,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.selectedLocale) = $event)),
                options: _ctx.auCountryOptions
              }, null, 8, ["modelValue", "options"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_GeneralJoinType, {
            "selected-locale": _ctx.state.selectedLocale,
            onClick: _ctx.joinGeneral
          }, null, 8, ["selected-locale", "onClick"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_InstallerJoinType, {
            "selected-locale": _ctx.state.selectedLocale,
            onClick: _ctx.joinInstaller
          }, null, 8, ["selected-locale", "onClick"])
        ])
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "close_btn_2",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      }, _toDisplayString(_ctx.$t('common.close')), 1)
    ]),
    _: 1
  }))
}