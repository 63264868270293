
import type { PropType } from 'vue';
import { computed, defineComponent, reactive, provide } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import _ from 'lodash';
import moment from 'moment';

import { Calendar, useMessageBox } from '@hems/component';
import { PricingTable } from '@hems/container/src/forms/device/settings/_shared/pricing/component/index';
import { Helper, DateHelper } from '@hems/util';
import { ENV_LOCALE, GEN_TYPE, PROVIDE_INJECT_KEYS } from '@hems/util/src/constant';

import type { PricingSettings, PricingItem } from 'hems/device/settings/pvmodule/gen3';

export default defineComponent({
  name: 'PricingSettingsPvModuleGen3SmartModuleGen2',
  components: {
    PricingTable,
    PricingCalendarRange: Calendar.PricingCalendarRange,
  },
  props: {
    data: {
      type: Object as PropType<Partial<PricingSettings>>,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    confirmMessage: {
      type: String,
      default: 'message.save_confirm',
    },
    isConnection: Boolean,
    productModelNm: String,
    emsVersion: {
      type: String,
      required: true,
    },
  },
  emits: ['save', 'goToList', 'cancel', 'edit'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    let copyData: {
      duration: {
        start: Date | null;
        end: Date | null;
      };
      weekdays?: PricingItem[];
      weekend?: PricingItem[];
    } | null = null;

    const store = useStore();
    const langCd = computed(() => store.state.appCtx.langCd);
    const format = computed(() => {
      langCd;

      return DateHelper.getCalendarDateFormat('TH', langCd.value);
    });
    const locale = Helper.getEnvLocale();
    const state = reactive<{
      editable: boolean;
      minDate: Date;
      duration: {
        start: Date | null;
        end: Date | null;
      };
      weekdays?: PricingItem[];
      weekend?: PricingItem[];
      isPermanent?: boolean;
      isEmsVer25OrHigher: boolean;
    }>({
      editable: props.editable,
      minDate: new Date(),
      duration: {
        start: null,
        end: null,
      },
      weekdays: [],
      weekend: [],
      isPermanent: false,
      isEmsVer25OrHigher:
        locale === ENV_LOCALE.US || Helper.checkValidEmsVersion(GEN_TYPE.GEN3, props.emsVersion, 25) === true,
    });

    const weekdaysLength = computed(() => state.weekdays?.length);
    const weekendLength = computed(() => state.weekend?.length);
    const maxCount = computed(() => (state.isEmsVer25OrHigher ? 30 : 6));
    const minuteOptionUnit = computed(() => (state.isEmsVer25OrHigher ? 1 : 30));

    function init() {
      if (props.data) {
        state.duration.start = props.data.tou_start_date
          ? moment(props.data.tou_start_date, 'YYYYMMDD').toDate()
          : null;
        state.duration.end = props.data.tou_end_date
          ? props.data.tou_end_date.startsWith('9999')
            ? null
            : moment(props.data.tou_end_date, 'YYYYMMDD').toDate()
          : null;

        state.weekdays = props.data.tou_weekday_section;

        state.weekend = props.data.tou_weekend_section;

        state.isPermanent = props.data.tou_end_date?.startsWith('9999');

        createCopyData();
      }
    }

    function createCopyData() {
      const { duration, weekdays, weekend } = state;
      copyData = _.cloneDeep({ duration, weekdays, weekend });
    }

    function getParam(): Partial<PricingSettings> | undefined {
      const param: Record<string, number | string | PricingItem[] | undefined> = {
        tou_start_date: moment(state.duration.start).format('YYYYMMDD'),
        tou_end_date: state.isPermanent
          ? moment(new Date('9999-01-01')).format('YYYYMMDD')
          : moment(state.duration.end).format('YYYYMMDD'),
      };

      param.tou_weekday_count = state.weekdays?.length;
      param.tou_weekday_section = state.weekdays?.map((item: PricingItem) => ({
        time: item.time,
        action: item.action,
        inverterReference: item.inverterReference ? item.inverterReference.toString() : '0',
      }));

      param.tou_weekend_count = state.weekend?.length;
      param.tou_weekend_section = state.weekend?.map((item: PricingItem) => ({
        time: item.time,
        action: item.action,
        inverterReference: item.inverterReference ? item.inverterReference.toString() : '0',
      }));

      return param;
    }

    function validate() {
      if (!state.duration.start || (!state.duration.end && !state.isPermanent)) {
        messageBox.alert(t('message.enter_application_period')).open();

        return false;
      }
      if (!DateHelper.isValidDateRange(state.duration.start, state.duration.end)) {
        messageBox.alert(t('message.start_before_end')).open();

        return false;
      }
      if (_.isEmpty(state.weekdays)) {
        messageBox.alert(t('message.invalid_weekdays')).open();

        return false;
      }
      if (_.isEmpty(state.weekend)) {
        messageBox.alert(t('message.invalid_weekend')).open();

        return false;
      }

      return true;
    }

    async function onSave() {
      if (!validate()) {
        return;
      }

      emit('save', getParam());
      state.editable = false;
    }
    function onEdit() {
      if (!props.isConnection) {
        messageBox.alert(t('message.modify_when_disconnected')).open();

        return;
      }
      createCopyData();
      state.editable = true;
      emit('edit', copyData);
    }
    function onCancel() {
      if (copyData) {
        state.duration = copyData.duration;
        state.weekdays = copyData.weekdays;
        state.weekend = copyData.weekend;
      }

      state.editable = false;
      emit('cancel');
    }
    function goToList() {
      emit('goToList');
    }

    function applyPermanent(isPermanent: boolean) {
      state.isPermanent = isPermanent;
    }

    init();
    const maxInverterRef = Helper.extractMaxInvRefFromProductModel(props.productModelNm);
    provide(PROVIDE_INJECT_KEYS.MAX_INVERTER_REF, maxInverterRef);

    return {
      state,
      maxCount,
      weekdaysLength,
      weekendLength,
      format,
      langCd,
      minuteOptionUnit,
      onSave,
      onEdit,
      onCancel,
      goToList,
      applyPermanent,
    };
  },
});
