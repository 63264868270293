import * as yup from 'yup';
import { isNull } from '../helper/helper';
import { DeviceServiceAdmin, DeviceServiceInstaller, JoinService, RemoteUpdateServiceAdmin } from '@hems/service';
import { SiteId } from 'hems/device';
import { Constant, Helper } from '..';
import axios from 'axios';

const API_BASE_URL = process.env.VUE_APP_API_URL;

export function initYup(): void {
  yup.setLocale({
    mixed: {
      default: () => ({ key: 'message.invalid_value' }),
      required: ({ label }: { label?: string }) => ({ key: 'message.field_required', values: { label } }),
    },
    string: {
      email: ({ label }: { label?: string }) => ({ key: 'message.email_format', values: { label } }),
      min: ({ min, label }: { min: number; label?: string }) => ({
        key: 'message.longer_than_min',
        values: { min, label },
      }),
      max: ({ max, label }: { max: number; label?: string }) => ({
        key: 'message.shorter_than_max',
        values: { max, label },
      }),
    },
    number: {
      min: ({ min, label }: { min: number; label?: string }) => ({
        key: 'message.greater_min',
        values: { min, label },
      }),
      max: ({ max, label }: { max: number; label?: string }) => ({
        key: 'message.greater_max',
        values: { max, label },
      }),
      integer: ({ label }: { label?: string }) => ({ key: 'message.none_integer', values: { label } }),
    },
  });

  yup.addMethod<yup.NumberSchema>(yup.number, 'isValidStep', function (step: number) {
    return this.test('isValidStep', function (value?: number) {
      const { createError } = this;
      return value === undefined
        ? false
        : value % step === 0
        ? true
        : createError({
            message: {
              key: 'message.valid_number_step',
              values: {
                step: step,
              },
            },
          });
    });
  });

  yup.addMethod<yup.NumberSchema>(yup.number, 'unitNumber', function (unitNumber: number, min = 0) {
    const getDecimalPoint = (num: number) => num?.toString().split('.')[1]?.length ?? 0;
    const exNumber = (unitNumber: number, min: number) =>
      Array.from(Array(3), (_, i) =>
        ((i + 1) * unitNumber + (min ? min : 0)).toFixed(getDecimalPoint(unitNumber))
      ).join(', ');

    return this.test('unitNumber', function (value?: number) {
      const { createError } = this;
      return value === undefined
        ? false
        : getDecimalPoint(value) <= getDecimalPoint(unitNumber)
        ? true
        : createError({
            message: {
              key: 'message.insert_units',
              values: {
                unit_number: unitNumber,
                ex: exNumber(unitNumber, min),
              },
            },
          });
    });
  });

  yup.addMethod<yup.StringSchema>(yup.string, 'deviceId', function (prevValue: CustomYup.PrevValue, callback) {
    return this.test('deviceId', async function (value?: string) {
      const { createError } = this;

      if (isNull(value)) {
        return false;
      }
      const classification = value.substr(0, 2).toUpperCase();
      const foxessPrefix = value.substr(0, 4).toUpperCase();
      if (classification === 'AR' && value.length !== 21) {
        if (callback) callback(false);
        return false;
      }
      if (classification === 'HS' && value.length !== 18) {
        if (callback) callback(false);
        return false;
      }
      if (!['AR', 'HS'].includes(classification) && value.length !== 18 && value.length !== 15) {
        if (callback) callback(false);
        return false;
      }

      if (value === prevValue.value.deviceId) {
        if (callback) {
          callback(true, {
            product_model_nm: prevValue.value.productModelNm,
            device_type: prevValue.value.deviceType,
          });
        }
        return true;
      }

      try {
        const deviceService = new DeviceServiceAdmin(window.axiosInstance.axios);
        const result = await deviceService.checkDeviceId(value);
        if (callback) callback(result.result, result);
        if (result.result) {
          prevValue.status = result.result;
          prevValue.value = {
            deviceId: value,
            productModelNm: result.product_model_nm,
            deviceType: (result as any).device_type,
          };
          return true;
        } else {
          return createError({
            message:
              result.fail_type === '0'
                ? {
                    key: 'message.invalid_serial',
                  }
                : result.fail_type === '1'
                ? { key: 'message.duplicate_serial' }
                : result.fail_type === '2'
                ? { key: 'message.mobile_only_serial' }
                : {},
          });
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    });
  });

  yup.addMethod<yup.StringSchema>(yup.string, 'deviceDemoId', function (callback) {
    return this.test('deviceDemoId', async function (value?: string) {
      const { createError } = this;

      if (isNull(value)) {
        return false;
      }

      try {
        const deviceService = new DeviceServiceInstaller(window.axiosInstance.axios);
        const result = await deviceService.checkValidAcDevice(value);
        if (callback) callback(result.result, result);
        if (result.result) {
          return true;
        } else {
          return createError({
            message:
              result.fail_type === '0'
                ? {
                    key: 'message.invalid_serial',
                  }
                : result.fail_type === '1'
                ? { key: 'message.duplicate_serial' }
                : {},
          });
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    });
  });

  yup.addMethod<yup.StringSchema>(
    yup.string,
    'deviceIdOfSmartModule',
    function (prevValue: CustomYup.PrevValue, callback) {
      return this.test('deviceId', async function (value?: string) {
        const { createError } = this;

        const error = createError({
          message: {
            key: 'message.duplicate_invalid_serial',
          },
        });
        if (isNull(value)) {
          return false;
        }

        const regex = /^\d{1}\.\d{1}\.\d{6}$/;
        if (!regex.test(value)) {
          return false;
        }

        if (value === prevValue.value.deviceId) {
          if (callback) {
            callback(true, {
              product_model_nm: prevValue.value.productModelNm,
              device_type: prevValue.value.deviceType,
            });
          }
          return true;
        }

        try {
          const deviceService = new DeviceServiceAdmin(window.axiosInstance.axios);
          const result = await deviceService.checkDeviceId(value);
          if (callback) callback(result.result, result);
          if (result.result) {
            prevValue.status = result.result;
            prevValue.value = {
              deviceId: value,
              productModelNm: result.product_model_nm,
              deviceType: (result as any).device_type,
            };
            return true;
          } else {
            return error;
          }
        } catch (e) {
          console.error(e);
          return false;
        }
      });
    }
  );

  yup.addMethod<yup.StringSchema>(yup.string, 'groupName', function (options: CustomYup.GroupNameOptions) {
    return this.test('groupName', async function (value?: string) {
      const { createError } = this;

      if (isNull(value)) {
        return createError({
          message: {
            key: 'message.field_required',
          },
        });
      }
      try {
        const result = await options.validator(value);
        if (!result.dup_chk) {
          return createError({
            message: {
              key: 'message.duplicate_invalid_grp',
            },
          });
        }
        return true;
      } catch (e) {
        return false;
      }
    });
  });

  yup.addMethod<yup.BooleanSchema>(yup.boolean, 'stable', function (options: CustomYup.StableOptions) {
    return this.test('stable', async function (value?: boolean) {
      const { createError } = this;

      try {
        // stable일 경우에만 validation 체크
        if (!value) return true;

        // Product Model Name이 없으면 Invalid
        const productModelNm = this.parent[options.productModelNmKey || 'product_model_nm'];
        if (!productModelNm) return false;

        // 해당 Product Model Name로 Remote Control한 것이 있으면 Invalid (Product Model Name별로 하나의 stable만 가져야 함.)
        const remoteUpdateService = new RemoteUpdateServiceAdmin(window.axiosInstance.axios);
        const stableGroup = await remoteUpdateService.getStableGroupByProductModelNm(productModelNm);
       
        if (stableGroup?.grp_id) {
          return createError({
            message: {
              key: 'message.exist_stable_grp',
              values: {
                product_model: productModelNm,
                defaultValue: `There is a Stable Group created with '${productModelNm}'.`,
              },
            },
          });
        }

        return true;
      } catch (e) {
        return createError({
          message: {
            key: 'message.error_check_stable',
            values: {
              defaultValue: 'An error occurred while checking Stable.',
            },
          },
        });
      }
    });
  });

  yup.addMethod<yup.NumberSchema>(yup.number, 'isValidSiteId', function (prevValue: CustomYup.PrevValue) {
    if (!prevValue)
      return this.test(function () {
        return false;
      });

    return this.test('isValidSiteId', async function (siteId?: SiteId) {
      const joinService = new JoinService(window.axiosInstance.axios);
      const { createError } = this;
      if (Helper.isNull(siteId)) {
        return createError({
          message: { key: 'message.field_required' },
        });
      }
      if (isNaN(Number(siteId)) || siteId.toString().length !== 9) {
        return createError({
          message: { key: 'message.invalid' },
        });
      }

      if (prevValue.value === siteId) return prevValue.status;

      try {
        const { result } = await joinService.checkSiteId(siteId);
        prevValue.status = result.is_valid;
        prevValue.value = siteId;
        return result.is_valid;
      } catch (e) {
        return false;
      }
    });
  });

  yup.addMethod<yup.StringSchema>(yup.string, 'isValidUserId', function (prevValue: CustomYup.PrevValue) {
    return this.test('isValidUserId', async function (userId?: string) {
      const { createError } = this;

      if (Helper.isNull(userId)) {
        return createError({
          message: { key: 'message.field_required' },
        });
      }

      if (userId.length < 6 || userId.length > 50) {
        return createError({
          message: { key: 'message.enter_id_characters', values: { min: 6, max: 50 } },
        });
      }

      if (!Constant.idPolicy.test(userId)) {
        return createError({
          message: { key: 'message.allow_special_character', values: { characters: '!@#$*()-_+,.~<>[]{}=|' } },
        });
      }

      if (prevValue.value === userId) return prevValue.status;

      try {
        const axiosInstance = axios.create({
          baseURL: API_BASE_URL,
        });
        const joinService = new JoinService(axiosInstance);
        const { result } = await joinService.checkUserIdEmail(userId);
        prevValue.status = result.is_valid;
        prevValue.value = userId;
        return result.is_valid;
      } catch (e) {
        return false;
      }
    });
  });

  yup.addMethod<yup.StringSchema>(yup.string, 'isValidEmail', function (prevValue: CustomYup.PrevValue) {
    return this.test('isValidEmail', async function (email?: string) {
      const { createError } = this;

      if (Helper.isNull(email)) {
        return createError({
          message: { key: 'message.field_required' },
        });
      }

      if (!yup.string().email().isValidSync(email)) {
        return createError({
          message: { key: 'message.email_format' },
        });
      }

      if (prevValue.value === email) return prevValue.status;

      try {
        const axiosInstance = axios.create({
          baseURL: API_BASE_URL,
        });
        const joinService = new JoinService(axiosInstance);
        const { result } = await joinService.checkUserIdEmail(email);
        prevValue.status = result.is_valid;
        prevValue.value = email;
        return result.is_valid;
      } catch (e) {
        return false;
      }
    });
  });

  const prevValues: Record<string, CustomYup.PrevValue> = {};
  const initValue = {
    status: false,
    value: undefined,
  };
  function clearPrevValues(name: string) {
    if (prevValues[name]) {
      delete prevValues[name];
    }
  }
  Object.assign(yup, { clearPrevValues });
  yup.addMethod<yup.StringSchema>(
    yup.string,
    'isValidByFn',
    function (key: string, checkerFn: yup.CheckerFn<string>, force: boolean | (() => boolean) = false) {
      return this.test('isValidByFn', async function (value?: any) {
        const { createError } = this;
        try {
          return await isValidByFn<string>(key, value, checkerFn, createError, force);
        } catch (error) {
          return false;
        }
      });
    }
  );

  yup.addMethod<yup.NumberSchema>(
    yup.number,
    'isValidByFn',
    function (key: string, checkerFn: yup.CheckerFn<number>, force: boolean | (() => boolean) = false) {
      return this.test('isValidByFn', { key: 'message.invalid' }, async function (value?: any) {
        const { createError } = this;

        return await isValidByFn<number>(key, Number(value), checkerFn, createError, force);
      });
    }
  );

  async function isValidByFn<T>(
    key: string,
    value: T,
    checkerFn: yup.CheckerFn<T>,
    createError: yup.TestContext['createError'],
    force: boolean | (() => boolean) = false
  ) {
    const isForceCallFn = typeof force === 'boolean' ? force : force();
    if (!prevValues[key]) {
      prevValues[key] = { ...initValue };
    }
    if (value === prevValues[key].value && !isForceCallFn) return prevValues[key].status;
    prevValues[key].value = value;

    try {
      const { isValid, errMsg } = await checkerFn(value, prevValues[key]);
      prevValues[key].status = isValid ?? false;
      if (errMsg) return createError({ message: errMsg });
      return prevValues[key].status;
    } catch (error) {
      prevValues[key].status = false;
      return prevValues[key].status;
    }
  }

  yup.addMethod<yup.StringSchema>(
    yup.string,
    'isValidLatLon',
    function (prevValue: CustomYup.PrevValue, options: CustomYup.LatLonOptions, currValue?: CustomYup.PrevValue) {
      return this.test('isValidLatLon', { key: 'message.duplicate_location' }, async function (value?: string) {
        const { createError } = this;

        if (currValue) {
          const curr = currValue.value as { lat: number; lon: number };
          const lat = this.parent[options.latKey || 'lat'];
          const lon = this.parent[options.lonKey || 'lon'];
          if (lat === curr.lat && lon === curr.lon) return currValue.status;
        }

        try {
          if (value === null || value === undefined)
            return createError({
              message: { key: 'message.field_required' },
            });
          const lat = this.parent[options.latKey || 'lat'];
          const lon = this.parent[options.lonKey || 'lon'];
          if (lat === null || lat === undefined || lon === null || lon === undefined) {
            return createError({
              message: { key: 'message.field_required' },
            });
          }

          if (prevValue.value) {
            const prev = prevValue.value as { lat: number; lon: number };
            if (prev.lat === lat && prev.lon === lon) return prevValue.status;
          }

          const deviceService = new DeviceServiceAdmin(window.axiosInstance.axios);
          const result = await deviceService.checkDuplicatedLocation({ latitude: lat, longitude: lon });
          prevValue.status = !result;
          prevValue.value = { lat, lon };
          return !result;
        } catch (e) {
          return createError({
            message: {
              key: 'message.invalid',
            },
          });
        }
      });
    }
  );

  yup.addMethod<yup.StringSchema>(
    yup.string,
    'isValidPassword',
    function (options: CustomYup.MinMaxLengthOptions, callback, t?: (key: string | number, variables?: any) => string) {
      return this.test('isValidPassword', { key: 'message.invalid' }, function (value?: string | null) {
        try {
          const { createError } = this;

          if (Helper.isNull(value)) {
            callback(true);
            return true;
          }
          // 길이
          let regexpLength = new RegExp(`^.{${options.min},${options.max}}$`);
          if (options.min === options.max) {
            regexpLength = new RegExp(`^.{${options.min}}$`);
          }
          const regexpNumberLetter = /^(?=.*[a-zA-Z])(?=.*\d).+$/; // 숫자 1개 이상 및 영문자
          const regexpSpecialCharacter = /.*[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*/; // 특수문자 1개 이상

          if (!regexpLength.test(value)) {
            if (options.min !== options.max) {
              callback(false);
              return createError({
                message: t
                  ? t('message.min_max_characters', { min: options.min, max: options.max })
                  : `It should be between ${options.min} and ${options.max} characters.`,
              });
            }
            callback(false);
            return createError({
              message: t
                ? t('message.num_characters', { num: options.min })
                : `It should be ${options.min} characters.`,
            });
          }
          if (!regexpNumberLetter.test(value)) {
            callback(false);
            return createError({ message: { key: 'message.should_number_letter' } });
          }
          if (!regexpSpecialCharacter.test(value)) {
            callback(false);
            return createError({ message: { key: 'message.should_one_special' } });
          }
          callback(true);
          return true;
        } catch (e) {
          callback(false);
          return false;
        }
      });
    }
  );
}
