import { AxiosInstance } from 'axios';
import { Service } from '../Service';
import { SupportInfo, EventcodeInfo } from 'hems/support';
import { apiWrapper } from '../../util/helper';
import { CommonResponseWrapper } from 'hems';

export default class SupportService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getGuideList(params: any): Promise<SupportInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<SupportInfo[]>>(
      apiWrapper.managerApi(`/managements/mobiles/pro-apps/support-guides`),
      params
    );
    return data;
  }

  public async addGuide(params: any): Promise<any> {
    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/portals/supports/guides`),
      params
    );
    return data;
  }

  public async updateGuide(supportId: number, params: any): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/portals/supports/guides/${supportId}`),
      params
    );
    return data;
  }
  public async deleteGuide(supportId: number): Promise<any> {
    const { data } = await this.delete<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/portals/supports/guides/${supportId}`)
    );
    return data;
  }

  public async getEventcodeList(params: any): Promise<EventcodeInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<EventcodeInfo[]>>(
      apiWrapper.managerApi(`/managements/mobiles/pro-apps/supports/event-codes`),
      params
    );
    return data;
  }

  public async addEventcode(params: any): Promise<any> {
    const { data } = await this.post<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/portals/supports/event-codes`),
      params
    );
    return data;
  }

  public async updateEventcode(alarmCd: string, params: any): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/portals/supports/event-codes/${alarmCd}`),
      params
    );
    return data;
  }

  public async deleteEventcode(alarmCd: string): Promise<any> {
    const { data } = await this.delete<CommonResponseWrapper<any>>(
      apiWrapper.managerApi(`/managements/portals/supports/event-codes/${alarmCd}`)
    );
    return data;
  }
}

export { SupportService };
