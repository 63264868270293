
/* eslint-disable no-undef */
import { useField } from 'vee-validate';
import { ErrorMessageLabel, GoogleMapApi, useMessageBox } from '@hems/component';
import { computed, defineComponent, onMounted, PropType, reactive, Ref, ref, watch } from 'vue';
import { Helper } from '@hems/util';
import { LangCd } from 'hems';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'GoogleMapAutoComplete',
  components: {
    ErrorMessageLabel,
  },
  props: {
    name: String,
    label: String,
    readonly: [String, Boolean],
    modelValue: String,
    mapObj: {
      type: Object as PropType<google.maps.Map>,
      required: true,
    },
    type: String,
    apiKey: {
      type: String,
      required: true,
    },
    language: {
      type: String as PropType<LangCd>,
      default: 'en',
    },
  },
  emits: ['update:modelValue', 'changePosition', 'changeCustomAddr'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    const googleMapApi = new GoogleMapApi({ apiKey: props.apiKey });
    const mapInput: Ref<HTMLInputElement | null> = ref(null);
    const { value: inputValue, errorMessage } = useField(props.name || Helper.getUUID(), undefined, {
      initialValue: props.modelValue,
    });

    const state = reactive({
      isBind: false,
      status: computed(() => (props.modelValue ? 'ok' : 'error') as 'ok' | 'error'),
      errMsg: '',
      isReadonly: computed(() => (props.readonly === '' || props.readonly ? true : false)),
      autocomplete: null as google.maps.places.Autocomplete | null,
      language: computed(() => props.language),
    });

    watch(
      () => state.isReadonly,
      () => {
        if (state.isReadonly) return;
        bindGoogleMap();
      }
    );

    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue !== inputValue.value) inputValue.value = props.modelValue || '';
      }
    );

    function bindGoogleMap() {
      if (state.isBind) return;

      // const { google } = window;
      if (state.isBind) return;
      if (!google) return;
      if (!mapInput.value) return;

      const options: google.maps.places.AutocompleteOptions = {
        fields: ['formatted_address', 'geometry'],
        bounds: props.mapObj.getBounds(),
        strictBounds: false,
        types: ['establishment', 'geocode'],
      };

      state.autocomplete = new google.maps.places.Autocomplete(mapInput.value, options);
      state.autocomplete.addListener('place_changed', async () => {
        state.isBind = true;
        changePlace();
      });
      google.maps.event.addDomListener(mapInput.value, 'keydown', function (event: KeyboardEvent) {
        if (event.code === 'Enter') {
          event.preventDefault();
        }
      });
    }

    async function changePlace() {
      if (!state.autocomplete) return;
      state.autocomplete.setValues({});

      let place = state.autocomplete.getPlace();

      if (!place) {
        state.status = 'error';
        state.errMsg = t('message.no_detail');
        return;
      }

      if (!place.geometry || !place.geometry.location) {
        const name = place.name || '';
        try {
          place = await getPlacePredictions(name);
        } catch (e) {
          state.status = 'error';
          state.errMsg = t('message.no_detail_input', { name: name || '' });
          return;
        }
      }

      if (!place.geometry) {
        state.status = 'error';
        state.errMsg = t('message.no_detail');
        return;
      }

      if (place.geometry.location) {
        props.mapObj.setCenter(place.geometry.location);
        props.mapObj.setZoom(15);

        setLocation(place.geometry.location.lat(), place.geometry.location.lng());
        inputValue.value = place.formatted_address || '';
        state.status = 'ok';
        state.errMsg = '';
      } else {
        state.status = 'error';
        state.errMsg = t('message.no_detail_input', { name: place.name || '' });
        return;
      }
    }

    function onBlur() {
      emit('changeCustomAddr', inputValue);
    }

    async function setLocation(lat: number, lng: number) {
      const locationInfo = await googleMapApi.getLocatioinInfo(lat, lng, state ? state.language : props.language);
      const statePostalInfo = await googleMapApi.getStatePostalInfo(locationInfo.address);
      const timezoneInfo = await googleMapApi.getTimezone(lat, lng);
      emit('changePosition', locationInfo, timezoneInfo, statePostalInfo, inputValue);
    }

    onMounted(() => {
      if (props.modelValue === null) {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('chrome') === -1 && userAgent.indexOf('safari') !== -1) {
          messageBox
            .alert([t('message.system_setting'), t('message.safari_current_location'), t('message.specify_location')])
            .open();
        }
      }
      if (!state.isReadonly) {
        bindGoogleMap();
      }
    });

    function getPlacePredictions(address: string): Promise<google.maps.GeocoderResult> {
      return new Promise<google.maps.GeocoderResult>((resolve, reject) => {
        const service = new google.maps.places.AutocompleteService();
        const geocoder = new google.maps.Geocoder();
        const request: google.maps.places.AutocompletionRequest = {
          input: address,
          types: ['geocode'],
        };
        service.getPlacePredictions(request, (result) => {
          if (!result) return reject();
          geocoder.geocode({ placeId: result[0].place_id }, (result) => {
            if (!result) return reject();
            props.mapObj.setCenter(result[0].geometry.location);
            resolve(result[0]);
          });
        });
      });
    }

    return {
      state,
      mapInput,
      inputValue,
      errorMessage,
      onBlur,
    };
  },
});
