import type { LangCd } from 'hems';

import type { ValueType } from 'hems/common/utils';

export const languages = [
  { text: 'English', value: 'en' },
  { text: 'Deutsch', value: 'de' },
  { text: 'Français', value: 'fr' },
  // { text: 'Italiano', value: 'it' }, 이탈리아어 hide처리
  { text: '한국어', value: 'ko' },
  { text: 'Português', value: 'pt' },
  { text: 'Español', value: 'mx' },
] as { text: string; value: LangCd }[];

export const LANGUAGE = {
  EN: 'en',
  DE: 'de',
  FR: 'fr',
  KO: 'ko',
  PT: 'pt',
  MX: 'mx',
  IT: 'it',
} as const;

export type LanguageValue = ValueType<typeof LANGUAGE>;

export const DEFAULT_LANGUAGE = LANGUAGE.EN;
