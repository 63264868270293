
import { useModelWrapper } from '@hems/util';
import { defineComponent, reactive, computed, PropType, watch } from 'vue';
import BasicInput from './BasicInput.vue';
import { Helper } from '@hems/util';
import { useField } from 'vee-validate';
import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';

export default defineComponent({
  name: 'AutoResizeInput',
  components: {
    BasicInput,
    ErrorMessageLabel,
  },
  inheritAttrs: false,
  props: {
    label: String,
    placeholder: String,
    modelValue: [String, Number],
    readonly: Boolean,
    name: String,
    as: Function,
    type: String,
    validClass: {
      type: String as PropType<'ok' | 'error'>,
      default: '',
    },
    className: {
      type: String as PropType<'fm_ipt' | 'fm_ipt_2' | 'selecter_3'>,
      default: 'fm_ipt',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const inputName = props.name ?? Helper.getUUID();
    const { value: inputValue, errorMessage } = useField<string | number | null>(inputName, undefined, {
      initialValue: props.modelValue,
      type: props.type,
    });

    const state = reactive({
      value: useModelWrapper(props, emit),
      editable: computed(() =>
        !props.readonly === undefined ? false : ['', true, 'true'].includes(!props.readonly) ? true : false
      ),
    });

    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue !== inputValue.value) inputValue.value = props.modelValue ?? null;
      }
    );

    return {
      state,
      errorMessage,
    };
  },
});
