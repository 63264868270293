
import type { PropType } from 'vue';
import { defineComponent, reactive, ref, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';

import _ from 'lodash';
import { Form } from 'vee-validate';
import * as yup from 'yup';

import {
  Accordion,
  BasicInput,
  ToggleInput,
  Selector,
  SettingTable,
  SettingTableRow,
  RadioGroup,
  useMessageBox,
  ChangedMark,
} from '@hems/component';
import {
  getAdvancedSettingACCBConfig,
  getAdvancedSettingACESConfig,
  ACSystemGridCodePair,
} from '@hems/container/src/forms/device/settings/_shared/ACSystemConfig';
import {
  getACSystemSchema,
  installSettingACMIConfig,
  installSettingACESConfig,
  getUpdateConfigByServer,
  getRangeDescription,
  getFormattedData,
  getSettingValue,
} from '@hems/container/src/forms/device/settings/_shared/advanced/advancedSettingsUtils';
import SettingConfirmPopup from '@hems/container/src/forms/device/settings/_shared/SettingConfirmPopup.vue';
import { DeviceServiceInstaller, CommonService } from '@hems/service';
import { Helper, SettingHelper, useACDeviceType } from '@hems/util';
import { AC_DEVICE_TYPE } from '@hems/util/src/constant';
import { CSS_COLUMN } from '@hems/util/src/constant';
import { AxiosErrorException } from '@hems/util/src/exception/exception';
import { getBasicSettingsColumns, getEngineerSettingsColumns } from '@hems/util/src/helper/settingHelper';

import type { Role, SelectorOption } from 'hems';

import type { SettingConfigGroup } from 'hems/common/settingConfig';
import type {
  GridCodeDataACSys,
  GridCodeInfoResponseACSys,
  GridCodeGroupInfoACSys,
  GridCodeInfoACSys,
} from 'hems/device';
import type { SettingGroup } from 'hems/device/settings';
import type { ACSys } from 'hems/device/settings/smartmodule';
import type { AdvancedSettings, AdvancedSettingsKey } from 'hems/device/settings/smartmodule/acsys';
import type { AdvSettings } from 'hems/device/settings/smartmodule/acsys';

type BasicSettings = ACSys.BasicSettings;

const initValue: Partial<AdvancedSettings> = {
  active_power_flag: 0,
  active_power_voltage_flag: 0,
  reactive_power_q_p_flag: 0,
  reactive_power_q_set_point_flag: 0,
  reactive_power_cospi_set_point_flag: 0,
  reactive_power_q_u_fixed_vref_select: 0,
  micro_inverter_ena_rmp: 0,
};
export default defineComponent({
  name: 'AdvancedSettingsACSystem',
  components: {
    Selector,
    Accordion,
    BasicInput,
    ToggleInput,
    Form,
    SettingTable,
    SettingTableRow,
    RadioGroup,
    ChangedMark,
    SettingConfirmPopup,
  },
  props: {
    cntryCd: {
      type: String,
      required: true,
    },
    roleNm: {
      type: String as PropType<Role>,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    showEditableColumnsOnly: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object as PropType<Partial<AdvancedSettings[]>>,
      required: true,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    isConnection: Boolean,
    basicSettingInfo: {
      type: Object as PropType<Partial<BasicSettings>>,
    },
  },
  emits: ['goToList', 'save', 'cancel', 'edit'],
  async setup(props, { emit }) {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    const commonService = new CommonService(window.axiosInstance.axios);
    const deviceService = new DeviceServiceInstaller(window.axiosInstance.axios);
    const { ACDeviceType } = useACDeviceType();
    const isCase1 = ACDeviceType.value === AC_DEVICE_TYPE.AC_COMBINER;
    const isCase3 = ACDeviceType.value === AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS_HUB;
    const isCase6 = ACDeviceType.value === AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS;
    const mergedSchema = ref<yup.AnySchema>(yup.object().shape({}));

    // FIXME 추후 as 개선 필요
    let accbCopyData = ref({} as AdvancedSettings);
    let acesCopyData = ref({} as AdvancedSettings);

    // 서버로 부터 가져온 AC System의 모든 Grid 정보
    let allGridCodeInfoByServer = ref<GridCodeGroupInfoACSys>({} as GridCodeGroupInfoACSys);
    let gridOptionData: SelectorOption[] = [];

    const isInit = ref(false);
    const expandAll = ref(true);
    const isEditable = ref(props.editable);

    const state = reactive<{
      editableCodes: string[];
      excludedColumns: string[];
      data: Partial<AdvancedSettings[]>;
      confirmPopup: { on: boolean; data: SettingGroup[] };
      countryCode: string;
    }>({
      editableCodes: [],
      excludedColumns: [],
      data: _.cloneDeep(props.data),
      confirmPopup: { on: false, data: [] },
      countryCode: props.cntryCd,
    });

    const accb = reactive<{
      schema: yup.AnySchema;
      config: SettingConfigGroup<AdvancedSettingsKey>[];
      allGridCodeInfo: GridCodeDataACSys;
      editableColumns: Record<number, string[]>;
      data: AdvancedSettings;
    }>({
      schema: yup.object().shape({}),
      config: getAdvancedSettingACCBConfig(t),
      allGridCodeInfo: { default_values: [], column_range: [] },
      editableColumns: {},

      // FIXME 추후 as 개선 필요
      data: {} as AdvancedSettings,
    });

    const aces = reactive<{
      schema: yup.AnySchema;
      config: SettingConfigGroup<AdvancedSettingsKey>[];
      allGridCodeInfo: GridCodeDataACSys;
      editableColumns: Record<number, string[]>;
      data: AdvancedSettings;
    }>({
      schema: yup.object().shape({}),
      config: getAdvancedSettingACESConfig(t),
      allGridCodeInfo: { default_values: [], column_range: [] },
      editableColumns: {},

      // FIXME 추후 as 개선 필요
      data: {} as AdvancedSettings,
    });

    let selectorGridCodeValue = ref(0);

    const selectorOptionsState = reactive<{
      energyPolicy: SelectorOption[];
      extctlGwConnection: SelectorOption[];
      gridCodeOptions: SelectorOption[];
      toggleOptions: SelectorOption[];
    }>({
      energyPolicy: [],
      extctlGwConnection: [],
      gridCodeOptions: [],
      toggleOptions: [],
    });

    watch(
      () => props.data,
      () => {
        getCurrentGridCode();
      }
    );

    function init() {
      if (props.editable) {
        onEdit();
      }
      isInit.value = true;
    }

    function refreshForm(updateACCBData?: AdvancedSettings, updateACESData?: AdvancedSettings) {
      if (updateACCBData) {
        accb.data = updateACCBData;
      }

      if (updateACESData) {
        aces.data = updateACESData;
      }

      if (isCase1) {
        setACCBConfigSchema();
        mergedSchema.value = accb.schema.required();
      } else if (isCase3 || isCase6) {
        setACCBConfigSchema();
        setACESConfigSchema();
        mergedSchema.value = accb.schema.required().concat(aces.schema);
      } else {
        setACESConfigSchema();
        mergedSchema.value = aces.schema.required();
      }

      rerender();
    }

    const rerender = () => {
      isInit.value = false;
      nextTick(() => {
        isInit.value = true;
      });
    };

    // Grid code 정보 서버에서 가져와서
    const fetchGridData = async () => {
      try {
        // API 호출
        const response: GridCodeInfoResponseACSys = await deviceService.getGridCodeACSystem({
          productModelNm: 'AACCB',
          cntryCd: 'US',
        });

        // ACCB 는 default grid 정보와 1547 grid 정보가 나뉘어있어서 아래와 같이 합치는 로직이 필요
        allGridCodeInfoByServer.value.acmi = {
          column_range: [...response.acmi[1547].column_range, ...response.acmi.default.column_range],
          default_values: [...response.acmi[1547].default_values, ...response.acmi.default.default_values],
        };

        // ACES Grid Code 정보 추가
        allGridCodeInfoByServer.value.aces = response.aces;

        loadGridCodeInfo();
      } catch (e: unknown) {
        if (e instanceof AxiosErrorException) {
          console.error(e);
        }
      }
    };

    // Grid Code 정보 할당
    function loadGridCodeInfo() {
      accb.allGridCodeInfo = allGridCodeInfoByServer.value.acmi;
      aces.allGridCodeInfo = allGridCodeInfoByServer.value.aces;

      // 5개의 Grid code의 default_values 의 column_names를 가져온 것이 실제로 수정 가능한 필드
      allGridCodeInfoByServer.value.acmi.default_values.forEach((item) => {
        const columnNames = item.column_names?.split(',').map((name: string) => name.trim().toLowerCase());
        accb.editableColumns[item.grid_code] = [...(columnNames ?? []), ...SettingHelper.getInstallSettingColumns()];
      });

      allGridCodeInfoByServer.value.aces.default_values.forEach((item) => {
        const columnNames = item.column_names?.split(',').map((name: string) => name.trim().toLowerCase());
        aces.editableColumns[item.grid_code] = [...(columnNames ?? []), ...SettingHelper.getInstallSettingColumns()];
      });

      if (isCase1) {
        gridOptionData = allGridCodeInfoByServer.value.acmi.default_values.map((item) => ({
          text: item.profile_name,
          value: item.grid_code,
        }));
      } else {
        gridOptionData = allGridCodeInfoByServer.value.aces.default_values.map((item) => ({
          text: item.profile_name,
          value: item.grid_code,
        }));
      }

      selectorOptionsState.gridCodeOptions = Helper.addSelectorOptionAtFirst(gridOptionData, {
        text: t('common.select'),
        value: null,
      });
    }

    function getCurrentGridCode() {
      // multi aces 여도, 다 똑같은 setting 값이라서 여러번 돌아도 괜찮음
      if (isCase1) {
        setACCBCurrentGridCodeInfo();
      } else if (isCase3 || isCase6) {
        setACCBCurrentGridCodeInfo();
        setACESCurrentGridCodeInfo();
      } else {
        setACESCurrentGridCodeInfo();
      }
    }

    function getInstallSettings(): { [key: string]: SettingConfigGroup<AdvancedSettingsKey>[] } {
      let excludedGroup: string[] = [];

      // 변경된 grid code가 아니라면 굳이 basic, engineering setting을 내릴 필요 없음
      if (isCase1 || isCase3 || isCase6) {
        excludedGroup =
          accbCopyData.value.grid_code === selectorGridCodeValue.value ? ['Basic Setting', 'Engineer Setting'] : [];
      } else {
        excludedGroup =
          acesCopyData.value.grid_code === selectorGridCodeValue.value ? ['Basic Setting', 'Engineer Setting'] : [];
      }

      const accbBasicSettings: SettingConfigGroup<AdvancedSettingsKey>[] = installSettingACMIConfig(
        t,
        selectorOptionsState,
        excludedGroup
      );
      const acesBasicSettings: SettingConfigGroup<AdvancedSettingsKey>[] = installSettingACESConfig(
        t,
        selectorOptionsState,
        excludedGroup
      );

      const settingGroup: {
        [key in 'accbBasicSettings' | 'acesBasicSettings']: SettingConfigGroup<AdvancedSettingsKey>[];
      } = { accbBasicSettings: [], acesBasicSettings: [] };

      settingGroup['accbBasicSettings'] = accbBasicSettings;
      settingGroup['acesBasicSettings'] = acesBasicSettings;

      return settingGroup;
    }

    function goToList() {
      emit('goToList');
    }

    function setDataByChangedGridCode(gridCodeDefaultValues: GridCodeInfoACSys[], gridCode: number) {
      const data = gridCodeDefaultValues.find((item) => item.grid_code === gridCode);
      if (!data) {
        return;
      }

      return { ..._.omit(data, ['profile_name', 'column_names']) };
    }

    function onChangeGridCode() {
      accb.data.grid_code = ACSystemGridCodePair[selectorGridCodeValue.value].accb;
      aces.data.grid_code = ACSystemGridCodePair[selectorGridCodeValue.value].aces;

      const accbDefaultValues = setDataByChangedGridCode(accb.allGridCodeInfo.default_values, accb.data.grid_code);
      const acesDefaultValues = setDataByChangedGridCode(aces.allGridCodeInfo.default_values, aces.data.grid_code);

      if (isCase1) {
        refreshForm(accbDefaultValues);
      } else if (isCase3 || isCase6) {
        refreshForm(accbDefaultValues, acesDefaultValues);
      } else {
        refreshForm(undefined, acesDefaultValues);
      }
    }

    function onEdit() {
      if (!props.isConnection) {
        messageBox.alert(t('message.modify_when_disconnected')).open();

        return;
      }
      isEditable.value = true;
      expandAll.value = true;
      emit('edit');
    }

    function onSave() {
      if (_.isNull(selectorGridCodeValue.value)) {
        messageBox.alert('Grid Code is required').open();

        return;
      }

      let accbParams;
      let acesParams;
      if (isCase1) {
        accbParams = getACCBSaveParams();
        state.confirmPopup = { on: true, data: { ...accbParams } };
      } else if (isCase3 || isCase6) {
        accbParams = getACCBSaveParams();
        acesParams = getACESSaveParams();
        state.confirmPopup = { on: true, data: { ...accbParams, ...acesParams } };
      } else {
        acesParams = getACESSaveParams();
        state.confirmPopup = { on: true, data: { ...acesParams } };
      }
    }

    function onConfirm() {
      state.confirmPopup.on = false;
      let params: AdvSettings = {
        advanced_setting: [],
      };

      if (isCase1 || isCase3 || isCase6) {
        state.excludedColumns =
          accbCopyData.value.grid_code === selectorGridCodeValue.value
            ? [...getBasicSettingsColumns(), ...getEngineerSettingsColumns()]
            : [];
        params.advanced_setting.push({ ...accb.data, product_model_nm: 'accb' });
      } else {
        state.excludedColumns =
          acesCopyData.value.grid_code === selectorGridCodeValue.value
            ? [...getBasicSettingsColumns(), ...getEngineerSettingsColumns()]
            : [];
        params.advanced_setting.push({ ...aces.data, product_model_nm: 'aces' });
      }

      emit('save', _.omit({ ...params, ...props.basicSettingInfo }, state.excludedColumns), function (isOk: boolean) {
        if (isOk) isEditable.value = false;
      });
    }

    function onCancel() {
      isEditable.value = false;
      if (isCase1) {
        refreshForm(accbCopyData.value, undefined);
      } else if (isCase3 || isCase6) {
        refreshForm(accbCopyData.value, acesCopyData.value);
      } else {
        refreshForm(undefined, acesCopyData.value);
      }
      emit('cancel');
    }

    function setACCBCurrentGridCodeInfo(): void {
      state.data.forEach((item) => {
        if (item?.product_model_nm === 'accb') {
          const data = { ...initValue, ...getFormattedData(item) };
          accb.data = data;
          accbCopyData.value = _.cloneDeep(data);
          selectorGridCodeValue.value = accb.data.grid_code;
        }
      });
    }

    function setACESCurrentGridCodeInfo(): void {
      state.data.forEach((item) => {
        if (item?.product_model_nm === 'aces') {
          const data = { ...initValue, ...getFormattedData(item) };
          aces.data = data;
          acesCopyData.value = _.cloneDeep(data);
          selectorGridCodeValue.value = aces.data.grid_code;
        }
      });
    }

    function setACCBConfigSchema() {
      accb.config = getAdvancedSettingACCBConfig(t);
      accb.config = getUpdateConfigByServer(accb.data.grid_code, accb.config, accb.allGridCodeInfo.column_range);
      accb.schema = getACSystemSchema(accb.config);
    }

    function setACESConfigSchema() {
      aces.config = getAdvancedSettingACESConfig(t);
      aces.config = getUpdateConfigByServer(aces.data.grid_code, aces.config, aces.allGridCodeInfo.column_range);
      aces.schema = getACSystemSchema(aces.config);
    }

    function getACCBSaveParams() {
      const accbMergedData = { ...accb.data, ...props.basicSettingInfo };
      const accbEditableColumnsByGridCode = accb.editableColumns[accb.data.grid_code];

      const accbNotNullConfirmedItems = Object.fromEntries(
        Object.entries(accbMergedData).filter(
          ([key, value]) => !Helper.isNull(value) && accbEditableColumnsByGridCode?.includes(key)
        )
      );

      let accbParams: SettingConfigGroup<AdvancedSettingsKey>[] = [
        ...accb.config,
        ...getInstallSettings()['accbBasicSettings'],
      ]
        .map((config) => {
          if (config.role && Array.isArray(config.role)) {
            let copyConfig = _.cloneDeep(config);
            if (!config.role.includes(props.roleNm as Role)) {
              copyConfig.hide = true;
            }
          }

          return {
            ...config,
            children: config.children
              .map((item) => {
                return {
                  ...item,
                  value: getSettingValue<AdvancedSettingsKey>(item, accbNotNullConfirmedItems),
                };
              })
              .filter((item) => !Helper.isNull(item.value) && item?.type !== 'header'),
          };
        })
        .filter((config) => config.children.length > 0);

      return accbParams;
    }

    function getACESSaveParams() {
      const acesMergedData = { ...aces.data, ...props.basicSettingInfo };
      const acesEditableColumnsByGridCode = aces.editableColumns[aces.data.grid_code];

      const acesNotNullComfirmedItems = Object.fromEntries(
        Object.entries(acesMergedData).filter(
          ([key, value]) => !Helper.isNull(value) && acesEditableColumnsByGridCode?.includes(key)
        )
      );

      let acesParams = [...aces.config, ...getInstallSettings()['acesBasicSettings']]
        .map((config) => {
          if (config.role && Array.isArray(config.role)) {
            let copyConfig = config;
            if (!copyConfig.role?.includes(props.roleNm)) {
              copyConfig.hide = true;
            }
            config = { ...copyConfig };
          }

          return {
            ...config,
            children: config.children
              .map((item) => {
                return {
                  ...item,
                  value: getSettingValue<AdvancedSettingsKey>(item, acesNotNullComfirmedItems),
                };
              })
              .filter((item) => !Helper.isNull(item.value) && item?.type !== 'header'),
          };
        })
        .filter((config) => config.children.length > 0);

      return acesParams;
    }

    async function loadSelectorOptions() {
      const { ENERGY_POLICY_GEN3_CD, EXTCTL_GW_CONNECTION_GEN3_CD } = await commonService.getCodesByGroupCode([
        { grpCd: 'ENERGY_POLICY_GEN3_CD' },
        { grpCd: 'EXTCTL_GW_CONNECTION_GEN3_CD' },
      ]);
      selectorOptionsState.energyPolicy = Helper.codeNamesToSelectorOptions(ENERGY_POLICY_GEN3_CD, t, {
        text: t('common.select'),
        value: null,
      });
      selectorOptionsState.extctlGwConnection = Helper.codeNamesToSelectorOptions(EXTCTL_GW_CONNECTION_GEN3_CD, t, {
        text: t('common.select'),
        value: null,
      });
      selectorOptionsState.gridCodeOptions = Helper.addSelectorOptionAtFirst(gridOptionData, {
        text: t('common.select'),
        value: null,
      });
    }

    await fetchGridData();
    getCurrentGridCode();
    init();
    loadSelectorOptions();
    refreshForm();

    return {
      isCase1,
      isCase3,
      isCase6,
      state,
      selectorOptionsState,
      mergedSchema,
      onChangeGridCode,
      selectorGridCodeValue,
      aces,
      accb,
      goToList,
      onEdit,
      onSave,
      onCancel,
      onConfirm,
      getRangeDescription,
      getSettingValue,
      CSS_COLUMN,
      isInit,
      expandAll,
      isEditable,
    };
  },
});
