import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupWrapper = _resolveComponent("PopupWrapper")!

  return (_openBlock(), _createBlock(_component_PopupWrapper, {
    class: "ty_5",
    style: {"width":"800px","opacity":"1","left":"50%","margin-left":"-640px","top":"190px","margin-bottom":"50px"}
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.NoticePopupCompoment))),
      _createElementVNode("button", {
        type: "button",
        class: "close_btn_2",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, _toDisplayString(_ctx.$t('common.close')), 1)
    ]),
    _: 1
  }))
}