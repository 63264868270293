
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import { Flow } from '@hems/component';
import { defaultEnergyFlow } from '@hems/container/src/energyflow/flow/EnergyFlowFunctions';

import type { EnergyFlowCase } from 'hems/energyFlow';

export default defineComponent({
  name: 'EnergyFlow',
  components: {
    Flow,
  },
  props: {
    energyFlow: {
      type: Object as PropType<EnergyFlowCase>,
      default: () => defaultEnergyFlow,
    },
  },
});
