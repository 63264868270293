
import { computed, defineComponent, Prop, PropType, reactive } from 'vue';
import moment from 'moment';
import VCalendarWrapper from '@hems/component/src/calendars/VCalendarWrapper.vue';
import { useModelWrapper } from '@hems/util';
import { CalendarModeType } from '.';

export type CalendarValue = Date;
export default defineComponent({
  name: '',
  components: {
    VCalendarWrapper,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Date as PropType<Date>,
      required: true,
    },
    label: String,
    format: String,
    minDate: Date as Prop<Date>,
    maxDate: Date as Prop<Date>,
    validate: Function as Prop<(date: CalendarValue, beforeDate: CalendarValue) => boolean>,
    mode: {
      type: String as PropType<CalendarModeType>,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      open: false,
      value: useModelWrapper<Date>(props, emit),
      maxDate: new Date(),
    });

    const dateMode = computed(() => (props.mode ? props.mode : 'date'));

    const displayValue = computed(() =>
      props.format
        ? moment(state.value).format(props.format)
        : moment(state.value).locale(navigator.language).format('LL')
    );

    return {
      state,
      dateMode,
      displayValue,
      onOpen: () => (state.open = true),
      onClose: () => (state.open = false),
    };
  },
});
