
import type { PropType } from 'vue';
import { defineComponent, reactive, onMounted, watch, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import _ from 'lodash';
import { Form } from 'vee-validate';
import * as yup from 'yup';

import {
  Accordion,
  BasicInput,
  ToggleInput,
  Selector,
  SettingTable,
  SettingTableRow,
  useMessageBox,
  ChangedMark,
} from '@hems/component';
import { CommonService } from '@hems/service';
import { PCS_CONNECTION_MODE, EXTERNAL_EMS_CONNECTION, EXTERNAL_EMS_MODEL } from '@hems/util/src/constant';
import { isNull, codeNamesToSelectorOptions, addSelectorOptionAtFirst } from '@hems/util/src/helper/helper';

import type { SelectorOption } from 'hems';

import type { GenType, SiteId } from 'hems/device';
import type { SettingGroup } from 'hems/device/settings';
import type { EngineerSettings as EngineerSettingsPvModuleGen3 } from 'hems/device/settings/pvmodule/gen3';
import type { AfciType } from 'hems/device/settings/pvmodule/gen3/engineer';
import type { EngineerSettings as EngineerSettingsSmartModuleGen2 } from 'hems/device/settings/smartmodule/gen2';

import { getEngineerSettingPvModuleGen3SmartModuleGen2Config } from './config';
import SettingConfirmPopup from '../SettingConfirmPopup.vue';

const initValue: Partial<EngineerSettingsPvModuleGen3 & EngineerSettingsSmartModuleGen2> = {
  eos_flag: 0,
  sg_ready_flag: 0,
  inverter_external_generator_mode: 0,
  inverter_permit_service_mode_select: 0,
  inverter_enter_service_randomized_delay: 0,
  inverter_logic_interface: 0,
  fcas_flag: 0,
  fcas_test_mode: 0,
  dc_optimizer_user_rsd: 0,
  ul1741_flag: 0,
  afci_ch1_flag: 0,
  afci_ch2_flag: 0,
  afci_ch3_flag: 0,

  pcs_connection_mode: 0,
  pcs_debug_mode: 0,
  external_ems_model: undefined,
  external_ems_modbus_rtu_dev: '/dev/ttymxc0',
  debug_mode_modbus: 0,
  auto_fw_update_flag: 0,
};

export default defineComponent({
  name: 'EngineerSettingsPvModuleGen3SmartModuleGen2',
  components: {
    Accordion,
    BasicInput,
    ToggleInput,
    Form,
    Selector,
    SettingTable,
    SettingTableRow,
    ChangedMark,
    SettingConfirmPopup,
  },
  props: {
    data: {
      type: Object as PropType<EngineerSettingsPvModuleGen3 & EngineerSettingsSmartModuleGen2>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    isConnection: {
      type: Boolean,
      required: true,
    },
    isHcore: {
      type: Boolean,
      default: false,
    },
    isQcore: {
      type: Boolean,
      default: false,
    },
    isAcCoupled: {
      type: Boolean,
      default: false,
    },
    isFcas: {
      type: Boolean,
      default: false,
    },
    genType: {
      type: String as PropType<'GEN3' | 'DC_OPTIMIZER_GEN2'>,
      default: 'GEN3',
    },
    changedValueSet: {
      type: Object as PropType<Set<string>>,
      default: () => new Set<string>(),
    },
    siteId: {
      type: Number as PropType<SiteId>,
      default: null,
    },
  },
  emits: ['goToList', 'save', 'cancel', 'edit'],
  async setup(props, { emit }) {
    const messageBox = useMessageBox();
    const { t } = useI18n();
    const commonService = new CommonService(window.axiosInstance.axios);
    let copyData: EngineerSettingsPvModuleGen3 & EngineerSettingsSmartModuleGen2 = _.cloneDeep(props.data);
    let copyChangedValueSet: Set<string> = _.cloneDeep(props.changedValueSet);
    const state = reactive<{
      data: EngineerSettingsPvModuleGen3 & EngineerSettingsSmartModuleGen2;
      changedValueSet: Set<string>;
      confirmPopup: { on: boolean; data: SettingGroup[] };
    }>({
      data: { ...initValue, ...props.data },
      changedValueSet: _.cloneDeep(props.changedValueSet),
      confirmPopup: { on: false, data: [] },
    });

    const isEditable = ref<boolean>(props.editable);
    const isSetFCAS = ref<boolean>(props.isFcas);
    const isHCORE = ref<boolean>(props.isHcore);
    const isQCORE = ref<boolean>(props.isQcore);
    const isACCoupled = ref<boolean>(props.isAcCoupled);
    const isGreatBritain = ref<boolean>(props.data.grid_code?.toString().includes('826') ?? false);

    let selectorOptionsState = reactive<{
      pcsConnectionType: SelectorOption[];
      modbusConnectionType: SelectorOption[];
      modbusRtuDev: SelectorOption[];
      mlpeRtuDev: SelectorOption[];
      modbusRtuBaudrate: SelectorOption[];
      modbusRtuParity: SelectorOption[];
      modeFlag: SelectorOption[];
      extEmsModel: SelectorOption[];
      debugInfo: SelectorOption[];
      filteredPcsConnectionType: SelectorOption[];
      meterInfo: SelectorOption[];
    }>({
      pcsConnectionType: [],
      modbusConnectionType: [],
      modbusRtuDev: [],
      mlpeRtuDev: [],
      modbusRtuBaudrate: [],
      modbusRtuParity: [],
      modeFlag: [],
      extEmsModel: [],
      debugInfo: [],
      filteredPcsConnectionType: [],
      meterInfo: [],
    });

    const afciDynamicKeyValue = computed<AfciType>(() => {
      return {
        afci_ch1_flag: props.data.afci_ch1_flag,
        afci_ch2_flag: props.data.afci_ch2_flag,
        afci_ch3_flag: props.data.afci_ch3_flag,
        afci_ch1_time_area_threshold_gain: props.data.afci_ch1_time_area_threshold_gain,
        afci_ch2_time_area_threshold_gain: props.data.afci_ch2_time_area_threshold_gain,
        afci_ch3_time_area_threshold_gain: props.data.afci_ch3_time_area_threshold_gain,
        afci_ch1_frequency_area_threshold_gain: props.data.afci_ch1_frequency_area_threshold_gain,
        afci_ch2_frequency_area_threshold_gain: props.data.afci_ch2_frequency_area_threshold_gain,
        afci_ch3_frequency_area_threshold_gain: props.data.afci_ch1_frequency_area_threshold_gain,
        afci_ch1_frequency_area_threshold_percent: props.data.afci_ch1_frequency_area_threshold_percent,
        afci_ch2_frequency_area_threshold_percent: props.data.afci_ch2_frequency_area_threshold_percent,
        afci_ch3_frequency_area_threshold_percent: props.data.afci_ch3_frequency_area_threshold_percent,
      };
    });

    const isDisabledEMSModel =
      state.data.external_ems_model === EXTERNAL_EMS_MODEL.FOXESS_H1 ||
      state.data.external_ems_model === EXTERNAL_EMS_MODEL.FOXESS_H3;
    const isDisabledEMSModBus = !isEditable.value || isDisabledEMSModel;
    const isDisabledEMSConnection =
      !isEditable.value || (state.data.pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL && isDisabledEMSModel);

    const computedVal = {
      valueRange: {
        pv_feed_in_limit: { min: 0, max: 100 },
        pv_max_pwr2: { min: 0, max: 3300 },
        feed_in_limit: { min: 0, max: 100 },
        battery_backup_soc: { min: 0, max: 100 },
      },
    };

    const defaultValidate = yup
      .number()
      .transform((v, o) => (o === '' ? null : v))
      .nullable();

    const schema = yup.object().shape({
      eos_flag: defaultValidate,
      eos_power_sampling_interval: defaultValidate.integer(),
      eos_default_sampling_interval: defaultValidate.integer(),
      sg_ready_flag: defaultValidate,
      sg_ready_heat_pump_consumption_power: yup.number().when('sg_ready_flag', {
        is: (sg_ready_flag: number) => sg_ready_flag === 1,
        then: defaultValidate.integer().min(0).max(65000),
      }),
      inverter_max_output_power: defaultValidate.integer().min(3600).max(20000),
      inverter_grid_target_frequency: defaultValidate.min(50).max(60).unitNumber(0.01),
      inverter_external_generator_mode: defaultValidate,
      inverter_permit_service_mode_select: defaultValidate,
      inverter_enter_service_randomized_delay: defaultValidate,
      inverter_import_power_limit: defaultValidate.integer().min(0).max(yup.ref('inverter_max_output_power')),
      inverter_export_power_limit: defaultValidate.integer().min(0).max(yup.ref('inverter_max_output_power')),
      battery_charge_limit: defaultValidate.integer().min(0).max(65535),
      battery_discharge_limit: defaultValidate.integer().min(0).max(65535),
      inverter_logic_interface: isGreatBritain.value ? defaultValidate : yup.number().nullable().notRequired(),
      fcas_response_capacity_limit: yup
        .number()
        .nullable()
        .when('eos_flag', {
          is: (eos_flag: number) => isSetFCAS.value && eos_flag === 1,
          then: defaultValidate.integer().min(0).max(2000),
        }),
      fcas_droop_rate_raise: yup
        .number()
        .nullable()
        .when('eos_flag', {
          is: (eos_flag: number) => isSetFCAS.value && eos_flag === 1,
          then: defaultValidate.min(0).max(100).unitNumber(0.01),
        }),
      fcas_droop_rate_lower: yup
        .number()
        .nullable()
        .when('eos_flag', {
          is: (eos_flag: number) => isSetFCAS.value && eos_flag === 1,
          then: defaultValidate.min(0).max(100).unitNumber(0.01),
        }),
      fcas_oftb_max: yup
        .number()
        .nullable()
        .when('eos_flag', {
          is: (eos_flag: number) => isSetFCAS.value && eos_flag === 1,
          then: defaultValidate.min(45).max(65).unitNumber(0.01),
        }),
      fcas_oftb_min: yup
        .number()
        .nullable()
        .when('eos_flag', {
          is: (eos_flag: number) => isSetFCAS.value && eos_flag === 1,
          then: defaultValidate.min(45).max(65).unitNumber(0.01),
        }),
      fcas_fr_max: yup
        .number()
        .nullable()
        .when('eos_flag', {
          is: (eos_flag: number) => isSetFCAS.value && eos_flag === 1,
          then: defaultValidate.min(45).max(65).unitNumber(0.01),
        }),
      fcas_fr_min: yup
        .number()
        .nullable()
        .when('eos_flag', {
          is: (eos_flag: number) => isSetFCAS.value && eos_flag === 1,
          then: defaultValidate.min(45).max(65).unitNumber(0.01),
        }),
      fcas_nofb_max: yup
        .number()
        .nullable()
        .when('eos_flag', {
          is: (eos_flag: number) => isSetFCAS.value && eos_flag === 1,
          then: defaultValidate.min(45).max(65).unitNumber(0.01),
        }),
      fcas_nofb_min: yup
        .number()
        .nullable()
        .when('eos_flag', {
          is: (eos_flag: number) => isSetFCAS.value && eos_flag === 1,
          then: defaultValidate.min(45).max(65).unitNumber(0.01),
        }),
      dc_optimizer_mst_tx_gain: isSmartModuleGen2(props.genType)
        ? defaultValidate.min(1).max(31)
        : yup.number().nullable().notRequired(),
      dc_optimizer_slv_tx_gain: isSmartModuleGen2(props.genType)
        ? defaultValidate.min(1).max(31)
        : yup.number().nullable().notRequired(),
      afci_arc_energy_region: defaultValidate.integer(),
      afci_ch1_flag: defaultValidate.integer(),
      afci_ch1_time_area_threshold_gain: defaultValidate.when('afci_ch1_flag', {
        is: (afci_ch1_flag: number) => afci_ch1_flag === 1,
        then: defaultValidate.integer().min(1).max(9),
      }),
      afci_ch1_frequency_area_threshold_gain: defaultValidate.when('afci_ch1_flag', {
        is: (afci_ch1_flag: number) => afci_ch1_flag === 1,
        then: defaultValidate.integer().min(1).max(9),
      }),
      afci_ch1_frequency_area_threshold_percent: defaultValidate.when('afci_ch1_flag', {
        is: (afci_ch1_flag: number) => afci_ch1_flag === 1,
        then: defaultValidate.integer().min(10).max(29),
      }),
      afci_ch2_flag: defaultValidate.integer(),
      afci_ch2_time_area_threshold_gain: defaultValidate.when('afci_ch2_flag', {
        is: (afci_ch2_flag: number) => afci_ch2_flag === 1,
        then: defaultValidate.integer().min(1).max(9),
      }),
      afci_ch2_frequency_area_threshold_gain: defaultValidate.when('afci_ch2_flag', {
        is: (afci_ch2_flag: number) => afci_ch2_flag === 1,
        then: defaultValidate.integer().min(1).max(9),
      }),
      afci_ch2_frequency_area_threshold_percent: defaultValidate.when('afci_ch2_flag', {
        is: (afci_ch2_flag: number) => afci_ch2_flag === 1,
        then: defaultValidate.integer().min(10).max(29),
      }),
      afci_ch3_flag: defaultValidate.integer(),
      afci_ch3_time_area_threshold_gain: defaultValidate.when('afci_ch3_flag', {
        is: (afci_ch3_flag: number) => afci_ch3_flag === 1,
        then: defaultValidate.integer().min(1).max(9),
      }),
      afci_ch3_frequency_area_threshold_gain: defaultValidate.when('afci_ch3_flag', {
        is: (afci_ch3_flag: number) => afci_ch3_flag === 1,
        then: defaultValidate.integer().min(1).max(9),
      }),
      afci_ch3_frequency_area_threshold_percent: defaultValidate.when('afci_ch3_flag', {
        is: (afci_ch3_flag: number) => afci_ch3_flag === 1,
        then: defaultValidate.integer().min(10).max(29),
      }),
      secondary_ems_modbus_tcp_ip: yup.string().when({
        is: (secondary_ems_modbus_tcp_ip?: string | null) => !isNull(secondary_ems_modbus_tcp_ip),
        then: yup
          .string()
          .matches(
            /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            t('message.format_ipv4')
          ),
      }),
      secondary_ems_modbus_tcp_port: defaultValidate.integer().min(0).max(65535),
      secondary_ems_server_id: defaultValidate.integer(),

      pcs_connection_mode: defaultValidate.integer(),
      pcs_debug_mode: defaultValidate.integer(),
      external_ems_model: defaultValidate.integer(),
      external_ems_connection: defaultValidate.integer().min(0).max(3),
      external_ems_modbus_tcp_ip: yup.string().when({
        is: (
          pcs_connection_mode: number,
          external_ems_connection: number,
          external_ems_modbus_tcp_ip?: string | null
        ) =>
          pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
          external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_TCP &&
          !isNull(external_ems_modbus_tcp_ip),
        then: yup
          .string()
          .matches(
            /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            t('message.format_ipv4')
          ),
      }),
      external_ems_modbus_tcp_port: defaultValidate.when({
        is: (pcs_connection_mode: number, external_ems_connection: number) =>
          pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
          external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_TCP,
        then: defaultValidate.integer().min(0).max(65535),
      }),
      external_ems_modbus_rtu_dev: yup
        .string()
        .nullable()
        .when({
          is: (pcs_connection_mode: number, external_ems_connection: number) =>
            pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
            external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU,
          then: yup.string().nullable(),
        }),
      external_ems_modbus_rtu_baudrate: defaultValidate.when({
        is: (pcs_connection_mode: number, external_ems_connection: number) =>
          pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
          external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU,
        then: defaultValidate.integer(),
      }),
      external_ems_modbus_rtu_parity: yup
        .string()
        .nullable()
        .when({
          is: (pcs_connection_mode: number, external_ems_connection: number) =>
            pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
            external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU,
          then: yup.string().nullable(),
        }),
      external_ems_modbus_rtu_data: defaultValidate.when({
        is: (pcs_connection_mode: number, external_ems_connection: number) =>
          pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
          external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU,
        then: defaultValidate.integer().min(5).max(8),
      }),
      external_ems_modbus_rtu_stop: defaultValidate.when({
        is: (pcs_connection_mode: number, external_ems_connection: number) =>
          pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
          external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU,
        then: defaultValidate.integer().min(1).max(2),
      }),
      external_ems_server_id: defaultValidate.integer(),
      battery_hysteresis_low: defaultValidate,
      battery_hysteresis_high: defaultValidate,
      battery_user_soc_min: defaultValidate.integer().min(0).max(yup.ref('battery_user_soc_max')),
      battery_user_soc_max: defaultValidate.integer().min(yup.ref('battery_user_soc_min')).max(100),
      log_level_process_mgr: defaultValidate,
      log_level_system_log: defaultValidate,
      log_level_fota: defaultValidate,
      log_level_power_control: defaultValidate,
      log_level_algorithm_mgr: defaultValidate,
      log_level_ess_mgr: defaultValidate,
      log_level_dc_source_mgr: defaultValidate,
      log_level_cloud_mgr: defaultValidate,
      log_level_meter_mgr: defaultValidate,
      log_level_gateway_mgr: defaultValidate,
      log_level_data_accumulator: defaultValidate,
      log_level_db_mgr: defaultValidate,
      log_level_web_engine: defaultValidate,
      log_level_gqa: defaultValidate,
      log_level_mlpe_mgr: defaultValidate,
      log_level_sys_mgr: defaultValidate,
      log_level_energy_link: defaultValidate,
      log_level_csip_mgr: defaultValidate,
      log_level_energy_optimizer: defaultValidate,
      debug_mode_mqtt: defaultValidate,
      debug_mode_modbus: defaultValidate,
      auto_fw_update_flag: defaultValidate.integer(),
    });

    async function loadSelectorOptions() {
      const {
        PCS_CONNECTION_TYPE_GEN3_CD,
        MODBUS_CONNECTION_TYPE_GEN3_CD,
        MODBUS_RTU_BAUDRATE_GEN3_CD,
        MODBUS_RTU_PARITY_GEN3_CD,
        MODE_FLAG_CD,
        EXT_EMS_MODEL_GEN3_CD,
        DEBUGINFO_GEN3_CD,
      } = await commonService.getCodesByGroupCode([
        { grpCd: 'PCS_CONNECTION_TYPE_GEN3_CD' },
        { grpCd: 'MODBUS_CONNECTION_TYPE_GEN3_CD' },
        { grpCd: 'MODBUS_RTU_BAUDRATE_GEN3_CD' },
        { grpCd: 'MODBUS_RTU_PARITY_GEN3_CD' },
        { grpCd: 'MODE_FLAG_CD' },
        { grpCd: 'EXT_EMS_MODEL_GEN3_CD', exclude: ['2'] },
        { grpCd: 'DEBUGINFO_GEN3_CD' },
      ]);

      selectorOptionsState.pcsConnectionType = [
        { text: t('common.select'), value: null },
        ...codeNamesToSelectorOptions(PCS_CONNECTION_TYPE_GEN3_CD, t),
      ];
      selectorOptionsState.modbusConnectionType = codeNamesToSelectorOptions(MODBUS_CONNECTION_TYPE_GEN3_CD, t, {
        text: t('common.select'),
        value: null,
      });
      selectorOptionsState.modbusRtuBaudrate = [
        { text: t('common.select'), value: null },
        ...codeNamesToSelectorOptions(MODBUS_RTU_BAUDRATE_GEN3_CD, t),
      ];
      selectorOptionsState.modbusRtuParity = [
        { text: t('common.select'), value: null },
        ...codeNamesToSelectorOptions(MODBUS_RTU_PARITY_GEN3_CD, t),
      ];
      selectorOptionsState.modeFlag = [
        { text: t('common.select'), value: null },
        ...codeNamesToSelectorOptions(MODE_FLAG_CD, t),
      ];
      selectorOptionsState.extEmsModel = [
        { text: t('common.select'), value: null },
        ...codeNamesToSelectorOptions(EXT_EMS_MODEL_GEN3_CD, t),
      ];
      selectorOptionsState.debugInfo = [
        { text: t('common.select'), value: null },
        ...codeNamesToSelectorOptions(DEBUGINFO_GEN3_CD, t),
      ];

      selectorOptionsState.modbusRtuDev = [
        { text: 'Serial1 (/dev/ttymxc3)', value: '/dev/ttymxc3' },
        { text: 'Serial2 (/dev/ttymxc0)', value: '/dev/ttymxc0' },
        // { text: 'Serial3 (/dev/ttymxc1)', value: '/dev/ttymxc1' }, // 추후 변경될 가능성이 있어 주석 처리 조치
      ];

      selectorOptionsState.mlpeRtuDev = [
        // { text: 'Serial1 (/dev/ttymxc3)', value: '/dev/ttymxc3' }, // 추후 변경될 가능성이 있어 주석 처리 조치
        // { text: 'Serial2 (/dev/ttymxc0)', value: '/dev/ttymxc0' }, // 추후 변경될 가능성이 있어 주석 처리 조치
        { text: 'Serial3 (/dev/ttymxc1)', value: '/dev/ttymxc1' },
      ];

      // 추후 변경될 가능성이 있어 주석 처리 조치
      // if (props.isHcore) {
      //   selectorOptionsState.modbusRtuDev.splice(2);
      // }

      selectorOptionsState.filteredPcsConnectionType = [
        { text: t('common.select'), value: null },
        ...codeNamesToSelectorOptions(
          PCS_CONNECTION_TYPE_GEN3_CD.filter((item) => item.code !== '2' && item.code !== '3'),
          t
        ),
      ];

      selectorOptionsState.meterInfo = addSelectorOptionAtFirst(
        [
          { text: 'Disable', value: '0' },
          { text: 'Meter', value: '1' },
          { text: 'CT', value: '2' },
        ],
        { text: t('common.select'), value: null }
      );
    }

    async function getFcasDefault() {
      const siteId = props.siteId;
      if (!isNull(siteId)) {
        const fcasDefaultValue = await commonService.getFcasSettingDefault(siteId);

        return _.omit(fcasDefaultValue, 'fcas_flag');
      }

      return {};
    }

    function getParam(): Partial<EngineerSettingsPvModuleGen3 & EngineerSettingsSmartModuleGen2> {
      const {
        eos_flag,
        eos_power_sampling_interval,
        eos_default_sampling_interval,
        sg_ready_flag,
        sg_ready_heat_pump_consumption_power,
        inverter_max_output_power,
        inverter_grid_target_frequency,
        inverter_external_generator_mode,
        inverter_permit_service_mode_select,
        inverter_enter_service_randomized_delay,
        inverter_import_power_limit,
        inverter_export_power_limit,
        battery_charge_limit,
        battery_discharge_limit,
        inverter_logic_interface,
        fcas_flag,
        fcas_response_capacity_limit,
        fcas_droop_rate_raise,
        fcas_droop_rate_lower,
        fcas_oftb_max,
        fcas_oftb_min,
        fcas_fr_max,
        fcas_fr_min,
        fcas_nofb_max,
        fcas_nofb_min,
        fcas_test_mode,
        dc_optimizer_user_rsd,
        dc_optimizer_mst_tx_gain,
        dc_optimizer_slv_tx_gain,
        external_control_connection,
        ul1741_flag,
        afci_arc_energy_region,
        afci_ch1_flag,
        afci_ch1_time_area_threshold_gain,
        afci_ch1_frequency_area_threshold_gain,
        afci_ch1_frequency_area_threshold_percent,
        afci_ch2_flag,
        afci_ch2_time_area_threshold_gain,
        afci_ch2_frequency_area_threshold_gain,
        afci_ch2_frequency_area_threshold_percent,
        afci_ch3_flag,
        afci_ch3_time_area_threshold_gain,
        afci_ch3_frequency_area_threshold_gain,
        afci_ch3_frequency_area_threshold_percent,
        secondary_ems_modbus_tcp_ip,
        secondary_ems_modbus_tcp_port,
        secondary_ems_server_id,

        pcs_connection_mode,
        pcs_debug_mode,
        external_ems_model,
        external_ems_connection,
        external_ems_modbus_tcp_ip,
        external_ems_modbus_tcp_port,
        external_ems_modbus_rtu_dev,
        external_ems_modbus_rtu_baudrate,
        external_ems_modbus_rtu_parity,
        external_ems_modbus_rtu_data,
        external_ems_modbus_rtu_stop,
        external_ems_server_id,
        external_ems_meter1,
        external_ems_meter2,
        battery_hysteresis_low,
        battery_hysteresis_high,
        battery_user_soc_min,
        battery_user_soc_max,
        log_level_process_mgr,
        log_level_system_log,
        log_level_fota,
        log_level_power_control,
        log_level_algorithm_mgr,
        log_level_ess_mgr,
        log_level_dc_source_mgr,
        log_level_cloud_mgr,
        log_level_meter_mgr,
        log_level_gateway_mgr,
        log_level_data_accumulator,
        log_level_db_mgr,
        log_level_web_engine,
        log_level_gqa,
        log_level_mlpe_mgr,
        log_level_sys_mgr,
        log_level_energy_link,
        log_level_diag_monitor,
        log_level_csip_mgr,
        log_level_energy_optimizer,
        debug_mode_mqtt,
        debug_mode_modbus,
        auto_fw_update_flag,
      } = state.data;

      const param: Partial<EngineerSettingsPvModuleGen3 & EngineerSettingsSmartModuleGen2> = {
        eos_flag,
        eos_power_sampling_interval,
        eos_default_sampling_interval,
        sg_ready_flag,
        sg_ready_heat_pump_consumption_power: sg_ready_flag === 1 ? sg_ready_heat_pump_consumption_power : undefined,
        inverter_max_output_power,
        inverter_grid_target_frequency,
        inverter_external_generator_mode,
        inverter_permit_service_mode_select,
        inverter_enter_service_randomized_delay,
        inverter_import_power_limit,
        inverter_export_power_limit,
        battery_charge_limit,
        battery_discharge_limit,
        inverter_logic_interface: isGreatBritain.value ? inverter_logic_interface : undefined,
        fcas_flag: isSetFCAS.value ? (eos_flag === 1 ? fcas_flag : 0) : undefined,
        fcas_response_capacity_limit: isSetFCAS.value && eos_flag === 1 ? fcas_response_capacity_limit : undefined,
        fcas_droop_rate_raise: isSetFCAS.value && eos_flag === 1 ? fcas_droop_rate_raise : undefined,
        fcas_droop_rate_lower: isSetFCAS.value && eos_flag === 1 ? fcas_droop_rate_lower : undefined,
        fcas_oftb_max: isSetFCAS.value && eos_flag === 1 ? fcas_oftb_max : undefined,
        fcas_oftb_min: isSetFCAS.value && eos_flag === 1 ? fcas_oftb_min : undefined,
        fcas_fr_max: isSetFCAS.value && eos_flag === 1 ? fcas_fr_max : undefined,
        fcas_fr_min: isSetFCAS.value && eos_flag === 1 ? fcas_fr_min : undefined,
        fcas_nofb_max: isSetFCAS.value && eos_flag === 1 ? fcas_nofb_max : undefined,
        fcas_nofb_min: isSetFCAS.value && eos_flag === 1 ? fcas_nofb_min : undefined,
        fcas_test_mode: isSetFCAS.value && eos_flag === 1 ? fcas_test_mode : undefined,
        dc_optimizer_user_rsd: isSmartModuleGen2(props.genType) ? dc_optimizer_user_rsd : undefined,
        dc_optimizer_mst_tx_gain: isSmartModuleGen2(props.genType) ? dc_optimizer_mst_tx_gain : undefined,
        dc_optimizer_slv_tx_gain: isSmartModuleGen2(props.genType) ? dc_optimizer_slv_tx_gain : undefined,
        // ul1741_flag 와의 dependency가 있어 화면에는 없으나 setting 내리도록 처리
        external_control_connection: ul1741_flag === 1 ? 0 : external_control_connection,
        ul1741_flag,
        afci_arc_energy_region,
        afci_ch1_flag,
        afci_ch1_time_area_threshold_gain: afci_ch1_flag === 1 ? afci_ch1_time_area_threshold_gain : undefined,
        afci_ch1_frequency_area_threshold_gain:
          afci_ch1_flag === 1 ? afci_ch1_frequency_area_threshold_gain : undefined,
        afci_ch1_frequency_area_threshold_percent:
          afci_ch1_flag === 1 ? afci_ch1_frequency_area_threshold_percent : undefined,
        afci_ch2_flag,
        afci_ch2_time_area_threshold_gain: afci_ch2_flag === 1 ? afci_ch2_time_area_threshold_gain : undefined,
        afci_ch2_frequency_area_threshold_gain:
          afci_ch2_flag === 1 ? afci_ch2_frequency_area_threshold_gain : undefined,
        afci_ch2_frequency_area_threshold_percent:
          afci_ch2_flag === 1 ? afci_ch2_frequency_area_threshold_percent : undefined,
        afci_ch3_flag,
        afci_ch3_time_area_threshold_gain: afci_ch3_flag === 1 ? afci_ch3_time_area_threshold_gain : undefined,
        afci_ch3_frequency_area_threshold_gain:
          afci_ch3_flag === 1 ? afci_ch3_frequency_area_threshold_gain : undefined,
        afci_ch3_frequency_area_threshold_percent:
          afci_ch3_flag === 1 ? afci_ch3_frequency_area_threshold_percent : undefined,
        secondary_ems_modbus_tcp_ip,
        secondary_ems_modbus_tcp_port,
        secondary_ems_server_id,

        pcs_connection_mode,
        pcs_debug_mode,
        external_ems_model: pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL ? external_ems_model : undefined,
        external_ems_connection,
        external_ems_modbus_tcp_ip:
          pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
          external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_TCP
            ? external_ems_modbus_tcp_ip
            : undefined,
        external_ems_modbus_tcp_port:
          pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
          external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_TCP
            ? external_ems_modbus_tcp_port
            : undefined,
        external_ems_modbus_rtu_dev:
          pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
          external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU
            ? external_ems_modbus_rtu_dev || '/dev/ttymxc0'
            : undefined,
        external_ems_modbus_rtu_baudrate:
          pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
          external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU
            ? external_ems_modbus_rtu_baudrate
            : undefined,
        external_ems_modbus_rtu_parity:
          pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
          external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU
            ? external_ems_modbus_rtu_parity
            : undefined,
        external_ems_modbus_rtu_data:
          pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
          external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU
            ? external_ems_modbus_rtu_data
            : undefined,
        external_ems_modbus_rtu_stop:
          pcs_connection_mode === PCS_CONNECTION_MODE.EXTERNAL &&
          external_ems_connection === EXTERNAL_EMS_CONNECTION.MODBUS_RTU
            ? external_ems_modbus_rtu_stop
            : undefined,
        external_ems_server_id,
        external_ems_meter1,
        external_ems_meter2,
        battery_hysteresis_low,
        battery_hysteresis_high,
        battery_user_soc_min,
        battery_user_soc_max,
        log_level_process_mgr,
        log_level_system_log,
        log_level_fota,
        log_level_power_control,
        log_level_algorithm_mgr,
        log_level_ess_mgr,
        log_level_dc_source_mgr,
        log_level_cloud_mgr,
        log_level_meter_mgr,
        log_level_gateway_mgr,
        log_level_data_accumulator,
        log_level_db_mgr,
        log_level_web_engine,
        log_level_gqa,
        log_level_mlpe_mgr,
        log_level_sys_mgr,
        log_level_energy_link,
        log_level_diag_monitor,
        log_level_csip_mgr,
        log_level_energy_optimizer,
        debug_mode_mqtt,
        debug_mode_modbus,
        auto_fw_update_flag,
      };

      const cleanedParam = _.pickBy(param, (v) => v !== undefined);

      return cleanedParam;
    }

    function isSmartModuleGen2(genType: GenType) {
      if (isNull(genType)) return false;
      if (genType === 'DC_OPTIMIZER_GEN2') return true;

      return false;
    }

    function onSave() {
      let confirmItems = getParam();
      let params = getEngineerSettingPvModuleGen3SmartModuleGen2Config(t, selectorOptionsState)
        .map((config) => {
          return {
            ...config,
            children: config.children
              .map((item) => {
                const itemValue =
                  confirmItems[item.code as keyof (EngineerSettingsPvModuleGen3 & EngineerSettingsSmartModuleGen2)];
                const value =
                  item.type === 'number' || item.type === 'text'
                    ? itemValue
                    : !isNull(itemValue)
                    ? item.options?.filter((option) => option.value == itemValue)[0]?.text
                    : undefined;

                return {
                  ...item,
                  value: value,
                };
              })
              .filter((item) => !isNull(item.value)),
          };
        })
        .filter((config) => config.children.length > 0);

      state.confirmPopup = { on: true, data: params };
    }

    function onConfirm() {
      state.confirmPopup.on = false;
      emit('save', getParam(), function (isOk: boolean) {
        if (isOk) isEditable.value = false;
      });
    }

    async function onEdit() {
      if (!props.isConnection) {
        messageBox.alert(t('message.modify_when_disconnected')).open();

        return;
      }

      copyData = _.cloneDeep(state.data);
      const fcasSettings = await getFcasDefault();
      state.data = { ...state.data, ...fcasSettings };
      isEditable.value = true;
      emit('edit', copyData);
    }

    function onCancel(handleReset?: () => void) {
      state.data = copyData;
      isEditable.value = false;
      if (handleReset) handleReset();
      emit('cancel');
    }
    function goToList() {
      emit('goToList');
    }

    function onChange(valueKey: string, targetValue?: string | number | boolean) {
      if (isEditable.value) {
        if (!isNull(targetValue)) {
          // 변경 항목 key set에 저장
          state.changedValueSet.add(valueKey);
        } else {
          // 변경 항목 key set에서 제거
          state.changedValueSet.delete(valueKey);
        }
      } else {
        // cancel 버튼 클릭 시
        state.changedValueSet = copyChangedValueSet;
      }
    }

    onMounted(() => {
      if (props.isAcCoupled) {
        state.data.sg_ready_heat_pump_consumption_power = 0;
      }
    });

    watch(
      () => state.data.fcas_flag,
      async (flag) => {
        if (flag === 0) {
          state.data.fcas_test_mode = 0;
        }
        if (flag) {
          const fcasSettings = await getFcasDefault();
          state.data = { ...state.data, ...fcasSettings };
        }
      }
    );

    watch(
      () => state.data.pcs_connection_mode,
      (mode) => {
        if (mode === PCS_CONNECTION_MODE.INTERNAL) {
          // internal -> CAN 통신으로 변경
          state.data.external_ems_connection = EXTERNAL_EMS_CONNECTION.CAN;
        } else if (mode === PCS_CONNECTION_MODE.EXTERNAL) {
          // external -> external ems model을 FOXESS H1으로 변경 & Modbus RTU 통신으로 변경
          state.data.external_ems_model = EXTERNAL_EMS_MODEL.FOXESS_H1;
          state.data.external_ems_connection = EXTERNAL_EMS_CONNECTION.MODBUS_RTU;
        }
      }
    );

    watch(
      () => state.data.external_ems_model,
      (model) => {
        if (model === EXTERNAL_EMS_MODEL.FOXESS_H1 || model === EXTERNAL_EMS_MODEL.FOXESS_H3) {
          // model이 FOXESS면 Modbus RTU 통신으로 변경
          state.data.external_ems_connection = EXTERNAL_EMS_CONNECTION.MODBUS_RTU;
        }
      }
    );

    await loadSelectorOptions();

    return {
      state,
      schema,
      computedVal,
      selectorOptionsState,
      onSave,
      onEdit,
      onCancel,
      goToList,
      onChange,
      onConfirm,
      afciDynamicKeyValue,
      PCS_CONNECTION_MODE,
      EXTERNAL_EMS_CONNECTION,
      EXTERNAL_EMS_MODEL,
      isEditable,
      isSetFCAS,
      isHCORE,
      isQCORE,
      isACCoupled,
      isGreatBritain,
      isDisabledEMSModBus,
      isDisabledEMSConnection,
    };
  },
});
