
import { useModelWrapper } from '@hems/util';
import { computed, defineComponent, reactive } from 'vue';

export default defineComponent({
  name: '',
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    rowCountPerPage: {
      type: Number,
      default: 10,
    },
    pageCount: {
      type: Number,
      default: 10,
    },
    totalRowCount: {
      type: Number,
      required: true,
    },
  },
  emits: ['changePage'],
  setup(props, { emit }) {
    const state = reactive({
      current: useModelWrapper<number>(props, emit),
      paging: computed(() => {
        const totalPage = Math.ceil(props.totalRowCount / props.rowCountPerPage);
        const pageGroup = Math.ceil(state.current / props.pageCount);
        const _last = pageGroup * props.pageCount;
        const last = _last > totalPage ? totalPage : _last;
        const _first = _last - (props.pageCount - 1);
        const first = _first < 1 ? 1 : _first;
        const next = last + 1;
        const prev = first - 1;
        const pages = Array.from(Array(last - first + 1), (_, i) => first + i);

        return {
          prev,
          first,
          last,
          next,
          pages,
          totalPage,
          disablePrevArrow: pages.includes(1),
          disableNextArrow: pages.includes(totalPage),
        };
      }),
    });

    function onClickPage(pageNum: number) {
      state.current = pageNum;
      emit('changePage');
    }

    function onClickPrev() {
      if (state.paging.disablePrevArrow) return;

      state.current = state.paging.prev;
      emit('changePage');
    }
    function onClickNext() {
      if (state.paging.disableNextArrow) return;

      state.current = state.paging.next;
      emit('changePage');
    }

    function onClickFirst() {
      if (state.paging.disablePrevArrow) return;

      state.current = 1;
      emit('changePage');
    }
    function onClickLast() {
      if (state.paging.disableNextArrow) return;

      state.current = state.paging.totalPage;
      emit('changePage');
    }

    return {
      state,
      event: {
        onClickPage,
        onClickNext,
        onClickPrev,
        onClickFirst,
        onClickLast,
      },
    };
  },
});
