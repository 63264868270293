/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { Helper } from '@hems/util';
import { createStore, createLogger } from 'vuex';
import appCtx, { AppCtxState } from './modules/appCtx';
import user, { UserInfoState } from './modules/user';

export interface RootState {
  appCtx: AppCtxState;
  user: UserInfoState;
}
export default createStore({
  plugins: Helper.getAppEnv() === 'local' ? [createLogger()] : [],
  modules: { appCtx, user },
});
