
import type { PropType } from 'vue';
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import * as Highcharts from 'highcharts';
import * as Highstock from 'highcharts/highstock';

export default defineComponent({
  name: 'GraphChart',
  components: {},
  props: {
    options: {
      type: Object as PropType<Highcharts.Options>,
      required: true,
    },
    type: {
      type: String as PropType<'Overview' | 'General' | 'Appliance' | 'TodayProduction' | 'TodayConsumption'>,
      default: 'General',
    },
  },
  setup(props) {
    const chart = ref<HTMLDivElement | null>(null);
    const chartInstance = ref<Highcharts.StockChart | null>(null);

    NoDataToDisplay(Highstock);

    function drawData(options: Highcharts.Options) {
      if (!options.chart || !chart.value) return;
      options.chart.renderTo = chart.value;
      options.chart.backgroundColor = 'transparent';
      chartInstance.value =
        props.type === 'Appliance' || props.type === 'TodayProduction' || props.type === 'TodayConsumption'
          ? Highcharts.chart(options)
          : Highstock.stockChart(options);
    }

    watch(
      () => props.options,
      (options: Highcharts.Options) => {
        drawData(options);
      }
    );

    onMounted(() => {
      drawData(props.options);
    });

    onBeforeUnmount(() => {
      chartInstance.value?.destroy();
    });

    return { chart };
  },
});
