import { Helper, Color } from '@hems/util';
import { CellRendererProps } from 'tui-grid/types/renderer';

interface InstallationStepRendererOptions {
  maxStep?: string;
  isComplete?: string;
}

class InstallationStepRenderer {
  private el: HTMLElement;
  private text: JQuery<HTMLElement>;

  constructor(props: CellRendererProps) {
    const { $ } = window;
    const el = $('<div class="installation-step-cell"></div>');
    this.text = $('<span class="installation-step-text"></span>');
    el.append(this.text);

    this.el = el.get(0);
    this.render(props);
  }

  getElement(): HTMLElement {
    return this.el;
  }

  render(props: CellRendererProps): void {
    const value = Helper.nullTo(props.value, '') as string;
    const options = props.columnInfo.renderer.options as InstallationStepRendererOptions;
    const maxStepColumnName = Helper.nullTo(options.maxStep, '') ?? '';
    const maxStep = Helper.nullTo(props.grid.getValue(props.rowKey, maxStepColumnName), value) as number;
    const isCompleteColumnName = Helper.nullTo(options.isComplete, '') ?? '';
    const isComplete = Helper.nullTo(props.grid.getValue(props.rowKey, isCompleteColumnName), value) as string;
    let displayedText = value;

    let textColor = Color.DEFAULT_COLOR.BLACK;
    let completeClass = '';

    if (Helper.isNull(value) || !options) {
      displayedText = '';
      return;
    }
    if (isComplete) {
      textColor = Color.DEFAULT_COLOR.PRIMARY_BLUE_LIGHT;
      completeClass = 'complete';
      displayedText = 'Complete';
    } else {
      textColor = Color.PRO_2_COLOR.TANGERINE;
      completeClass = 'incomplete';

      if (maxStep <= Number(value)) {
        displayedText = `Step ${Number(value)} is incomplete`;
      } else {
        displayedText = `Step ${Number(value) + 1} is incomplete`;
      }
    }
    this.text.addClass(completeClass);
    this.text.css({ color: textColor });
    this.text.text(displayedText);
  }
}

export default InstallationStepRenderer;
