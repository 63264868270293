
import { computed, defineComponent, reactive } from 'vue';
import { BasicInput } from '../inputs';
import { SettingTable, SettingTableRow } from '@hems/component';
import PopupWrapper from './PopupWrapper.vue';

export default defineComponent({
  name: 'BatteryModulePopup',
  components: {
    PopupWrapper,
    SettingTable,
    SettingTableRow,
    BasicInput,
  },
  props: {
    title: String,
    cancelText: String,
    data: Object,
  },
  emits: ['close'],
  async setup(props, { emit }) {
    const state = reactive({
      data: computed(() => props.data),
    });

    const close = () => {
      emit('close');
    };

    return {
      state,
      close,
    };
  },
});
