
import { defineComponent, PropType, reactive, computed, Prop, watch } from 'vue';
import { LabelSelectorOption } from 'hems';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  name: 'MultiSelector',
  components: {
    Multiselect,
  },
  props: {
    label: String,
    valueType: {
      type: String as PropType<'string' | 'number' | 'boolean'>,
      default: 'string',
    },
    placeholder: String,
    options: {
      type: Array as PropType<LabelSelectorOption[]>,
      required: false,
    },
    inputValue: {
      type: [] as PropType<string[]>,
      required: false,
    },
    modelValue: Object as PropType<string[]>,
    productModelNms: {
      type: Object as PropType<string[]>,
      required: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      placeholder: computed(() => props.placeholder ?? 'Select an option'),
      productModelNms: computed(() => props.productModelNms),
      inputValue: props.productModelNms,
    });

    watch(
      () => props.modelValue,
      () => {
        if (state.inputValue !== props.modelValue) state.inputValue = props.modelValue || [];
      }
    );

    watch(
      () => state.inputValue,
      () => {
        emit('update:modelValue', state.inputValue);
      }
    );

    return {
      state,
      selOptions: computed(() => props.options),
    };
  },
});
