import type { ValueType } from 'hems/common/utils';

export const NETWORK_TYPE_CODE = {
  /** 미사용 값 */
  ALL: -1,
  /** Ethernet */
  ETHERNET: 0,
  /** Wi-Fi */
  WIFI: 1,
  /** Cellular */
  LTE: 2,
};

export type NetworkTypeCodeValue = ValueType<typeof NETWORK_TYPE_CODE>;

export const INTERNET_CONNECTION_STATUS_CODE = {
  /** Not Available */
  NOT_AVAILABLE: 0,
  /** Available */
  AVAILABLE: 1,
  /** Active */
  ACTIVE: 2,
};

export type InternetConnectionStatusCodeValue = ValueType<typeof INTERNET_CONNECTION_STATUS_CODE>;
