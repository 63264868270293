
import { defineComponent } from 'vue';

import Image from '@hems/component/src/image/Image2.vue';
import { useCheckMobile } from '@hems/util';

export default defineComponent({
  name: 'RefreshButton',
  components: {
    Image,
  },
  inheritAttrs: false,
  setup() {
    const { isMobile } = useCheckMobile();

    const refreshClassName = isMobile ? 'btn-refresh-container-mobile' : 'btn-refresh-container-web';
    const imgSrc = isMobile ? 'smartmodule/ic_dashboard_refresh_mobile_light' : 'smartmodule/ic_dashboard_refresh';

    return {
      refreshClassName,
      imgSrc,
    };
  },
});
