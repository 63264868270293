import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Flow = _resolveComponent("Flow")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: "energyflow2/PVtoB"
    }, null, 512), [
      [_vShow, _ctx.energyFlow.isPvToBattery]
    ]),
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: "energyflow2/PVtoG"
    }, null, 512), [
      [_vShow, _ctx.energyFlow.isPvToGrid]
    ]),
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: "energyflow2/PVtoL"
    }, null, 512), [
      [_vShow, _ctx.energyFlow.isPvToLoad]
    ]),
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: "energyflow2/BtoG"
    }, null, 512), [
      [_vShow, _ctx.energyFlow.isBatteryToGrid]
    ]),
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: "energyflow2/BtoL"
    }, null, 512), [
      [_vShow, _ctx.energyFlow.isBatteryToLoad]
    ]),
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: "energyflow2/GtoB"
    }, null, 512), [
      [_vShow, _ctx.energyFlow.isGridToBattery]
    ]),
    _withDirectives(_createVNode(_component_Flow, {
      class: "flow_container",
      name: "energyflow2/GtoL"
    }, null, 512), [
      [_vShow, _ctx.energyFlow.isGridToLoad]
    ])
  ], 64))
}