
import { Messages } from 'hems';
import { computed, defineComponent, ref, watch } from 'vue';

type MessageBoxType = 'alert' | 'confirm';
type MessageBoxInstance = {
  on: boolean;
  type: MessageBoxType;
  title: string;
  messages: Messages;
  boxStyle?: Record<string, string>;
  titleStyle?: Record<string, string>;
  messageStyle?: Record<string, string>;
  resolve?: (value: unknown) => void;
  reject?: (reason?: any) => void;
};
class MessageBox {
  private _type: MessageBoxType = 'alert';
  private _title = '';
  private _messages: Messages = '';
  private _boxStyle?: Record<string, string>;
  private _titleStyle?: Record<string, string>;
  private _messageStyle?: Record<string, string>;

  constructor() {
    this.init();
  }

  private init(type: MessageBoxType = 'alert') {
    this._type = type;
    this._title = '';
    this._messages = '';
    this._boxStyle = undefined;
    this._titleStyle = undefined;
    this._messageStyle = undefined;
    return this;
  }

  private getInstance(resolve: (value: unknown) => void, reject: (reason?: any) => void): MessageBoxInstance {
    return {
      on: true,
      type: this._type,
      title: this._title,
      messages: this._messages,
      boxStyle: this._boxStyle,
      titleStyle: this._titleStyle,
      messageStyle: this._messageStyle,
      resolve,
      reject,
    };
  }

  alert(messages: Messages) {
    this.init('alert');
    this._messages = messages;
    return this.chainSetMessage();
  }
  confirm(messages: Messages) {
    this.init('confirm');
    this._messages = messages;
    return this.chainSetMessage();
  }

  private chainSetMessage() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return {
      title(title: string) {
        self._title = title;
        return this;
      },
      width(width: string) {
        self._boxStyle = { ...self._boxStyle, width };
        return this;
      },
      alignTitle(textAlign: 'left' | 'right' | 'center' = 'left') {
        self._titleStyle = { ...self._titleStyle, textAlign };
        return this;
      },
      alignMessage(textAlign: 'left' | 'right' | 'center' = 'center') {
        self._messageStyle = { ...self._messageStyle, textAlign };
        return this;
      },
      open() {
        return new Promise((resolve, reject) => {
          _messageBox.value.on = false;
          setTimeout(() => {
            _messageBox.value = self.getInstance(resolve, reject);
          }, 250);
        });
      },
    };
  }
}

const _messageBox = ref({} as MessageBoxInstance);
const btnCancelEl = ref(null as null | HTMLButtonElement);
const close = () => {
  _messageBox.value.on = false;
};

export const useMessageBox = (): MessageBox => new MessageBox();

export default defineComponent({
  name: 'MessageBox',
  setup() {
    const messageBox = computed(() => _messageBox.value);
    const messages = computed(() => {
      const { messages } = messageBox.value;
      return '<p>' + (Array.isArray(messages) ? messages.join('<br/>') : messages) + '</p>';
    });

    const onOk = () => {
      const { resolve } = messageBox.value;
      if (resolve) resolve(true);
      close();
    };
    const onCancel = () => {
      const { resolve } = messageBox.value;
      if (resolve) resolve(false);
      close();
    };
    watch(
      () => _messageBox.value.on,
      (on) => {
        if (on) {
          setTimeout(() => {
            btnCancelEl.value?.focus();
          }, 250);
        }
      }
    );
    return {
      messageBox,
      messages,
      onOk,
      onCancel,
      btnCancelEl,
    };
  },
});
