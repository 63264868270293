
import { defineComponent, PropType, reactive, computed } from 'vue';
import { useStore } from 'vuex';

import _ from 'lodash';
import { Form } from 'vee-validate';

import { DotStep, SettingTable, TermsGroup } from '@hems/component';
import { Helper, TermsHelper } from '@hems/util';
import { OptionalTermsCategoryValue, OPTIONAL_TERMS_CATEGORY } from '@hems/util/src/constant';

import { AppCtxState } from '@/store/modules/appCtx';

import { EnvLocale } from 'hems';

import { Join } from 'hems/auth';
import { EffectiveTermsInfo } from 'hems/terms';

export default defineComponent({
  name: 'Step3OptionalData',
  components: {
    DotStep,
    SettingTable,
    Form,
    TermsGroup,
  },
  props: {
    data: Object as PropType<Join.GeneralOptinalJoinInfo>,
    locale: {
      type: String as PropType<EnvLocale>,
    },
    termsList: {
      type: Array as PropType<EffectiveTermsInfo[]>,
      required: true,
    },
  },
  emits: ['next', 'back'],
  setup(props, { emit }) {
    const store = useStore();
    const appCtx = store.state.appCtx as AppCtxState;
    const langCd = computed(() => appCtx.langCd);

    const state = reactive<
      Omit<Join.GeneralOptinalJoinInfo, 'terms_ver'> & {
        termsData: Record<OptionalTermsCategoryValue, EffectiveTermsInfo>;
        locale: EnvLocale;
      }
    >({
      bld_area_val: props.data?.bld_area_val ?? null,
      usage_report: props.data?.usage_report ?? false,
      direct_marketing: props.data?.direct_marketing ?? false,
      termsData: {} as Record<OptionalTermsCategoryValue, EffectiveTermsInfo>,
      locale: props.locale ?? appCtx.envLocale,
    });

    const onNext = () => {
      emit(
        'next',
        'optionalData',
        _.pickBy(state, (v) => !Helper.isNull(v))
      );
    };

    const setOptionalTerms = () => {
      state.termsData = TermsHelper.getFilteredTerms(props.termsList, state.locale, langCd.value).optionalTerms;
    };

    setOptionalTerms();

    return {
      state,
      OPTIONAL_TERMS_CATEGORY,
      onNext,
      envLocale: appCtx.envLocale,
      getTermsTitleKey: TermsHelper.getTermsTitleKey,
      getAgreementTitleKey: TermsHelper.getAgreementTitleKey,
    };
  },
});
