
/* eslint-disable no-undef */
import type { PropType, Ref } from 'vue';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { GoogleMap, Marker } from 'vue3-google-map';

import { useMessageBox } from '@hems/component';
import { GoogleMapApi } from '@hems/component/src/google/map/GoogleMapAPI';

import type { LangCd } from 'hems';

export default defineComponent({
  name: 'GoogleMapWrapper',
  components: {
    GoogleMap,
    Marker,
  },
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
    lat: {
      type: Number,
      required: true,
      default: 0,
    },
    lng: {
      type: Number,
      required: true,
      default: 0,
    },
    language: {
      type: String as PropType<LangCd>,
      default: 'en',
    },
    apiKey: {
      type: String,
      required: true,
    },
  },
  emits: ['changePosition', 'instance'],
  async setup(props, { emit }) {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    const googleMap: Ref<InstanceType<typeof GoogleMap> | null> = ref(null);
    const googleMapApi = new GoogleMapApi({ apiKey: props.apiKey });

    const getGooglemapInstance = () => {
      if (googleMap.value?.ready) {
        googleMap.value?.map?.addListener('rightclick', function (instance: any) {
          if (state.editable) {
            setLocation(instance.latLng.lat(), instance.latLng.lng());
          }
        });

        emit('instance', googleMap.value);
      }
    };
    watch(() => googleMap.value?.ready, getGooglemapInstance);

    const { lat, lng } = await getGeolocation(props.lat, props.lng);
    const state = reactive({
      editable: computed(() => props.editable),
      position: computed(() => ({ lat: props.lat || lat, lng: props.lng || lng })),
      language: computed(() => props.language),
    });

    async function getGeolocation(_lat?: number, _lng?: number) {
      let lat = _lat !== undefined ? _lat : 0;
      let lng = _lng !== undefined ? _lng : 0;

      if (lat === 0 && lng === 0) {
        try {
          const { lat: _lat, lng: _lng } = await googleMapApi.getCurrentLocation();
          lat = _lat;
          lng = _lng;
          await setLocation(lat, lng);
        } catch (e) {
          // 현재 위치를 받지 못하는 경우 메시지 알림 - Mac 사용자 시스템 설정 변경 요청
          if (e instanceof GeolocationPositionError) {
            messageBox
              .alert([
                t('message.location_not_found'),
                t('message.check_location_setting'),
                t('message.system_setting'),
              ])
              .open();
          } else {
            console.warn(e);
          }
        }
      } else {
        await setLocation(lat, lng);
      }

      return {
        lat,
        lng,
      };
    }

    async function setLocation(lat: number, lng: number) {
      const locationInfo = await googleMapApi.getLocatioinInfo(lat, lng, state ? state.language : props.language);
      const statePostalInfo = await googleMapApi.getStatePostalInfo(locationInfo.address);
      const timezoneInfo = await googleMapApi.getTimezone(lat, lng);
      emit('changePosition', locationInfo, timezoneInfo, statePostalInfo);
    }

    async function dragend(e: any) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      setLocation(lat, lng);
    }

    return {
      state,
      googleMap,
      dragend,
      getGooglemapInstance,
    };
  },
});
