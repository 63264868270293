import { vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6e7b7a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fm_chk" }
const _hoisted_2 = ["name", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _withDirectives(_createElementVNode("input", _mergeProps({
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.value) = $event)),
      type: "checkbox",
      name: _ctx.name
    }, _ctx.$attrs, { value: _ctx.value }), null, 16, _hoisted_2), [
      [_vModelCheckbox, _ctx.state.value]
    ]),
    _createElementVNode("span", {
      class: "in",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}