
import { defineComponent, reactive, PropType, computed } from 'vue';
import { MultipleTableColumn } from './types';

export default defineComponent({
  name: 'MultipleTableReadOnly',
  props: {
    id: String,
    editable: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array as PropType<MultipleTableColumn[]>,
      required: true,
    },
    data: {
      type: Array as PropType<Record<string, any>[]>,
      default: () => [],
    },
    sort: Function,
  },
  setup(props, { emit }) {
    const state = reactive({
      data: props.data,
    });

    const contentAlign = computed(() => {
      return props.columns.reduce((prev, curr) => {
        return { ...prev, [curr.id]: curr.contentAlign ?? 'center' };
      }, {});
    });

    return {
      state,
      contentAlign,
    };
  },
});
