
import { defineComponent, PropType, computed } from 'vue';
import { Image } from '@hems/component';
import { Device, LangCd } from 'hems';
import { Helper, DateHelper } from '@hems/util';
import { WarrantyYear } from 'hems/device';

export default defineComponent({
  name: 'ModulesInfo',

  components: {
    Image,
  },
  props: {
    data: {
      type: Object as PropType<Device.PvModuleInfo>,
    },
    langCd: {
      type: String as PropType<LangCd>,
      required: true,
      default: 'en',
    },
    timezone: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const defaultWarrantyYear = '15';
    const src = computed(() => getWarrantyYear(props.data?.remain_year || defaultWarrantyYear));

    function getWarrantyYear(value: WarrantyYear) {
      return 'mon/module_warranty_' + value;
    }

    function convertToDate(value: number): string {
      return DateHelper.getDateFormatter(props.langCd, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: props.timezone,
      })
        .format(value * 1000)
        .toString();
    }

    return { convertToDate, src };
  },
});
