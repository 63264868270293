/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited. 
*/
import type { MomentSetObject } from 'moment';
import moment from 'moment-timezone';

import type { DateUnitValue } from '@hems/util/src/constant';
import {
  DEFAULT_LANGUAGE,
  ENV_LOCALE,
  GRAPH_INTERVAL,
  GRAPH_TERM_UNIT,
  LANGUAGE,
  ONE_DAY_VALUE_IN_MILLISECONDS,
  UTC_TIME_ZONE,
} from '@hems/util/src/constant';
import { getEnvLocale, getLangCd, padLeft, isNull } from '@hems/util/src/helper/helper';

import type { DateFormatConfig, EnvLocale, LangCd, Period, TermUnit, TermUnitAll, TimeValue } from 'hems';

import type { Interval } from 'hems/report';

type CalendarRangeValue = { start: Date; end: Date };

/** 기간 조회 - Search Box의 기간 설정 용도 */
export function getDiffDate(start: string, end?: string): number {
  if (!end) {
    const now = new Date();
    end = String(now.getFullYear()) + String(now.getMonth() + 1) + String(now.getDate());
  }
  const year1 = Number(start.substring(0, 4));
  const month1 = Number(start.substring(4, 2)) - 1; // 1월=0,12월=11
  const day1 = Number(start.substring(6, 2));
  const hour1 = Number(start.substring(8, 2));
  const min1 = Number(start.substring(10, 2));

  const year2 = Number(end.substring(0, 4));
  const month2 = Number(end.substring(4, 2)) - 1;
  const day2 = Number(end.substring(6, 2));
  const hour2 = Number(end.substring(8, 2));
  const min2 = Number(end.substring(10, 2));

  const startDate = new Date(year1, month1, day1, hour1, min1);
  const endDate = new Date(year2, month2, day2, hour2, min2);
  const day = ONE_DAY_VALUE_IN_MILLISECONDS;
  const value = (endDate.getTime() - startDate.getTime()) / day;

  return parseInt(String(value), 10);
}

/** 일 기준 기간 조회 - 유효성 체크 용도 */
export function getRangeDate(value: CalendarRangeValue): number {
  const startValue = moment(value.start).valueOf();
  const endValue = moment(value.end).valueOf();

  return (endValue - startValue) / ONE_DAY_VALUE_IN_MILLISECONDS;
}

/** 월 기준 기간 조회 - 유효성 체크 용도 */
export function getRangeMonth(value: CalendarRangeValue): number {
  const startDate = moment(value.start);
  const endDate = moment(value.end);
  const startValue = startDate.year() * 12 + startDate.month();
  const endValue = endDate.year() * 12 + endDate.month();

  return endValue - startValue;
}

/** 날짜 스트링 조회 - 'YYYYMMDD' | 'YYYYMMDDHH' */
export function getDateToString(date: Date, type?: string) {
  return (
    date.getFullYear() +
    padLeft(date.getMonth() + 1, 2) +
    padLeft(date.getDate(), 2) +
    (type === GRAPH_TERM_UNIT.HOUR ? padLeft(date.getHours(), 2) : '')
  );
}

/** 날짜 포맷 조회 - 그리드(테이블) 용도 */
export function getGridDateFormat(termUnit: TermUnit, langCd: LangCd) {
  if (termUnit === GRAPH_TERM_UNIT.MINUTE) {
    switch (langCd) {
      case LANGUAGE.KO:
        return 'YYYY/MM/DD';
      default:
        return 'DD/MM/YYYY';
    }
  } else if (termUnit === GRAPH_TERM_UNIT.HOUR) {
    switch (langCd) {
      case LANGUAGE.KO:
        return 'YYYY/MM/DD HH:mm:ss';
      default:
        return 'DD/MM/YYYY HH:mm:ss';
    }
  }
}

/** timestamp 값 -> 날짜 스트링 변환(타임존 적용) */
export function getTDUnitDateFormat(timestamp: string, langCd: LangCd, timezone: string) {
  return moment.utc(timestamp).tz(timezone, true).format(getGridDateFormat(GRAPH_TERM_UNIT.HOUR, langCd));
}

/** 날짜 포맷 조회 - 캘린더 용도 */
export function getCalendarDateFormat(termUnit: TermUnitAll, langCd: LangCd) {
  if (termUnit === GRAPH_TERM_UNIT.MONTH) {
    switch (langCd) {
      case LANGUAGE.KO:
        return 'YYYY/MM';
      default:
        return 'MM/YYYY';
    }
  } else if (termUnit === GRAPH_TERM_UNIT.MINUTE) {
    switch (langCd) {
      case LANGUAGE.KO:
        return 'YYYY/MM/DD';
      default:
        return 'DD/MM/YYYY';
    }
  } else if (termUnit === GRAPH_TERM_UNIT.HOUR) {
    switch (langCd) {
      case LANGUAGE.KO:
        return 'YYYY/MM/DD HH:mm';
      default:
        return 'DD/MM/YYYY HH:mm';
    }
  }
}

/** 날짜 포맷 변환 - Intl 내장 객체 적용 */
export function getDateFormatter(langCd: string, options: Intl.DateTimeFormatOptions = {}) {
  return new Intl.DateTimeFormat(langCd, options);
}

/** 오늘 기준 시작 Date 조회 - moment 적용 */
export function getHourlyStartValue(): Date {
  return moment().startOf('day').toDate();
}

/** 오늘 기준 끝 Date 조회 - moment 적용 */
export function getHourlyEndValue(date: Date): Date {
  return moment(date).endOf('day').toDate();
}

/** 현재 Date 조회 */
export function now() {
  return new Date();
}

/** 현재 Date 조회 - 년, 월, 일까지 적용 */
export function today() {
  return new Date(now().getFullYear(), now().getMonth(), now().getDate());
}

/** 현재 Date 포맷 - moment 적용 */
export function formatToday(format: string) {
  return moment().format(format);
}

/** 어제 Date 조회 - 년, 월, 일까지 적용 */
export const getYesterday = () => {
  const now = today();

  return new Date(now.setDate(now.getDate() - 1));
};

/** 2일 전 Date 조회 - 년, 월, 일까지 적용 */
export function twoDaysAgo() {
  return new Date(now().getFullYear(), now().getMonth(), now().getDate() - 2);
}

/** 1달 후 Date 조회 - 년, 월, 일까지 적용 */
export function oneMonthAfter(date: Date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

/** 날짜 조회 시 시작 Date 조회 (오늘 기준 6일 전) */
export function sixDaysAgo() {
  return new Date(now().getFullYear(), now().getMonth(), now().getDate() - 6);
}

/** 날짜 조회 시 끝 Date 조회 (오늘 기준 끝 시간) */
export function endDate() {
  return new Date(now().getFullYear(), now().getMonth(), now().getDate(), 23, 59, 59, 999);
}

/** 날짜 포맷 변환 - moment 적용 (기본값: 'YYYYMMDD') */
export function formatDate(date: Date, format = 'YYYYMMDD') {
  return moment(date).format(format);
}

/** 현재 날짜 포맷 변환 - 정규식, Intl 내장 객체 적용 */
export function getSimpleDateFormat(date: string, type: string): string {
  let now = new Date();

  if (date) {
    const year = Number(date.substring(0, 4));
    const month = Number(date.substring(4, 6)) > 0 ? Number(date.substring(4, 6)) : 1;
    const day = Number(date.substring(6, 8)) > 0 ? Number(date.substring(6, 8)) : 1;

    now = new Date(year, month - 1, day);
  }
  let options = { year: 'numeric', month: 'short', day: 'numeric' } as Intl.DateTimeFormatOptions;
  let replaceValue = '$2 $1';
  let additionalValue = ' ';
  if (type === 'month') {
    options = { year: 'numeric', month: 'short' };
    replaceValue = '$1 $2';
  } else if (type === 'year') {
    options = { year: 'numeric' };
  } else if (type === 'lifetime') {
    options = { year: 'numeric' };
    const today = new Date();
    additionalValue = ' - ' + today.getUTCFullYear();
  }
  const re = /(\w+)\s(\w+)/;

  return now.toLocaleDateString('en-US', options).replace(re, replaceValue) + additionalValue;
}

/** Interval에 따른 날짜 포맷 변환 */
export function formatDateByInterval(date: Date, interval: Interval) {
  switch (interval) {
    case GRAPH_INTERVAL.HOURLY:
      return moment(date).format('YYYYMMDDHH');
    case GRAPH_INTERVAL.DAILY:
      return moment(date).format('YYYYMMDD');
    case GRAPH_INTERVAL.MONTHLY:
      return moment(date).format('YYYYMM');
    case GRAPH_INTERVAL.YEARLY:
      return moment(date).format('YYYY');
  }
}

/** Interval에 따른 timestamp 조회 */
export function getTime(date: string | number, interval: Interval): number {
  if (typeof date == 'number') return 0;
  switch (interval) {
    case GRAPH_INTERVAL.HOURLY:
      return new Date(
        Number(date.substring(0, 4)),
        Number(date.substring(4, 6)) - 1,
        Number(date.substring(6, 8)),
        Number(date.substring(8, 10))
      ).getTime();
    case GRAPH_INTERVAL.DAILY:
      return new Date(
        Number(date.substring(0, 4)),
        Number(date.substring(4, 6)) - 1,
        Number(date.substring(6, 8))
      ).getTime();
    case GRAPH_INTERVAL.MONTHLY:
      return new Date(Number(date.substring(0, 4)), Number(date.substring(4, 6)) - 1).getTime();
    case GRAPH_INTERVAL.YEARLY:
      return moment(date, 'YYYY').toDate().getTime();
  }
}

/** 날짜의 '일' 반환 */
export function getNumberOfDaysByMonth(value: string) {
  return new Date(Number(value.substring(0, 4)), Number(value.substring(4, 6)), 0).getDate();
}

/** 끝 날짜가 시작 날짜 이후인지 체크함 */
export function isValidDateRange(start: Date | null, end: Date | null) {
  const startDate = start;
  const endDate = end;
  if (!startDate || !endDate) return true;
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  return startDate <= endDate;
}

/** 리전에 따른 Date 포맷 조회 */
// FIXME: complexity 줄이기
// eslint-disable-next-line complexity
export function getLocalDateFormat(dateFormatConfig: DateFormatConfig) {
  const { locale, isTime, isDay, isMonthly } = dateFormatConfig;
  const isSecond = dateFormatConfig.isSecond ?? true;

  if (isMonthly) {
    return 'MMM, YYYY';
  }

  switch (locale) {
    case ENV_LOCALE.EU:
    case ENV_LOCALE.AU:
    case ENV_LOCALE.NZ:
      if (isDay) {
        if (isTime && isSecond) {
          return 'dddd, DD MMM, YYYY (HH:mm:ss)';
        }
        if (isTime) {
          return 'dddd, DD MMM, YYYY (HH:mm)';
        }

        return 'dddd, DD MMM, YYYY';
      }
      if (isTime && isSecond) {
        return 'DD MMM, YYYY (HH:mm:ss)';
      }
      if (isTime) {
        return 'DD MMM, YYYY (HH:mm)';
      }

      return 'DD MMM, YYYY';

    case ENV_LOCALE.US:
      if (isDay) {
        if (isTime && isSecond) {
          return 'dddd, MMM DD, YYYY (HH:mm:ss)';
        }
        if (isTime) {
          return 'dddd, MMM DD, YYYY (HH:mm)';
        }

        return 'dddd, MMM DD, YYYY';
      }
      if (isTime && isSecond) {
        return 'MMM DD, YYYY (HH:mm:ss)';
      }
      if (isTime) {
        return 'MMM DD, YYYY (HH:mm)';
      }

      return 'MMM DD, YYYY';

    default:
      return 'DD MMM, YYYY';
  }
}

/** 리전에 따른 Date 포맷(타임존 포함) 조회 */
// FIXME: complexity 줄이기
// eslint-disable-next-line complexity
export function getDateFormatWithTimeZone(dateFormatConfig: DateFormatConfig) {
  const { locale, isTime, isDay } = dateFormatConfig;
  const isSecond = dateFormatConfig.isSecond ?? true;

  switch (locale) {
    case ENV_LOCALE.EU:
    case ENV_LOCALE.AU:
    case ENV_LOCALE.NZ:
      if (isDay) {
        if (isTime && isSecond) {
          return 'dddd, DD MMM, YYYY (HH:mm:ss z, UTCZ)';
        }
        if (isTime) {
          return 'dddd, DD MMM, YYYY (HH:mm z, UTCZ)';
        }

        return 'dddd, DD MMM, YYYY (z, UTCZ)';
      }
      if (isTime && isSecond) {
        return 'DD MMM, YYYY (HH:mm:ss z, UTCZ)';
      }
      if (isTime) {
        return 'DD MMM, YYYY (HH:mm z, UTCZ)';
      }

      return 'DD MMM, YYYY (z, UTCZ)';

    case ENV_LOCALE.US:
      if (isDay) {
        if (isTime && isSecond) {
          return 'dddd, MMM DD, YYYY (HH:mm:ss z, UTCZ)';
        }
        if (isTime) {
          return 'dddd, MMM DD, YYYY (HH:mm z, UTCZ)';
        }

        return 'dddd, MMM DD, YYYY (z, UTCZ)';
      }
      if (isTime && isSecond) {
        return 'MMM DD, YYYY (HH:mm:ss z, UTCZ)';
      }
      if (isTime) {
        return 'MMM DD, YYYY (HH:mm z, UTCZ)';
      }

      return 'MMM DD, YYYY (z, UTCZ)';

    default:
      return 'DD MMM, YYYY (HH:mm:ss z, UTCZ)';
  }
}

/** 날짜 스트링 조회 - 'YYYY-MM-DD HH:mm' | 'YYYY-MM-DD HH:mm:ss' | 'YYYY-MM-DD' */
export function getDateString(date: Date, isTime = true, timeValue?: TimeValue) {
  const month: string = ('0' + (date.getMonth() + 1)).slice(-2);
  const day: string = ('0' + date.getDate()).slice(-2);
  const hour: string = ('0' + date.getHours()).slice(-2);
  const minute: string = ('0' + date.getMinutes()).slice(-2);
  const second: string = ('0' + date.getSeconds()).slice(-2);

  return isTime
    ? timeValue
      ? `${date.getFullYear()}-${month}-${day} ${timeValue}`
      : `${date.getFullYear()}-${month}-${day} ${hour}:${minute}:${second}`
    : `${date.getFullYear()}-${month}-${day}`;
}

/** 리전에 따른 기본 타임존 스트링 조회 */
export function getDefaultTimeZoneByLocale(envLocale: EnvLocale) {
  switch (envLocale) {
    case ENV_LOCALE.EU:
      return 'Europe/Berlin';
    case ENV_LOCALE.AU:
    case ENV_LOCALE.NZ:
      return 'Australia/Sydney';
    case ENV_LOCALE.US:
      return 'America/Los_Angeles';
  }
}

/**
 * locale 날짜(date)값에 대해 GMT(UTC+0) String 타입 포맷(YYYY-MM-DD HH:mm:ss)으로 변환
 * @param envLocale
 * @param date
 * @param timeValue
 * @returns
 */
export function convertDefaultToGMTDateTime(envLocale: EnvLocale, date: Date, timeValue?: TimeValue) {
  const _date = getDateString(date, true, timeValue);
  const defaultDateTime = moment.tz(_date, getDefaultTimeZoneByLocale(envLocale));
  const gmtDateTime = defaultDateTime.clone().tz('GMT');

  return gmtDateTime.format('YYYY-MM-DD HH:mm:ss');
}

/**
 * timestamp 값에 대해 locale 날짜 String 타입 포맷(YYYY-MM-DD)으로 변환
 * @param envLocale
 * @param timestamp
 * @returns
 */
export function getTimestampToLocaleDate(envLocale: EnvLocale, timestamp: string) {
  return new Date(moment.unix(Number(timestamp)).tz(getDefaultTimeZoneByLocale(envLocale)).format('YYYY-MM-DD'));
}

/**
 * unix timestamp 값에 대한 타임존(timezone)의 String 타입 날짜 포맷(dateFormat)으로 변환
 * @param timestamp
 * @param timezone
 * @param dateFormat
 * @returns
 */
export function getTimestampToTimeZoneDateFormat(timestamp: number, timezone: string, dateFormat: string) {
  try {
    return moment(timestamp).tz(timezone).format(dateFormat);
  } catch (error) {
    console.log(error);

    return '';
  }
}

export function getTimeZoneOffsetByLocale(envLocale: EnvLocale, withTime = false, date?: number | string) {
  const timeZoneId = getDefaultTimeZoneByLocale(envLocale);
  const offsetMinutes = date ? moment.tz(date, timeZoneId).utcOffset() : moment.tz(timeZoneId).utcOffset();
  const hours = Math.floor(Math.abs(offsetMinutes) / 60);
  const minutes = Math.abs(offsetMinutes) % 60;

  let offsetString = offsetMinutes < 0 ? `-${padLeft(hours, 2)}` : `+${padLeft(hours, 2)}`;
  if (withTime) {
    offsetString = `${offsetString}:${padLeft(minutes, 2)}`;
  }

  return offsetString;
}

/** momentTimeZone의 short term 조회 */
export function getShortFormTimeZone(momentTimeZone: string) {
  return moment.tz(momentTimeZone).format('z');
}

/** momentTimeZone의 long term 조회 */
export function getLongFormTimeZone(momentTimeZone: string) {
  const abbrs: Record<string, string> = {
    AEDT: 'Australian Eastern Daylight Time',
  };

  moment.fn.zoneName = function () {
    const abbr = this.zoneAbbr();

    return abbrs[abbr] || abbr;
  };

  return moment.tz(momentTimeZone).format('zz');
}

/** UTC 기준 날짜 포맷 변환 */
export function getUtcDate(date: number, locale: EnvLocale, formatConfig: Omit<DateFormatConfig, 'locale'>) {
  return moment.utc(date).format(getDateFormatWithTimeZone({ locale, ...formatConfig }));
}

/** Date with Timezone 날짜 포맷 변환 */
export function getTimezoneDate(date: Date | number | string, formatConfig: Omit<DateFormatConfig, 'locale'>) {
  const { needUtcConvert, timezone = UTC_TIME_ZONE } = formatConfig;
  const locale = getEnvLocale();
  const lang = getLangCd() ?? DEFAULT_LANGUAGE;
  const dateFormat = getDateFormatWithTimeZone({ locale, ...formatConfig });
  moment.locale(lang);
  if (needUtcConvert) {
    moment(date).utc().tz(timezone).format(dateFormat);
  }

  return moment.tz(date, timezone).format(dateFormat);
}

/** 오늘 시작, 끝 Date 조회 (포맷 변환 포함) */
export function getTodayStartEndDateTimes(timeZone: string, format?: string) {
  const startDate = moment().tz(timeZone).startOf('date').format(format);
  const endDate = moment().tz(timeZone).endOf('date').format(format);

  return [startDate, endDate];
}

/** local Date String */
export function getLocalDate(date: Date | number | string, formatConfig: Omit<DateFormatConfig, 'locale'>) {
  const { isTime, isDay, isSecond, isMonthly, needUtcConvert, needTimezoneConvert, timezone } = formatConfig;
  const locale = getEnvLocale();
  const lang = getLangCd() ?? DEFAULT_LANGUAGE;
  const dateFormat = getLocalDateFormat({ locale, isTime, isDay, isSecond, isMonthly });
  moment.locale(lang);
  if (needUtcConvert) {
    if (needTimezoneConvert && !isNull(timezone)) {
      return moment.utc(date).tz(timezone, true).format(dateFormat);
    }

    return moment.utc(date).format(dateFormat);
  }
  if (needTimezoneConvert && !isNull(timezone)) {
    return moment.tz(date, timezone).format(dateFormat);
  }

  return moment(date).format(dateFormat);
}

/** Graph Date 파라미터 */
export function getGraphDateParameter(date: Date | number | string, termUnit: TermUnit) {
  if (termUnit === GRAPH_TERM_UNIT.MINUTE) {
    return moment(date).format('YYYYMMDD');
  } else {
    return moment(date).format('YYYYMMDDHH');
  }
}

/** Graph Date String */
export function getConvertedGraphDateString(date: string, termUnit: TermUnit) {
  const locale = getEnvLocale();
  const lang = getLangCd() ?? DEFAULT_LANGUAGE;
  const dateFormat =
    termUnit === GRAPH_TERM_UNIT.MINUTE
      ? 'HH:mm'
      : getLocalDateFormat({ locale, isTime: true, isDay: false, isSecond: false });
  moment.locale(lang);

  return moment(date, 'YYYYMMDDHHmm').format(dateFormat);
}

/** 두 날짜의 차이 조회 (일, 월, 년) */
export const getDateDifference = (date: Period, dateUnit: DateUnitValue): number => {
  return moment(date.end).diff(moment(date.start), dateUnit);
};

/** 시간 설정 옵션으로 변경된 날짜 조회 */
export const getDateWithAdjustedTime = (date: Date | number | string, adjustedOptions: MomentSetObject): Date => {
  return moment(date).set(adjustedOptions).toDate();
};

/** 타임존에 해당하는 현재 시간 조회 */
export const getCurrentHourByTimezone = (timezone: string): number => moment().tz(timezone).hour();

/** 브라우저 타임존 조회 */
export const getBrowserTimezone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;
