
import { SelectorOption, SelectorValue } from 'hems';
import { Helper } from '@hems/util';
import { defineComponent, PropType } from 'vue';
import BasicCheckbox from './BasicCheckbox.vue';
import ErrorMessageLabel from '../../labels/ErrorMessageLabel.vue';
import { useField } from 'vee-validate';

export default defineComponent({
  name: 'CheckboxGroup',
  components: {
    BasicCheckbox,
    ErrorMessageLabel,
  },
  inheritAttrs: false,
  props: {
    name: String,
    modelValue: {
      type: [String, Number, Boolean, null] as PropType<SelectorValue>,
      required: true,
    },
    maxColumnNum: {
      type: Number,
      default: 4,
    },
    options: {
      type: Array as PropType<SelectorOption[]>,
      required: true,
    },
    direction: {
      type: String as PropType<'row' | 'col'>,
      default: 'row',
    },
    align: {
      type: String as PropType<'left' | 'right' | 'center'>,
      default: 'left',
    },
    valueType: {
      type: String as PropType<'string' | 'number'>,
      default: 'string',
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage } = useField(props.name || Helper.getUUID(), undefined, {
      initialValue: props.modelValue,
      type: props.valueType,
    });

    return {
      inputValue,
      errorMessage,
    };
  },
});
