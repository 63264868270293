import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3473f7f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "basic-input" }
const _hoisted_2 = ["value", "name", "type", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!
  const _component_ErrorMessageLabel = _resolveComponent("ErrorMessageLabel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createElementVNode("input", _mergeProps({
        value: _ctx.inputValue,
        name: _ctx.inputName,
        type: (_ctx.state.isReadonly ? 'hidden' : _ctx.state.showPassword ? 'text' : _ctx.type) || 'text',
        class: { [_ctx.className]: true, [`${!!_ctx.errorMessage ? 'error' : _ctx.validClass ? _ctx.validClass : ''}`]: true }
      }, _ctx.$attrs, {
        readonly: _ctx.state.isReadonly,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
      }), null, 16, _hoisted_2),
      _withDirectives(_createElementVNode("span", _mergeProps({ class: "fm_ipt display-value" }, _ctx.$attrs, {
        class: { [_ctx.className]: true }
      }), _toDisplayString(_ctx.type === 'password' && !_ctx.state.showPassword ? _ctx.displayPassword(_ctx.state.displayValue) : _ctx.state.displayValue), 17), [
        [_vShow, _ctx.state.isReadonly]
      ]),
      (_ctx.type === 'password')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "password-image",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.state.showPassword = !_ctx.state.showPassword))
          }, [
            (_ctx.state.showPassword)
              ? (_openBlock(), _createBlock(_component_Image2, {
                  key: 0,
                  src: "smartmodule/hide_pw",
                  extension: "svg"
                }))
              : (_openBlock(), _createBlock(_component_Image2, {
                  key: 1,
                  src: "smartmodule/show_pw",
                  extension: "svg"
                }))
          ]))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createVNode(_component_ErrorMessageLabel, { "error-message": _ctx.errorMessage }, null, 8, ["error-message"]), [
      [_vShow, _ctx.type !== 'hidden']
    ])
  ], 64))
}