/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited. 
*/

export const useCheckMobile = () => {
  const isMobile = location.href.includes('/mobile/');

  return { isMobile };
};
