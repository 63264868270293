import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4d5a332"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box-lft" }
const _hoisted_2 = {
  key: 3,
  class: "middle-line"
}
const _hoisted_3 = { class: "box-rt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TodayConsumption = _resolveComponent("TodayConsumption")!
  const _component_TodayProduction = _resolveComponent("TodayProduction")!
  const _component_TodayProductionACNoConsumptionCT = _resolveComponent("TodayProductionACNoConsumptionCT")!
  const _component_EnergyIndependence = _resolveComponent("EnergyIndependence")!
  const _component_EnergyValueAC = _resolveComponent("EnergyValueAC")!
  const _component_EnergyValueACNoConsumptionCT = _resolveComponent("EnergyValueACNoConsumptionCT")!
  const _component_EnergyValue = _resolveComponent("EnergyValue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.energyValueContainerClassName)
    }, [
      (_ctx.isACSystem && !_ctx.siteConfiguration?.hasPV)
        ? (_openBlock(), _createBlock(_component_TodayConsumption, {
            key: 0,
            "report-data": _ctx.reportData.today,
            "show-chart": _ctx.showChart,
            timezone: _ctx.baseDevice?.timezone_id
          }, null, 8, ["report-data", "show-chart", "timezone"]))
        : (_ctx.isConsumptionCt)
          ? (_openBlock(), _createBlock(_component_TodayProduction, {
              key: 1,
              "report-data": _ctx.reportData.today,
              "show-chart": _ctx.showChart,
              "is-connect-third-party": _ctx.isConnectThirdParty,
              timezone: _ctx.baseDevice?.timezone_id
            }, null, 8, ["report-data", "show-chart", "is-connect-third-party", "timezone"]))
          : (_openBlock(), _createBlock(_component_TodayProductionACNoConsumptionCT, {
              key: 2,
              "report-data": _ctx.reportData.today,
              "show-chart": _ctx.showChart,
              timezone: _ctx.baseDevice?.timezone_id
            }, null, 8, ["report-data", "show-chart", "timezone"])),
      (!_ctx.isACSystem || (_ctx.siteConfiguration?.hasPV && _ctx.isConsumptionCt))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.siteConfiguration?.hasPV && _ctx.isConsumptionCt)
        ? (_openBlock(), _createBlock(_component_EnergyIndependence, {
            key: 4,
            "energy-independence-data": _ctx.energyIndependenceData
          }, null, 8, ["energy-independence-data"]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isACSystem && _ctx.isConsumptionCt)
        ? (_openBlock(), _createBlock(_component_EnergyValueAC, {
            key: 0,
            "realtime-data": _ctx.realtimeData,
            "site-configuration-info": _ctx.siteConfiguration,
            disconnect: _ctx.disconnect,
            "home-energy-data": _ctx.homeEnergyData,
            "is-connect-third-party": _ctx.isConnectThirdParty,
            "is-consumption-ct": _ctx.isConsumptionCt,
            onReloadHomeEnergyData: _ctx.loadHomeEnergyData
          }, null, 8, ["realtime-data", "site-configuration-info", "disconnect", "home-energy-data", "is-connect-third-party", "is-consumption-ct", "onReloadHomeEnergyData"]))
        : (!_ctx.isConsumptionCt)
          ? (_openBlock(), _createBlock(_component_EnergyValueACNoConsumptionCT, {
              key: 1,
              "realtime-data": _ctx.realtimeData
            }, null, 8, ["realtime-data"]))
          : (_openBlock(), _createBlock(_component_EnergyValue, {
              key: 2,
              "realtime-data": _ctx.realtimeData,
              disconnect: _ctx.disconnect,
              "home-energy-data": _ctx.homeEnergyData,
              "is-connect-third-party": _ctx.isConnectThirdParty,
              onReloadHomeEnergyData: _ctx.loadHomeEnergyData
            }, null, 8, ["realtime-data", "disconnect", "home-energy-data", "is-connect-third-party", "onReloadHomeEnergyData"]))
    ])
  ]))
}