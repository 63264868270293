import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66c2cdd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "downloadBtnWrapper"
}
const _hoisted_3 = {
  type: "button",
  class: "btn_t btn_c_4"
}
const _hoisted_4 = {
  ref: "sigPad",
  style: {"display":"none"}
}
const _hoisted_5 = ["width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "flex flex-direction-colomn",
      style: _normalizeStyle({ minHeight: `${_ctx.minHeight}px`, width: `${_ctx.width}px` })
    }, [
      (_ctx.signImageUrl)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.signImageUrl,
            class: "sig-image"
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true),
      (!_ctx.hideDownload)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("button", _hoisted_3, [
              _createElementVNode("span", {
                class: "ir_b ico_down_2",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.download && _ctx.download(...args)))
              }, _toDisplayString(_ctx.$t('common.download_data')), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 4),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("canvas", {
        class: "pad",
        width: _ctx.width,
        height: _ctx.minHeight
      }, null, 8, _hoisted_5)
    ], 512)
  ], 64))
}