import type { ValueType } from 'hems/common/utils';
import type { EffectiveTermsInfo } from 'hems/terms';

export const REQUIRED_TERMS_CATEGORY = {
  TERMS_OF_SERVICE: 'Terms of Service',
  TERMS_OF_REGION: 'Terms of Region',
  TERMS_OF_PRIVACY: 'Privacy Policy',
} as const;

export const OPTIONAL_TERMS_CATEGORY = {
  TERMS_OF_INDIVIDUAL: 'Terms of Individual',
  TERMS_OF_DIRECT_MARKETING: 'Terms of DirectMarketing',
} as const;

export const TERMS_CATEGORY = {
  ...REQUIRED_TERMS_CATEGORY,
  ...OPTIONAL_TERMS_CATEGORY,
} as const;

export type RequiredTermsCategoryValue = ValueType<typeof REQUIRED_TERMS_CATEGORY>;
export type OptionalTermsCategoryValue = ValueType<typeof OPTIONAL_TERMS_CATEGORY>;
export type TermsCategoryValue = ValueType<typeof TERMS_CATEGORY>;

export const TERMS_TYPE = {
  USER: 'USER',
  INSTALLER: 'INSTALLER',
  SITE: 'SITE',
} as const;

export type TermsTypeValue = ValueType<typeof TERMS_TYPE>;

export interface FilteredTerms {
  requiredTerms: Record<RequiredTermsCategoryValue, EffectiveTermsInfo>;
  optionalTerms: Record<OptionalTermsCategoryValue, EffectiveTermsInfo>;
  allTerms: Record<TermsCategoryValue, EffectiveTermsInfo>;
}
